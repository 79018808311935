import { useState, useContext, useCallback, useEffect } from "react";
import { Alert, Button, Container, Modal } from "react-bootstrap";
import { Download } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useAuth, useDocumentsNaming, useFormFields } from "hooks";
import { IModelValuePair } from "types";
import { UserContext, CompanyContext, SheetsContext, IExportSettings, IRevision } from "context";
import { EMPTY_SETTINGS, DOCUMENTLIST_TEMPLATE } from "_constants";
import ExportForm from "./ExportForm";
import EditRevisionModal from "./EditRevisionModal";
import { API_URL } from "config";

interface Props {
    show: boolean;
    handleClose: () => void;
    projectId: string;
}

const ExportModal = ({ show, handleClose, projectId }: Props): JSX.Element => {
    const { t } = useTranslation();
    const [showEditRevisionModal, setShowEditRevisionModal] = useState<boolean>(false);
    const [selectedRevision, setSelectedRevision] = useState<IRevision | undefined>(undefined);
    const [selectedKnowledge, setSelectedKnowledge] = useState<IModelValuePair | undefined>(undefined);
    const { exportSettings, handleSaveExportSettings } = useContext(SheetsContext);
    const { getAccessToken } = useAuth();
    const { company, companyHasLogo } = useContext(CompanyContext);
    const { user } = useContext(UserContext);
    const { fields, handleFieldChange } = useFormFields<IExportSettings>(exportSettings || EMPTY_SETTINGS);
    const [queryString, setQueryString] = useState<string>("");

    const { previewDocumentNumber } = useDocumentsNaming({ ...DOCUMENTLIST_TEMPLATE, projectId: projectId });
    const filename = previewDocumentNumber({
        ...DOCUMENTLIST_TEMPLATE,
        knowledgeCode: selectedKnowledge?.code || "",
        knowledgeId: selectedKnowledge?.id || "",
        title: fields.title,
    });

    const handleClickDownload = () => {
        handleSaveExportSettings(fields);
        handleClose();
    };

    const handleOpenRevisionModal = (index: number): void => {
        setSelectedRevision(fields.revisions.find(rev => rev.index === index));
        setShowEditRevisionModal(true);
    };

    const createQueryString = useCallback(async (): Promise<string> => {
        const selectedRevisionField = fields.revisions.find(rev => rev.index === fields.selectedRevision);
        const selectedRevisionDate = selectedRevisionField?.date || "-";
        const selectedRevisionIndex = selectedRevisionField?.index || "-";
        const revisionQuery = `revision=${selectedRevisionIndex}&revisionDate=${selectedRevisionDate}`;
        const companyQuery = `companyId=${company?.id || ""}`;
        const formatQuery = `format=${fields.format || ""}`;
        const filenameQuery = `filename=${filename || ""}`;
        const knowledgeQuery = `knowledge=${fields.knowledge || ""}`;
        const dateQuery = `date=${fields.date || ""}`;
        const accessToken = await getAccessToken();
        const accessTokenQuery = `accessToken=${accessToken}`;
        return `${formatQuery}&${dateQuery}&${knowledgeQuery}&${filenameQuery}&${revisionQuery}&${companyQuery}&${accessTokenQuery}`;
    }, [fields, company, filename, getAccessToken]);

    useEffect(() => {
        const query = async () => {
            const query = await createQueryString();
            setQueryString(query);
        };
        query();
    }, [createQueryString]);

    const inputIsValid = () => fields.title !== "";

    return (
        <>
            <Modal show={show} onHide={handleClose} centered backdrop="static" scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>{t("pages.FAST.sheet-list.export-sheet-list.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{ minHeight: "650px" }}>
                        {!companyHasLogo && user?.role === "admin" && (
                            <Alert variant="warning">
                                No company logo found. <br /> Please go to company settings and add one.
                            </Alert>
                        )}
                        {!companyHasLogo && user?.role !== "admin" && (
                            <Alert variant="warning">
                                No company logo found. <br /> Ask your admin to add company logo.
                            </Alert>
                        )}
                        <ExportForm
                            fields={fields as IExportSettings}
                            handleFieldChange={handleFieldChange}
                            handleOpenRevisionModal={handleOpenRevisionModal}
                            selectedKnowledge={selectedKnowledge}
                            setSelectedKnowledge={setSelectedKnowledge}
                            filename={filename}
                        />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                    <Button
                        disabled={!inputIsValid() || !companyHasLogo}
                        style={{ display: "table-cell" }}
                        target="_blank"
                        href={`${API_URL}/v1/projects/${projectId}/document-list?${queryString}`}
                        variant="primary"
                        onClick={handleClickDownload}
                        className="d-inline-flex align-items-center"
                    >
                        <Download className="me-2" />
                        {t("buttons.download")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <EditRevisionModal
                show={showEditRevisionModal}
                revisions={fields.revisions}
                selectedRevision={selectedRevision}
                handleFieldChange={handleFieldChange}
                handleClose={() => setShowEditRevisionModal(false)}
            />
        </>
    );
};

export default ExportModal;
