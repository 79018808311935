import axios from "axios";
import { API_URL } from "config";
import { IModelMetaData } from "types";

type IRevitParameterService = {
    getRevitParameterList: (accessToken: string) => Promise<IModelMetaData[]>;
};

export const RevitParameterService: IRevitParameterService = {
    getRevitParameterList: async (accessToken: string) => {
        const res = await axios.get(`${API_URL}/v1/revit-parameters/parameters`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return res.data;
    },
};
