import React from 'react'
import { ListGroup } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { ThemeValuePair } from 'types';
import { ListItemWithActionWrapper, ListItemDissmissContainer } from '../Shared/ListItem';

interface Props {
    valuePair: ThemeValuePair;
    valueCode: string;
    handleSelectThemeValuePair: (id: string) => void;
    handleDeleteThemeValuePair: (id: string) => void;
}

const ThemeValuePairListItem: React.FC<Props> = ({ valuePair, valueCode, handleSelectThemeValuePair, handleDeleteThemeValuePair }) => (
    <ListGroup.Item

        className="list-item"
    >
        <ListItemWithActionWrapper
            interactive
            handleOnClick={() => {
                handleSelectThemeValuePair(valuePair.id)
            }}>
            <div>{`${valueCode}${valuePair.code}`}</div>
            <div>{valuePair.value}</div>
        </ListItemWithActionWrapper>
        <ListItemDissmissContainer handleOnClick={() => handleDeleteThemeValuePair(valuePair.id)}>
            <Trash />
        </ListItemDissmissContainer>
    </ListGroup.Item>
)

export default ThemeValuePairListItem