import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Check } from "react-bootstrap-icons";

interface FormFieldProps {
    initialValue: string;
    handleSaveField: (value: string | number) => void;
    children?: React.ReactNode;
    permaDisabled?: boolean;
    validInputRegex?: RegExp;
    invalidInputMessage?: string;
}

const FormFieldWithAcceptButton: React.FC<FormFieldProps> = ({
    initialValue,
    handleSaveField,
    children,
    permaDisabled,
    invalidInputMessage,
    validInputRegex,
    ...props
}) => {
    const [value, setValue] = useState<string | number>(initialValue);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const checkingForValidInput = validInputRegex !== undefined;
    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const [invalidValue, setInvalidValue] = useState<string | number>(initialValue);

    useEffect(() => {
        if (!isFocused) setValue(initialValue);
    }, [initialValue, isFocused]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        setIsInvalid(false);
    };

    const handleSubmit = () => {
        if (checkingForValidInput) {
            if (validInputRegex?.test(value.toString())) {
                handleSaveField(value);
            } else {
                setIsInvalid(true);
                setInvalidValue(value);
            }
        } else {
            handleSaveField(value);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleSubmit();
            if (checkingForValidInput) {
                if (validInputRegex?.test(value.toString())) {
                    e.currentTarget.blur();
                }
            }
        }
        if (e.key === "Escape") {
            e.currentTarget.blur();
        }
    };

    const activateField = () => {
        if (permaDisabled) {
            return;
        }
        setIsFocused(true);
        setIsInvalid(false);
    };

    const handleOnBlur = () => {
        handleSubmit();
        setIsFocused(false);
    };

    return (
        <>
            <InputGroup>
                <Form.Control
                    style={{
                        backgroundColor: permaDisabled === true ? "" : "white",
                        cursor: permaDisabled === true ? "not-allowed" : "text",
                    }}
                    isInvalid={checkingForValidInput && isInvalid}
                    readOnly={!isFocused}
                    disabled={permaDisabled}
                    onFocus={activateField}
                    onBlur={handleOnBlur}
                    value={value}
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                    onClick={activateField}
                    {...props}
                >
                    {children}
                </Form.Control>
            </InputGroup>
            {invalidInputMessage && validInputRegex && isInvalid && (
                <div className="invalid-feedback d-block">
                    <b>{invalidValue}</b>
                    {invalidInputMessage}
                </div>
            )}
        </>
    );
};

export default FormFieldWithAcceptButton;
