import React, { useState } from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";
import LoginForm from "./LoginForm";

interface Props {
    setShowEmailLoginForm: (setShowEmailLoginForm: boolean) => void;
}

const LoginEmailContainer: React.FC<Props> = ({ setShowEmailLoginForm }) => {
    const [showForgotPasswordForm, setShowForgotPasswordForm] = useState<boolean>(false);

    return (
        <>
            {showForgotPasswordForm ? (
                <ForgotPasswordForm />
            ) : (
                <LoginForm
                    setShowEmailLoginForm={setShowEmailLoginForm}
                    setShowForgotPasswordForm={setShowForgotPasswordForm}
                />
            )}
        </>
    );
};

export default LoginEmailContainer;
