import React from "react";
import { Form, Row, Col, ButtonGroup, ToggleButton } from "react-bootstrap";
import { FileEarmarkExcel, FileEarmarkPdf } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { IExportSettings, IRevision } from "context";
import { useContextModel, FormEvent, SyntheticEvent } from "hooks";
import { IModelValuePair } from "types";
import RevisionDiagramCheckbox from "./RevisionDiagramCheckbox";

const exportFormats = [
    { id: "EXCEL", title: "Excel", icon: FileEarmarkExcel },
    { id: "PDF", title: "PDF", icon: FileEarmarkPdf },
];

interface Props {
    fields: IExportSettings;
    handleOpenRevisionModal: (index: number) => void;
    handleFieldChange: (event: FormEvent) => void;
    selectedKnowledge: IModelValuePair | undefined;
    setSelectedKnowledge: React.Dispatch<React.SetStateAction<IModelValuePair | undefined>>;
    filename: string;
}

const ExportForm: React.FC<Props> = ({
    fields,
    handleOpenRevisionModal,
    handleFieldChange,
    selectedKnowledge,
    setSelectedKnowledge,
    filename,
}) => {
    const { t } = useTranslation();
    const { contextModel, getKnowledgeById } = useContextModel();

    const customHandleFieldChange = (targetId: string, targetValue: IRevision[] | string | number) => {
        const syntheticEventTarget = { id: targetId, value: targetValue };
        handleFieldChange({ target: syntheticEventTarget } as SyntheticEvent);
    };

    const handleChangeKnowledge = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target.value === "NONE") {
            setSelectedKnowledge(undefined);
            customHandleFieldChange("knowledge", "NONE");
            return;
        }
        const targetKnowledge = getKnowledgeById(e.target.value);
        setSelectedKnowledge(targetKnowledge);
        customHandleFieldChange("knowledge", e.target.value);
    };

    return (
        <Form className="d-grid gap-2" noValidate>
            <Row>
                <Form.Group as={Col} controlId="title">
                    <Form.Label>{t("pages.FAST.sheet-list.export-sheet-list.fields.title")}</Form.Label>
                    <Form.Control type="text" value={fields.title} onChange={handleFieldChange} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} controlId="knowledge">
                    <Form.Label>{t("pages.FAST.sheet-list.export-sheet-list.fields.knowledge-area")}</Form.Label>
                    <Form.Select value={selectedKnowledge?.id || "NONE"} onChange={() => handleChangeKnowledge}>
                        <option value="NONE">
                            {t("pages.FAST.sheet-list.export-sheet-list.fields.knowledge-area-all")}
                        </option>
                        {contextModel.knowledges.map((knowledge, index) => (
                            <option value={knowledge.id} key={index}>
                                {knowledge.code} - {knowledge.value}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} controlId="date">
                    <Form.Label>{t("pages.FAST.sheet-list.export-sheet-list.fields.date")}</Form.Label>
                    <Form.Control type="date" value={fields.date} onChange={handleFieldChange} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>{t("pages.FAST.sheet-list.export-sheet-list.fields.revision")}</Form.Label>
                    <RevisionDiagramCheckbox
                        revisions={fields.revisions}
                        value={fields.selectedRevision}
                        handleFieldChange={customHandleFieldChange}
                        handleOpenModal={handleOpenRevisionModal}
                    />
                    {fields.selectedRevision !== 0 && (
                        <Form.Text
                            onClick={() => customHandleFieldChange("selectedRevision", 0)}
                            className="text-muted mt-0 pointer"
                        >
                            {t("pages.FAST.sheet-list.export-sheet-list.fields.remove-selected-revision")}
                        </Form.Text>
                    )}
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>{t("pages.FAST.sheet-list.export-sheet-list.fields.format")}</Form.Label>
                    <Col className="px-0">
                        <ButtonGroup>
                            {exportFormats.map(format => (
                                <ToggleButton
                                    id={format.id}
                                    style={{ height: "42px" }}
                                    variant={"light"}
                                    key={format.id}
                                    type="radio"
                                    value={format.id}
                                    className="d-inline-flex align-items-center"
                                    size="sm"
                                    checked={fields.format === format.id}
                                    onChange={() => customHandleFieldChange("format", format.id)}
                                >
                                    {React.createElement(format.icon)}
                                    <span className="ms-2">{format.title}</span>
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </Col>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} controlId="filename">
                    <Form.Label>{t("pages.FAST.sheet-list.export-sheet-list.fields.file-name")}</Form.Label>
                    <Form.Control
                        type="text"
                        value={`${filename}.${fields.format === "PDF" ? "pdf" : "xlsx"}`}
                        disabled
                    />
                </Form.Group>
            </Row>
        </Form>
    );
};

export default ExportForm;
