import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { Apartment } from "types";
import { EMPTY_APARTMENT } from "_constants";
import { ApartmentsContext } from "context";
import _ from "lodash";
import { ContainerWithStatusAlertHandling } from "../../../hoc/withStatusAlerts";
import ApartmentCard from "./ApartmentCard";
import ApartmentModal from "./ApartmentModal";
import { useTranslation } from "react-i18next";

const ApartmentContainer: React.FC = () => {
    const { loadingApartments, error, apartments } = useContext(ApartmentsContext);
    const [showApartmentModal, setShowApartmentModal] = useState<boolean>(false);
    const [selectedApartment, setSelectedApartment] = useState<Apartment>(EMPTY_APARTMENT);
    const { t } = useTranslation();

    const handleOpenCreateApartmentModal = () => {
        const NEW_APARTMENT: Apartment = {
            // TODO: MIDLERTIDIGT FIX
            id: "",
            name: "",
            areaSize: 0,
            rooms: [],
        };

        setSelectedApartment(NEW_APARTMENT);
        setShowApartmentModal(true);
    };

    const handleOpenEditApartmentModal = (apartment: Apartment) => {
        setSelectedApartment(apartment);
        setShowApartmentModal(true);
    };

    return (
        <ContainerWithStatusAlertHandling
            className="m-0 p-4 h-100"
            style={{ overflow: "auto" }}
            fluid
            loading={loadingApartments}
            loadingMessage={t("pages.FLAT.apartments.loading") as string}
            error={error}
        >
            <h1>{t("pages.FLAT.apartments.title")}</h1>
            <h6 className="text-muted">{t("pages.FLAT.apartments.subtitle")}</h6>
            <Row className="mt-4">
                <Col>
                    <Button className="me-1" onClick={handleOpenCreateApartmentModal}>
                        <Plus size="1.5rem" />
                        {t("pages.FLAT.apartments.add-apartment")}
                    </Button>
                </Col>
            </Row>
            <Row className="mt-2 p-2">
                {_.orderBy(apartments, ["name"]).map(apartment => (
                    <ApartmentCard key={apartment.id} onClick={handleOpenEditApartmentModal} apartment={apartment} />
                ))}
            </Row>
            {showApartmentModal && (
                <ApartmentModal handleClose={() => setShowApartmentModal(false)} apartment={selectedApartment} />
            )}
        </ContainerWithStatusAlertHandling>
    );
};

export default ApartmentContainer;
