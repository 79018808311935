import { Row, Col, Spinner, ProgressBar } from "react-bootstrap";
import { Check, Dot, X } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { CreateProjectStatus } from "types";

interface Props {
    createProjectStatus: CreateProjectStatus;
    createProjectProgress: number;
}

const LoadingCreateProject: React.FC<Props> = ({ createProjectStatus, createProjectProgress }) => {
    const { t } = useTranslation();

    return (
        <div>
            <Row className="mb-2">
                <Col>
                    <h5>{t("pages.shared.creating-project.header")}</h5>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col>
                    <LoadingLine
                        status={createProjectStatus.copiedNamingSyntax}
                        text={t("pages.shared.creating-project.loading-namingsyntax")}
                    />
                    <LoadingLine
                        status={createProjectStatus.copiedRevitParameters}
                        prevStatus={createProjectStatus.copiedNamingSyntax}
                        text={t("pages.shared.creating-project.loading-revitparameters")}
                    />
                    <LoadingLine
                        status={createProjectStatus.copiedDisciplines}
                        prevStatus={createProjectStatus.copiedRevitParameters}
                        text={t("pages.shared.creating-project.loading-disciplines")}
                    />
                    <LoadingLine
                        status={createProjectStatus.copiedKnowledges}
                        prevStatus={createProjectStatus.copiedDisciplines}
                        text={t("pages.shared.creating-project.loading-knowledges")}
                    />
                    <LoadingLine
                        status={createProjectStatus.copiedSheetTypes}
                        prevStatus={createProjectStatus.copiedKnowledges}
                        text={t("pages.shared.creating-project.loading-sheettypes")}
                    />
                    <LoadingLine
                        status={createProjectStatus.copiedFormats}
                        prevStatus={createProjectStatus.copiedSheetTypes}
                        text={t("pages.shared.creating-project.loading-formats")}
                    />
                    <LoadingLine
                        status={createProjectStatus.copiedScales}
                        prevStatus={createProjectStatus.copiedFormats}
                        text={t("pages.shared.creating-project.loading-scales")}
                    />
                    <LoadingLine
                        status={createProjectStatus.copiedPhases}
                        prevStatus={createProjectStatus.copiedScales}
                        text={t("pages.shared.creating-project.loading-phases")}
                    />
                    <LoadingLine
                        status={createProjectStatus.copiedStages}
                        prevStatus={createProjectStatus.copiedPhases}
                        text={t("pages.shared.creating-project.loading-stages")}
                    />
                </Col>
                <Col>
                    <LoadingLine
                        status={createProjectStatus.copiedDocumentKindCategories}
                        prevStatus={createProjectStatus.copiedStages}
                        text={t("pages.shared.creating-project.loading-documentkindcategories")}
                    />
                    <LoadingLine
                        status={createProjectStatus.copiedDocumentKinds}
                        prevStatus={createProjectStatus.copiedDocumentKindCategories}
                        text={t("pages.shared.creating-project.loading-documentkinds")}
                    />
                    <LoadingLine
                        status={createProjectStatus.copiedFileTypes}
                        prevStatus={createProjectStatus.copiedDocumentKinds}
                        text={t("pages.shared.creating-project.loading-filetypes")}
                    />

                    <LoadingLine
                        status={createProjectStatus.copiedBusinessTypes}
                        prevStatus={createProjectStatus.copiedFileTypes}
                        text={t("pages.shared.creating-project.loading-businesstypes")}
                    />

                    <LoadingLine
                        status={createProjectStatus.copiedSheetTemplates}
                        prevStatus={createProjectStatus.copiedBusinessTypes}
                        text={t("pages.shared.creating-project.loading-sheettemplates")}
                    />
                    <LoadingLine
                        status={createProjectStatus.copiedDocumentTemplates}
                        prevStatus={createProjectStatus.copiedSheetTemplates}
                        text={t("pages.shared.creating-project.loading-documenttemplates")}
                    />
                    <LoadingLine
                        status={createProjectStatus.createdSharedBuilding}
                        prevStatus={createProjectStatus.copiedDocumentTemplates}
                        text={t("pages.shared.creating-project.loading-sharedbuilding")}
                    />
                    <LoadingLine
                        status={createProjectStatus.createdGroundLevel}
                        prevStatus={createProjectStatus.createdSharedBuilding}
                        text={t("pages.shared.creating-project.loading-groundlevel")}
                    />
                    <LoadingLine
                        status={createProjectStatus.copiedThemeAndSerialNumbers}
                        prevStatus={createProjectStatus.createdGroundLevel}
                        text={t("pages.shared.creating-project.loading-themevalues-serialnumberconfig")}
                    />
                </Col>
            </Row>
            <div>
                <ProgressBar animated label={`${createProjectProgress}%`} now={createProjectProgress} />
            </div>
        </div>
    );
};

export default LoadingCreateProject;

interface LoadingLineProps {
    status: boolean;
    prevStatus?: boolean;
    text: string;
}

const LoadingLine: React.FC<LoadingLineProps> = ({ status, prevStatus = true, text }) => (
    <>
        {status ? (
            <Check color="green" />
        ) : prevStatus ? (
            <Spinner animation={"border"} size="sm" variant="primary" />
        ) : (
            <Dot />
        )}{" "}
        {text}
        <br />
    </>
);
