import React, { useContext, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { IDocumentTemplate } from 'types';
import { DocumentsContext } from 'context';
import DocumentConfigurator from './DocumentConfigurator';
import { useTranslation } from 'react-i18next';

interface IDocumentsModalConfigurator {
    handleClose: () => void;
    title: string;
}

const DocumentsModalConfigurator = ({
    handleClose,

    title,
}: IDocumentsModalConfigurator): JSX.Element => {
    const { t } = useTranslation();
    const [selectedDocumentKeys, setSelectedDocumentKeys] = useState<IDocumentTemplate[]>([]);
    const { handleGenerateDocuments } = useContext(DocumentsContext);

    const handleSelectedDocumentKey = (isSelected: boolean, documentKey: IDocumentTemplate) => {
        if (isSelected) {
            setSelectedDocumentKeys([...selectedDocumentKeys, documentKey]);
        } else {
            setSelectedDocumentKeys(selectedDocumentKeys.filter(key => key !== documentKey));
        }
    };

    return (
        <Modal size="xl" show={true} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DocumentConfigurator
                    selectedDocumentKeys={selectedDocumentKeys}
                    handleSelectedDocumentKey={handleSelectedDocumentKey}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('buttons.cancel')}
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        handleGenerateDocuments(selectedDocumentKeys);
                        handleClose();
                    }}
                >
                    {t('buttons.add')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DocumentsModalConfigurator;
