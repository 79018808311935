import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import SheetsContainer from "../components/Sheets/SheetsContainer";
import FastSettingsContainer from "../components/Sheets/FastSettingsContainer";
import FastBuildingContainer from "../components/Location/FastBuildingContainer";
import ProjectValuePairListContainer from "../components/Project/ProjectValuePairListContainer";
import FASTDashboard from "../components/SheetsDocuments/FASTDashboard";
import EmptyFeatureState from "../components/Shared/EmptyFeatureState";
import NotEnabledIcon from "../images/not-enabled.svg";
import { useFeatureFlag, useProjectId } from "hooks";
import ProviderComposer from "context/ProviderComposer";
import SheetsProvider from "context/SheetsProvider";
import SyntaxProvider from "context/SyntaxProvider";
import ParametersContainer from "components/Project/ParametersContainer";
import SheetTemplatesContainer from "components/Templates/SheetTemplates/SheetTemplatesContainer";
import { useTranslation } from "react-i18next";

const FastRoutes: React.FC = () => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const projectId = useProjectId();
    const { enabledCompanyFeatureFlags, enabledProjectFeatureFlags, loading } = useFeatureFlag();

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <Spinner animation="grow" />
            </div>
        );
    } else if (!enabledCompanyFeatureFlags.FAST) {
        return (
            <EmptyFeatureState
                title={t("status.product-not-enabled", { product: "FAST" })}
                description={t("status.get-access-admin")}
                icon={NotEnabledIcon}
            />
        );
    } else if (!enabledProjectFeatureFlags.FAST) {
        return (
            <EmptyFeatureState
                title={t("status.product-not-enabled-for-project", { product: "FAST" })}
                description={t("status.get-access-project-settings")}
                icon={NotEnabledIcon}
            />
        );
    } else {
        return (
            <ProviderComposer providers={[SheetsProvider, SyntaxProvider]} projectId={projectId}>
                <Switch>
                    <Route exact path={`${url}`}>
                        <FASTDashboard />
                    </Route>
                    <Route exact path={`${url}/sheets`}>
                        <SheetsContainer />
                    </Route>
                    <Route exact path={`${url}/locations`}>
                        <FastBuildingContainer />
                    </Route>
                    <Route exact path={`${url}/valuelists`}>
                        <ProjectValuePairListContainer />
                    </Route>
                    <Route exact path={`${url}/settings`}>
                        <FastSettingsContainer />
                    </Route>
                    <Route exact path={`${url}/templates`}>
                        <SheetTemplatesContainer />
                    </Route>
                    <Route exact path={`${url}/parameters`}>
                        <ParametersContainer />
                    </Route>
                </Switch>
            </ProviderComposer>
        );
    }
};

export default FastRoutes;
