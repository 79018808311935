import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form, Col, Row, Spinner } from "react-bootstrap";
import { BoxArrowUpRight, CheckCircleFill, Circle, GripVertical } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { SerialNumberConfig, SyntaxElement } from "types";
import { SyntaxContext } from "context";
import { useThemeValueLists } from "hooks";
import { SYNTAX_VIEW_MODEL, NamingTypes, SyntaxElementIds } from "_constants";
import { sortBy } from "lodash";
import SerialNumberConfiguratorModal from "./SerialNumberConfiguratorModal";

interface Props {
    nameSyntax: SyntaxElement[];
    serialNumberConfig?: SerialNumberConfig;
    selectedThemeValueListId?: string;
    handleSaveSerialNumberConfig?: (serialNumberConfig: SerialNumberConfig) => void;
    selectThemeValueList?: (id: string) => void;
    saveSyntaxElements: (elements: SyntaxElement[]) => Promise<void>;
    handleClose: () => void;
    handleReOpen: () => void;
    show: boolean;
}

const NamingSyntaxModal: React.FC<Props> = ({
    nameSyntax,
    selectedThemeValueListId,
    serialNumberConfig,
    handleSaveSerialNumberConfig,
    selectThemeValueList,
    saveSyntaxElements,
    handleClose,
    handleReOpen,
    show,
}) => {
    const { t } = useTranslation();
    const { displayPreview } = useContext(SyntaxContext);
    const [elements, setElements] = useState<SyntaxElement[]>(sortBy(nameSyntax, "priority") || []);
    const [showSerialNumberConfigurator, setShowSerialNumberConfigurator] = useState<boolean>(false);
    const { themeValueLists } = useThemeValueLists();
    const [isInvalid, setIsInvalid] = useState<string[]>([]);

    const namingType = elements && elements.length > 0 ? elements[0].namingType : undefined;

    const enabledElementIds = elements.filter(e => e.enabled).map(e => e.id);

    useEffect(() => {
        if (elements && elements.length === 0 && nameSyntax && nameSyntax.length > 0) {
            setElements(sortBy(nameSyntax, "priority"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameSyntax]);

    const handleToggle = (element: SyntaxElement) => {
        const newElements = [...elements];
        const index = newElements.findIndex(e => e.id === element.id);
        newElements[index].enabled = !newElements[index].enabled;
        setElements(newElements);

        //removes theme from invalid if it is disabled allowing user to save form
        if (
            element.id === SyntaxElementIds.THEMEVALUELIST &&
            element.enabled === false &&
            isInvalid.includes(SyntaxElementIds.THEMEVALUELIST)
        ) {
            setIsInvalid(isInvalid.filter(id => id !== SyntaxElementIds.THEMEVALUELIST));
        }
    };

    const validateFields = (): boolean => {
        const invalid = [];
        const selectedThemeValueIsValid = () => selectedThemeValueListId !== "";
        if (enabledElementIds.includes(SyntaxElementIds.THEMEVALUELIST)) {
            if (!selectedThemeValueIsValid()) {
                invalid.push("THEMEVALUELIST");
            }
        }
        if (invalid.length > 0) {
            setIsInvalid(invalid);
            return false;
        }
        return true;
    };

    const handleSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        const valid = validateFields();

        if (valid) {
            saveSyntaxElements(elements);
            handleClose();
        }
    };

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        const newElements = Array.from(elements);
        const [reorderedItem] = newElements.splice(result.source.index, 1);
        newElements.splice(result.destination.index, 0, reorderedItem);
        const updatedElements = newElements.map((e, i) => ({ ...e, priority: i + 1 }));
        setElements(updatedElements);
    };

    const handleCloseSerialNumberConfigurator = () => {
        setShowSerialNumberConfigurator(false);
        handleReOpen();
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{t("pages.shared.naming-syntax.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {elements && elements.length > 0 ? (
                        <>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="droppable">
                                    {provided => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {elements.map((element, index) => (
                                                <Draggable key={element?.id} draggableId={element?.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            className="card my-1"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            key={element?.id}
                                                            onClick={() => handleToggle(element)}
                                                        >
                                                            <div className="card-body py-2 m-0 d-flex justify-content-between">
                                                                <span>
                                                                    <GripVertical
                                                                        className="align-middle mx-0 me-2"
                                                                        color="grey"
                                                                    />
                                                                    {t(SYNTAX_VIEW_MODEL(element.id).translationId)}
                                                                </span>
                                                                {element.id === "SERIALNUMBER" && (
                                                                    <Button
                                                                        size="sm"
                                                                        variant="outline-primary"
                                                                        className="d-inline-flex align-items-center m-0 p-1 ms-1"
                                                                        onClick={e => {
                                                                            setShowSerialNumberConfigurator(true);
                                                                            handleClose();
                                                                            e.stopPropagation();
                                                                        }}
                                                                    >
                                                                        <BoxArrowUpRight className="align-middle mx-0 me-2" />
                                                                        {t("buttons.edit")}
                                                                    </Button>
                                                                )}
                                                                <span className="text-muted font-weight-ligher">
                                                                    ({SYNTAX_VIEW_MODEL(element.id).code})
                                                                    {element.enabled ? (
                                                                        <CheckCircleFill className="text-success ms-1" />
                                                                    ) : (
                                                                        <Circle className="text-dark ms-1" />
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <Form className="d-grid gap-2 mt-4">
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("pages.shared.naming-syntax.preview")}</Form.Label>
                                        <Form.Control readOnly value={displayPreview(elements)} />
                                    </Form.Group>
                                </Row>
                                {namingType === NamingTypes.SHEET && (
                                    <Row>
                                        <Form.Group as={Col} controlId="themeValueListId">
                                            <Form.Label>
                                                {t("pages.shared.naming-syntax.elements.theme-value-list")}
                                                {enabledElementIds.includes(SyntaxElementIds.THEMEVALUELIST) ? (
                                                    <span className="text-danger">*</span>
                                                ) : null}
                                            </Form.Label>
                                            <Form.Select
                                                disabled={!enabledElementIds.includes(SyntaxElementIds.THEMEVALUELIST)}
                                                isInvalid={isInvalid.includes("THEMEVALUELIST")}
                                                value={selectedThemeValueListId}
                                                onChange={e => {
                                                    if (isInvalid.includes("THEMEVALUELIST")) {
                                                        setIsInvalid(isInvalid.filter(i => i !== "THEMEVALUELIST"));
                                                    }
                                                    if (selectThemeValueList !== undefined) {
                                                        selectThemeValueList(e.target.value);
                                                    }
                                                }}
                                            >
                                                <option value="">{t("status.empty-value")}</option>
                                                {themeValueLists.map(themeValueList => (
                                                    <option value={themeValueList.id} key={themeValueList.id}>
                                                        {`${themeValueList.code} - ${themeValueList.name}`}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                {t("pages.shared.naming-syntax.elements.theme-value-list-help")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                )}
                            </Form>
                        </>
                    ) : (
                        <div className="text-center">
                            <Spinner animation="border" role="status" />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                    <Button disabled={isInvalid.length > 0} variant="primary" onClick={handleSubmit}>
                        {t("buttons.save")}
                    </Button>
                </Modal.Footer>
            </Modal>
            {serialNumberConfig !== undefined && (
                <SerialNumberConfiguratorModal
                    handleSave={(serialNumberConfig: SerialNumberConfig) => {
                        if (handleSaveSerialNumberConfig !== undefined) {
                            handleSaveSerialNumberConfig(serialNumberConfig);
                        }
                    }}
                    serialNumberConfig={serialNumberConfig}
                    handleClose={handleCloseSerialNumberConfigurator}
                    show={showSerialNumberConfigurator}
                />
            )}
        </>
    );
};

export default NamingSyntaxModal;
