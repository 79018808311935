import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Col, Row, Alert } from "react-bootstrap";
import { useThemeValueLists, useFormFields } from "hooks";
import { ThemeValuePair } from "types";
import { EMPTY_THEME_VALUE_PAIR } from "_constants";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";

interface Props {
    themeValuePair?: ThemeValuePair;
    handleClose: () => void;
    themeValueListId: string | undefined;
}

const ThemeValuePairModal: React.FC<Props> = ({ themeValuePair, themeValueListId, handleClose }) => {
    const { addThemeValuePair, editThemeValuePair } = useThemeValueLists();
    const { fields, handleFieldChange, setFields } = useFormFields<ThemeValuePair>(
        themeValuePair || EMPTY_THEME_VALUE_PAIR
    );
    const [codeIsInvalid, setCodeIsInvalid] = useState<boolean>(false);
    const [valueIsInvalid, setValueIsInvalid] = useState<boolean>(false);
    const isEditState = themeValuePair !== undefined;
    const noFormChanges = isEqual(themeValuePair, fields);
    const { t } = useTranslation();

    const codeRegex = /^(\d{1,3}\.){0,4}\d{1,3}$/;

    const handleSubmit = () => {
        if (themeValueListId === undefined) return;

        if (!codeRegex.test(fields.code)) {
            setCodeIsInvalid(true);
        }
        if (!fields.value) {
            setValueIsInvalid(true);
        }

        if (!codeRegex.test(fields.code) || !fields.value) return;

        if (isEditState) {
            editThemeValuePair(themeValueListId, fields);
        } else {
            addThemeValuePair(themeValueListId, fields);
        }
        handleClose();
    };

    useEffect(() => {
        if (!themeValuePair) return;
        setFields(themeValuePair);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeValuePair]);

    return (
        <Modal show={true} onHide={handleClose} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    {isEditState
                        ? `${t("pages.FAST.value-lists.theme-value-pairs.edit")} ${themeValuePair.value}`
                        : t("pages.FAST.value-lists.theme-value-pairs.create-new")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0 my-2">
                <Form>
                    <Row>
                        <Form.Group as={Col} controlId="code" className="flex-grow-0" md="12" lg="3">
                            <Form.Label>{t("pages.FAST.value-lists.theme-value-pairs.code")}</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.code}
                                onChange={e => {
                                    handleFieldChange(e);
                                    setCodeIsInvalid(false);
                                }}
                                isInvalid={codeIsInvalid}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="value">
                            <Form.Label>{t("pages.FAST.value-lists.theme-value-pairs.value")}</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.value}
                                onChange={e => {
                                    handleFieldChange(e);
                                    setValueIsInvalid(false);
                                }}
                                isInvalid={valueIsInvalid}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            {codeIsInvalid && (
                                <Alert variant="warning">
                                    {t("pages.FAST.value-lists.theme-value-pairs.code-invalid")}
                                </Alert>
                            )}
                            {valueIsInvalid && (
                                <Alert variant="warning">
                                    {t("pages.FAST.value-lists.theme-value-pairs.value-invalid")}
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("buttons.cancel")}
                </Button>
                <Button
                    variant="primary"
                    disabled={noFormChanges || codeIsInvalid || valueIsInvalid}
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    {isEditState ? t("buttons.save") : t("buttons.create")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ThemeValuePairModal;
