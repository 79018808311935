import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { LoggingContext } from "context";
import {
    OAuthProvider,
    onAuthStateChanged,
    setPersistence,
    signInWithPopup,
    signInWithEmailAndPassword,
    signOut,
    User as FirebaseUser,
    browserLocalPersistence,
    inMemoryPersistence,
    sendPasswordResetEmail,
} from "@firebase/auth";
import { auth, firestore } from "config";
import { doc, getDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

interface IAuthenticationContext {
    error: Error | null;
    handleSignIn: (email: string, password: string, rememberMe: boolean) => Promise<void>;
    handleSignOut: () => void;
    handleForgotPassword: (email: string) => void;
    handleMicrosoftLogin: () => void;
    loading: boolean;
    firebaseUser: FirebaseUser | null;
    getAccessToken: () => Promise<string | undefined>;
}

export const AuthenticationContext = createContext({} as IAuthenticationContext);

interface Props {
    children: ReactNode;
}

export const AuthProvider = ({ children }: Props): JSX.Element => {
    const [error, setError] = useState<Error | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(null);

    const { logger } = useContext(LoggingContext);

    const { t } = useTranslation();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async user => {
            if (user !== null) {
                setFirebaseUser(user);
            } else {
                setFirebaseUser(null);
            }
        });
        return () => {
            unsubscribe();
        };
    }, []);

    const handleSignIn = async (email: string, password: string, rememberMe: boolean) => {
        setError(null);
        setLoading(true);
        try {
            if (rememberMe) {
                await setPersistence(auth, browserLocalPersistence);
            } else {
                await setPersistence(auth, inMemoryPersistence);
            }

            const response = await signInWithEmailAndPassword(auth, email, password);
            const token = await response.user?.getIdToken();
            if (!token) {
                throw new Error("no token found for user");
            }
            toast.success(t("pages.login.status.login-success"));
        } catch (error) {
            throw new Error("Error signing in");
        }
        setLoading(false);
    };

    const handleMicrosoftLogin = async () => {
        setError(null);
        setLoading(true);

        const provider = new OAuthProvider("microsoft.com");
        try {
            //get accepted domains from firestore
            const acceptedDomainsRef = doc(firestore, "admin", "settings");
            const acceptedDomainsSnapshot = await getDoc(acceptedDomainsRef);
            const acceptedDomains = acceptedDomainsSnapshot.data()?.acceptedDomains;

            await setPersistence(auth, browserLocalPersistence);
            const result = await signInWithPopup(auth, provider);

            const domain = result.user.email?.split("@")[1];

            if (acceptedDomains === undefined) {
                await signOut(auth);
                throw new Error();
            }

            if (!acceptedDomains.includes(domain)) {
                await signOut(auth);
                throw new Error();
            }
        } catch (error) {
            setError(new Error(t("error.auth-login") as string));
        } finally {
            setLoading(false);
        }
    };

    const handleForgotPassword = async (email: string) => {
        setError(null);
        setLoading(true);
        try {
            await sendPasswordResetEmail(auth, email);
        } catch (error) {
            setError(new Error(t("error.auth-forgot-password") as string));
        }
        setLoading(false);
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            setError(new Error(t("error.auth-logout") as string));
            logger.error(t("error.auth-logout") as string, error);
        }
    };

    const getAccessToken = useCallback(async () => await firebaseUser?.getIdToken(), [firebaseUser]);

    return (
        <AuthenticationContext.Provider
            value={{
                error,
                handleSignIn,
                handleMicrosoftLogin,
                handleForgotPassword,
                handleSignOut,
                loading,
                firebaseUser,
                getAccessToken,
            }}
        >
            {children}
        </AuthenticationContext.Provider>
    );
};

export default AuthProvider;
