import { ListGroup } from 'react-bootstrap';
import { IDocumentTemplate } from 'types';
import { useContextModel } from 'hooks';

interface Props {
    template: IDocumentTemplate;
    handleClick: () => void;
}

const DocumentTemplateListItem = ({ template, handleClick }: Props): JSX.Element => {
    const { getDisciplineById, getKnowledgeById, getDocumentKindById, getDocumentKindCategoryById, loadingModel } = useContextModel()

    const knowledge = getKnowledgeById(template.knowledgeId);
    const discipline = getDisciplineById(template.disciplineId);
    const documentKind = getDocumentKindById(template.documentKindId);
    const documentKindCategory = getDocumentKindCategoryById(documentKind?.documentKindCategoryId);

    return (
        <ListGroup.Item action onClick={handleClick} className="d-flex justify-content-between align-items-center">
            <small className={"text-truncate"} style={{ width: "25%" }}>{template.name}</small>
            <small style={{ width: "25%" }}>{!loadingModel && `${knowledge.code}`}</small>
            <small style={{ width: "25%" }}>{!loadingModel && `${documentKindCategory.code}.${documentKind.subCode}`}</small>
            <small style={{ width: "25%" }}>{!loadingModel && `${discipline.code}`}</small>
        </ListGroup.Item>
    );
};

export default DocumentTemplateListItem;