import { useState } from "react";
import { Button } from "react-bootstrap";
import { Plus, Dash } from "react-bootstrap-icons";
import { useContextModel } from "hooks";
import { ISheetTemplate } from "types";
import { ToolTipWrapper } from "../ToolTip/ToolTip";
import { CheckboxCard, Line, LineItem } from "../Shared/Layout";

interface Props {
    sheetTemplate: ISheetTemplate;
    selected: boolean;
    handleSelectSheet: (sheetTemplate: ISheetTemplate, amount?: number, keepSelected?: boolean) => void;
}

const SheetCheckboxCardWithRange: React.FC<Props> = ({ sheetTemplate, selected, handleSelectSheet }) => {
    const [amount, setAmount] = useState(0);
    const { getDisciplineById } = useContextModel();

    const plusOneSheet = () => {
        setAmount(amount => amount + 1);
        handleSelectSheet(sheetTemplate, amount + 1, true);
    };

    const minusOneSheet = () => {
        if (amount >= 2) {
            setAmount(amount => amount - 1);
            handleSelectSheet(sheetTemplate, amount - 1, true);
        } else if (amount === 1) {
            setAmount(0);
            handleSelectSheet(sheetTemplate, 0, true);
        }
    };

    return (
        <CheckboxCard selected={amount > 0} noHoverPointer={true}>
            <Line>
                <LineItem className="user-select-none" grow>
                    {sheetTemplate.title}{" "}
                    <span className="text-muted font-weight-lighter">
                        ({getDisciplineById(sheetTemplate.disciplineId)?.code})
                    </span>
                </LineItem>
                <LineItem className="mx-3">
                    <Button
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            minusOneSheet();
                        }}
                        variant={"outline-primary"}
                        className="d-inline-flex align-items-center my-0 py-0 px-1"
                        size="sm"
                    >
                        <Dash />
                    </Button>
                    <ToolTipWrapper
                        placement="top"
                        id={`sheetTemplate.${sheetTemplate.id}`}
                        message="Number of sheets created"
                        eventId=""
                    >
                        <div className="d-inline-flex align-items-center mx-3">{amount}</div>
                    </ToolTipWrapper>
                    <Button
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            plusOneSheet();
                        }}
                        variant={"outline-primary"}
                        className="d-inline-flex align-items-center my-0 py-0 px-1"
                        size="sm"
                    >
                        <Plus />
                    </Button>
                </LineItem>
            </Line>
        </CheckboxCard>
    );
};

export default SheetCheckboxCardWithRange;
