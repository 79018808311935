import React from "react";
import { Form } from "react-bootstrap";
import { SewageSystem } from "types";

type Props<TSystemType> = {
    fields: TSystemType;
    setFields: React.Dispatch<React.SetStateAction<TSystemType>>;
};

const SewageForm: React.FC<Props<SewageSystem>> = ({ fields, setFields }) => <Form></Form>;

export default SewageForm;
