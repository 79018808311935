import React from "react";
import { Col, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useHover, useContextModel, useApplicationUI } from "hooks";
import { Project } from "types";
import { logEvent } from "config";
import { FastBadge, FlatBadge, BE18Badge } from "../Shared/Badges/FeatureBadges";

interface IProjectCard {
    project: Project;
}

const ProjectCard: React.FC<IProjectCard> = ({ project }) => {
    const { handleHideAppDrawer } = useApplicationUI();
    const hoverRef = React.useRef(null);
    const isHover = useHover(hoverRef);
    const { getPhaseById } = useContextModel();

    const history = useHistory();

    return (
        <Col
            ref={hoverRef}
            className="p-2"
            xl="3"
            lg="4"
            md="6"
            sm="12"
            xs="12"
            onClick={() => {
                history.push(`/projects/${project.id}`);
                handleHideAppDrawer();
                logEvent("project_select");
            }}
        >
            <Card
                className="transition-fast pointer shadow-sm"
                border={isHover ? "dark" : "primary"}
                bg={project.archived ? (isHover ? "light" : "flux-card-archive") : isHover ? "light" : undefined}
            >
                <Card.Body>
                    <Card.Title style={{ fontSize: "1rem" }} className="pb-0 mb-0">
                        <span className="text-truncate">{`${project.number} ${project.name.toUpperCase()}`}</span>
                    </Card.Title>
                    <Card.Subtitle style={{ fontSize: "0.8rem", fontWeight: 400 }}>
                        <div className="pb-0" style={{ paddingTop: "0.75rem" }}>
                            <span className="text-muted text-truncate">Internal Project ID: </span>
                            <span className="text-body text-truncate">{project.internalNumber}</span>
                        </div>
                    </Card.Subtitle>
                    <Card.Subtitle style={{ fontSize: "0.8rem", fontWeight: 400 }}>
                        <div className="pb-0" style={{ paddingTop: "0.75rem" }}>
                            <span className="text-muted text-truncate">Phase: </span>
                            <span className="text-body text-truncate">
                                {project.phaseId && getPhaseById(project.phaseId).value}
                            </span>
                        </div>
                    </Card.Subtitle>
                    <Card.Subtitle style={{ fontSize: "0.8rem", fontWeight: 400 }}>
                        <div className="pb-0" style={{ paddingTop: "0.75rem" }}>
                            <span className="text-muted text-truncate">
                                {`Tools: `}
                                {project.projectFeatures && (
                                    <>
                                        {project.projectFeatures.FAST && <FastBadge size={12} />}
                                        {project.projectFeatures.FLAT && <FlatBadge size={12} />}
                                        {project.projectFeatures.BE18 && <BE18Badge size={12} />}
                                    </>
                                )}
                            </span>
                        </div>
                    </Card.Subtitle>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default ProjectCard;
