import { useContext } from 'react';
import { AuthenticationContext } from 'context';
import { type User as FirebaseAuthUser } from 'firebase/auth';

type UseAuthProps = {
    user: FirebaseAuthUser | null
    getAccessToken: () => Promise<string | undefined>
}

export const useAuth = (): UseAuthProps => {
    const { firebaseUser, getAccessToken } = useContext(AuthenticationContext);

    return {
        user: firebaseUser,
        getAccessToken: getAccessToken,
    }
}