import React, { useEffect } from "react";
import { Container, Card, Col, Row, Button } from "react-bootstrap";
import { useCookies } from "react-cookie";

const CookieDisclaimer = (): JSX.Element | null => {
    const [cookies, setCookie] = useCookies(["acceptedDisclaimer"]);

    useEffect(() => {
        if (cookies.acceptedDisclaimer === undefined) {
            setCookie("acceptedDisclaimer", "false", { path: "/" });
        }
    }, [cookies, setCookie]);

    const handleAccept = () => {
        const date = new Date();
        date.setTime(date.getTime() + 2 * 365 * 24 * 60 * 60 * 1000);
        setCookie("acceptedDisclaimer", "true", { path: "/", expires: date });
    };

    if (cookies.acceptedDisclaimer === "true") {
        return null;
    }
    return (
        <Container className="fixed-bottom">
            <Row>
                <Col sm={12} md={10}>
                    <Card className="mb-2">
                        <Card.Body>
                            <Card.Title>This website uses cookies</Card.Title>
                            <Row>
                                <Card.Text sm={11} as={Col}>
                                    Flux AD uses cookies to provide necessary website functionality, improve your
                                    experience and analyze our traffic. By using our website, you agree to our{" "}
                                    <a
                                        href="https://www.flux-ad.dk/privacy-notice/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Notice
                                    </a>{" "}
                                    and our cookies usage.
                                </Card.Text>
                                <Col sm={1}>
                                    <Button variant="primary" onClick={handleAccept}>
                                        OK
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CookieDisclaimer;
