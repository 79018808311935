import {
    CellValueChangedEvent,
    ColDef,
    FirstDataRenderedEvent,
    GetContextMenuItemsParams,
    GetRowIdParams,
    GridOptions,
    GridReadyEvent,
    IRowNode,
    ValueGetterParams,
    ValueSetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { logEvent } from "config";
import { BuildingsContext, StoreysContext, SyntaxContext } from "context";
import { useAuth, useContextModel, usePreviewSheetsNumber, useProjectId } from "hooks";
import _ from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";
import { ISheet, Storey } from "types";
import { SheetService } from "services";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const useAgGrid = (
    gridRef: React.RefObject<AgGridReact>
): {
    gridReady: boolean;
    gridOptions: GridOptions;
    getSelectedRowIds: () => string[];
    onFilterTextBoxChanged: (searchString: string) => void;
    numSelectedRows: number;
} => {
    const projectId = useProjectId();
    const { contextModel, loadingModel } = useContextModel();
    const { loadingProject: loadingSyntaxElements } = useContext(SyntaxContext);
    const [gridReady, setGridReady] = useState<boolean>(false);
    const { buildings, loadingBuildings } = useContext(BuildingsContext);
    const { storeys, loadingStoreys } = useContext(StoreysContext);
    const { previewSheetNumber } = usePreviewSheetsNumber();
    const { getAccessToken } = useAuth();
    const [numSelectedRows, setNumSelectedRows] = useState<number>(0);
    const { t } = useTranslation();

    const knowledgeValueGetter = (params: ValueGetterParams) =>
        `${params.data.knowledgeCode} - ${params.data.knowledgeValue}`;
    const disciplineValueGetter = (params: ValueGetterParams) =>
        `${params.data.disciplineCode} - ${params.data.disciplineValue}`;
    const sheetTypeValueGetter = (params: ValueGetterParams) =>
        `${params.data.sheetTypeCode} - ${params.data.sheetTypeValue}`;
    const locationValueGetter = (params: ValueGetterParams) =>
        `${params.data.locationCode} - ${params.data.locationName}`;
    const storeyValueGetter = (params: ValueGetterParams) => `${params.data.storeyCode} - ${params.data.storeyName}`;
    const scaleValueGetter = (params: ValueGetterParams) =>
        `${params.data.documentClassIdISO5455Code} - ${params.data.documentClassIdISO5455Value}`;
    const businessTypeValueGetter = (params: ValueGetterParams) =>
        `${params.data.businessTypeCode} - ${params.data.businessTypeValue}`;

    const knowledgeValueSetter = (params: ValueSetterParams) => {
        const knowledge = params.newValue;
        const knowledgeCode = knowledge.split(" - ")[0];
        const knowledgeValue = knowledge.split(" - ")[1];
        const knowledgeId = contextModel.knowledges.find(k => k.code === knowledgeCode)?.id; // needs refactor cause code can be duplicated
        params.data.knowledgeCode = knowledgeCode;
        params.data.knowledgeValue = knowledgeValue;
        params.data.knowledgeId = knowledgeId;
        return true;
    };

    const businessTypeValueSetter = (params: ValueSetterParams) => {
        const businessType = params.newValue;
        const businessTypeCode = businessType.split(" - ")[0];
        const businessTypeValue = businessType.split(" - ")[1];
        const businessTypeId = contextModel.businessTypes.find(b => b.code === businessTypeCode)?.id; // needs refactor cause code can be duplicated
        params.data.businessTypeCode = businessTypeCode;
        params.data.businessTypeValue = businessTypeValue;
        params.data.businessTypeId = businessTypeId;
        return true;
    };

    const disciplineValueSetter = (params: ValueSetterParams) => {
        const discipline = params.newValue;
        const disciplineCode = discipline.split(" - ")[0];
        const disciplineValue = discipline.split(" - ")[1];
        const disciplineId = contextModel.disciplines.find(d => d.code === disciplineCode)?.id; // needs refactor cause code can be duplicated
        params.data.disciplineCode = disciplineCode;
        params.data.disciplineValue = disciplineValue;
        params.data.disciplineId = disciplineId;
        return true;
    };

    const sheetTypeValueSetter = (params: ValueSetterParams) => {
        const sheetType = params.newValue;
        const sheetTypeCode = sheetType.split(" - ")[0];
        const sheetTypeValue = sheetType.split(" - ")[1];
        const sheetTypeId = contextModel.sheetTypes.find(s => s.code === sheetTypeCode)?.id; // needs refactor cause code can be duplicated
        params.data.sheetTypeCode = sheetTypeCode;
        params.data.sheetTypeValue = sheetTypeValue;
        params.data.sheetTypeId = sheetTypeId;
        return true;
    };

    const locationValueSetter = (params: ValueSetterParams) => {
        const location = params.newValue;
        const locationCode = location.split(" - ")[0];
        const locationName = location.split(" - ")[1];
        const locationId = buildings.find(b => b.code === locationCode)?.id; // needs refactor cause code can be duplicated
        params.data.locationCode = locationCode;
        params.data.locationName = locationName;
        params.data.locationId = locationId;
        params.data.storeyCode = "";
        params.data.storeyName = "";
        params.data.storeyId = "";
        return true;
    };

    const storeyValueSetter = (params: ValueSetterParams) => {
        const storey = params.newValue;
        const storeyCode = storey.split(" - ")[0];
        const storeyName = storey.split(" - ")[1];
        const storeyId = storeys[params.data.locationId].find(s => s.code === storeyCode)?.id; // needs refactor cause code can be duplicated
        params.data.storeyCode = storeyCode;
        params.data.storeyName = storeyName;
        params.data.storeyId = storeyId;
        return true;
    };

    const scaleValueSetter = (params: ValueSetterParams) => {
        const scale = params.newValue;
        const scaleCode = scale.split(" - ")[0];
        const scaleValue = scale.split(" - ")[1];
        const scaleId = contextModel.outputScales.find(s => s.code === scaleCode)?.id; // needs refactor cause value code can be duplicated
        params.data.documentClassIdISO5455Code = scaleCode;
        params.data.documentClassIdISO5455Value = scaleValue;
        params.data.documentClassIdISO5455Id = scaleId;
        return true;
    };

    const documentNumberValueGetter = (params: ValueGetterParams) => previewSheetNumber(params.data);

    const documentNumberComparator = (
        valueA: string,
        valueB: string,
        //eslint-disable-next-line
        nodeA: IRowNode<any>,
        //eslint-disable-next-line
        nodeB: IRowNode<any>,
        isDescending: boolean
        //eslint-disable-next-line
    ): number => {
        const serialNumberA = nodeA.data.serialNumber;
        const serialNumberB = nodeB.data.serialNumber;

        if (serialNumberA === serialNumberB) return 0;
        return serialNumberA > serialNumberB ? 1 : -1;
    };

    useEffect(() => {
        if (loadingModel || loadingBuildings || loadingStoreys) return;
        if (!gridRef) return;

        const newColumns = gridRef.current?.api?.getColumnDefs();
        if (!newColumns) return;

        const fieldConfigurations: {
            [key: string]: { setter: (params: ValueSetterParams) => boolean; values: string[] };
        } = {
            "knowledgeId&knowledgeCode&knowledgeValue": {
                setter: knowledgeValueSetter,
                values: contextModel.knowledges.map(k => `${k.code} - ${k.value}`),
            },
            "disciplineId&disciplineCode&disciplineValue": {
                setter: disciplineValueSetter,
                values: contextModel.disciplines.map(d => `${d.code} - ${d.value}`),
            },
            "businessTypeId&businessTypeCode&businessTypeValue": {
                setter: businessTypeValueSetter,
                values: contextModel.businessTypes.map(b => `${b.code} - ${b.value}`),
            },
            "documentClassIdISO5455CodeId&documentClassIdISO5455Value": {
                setter: scaleValueSetter,
                values: contextModel.outputScales.map(d => `${d.code} - ${d.value}`),
            },
            "locationId&locationCode&locationName": {
                setter: locationValueSetter,
                values: buildings.map(b => `${b.code} - ${b.name}`),
            },
            "sheetTypeId&sheetTypeCode&sheetTypeValue": {
                setter: sheetTypeValueSetter,
                values: contextModel.sheetTypes.map(s => `${s.code} - ${s.value}`),
            },
        };

        newColumns.forEach((column: ColDef) => {
            if (!column.field) return;
            const config = fieldConfigurations[column.field];
            if (config) {
                column.valueSetter = config.setter;
                column.cellEditorParams = {
                    values: config.values,
                };
            } else if (column.field === "storeyId&storeyCode&storeyName") {
                column.valueSetter = storeyValueSetter;
                column.cellEditorParams = (params: ValueGetterParams) => {
                    const building = buildings?.find(b => b.code === params.data.locationCode);
                    if (!building || building === undefined) {
                        return {
                            values: ["No building selected"],
                        };
                    } else {
                        return {
                            values: _.orderBy(
                                storeys[building.id || 0].map((s: Storey) => `${s.code} - ${s.name}`),
                                ["sortPriority"],
                                ["asc"]
                            ),
                        };
                    }
                };
            }
        });

        gridRef.current?.api?.setColumnDefs(newColumns);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingModel, loadingBuildings, loadingStoreys, gridReady]);

    useEffect(() => {
        if (loadingSyntaxElements) return;
        if (!gridRef) return;

        const newColumns = gridRef.current?.api?.getColumnDefs();
        if (!newColumns) return;

        newColumns.forEach((column: ColDef) => {
            if (!column.headerName) return;
            if (column.headerName === "Document Number") {
                column.valueGetter = documentNumberValueGetter;
                column.comparator = documentNumberComparator;
            }
        });

        gridRef.current?.api?.setColumnDefs(newColumns);
        gridRef.current?.columnApi?.autoSizeAllColumns(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingModel, loadingBuildings, loadingStoreys, gridReady]);

    const onGridReady = (event: GridReadyEvent) => {
        setGridReady(true);
    };

    const onFirstDataRendered = useCallback((event: FirstDataRenderedEvent) => {
        event.columnApi.autoSizeAllColumns(false);
    }, []);

    const getContextMenuItems = useCallback(
        (params: GetContextMenuItemsParams) => ["copy", "copyWithHeaders", "paste", "separator", "export"],
        []
    );

    const handleCellValueChanged = (event: CellValueChangedEvent) => {
        submitUpdate(event.data);
    };

    const { mutateAsync: submitUpdate } = useMutation({
        mutationFn: async (data: ISheet) => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            return SheetService.updateSheet(projectId, data.id, data, accessToken);
        },
        onSuccess: () => {
            logEvent("sheet_save");
        },
    });

    const getSelectedRowIds = () => gridRef.current?.api?.getSelectedRows()?.map((row: ISheet) => row.id) || [];

    const onFilterTextBoxChanged = (searchString: string) => {
        gridRef.current?.api?.setQuickFilter(searchString);
    };

    const onSelectionChanged = () => {
        setNumSelectedRows(gridRef.current?.api?.getSelectedNodes().length || 0);
    };

    const colDefs: ColDef[] = [
        {
            filter: false, //temp fix for possible ag-grid bug, but no filtering on document number
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            headerCheckboxSelectionCurrentPageOnly: true,
            lockPosition: "left",
            cellClass: "locked-col ag-right-aligned-cell",
            headerName: "Document Number",
            cellDataType: "text",
            valueGetter: documentNumberValueGetter,
            editable: false,
            width: 200,
            comparator: documentNumberComparator,
        },
        { field: "title", cellDataType: "text" },
        {
            headerName: "Knowledge",
            field: "knowledgeId&knowledgeCode&knowledgeValue",
            valueGetter: knowledgeValueGetter,
            valueSetter: knowledgeValueSetter,
            cellDataType: "text",
            cellEditor: "agRichSelectCellEditor",
            cellEditorParams: {
                values: [t("messages.error-data")],
            },
            cellEditorPopup: true,
        },
        {
            headerName: "Discipline",
            field: "disciplineId&disciplineCode&disciplineValue",
            valueGetter: disciplineValueGetter,
            valueSetter: disciplineValueSetter,
            cellDataType: "text",
            cellEditor: "agRichSelectCellEditor",
            cellEditorParams: {
                values: [t("messages.error-data")],
            },
            cellEditorPopup: true,
        },
        {
            headerName: "Location",
            field: "locationId&locationCode&locationName",
            valueGetter: locationValueGetter,
            valueSetter: locationValueSetter,
            cellDataType: "text",
            cellEditor: "agRichSelectCellEditor",
            cellEditorParams: {
                values: [t("messages.error-data")],
            },
            cellEditorPopup: true,
        },
        {
            headerName: "Storey",
            field: "storeyId&storeyCode&storeyName",
            valueGetter: storeyValueGetter,
            valueSetter: storeyValueSetter,
            cellDataType: "text",
            cellEditor: "agRichSelectCellEditor",
            cellEditorParams: {
                values: [t("messages.error-data")],
            },
            cellEditorPopup: true,
        },
        {
            headerName: "Sheet Type",
            field: "sheetTypeId&sheetTypeCode&sheetTypeValue",
            valueGetter: sheetTypeValueGetter,
            valueSetter: sheetTypeValueSetter,
            cellDataType: "text",
            cellEditor: "agRichSelectCellEditor",
            cellEditorParams: {
                values: [t("messages.error-data")],
            },
            cellEditorPopup: true,
        },
        {
            headerName: "Business Type",
            field: "businessTypeId&businessTypeCode&businessTypeValue",
            valueGetter: businessTypeValueGetter,
            valueSetter: businessTypeValueSetter,
            cellDataType: "text",
            cellEditor: "agRichSelectCellEditor",
            cellEditorParams: {
                values: [t("messages.error-data")],
            },
            cellEditorPopup: true,
        },
        {
            headerName: "Scale",
            field: "documentClassIdISO5455CodeId&documentClassIdISO5455Value",
            valueGetter: scaleValueGetter,
            valueSetter: scaleValueSetter,
            cellDataType: "text",
            cellEditor: "agRichSelectCellEditor",
            cellEditorParams: {
                values: [t("messages.error-data")],
            },
            cellEditorPopup: true,
        },
        { field: "createDate", cellDataType: "dateString", editable: false, filter: "agDateColumnFilter" },
        { field: "designedBy", cellDataType: "text" },
        { field: "reviewedByPerson", headerName: "Reviewed by", cellDataType: "text" },
        { field: "approvedByPerson", headerName: "Approved by", cellDataType: "text" },
        { field: "serialNumber", cellDataType: "text", hide: true },
        { field: "locationScopebox", cellDataType: "text", hide: true },
        { field: "creatorName", cellDataType: "text", hide: true },
    ];

    const gridOptions: GridOptions = {
        onSelectionChanged: onSelectionChanged,
        onGridReady: onGridReady,
        onFirstDataRendered: onFirstDataRendered,
        getContextMenuItems: getContextMenuItems,
        onCellValueChanged: handleCellValueChanged,
        getRowId: (params: GetRowIdParams) => params.data.id,
        isGroupOpenByDefault: useCallback(() => true, []),
        allowContextMenuWithControlKey: true,
        groupDisplayType: "groupRows",
        rowGroupPanelShow: "onlyWhenGrouping",
        rowSelection: "multiple",
        groupSelectsChildren: true,
        groupSelectsFiltered: true,
        suppressRowClickSelection: true,
        multiSortKey: "ctrl",
        enableRangeSelection: true,
        suppressDragLeaveHidesColumns: true,
        animateRows: true,
        rowHeight: 40,
        headerHeight: 30,
        undoRedoCellEditing: true,
        undoRedoCellEditingLimit: 20,
        stopEditingWhenCellsLoseFocus: true,
        enableFillHandle: true,
        fillHandleDirection: "y",
        columnDefs: colDefs,
        defaultColDef: {
            unSortIcon: true,
            resizable: true,
            sortable: true,
            editable: true,
            enableRowGroup: true,
            filter: "agMultiColumnFilter",
        },
    };

    return {
        gridReady,
        gridOptions,
        onFilterTextBoxChanged,
        getSelectedRowIds,
        numSelectedRows,
    };
};
