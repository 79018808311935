import { FC } from "react";
import { ProgressBar } from "react-bootstrap";
import { SectionData } from "./Sections";
import { useTranslation } from "react-i18next";

type Props = {
    currentStep: number;
    sections: SectionData[];
    setStep: (id: number) => void;
};

const SectionProgressBar: FC<Props> = ({ currentStep, setStep, sections }) => {
    const lastStep = sections.length - 1;
    const progress = Math.max(2, currentStep / lastStep * 100) - 1
    const { t } = useTranslation();

    return (
        <div className="p-4 my-4">
            <ProgressBar now={progress} />
            <div className="d-flex justify-content-between">
                {sections.map((section, i) => (
                    <div
                        key={i}
                        onClick={() => setStep(i)}
                        className={`pointer text-center ${i === currentStep ? "" : "text-muted"}`}
                    >
                        {t(section.titleTranslationId)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SectionProgressBar;
