import { Storey, Building } from "types";
import { CONTENT_TYPES, UNKNOWN_BUILDING, LEVEL_STOREY } from "_constants";
import { isEqual } from "lodash";
import _ from "lodash";
import { t } from "i18next";

export const getBuilding = (buildings: Building[], buildingId: string): Building => {
    if (!buildingId) {
        return UNKNOWN_BUILDING;
    }
    const building = buildings.find(b => b.id === buildingId);
    if (building === undefined) return UNKNOWN_BUILDING;
    return building;
};

export const getStorey = (storeys: Storey[], storeyId: string): Storey => {
    const storey = storeys.find(s => s.id === storeyId);
    if (storey) return storey;
    const translatedUnknownStorey = { ...LEVEL_STOREY, name: t(LEVEL_STOREY.name) };
    return translatedUnknownStorey;
};

export const createEmptyArray = (length: number): number[] => [...Array(length).keys()];

export const createArrayOf = <T>(length: number, value: T): T[] =>
    createEmptyArray(length).map(_ => (typeof value === "function" ? value() : value));

export const isTouched = <T>(value: T) => value !== undefined && value !== "" && value !== 0;

export const toArrayBuffer = (buffer: Buffer): ArrayBuffer => {
    const arrayBuffer = new ArrayBuffer(buffer.length);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < buffer.length; ++i) {
        view[i] = buffer[i];
    }
    return arrayBuffer;
};

type Primitive = string | number | bigint | boolean | symbol | null | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapObjectValues = <T extends (...arg: any) => Primitive>(
    record: { [key: string]: unknown },
    func: T
): Record<string, ReturnType<T>> => {
    const obj: { [key: string]: ReturnType<T> } = {};
    for (const key of Object.keys(record)) {
        obj[key as string] = func(record[key]) as ReturnType<T>;
    }
    return obj;
};

export const sortByFunctionAndProperty =
    <
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        TFunc extends (arg0: string) => any
    >(
        sortFunction: TFunc,
        property: keyof ReturnType<TFunc>
    ): ((a: Parameters<TFunc>[number], b: Parameters<TFunc>[number]) => number) =>
    (a: Parameters<TFunc>[number], b: Parameters<TFunc>[number]) => {
        if (sortFunction(a)[property] > sortFunction(b)[property]) {
            return 1;
        }
        if (sortFunction(b)[property] > sortFunction(a)[property]) {
            return -1;
        }
        return 0;
    };

const convertToString = (value: number | boolean | string): string => {
    if (typeof value === "string") {
        const numberOrFloatPattern = /^([1-9]\d*(\.|,)\d*|0?(\.|,)\d*[1-9]\d*|[1-9]\d*)$/;
        if (numberOrFloatPattern.exec(value) === null) {
            return value;
        }
        return value.replace(".", ",");
    }

    if (typeof value === "number" || typeof value === "bigint") {
        return convertToString(`${value}`);
    }
    if (typeof value === "boolean") {
        return value ? "ja" : "nej";
    }

    return convertToString(`${value}`);
};

export const convertDecimalSymbol = <
    T extends Record<string, string | number | boolean | Record<string, string | number | boolean>[]>
>(
    fields: T
): Record<string, string | boolean | Record<string, string>[]> => {
    const result: Record<string, boolean | string | Record<string, string>[]> = {};
    for (const [key, value] of Object.entries(fields)) {
        const isArray = Array.isArray(value);
        if (isArray) {
            const array = value.map(item => mapObjectValues(item, convertToString));
            result[key] = array;
            continue;
        }
        const keyPrefix = key.substring(0, 3).toLocaleLowerCase();
        if (keyPrefix.includes("has") || keyPrefix.includes("is")) {
            result[key] = value as boolean;
            continue;
        }
        const txt = convertToString(value);
        result[key] = txt;
    }
    return result;
};

export const filterArchived = <T extends { archived?: boolean }>(items: T[]): T[] =>
    items.filter(item => !item.archived);

/**
 * creates an object based on a collection of objects,
 * if a values of the same key are equal the value is retained,
 * if values differ the value is replaced with undefined
 */
export const oneBasedOnCollection = <TObject extends object>(collection: TObject[]): TObject | undefined => {
    if (collection.length === 0) return undefined;
    const entries = Object.keys(collection[0]).map(key => {
        const values = collection.map(item => (item[key as keyof TObject] ? item[key as keyof TObject] : ""));
        const uniqueValues = [...new Set(values)];
        // if (uniqueValues.length > 1) return [key, uniqueValues.join(',')]
        if (uniqueValues.length > 1) return [key, undefined];
        return [key, uniqueValues[0]];
    });
    return Object.fromEntries(entries);
};

/**
 * checks collections of objects and return array of key names where values are different
 */
export const keysDifference = <TObject extends object>(collection: TObject[]): string[] => {
    if (collection.length === 0) return [];
    const keys: string[] = [];
    Object.keys(collection[0]).forEach(key => {
        const values = collection.map(item => (item[key as keyof TObject] ? item[key as keyof TObject] : ""));
        const uniqueValues = [...new Set(values)];
        if (uniqueValues.length > 1) {
            keys.push(key);
        }
    });
    return keys;
};

/**
 * Creates an object based on base object, using fallback when key doesnt exist in base or is unknown
 */
export const useDefault = <TObject extends object>(base: Partial<TObject> | undefined, fallback: TObject): TObject => {
    if (base === undefined) return fallback;
    const entries = Object.keys(fallback).map(key => {
        if (Object.keys(base).includes(key) && base[key as keyof TObject] === undefined)
            return [key, fallback[key as keyof TObject]];
        return [key, base[key as keyof TObject]];
    });
    return Object.fromEntries(entries);
};

type ObjectKeys<T> =
    // eslint-disable-next-line @typescript-eslint/ban-types
    T extends object ? (keyof T)[] : T extends number ? [] : T extends Array<unknown> | string ? string[] : never;

export const objectKeys = <TObject extends object>(object: TObject): ObjectKeys<TObject> =>
    Object.keys(object) as ObjectKeys<TObject>;

export const downloadBlob = async (data: unknown, filename: string) => {
    let file = new File([data as BlobPart], filename);

    let a = document.createElement("a");
    document.body.appendChild(a);

    const filenameParts = filename.split(".");
    const extension = filenameParts[filenameParts.length - 1];
    const type = CONTENT_TYPES[extension];
    let blob = new Blob([await file.arrayBuffer()], {
        type: type,
    });

    let generatedReportUrl = window.URL.createObjectURL(blob);

    a.href = generatedReportUrl;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(generatedReportUrl);
};

export const onFileInput = (_file: File, func: (raw: string) => void, utf = true) => {
    const reader = new FileReader();
    reader.readAsText(_file, utf ? "UTF-8" : "Latin1");
    reader.onload = () => {
        const result = reader.result;
        if (utf && (result as string).includes("�")) {
            onFileInput(_file, func, false);
        } else {
            func(result as string);
        }
    };
};

export const getObjectDiff = <TObject extends object>(obj1: TObject, obj2: TObject): (keyof TObject)[] => {
    const keys = Object.keys(obj1) as (keyof TObject)[];
    const diff = keys.map(key => (`${obj1[key]}` !== `${obj2[key]}` ? (key as keyof TObject) : undefined));
    return diff.filter(key => key !== undefined) as (keyof TObject)[];
};
