import React, { useContext, useState } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { IDocument } from "types";
import { DocumentsContext } from "context";
import { useContextModel } from "hooks";
import DocumentEditorModal from "./DocumentModal";
import DocumentsListRow from "./DocumentsListRow";
import _ from "lodash";

const DocumentsList: React.FC = () => {
    const { getKnowledgeById } = useContextModel();
    const [showDocumentEditorModal, setShowDocumentEditorModal] = useState(false);
    const [selected, setSelected] = useState<IDocument | null>(null);
    const { documents } = useContext(DocumentsContext);

    const handleOpenModal = (id: string) => {
        setSelected(documents.filter(document => document.id === id)[0]);
        setShowDocumentEditorModal(true);
    };

    const handleCloseModal = () => {
        setShowDocumentEditorModal(false);
    };

    const uniqueDocumentKnowledge = [...new Set(documents.map(document => document.knowledgeId))];

    return (
        <>
            {selected !== null && (
                <DocumentEditorModal
                    show={showDocumentEditorModal}
                    document={selected}
                    handleClose={handleCloseModal}
                />
            )}
            <ListGroup>
                {uniqueDocumentKnowledge.map(knowledge => (
                    <React.Fragment key={knowledge}>
                        <ListGroupItem variant="primary">
                            {getKnowledgeById(knowledge)?.code} - {getKnowledgeById(knowledge)?.value}
                        </ListGroupItem>

                        {_.sortBy(
                            documents.filter(document => document.knowledgeId === knowledge),
                            "title"
                        ).map(document => (
                            <DocumentsListRow key={document.id} document={document} handleClick={handleOpenModal} />
                        ))}
                    </React.Fragment>
                ))}
            </ListGroup>
        </>
    );
};

export default DocumentsList;
