import React, { ReactNode, useEffect, useState, HTMLAttributes } from "react";
import { Alert, Col, Container, ContainerProps, Fade, Row, Spinner } from "react-bootstrap";
import { ExclamationTriangleFill, InfoCircle } from "react-bootstrap-icons";

const DISMISS_TIMEOUT = 45 * 1000;

interface IErrorAlert {
    show: boolean;
    message: string;
}
export const ErrorAlert = ({ show, message }: IErrorAlert): JSX.Element => {
    const [visible, setVisible] = useState<boolean>(show);

    useEffect(() => {
        let mounted = true;
        if (show) {
            setTimeout(() => {
                if (!mounted) return;
                setVisible(false);
            }, DISMISS_TIMEOUT);
        }
        if (!show) {
            setVisible(false);
        }
        return () => {
            mounted = false;
        };
    }, [show]);

    return (
        <Alert
            show={visible}
            variant="danger"
            onClose={() => setVisible(false)}
            dismissible
            transition={Fade}
        >
            <Alert.Heading as={"h5"}>
                <ExclamationTriangleFill className="mb-1" size="32" /> Fejl
            </Alert.Heading>
            {message}
        </Alert>
    );
};

interface ILoadingAlert {
    message: string;
}
export const LoadingAlert = ({ message }: ILoadingAlert): JSX.Element => (
    <Alert variant="secondary">
        <Alert.Heading as={"h5"}>
            <Spinner animation="grow" /> Indlæser
        </Alert.Heading>
        {message}
    </Alert>
);

interface IStatusAlert {
    show: boolean;
    title: string;
    message: string;
}
export const StatusAlert = ({
    show,
    title,
    message,
}: IStatusAlert): JSX.Element => {
    const [visible, setVisible] = useState<boolean>(show);

    useEffect(() => {
        if (show) {
            setTimeout(() => setVisible(false), DISMISS_TIMEOUT);
        }
        if (!show) {
            setVisible(false);
        }
    }, [show]);

    return (
        <Alert show={visible} variant="info">
            <Alert.Heading as={"h5"}>
                <InfoCircle /> {title}
            </Alert.Heading>
            {message}
        </Alert>
    );
};

interface INotificationWrapper {
    children: ReactNode;
}
export const NotificationWrapper = ({
    children,
}: INotificationWrapper): JSX.Element => (
    <div className="fixed-bottom">
        <Container fluid>
            <Row className="justify-content-end">
                <Col sm={5} xs={10}>
                    {children}
                </Col>
            </Row>
        </Container>
    </div>
);

interface IWithStatusAlertHandling extends HTMLAttributes<HTMLElement> {
    error?: Error | null;
    loading?: boolean;
    loadingMessage?: string;
    status?: boolean;
    statusTitle?: string;
    statusMessage?: string;
    children?: ReactNode;
}

export const withStatusAlertHandling = <T extends IWithStatusAlertHandling>(
    WrappedComponent: React.ComponentType<T>
) =>
    ({
        error,
        loading,
        loadingMessage = "",
        status,
        statusTitle = "",
        statusMessage = "",
        children,
        ...props
    }: T & IWithStatusAlertHandling) => {
        const visible = error || loading || status;
        return (
            <WrappedComponent {...(props as T)}>
                {visible && (
                    <NotificationWrapper>
                        {error && <ErrorAlert show message={error.message} />}
                        {loading && <LoadingAlert message={loadingMessage} />}
                        {status && (
                            <StatusAlert show title={statusTitle} message={statusMessage} />
                        )}
                    </NotificationWrapper>
                )}
                {children}
            </WrappedComponent>
        );
    };

export const ContainerWithStatusAlertHandling = withStatusAlertHandling<ContainerProps>(
    ({ children, ...props }) => (
        <Container {...props}>{children}</Container>
    )
);

export const DivWithStatusAlertHandling = withStatusAlertHandling<HTMLAttributes<HTMLElement>>(
    ({ children, ...props }) => (
        <div {...props}>{children}</div>
    )
);
