import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { logEvent } from "config";
import ConfirmModal from "../Shared/ConfirmModal";
import { CompanyContext } from "context";

interface Props {
    companyId: string;
}

const RemoveLogoButton = ({ companyId }: Props): JSX.Element => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const { deleteCompanyLogo } = useContext(CompanyContext);

    const handleClick = () => {
        setDisabled(true);
        deleteCompanyLogo();
    };

    return (
        <>
            <Button
                className="mt-2 mx-0 p-0"
                variant="link"
                onClick={() => setShowConfirmModal(true)}
                disabled={disabled}
            >
                Remove
            </Button>
            <ConfirmModal
                show={showConfirmModal}
                handleAction={() => {
                    setShowConfirmModal(false);
                    logEvent("company_delete_logo");
                    handleClick();
                }}
                handleClose={() => setShowConfirmModal(false)}
                description="Er du sikker på du vil slette Virksomhedslogo?"
                confirmText="Ja, slet logo"
            />
        </>
    );
};

export default RemoveLogoButton;
