import React, { useState, HTMLAttributes, DragEvent, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<HTMLBaseElement> {
    label?: string;
    fileFormat?: string;
    fileSize?: number | string;
    handleDrop: (e: DragEvent<HTMLDivElement>) => void;
    handleChange?: (e: ChangeEvent<HTMLDivElement>) => void;
    file: File | null;
}

const FileInputForm: React.FC<Props> = ({ label, fileFormat, fileSize, handleDrop, file }) => {
    const [dragActive, setDragActive] = useState(false);
    const { t } = useTranslation();

    const handleDrag = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const _handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        handleDrop(e);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        handleChange(e);
    };

    return (
        <div>
            {label === undefined || label === "" ? null : (
                <label className="d-block" style={{ fontWeight: 500 }}>
                    {label}
                </label>
            )}
            <div
                className={`mt-1 d-flex justify-content-center rounded-lg border border${
                    dragActive ? "-primary bg-light" : file ? "-info" : "-light"
                } p-3 `}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={_handleDrop}
            >
                <div className="space-y-1 text-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className={`mx-auto text-gray-${file ? 900 : 400}`}
                        style={{ width: "3rem", height: "3rem" }}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1}
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                        />
                    </svg>

                    {file === null ? (
                        <div>
                            <div className="d-flex text-muted">
                                <label
                                    htmlFor="file-upload"
                                    className="d-relative cursor-pointer rounded-lg bg-transparent text-primary"
                                >
                                    {/* <span><small>Vælg en fil</small></span> */}
                                    <input
                                        id="file-upload"
                                        name="file-upload"
                                        type="file"
                                        multiple={false}
                                        accept={`.${fileFormat}`}
                                        className="sr-only"
                                        style={{ display: "none" }}
                                        onChange={handleChange}
                                        // onClick={(event: ChangeEvent<HTMLInputElement>)=> {
                                        //     event.target.value = null
                                        // }}
                                    />
                                </label>
                                <p className="ps-1">
                                    <small>{t("actions.drag-n-drop-file")}</small>
                                </p>
                            </div>
                            <p>
                                {fileFormat === undefined || fileFormat === ""
                                    ? t("form.all-formats")
                                    : `.${fileFormat} filer`}{" "}
                                {fileSize === undefined || fileSize === ""
                                    ? null
                                    : t("form.file-max-size", { size: fileSize })}
                            </p>
                        </div>
                    ) : (
                        <div>
                            <div className="flex">
                                <p className="ps-1">
                                    <small>{file.name}</small>
                                </p>
                            </div>
                            <p>
                                {file.type} - {Math.round(file.size / 1000)} KB
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FileInputForm;
