import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAuth, useProjectId } from "hooks";
import TableContainer from "./TableContainer";
import { useQuery } from "@tanstack/react-query";
import { getProjectDataCheck } from "services/ProjectServices";
import toast from "react-hot-toast";

const SheetsContainer: React.FC = () => {
    const { t } = useTranslation();
    const projectId = useProjectId();
    const { getAccessToken } = useAuth();

    const { data, isLoading } = useQuery<{ sheets: number; buildings: number }>({
        queryKey: ["projectDataCheck", projectId],
        queryFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            return getProjectDataCheck(projectId, accessToken);
        },
        onError: () => {
            toast.error(t("messages.error.text"));
        },
    });

    const buildingsExist = (data?.buildings || 0) > 0;

    return (
        <Container fluid className="m-0 p-4 h-100 d-flex flex-column" style={{ overflowY: "hidden" }}>
            <Row>
                <Col>
                    <h1>{t("pages.FAST.sheet-list.title")}</h1>
                    <h6 className="text-muted">{t("pages.FAST.sheet-list.subtitle")}</h6>
                </Col>
            </Row>
            {!isLoading && <TableContainer buildingsExist={buildingsExist} projectId={projectId} />}
        </Container>
    );
};

export default SheetsContainer;
