import React, { ReactNode } from 'react';
import { Button, Card } from 'react-bootstrap';
import { CheckCircleFill, Circle } from 'react-bootstrap-icons';
import { useHover } from 'hooks';
import { IDocumentTemplate } from 'types';

interface ICardRow {
    children: ReactNode[];
}

interface ICardRowItem {
    grow?: boolean;
    className: string;
    children: ReactNode;
}

interface IDocumentCheckbox {
    document: IDocumentTemplate;
    selected: boolean;
    handleClick: (selected: boolean, document: IDocumentTemplate) => void;
}

const CardRow = ({ children }: ICardRow): JSX.Element => <div className="d-flex">{children}</div>;
const CardRowItem = ({ grow, className, children }: ICardRowItem): JSX.Element => (
    <div className={grow ? [className, 'flex-grow-1'].join(' ') : className}>{children}</div>
);

export const DocumentCheckbox = ({ document, selected, handleClick }: IDocumentCheckbox): JSX.Element => {
    const hoverRef = React.useRef(null);
    const isHover = useHover(hoverRef);
    return (
        <Card
            ref={hoverRef}
            className="my-1 transition pointer"
            onClick={() => handleClick(!selected, document)}
            border={selected ? 'primary' : undefined}
            bg={isHover ? 'light' : undefined}
        >
            <Card.Body>
                <CardRow>
                    <CardRowItem grow className="user-select-none">
                        {document.name}
                    </CardRowItem>
                    <CardRowItem className="mx-3">
                        <Button
                            onClick={event => {
                                event.stopPropagation();
                                handleClick(!selected, document);
                            }}
                            variant="link"
                            className="d-inline-flex align-items-center m-0 p-0"
                        >
                            {selected ? <CheckCircleFill className="text-success" /> : <Circle className="text-dark" />}
                        </Button>
                    </CardRowItem>
                </CardRow>
            </Card.Body>
        </Card>
    );
};
