import  { useContext, useState } from 'react';
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword as firebaseUpdatePassword } from "@firebase/auth";
import { UserContext, LoggingContext } from 'context';
import { type User } from 'types';
import { type AuthError } from 'firebase/auth';
import { API_URL } from 'config';
import axios from 'axios';
import { useAuth } from './use-auth';

type UseUserProps = {
    isAdmin: boolean;
    user: User,
    updateUser: (userData: Partial<User>) => Promise<unknown>;
    error: Error | null;
    updatePassword: (currentPassword: string, newPassword: string) => Promise<unknown>;
}

export const useUser = () : UseUserProps => {
    const [error, setError] = useState<Error | AuthError | null>(null)
    const { user } = useContext(UserContext);
    const { logger } = useContext(LoggingContext);
    const { user: firebaseUser, getAccessToken } = useAuth();

    const updateUser = async (userData: Partial<User>) : Promise<unknown> => {
        if (firebaseUser === null) return;
        const url = `${API_URL}/v1/users/${firebaseUser.uid}`
        try {
            const accessToken = await getAccessToken();
            if (accessToken === undefined) {
                throw new Error("Failed to get access token");
            }
            const res = await axios.put(url, { user: userData }, { headers: { 'Authorization': `token ${accessToken}` } });
            return res;
        } catch (e) {
            setError(e as Error)
        }
    }

    const updatePassword = (currentPassword: string, newPassword: string): Promise<unknown> => {
        const promise = new Promise((resolve, reject) => {
            if (firebaseUser === null) return;
            const { email } = firebaseUser;
            const credentials = EmailAuthProvider.credential(email!, currentPassword)
            reauthenticateWithCredential(
                firebaseUser,
                credentials
            ).then(result => {
                if (newPassword !== "") {
                    firebaseUpdatePassword(firebaseUser, newPassword).then(success => {
                        logger.log(`UserId: ${firebaseUser.uid} updated password`);
                        resolve(true)
                    });
                }
            }).catch(err => {
                reject(false);
                setError(err as AuthError);
                logger.error("Failed to update password", err);
            })
        })
        return promise;
    }

    const defaultUser = {
        id: firebaseUser?.uid || '',
        role: "user",
        name: '',
        initials: '',
        viewTemplateName: '',
        company: '',
        email: '',
        ...user,
    } as User;

    return {
        user: defaultUser,
        updateUser, 
        error, 
        updatePassword,
        isAdmin: user?.role === "admin",
    }
}