import React, { useContext } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import FooterContainer from "../components/Footer/FooterContainer";
import ProjectsContainer from "../components/ProjectsOverview/ProjectsContainer";
import ProjectRoutes from "./ProjectRoutes";
import ChangeLogContainer from "../components/ChangeLog/ChangeLogContainer";
import CompanyRoutes from "./CompanyRoutes";
import AppNavigationDrawer from "../components/NavigationBar/AppNavigationDrawer";
import ProjectNavigationDrawer from "../components/NavigationBar/ProjectNavigationDrawer";
import CompanyNavigationDrawer from "../components/NavigationBar/CompanyNavigationDrawer";
import TopNavigationBar from "../components/NavigationBar/TopNavigationBar";
import HomePageContainer from "../components/HomePage/HomePageContainer";
import { AuthenticationContext } from "context";

const ProtectedRoutes: React.FC = () => {
    const { firebaseUser } = useContext(AuthenticationContext);
    const isRestricted = !firebaseUser;
    const history = useHistory();
    const currentPath = history.location.pathname;
    const isProjectPage = currentPath.includes("/projects/");
    const isCompanyPage = currentPath.includes("/company");

    return (
        <div className="w-100 h-100 position-absolute" style={{ overflowY: "hidden" }}>
            <div className="d-flex h-100">
                <AppNavigationDrawer />
                <div className="flex-grow-1 h-100">
                    <div className="d-flex w-100 h-100">
                        {isProjectPage && !isRestricted && <ProjectNavigationDrawer />}
                        {isCompanyPage && !isRestricted && <CompanyNavigationDrawer />}
                        <div className="d-flex flex-column w-100 h-100" style={{ overflow: "hidden" }}>
                            <TopNavigationBar />
                            <div className="flex-grow-1 main-view">
                                <Switch>
                                    <Route exact path="/">
                                        <HomePageContainer />
                                    </Route>
                                    <Route exact path="/projects">
                                        <ProjectsContainer />
                                    </Route>
                                    <Route path="/projects/:projectId">
                                        <ProjectRoutes />
                                    </Route>
                                    <Route path="/company">
                                        <CompanyRoutes />
                                    </Route>
                                    <Route path="/change-log">
                                        <ChangeLogContainer />
                                    </Route>
                                </Switch>
                            </div>
                            <FooterContainer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ProtectedRoutes;
