import React, { useContext, useState } from "react";
import { Alert, Button, Col, Row, Container } from "react-bootstrap";
import { ShaftsContext } from "context";
import { IShaftType } from "types";
import { EMPTY_SHAFT } from "_constants";
import ShaftCard from "./ShaftCard";
import ShaftResultContainer from "./ShaftResultContainer";
import ShaftModal from "../ShaftModal";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { SystemService } from "services/SystemService";
import { useProjectId, useAuth } from "hooks";
import toast from "react-hot-toast";

const CalculationContainer: React.FC = () => {
    const { t } = useTranslation();
    const projectId = useProjectId();
    const { getAccessToken } = useAuth();
    const { data: systems, isLoading: loadingSystems } = useQuery({
        queryKey: ["systems"],
        queryFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) {
                throw new Error("No access token found");
            }
            return SystemService.getSystems(projectId, accessToken);
        },
        enabled: !!projectId,
        select: response => response.data,
        onError: () => {
            toast.error(t("pages.FLAT.systems.error-loading-systems"));
        },
    });

    const { shafts, handleCreateShaft } = useContext(ShaftsContext);
    const [selectedShaftId, setSelectedShaftId] = useState<string>("");
    const [showShaftModal, setShowShaftModal] = useState<boolean>(false);

    const systemsAreMissing = (systems || []).length === 0;
    const selectedShaft = shafts.find(shaft => shaft.id === selectedShaftId) || EMPTY_SHAFT;

    const handleSelectShaftCard = (shaft: IShaftType) => {
        if (shaft.id === selectedShaft.id) return;
        setSelectedShaftId(shaft.id);
    };

    const handleDuplicateShaft = (shaft: IShaftType) => {
        const shaftCopy = {
            ...shaft,
            shaftCalculations: [],
            name: `${shaft.name} ${t("pages.FLAT.shafts.shaft-copy")}`,
        };
        handleCreateShaft(shaftCopy);
    };

    const handleCreateShaftModal = () => {
        setSelectedShaftId("");
        setShowShaftModal(true);
    };

    const handleEditShaftModal = (shaft: IShaftType) => {
        setSelectedShaftId(shaft.id);
        setShowShaftModal(true);
    };

    return (
        <Container fluid className="m-0 p-4 h-100" style={{ overflow: "auto" }}>
            <Row className="h-100">
                <Col xs={3}>
                    <div>
                        <h1>{t("pages.FLAT.shafts.title")}</h1>
                        <Button className="mb-2" onClick={handleCreateShaftModal}>
                            {t("pages.FLAT.shafts.button-create-shaft")}
                        </Button>
                        {systemsAreMissing && !loadingSystems && (
                            <Alert variant="warning">{t("pages.FLAT.shafts.warning-system")}</Alert>
                        )}
                        {shafts &&
                            shafts.map(shaft => (
                                <ShaftCard
                                    key={shaft.id}
                                    shaft={shaft}
                                    handleClick={() => handleSelectShaftCard(shaft)}
                                    handleDuplicateShaft={() => handleDuplicateShaft(shaft)}
                                    handleEditShaft={() => handleEditShaftModal(shaft)}
                                    isSelected={shaft.id === selectedShaft.id}
                                />
                            ))}
                    </div>
                </Col>
                <Col xs={9} className="border-left">
                    {selectedShaft.id !== "" && (
                        <>
                            <ShaftResultContainer shaft={selectedShaft} />
                        </>
                    )}
                </Col>
            </Row>
            {showShaftModal && <ShaftModal handleClose={() => setShowShaftModal(false)} shaft={selectedShaft} />}
        </Container>
    );
};

export default CalculationContainer;
