import React, { createContext, useState, ReactNode, useEffect } from "react";
import { User } from "types";
import axios from "axios";
import { doc, onSnapshot } from "@firebase/firestore";
import { API_URL, firestore } from "config";
import { useAuth } from "hooks";

interface IUserContext {
    error: Error | null;
    userCreated: boolean;
    user: User | null;
    loading: boolean;
    status: string;
    addProjectToUser: (userId: string, projectId: string) => Promise<void>;
    setUserCreated: (created: boolean) => void;
    selectedTool: string;
    setSelectedTool: (tool: string) => void;
    removeUserFromProject: (projectId: string, userId: string) => Promise<void>;
    userRemoved: boolean;
}

export const UserContext = createContext({} as IUserContext);

interface Props {
    children: ReactNode;
}

export const UserProvider = ({ children }: Props): JSX.Element => {
    const [error, setError] = useState<Error | null>(null);
    const [status, setStatus] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [userCreated, setUserCreated] = useState(false);
    const [userRemoved, setUserRemoved] = useState(false);
    const { user: firebaseUser, getAccessToken } = useAuth();
    const [selectedTool, setSelectedTool] = useState<string>("AUTO_SHEETS");
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const createSSOUser = async (email: string, displayName: string, uid: string) => {
            setLoading(true);
            const requestUrl = `${API_URL}/v1/users/createFirestoreUser`;
            const requestJson = JSON.stringify({ email, displayName, uid });
            const token = await getAccessToken();

            try {
                if (!token) throw new Error("No access token");

                await axios.post(requestUrl, requestJson, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                });
                setLoading(false);
            } catch (error) {
                setError(error as Error);
            }
        };

        if (!firebaseUser) return;
        setLoading(true);
        const unsubscribe = onSnapshot(doc(firestore, "users", firebaseUser.uid), doc => {
            if (doc.exists()) {
                setUser(doc.data() as User);
            } else {
                if (firebaseUser.email !== null && firebaseUser.displayName !== null) {
                    createSSOUser(firebaseUser.email, firebaseUser.displayName, firebaseUser.uid);
                }
            }
            setLoading(false);
        });
        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firebaseUser]);

    const addProjectToUser = async (userId: string, projectId: string) => {
        // TODO: move to project provider
        setStatus("");
        setLoading(true);

        const requestUrl = `${API_URL}/v1/projects/${projectId}/users`;
        const requestJson = JSON.stringify({ userId });

        try {
            const token = await getAccessToken();
            if (token === undefined) {
                throw new Error("No access token");
            }
            await axios.post(requestUrl, requestJson, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            setStatus("Brugeren er blevet tilføjet");
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error as Error);
        }
    };

    const removeUserFromProject = async (projectId: string, userId: string) => {
        // TODO: move to project provider
        setLoading(true);

        const requestUrl = `${API_URL}/v1/projects/${projectId}/users/${userId}`;

        try {
            const token = await getAccessToken();
            if (token === undefined) {
                throw new Error("No access token");
            }
            await axios.delete(requestUrl, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            setLoading(false);
            setUserRemoved(true);
        } catch (error) {
            setLoading(false);
            setUserRemoved(false);
            setError(error as Error);
        }
    };

    return (
        <UserContext.Provider
            value={{
                setUserCreated,
                userCreated,
                addProjectToUser,
                selectedTool,
                setSelectedTool,
                error,
                loading,
                removeUserFromProject,
                userRemoved,
                status,
                user,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
