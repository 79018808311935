import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ProjectUsersContainer from "../components/Project/ProjectUsersContainer";
import ProjectSettingsContainer from "../components/Project/ProjectSettingsContainer";
import Be18Routes from "./Be18Routes";
import FlatRoutes from "./FlatRoutes";
import FastRoutes from "./FastRoutes";
import ProjectDashboard from "../components/Project/ProjectDashboard";
import BuildingsProvider from "context/BuildingsProvider";
import ProviderComposer from "context/ProviderComposer";
import StoreysProvider from "context/StoreysProvider";
import { useProjectId } from "hooks";

const ProjectRoutes: React.FC = () => {
    const { url } = useRouteMatch();
    const projectId = useProjectId();
    return (
        <ProviderComposer
            projectId={projectId}
            providers={[
                BuildingsProvider,
                StoreysProvider,
            ]}>
            <Switch>
                <Route exact path={`${url}/`}>
                    <ProjectDashboard />
                </Route>
                <Route exact path={`${url}/settings`}>
                    <ProjectSettingsContainer />
                </Route>
                <Route exact path={`${url}/users/`}>
                    <ProjectUsersContainer />
                </Route>
                <Route path={`${url}/fast`}>
                    <FastRoutes />
                </Route>
                <Route path={`${url}/flat`}>
                    <FlatRoutes />
                </Route>
                <Route path={`${url}/be18`}>
                    <Be18Routes />
                </Route>
            </Switch>
        </ProviderComposer>
    );
};

export default ProjectRoutes;
