import { IExportSettings } from "context";
import { IDocument } from "types";

export const DOCUMENTLIST_TEMPLATE: IDocument = {
    title: "Tegningsfortegnelse",
    disciplineId: "cKYdHr31ZgbG8FJ4FdFa",
    disciplineCode: "MXX",
    disciplineValue: "",
    knowledgeId: "",
    knowledgeCode: "KXX",
    knowledgeValue: "",
    documentKindId: "",
    documentKindValue: "Arkivfortegnelse",
    documentKindCode: "1",
    documentKindCategoryId: "",
    documentKindCategoryValue: "",
    documentKindCategoryCode: "C01",
    projectId: "",
    id: "",
    approvedByPerson: "",
    businessTypeId: "",
    businessTypeCode: "",
    businessTypeValue: "",
    companyVatNo: "",
    contentDescription: "",
    contractType: "",
    createDate: "",
    creatorName: "",
    designedBy: "",
    documentationPurpose: "",
    documentClassIdISO5455Id: "",
    documentClassIdISO5455Code: "",
    documentClassIdISO5455Value: "",
    documentClassIdISO5457Id: "",
    documentClassIdISO5457Name: "",
    documentClassIdISO5457HSize: 0,
    documentClassIdISO5457VSize: 0,
    documentDomainId: "",
    documentForm: "",
    documentId: "",
    documentRevisionId: "",
    documentVersionId: "",
    exchange: "",
    fileTypeId: "",
    fileTypeCode: "",
    fileTypeValue: "",
    languageCode: "",
    levelofInformation: "",
    locationCode: "",
    locationId: "",
    locationName: "",
    organization: "",
    organizationContractId: "",
    organizationContractName: "",
    organizationId: "",
    organizationProjectDescription: "",
    organizationProjectId: "",
    organizationProjectName: "",
    phaseCode: "",
    phaseValue: "",
    processType: "",
    projectClientName: "",
    projectDelivery: "",
    projectDescription: "",
    projectName: "",
    releaseDate: "",
    representationFileName: "",
    reviewedByPerson: "",
    revisionText: "",
    revitCreateDate: "",
    serialNumber: "",
    sheetTypeId: "",
    sheetTypeValue: "",
    sheetTypeCode: "",
    stageCode: "",
    stageValue: "",
    status: "",
    storeyCode: "",
    storeyId: "",
    storeyName: "",
    themeCode: "",
    themeValue: "",
    themeId: "",
    workArea: "",
    handledByRevit: false,
    sheetTemplateId: "",
    projectDatabaseId: "",
    locationScopebox: "",
    legendName: "",
    viewTemplateName: "",
    viewFamilyType: undefined,
    viewFamilyTypeName: "",
};

export const TODAY = new Date().toISOString().split("T")[0];

export const EMPTY_SETTINGS: IExportSettings = {
    title: "Tegningsfortegnelse",
    filename: "C01.1_ZTegningsfortegnelse",
    date: TODAY,
    format: "EXCEL",
    knowledge: "NONE",
    selectedRevision: 0,
    revisions: [],
};
