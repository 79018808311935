import React, { useContext } from "react";
import { Row, Col, Container, Card, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CompanyContext, SyntaxContext, UserContext } from "context";
import { SerialNumberConfig } from "types";
import { DEFAULT_SERIALNUMBERCONFIG } from "_constants";
import EditCompanyRevitParametersModalForm from "../Project/EditCompanyRevitParametersModalForm";
import NamingSyntaxModal from "../Project/NamingSyntaxModal";
import ConfirmModal from "../Shared/ConfirmModal";

const CompanyParameters: React.FC = () => {
    const [showSheetNamingModal, setShowSheetNamingModal] = React.useState(false);
    const [showDocumentNamingModal, setShowDocumentNamingModal] = React.useState(false);
    const [showEditProjectRevitParameters, setShowEditProjectRevitParameters] = React.useState(false);
    const [showConfirmResetModal, setShowConfirmResetModal] = React.useState(false);
    const { company, resetCompanyNamingSyntaxAndSerialNumberConfig, handleUpdateSerialNumberConfig } =
        useContext(CompanyContext);
    const {
        companyDocumentNameSyntax,
        companySheetNameSyntax,
        companySheetSyntaxPreview,
        companyDocumentSyntaxPreview,
        saveCompanySyntaxElements,
        selectCompanyThemeValueList,
    } = useContext(SyntaxContext);
    const { user } = useContext(UserContext);
    const { t } = useTranslation();

    return (
        <Container fluid className="p-4 m-0 w-100 h-100" style={{ overflowY: "auto" }}>
            <h1>{t("pages.company.naming-convention.title")}</h1>
            <h6 className="text-muted">{t("pages.company.naming-convention.subtitle")}</h6>
            <Row>
                <Card className="m-3" style={{ width: 400 }}>
                    <Card.Body>
                        {t("pages.FAST.settings.card-title.sheet-number")}
                        <Form.Control disabled value={companySheetSyntaxPreview} />
                    </Card.Body>
                    <Button
                        className="m-1"
                        variant="secondary"
                        disabled={user?.role !== "admin" || false}
                        onClick={() => {
                            setShowSheetNamingModal(true);
                        }}
                    >
                        {t("buttons.edit")}
                    </Button>
                </Card>
                <Card className="m-3" style={{ width: 400 }}>
                    <Card.Body>
                        {t("pages.FAST.settings.card-title.document-number")}
                        <Form.Control disabled value={companyDocumentSyntaxPreview} />
                    </Card.Body>
                    <Button
                        className="m-1"
                        variant="secondary"
                        disabled={user?.role !== "admin" || false}
                        onClick={() => {
                            setShowDocumentNamingModal(true);
                        }}
                    >
                        {t("buttons.edit")}
                    </Button>
                </Card>
                <Card className="m-3" style={{ width: 400 }}>
                    <Card.Body>{t("pages.FAST.settings.card-title.revit-parameters")}</Card.Body>
                    <Button
                        className="m-1"
                        variant="secondary"
                        disabled={user?.role !== "admin" || false}
                        onClick={() => {
                            setShowEditProjectRevitParameters(true);
                        }}
                    >
                        {t("buttons.edit")}
                    </Button>
                </Card>
            </Row>
            <Row className="mt-2">
                <Col>
                    <Button variant="danger" onClick={() => setShowConfirmResetModal(true)}>
                        Reset drawing and document numbers
                    </Button>
                </Col>
            </Row>
            <NamingSyntaxModal
                show={showSheetNamingModal}
                handleReOpen={() => setShowSheetNamingModal(true)}
                handleClose={() => setShowSheetNamingModal(false)}
                nameSyntax={companySheetNameSyntax}
                serialNumberConfig={(company?.serialNumberConfig as SerialNumberConfig) || DEFAULT_SERIALNUMBERCONFIG}
                handleSaveSerialNumberConfig={handleUpdateSerialNumberConfig}
                selectedThemeValueListId={company?.themeValueListId || ""}
                saveSyntaxElements={saveCompanySyntaxElements}
                selectThemeValueList={selectCompanyThemeValueList}
            />
            <NamingSyntaxModal
                show={showDocumentNamingModal}
                handleReOpen={() => setShowDocumentNamingModal(true)}
                handleClose={() => setShowDocumentNamingModal(false)}
                nameSyntax={companyDocumentNameSyntax}
                saveSyntaxElements={saveCompanySyntaxElements}
            />
            {showEditProjectRevitParameters && (
                <EditCompanyRevitParametersModalForm handleClose={() => setShowEditProjectRevitParameters(false)} />
            )}
            <ConfirmModal
                show={showConfirmResetModal}
                confirmText="Reset"
                description="Are you sure you want to reset the drawing and document numbers?"
                handleAction={() => {
                    resetCompanyNamingSyntaxAndSerialNumberConfig();
                    setShowConfirmResetModal(false);
                }}
                handleClose={() => setShowConfirmResetModal(false)}
            />
        </Container>
    );
};

export default CompanyParameters;
