import React from 'react';
import { ThemeValueList } from 'types';
import ThemeValueListTableHeader from './ThemeValueListTableHeader';
import ThemeValueListTableRow from './ThemeValueListTableRow';

interface Props {
    themeValueLists: ThemeValueList[];
    handleSelectThemeValueList: (id: string) => void;
}

const ThemeValueListTable: React.FC<Props> = ({ themeValueLists, handleSelectThemeValueList }) => (
    <div>
        <ThemeValueListTableHeader />
        {themeValueLists.map(themeValueList => <ThemeValueListTableRow key={themeValueList.id} themeValueList={themeValueList} handleSelectThemeValueList={handleSelectThemeValueList} />)}
    </div>
)

export default ThemeValueListTable;