import React, { useEffect, useState, createContext, ReactNode, useContext, useCallback, useRef } from "react";
import { LoggingContext } from "context";
import { FirestoreSheet, ISheet } from "types";
import axios from "axios";
import { API_URL, firestore } from "config";
import { collection, doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { useAuth } from "hooks";

interface ISheetContext {
    error: Error | null | undefined;
    exportSettings?: IExportSettings;
    handleUpdateMultipleSheetsByOne: (base: Partial<FirestoreSheet>, sheetIds: string[]) => Promise<void>;
    handleSaveExportSettings: (exportSettingsData: IExportSettings) => void;
    loadingSheets: boolean;
    sheets: ISheet[];
}

export type IExportSettings = {
    filename: string;
    title: string;
    date: string;
    format: string;
    knowledge: string;
    selectedRevision: number;
    revisions: IRevision[];
};

export type IRevision = {
    index: number;
    date: string;
    name: string;
};

export const SheetsContext = createContext({} as ISheetContext);

interface Props {
    projectId?: string;
    children?: ReactNode;
}

const SheetsProvider = ({ projectId, children }: Props): JSX.Element => {
    const [loadingSheets, setLoadingSheets] = useState<boolean>(true);
    const [sheets, setSheets] = useState<ISheet[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [exportSettings, setExportSettings] = useState<IExportSettings | undefined>(undefined);

    const { logger } = useContext(LoggingContext);
    const { getAccessToken } = useAuth();

    const getExportData = useCallback(
        async (projectId: string) => async () => {
            if (!projectId) {
                return;
            }
            const projectRef = doc(firestore, "projects", projectId);
            const projectSnapshot = await getDoc(projectRef);
            if (!projectSnapshot.exists()) {
                setError(new Error("Projektet kunne ikke findes. Prøv venligst igen."));
                return;
            }
            const project = projectSnapshot.data();
            if (project && project.exportSettings) {
                setExportSettings(project.exportSettings);
            } else {
                logger.error("No project export settings");
            }
        },
        [logger]
    );

    useEffect(() => {
        if (!projectId) {
            return;
        }

        const sheetsRef = collection(firestore, "projects", projectId, "sheets");

        setLoadingSheets(true);
        try {
            onSnapshot(sheetsRef, documentsSnapshot => {
                const sheets: ISheet[] = [];
                documentsSnapshot.forEach(documentSnapshot => {
                    const sheet = documentSnapshot.data() as ISheet;
                    sheet.id = documentSnapshot.id;
                    sheets.push(sheet);
                });

                sheets.sort((a, b) => {
                    if (a.serialNumber < b.serialNumber) {
                        return -1;
                    }
                    if (a.serialNumber > b.serialNumber) {
                        return 1;
                    }
                    return 0;
                });

                setSheets(sheets);
                setLoadingSheets(false);
            });
        } catch (error) {
            setLoadingSheets(false);
            setError(new Error("Sheets kunne ikke hentes. Prøv venligst igen."));
            logger.error("Failed to get sheets", error);
        }
        getExportData(projectId);
    }, [projectId, logger, getExportData]);

    const handleSaveExportSettings = (exportSettingsData: IExportSettings) => {
        if (!projectId) {
            logger.error("Failed to save export settings, projectId is falsy", error);
            setError(new Error("Sheet eksport instillinger kunne ikke gemmes. Prøv venligst igen."));
            return;
        }
        Object.keys(exportSettingsData).forEach(
            key =>
                exportSettingsData[key as keyof IExportSettings] === undefined &&
                delete exportSettingsData[key as keyof IExportSettings]
        );

        const projectRef = doc(firestore, "projects", projectId);
        try {
            setDoc(projectRef, { exportSettings: exportSettingsData }, { merge: true });
        } catch (error) {
            setError(new Error("Sheet export indstillingerne kunne ikke opdaternes. Prøv venligst igen."));
            logger.error("Failed to save sheet export settings", error);
        }
        setExportSettings(exportSettingsData);
    };

    const handleUpdateMultipleSheetsByOne = async (base: Partial<FirestoreSheet>, sheetIds: string[]) => {
        setLoadingSheets(true);
        const requestUrl = `${API_URL}/v1/projects/${projectId}/sheetsByOne`;

        const requestJson = JSON.stringify({
            sheet: base,
            sheetIds,
        });
        try {
            const accessToken = await getAccessToken();
            if (accessToken === undefined) {
                throw new Error("No access token");
            }
            const response = await axios.put(requestUrl, requestJson, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            setLoadingSheets(false);
            return response.data;
        } catch (error) {
            setError(error as Error);
            setLoadingSheets(false);
        }
    };

    return (
        <SheetsContext.Provider
            value={{
                error,
                exportSettings,
                handleSaveExportSettings,
                handleUpdateMultipleSheetsByOne,
                loadingSheets,
                sheets,
            }}
        >
            {children}
        </SheetsContext.Provider>
    );
};

export default SheetsProvider;
