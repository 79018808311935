import React, { FC, useContext } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserContext, BuildingsContext } from "context";
import { useContextModel, useThemeValueLists, useProject, useBuildingStoreys, FormEvent } from "hooks";
import { ISheet, Storey, ViewType } from "types";
import { UNKNOWN_BUILDING_ID, ViewTypeOptions } from "_constants";
import { sortBy } from "lodash";

type Props = {
    fields: ISheet;
    handleFieldChange: (e: FormEvent) => void;
    handleKeyValueChange: (list: unknown[], event: React.ChangeEvent<HTMLSelectElement>) => void;
    handleScaleKeyValueChange: (list: unknown[], event: React.ChangeEvent<HTMLSelectElement>) => void;
    handleFormatKeyValueChange: (list: unknown[], event: React.ChangeEvent<HTMLSelectElement>) => void;
    previewSheetNumber: string;
    isInvalid: string[];
    sheetNumberIsUnique: boolean;
};

const SheetModalFormFields: FC<Props> = ({
    fields,
    handleFieldChange,
    handleKeyValueChange,
    handleScaleKeyValueChange,
    handleFormatKeyValueChange,
    previewSheetNumber,
    isInvalid,
    sheetNumberIsUnique,
}) => {
    const { t } = useTranslation();
    const {
        contextModel,
        loadingModel,
        sheetTypeStillExist,
        outputFormatStillExist,
        outputScaleStillExist,
        knowledgeStillExist,
        disciplineStillExist,
        businessTypeStillExist,
    } = useContextModel();
    const { user } = useContext(UserContext);
    const { themeValueLists } = useThemeValueLists();
    const { project } = useProject(fields.projectDatabaseId);
    const { buildings: locations } = useContext(BuildingsContext);
    const storeys: Storey[] = useBuildingStoreys(fields.locationId || UNKNOWN_BUILDING_ID);
    const valueList = themeValueLists.find(l => l.id === project?.themeValueListId);
    const valuePairs =
        valueList?.valuePairs.map(valuePair => ({
            ...valuePair,
            code: `${valueList?.code || "T"}${valuePair.code}`,
        })) || [];

    return (
        <Container>
            <Row>
                <Col className="mx-3">
                    <Form className="d-grid gap-2" noValidate>
                        <Row>
                            <Form.Group as={Col} controlId="title">
                                <Form.Label>
                                    {t("pages.FAST.sheet-list.edit-sheet.fields.title")}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    isInvalid={isInvalid.includes("TITLE")}
                                    type="text"
                                    placeholder=""
                                    value={fields.title}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="fileType">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.file-type")}</Form.Label>
                                <Form.Select
                                    value={fields.fileTypeId}
                                    onChange={e => handleKeyValueChange(contextModel.fileTypes, e)}
                                >
                                    <option value="">{t("status.empty-value")}</option>
                                    {contextModel.fileTypes.map((fileType, index) => (
                                        <option value={fileType.id} key={index}>
                                            {`${fileType.code} - ${fileType.value}`}
                                            {fileType.preferred && " ★ "}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} controlId="businessType">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.business-type")}</Form.Label>
                                <Form.Select
                                    isInvalid={!businessTypeStillExist(fields.businessTypeId) && !loadingModel}
                                    value={fields.businessTypeCode}
                                    onChange={e => handleKeyValueChange(contextModel.businessTypes, e)}
                                >
                                    <option value="">{t("status.empty-value")}</option>
                                    {contextModel.businessTypes.map(bt => (
                                        <option value={bt.code} key={bt.id}>
                                            {`${bt.code} - ${bt.value}`}
                                            {bt.preferred && " ★ "}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {t("pages.FAST.sheet-list.edit-sheet.fields.deleted-value")}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="serialNumber">
                                <Form.Label>
                                    {t("pages.FAST.sheet-list.edit-sheet.fields.running-number")}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    isInvalid={isInvalid.includes("SERIAL_NUMBER")}
                                    type="text"
                                    placeholder="NXXX"
                                    value={fields.serialNumber}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="knowledge">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.knowledge-area")}</Form.Label>
                                <Form.Select
                                    isInvalid={!knowledgeStillExist(fields.knowledgeId) && !loadingModel}
                                    value={fields.knowledgeId}
                                    onChange={e => handleKeyValueChange(contextModel.knowledges, e)}
                                >
                                    <option value="">{t("status.empty-value")}</option>
                                    {contextModel.knowledges.map((knowledge, index) => (
                                        <option value={knowledge.id} key={index}>
                                            {`${knowledge.code} - ${knowledge.value}`}
                                            {knowledge.preferred && " ★ "}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {t("pages.FAST.sheet-list.edit-sheet.fields.value-deleted")}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="discipline">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.discipline")}</Form.Label>
                                <Form.Select
                                    isInvalid={!disciplineStillExist(fields.disciplineId) && !loadingModel}
                                    value={fields.disciplineId}
                                    onChange={e => handleKeyValueChange(contextModel.disciplines, e)}
                                >
                                    <option value="">{t("status.empty-value")}</option>
                                    {contextModel.disciplines.map(discipline => (
                                        <option value={discipline.id} key={discipline.id}>
                                            {`${discipline.code} - ${discipline.value}`}
                                            {discipline.preferred && " ★ "}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {t("pages.FAST.sheet-list.edit-sheet.fields.deleted-value")}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="sheetType">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.sheet-type")}</Form.Label>
                                <Form.Select
                                    isInvalid={!sheetTypeStillExist(fields.sheetTypeId) && !loadingModel}
                                    value={fields.sheetTypeCode}
                                    onChange={e => handleKeyValueChange(contextModel.sheetTypes, e)}
                                >
                                    <option value="">{t("status.empty-value")}</option>
                                    {contextModel.sheetTypes.map(sheetType => (
                                        <option value={sheetType.code} key={sheetType.id}>
                                            {`${sheetType.code} - ${sheetType.value}`}
                                            {sheetType.preferred && " ★ "}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {t("pages.FAST.sheet-list.edit-sheet.fields.deleted-value")}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="theme">
                                <Form.Label>
                                    {t(
                                        !valueList
                                            ? "pages.FAST.sheet-list.edit-sheet.fields.no-value-list-selected"
                                            : "pages.FAST.sheet-list.edit-sheet.fields.theme"
                                    )}
                                </Form.Label>
                                <Form.Select
                                    value={fields.themeId}
                                    onChange={e => handleKeyValueChange(valuePairs, e)}
                                    disabled={valueList === undefined}
                                >
                                    <option value="">{t("status.empty-value")}</option>
                                    {valueList &&
                                        sortBy(valuePairs, "code").map(valuePair => (
                                            <option value={valuePair.id} key={valuePair.id}>
                                                {`${valuePair.code} - ${valuePair.value}`}
                                            </option>
                                        ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="location">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.location")}</Form.Label>
                                <Form.Select
                                    value={fields.locationId}
                                    onChange={e => handleKeyValueChange(locations, e)}
                                >
                                    <option value="">{t("status.empty-value")}</option>
                                    {locations &&
                                        locations.map(location => (
                                            <option value={location.id} key={location.id}>
                                                {location.name}
                                            </option>
                                        ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} controlId="storey">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.storey")}</Form.Label>
                                <Form.Select
                                    value={fields.storeyId}
                                    onChange={e =>
                                        handleKeyValueChange(
                                            // UNKNOWN_STOREY, disabled for now, need a discussion on how to handle storeys
                                            [...storeys],
                                            e
                                        )
                                    }
                                >
                                    <option value="">{t("status.empty-value")}</option>
                                    {[
                                        // UNKNOWN_STOREY, disabled for now, need a discussion on how to handle storeys
                                        ...storeys,
                                    ].map(storey => (
                                        <option value={storey.id} key={storey.id}>
                                            {storey.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.serial-number")}</Form.Label>
                                <Form.Control isInvalid={!sheetNumberIsUnique} readOnly value={previewSheetNumber} />
                                <Form.Control.Feedback type="invalid">
                                    {t("pages.FAST.sheet-list.edit-sheet.fields.serial-number-not-unique")}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} controlId="documentClassIdISO5455">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.scale")}</Form.Label>
                                <Form.Select
                                    isInvalid={!outputScaleStillExist(fields.documentClassIdISO5455Id) && !loadingModel}
                                    value={fields.documentClassIdISO5455Id}
                                    onChange={e => handleScaleKeyValueChange(contextModel.outputScales, e)}
                                >
                                    <option value="">{t("status.empty-value")}</option>
                                    {contextModel.outputScales.map(scale => (
                                        <option value={scale.id} key={scale.id}>
                                            {`${scale.code} - ${scale.value}`}
                                            {scale.preferred && " ★ "}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {t("pages.FAST.sheet-list.edit-sheet.fields.deleted-value")}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="documentClassIdISO5457">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.format")}</Form.Label>
                                <Form.Select
                                    isInvalid={
                                        !outputFormatStillExist(fields.documentClassIdISO5457Id) && !loadingModel
                                    }
                                    value={fields.documentClassIdISO5457Id}
                                    onChange={e => handleFormatKeyValueChange(contextModel?.outputFormats, e)}
                                >
                                    <option value="">{t("status.empty-value")}</option>
                                    {contextModel.outputFormats.map(format => (
                                        <option value={format.id} key={format.id}>
                                            {format.code}
                                            {format.preferred && " ★ "}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {t("pages.FAST.sheet-list.edit-sheet.fields.deleted-value")}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="createDate">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.date")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    value={fields.createDate}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="designedBy">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.designed-by")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="-"
                                    value={fields.designedBy}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="creatorName">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.creator-name")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="-"
                                    value={fields.creatorName}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="reviewedByPerson">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.reviewed-by")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="-"
                                    value={fields.reviewedByPerson}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="approvedByPerson">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.approved-by")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="-"
                                    value={fields.approvedByPerson}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="uniqueId">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.revit-unique-id")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="-"
                                    value={fields.uniqueId || ""}
                                    onChange={handleFieldChange}
                                    readOnly={user?.role !== "admin"}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="locationScopebox">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.scope-box")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={fields.locationScopebox || ""}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            {/* <Form.Group as={Col} controlId="viewFamilyType">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.view-family")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={fields.viewFamilyType || ""}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group> */}

                            <Form.Group as={Col} controlId="viewFamilyType">
                                <Form.Label>{t("pages.FAST.templates.value-title.ViewFamilyType")}</Form.Label>
                                <Form.Select value={fields.viewFamilyType} onChange={handleFieldChange}>
                                    {Object.keys(ViewTypeOptions).map(viewType => (
                                        <option value={viewType} key={viewType}>
                                            {t(ViewTypeOptions[viewType as unknown as ViewType])}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} controlId="viewFamilyTypeName">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.view-type-name")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={fields.viewFamilyTypeName || ""}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="viewTemplateName">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.view-template")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={fields.viewTemplateName || ""}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="legendName">
                                <Form.Label>{t("pages.FAST.sheet-list.edit-sheet.fields.legend-name")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={fields.legendName || ""}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default SheetModalFormFields;
