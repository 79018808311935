import React, { useEffect, useState, createContext, ReactNode, useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import { SyntaxElement } from "types";
import { CompanyContext } from "context";
import { NamingTypes, SyntaxElementIds, SYNTAX_VIEW_MODEL } from "_constants";
import { sortBy } from "lodash";
import { firestore } from "config";
import { collection, onSnapshot, setDoc, doc, writeBatch } from "firebase/firestore";

interface ISyntaxContext {
    error: Error | null;
    loadingCompany: boolean;
    loadingProject: boolean;
    sheetNameSyntax: SyntaxElement[];
    sheetSyntaxElements: SyntaxElementIds[];
    sheetSyntaxPreview: string;
    documentNameSyntax: SyntaxElement[];
    documentSyntaxElements: SyntaxElementIds[];
    documentSyntaxPreview: string;
    companySheetNameSyntax: SyntaxElement[];
    companySheetSyntaxElements: SyntaxElementIds[];
    companySheetSyntaxPreview: string;
    companyDocumentNameSyntax: SyntaxElement[];
    companyDocumentSyntaxElements: SyntaxElementIds[];
    companyDocumentSyntaxPreview: string;
    displayPreview: (elements: SyntaxElement[]) => string;
    selectProjectThemeValueList: (id: string) => void;
    selectCompanyThemeValueList: (id: string) => void;
    saveProjectSyntaxElements: (elements: SyntaxElement[]) => Promise<void>;
    saveCompanySyntaxElements: (elements: SyntaxElement[]) => Promise<void>;
}

export const SyntaxContext = createContext({} as ISyntaxContext);

interface Props {
    children?: ReactNode;
}

const SyntaxProvider = ({ children }: Props): JSX.Element => {
    const match = useRouteMatch<{ projectId: string }>("/projects/:projectId");
    const projectId = match?.params.projectId || "";

    const [namingSyntax, setNamingSyntax] = useState<SyntaxElement[]>([]);
    const [companyNamingSyntax, setCompanyNamingSyntax] = useState<SyntaxElement[]>([]);
    const { company } = useContext(CompanyContext);
    const [loadingProject, setLoadingProject] = useState<boolean>(true);
    const [loadingCompany, setLoadingCompany] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        if (!company?.id) return;
        const namingSyntaxRef = collection(firestore, "companies", company.id, "naming-syntax");
        const unsubscribe = onSnapshot(namingSyntaxRef, documentsSnapshot => {
            const elements: SyntaxElement[] = documentsSnapshot.docs.map(
                snapshot => ({ ...snapshot.data(), refId: snapshot.id } as SyntaxElement)
            );
            setCompanyNamingSyntax(elements);
            setLoadingProject(false);
        });
        return () => unsubscribe();
    }, [company?.id, projectId]);

    useEffect(() => {
        if (!projectId) return;
        const namingSyntaxRef = collection(firestore, "projects", projectId, "naming-syntax");
        const unsubscribe = onSnapshot(namingSyntaxRef, documentsSnapshot => {
            const elements: SyntaxElement[] = documentsSnapshot.docs.map(
                snapshot => ({ ...snapshot.data(), refId: snapshot.id } as SyntaxElement)
            );
            setNamingSyntax(elements);
            setLoadingCompany(false);
        });
        return () => unsubscribe();
    }, [projectId]);

    const saveProjectSyntaxElements = async (elements: SyntaxElement[]): Promise<void> => {
        if (!projectId) return;
        try {
            const batch = writeBatch(firestore);
            elements.forEach(element => {
                const { priority, enabled } = element;
                const ref = doc(firestore, "projects", projectId, "naming-syntax", element.refId);
                batch.set(ref, { priority, enabled }, { merge: true });
            });
            await batch.commit();
        } catch (error) {
            setError(error as Error);
        }
    };

    const saveCompanySyntaxElements = async (elements: SyntaxElement[]): Promise<void> => {
        if (!company?.id) return;
        try {
            const batch = writeBatch(firestore);
            elements.forEach(element => {
                const { priority, enabled } = element;
                const ref = doc(firestore, "companies", company.id, "naming-syntax", element.refId);
                batch.set(ref, { priority, enabled }, { merge: true });
            });
            await batch.commit();
        } catch (error) {
            setError(error as Error);
        }
    };

    const selectProjectThemeValueList = (id: string) => {
        if (!projectId) return;
        const projectRef = doc(firestore, "projects", projectId);
        setDoc(projectRef, { themeValueListId: id }, { merge: true });
    };

    const selectCompanyThemeValueList = (id: string) => {
        if (!company?.id) return;
        const companyRef = doc(firestore, "companies", company.id);
        setDoc(companyRef, { themeValueListId: id }, { merge: true });
    };

    const syntaxPreview = (elements: SyntaxElement[]): string => {
        if (!elements || elements.length === 0) {
            return "";
        }
        // get first type
        const type = elements[0].namingType;

        const filtered = elements.filter(syntaxElement => syntaxElement.enabled);
        if (filtered.length === 0) return "";
        const ids = sortBy(filtered, "priority").map(syntaxElement => syntaxElement.id);
        const preview = ids
            .map(element => {
                if (type === NamingTypes.DOCUMENT && element === SyntaxElementIds.TITLE) {
                    return `Z${SYNTAX_VIEW_MODEL(element).code}`;
                }
                return SYNTAX_VIEW_MODEL(element).code;
            })
            .join("_");

        return preview;
    };

    const syntaxElementsIds = (elements: SyntaxElement[]): SyntaxElementIds[] => {
        const filtered = elements.filter(element => element.enabled);
        return sortBy(filtered, "priority").map(syntaxElement => syntaxElement.id);
    };

    const sheetNameSyntax = namingSyntax.filter((element: SyntaxElement) => element.namingType === NamingTypes.SHEET);
    const sheetSyntaxPreview = syntaxPreview(sheetNameSyntax);
    const sheetSyntaxElements = syntaxElementsIds(sheetNameSyntax);

    const documentNameSyntax = namingSyntax.filter(
        (element: SyntaxElement) => element.namingType === NamingTypes.DOCUMENT
    );
    const documentSyntaxPreview = syntaxPreview(documentNameSyntax);
    const documentSyntaxElements = syntaxElementsIds(documentNameSyntax);

    const companySheetNameSyntax = companyNamingSyntax.filter(
        (element: SyntaxElement) => element.namingType === NamingTypes.SHEET
    );
    const companySheetSyntaxPreview = syntaxPreview(companySheetNameSyntax);
    const companySheetSyntaxElements = syntaxElementsIds(companySheetNameSyntax);

    const companyDocumentNameSyntax = companyNamingSyntax.filter(
        (element: SyntaxElement) => element.namingType === NamingTypes.DOCUMENT
    );
    const companyDocumentSyntaxPreview = syntaxPreview(companyDocumentNameSyntax);
    const companyDocumentSyntaxElements = syntaxElementsIds(companyDocumentNameSyntax);

    return (
        <SyntaxContext.Provider
            value={{
                error,
                loadingProject,
                loadingCompany,
                sheetNameSyntax,
                sheetSyntaxPreview,
                sheetSyntaxElements,
                documentNameSyntax,
                documentSyntaxPreview,
                documentSyntaxElements,
                companySheetNameSyntax,
                companySheetSyntaxElements,
                companySheetSyntaxPreview,
                companyDocumentNameSyntax,
                companyDocumentSyntaxElements,
                companyDocumentSyntaxPreview,
                selectProjectThemeValueList,
                selectCompanyThemeValueList,
                saveProjectSyntaxElements,
                saveCompanySyntaxElements,
                displayPreview: syntaxPreview,
            }}
        >
            {children}
        </SyntaxContext.Provider>
    );
};

export default SyntaxProvider;
