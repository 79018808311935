import React from "react";
import { Modal, Button, Form, Row, Col, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormFields, useBuildingStoreys, FormEvent, useProjectId, useAuth } from "hooks";
import { Storey } from "types";
import { NEW_STOREY, SPECIALSTOREYS } from "_constants";
import { StoreyService } from "services/StoreyServices";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type CreateStoreyModalProps = {
    buildingId: string;
    handleClose: () => void;
};

const CreateStoreyModal: React.FC<CreateStoreyModalProps> = ({ buildingId, handleClose }) => {
    const { t } = useTranslation();
    // const { handleSaveStorey } = useContext(StoreysContext);
    const queryClient = useQueryClient();

    const { fields, handleFieldChange, setFields } = useFormFields<Storey>(NEW_STOREY);
    const projectId = useProjectId();
    const { getAccessToken } = useAuth();

    const addStoreyMutation = useMutation({
        mutationFn: async (storey: Storey) => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            if (!projectId) throw new Error("No project");
            if (!buildingId) throw new Error("No building");

            return StoreyService.postStorey(projectId, buildingId, storey, accessToken);
        },
        onSuccess: () => {
            queryClient.refetchQueries(["storeys", projectId, buildingId]);
        },
    });

    return (
        <Modal show={true} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{t("pages.shared.add-storey.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PredefinedStoreys selectStorey={setFields} buildingId={buildingId} />
                <ModalFields fields={fields} handleFieldChange={handleFieldChange} buildingId={buildingId} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("buttons.cancel")}
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        addStoreyMutation.mutate(fields);
                        handleClose();
                    }}
                >
                    {t("buttons.add")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateStoreyModal;

type ModalFieldsProps = {
    fields: Storey;
    buildingId: string;
    handleFieldChange: (e: FormEvent) => void;
};

const ModalFields: React.FC<ModalFieldsProps> = ({ fields, buildingId, handleFieldChange }) => {
    const { t } = useTranslation();

    return (
        <Form className="d-grid gap-2">
            <Form.Group as={Row} controlId="name">
                <Form.Label column sm="3" className="py-0 px-3 my-auto">
                    {t("pages.shared.add-storey.fields.name")}
                </Form.Label>
                <Col sm="9">
                    <Form.Control
                        value={fields.name}
                        onChange={handleFieldChange}
                        placeholder={t("pages.shared.add-storey.fields.name-placeholder") as string}
                    />
                </Col>
            </Form.Group>
            {/* Nummer */}
            <Form.Group as={Row} controlId="code">
                <Form.Label column sm="3" className="py-0 px-3 my-auto">
                    {t("pages.shared.add-storey.fields.code")}
                </Form.Label>
                <Col sm="9">
                    <Form.Control
                        value={fields.code}
                        onChange={handleFieldChange}
                        placeholder={t("pages.shared.add-storey.fields.code-placeholder") as string}
                    />
                </Col>
            </Form.Group>
        </Form>
    );
};

type PredefinedStoreysProps = {
    selectStorey: (value: Storey) => void;
    buildingId: string;
};

const PredefinedStoreys = ({ selectStorey, buildingId }: PredefinedStoreysProps): JSX.Element => {
    const { t } = useTranslation();
    const storeys = useBuildingStoreys(buildingId);
    const handleSelectStorey = (storey: Storey) => {
        let { code, number, name, isPlan } = storey;
        if (storey.code === "EK") {
            number = Math.min(...storeys.map(s => s.number)) - 1;
            code = `EK${Math.abs(number)}`;
            name = `Basement ${Math.abs(number)}`;
        }
        if (["ET", "EX"].includes(storey.code)) {
            number = Math.max(...storeys.map(s => s.number)) + 1;
            if (code === "EX") {
                code = `E${number}`;
                name = `${number}. Storey`;
            }
        }
        if (name.includes("pages.shared.locations.variables.storey-types.")) {
            name = t(name);
        }
        selectStorey({ name, number, code, isPlan });
    };
    return (
        <Row className="pb-2 mb-2">
            <Col sm="3" className="py-0 px-3 my-auto">
                {t("pages.shared.add-storey.fields.templates")}
            </Col>
            <Col sm="9">
                <ListGroup horizontal>
                    {SPECIALSTOREYS.map(storey => (
                        <ListGroup.Item
                            key={storey.code}
                            className="py-2"
                            action
                            onClick={e => {
                                e.preventDefault();
                                handleSelectStorey(storey);
                            }}
                        >
                            {storey.code === "EX" ? "Storey" : t(storey.name)}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Col>
        </Row>
    );
};
