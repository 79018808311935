import { useContext, useEffect, useState } from "react";
import { CompanyContext } from "context";
import { useProject, useProjectId } from "hooks";

const FALLBACK_FEATURES = {
    FAST: true,
    FLAT: false,
    BE18: false,
}

export const useFeatureFlag = () => {
    const { company, loading: loadingCompany } = useContext(CompanyContext);
    const projectId = useProjectId();
    const { project, loading: loadingProject } = useProject(projectId);
    const [loading, setLoading] = useState(true);
    const [enabledProjectFeatureFlags, setEnabledProjectFeatureFlags] = useState({ FAST: false, FLAT: false, BE18: false });
    const [enabledCompanyFeatureFlags, setEnabledCompanyFeatureFlags] = useState({ FAST: false, FLAT: false, BE18: false });

    useEffect(() => {
        setLoading(true);
        if (loadingCompany || loadingProject || company === undefined) return;
        const features = company.companyFeatures;
        if (features === undefined) {
            setEnabledCompanyFeatureFlags({ ...FALLBACK_FEATURES });
        } else {
            setEnabledCompanyFeatureFlags({ ...features });
        }
        setLoading(false);
    }, [loadingCompany, loadingProject, company]);

    useEffect(() => {
        setLoading(true);
        if (loadingCompany || loadingProject || project === undefined) return;
        
        const isOldProject = project.version < "1.0.0";
        const features = project.projectFeatures;
        if (isOldProject || features === undefined) {
            setEnabledProjectFeatureFlags({ ...FALLBACK_FEATURES });
        } else {
            setEnabledProjectFeatureFlags({ ...features });
        }
        setLoading(false);
    }, [loadingCompany, loadingProject, project]);

    return {
        enabledProjectFeatureFlags,
        enabledCompanyFeatureFlags,
        loading
    };
};
