import React, { useEffect, useState, createContext, ReactNode, useContext } from "react";
import { LoggingContext, UserContext } from "context";
import { Project, ProjectSpecification, CreateProjectStatus } from "types";
import { UNKNOWN_PROJECTSUPPLY, UNKNOWN_BUILDING, SITE_STOREY, emptyProjectStatus } from "_constants";
import {
    collection,
    doc,
    deleteDoc,
    where,
    query,
    onSnapshot,
    setDoc,
    updateDoc,
    getDocs,
    addDoc,
    DocumentReference,
    DocumentData,
    getDoc,
} from "firebase/firestore";
import _, { pickBy } from "lodash";
import { firestore } from "config";
import { useAuth } from "hooks";
import { t } from "i18next";

interface IProjectContext {
    error: Error | null;
    setError: (error: Error | null) => void;
    handleArchiveProject: (projectId: string | undefined) => void;
    handleDeleteProject: (projectId: string | undefined) => void;
    handleCreateProject: (project: Project | Partial<Project>) => Promise<string | undefined>;
    handleSaveProject: (project: Project | Partial<Project>) => void;
    handleSaveProjectSpecification: (
        projectId: string,
        projectSpecification: ProjectSpecification | Partial<ProjectSpecification>
    ) => void;
    handleSaveProjectField: (projectId: string, entry: Partial<Project>) => void;
    loading: boolean;
    setLoading: (bool: boolean) => void;
    projects: Project[];
    status: string;
    creatingProject: boolean;
    createProjectProgress: number;
    createProjectStatus: CreateProjectStatus;
}

export const ProjectContext = createContext({} as IProjectContext);

interface Props {
    children?: ReactNode;
}

const ProjectProvider = ({ children }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [projects, setProjects] = useState<Project[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [status, setStatus] = useState<string>("");
    const { user } = useContext(UserContext);
    const [creatingProject, setCreatingProject] = useState<boolean>(false);
    const [createProjectProgress, setCreateProjectProgress] = useState<number>(0);
    const [createProjectStatus, setCreateProjectProgressStatus] = useState<CreateProjectStatus>(emptyProjectStatus);
    const { user: firebaseUser } = useAuth();
    const { logger } = useContext(LoggingContext);
    const { currentVersion } = useContext(LoggingContext);

    useEffect(() => {
        if (!firebaseUser) {
            return;
        }
        const projectsRef = collection(firestore, "projects");
        const userProjectsQuery = query(
            projectsRef,
            where("users", "array-contains", firebaseUser.uid),
            where("version", ">=", "0.7.00")
        );

        setLoading(true);
        const unsubscribe = onSnapshot(userProjectsQuery, documentsSnapshot => {
            const projects: Project[] = [];
            documentsSnapshot.forEach(document => {
                const project = document.data();
                project.id = document.id;
                projects.push(project as Project);
            });

            setProjects(_.sortBy(projects, ["internalNumber", "name"]));
            setLoading(false);
        });
        return () => unsubscribe();
    }, [firebaseUser]);

    useEffect(() => {
        const totalVariables = Object.keys(createProjectStatus).length;
        const totalTrueVariables = Object.values(createProjectStatus).filter(value => value === true).length;
        const progress = Math.round((totalTrueVariables / totalVariables) * 100);
        setCreateProjectProgress(progress);
    }, [createProjectStatus]);

    const handleArchiveProject = async (projectId: string | undefined) => {
        if (projectId === undefined) {
            const error = new Error("Projekt ID ikke fundet, specificer venligst hvilket projekt du vil arkivere.");
            setError(error);
            return;
        }

        const projectRef = doc(firestore, "projects", projectId);
        try {
            await setDoc(projectRef, { archived: true }, { merge: true });
            setStatus("Succesfully archived project");
        } catch (error) {
            logger.error("Failed to archive project", error);
            setError(new Error("Projektet kunne ikke slettes, prøv igen."));
        }
    };

    const handleDeleteProject = async (projectId: string | undefined) => {
        if (projectId === undefined) {
            const error = new Error("Projekt ID ikke fundet, specificer venligst hvilket projekt du vil slette.");
            setError(error);
            return;
        }

        const projectRef = doc(firestore, "projects", projectId);
        try {
            await deleteDoc(projectRef);
            setStatus("Succesfully deleted project");
        } catch (error) {
            logger.error("Failed to delete project", error);
            setError(new Error("Projektet kunne ikke slettes, prøv igen."));
        }
    };

    const handleSaveProject = async (projectData: Project | Partial<Project>) => {
        // Remove all key-value pairs where the value is undefined
        const cleanProjectData = pickBy(projectData, value => value !== undefined);
        if (!projectData || projectData.id === undefined) {
            return;
        }
        const projectRef = doc(firestore, "projects", projectData.id);

        try {
            await setDoc(projectRef, cleanProjectData, { merge: true });
        } catch (error) {
            setError(new Error("Projektet kunne ikke opdateres. Prøv venligst igen."));
            logger.error("Failed to save project", error);
        }
    };

    const handleSaveProjectSpecification = async (
        projectId: string,
        projectSpecification: ProjectSpecification | Partial<ProjectSpecification>
    ) => {
        // Remove all key-value pairs where the value is undefined
        const cleanProjectSpecification = pickBy(projectSpecification, value => value !== undefined);
        const projectRef = doc(firestore, "projects", projectId);
        try {
            await setDoc(projectRef, { specification: cleanProjectSpecification }, { merge: true });
        } catch (error) {
            setError(new Error("Projektet kunne ikke opdateres. Prøv venligst igen."));
            logger.error("Failed to save project specification", error);
        }
    };

    const handleSaveProjectField = async (projectId: string, entry: Partial<Project>) => {
        const projectRef = doc(firestore, "projects", projectId);
        try {
            await updateDoc(projectRef, entry);
        } catch (error) {
            setError(new Error("Projektet kunne ikke opdateres. Prøv venligst igen."));
            logger.error("Failed to save project field", error);
        }
    };

    const handleCreateProject = async (project: Project | Partial<Project>): Promise<string> => {
        setCreatingProject(true);
        setCreateProjectProgress(5);
        setCreateProjectProgressStatus(emptyProjectStatus);

        if (!firebaseUser) {
            setError(new Error("Kunne ikke finde din brugerprofil. Prøv at log ind igen."));
            logger.error("Failed to find user", firebaseUser);
            return "";
        }

        const projectsReference = collection(firestore, "projects");
        const projectData = {
            ...project,
            projectSupply: UNKNOWN_PROJECTSUPPLY,
            owner: firebaseUser.uid,
            users: [firebaseUser.uid],
            version: currentVersion,
            projectFeatures: project.projectFeatures || { FAST: true, FLAT: false, BE18: false },
        };
        try {
            const projectReference = await addDoc(projectsReference, projectData);

            if (user?.company !== undefined) {
                await copyCompanySettingsToProject(user?.company, projectReference);
            }

            return projectReference.id;
        } catch (error) {
            setError(new Error("Projektet kunne ikke oprettes. Prøv venligst igen."));
            logger.error("Failed to create project specification", error);
            return "";
        } finally {
            setCreatingProject(false);
        }
    };

    const copyCompanySettingsToProject = async (
        companyId: string,
        projectReference: DocumentReference<DocumentData>
    ) => {
        if (companyId) {
            // add theme and serialnumber config from company
            try {
                const companyThemeRef = doc(firestore, "companies", companyId);
                const companyThemeSnapshot = await getDoc(companyThemeRef);
                const companyTheme = companyThemeSnapshot.data()?.themeValueListId;
                const companySerialNumberConfig = companyThemeSnapshot.data()?.serialNumberConfig;
                if (companyTheme) {
                    await setDoc(
                        projectReference,
                        { themeValueListId: companyTheme, serialNumberConfig: companySerialNumberConfig },
                        { merge: true }
                    ).then(() =>
                        setCreateProjectProgressStatus(prevStatus => ({
                            ...prevStatus,
                            copiedThemeAndSerialNumbers: true,
                        }))
                    );
                }
            } catch (error) {
                logger.error("Failed to copy theme and serialnumber config", error);
            }

            try {
                // add naming syntax
                const companyNamingSyntaxRef = collection(firestore, "companies", companyId, "naming-syntax");
                const companyNamingSyntaxSnapshot = await getDocs(companyNamingSyntaxRef);
                const promises: Promise<DocumentReference<DocumentData>>[] = [];
                companyNamingSyntaxSnapshot.forEach(docSnap => {
                    promises.push(addDoc(collection(projectReference, "naming-syntax"), docSnap.data()));
                });
                await Promise.all(promises).then(() =>
                    setCreateProjectProgressStatus(prevStatus => ({ ...prevStatus, copiedNamingSyntax: true }))
                );
            } catch (error) {
                logger.error("Failed to copy naming syntax", error);
            }

            try {
                // add revit parameters from company
                const companyRevitParametersRef = collection(firestore, "companies", companyId, "revit-parameters");
                const companyRevitParametersSnapshot = await getDocs(companyRevitParametersRef);
                const promises: Promise<DocumentReference<DocumentData>>[] = [];
                companyRevitParametersSnapshot.forEach(docSnap => {
                    const promise = addDoc(collection(projectReference, "revit-parameters"), docSnap.data());
                    promises.push(promise);
                });
                await Promise.all(promises).then(() =>
                    setCreateProjectProgressStatus(prevStatus => ({ ...prevStatus, copiedRevitParameters: true }))
                );
            } catch (error) {
                logger.error("Failed to copy revit parameters", error);
            }

            try {
                // copy company model to projekt model
                const companyModelDisciplinesRef = collection(firestore, "companies", companyId, "model-disciplines");
                const companyModelDisciplinesSnapshot = await getDocs(companyModelDisciplinesRef);
                const promises: Promise<void>[] = [];
                companyModelDisciplinesSnapshot.forEach(docSnap => {
                    const promise = setDoc(doc(projectReference, "model-disciplines", docSnap.id), docSnap.data());
                    promises.push(promise);
                });
                await Promise.all(promises).then(() =>
                    setCreateProjectProgressStatus(prevStatus => ({ ...prevStatus, copiedDisciplines: true }))
                );
            } catch (error) {
                logger.error("Failed to copy model disciplines", error);
            }

            try {
                const companyModelKnowledgesRef = collection(firestore, "companies", companyId, "model-knowledges");
                const companyModelKnowledgesSnapshot = await getDocs(companyModelKnowledgesRef);
                const promises: Promise<void>[] = [];
                companyModelKnowledgesSnapshot.forEach(docSnap => {
                    const promise = setDoc(doc(projectReference, "model-knowledges", docSnap.id), docSnap.data());
                    promises.push(promise);
                });
                await Promise.all(promises).then(() =>
                    setCreateProjectProgressStatus(prevStatus => ({ ...prevStatus, copiedKnowledges: true }))
                );
            } catch (error) {
                logger.error("Failed to copy model knowledges", error);
            }

            try {
                const companyModelSheetTypesRef = collection(firestore, "companies", companyId, "model-sheetTypes");
                const companyModelSheetTypesSnapshot = await getDocs(companyModelSheetTypesRef);
                const promises: Promise<void>[] = [];
                companyModelSheetTypesSnapshot.forEach(docSnap => {
                    const promise = setDoc(doc(projectReference, "model-sheetTypes", docSnap.id), docSnap.data());
                    promises.push(promise);
                });
                await Promise.all(promises).then(() =>
                    setCreateProjectProgressStatus(prevStatus => ({ ...prevStatus, copiedSheetTypes: true }))
                );
            } catch (error) {
                logger.error("Failed to copy model sheet types", error);
            }

            try {
                const companyModelFormatsRef = collection(firestore, "companies", companyId, "model-outputFormats");
                const companyModelFormatsSnapshot = await getDocs(companyModelFormatsRef);
                const promises: Promise<void>[] = [];
                companyModelFormatsSnapshot.forEach(docSnap => {
                    const promise = setDoc(doc(projectReference, "model-outputFormats", docSnap.id), docSnap.data());
                    promises.push(promise);
                });
                await Promise.all(promises).then(() =>
                    setCreateProjectProgressStatus(prevStatus => ({ ...prevStatus, copiedFormats: true }))
                );
            } catch (error) {
                logger.error("Failed to copy model output formats", error);
            }

            try {
                const companyModelScalesRef = collection(firestore, "companies", companyId, "model-outputScales");
                const companyModelScalesSnapshot = await getDocs(companyModelScalesRef);
                const promises: Promise<void>[] = [];
                companyModelScalesSnapshot.forEach(docSnap => {
                    const promise = setDoc(doc(projectReference, "model-outputScales", docSnap.id), docSnap.data());
                    promises.push(promise);
                });
                await Promise.all(promises).then(() =>
                    setCreateProjectProgressStatus(prevStatus => ({ ...prevStatus, copiedScales: true }))
                );
            } catch (error) {
                logger.error("Failed to copy model output scales", error);
            }

            try {
                const companyModelPhasesRef = collection(firestore, "companies", companyId, "model-projectPhases");
                const companyModelPhasesSnapshot = await getDocs(companyModelPhasesRef);
                const promises: Promise<void>[] = [];
                companyModelPhasesSnapshot.forEach(docSnap => {
                    const promise = setDoc(doc(projectReference, "model-projectPhases", docSnap.id), docSnap.data());
                    promises.push(promise);
                });
                await Promise.all(promises).then(() =>
                    setCreateProjectProgressStatus(prevStatus => ({ ...prevStatus, copiedPhases: true }))
                );
            } catch (error) {
                logger.error("Failed to copy model project phases", error);
            }

            try {
                const companyModelStagesRef = collection(firestore, "companies", companyId, "model-projectStages");
                const companyModelStagesSnapshot = await getDocs(companyModelStagesRef);
                const promises: Promise<void>[] = [];
                companyModelStagesSnapshot.forEach(docSnap => {
                    const promise = setDoc(doc(projectReference, "model-projectStages", docSnap.id), docSnap.data());
                    promises.push(promise);
                });
                await Promise.all(promises).then(() =>
                    setCreateProjectProgressStatus(prevStatus => ({ ...prevStatus, copiedStages: true }))
                );
            } catch (error) {
                logger.error("Failed to copy model project stages", error);
            }

            try {
                const companyModelDocumentKindCategoriesRef = collection(
                    firestore,
                    "companies",
                    companyId,
                    "model-documentKindCategories"
                );
                const companyModelDocumentKindCategoriesSnapshot = await getDocs(companyModelDocumentKindCategoriesRef);
                const promises: Promise<void>[] = [];
                companyModelDocumentKindCategoriesSnapshot.forEach(docSnap => {
                    const promise = setDoc(
                        doc(projectReference, "model-documentKindCategories", docSnap.id),
                        docSnap.data()
                    );
                    promises.push(promise);
                });
                await Promise.all(promises).then(() =>
                    setCreateProjectProgressStatus(prevStatus => ({
                        ...prevStatus,
                        copiedDocumentKindCategories: true,
                    }))
                );
            } catch (error) {
                logger.error("Failed to copy model document kind categories", error);
            }

            try {
                const companyModelDocumentKindsRef = collection(
                    firestore,
                    "companies",
                    companyId,
                    "model-documentKinds"
                );
                const companyModelDocumentKindsSnapshot = await getDocs(companyModelDocumentKindsRef);
                const promises: Promise<void>[] = [];
                companyModelDocumentKindsSnapshot.forEach(docSnap => {
                    const promise = setDoc(doc(projectReference, "model-documentKinds", docSnap.id), docSnap.data());
                    promises.push(promise);
                });
                await Promise.all(promises).then(() =>
                    setCreateProjectProgressStatus(prevStatus => ({ ...prevStatus, copiedDocumentKinds: true }))
                );
            } catch (error) {
                logger.error("Failed to copy model document kinds", error);
            }

            try {
                const companyModelFileTypesRef = collection(firestore, "companies", companyId, "model-fileTypes");
                const companyModelFileTypesSnapshot = await getDocs(companyModelFileTypesRef);
                const promises: Promise<void>[] = [];
                companyModelFileTypesSnapshot.forEach(docSnap => {
                    const promise = setDoc(doc(projectReference, "model-fileTypes", docSnap.id), docSnap.data());
                    promises.push(promise);
                });
                await Promise.all(promises).then(() =>
                    setCreateProjectProgressStatus(prevStatus => ({ ...prevStatus, copiedFileTypes: true }))
                );
            } catch (error) {
                logger.error("Failed to copy model file types", error);
            }

            try {
                const companyModelBusinessTypesRef = collection(
                    firestore,
                    "companies",
                    companyId,
                    "model-businessTypes"
                );
                const companyModelPhasesSnapshot = await getDocs(companyModelBusinessTypesRef);
                const promises: Promise<void>[] = [];
                companyModelPhasesSnapshot.forEach(docSnap => {
                    const promise = setDoc(doc(projectReference, "model-businessTypes", docSnap.id), docSnap.data());
                    promises.push(promise);
                });
                await Promise.all(promises).then(() =>
                    setCreateProjectProgressStatus(prevStatus => ({ ...prevStatus, copiedBusinessTypes: true }))
                );
            } catch (error) {
                logger.error("Failed to copy model project phases", error);
            }

            // copy company templates to project templates
            try {
                const companySheetTemplatesRef = collection(firestore, "companies", companyId, "sheet-templates");
                const companySheetTemplatesSnapshot = await getDocs(companySheetTemplatesRef);
                const promises: Promise<void>[] = [];
                companySheetTemplatesSnapshot.forEach(docSnap => {
                    const promise = setDoc(doc(projectReference, "sheet-templates", docSnap.id), docSnap.data());
                    promises.push(promise);
                });
                await Promise.all(promises).then(() =>
                    setCreateProjectProgressStatus(prevStatus => ({ ...prevStatus, copiedSheetTemplates: true }))
                );
            } catch (error) {
                logger.error("Failed to copy sheet templates", error);
            }

            try {
                const companydocumentTemplatesRef = collection(firestore, "companies", companyId, "document-templates");
                const companydocumentTemplatesSnapshot = await getDocs(companydocumentTemplatesRef);
                const promises: Promise<void>[] = [];
                companydocumentTemplatesSnapshot.forEach(docSnap => {
                    const promise = setDoc(doc(projectReference, "document-templates", docSnap.id), docSnap.data());
                    promises.push(promise);
                });
                await Promise.all(promises).then(() =>
                    setCreateProjectProgressStatus(prevStatus => ({ ...prevStatus, copiedDocumentTemplates: true }))
                );
            } catch (error) {
                logger.error("Failed to copy document templates", error);
            }
        }

        try {
            // add unknown building
            const buildingRef = doc(collection(projectReference, "buildings"), UNKNOWN_BUILDING.id);
            await setDoc(buildingRef, { ...UNKNOWN_BUILDING, sharedLocation: true }).then(() => {
                setCreateProjectProgressStatus(prevStatus => ({ ...prevStatus, createdSharedBuilding: true }));
            });

            // add ground level
            const storeysSiteRef = doc(collection(buildingRef, "storeys"));
            const groundLevel = SITE_STOREY;
            groundLevel.name = t(SITE_STOREY.name);
            await setDoc(storeysSiteRef, groundLevel, { merge: false }).then(() => {
                setCreateProjectProgressStatus(prevStatus => ({ ...prevStatus, createdGroundLevel: true }));
            });
        } catch (error) {
            logger.error("Failed to create unknown building or ground level", error);
        }
    };

    return (
        <ProjectContext.Provider
            value={{
                error,
                setError,
                handleCreateProject,
                handleSaveProject,
                handleSaveProjectSpecification,
                handleSaveProjectField,
                handleArchiveProject,
                handleDeleteProject,
                status,
                loading,
                setLoading,
                projects,
                creatingProject,
                createProjectProgress,
                createProjectStatus,
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
};

export default ProjectProvider;
