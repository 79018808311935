import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { SystemType, VentilationSystem, DecentralVentilationSystem, FireProtection } from "types";
import { useTranslation } from "react-i18next";

type Props<TSystemType> = {
    fields: TSystemType;
    handleFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setFields: React.Dispatch<React.SetStateAction<TSystemType>>;
    inputIsinvalid: boolean;
};

const VentilationForm: React.FC<Props<VentilationSystem>> = ({
    fields,
    handleFieldChange,
    setFields,
    inputIsinvalid,
}) => {
    const { t } = useTranslation();

    const handleCheckField = <Element extends HTMLInputElement>(e: React.ChangeEvent<Element>) => {
        const id = e.target.id as keyof VentilationSystem;
        setFields(val => ({ ...val, [id]: !val[id] }));
    };

    const handleChangeSystemType = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const systemType = event.target.value;
        if (systemType === SystemType.DECENTRAL) {
            const baseSystem = {
                ...fields,
                systemType,
                fireProtection: FireProtection.FIREDAMPER,
            };
            setFields(baseSystem as DecentralVentilationSystem);
        } else {
            const baseSystem = { ...fields, systemType };
            setFields(baseSystem as VentilationSystem);
        }
    };

    return (
        <>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>
                        {t("pages.FLAT.systems.card-modal.type")}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                        isInvalid={!fields.systemType && inputIsinvalid}
                        id="systemType"
                        value={fields.systemType}
                        onChange={e => {
                            handleChangeSystemType(e as React.ChangeEvent<HTMLSelectElement>);
                        }}
                    >
                        <option value="" key="">
                            {t("pages.FLAT.systems.card-modal.type-select")}
                        </option>
                        {Object.keys(SystemType).map(type => (
                            <option key={type} value={type}>
                                {`${t(`pages.FLAT.systems.card-modal.${type}`)}`}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>
            {fields.systemType === SystemType.DECENTRAL && (
                <>
                    <DecentralVentilationForm fields={fields} handleCheckField={handleCheckField} />
                </>
            )}
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>
                        {t("pages.FLAT.systems.card-modal.ventilation.fire-protection")}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                        isInvalid={!fields.fireProtection && inputIsinvalid}
                        id="fireProtection"
                        value={fields.fireProtection}
                        disabled={fields.systemType === SystemType.DECENTRAL}
                        onChange={e => handleFieldChange(e as unknown as React.ChangeEvent<HTMLInputElement>)}
                    >
                        <option value="" key="">
                            {t("pages.FLAT.systems.card-modal.ventilation.fire-protection-select")}
                        </option>
                        {Object.keys(FireProtection).map(type => (
                            <option key={type} value={type}>
                                {`${t(`pages.FLAT.systems.card-modal.ventilation.${type}`)}`}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Check
                        id="heatingSurface"
                        type="switch"
                        label={t("pages.FLAT.systems.card-modal.ventilation.heating-surface")}
                        checked={fields.heatingSurface}
                        onChange={handleCheckField}
                    />
                </Form.Group>
            </Row>
        </>
    );
};

export default VentilationForm;

const DecentralVentilationForm: React.FC<{
    fields: DecentralVentilationSystem;
    handleCheckField: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ fields, handleCheckField }) => {
    const { t } = useTranslation();

    return (
        <>
            <Row>
                <Form.Group as={Col}>
                    <Form.Check
                        id="sharedIntake"
                        type="switch"
                        label={t("pages.FLAT.systems.card-modal.ventilation.shared-intake")}
                        checked={fields.sharedIntake}
                        onChange={handleCheckField}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Check
                        id="sharedExhaust"
                        type="switch"
                        label={t("pages.FLAT.systems.card-modal.ventilation.shared-exhaust")}
                        checked={fields.sharedExhaust}
                        onChange={handleCheckField}
                    />
                </Form.Group>
            </Row>
        </>
    );
};
