import { HousingType, Storey } from "types";

export type Building = {
    code: string;
    description: string;
    id: string | undefined;
    name: string;
    numberOfBasementStoreys: number;
    numberOfStoreys: number;
    scopeBox: string;
    revitModel: string;
    sharedLocation: boolean;
    housingType?: HousingType;
    housingTypeSize?: number;
    specialStoreys?: string[];
    storeys?: Storey[];
}

export type Apartment = {
    id: string;
    name: string;
    areaSize: number;
    rooms: Room[];
}

export type Room = {
    id: string;
    name: string;
    airFlow: number;
    fixtures: Fixture[];
    priority?: number;
}

export type Fixture = {
    type: FixtureType;
    priority: number;
}

export enum FixtureType {
    SINK = "SINK",
    KITCHENSINK = "KITCHENSINK",
    SHOWER = "SHOWER",
    BATHTUB = "BATHTUB",
    DISHWASHER = "DISHWASHER",
    WASHINGMACHINE = "WASHINGMACHINE",
    WC = "WC",
    BIDET = "BIDET",
}