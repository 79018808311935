import { kebabCase, sortBy } from 'lodash';
import { EnergyRapportFields, EnergyRapportTables, Report, XMLEntity, XMLFile } from 'types';
import { AbstractBE18XMLService } from './xmlService';

const WINDOW_TYPES = [
    {
        value: 'Energirude 2 lags',
        u: 1.2,
        g: 0.6,
    },
    {
        value: 'Energirude 3 lags',
        u: 0.9,
        g: 0.55,
    },
    {
        value: 'Solafskærmende glas',
        u: 0.9,
        g: 0.35,
    },
];

export class BE18ModelService extends AbstractBE18XMLService {
    constructor(xml: string) {
        super(xml);
        this.content = this.parseFile(this.data);
    }

    public getContent(): Pick<Report, 'tables' | 'fields'> {
        if (this.content === undefined) {
            throw new Error('content');
        }
        return this.content;
    }
    public parseFile = (
        xml: XMLEntity | XMLFile | undefined,
    ): Pick<Report, 'tables' | 'fields'> | undefined => {
        if (xml === undefined) return;
        const store: Pick<Report, 'tables' | 'fields'> = {
            fields: {},
            tables: {},
        };
        const setState = <T extends keyof EnergyRapportFields>(key: T, value: EnergyRapportFields[T]) => {
            if (value === undefined) {
                store.fields[key] = '';
            } else {
                store.fields[key] = value;
            }
        };
        const setTables = <T extends keyof EnergyRapportTables>(key: T, value: EnergyRapportTables[T]) => {
            store.tables[key] = value;
        };

        // Heating supply
        const heatSupply = this.getElementValueByPath(xml, 'BE05/BUILDING/basic_heat_supply');
        setState('HeatSupply', heatSupply);

        // Areas
        const area = this.parseNumberValue(this.getElementValueByPath(xml, 'BE05/BUILDING/ae'));
        const basementArea = this.parseNumberValue(this.getElementValueByPath(xml, 'BE05/BUILDING/ae3'));
        const addedArea = this.parseNumberValue(this.getElementValueByPath(xml, 'BE05/BUILDING/ae2'));
        const hasAddedArea = addedArea > 0;
        setState('FloorArea', area);
        setState('BruttoArea', area + basementArea / 2);
        setState('AddedArea', addedArea);
        setState('hasAddedArea', hasAddedArea);

        const heatingCapacity = this.parseNumberValue(
            this.getElementValueByPath(xml, 'BE05/BUILDING/heat_cap'),
        );
        setState('HeatCapacityTotal', heatingCapacity);

        // TimeUsedWeekly
        const schedule = this.parseNumberValue(this.getElementValueByPath(xml, 'BE05/BUILDING/tb'));
        const additionTimeStart = this.getElementValueByPath(xml, 'BE05/BUILDING/start_time', '0');
        const additionTimeEnd = this.getElementValueByPath(xml, 'BE05/BUILDING/end_time', '24');
        setState('TimeUsedWeekly', schedule);
        setState('AdditionTimeUsedWeekly', `${additionTimeStart} - ${additionTimeEnd}`);

        // rotation
        const rotation = this.parseNumberValue(this.getElementValueByPath(xml, 'BE05/BUILDING/rotation'));
        setState('Rotation', rotation);

        // type
        
        const buildingType = this.getElementValueByPath(xml, 'BE05/BUILDING/has_btype');
        const isBusinessType = buildingType === 'ATYPE';
        const isResidentialType = [
            "ETYPE", // etagebolig
            "STYPE", // samenbyggede boliger
            "FTYPE" // fritliggende bolig
        ].includes(buildingType);
        setState('BuildingType', buildingType);
        setState('isCommercial', isBusinessType);
        setState('isResidential', isResidentialType);

        // windows
        const windowData = this.getSubElementsByPath(xml, 'BE05/TRANSP_CONSTR');
        const transparentConstructions = windowData.map(w => this.extractWindowsFromXML(w));

        const uniqueWindowTypes = this.getUniquesByProperty(transparentConstructions, [
            'fc',
            'orient',
            'u',
            'g',
        ]).map(w => {
            let type = WINDOW_TYPES[0];
            const name = transparentConstructions
                .filter(tc => tc.fc === w.fc && tc.orient === w.orient && tc.u === w.u && tc.g === w.g)
                .map(tc => tc.name)
                .join(", ")
            const matchedWindowTypes = WINDOW_TYPES.filter(wt => wt.u === Number(w.u));
            if (matchedWindowTypes.length === 1) {
                const matchedWindowType = matchedWindowTypes[0];
                type = matchedWindowType;
            }
            return { ...w, name, type: type.value };
        });

        const glassAreaRatio =
            transparentConstructions
                .map(w => {
                    const { area, no } = w;
                    return { area: Number(area), no: parseInt(no) };
                })
                .reduce((acc, cur) => {
                    const totalArea = cur.no * cur.area;
                    return acc + totalArea;
                }, 0) / area;
        if (uniqueWindowTypes.length > 0) {
            setState('GlassAreaRatio', Number(glassAreaRatio.toFixed(2)) * 100);
            setTables('UniqueWindowTypes', uniqueWindowTypes);

            const firstWindow = transparentConstructions[0];
            const windowDoorPartOfArea = this.parseNumberValue(firstWindow?.ff);
            setState('WindowGlassRationWeightedAverage', windowDoorPartOfArea);
            const matchedWindowTypes = WINDOW_TYPES.filter(w => w.u === Number(firstWindow?.u));
            if (matchedWindowTypes.length === 1) {
                const matchedWindowType = matchedWindowTypes[0];
                if (matchedWindowType !== undefined) {
                    setState('WindowType', kebabCase(matchedWindowType.value));
                }
            }
        }

        // linje tab
        const thermalBridgesElements = this.getSubElementsByPath(xml, 'BE05/COLD_BRIDGE');
        const thermalBridges = thermalBridgesElements.map(el => {
            const name = this.getElementValue(this.getSubElement(el, 'id')) || '-';
            const u = this.getElementValue(this.getSubElement(el, 'u')) || '-';
            return {
                name,
                u,
            };
        });
        setTables('LineLoss', thermalBridges);

        // opaqueConstructions
        const constuctions = this.getSubElementsByPath(xml, 'BE05/OPAQUE_CONST');
        const opaqueConstructions = constuctions.map(el => {
            const u = this.getElementValue(this.getSubElement(el, 'u')) || '-';
            const id = this.getElementValue(this.getSubElement(el, 'id'));
            const { name, lambda, iso } = this.parseConstructionId(id);
            return {
                name,
                u,
                lambda,
                iso,
            };
        });
        if (opaqueConstructions.length > 0) {
            setTables('ExteriorWalls', opaqueConstructions);
        }

        // version
        const be18version = this.getElementValueByPath(xml, 'BE05/BE05/id');
        setState('Version', be18version);

        // project name
        const projectName = this.getElementValueByPath(xml, 'BE05/BUILDING/id');
        setState('ProjectName', projectName);

        // skygger
        const shadingsXML = this.getSubElementsByPath(xml, 'BE05/SHADING');
        const shadings = shadingsXML.map(el => this.extractShadingFromXML(el));
        setTables('Shadings', shadings);

        // sommerkomfort
        const heatedRoomArea = this.parseNumberValue(
            this.getElementValueByPath(xml, 'BE05/HEATED_ROOM/area'),
        );
        const heatedRoomVolumen = heatedRoomArea * 2.5;
        const heatedRoomVinter = this.parseNumberValue(
            this.getElementValueByPath(xml, 'BE05/HEATED_ROOM/vent_basic'),
        );
        const heatedRoomSummerDay = this.parseNumberValue(
            this.getElementValueByPath(xml, 'BE05/HEATED_ROOM/vent_day'),
        );
        const heatedRoomSummerNigth = this.parseNumberValue(
            this.getElementValueByPath(xml, 'BE05/HEATED_ROOM/vent_night'),
        );
        setState('HeatedRoomArea', heatedRoomArea);
        setState('HeatedRoomVolumen', heatedRoomVolumen);
        setState('HeatedRoomVinter', heatedRoomVinter);
        setState('HeatedRoomSummerDay', heatedRoomSummerDay);
        setState('HeatedRoomSummerNigth', heatedRoomSummerNigth);

        // ventilation
        const ventilationComponents = this.getSubElementsByPath(xml, 'BE05/VENTILATION');
        const ventilationUnits = ventilationComponents.map(el => this.extractVentilationFromXML(el));

        const centralUnits = this.getUniquesByProperty(ventilationUnits, ['elvf', 'sel', 'system', 'nvgv'])
            .filter(vu => vu.system.includes('VE'))
            .sort(this.sortByExtractedNumberFromProperty('system'));

        setTables('VentilationUnits', ventilationUnits);
        // q50
        const infiltration = ventilationUnits.filter(vu => vu.system === 'INF' && vu.infil > 0);
        if (infiltration.length > 0 && infiltration[0]?.infil !== undefined) {
            const inf = infiltration[0]?.infil;
            const q50 = (inf - 0.04) / 0.06;
            setState('q50', q50.toFixed(2));
        }
        setState('NumberOfUnits', new Set(centralUnits.map(u => u.system)).size);
        const summerNaturalAirVelocity = Math.max(
            ...ventilationUnits
                .filter(vu => vu.naturalAirVelocitySummer > 0)
                .map(vu => vu.naturalAirVelocitySummer),
        );
        setState('VentilationDuringSommer', summerNaturalAirVelocity);

        // internal heat load
        const usageLoadElements = this.getSubElementsByPath(xml, 'BE05/USAGE_LOAD');
        const hasMultipleUsageLoadZones = usageLoadElements.length > 1
        // if more than one value in table, insert whole table
        if (usageLoadElements) {
            setState('hasMultipleUsageLoadZones', hasMultipleUsageLoadZones);
            const usageLoadZones = usageLoadElements.map(el => {
                const name = this.getElementValue(this.getSubElement(el, 'id')) || '-';
                const area = this.parseNumberValue(this.getElementValue(this.getSubElement(el, 'area')))
                const people = this.parseNumberValue(this.getElementValue(this.getSubElement(el, 'ppers')));
                const appliance = this.parseNumberValue(this.getElementValue(this.getSubElement(el, 'papp')));
                return {
                    name,
                    area,
                    people,
                    appliance,
                };
            })
            setTables('UsageLoadZones', usageLoadZones);
        } else {
            const peopleLoadUserTime = this.getElementValueByPath(xml, 'BE05/USAGE_LOAD/ppers');
            const applianceUserTime = this.getElementValueByPath(xml, 'BE05/USAGE_LOAD/papp');
            setState('PeopleLoadUserTime', this.parseNumberValue(peopleLoadUserTime));
            setState('ApplianceUserTime', this.parseNumberValue(applianceUserTime));
        }

        // solceller
        const hasSolarPanel = this.getElementValueByPath(xml, 'BE05/BUILDING/pv_panel') === 'T';
        setState('HasSolarPanel', hasSolarPanel);
        if (hasSolarPanel) {
            const solarPanelArea = this.parseNumberValue(
                this.getElementValueByPath(xml, 'BE05/PV_CELL/area'),
            );
            const solarPanelPeakPower = this.parseNumberValue(
                this.getElementValueByPath(xml, 'BE05/PV_CELL/peak_power'),
            );
            const solarPanelEfficiency = this.parseNumberValue(
                this.getElementValueByPath(xml, 'BE05/PV_CELL/efficiency'),
            );
            const solarPanelOrientation = this.parseOrientation(
                this.getElementValueByPath(xml, 'BE05/PV_CELL/orient'),
            );
            const solarPanelSlope = this.parseNumberValue(
                this.getElementValueByPath(xml, 'BE05/PV_CELL/slope'),
            );
            setState('SolarPanelArea', solarPanelArea);
            setState('SolarPanelPeakPower', solarPanelPeakPower);
            setState('SolarPanelEfficiency', solarPanelEfficiency);
            setState('SolarPanelOrientation', solarPanelOrientation);
            setState('SolarPanelSlope', solarPanelSlope);
        }

        // tillæg
        const energyFrameAdditions = this.parseNumberValue(
            this.getElementValueByPath(xml, 'BE05/BUILDING/add_eframe'),
        );
        const hasEnergyFrameAdditions = !isResidentialType && energyFrameAdditions > 0;
        setState('EnergyFrameAdd', energyFrameAdditions);
        setState('hasEnergyFrameAdditions', hasEnergyFrameAdditions)

        // Varmefordelingsanlæg
        const heatSupplyTemps = this.getSubElementsByPath(xml, 'BE05/DIM_TEMP');
        if (heatSupplyTemps.length === 2) {
            const supplyElement = heatSupplyTemps[0];
            const returnElement = heatSupplyTemps[1];
            const heatSupplySupplyTemp = this.getElementValue(this.getSubElement(supplyElement, 'temp'));
            const heatSupplyReturnTemp = this.getElementValue(this.getSubElement(returnElement, 'temp'));
            setState('HeatSupplySupplyTemp', this.parseNumberValue(heatSupplySupplyTemp));
            setState('HeatSupplyReturnTemp', this.parseNumberValue(heatSupplyReturnTemp));
        }
        const heatingPipeLength = this.parseNumberValue(
            this.getElementValueByPath(xml, 'BE05/HEAT_TUBE/len'),
        );
        const heatingPipeHeatloss = this.parseNumberValue(
            this.getElementValueByPath(xml, 'BE05/HEAT_TUBE/psi'),
        );
        const isHeatingPipeOutdoorCompensation =
            this.getElementValueByPath(xml, 'BE05/HEAT_TUBE/outdoor_comp') === 'T';
        setState('HeatingPipeLength', heatingPipeLength);
        setState('HeatingPipeHeatloss', heatingPipeHeatloss);
        setState('isHeatingPipeOutdoorCompensation', isHeatingPipeOutdoorCompensation);

        // varmefordelingspumper
        const heatDistributionA = this.getElementValueByPath(xml, 'BE05/HEAT_DISTRIBUTION/pump_const_year');
        const heatDistributionV = this.getElementValueByPath(xml, 'BE05/HEAT_DISTRIBUTION/pump_const_hs');
        const heatDistributionT = this.getElementValueByPath(xml, 'BE05/HEAT_DISTRIBUTION/pump_timereg_hs');
        // const heatDistributionK = this.getElementValueByPath(xml, 'BE05/HEAT_DISTRIBUTION/pump_combi');
        const distributionPump = this.getSubElementsByPath(xml, 'BE05/DIST_PUMP');
        const distributionPumps = distributionPump.map(el => {
            const id = el.attributes.rid;
            const type = heatDistributionA?.includes(id)
                ? 'Altid konstant drift'
                : heatDistributionV?.includes(id)
                    ? 'Konstant i opvarmningssæson'
                    : heatDistributionT?.includes(id)
                        ? 'Tidsstyret'
                        : 'Kombi-pumpe';
            const no = this.parseNumberValue(this.getElementValue(this.getSubElement(el, 'no')));
            const name = this.getElementValue(this.getSubElement(el, 'id')) || `Pumpe ${no}`;
            const pnom = this.parseNumberValue(this.getElementValue(this.getSubElement(el, 'pnom')));
            const fp = this.parseNumberValue(this.getElementValue(this.getSubElement(el, 'fp')));

            return {
                id,
                name,
                no,
                pnom,
                fp,
                type,
            };
        });
        if (distributionPumps.length > 0) {
            setTables('DistributionPumps', sortBy(distributionPumps, 'id'));
        }

        // varmtvandsbeholder
        const hotWaterTankSize = this.parseNumberValue(this.getElementValueByPath(xml, 'BE05/DHW_TANK/vol'));
        const useFlowWaterHeater = hotWaterTankSize === 0;
        const hotWaterTankHeatloss = this.parseNumberValue(
            this.getElementValueByPath(xml, 'BE05/DHW_TANK/heat_loss'),
        );
        setState('HotWaterTankSize', hotWaterTankSize);
        setState('useFlowWaterHeater', useFlowWaterHeater);
        setState('HotWaterTankHeatloss', hotWaterTankHeatloss);

        // tilslutningsrør vvb
        const hotWaterTankPipeHeatloss = this.parseNumberValue(
            this.getElementValueByPath(xml, 'BE05/DHW_PIPE/tube_psi'),
        );
        const hotWaterTankPipeLength = this.parseNumberValue(
            this.getElementValueByPath(xml, 'BE05/DHW_PIPE/tube_len'),
        );
        setState('HotWaterTankPipeHeatloss', hotWaterTankPipeHeatloss);
        setState('HotWaterTankPipeLength', hotWaterTankPipeLength);

        // Cirkulationspumper
        const circulatorPumpAmount = this.parseNumberValue(
            this.getElementValueByPath(xml, 'BE05/DHW_PUMP_CIRC/n_cp'),
        );
        const CirculatorPumpEffect = this.parseNumberValue(
            this.getElementValueByPath(xml, 'BE05/DHW_PUMP_CIRC/p_eff'),
        );
        const CirculatorPumpReductionFactor = this.parseNumberValue(
            this.getElementValueByPath(xml, 'BE05/DHW_PUMP_CIRC/r_fac'),
        );
        setState('CirculatorPumpAmount', circulatorPumpAmount);
        setState('CirculatorPumpEffect', CirculatorPumpEffect);
        setState('CirculatorPumpReductionFactor', CirculatorPumpReductionFactor);

        // Cirkulationsrør
        const CirculatorPipeLength = this.parseNumberValue(
            this.getElementValueByPath(xml, 'BE05/DHW_TUBE/len'),
        );
        const CirculatorPipeHeatloss = this.parseNumberValue(
            this.getElementValueByPath(xml, 'BE05/DHW_TUBE/psi'),
        );
        setState('CirculatorPipeLength', CirculatorPipeLength);
        setState('CirculatorPipeHeatloss', CirculatorPipeHeatloss);

        return store;
    };
}
