import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
    handleClose: () => void;
    handleAction: () => void;
    title?: string | React.ReactNode;
    description: string | React.ReactNode;
    confirmText: string;
    buttonsDisabled?: boolean;
    show: boolean;
}

const ConfirmModal = ({
    handleAction,
    handleClose,
    title,
    description,
    confirmText,
    buttonsDisabled,
    show,
}: Props): JSX.Element => {
    const { t } = useTranslation();
    return (
        <Modal show={show} size="sm" onHide={handleClose} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title>{title ? title : (t("messages.warning.title") as string)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{description ? description : t("messages.warning.text")}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} disabled={buttonsDisabled ? buttonsDisabled : false}>
                    {t("buttons.cancel")}
                </Button>
                <Button
                    variant="danger"
                    onClick={() => {
                        handleAction();
                    }}
                    disabled={buttonsDisabled ? buttonsDisabled : false}
                >
                    {confirmText ? confirmText : t("messages.warning.confirm")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;
