import React from "react";
import { Container, Col, Row } from "react-bootstrap";

interface Props {
    title: string;
    description: string;
    icon: string;
}

const EmptyFeatureState : React.FC<Props> = ({ title, description, icon }) => (
    <Container className="p-4 h-100">
        <Row className="h-100 justify-content-center align-items-center">
            <Col className="text-center">
                <img src={icon} alt="" />
                <p className="mt-4 mb-0">{title}</p>
                <p>{description}</p>
            </Col>
        </Row>
    </Container>
)

export default EmptyFeatureState;