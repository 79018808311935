import { Storey, SheetTemplateStoreyOptions } from "types";

export enum StoreyCodes {
    GROUND_LEVEL = "EST",
    ROOF_LEVEL = "ET",
    FOUNDATION_LEVEL = "EF",
    PILE_FOUNDATION_LEVEL = "EPL",
    UNSPECIFIED_LEVEL = "EX",
    MULTIPLE_LEVELS = "E?",
    BASEMENT_LEVEL = "EK",
    SITE_LEVEL = "ETE",
}

export const NEW_STOREY: Storey = {
    code: "",
    name: "",
    number: 0,
    sortPriority: 0,
    isPlan: false,
};

export const FOUNDATION_STOREY: Storey = {
    code: StoreyCodes.FOUNDATION_LEVEL,
    name: "pages.shared.locations.variables.storey-types.foundation",
    number: 0,
    sortPriority: 0,
    isPlan: false,
};

export const ROOF_STOREY: Storey = {
    code: StoreyCodes.ROOF_LEVEL,
    name: "pages.shared.locations.variables.storey-types.roof",
    number: 0,
    sortPriority: 0,
    isPlan: false,
};

export const SITE_STOREY: Storey = {
    code: StoreyCodes.SITE_LEVEL,
    name: "pages.shared.locations.variables.storey-types.terrain",
    number: 0,
    sortPriority: 0,
    isPlan: false,
};

export const BASEMENT_STOREY: Storey = {
    code: StoreyCodes.BASEMENT_LEVEL,
    name: "pages.shared.locations.variables.storey-types.basement",
    number: -1,
    sortPriority: 0,
    isPlan: true,
};

export const LEVEL_STOREY: Storey = {
    id: StoreyCodes.UNSPECIFIED_LEVEL,
    code: StoreyCodes.UNSPECIFIED_LEVEL,
    name: "pages.shared.locations.variables.storey-types.all",
    number: 0,
    sortPriority: 0,
    isPlan: true,
};

export const GROUND_STOREY: Storey = {
    code: StoreyCodes.GROUND_LEVEL,
    name: "pages.shared.locations.variables.storey-types.ground",
    number: 0,
    sortPriority: 0,
    isPlan: false,
};

export const PILE_STOREY: Storey = {
    code: StoreyCodes.PILE_FOUNDATION_LEVEL,
    name: "pages.shared.locations.variables.storey-types.pile",
    number: 0,
    sortPriority: 0,
    isPlan: false,
};

export const SPECIALSTOREYS: Storey[] = [
    LEVEL_STOREY,
    BASEMENT_STOREY,
    ROOF_STOREY,
    FOUNDATION_STOREY,
    SITE_STOREY,
    PILE_STOREY,
];

export const DEFAULT_SPECIAL_STOREY_CODE: string[] = [
    FOUNDATION_STOREY.code,
    ROOF_STOREY.code,
    SITE_STOREY.code,
    PILE_STOREY.code,
];

export const STOREY_OPTIONS: SheetTemplateStoreyOptions = {
    ETE: "pages.FAST.templates.storey-options.ETE",
    EST: "pages.FAST.templates.storey-options.EST",
    EK: "pages.FAST.templates.storey-options.EK",
    "E?": "pages.FAST.templates.storey-options.E?",
    ET: "pages.FAST.templates.storey-options.ET",
    EF: "pages.FAST.templates.storey-options.EF",
    EPL: "pages.FAST.templates.storey-options.EPL",
    EX: "pages.FAST.templates.storey-options.EX",
};
