import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import LocationContainer from "../components/Location/LocationContainer";
import EnergyEfficiencyContainer from "../components/EnergyEfficiency/EnergyEfficiencyContainer";
import EmptyFeatureState from "../components/Shared/EmptyFeatureState";
import BE18Dashboard from "../components/EnergyEfficiency/BE18Dashboard";
import DocumentsContainer from "../components/Documents/DocumentsContainer";
import DocumentSettingsContainer from "../components/Documents/DocumentSettingsContainer";
import NotEnabledIcon from "../images/not-enabled.svg";
import { useFeatureFlag, useProjectId } from "hooks";
import DocumentsProvider from "context/DocumentsProvider";
import EnergyRapportProvider from "context/EnergyRapportProvider";
import ProviderComposer from "context/ProviderComposer";
import SyntaxProvider from "context/SyntaxProvider";
import DocumentTemplatesContainer from "components/Templates/DocumentTemplates/DocumentTemplatesContainer";
import { useTranslation } from "react-i18next";

const Be18Routes: React.FC = () => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const projectId = useProjectId();
    const { enabledCompanyFeatureFlags, enabledProjectFeatureFlags, loading } = useFeatureFlag();

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <Spinner animation="grow" />
            </div>
        );
    } else if (!enabledCompanyFeatureFlags.BE18) {
        return (
            <EmptyFeatureState
                title={t("status.product-not-enabled", { product: "BE18" })}
                description={t("status.get-access-admin")}
                icon={NotEnabledIcon}
            />
        );
    } else if (!enabledProjectFeatureFlags.BE18) {
        return (
            <EmptyFeatureState
                icon={NotEnabledIcon}
                title={t("status.product-not-enabled-for-project", { product: "BE18" })}
                description={t("status.get-access-project-settings")}
            />
        );
    } else {
        return (
            <ProviderComposer
                providers={[DocumentsProvider, SyntaxProvider, EnergyRapportProvider]}
                projectId={projectId}
            >
                <Switch>
                    <Route exact path={`${url}`}>
                        <BE18Dashboard />
                    </Route>
                    <Route exact path={`${url}/locations`}>
                        <LocationContainer />
                    </Route>
                    <Route exact path={`${url}/documents`}>
                        <DocumentsContainer />
                    </Route>
                    <Route exact path={`${url}/settings`}>
                        <DocumentSettingsContainer />
                    </Route>
                    <Route exact path={`${url}/generate`}>
                        <EnergyEfficiencyContainer projectId={projectId} />
                    </Route>
                    <Route exact path={`${url}/templates`}>
                        <DocumentTemplatesContainer />
                    </Route>
                </Switch>
            </ProviderComposer>
        );
    }
};

export default Be18Routes;
