import React from "react";
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { CheckCircleFill, Circle } from "react-bootstrap-icons";
import { useContextModel, usePlanTemplateHovertext } from "hooks";
import { PlanTemplate } from "types";
import { CheckboxCard, Line, LineItem } from "../Shared/Layout";

interface Props {
    sheetTemplate: PlanTemplate;
    selected: boolean;
    handleSelectSheet: (sheetTemplate: PlanTemplate, amount?: number) => void;
}

const SheetCheckboxCard: React.FC<Props> = ({ sheetTemplate, selected, handleSelectSheet }) => {
    const { getDisciplineById } = useContextModel();
    const handleOnClick = () => (selected ? handleSelectSheet(sheetTemplate, 0) : handleSelectSheet(sheetTemplate));

    return (
        <CheckboxCard selected={selected} onClick={handleOnClick}>
            <Line>
                <LineItem className="user-select-none" grow>
                    {sheetTemplate.title}{" "}
                    <span className="text-muted font-weight-lighter">
                        ({getDisciplineById(sheetTemplate.disciplineId)?.code})
                    </span>
                </LineItem>
                <LineItem className="mx-3">
                    <OverlayTrigger
                        overlay={
                            <Popover id="tooltip-sheets-created" style={{ width: "500px" }}>
                                <Popover.Header as="h3">{`Sheets will be created for these locations and stories:`}</Popover.Header>
                                <Popover.Body>{usePlanTemplateHovertext(sheetTemplate)}</Popover.Body>
                            </Popover>
                        }
                    >
                        <Button
                            onClick={event => {
                                event.stopPropagation();
                                if (selected) {
                                    handleSelectSheet(sheetTemplate, 0);
                                } else {
                                    handleSelectSheet(sheetTemplate);
                                }
                            }}
                            variant="link"
                            className="d-inline-flex align-items-center m-0 p-0"
                        >
                            {selected ? <CheckCircleFill className="text-success" /> : <Circle className="text-dark" />}
                        </Button>
                    </OverlayTrigger>
                </LineItem>
            </Line>
        </CheckboxCard>
    );
};

export default SheetCheckboxCard;
