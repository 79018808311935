import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useContextModel, useHover } from "hooks";
import { ModelDataType } from "_constants";
import { Line, LineItem } from "../Shared/Layout";
import ItemModalList from "./ItemModalList";
import { t } from "i18next";

const ModelList: React.FC = () => {
    const [showDiciplinesModal, setShowDiciplinesModal] = useState<boolean>(false);
    const [showKnowledgesModal, setShowKnowledgesModal] = useState<boolean>(false);
    const [showSheetTypesModal, setShowSheetTypesModal] = useState<boolean>(false);
    const [showScalesModal, setShowScalesModal] = useState<boolean>(false);
    const [showFormatsModal, setShowFormatsModal] = useState<boolean>(false);
    const [showPhasesModal, setShowPhasesModal] = useState<boolean>(false);
    const [showStagesModal, setShowStagesModal] = useState<boolean>(false);
    const [showDocumentKindModal, setShowDocumentKindModal] = useState<boolean>(false);
    const [showDocumentKindCategoryModal, setShowDocumentKindCategoryModal] = useState<boolean>(false);
    const [showFileTypesModal, setShowFileTypesModal] = useState<boolean>(false);
    const [showBusinessTypesModal, setShowBusinessTypesModal] = useState<boolean>(false);
    const {
        contextModel,
        createDiscipline,
        createKnowledge,
        createSheetType,
        createOutputScale,
        createOutputFormat,
        createPhase,
        createStage,
        createDocumentKind,
        createDocumentKindCategory,
        createFileType,
        updateDiscipline,
        updateKnowledge,
        updateSheetType,
        updateOutputScale,
        updateOutputFormat,
        updatePhase,
        updateStage,
        updateDocumentKind,
        updateDocumentKindCategory,
        updateFileType,
        deleteDiscipline,
        deleteKnowledge,
        deleteSheetType,
        deleteOutputScale,
        deleteOutputFormat,
        deletePhase,
        deleteStage,
        deleteDocumentKind,
        deleteDocumentKindCategory,
        deleteFileType,
        createBusinessType,
        updateBusinessType,
        deleteBusinessType,
    } = useContextModel();

    return (
        <>
            <Row className="mt-4">
                <Col>
                    <Container fluid className="px-0">
                        <ValueListTableHeader />
                        <ValueListTableRow
                            setShowModal={() => setShowDiciplinesModal(true)}
                            title={t("pages.FAST.value-lists.types.discipline")}
                            numberOfValues={contextModel.disciplines.length}
                            metaDataCode={"M"}
                            definition={""}
                        />
                        <ValueListTableRow
                            setShowModal={() => setShowKnowledgesModal(true)}
                            title={t("pages.FAST.value-lists.types.knowledge")}
                            numberOfValues={contextModel.knowledges.length}
                            metaDataCode={"K"}
                            definition={""}
                        />
                        <ValueListTableRow
                            setShowModal={() => setShowSheetTypesModal(true)}
                            title={t("pages.FAST.value-lists.types.sheet-type")}
                            numberOfValues={contextModel.sheetTypes.length}
                            metaDataCode={"H"}
                            definition={""}
                        />
                        <ValueListTableRow
                            setShowModal={() => setShowScalesModal(true)}
                            title={t("pages.FAST.value-lists.types.scale")}
                            numberOfValues={contextModel.outputScales.length}
                            metaDataCode={"S"}
                            definition={""}
                        />
                        <ValueListTableRow
                            setShowModal={() => setShowFormatsModal(true)}
                            title={t("pages.FAST.value-lists.types.format")}
                            numberOfValues={contextModel.outputFormats.length}
                            metaDataCode={"-"}
                            definition={""}
                        />
                        <ValueListTableRow
                            setShowModal={() => setShowPhasesModal(true)}
                            title={t("pages.FAST.value-lists.types.phase")}
                            numberOfValues={contextModel.projectPhases.length}
                            metaDataCode={"A"}
                            definition={""}
                        />
                        <ValueListTableRow
                            setShowModal={() => setShowStagesModal(true)}
                            title={t("pages.FAST.value-lists.types.stage")}
                            numberOfValues={contextModel.projectStages.length}
                            metaDataCode={"-"}
                            definition={""}
                        />
                        <ValueListTableRow
                            setShowModal={() => setShowDocumentKindModal(true)}
                            title={t("pages.FAST.value-lists.types.document-kind")}
                            numberOfValues={contextModel.documentKinds.length}
                            metaDataCode={"C"}
                            definition={""}
                        />
                        <ValueListTableRow
                            setShowModal={() => setShowDocumentKindCategoryModal(true)}
                            title={t("pages.FAST.value-lists.types.document-kind-category")}
                            numberOfValues={contextModel.documentKindCategories.length}
                            metaDataCode={"C"}
                            definition={""}
                        />
                        <ValueListTableRow
                            setShowModal={() => setShowBusinessTypesModal(true)}
                            title={t("pages.FAST.value-lists.types.business-type")}
                            numberOfValues={contextModel.businessTypes.length}
                            metaDataCode={"B"}
                            definition={""}
                        />
                        <ValueListTableRow
                            setShowModal={() => setShowFileTypesModal(true)}
                            title={t("pages.FAST.value-lists.types.file-type")}
                            numberOfValues={contextModel.fileTypes.length}
                            metaDataCode={"F"}
                            definition={""}
                        />
                    </Container>
                </Col>
            </Row>
            {showDiciplinesModal && (
                <ItemModalList
                    title={t("pages.FAST.value-lists.types.discipline")}
                    itemModel={contextModel.disciplines}
                    handleClose={() => setShowDiciplinesModal(false)}
                    handleCreate={createDiscipline}
                    handleUpdate={updateDiscipline}
                    handleDelete={deleteDiscipline}
                    modelDataType={ModelDataType.VALUEPAIR}
                />
            )}
            {showKnowledgesModal && (
                <ItemModalList
                    title={t("pages.FAST.value-lists.types.knowledge")}
                    itemModel={contextModel.knowledges}
                    handleClose={() => setShowKnowledgesModal(false)}
                    handleCreate={createKnowledge}
                    handleUpdate={updateKnowledge}
                    handleDelete={deleteKnowledge}
                    modelDataType={ModelDataType.VALUEPAIR}
                />
            )}
            {showSheetTypesModal && (
                <ItemModalList
                    title={t("pages.FAST.value-lists.types.sheet-type")}
                    itemModel={contextModel.sheetTypes}
                    handleClose={() => setShowSheetTypesModal(false)}
                    handleCreate={createSheetType}
                    handleUpdate={updateSheetType}
                    handleDelete={deleteSheetType}
                    modelDataType={ModelDataType.VALUEPAIR}
                />
            )}
            {showScalesModal && (
                <ItemModalList
                    title={t("pages.FAST.value-lists.types.scale")}
                    itemModel={contextModel.outputScales}
                    handleClose={() => setShowScalesModal(false)}
                    handleCreate={createOutputScale}
                    handleUpdate={updateOutputScale}
                    handleDelete={deleteOutputScale}
                    modelDataType={ModelDataType.VALUEPAIR}
                />
            )}
            {showFormatsModal && (
                <ItemModalList
                    title={t("pages.FAST.value-lists.types.format")}
                    itemModel={contextModel.outputFormats}
                    handleClose={() => setShowFormatsModal(false)}
                    handleCreate={createOutputFormat}
                    handleUpdate={updateOutputFormat}
                    handleDelete={deleteOutputFormat}
                    modelDataType={ModelDataType.OUTPUTFORMAT}
                />
            )}
            {showPhasesModal && (
                <ItemModalList
                    title={t("pages.FAST.value-lists.types.phase")}
                    itemModel={contextModel.projectPhases}
                    handleClose={() => setShowPhasesModal(false)}
                    handleCreate={createPhase}
                    handleUpdate={updatePhase}
                    handleDelete={deletePhase}
                    modelDataType={ModelDataType.VALUEPAIR}
                />
            )}
            {showStagesModal && (
                <ItemModalList
                    title={t("pages.FAST.value-lists.types.stage")}
                    itemModel={contextModel.projectStages}
                    handleClose={() => setShowStagesModal(false)}
                    handleCreate={createStage}
                    handleUpdate={updateStage}
                    handleDelete={deleteStage}
                    modelDataType={ModelDataType.VALUEPAIR}
                />
            )}
            {showDocumentKindModal && (
                <ItemModalList
                    title={t("pages.FAST.value-lists.types.document-kind")}
                    itemModel={contextModel.documentKinds}
                    handleClose={() => setShowDocumentKindModal(false)}
                    handleCreate={createDocumentKind}
                    handleUpdate={updateDocumentKind}
                    handleDelete={deleteDocumentKind}
                    modelDataType={ModelDataType.DOCUMENTKIND}
                />
            )}
            {showDocumentKindCategoryModal && (
                <ItemModalList
                    title={t("pages.FAST.value-lists.types.document-kind-category")}
                    itemModel={contextModel.documentKindCategories}
                    handleClose={() => setShowDocumentKindCategoryModal(false)}
                    handleCreate={createDocumentKindCategory}
                    handleUpdate={updateDocumentKindCategory}
                    handleDelete={deleteDocumentKindCategory}
                    modelDataType={ModelDataType.VALUEPAIR}
                />
            )}
            {showFileTypesModal && (
                <ItemModalList
                    title={t("pages.FAST.value-lists.types.file-type")}
                    itemModel={contextModel.fileTypes}
                    handleClose={() => setShowFileTypesModal(false)}
                    handleCreate={createFileType}
                    handleUpdate={updateFileType}
                    handleDelete={deleteFileType}
                    modelDataType={ModelDataType.VALUEPAIR}
                />
            )}
            {showBusinessTypesModal && (
                <ItemModalList
                    title={t("pages.FAST.value-lists.types.business-type")}
                    itemModel={contextModel.businessTypes}
                    handleClose={() => setShowBusinessTypesModal(false)}
                    handleCreate={createBusinessType}
                    handleUpdate={updateBusinessType}
                    handleDelete={deleteBusinessType}
                    modelDataType={ModelDataType.VALUEPAIR}
                />
            )}
        </>
    );
};
export default ModelList;

const ValueListTableHeader: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Row>
            <Col>
                <div className="px-3 text-muted">
                    <Line>
                        <LineItem style={{ width: "35%" }}>{t("pages.FAST.value-lists.value-headers.name")}</LineItem>
                        <LineItem style={{ width: "20%", textAlign: "center" }}>
                            {t("pages.FAST.value-lists.value-headers.values")}
                        </LineItem>
                        <LineItem style={{ width: "20%", textAlign: "center" }}>
                            {t("pages.FAST.value-lists.value-headers.codes")}
                        </LineItem>
                        <LineItem style={{ width: "25%", textAlign: "right" }}>
                            {t("pages.FAST.value-lists.value-headers.definitions")}
                        </LineItem>
                    </Line>
                </div>
            </Col>
        </Row>
    );
};

interface ValueListTableRowProps {
    setShowModal: (bool: boolean) => void;
    title: string;
    numberOfValues: number;
    metaDataCode: string;
    definition: string;
}

const ValueListTableRow: React.FC<ValueListTableRowProps> = ({
    setShowModal,
    title,
    numberOfValues,
    metaDataCode,
    definition,
}) => {
    const hoverRef = React.useRef(null);
    const isHover = useHover(hoverRef);

    return (
        <Row className="mb-2">
            <Col>
                <Card
                    ref={hoverRef}
                    className="transition"
                    body
                    bg={isHover ? "light" : undefined}
                    role="button"
                    onClick={() => setShowModal(true)}
                >
                    <Line>
                        <LineItem style={{ width: "35%" }}>{title}</LineItem>
                        <LineItem style={{ width: "20%", textAlign: "center" }}>{numberOfValues}</LineItem>
                        <LineItem style={{ width: "20%", textAlign: "center" }}>{metaDataCode}</LineItem>
                        <LineItem
                            className="d-inline-block text-truncate text-muted font-weight-light"
                            style={{ width: "25%", textAlign: "right" }}
                        >
                            {definition}
                        </LineItem>
                    </Line>
                </Card>
            </Col>
        </Row>
    );
};
