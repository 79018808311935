import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { EnergyRapportFields } from "types";
import { useEnergyRapport } from "hooks";
import { ToogleOption } from "../../Shared/ToggleOption";
import { ReportFieldsInputGroup, ReportFieldsSelectGroup } from "../../Shared/InputControl";
import { SectionComponent } from "./Sections";

type FieldOptions = {
    value: string;
    key: string | number | boolean;
};

export interface SectionField {
    id?: string;
    labelId?: string;
    type: "text" | "number" | "options" | "dropdown" | "custom";
    dependent?: {
        id: string;
        value: unknown;
        condition: "==" | "!=" | "undefined";
    };
    readonly?: boolean;
}

export interface InputSectionField extends SectionField {
    id: string;
    type: "text" | "number";
}

export interface SectionFieldWithOptions extends SectionField {
    id: string;
    type: "options" | "dropdown";
    options: FieldOptions[];
}
export interface CustomSectionField extends SectionField {
    type: "custom";
    element: React.FC;
}

const checkCondition = (fields: EnergyRapportFields, dependent: NonNullable<SectionField["dependent"]>): boolean => {
    const { id, value, condition } = dependent;
    const conditionsMap = {
        "==": fields && fields[id as keyof EnergyRapportFields] === value,
        "!=": fields && fields[id as keyof EnergyRapportFields] !== value,
        undefined: fields && fields[id as keyof EnergyRapportFields] === undefined,
    };
    const conditionsCheck = (condition: "==" | "!=" | "undefined") => conditionsMap[condition];
    return conditionsCheck(condition);
};

const BaseSection: SectionComponent<{ data: SectionField[][] }> = ({ data: fieldsData }) => {
    const { fields } = useEnergyRapport();
    return (
        <Container fluid>
            <Row>
                <Col>
                    <Form className="d-grid gap-2">
                        {fieldsData.map((fieldsRow, i) => (
                            <Row key={i}>
                                {fieldsRow.map((field, j) => {
                                    if (field.dependent !== undefined) {
                                        const conditionMet = checkCondition(
                                            fields as EnergyRapportFields,
                                            field.dependent
                                        );
                                        if (conditionMet) {
                                            return <FieldInput field={field} key={j} />;
                                        }
                                    } else {
                                        return <FieldInput field={field} key={j} />;
                                    }
                                    return null;
                                })}
                            </Row>
                        ))}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default BaseSection;

const FieldInput: React.FC<{ field: SectionField }> = ({ field }) => {
    if (field.type === "text" || field.type === "number") {
        return (
            <ReportFieldsInputGroup
                fieldId={field.id as keyof EnergyRapportFields}
                label={field.labelId}
                type={field.type}
                disabled={(field as InputSectionField).readonly}
            />
        );
    } else if (field.type === "custom") {
        const Element = (field as CustomSectionField).element;
        return <Element />;
    } else if (field.type === "dropdown") {
        return (
            <ReportFieldsSelectGroup
                options={(field as SectionFieldWithOptions).options}
                fieldId={field.id as keyof EnergyRapportFields}
                label={field.labelId}
            />
        );
    } else {
        return (
            <ToogleOption
                options={(field as SectionFieldWithOptions).options}
                fieldId={field.id as keyof EnergyRapportFields}
                label={field.labelId}
            />
        );
    }
};
