import React from "react";
import { Card } from "react-bootstrap";
import { BE18Badge, FastBadge, FlatBadge } from "../Badges/FeatureBadges";

interface Props {
    tool: string;
    title: string;
    titleAmount: number;
    onClick: () => void;
}

const UserCard: React.FC<Props> = ({ tool, title, titleAmount, onClick }) => (
    <Card onClick={() => onClick()} className="shadow-sm m-2 p-0" style={{ width: 200, height: 95, cursor: "pointer" }}>
        <Card.Body className="p-2">
            <Card.Title className="mb-4">
                {tool === "FAST" && <FastBadge />}
                {tool === "FLAT" && <FlatBadge />}
                {tool === "BE18" && <BE18Badge />}
            </Card.Title>
            <Card.Subtitle className="d-flex align-items-center">
                <div>
                    <small style={{ fontSize: "0.8rem" }} className="text-muted">
                        {title}
                    </small>
                </div>
                <div className="ms-1">
                    <h3 style={{ display: "inline" }}>{titleAmount}</h3>
                </div>
            </Card.Subtitle>
        </Card.Body>
    </Card>
);

export default UserCard;
