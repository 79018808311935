import { FC } from "react";
import { FilesSection } from "./FilesSection";
import { AdditionalSections } from "./AdditionalSections";
import { MetaDataSection } from "./MetaDataSection";
import { HeatingCapacitySection } from "./HeatingCapacitySection";
import { useTranslation } from "react-i18next";

export type SectionData = {
    id: string;
    titleTranslationId: string;
    subTitleTranslationId: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SectionComponent<T = any> = FC<T>;

export const PAGES: Record<string, SectionComponent> = {
    FILES: FilesSection,
    META_DATA: MetaDataSection,
    HEATING_CAPACITY: HeatingCapacitySection,
    ADDITIONAL: AdditionalSections,
};

type HandlerProps = {
    page: string;
}

export const SectionHandler = <T extends HandlerProps,>({ ...props }: T) => {
    const Handler: SectionComponent = PAGES[props.page] || FourOhFourPage;

    return <Handler {...props} />;
}

export const FourOhFourPage: SectionComponent = () => {
    const { t } = useTranslation();

    return (
        <div>{t("pages.BE18.configurator.page-not-found")}</div>
    );
}
