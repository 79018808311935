import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AbstractBaseSheetTemplate, LocationSpecificTemplate } from "types";
import CreateSheetsContext from "./CreateSheetsContext";
import { useAuth } from "hooks";
import { SheetService } from "services";

interface Props {
    handleClose: () => void;
    title: string;
    projectId: string;
    fetchData: () => void;
}

const CreateSheetsModal: React.FC<Props> = ({ handleClose, title, projectId, fetchData }) => {
    const { t } = useTranslation();
    const { getAccessToken } = useAuth();
    const [selectedSheets, setSelectedSheets] = useState<
        {
            amount: number;
            sheetTemplate: AbstractBaseSheetTemplate;
        }[]
    >([]);

    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: async () => {
            const accessToken = await getAccessToken();
            if (accessToken === undefined) throw new Error("No access token");
            const res = await SheetService.addSheets(projectId, selectedSheets, accessToken);
            return res;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["projectDataCheck"] });
            fetchData();
            handleClose();
        },
    });

    const handleSelectedSheetKey = (sheetTemplate: AbstractBaseSheetTemplate, amount = 1, keepSelected = false) => {
        const isSelected = selectedSheets.some(selectedSheet => selectedSheet.sheetTemplate.id === sheetTemplate.id);
        if (isSelected && !keepSelected) {
            setSelectedSheets(
                selectedSheets.filter(selectedSheet => selectedSheet.sheetTemplate.id !== sheetTemplate.id)
            );
        } else {
            setSelectedSheets(selectedSheets => [
                ...selectedSheets.filter(selectedSheet => selectedSheet.sheetTemplate.id !== sheetTemplate.id),
                { amount: amount, sheetTemplate: sheetTemplate },
            ]);
        }
    };

    const handleAddDiagram = (sheetTemplate: LocationSpecificTemplate) => {
        setSelectedSheets(selectedSheets => [...selectedSheets, { amount: 1, sheetTemplate: sheetTemplate }]);
    };

    const handleDeleteDiagram = (sheetTemplate: LocationSpecificTemplate) => {
        const currentSelectedSheetTemplates = [...selectedSheets.map(selected => selected.sheetTemplate)];
        const index = currentSelectedSheetTemplates.indexOf(sheetTemplate);
        if (index === -1) return;
        const currentSelectedSheets = [...selectedSheets];
        currentSelectedSheets.splice(index, 1);
        setSelectedSheets(currentSelectedSheets);
    };

    return (
        <Modal size="xl" show={true} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CreateSheetsContext
                    selectedSheets={selectedSheets}
                    handleSelectSheet={handleSelectedSheetKey}
                    handleAddDiagram={handleAddDiagram}
                    handleDeleteDiagram={handleDeleteDiagram}
                    handleEmptySelectedSheets={() => setSelectedSheets([])}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("buttons.cancel")}
                </Button>
                <Button
                    variant="primary"
                    disabled={mutation.isLoading}
                    onClick={() => {
                        mutation.mutate();
                    }}
                >
                    {mutation.isLoading ? t("status.loading") : t("buttons.add")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateSheetsModal;
