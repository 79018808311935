import React, { useRef, useState } from "react";
import { Button, ButtonProps, Card } from "react-bootstrap";
import { CheckCircleFill, Circle, ArrowUp, ArrowDown, Icon } from "react-bootstrap-icons";
import { useHover } from "hooks";
import {
    ILine,
    ILineItem,
    IPseudoButton,
    ICheckBox,
    ICardRowItem,
    ICardRow,
    ICheckBoxWithPriority as ICheckBoxCardWithPriority,
} from "../../types/layout";

export const Line = ({ children, className }: ILine): JSX.Element => (
    <div className={`d-flex ${className ? className : ""}`}>{children}</div>
);
export const LineItem = ({
    grow,
    userSelect,
    className,
    onClick,
    children,
    role,
    style,
    ...props
}: ILineItem): JSX.Element => {
    const { p, px, py, pl, pb, pt, pr, m, mx, my, ml, mb, mt, mr } = props;
    const classNames = () => {
        const stringBuilder = [];
        if (className) stringBuilder.push(className);
        if (userSelect) stringBuilder.push(`user-select-${userSelect}`);
        if (grow) stringBuilder.push("flex-grow-1");
        if (p) stringBuilder.push(`p-${p}`);
        if (px) stringBuilder.push(`px-${px}`);
        if (py) stringBuilder.push(`py-${py}`);
        if (pl) stringBuilder.push(`ps-${pl}`);
        if (pb) stringBuilder.push(`pb-${pb}`);
        if (pt) stringBuilder.push(`pt-${pt}`);
        if (pr) stringBuilder.push(`pe-${pr}`);
        if (m) stringBuilder.push(`m-${m}`);
        if (mx) stringBuilder.push(`mx-${mx}`);
        if (my) stringBuilder.push(`my-${my}`);
        if (ml) stringBuilder.push(`ms-${ml}`);
        if (mb) stringBuilder.push(`mb-${mb}`);
        if (mt) stringBuilder.push(`mt-${mt}`);
        if (mr) stringBuilder.push(`me-${mr}`);
        return stringBuilder;
    };
    return (
        <div className={classNames().join(" ")} role={role} onClick={onClick} style={style}>
            {children}
        </div>
    );
};
export const PseudoButton = ({ className, variant, children, onClick }: IPseudoButton): JSX.Element => (
    <div className={`btn${variant ? " btn-".concat(variant) : ""} ${className}`} onClick={onClick}>
        {children}
    </div>
);

export const CheckboxCard: React.FC<ICheckBox> = ({ onClick, selected, className, noHoverPointer, children }) => {
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);

    return (
        <Card
            ref={hoverRef}
            className={`my-1 transition  ${className ? className : ""} ${noHoverPointer ? "" : "pointer"}`}
            onClick={onClick}
            border={selected ? "primary" : undefined}
            bg={isHover ? "light" : undefined}
        >
            <Card.Body>{children}</Card.Body>
        </Card>
    );
};

export const CheckboxCardWithPriority = ({
    onClick,
    intialSelected,
    className,
    onClickArrowUp,
    onClickArrowDown,
    border,
    children,
}: ICheckBoxCardWithPriority): JSX.Element => {
    const [selected, setSelected] = useState(intialSelected || false);
    const [hover, setHover] = useState(false);

    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setSelected(prevState => !prevState);
        if (onClick) {
            onClick();
        }
    };

    const handleOnClickArrowUp = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (onClickArrowUp) {
            onClickArrowUp();
        }
    };
    const handleOnClickArrowDown = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (onClickArrowDown) {
            onClickArrowDown();
        }
    };
    return (
        <Card
            className={`my-1 transition pointer ${className ? className : ""}`}
            onClick={handleOnClick}
            border={selected && border ? "primary" : undefined}
            bg={hover || !selected ? "light" : undefined}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Card.Body className="py-2">
                <Line>
                    <LineItem userSelect="none" grow>
                        {children}
                    </LineItem>
                    {onClickArrowUp && (
                        <LineItem mx={3}>
                            <Button
                                onClick={handleOnClickArrowUp}
                                variant="link"
                                className="d-inline-flex align-items-center m-0 p-0"
                            >
                                <ArrowUp className="text-dark" />
                            </Button>
                        </LineItem>
                    )}
                    {onClickArrowDown && (
                        <LineItem mx={3}>
                            <Button
                                onClick={handleOnClickArrowDown}
                                variant="link"
                                className="d-inline-flex align-items-center m-0 p-0"
                            >
                                <ArrowDown className="text-dark" />
                            </Button>
                        </LineItem>
                    )}
                    <LineItem mx={3}>
                        <Button
                            onClick={handleOnClick}
                            variant="link"
                            className="d-inline-flex align-items-center m-0 p-0"
                        >
                            {selected ? <CheckCircleFill className="text-success" /> : <Circle className="text-dark" />}
                        </Button>
                    </LineItem>
                </Line>
            </Card.Body>
        </Card>
    );
};

export const CardRow = ({ children }: ICardRow): JSX.Element => <div className="d-flex">{children}</div>;
export const CardRowItem = ({ grow, className, children }: ICardRowItem): JSX.Element => (
    <div className={grow ? [className, "flex-grow-1"].join(" ") : className}>{children}</div>
);

interface IconButtonProps extends ButtonProps {
    icon: Icon;
    iconPosition: "left" | "right";
    className?: string;
    children: React.ReactNode;
}

export const IconButton: React.FC<IconButtonProps> = ({ icon, iconPosition, children, className, ...props }) => {
    const SelectedIcon = icon;
    return (
        <Button className={`d-inline-flex align-items-center ${className ? className : ""}`} {...props}>
            {iconPosition === "left" && <SelectedIcon />}
            <div className={`m${iconPosition === "left" ? "l" : "r"}-1`}>{children}</div>
            {iconPosition === "right" && <SelectedIcon />}
        </Button>
    );
};
