import React from "react";
import { Button, Col, ListGroup } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { ThemeValuePair } from "types";
import sortBy from "lodash/sortBy";
import ThemeValuePairListItem from "./ThemeValuePairListItem";
import { useTranslation } from "react-i18next";
import _ from "lodash";

interface Props {
    code: string;
    lexicographicallySorted: boolean;
    themeValuePairs: ThemeValuePair[];
    handleShowModal: (state: boolean) => void;
    handleSelectThemeValuePair: (id: string) => void;
    handleDeletetThemeValuePair: (id: string) => void;
}

const ThemeValuePairList: React.FC<Props> = ({
    code,
    lexicographicallySorted,
    handleShowModal,
    themeValuePairs,
    handleSelectThemeValuePair,
    handleDeletetThemeValuePair,
}) => {
    const { t } = useTranslation();

    let sortedThemeValuePairs = themeValuePairs;
    if (lexicographicallySorted) {
        //lexicographically sort the theme value pairs
        sortedThemeValuePairs = sortBy(themeValuePairs, pair => pair.code as string);
    } else {
        //numerical sort the theme value pairs
        sortedThemeValuePairs = sortBy(themeValuePairs, pair => parseInt(pair.code));
    }

    return (
        <Col className="mt-2 mb-6" xs={{ span: 12, order: 12 }} md={{ span: 12, order: 12 }} lg={{ order: 1 }}>
            <h3>
                <small>
                    <strong>{t("pages.FAST.value-lists.theme-value-pairs.values")}</strong>
                </small>
                <Button
                    variant="outline-success"
                    size="sm"
                    className="d-inline-flex align-items-center ms-2 ps-1"
                    onClick={() => handleShowModal(true)}
                >
                    <Plus />
                    <div className="ms-1">{t("pages.FAST.value-lists.theme-value-pairs.add-value")}</div>
                </Button>
            </h3>
            <ListGroup style={{ overflowY: "auto", maxHeight: "700px" }}>
                {sortedThemeValuePairs.map(vp => (
                    <ThemeValuePairListItem
                        key={vp.id}
                        valueCode={code}
                        valuePair={vp}
                        handleSelectThemeValuePair={handleSelectThemeValuePair}
                        handleDeleteThemeValuePair={handleDeletetThemeValuePair}
                    />
                ))}
            </ListGroup>
        </Col>
    );
};

export default ThemeValuePairList;
