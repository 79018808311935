import { useContext } from "react";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserCompanyRole } from "types";
import { useContextModel } from "hooks";
import { CompanyContext, UserContext } from "context";
import { ToolTipButton } from "../ToolTip/ToolTip";
import { ContainerWithStatusAlertHandling } from "../../hoc/withStatusAlerts";
import EditCompanyLogo from "./EditCompanyLogo";
import FormFieldWithAcceptButton from "../Shared/Form/FormFieldWithAcceptButton";

const CompanySettingsContainer = (): JSX.Element => {
    const { t } = useTranslation();
    const { updateCompany, company, error, loading, status } = useContext(CompanyContext);
    const { user } = useContext(UserContext);
    const { contextModel, loadingModel, knowledgeStillExist } = useContextModel();
    const isAdminUser = user && user.role === UserCompanyRole.admin;

    const validDateFormat = ["dd-mm-yyyy", "mm-dd-yyyy", "yyyy-mm-dd", "yyyy-dd-mm", "dd-yyyy-mm", "mm-yyyy-dd"];

    const handleSaveField = async (property: string, value: string | number) => {
        if (!isAdminUser || !company?.id) return;
        await updateCompany(company?.id as string, { ...company, [property]: value });
    };

    return (
        <ContainerWithStatusAlertHandling
            className="m-0 p-4 h-100"
            error={error}
            loading={loading}
            status={status.length > 0}
            statusTitle={status}
            fluid
            style={{ overflowY: "auto" }}
        >
            <h1>{t("pages.company.settings.title")}</h1>
            <h6 className="text-muted">Manage company settings</h6>
            {company && (
                <>
                    <Row className="mt-2">
                        <Col sm="12" md="9" lg="6">
                            <Form className="d-grid gap-2" noValidate>
                                <FormGroup controlId="name">
                                    <Form.Label>
                                        {t("pages.company.settings.fields.name")}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <FormFieldWithAcceptButton
                                        initialValue={company.name || ""}
                                        handleSaveField={value => handleSaveField("name", value)}
                                        permaDisabled={!isAdminUser}
                                        validInputRegex={/^[a-zA-Z0-9æøåÆØÅ ]{1,}$/}
                                    />
                                </FormGroup>
                                <Form.Group controlId="dateFormat">
                                    <Form.Label>{t("pages.company.settings.fields.date")}</Form.Label>
                                    <ToolTipButton
                                        eventId="hover_login"
                                        className="ms-1"
                                        tooltipId="company.helpDateformatButton"
                                        tooltipMessage={t("pages.company.settings.fields.date-help")}
                                        tooltipPlacement="right"
                                        variant="outline-secondary"
                                    />
                                    <Form.Select
                                        value={company.dateFormat}
                                        onChange={e => handleSaveField("dateFormat", e.target.value)}
                                        disabled={!isAdminUser}
                                    >
                                        {validDateFormat.map((dateFormat, index) => (
                                            <option value={dateFormat} key={index}>
                                                {dateFormat}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {t("status.value-deleted")}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="knowledgeId">
                                    <Form.Label>{t("pages.company.settings.fields.knowledge-area")}</Form.Label>
                                    <Form.Select
                                        isInvalid={!knowledgeStillExist(company?.knowledgeId || "") && !loadingModel}
                                        value={company.knowledgeId}
                                        onChange={e => handleSaveField("knowledgeId", e.target.value)}
                                        disabled={!isAdminUser}
                                    >
                                        <option value="" key="">
                                            {t("status.empty-value")}
                                        </option>
                                        {contextModel.knowledges.map((knowledge, index) => (
                                            <option value={knowledge.id} key={index}>
                                                {knowledge.code} - {knowledge.value}
                                                {knowledge.preferred && " ★ "}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {t("status.value-deleted")}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <EditCompanyLogo isAdminUser={!!isAdminUser} user={user} />
                            </Form>
                        </Col>
                    </Row>
                </>
            )}
        </ContainerWithStatusAlertHandling>
    );
};

export default CompanySettingsContainer;
