import React, { useContext } from "react";
import { ProjectContext } from "context";
import { useFeatureFlag, useProject, useProjectId } from "hooks";
import FeatureToggleButton from "../Shared/Buttons/FeatureToggleButton";

const ProjectFeatureToggle = () => {
    const projectId = useProjectId();
    const { project } = useProject(projectId);
    const { handleSaveProject } = useContext(ProjectContext);
    const { enabledCompanyFeatureFlags } = useFeatureFlag();

    const handleToggleFAST = () => {
        if (!project) {
            return;
        }
        const toggledFeature = project?.projectFeatures?.FAST !== true;
        const updatedProjectFeatures = {
            ...project?.projectFeatures,
            FAST: toggledFeature,
        };
        handleSaveProject({
            ...project,
            projectFeatures: updatedProjectFeatures,
        });
    };
    const handleToggleFLAT = () => {
        if (!project) {
            return;
        }
        const toggledFeature = project?.projectFeatures?.FLAT !== true;
        const updatedProjectFeatures = {
            ...project?.projectFeatures,
            FLAT: toggledFeature,
        };
        handleSaveProject({
            ...project,
            projectFeatures: updatedProjectFeatures,
        });
    };
    const handleToggleBE18 = () => {
        if (!project) {
            return;
        }
        const toggledFeature = project?.projectFeatures?.BE18 !== true;
        const updatedProjectFeatures = {
            ...project?.projectFeatures,
            BE18: toggledFeature,
        };
        handleSaveProject({
            ...project,
            projectFeatures: updatedProjectFeatures,
        });
    };
    return (
        <>
            {enabledCompanyFeatureFlags.FAST &&
                <FeatureToggleButton
                    feature="FAST"
                    handleToggleProjectFeature={handleToggleFAST}
                    selected={project?.projectFeatures?.FAST || false}
                    disabled={project?.archived}
                />}
            {enabledCompanyFeatureFlags.FLAT &&
                <FeatureToggleButton
                    feature="FLAT"
                    handleToggleProjectFeature={handleToggleFLAT}
                    selected={project?.projectFeatures?.FLAT || false}
                    disabled={project?.archived}
                />}
            {enabledCompanyFeatureFlags.BE18 &&
                <FeatureToggleButton
                    feature="BE18"
                    handleToggleProjectFeature={handleToggleBE18}
                    selected={project?.projectFeatures?.BE18 || false}
                    disabled={project?.archived}
                />}
        </>
    );
};
export default ProjectFeatureToggle;
