import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { UserCreationService } from "services";

const InvitedUserForm: React.FC = () => {
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [inputIsinvalid, setInputIsInvalid] = useState<boolean>(false);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token") || "";
    const history = useHistory();
    const { t } = useTranslation();

    const redirect = () => {
        history.push("/");
    };
    if (!token) {
        setTimeout(redirect, 5000);
    }

    const passwordRegex = /^(?=.*?\p{Lu})(?=.*?\p{Ll})(?=.*?[0-9]).{8,50}$/u;
    const passwordIsValid = passwordRegex.test(password);

    const nameIsValid = name !== "" && name.length >= 3;

    useEffect(() => {
        if (inputIsinvalid) {
            setInputIsInvalid(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, password]);

    const { data: invitedUser, isLoading } = useQuery({
        queryKey: ["invitedUser"],
        queryFn: async () => UserCreationService.getInvitedUserData(token),
        enabled: !!token,
        onSuccess: user => {
            setName(user.name);
            setEmail(user.email);
        },
    });

    const handleSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        if (passwordIsValid && nameIsValid) {
            CreateInvitedUserMutation.mutate();
        } else {
            setInputIsInvalid(true);
        }
    };

    const CreateInvitedUserMutation = useMutation({
        mutationFn: async () => {
            if (!inputIsinvalid) {
                await UserCreationService.createInvitedUser(token, name, password);
            } else {
                setInputIsInvalid(true);
            }
        },
        onSuccess: async () => {
            toast.success(t("pages.invited-user.success"));
            setTimeout(redirect, 3000);
        },
        onError: error => {
            toast.error(t("pages.invited-user.error"));
        },
    });

    return (
        <>
            {token === "" ? (
                <div>
                    <h2>{t("pages.invited-user.invalid-token")}</h2>
                </div>
            ) : (
                <div>
                    {isLoading ? (
                        <Spinner animation="border" />
                    ) : (
                        <div style={{ width: "25rem" }}>
                            <h4>
                                {
                                    t("pages.invited-user.title", {
                                        company: invitedUser?.companyName,
                                    }) as string
                                }
                            </h4>
                            <h6>{t("pages.invited-user.subtitle")}</h6>
                            <Form className="d-grid gap-2 my-4">
                                <Form.Group controlId="email">
                                    <Form.Label>{t("pages.invited-user.email")}</Form.Label>
                                    <Form.Control type="email" value={email} disabled />
                                </Form.Group>
                                <Form.Group controlId="name">
                                    <Form.Label>{t("pages.invited-user.name")}</Form.Label>
                                    <Form.Control
                                        onChange={event => setName(event.target.value)}
                                        isInvalid={!nameIsValid && inputIsinvalid}
                                        placeholder={t("pages.invited-user.name-placeholder") as string}
                                        type="text"
                                        value={name}
                                        disabled={CreateInvitedUserMutation.isSuccess}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t("pages.invited-user.name-invalid")}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t("pages.invited-user.password")}</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            id="password"
                                            isInvalid={!passwordIsValid && inputIsinvalid}
                                            onChange={event => {
                                                setPassword(event.target.value);
                                            }}
                                            placeholder={t("pages.login.fields.password-placeholder") as string}
                                            type={showPassword ? "text" : "password"}
                                            value={password}
                                            disabled={CreateInvitedUserMutation.isSuccess}
                                        />
                                        <InputGroup.Text
                                            onClick={() => setShowPassword(!showPassword)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {showPassword ? <EyeSlash /> : <Eye />}
                                        </InputGroup.Text>
                                        <Form.Control.Feedback type="invalid">
                                            <Alert variant="warning">{t("pages.invited-user.password-invalid")}</Alert>
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Form>
                            <Button
                                className="d-grid gap-2"
                                variant="primary"
                                type="submit"
                                onClick={handleSubmit}
                                disabled={
                                    CreateInvitedUserMutation.isLoading ||
                                    CreateInvitedUserMutation.isSuccess ||
                                    isLoading
                                }
                            >
                                {t("pages.invited-user.button-create-account")}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default InvitedUserForm;
