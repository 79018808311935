import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { ElectricitySystem } from "types";
import { useTranslation } from "react-i18next";

type Props<TSystemType> = {
    fields: TSystemType;
    setFields: React.Dispatch<React.SetStateAction<TSystemType>>;
};

const ElectricityForm: React.FC<Props<ElectricitySystem>> = ({ fields, setFields }) => {
    const { t } = useTranslation();

    const handleCheckField = <Element extends HTMLInputElement>(e: React.ChangeEvent<Element>) => {
        const id = e.target.id as keyof ElectricitySystem;
        setFields(val => ({ ...val, [id]: !val[id] }));
    };

    return (
        <Row>
            <Form.Group as={Col}>
                <p>{t("pages.FLAT.systems.card-modal.electricity.switchboard")}:</p>
                <Form.Check
                    id="integratedSwitchboard"
                    type="radio"
                    label={t("pages.FLAT.systems.card-modal.electricity.integrated")}
                    checked={fields.integratedSwitchboard}
                    onChange={handleCheckField}
                />
                <Form.Check
                    id="integratedSwitchboard"
                    type="radio"
                    label={t("pages.FLAT.systems.card-modal.electricity.in-shaft")}
                    checked={!fields.integratedSwitchboard}
                    onChange={handleCheckField}
                />
            </Form.Group>
        </Row>
    );
};

export default ElectricityForm;
