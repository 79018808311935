import { FC, HTMLAttributes } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { Check, Download, FileEarmarkText, ChevronLeft } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import { useEnergyRapport, useLogger, useProjectId } from "hooks";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { downloadBlob } from "utils/helpers";

const CompletedReportForm: FC<HTMLAttributes<HTMLBaseElement>> = () => {
    const { documentTitle, generateWordReport, handleInitiateNewReport, fields, tables } = useEnergyRapport();
    const projectId = useProjectId();
    const logger = useLogger();
    const { t } = useTranslation();
    const history = useHistory();
    const handleGoToDocumentList = () => {
        const url = `/projects/${projectId}/be18/documents`;
        history.push(url);
    };

    const downloadDocumentMutation = useMutation({
        mutationFn: generateWordReport,
        onSuccess: async (data, variables) => downloadBlob(data, `${variables.title || documentTitle()}.docx`),
        onError: error => {
            const err = error as Error;
            logger.log(err.message, err);
        },
    });

    return (
        <Row className="justify-content-center align-items-center h-100 text-center">
            <div className="align-self-start">
                <Button
                    size="sm"
                    variant="link"
                    className="d-inline-flex align-items-center"
                    onClick={() => handleInitiateNewReport()}
                >
                    <ChevronLeft />
                    <div className="ms-1">{t("pages.BE18.completed.reset")}</div>
                </Button>
            </div>
            <Col xs={6} className="grow-1">
                <div className="d-flex align-items-end justify-content-center">
                    <Check className="text-success me-1" size="4rem" />
                    <h1>{t("pages.BE18.completed.title")}</h1>
                </div>
                <p>
                    <i>{t("pages.BE18.completed.subtitle")}</i>
                </p>
                <div className="mt-4">
                    <FileEarmarkText fontSize="6rem" />
                </div>
                <p className="mt-2 mb-4">
                    <small>{documentTitle()}.docx</small>
                </p>
                <div className="d-flex flex-column justify-content-center">
                    <div>
                        <Button
                            variant="primary"
                            className="d-inline-flex align-items-center"
                            disabled={downloadDocumentMutation.isLoading}
                            onClick={() => downloadDocumentMutation.mutate({ fieldsData: fields, tablesData: tables })}
                        >
                            {downloadDocumentMutation.isLoading ? (
                                <Spinner animation="border" role="status" />
                            ) : (
                                <Download />
                            )}
                            <div className="ms-2">{t("buttons.download")}</div>
                        </Button>
                    </div>
                    <div>
                        <Button
                            size="sm"
                            variant="link"
                            className="d-inline-flex align-items-center"
                            onClick={() => handleGoToDocumentList()}
                        >
                            <i>{t("pages.BE18.completed.go-to-documents")}</i>
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default CompletedReportForm;
