import axios from "axios";
import { API_URL } from "config";
import { User } from "types";

type AbstractUserService = {
    getCompanyUsers: (companyId: string, accessToken: string) => Promise<User[]>;
    inviteCompanyUser: (companyId: string, user: Partial<User>, accessToken: string) => Promise<void>;
    removeCompanyUser: (companyId: string, id: string, accessToken: string) => Promise<void>;
    getCompanyInvitedUsers: (companyId: string, accessToken: string) => Promise<Partial<User>[]>;
};

export const UserService: AbstractUserService = {
    getCompanyUsers: async (companyId: string, accessToken: string) => {
        const requestUrl = `${API_URL}/v1/companies/${companyId}/users`;
        const response = await axios.get(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.data;
    },
    getCompanyInvitedUsers: async (companyId: string, accessToken: string) => {
        const requestUrl = `${API_URL}/v1/companies/${companyId}/invited-users`;
        const response = await axios.get(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.data;
    },
    inviteCompanyUser: async (companyId: string, user: Partial<User>, accessToken: string) => {
        const { email, role, name } = user;
        const requestUrl = `${API_URL}/v1/companies/${companyId}/users`;
        const requestJson = JSON.stringify({
            email,
            role,
            company: companyId,
            name,
        });
        return await axios.post(requestUrl, requestJson, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
    },
    removeCompanyUser: async (companyId: string, id: string, accessToken: string) => {
        const requestUrl = `${API_URL}/v1/companies/${companyId}/users/${id}`;
        return await axios.delete(requestUrl, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    },
};
