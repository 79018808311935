import React, { useContext, useState } from "react";
import { Col, Row, Image, Card } from "react-bootstrap";
import { CompanyContext } from "context";
import { User } from "types";
import { useTranslation } from "react-i18next";
import RemoveLogoButton from "./RemoveLogoButton";

type Props = {
    isAdminUser: boolean;
    user: User | null;
}

const EditCompanyLogo: React.FC<Props> = ({ isAdminUser, user }) => {
    const { t } = useTranslation();
    const [dragActive, setDragActive] = useState(false);
    const { logoUrl, uploadCompanyLogo, companyHasLogo } = useContext(CompanyContext);
    const imageDateHash = `&date=${new Date().getTime()}`;

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.files && e.target.files.length === 1) {
            const file = e.target.files[0];
            uploadCompanyLogo(file);
        }

    };

    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const _handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length === 1) {
            const file = e.dataTransfer.files[0];
            uploadCompanyLogo(file);
        }
    };

    return (
        <>
            <Row>
                <Col>
                    <p>{t("pages.company.settings.fields.logo")}</p>
                </Col>
            </Row>
            <Card
                bg={dragActive ? "secondary" : ""}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={_handleDrop}
                className="dashed-boder"
            >
                <Card.Body>
                    <Row className="d-flex">
                        <Col className="d-flex flex-column justify-content-start p-4 mx-2 bg-light flex-grow-0 rounded-lg">
                            <Image style={{ maxWidth: "70px", maxHeight: "70px" }} src={`${logoUrl}${companyHasLogo ? imageDateHash : ""}`} rounded />
                            {((companyHasLogo) && isAdminUser && user?.company) && <RemoveLogoButton companyId={user?.company} />}
                        </Col>
                        <Col className="d-flex flex-wrap align-content-center">
                            {isAdminUser && user?.company && <>
                                <h5 className="text-muted">
                                    {t("pages.company.settings.fields.logo-title1")}
                                </h5>
                                <label style={{ cursor: "pointer" }} htmlFor="file-upload" className="h5 px-1">{t("pages.company.settings.fields.logo-title2")}</label>
                                <input
                                    style={{ visibility: "hidden" }}
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    multiple={false}
                                    onChange={handleChange}
                                />
                                <h5 className="text-muted">
                                    {t("pages.company.settings.fields.file-formats")}
                                </h5>
                            </>}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
};

export default EditCompanyLogo;