import React, { FC, useContext } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { LoggingContext } from "context";
import ChangeLogEntry from "./ChangeLogEntry";
import { reverse, sortBy } from "lodash";
import { useTranslation } from "react-i18next";

const ChangeLogContainer: FC = () => {
    const { plugin, webapp } = useContext(LoggingContext);
    const { t } = useTranslation();

    return (
        <Container fluid className="p-4 h-100" style={{ overflow: 'auto' }}>
            <Tabs defaultActiveKey="frontEnd" id="version-tabs" className="mb-3">
                <Tab eventKey="frontEnd" title={t("changelog.webapp-tab")}>
                    <h1>{t("changelog.webapp-title")}</h1>
                    <h6 className="text-muted">{t("changelog.webapp-subtitle")}</h6>
                    <hr />
                    {reverse(sortBy(webapp, "version")).map(entry => <ChangeLogEntry key={entry.version} changeLogEntry={entry} />)}
                </Tab>
                <Tab eventKey="plugin" title={t("changelog.revit-tab")}>
                    <h1>{t("changelog.revit-title")}</h1>
                    <h6 className="text-muted">{t("changelog.revit-subtitle")}</h6>
                    <hr />
                    {reverse(sortBy(plugin, "version")).map(entry => <ChangeLogEntry key={entry.version} changeLogEntry={entry} />)}
                </Tab>
            </Tabs>
        </Container>
    )
};

export default ChangeLogContainer;