import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useProject } from "hooks";
import RevitParameterContainer from "../Parameters/RevitParameterContainer";
import { useTranslation } from "react-i18next";

interface Props {
    projectId: string;
    handleClose: () => void;
}

const EditProjectRevitParametersModalForm: React.FC<Props> = ({ projectId, handleClose }) => {
    const { t } = useTranslation();
    const { revitParameters, createRevitParameter, updateRevitParameter, deleteRevitParameter } = useProject(projectId);

    return (
        <Modal show={true} onHide={handleClose} backdrop="static" scrollable size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("pages.FAST.settings.revit-parameters.title")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6 className="text-muted">{t("pages.FAST.settings.revit-parameters.subtitle")}</h6>
                <RevitParameterContainer
                    revitParameters={revitParameters}
                    createRevitParameter={createRevitParameter}
                    updateRevitParameter={updateRevitParameter}
                    deleteRevitParameter={deleteRevitParameter}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    {t("buttons.close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditProjectRevitParametersModalForm;
