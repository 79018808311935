import React, { ReactNode, useEffect, useState, useContext, createContext } from "react";
import { Apartment } from "types";
import { LoggingContext } from "context";
import { firestore } from "config";
import { addDoc, collection, deleteDoc, doc, onSnapshot, updateDoc } from "@firebase/firestore";

interface IApartmentsContext {
    error: Error | null | undefined;
    handleCreateApartment: (apartment: Apartment) => void;
    handleUpdateApartment: (apartment: Apartment) => void;
    handleDeleteApartment: (apartmentId: string) => void;
    loadingApartments: boolean;
    apartments: Apartment[];
}

export const ApartmentsContext = createContext({} as IApartmentsContext);

interface Props {
    projectId?: string;
    children?: ReactNode;
}

const ApartmentsProvider = ({ projectId, children }: Props) => {
    const [loadingApartments, setLoadingApartments] = useState<boolean>(true);
    const [apartments, setApartments] = useState<Apartment[]>([]);
    const [error, setError] = useState<Error | null>();
    const { logger } = useContext(LoggingContext);

    useEffect(
        () => {
            if (!projectId) {
                return;
            }
            const apartmentsRef = collection(firestore, 'projects', projectId, 'apartments');

            setLoadingApartments(true);
            onSnapshot(apartmentsRef, apartmentsSnapshot => {
                const apartments: Apartment[] = [];
                apartmentsSnapshot.forEach(apartmentDocument => {
                    const apartment = apartmentDocument.data() as Apartment;
                    apartment.id = apartmentDocument.id;
                    const rooms = apartment.rooms.sort((a, b) => (a.priority || 0) - (b.priority || 0));
                    const appartmentsWithSortedRooms = { ...apartment, rooms: rooms };
                    apartments.push(appartmentsWithSortedRooms as Apartment);

                });
                setApartments(apartments);
                setLoadingApartments(false);
            },
                error => {
                    setLoadingApartments(false);
                    setError(new Error("Lejlighedstyper kunne ikke hentes. Prøv venligst igen."))
                    logger.error("Couldn't fetch apartments", error);
                }
            );
        }, [projectId, logger]);

    const handleCreateApartment = async (apartment: Apartment) => {
        if (!projectId) {
            setError(new Error("Lejlighedstype kunne ikke oprettes, prøv igen."));
            return;
        }
        try {
            setLoadingApartments(true);
            const rooms = apartment.rooms.map((room, index) => ({ ...room, priority: index + 1 }));
            const apartmentsCollectionRef = collection(firestore, "projects", projectId, "apartments");
            addDoc(apartmentsCollectionRef, cleanApartment({ ...apartment, rooms: rooms }));
        } catch (error) {
            setError(new Error("Lejlighedstype kunne ikke opdateres, prøv igen."));
            logger.error("Failed to update apartment", error);
        } finally {
            setLoadingApartments(false);
        }
    }

    const handleUpdateApartment = async (apartment: Apartment) => {
        if (!projectId) {
            setError(new Error("Lejlighedstype kunne ikke redigeres, prøv igen."));
            return;
        }
        try {
            setLoadingApartments(true);
            const rooms = apartment.rooms.map((room, index) => ({ ...room, priority: index + 1 }));
            const docRef = doc(firestore, "projects", projectId, "apartments", apartment.id);
            updateDoc(docRef, cleanApartment({ ...apartment, rooms: rooms }));
        } catch (error) {
            setError(new Error("Lejlighedstype kunne ikke opdateres, prøv igen."));
            logger.error("Failed to update apartment", error);
        } finally {
            setLoadingApartments(false);
        }
    }

    const handleDeleteApartment = async (apartmentId: string) => {
        if (!projectId) {
            setError(new Error("Lejlighedstype kunne ikke slettes, prøv igen."));
            return;
        }
        try {
            setLoadingApartments(true);
            const docRef = doc(firestore, "projects", projectId, "apartments", apartmentId);
            deleteDoc(docRef);
        } catch (error) {
            setError(new Error("Lejlighedstype kunne ikke slettes, prøv igen."));
            logger.error("Failed to delete apartment", error);
        } finally {
            setLoadingApartments(false);
        }

    }

    const cleanApartment = (apartment: Partial<Apartment>) => {
        delete apartment.id;
        return { ...apartment, areaSize: Number(apartment.areaSize) };
    };

    return (<ApartmentsContext.Provider
        value={{
            error,
            handleCreateApartment,
            handleUpdateApartment,
            handleDeleteApartment,
            loadingApartments,
            apartments,
        }}
    >
        {children}
    </ApartmentsContext.Provider>
    );
}
export default ApartmentsProvider;