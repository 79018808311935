import axios from "axios";
import { API_URL } from "config";

export const getProjectDataCheck = async (projectId: string, accessToken: string) => {
    const res = await axios.get<string, { data: { sheets: number; buildings: number } }>(
        `${API_URL}/v1/projects/${projectId}/check-data`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
    }
    );
    return res.data;
};
