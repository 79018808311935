import { ListGroup } from 'react-bootstrap';
import { useContextModel } from 'hooks';
import { ISheetTemplate } from 'types';

interface Props {
    template: ISheetTemplate;
    handleClick: () => void;
}

const SheetTemplateListItem: React.FC<Props> = ({ template, handleClick }) => {
    const { getSheetTypeById, getDisciplineById, getKnowledgeById, loadingModel } = useContextModel()

    const knowledge = getKnowledgeById(template.knowledgeId);
    const discipline = getDisciplineById(template.disciplineId);
    const sheetType = getSheetTypeById(template.sheetTypeId);

    return (
        <ListGroup.Item action onClick={handleClick} className="d-flex justify-content-between align-items-center">
            <small className={"text-truncate"} style={{ width: "25%" }}>{template.title}</small>
            <small style={{ width: "25%" }}>{!loadingModel && `${knowledge.code}`}</small>
            <small style={{ width: "25%" }}>{!loadingModel && `${sheetType.code} ${sheetType.value}`}</small>
            <small style={{ width: "25%" }}>{!loadingModel && `${discipline.code}`}</small>
        </ListGroup.Item>
    );
};

export default SheetTemplateListItem;