import OverlayIconButton from "components/Shared/Buttons/OverlayIconButton";
import _ from "lodash";
import React from "react";
import { ListGroup, Placeholder } from "react-bootstrap";
import { PersonXFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { User } from "types";

interface Props {
    users: Partial<User>[];
    isAdminUser: boolean;
    handleRemoveUser: (user: User) => void;
    isLoading: boolean;
}

const CompanyUsersList: React.FC<Props> = ({ users, isAdminUser, handleRemoveUser, isLoading }) => {
    const { t } = useTranslation();

    return (
        <div className="overflow-auto">
            <ListGroup variant="flush">
                <ListGroup.Item
                    style={{ backgroundColor: "#E4ECFB" }}
                    className="d-flex justify-content-between align-items-center"
                >
                    <small style={{ width: "35%" }}>{t("pages.company.users.header-name")}</small>
                    <small style={{ width: "35%" }}>{t("pages.company.users.header-email")}</small>
                    <small style={{ width: "10%" }}>{t("pages.company.users.header-role")}</small>
                    <div style={{ width: 30 }}></div>
                </ListGroup.Item>
                {isLoading ? (
                    Array.from({ length: 5 }, (_, index) => (
                        <Placeholder
                            as={ListGroup.Item}
                            className="d-flex justify-content-between align-items-center"
                            animation="glow"
                        >
                            <span style={{ width: "27%" }}>
                                <Placeholder xs={4} />
                            </span>
                            <span style={{ width: "27%" }}>
                                <Placeholder xs={4} />
                            </span>
                            <span style={{ width: "18%" }}>
                                <Placeholder xs={4} />
                            </span>
                        </Placeholder>
                    ))
                ) : (
                    <>
                        {_.orderBy(users, [user => user.name?.toLowerCase()], ["asc"]).map(user => (
                            <ListGroup.Item
                                key={user.id}
                                className="d-flex justify-content-between align-items-center text-truncate"
                            >
                                <div style={{ width: "35%" }}>
                                    {user.name}
                                    {user.invitedUser && <i>{t("pages.company.users.pending-invitation")}</i>}
                                </div>
                                <div style={{ width: "35%" }}>
                                    <i>{user.email}</i>
                                </div>
                                <div style={{ width: "10%" }}>{user.role}</div>
                                {isAdminUser && (
                                    <OverlayIconButton
                                        style={{ color: user.invitedUser ? "grey" : "red" }}
                                        overlayText={t("pages.company.users.button-remove-user")}
                                        className="float-right m-0 py-0 px-1"
                                        size="sm"
                                        disabled={user.invitedUser}
                                        variant="link"
                                        onClick={() => {
                                            handleRemoveUser(user as User);
                                        }}
                                    >
                                        <PersonXFill size={16} />
                                    </OverlayIconButton>
                                )}
                            </ListGroup.Item>
                        ))}
                    </>
                )}
            </ListGroup>
        </div>
    );
};

export default CompanyUsersList;
