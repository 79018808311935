import React from "react";
import { Badge } from "react-bootstrap";

interface BadgeProps {
    size?: number;
}

export const FastBadge: React.FC<BadgeProps> = ({ size }) => (
    <Badge style={{ color: "#495057", fontSize: size }} className="bg-fast me-1">
        FAST
    </Badge>
);

export const FlatBadge: React.FC<BadgeProps> = ({ size }) => (
    <Badge style={{ color: "#495057", fontSize: size }} className="bg-flat me-1">
        FLAT
    </Badge>
);

export const BE18Badge: React.FC<BadgeProps> = ({ size }) => (
    <Badge style={{ color: "#495057", fontSize: size }} className="bg-be18 me-1">
        BE18
    </Badge>
);
