import { useContext, useState } from "react";
import { Button, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { useContextModel, useContextTemplates } from "hooks";
import { UserContext } from "context";
import { IDocumentTemplate } from "types";
import { EMPTY_DOCUMENT_TEMPLATE } from "_constants";
import _ from "lodash";
import DocumentTemplateModal from "./DocumentTemplateModal";
import DocumentTemplateListItem from "./DocumentTemplateListItem";
import { useTranslation } from "react-i18next";

interface Props {
    title?: string;
}

const DocumentTemplatesContainer: React.FC<Props> = ({ title }) => {
    const [showTemplateModal, setShowTemplateModal] = useState<boolean>(false);
    const [selectedTemplate, setSelectedTemplate] = useState<IDocumentTemplate>(EMPTY_DOCUMENT_TEMPLATE);
    const [search, setSearch] = useState<string>("");
    const [filteredKnowledgeId, setFilteredKnowledgeId] = useState<string>("");
    const [filteredDocumentKindCategoryId, setFilteredDocumentKindCategoryId] = useState<string>("");
    const [filteredDisciplineId, setFilteredDisciplineId] = useState<string>("");
    const { user } = useContext(UserContext);
    const { t } = useTranslation();

    const { documentTemplates, createDocumentTemplate, updateDocumentTemplate, deleteDocumentTemplate } =
        useContextTemplates();
    const {
        contextModel,
        inCompanyContext,
        getKnowledgeById,
        getDisciplineById,
        getDocumentKindById,
        getDocumentKindCategoryByDocumentKindId,
    } = useContextModel();
    const canEditFields = user?.role === "admin" || !inCompanyContext;

    const searchDocumentTemplates = documentTemplates.filter(
        documentTemplate => !!(search === "") || documentTemplate.name.toLowerCase().includes(search.toLowerCase())
    );
    const filteredSheetTemplates = searchDocumentTemplates.filter(
        documentTemplate =>
            (!!(filteredKnowledgeId === "") || documentTemplate.knowledgeId === filteredKnowledgeId) &&
            (!!(filteredDocumentKindCategoryId === "") ||
                getDocumentKindCategoryByDocumentKindId(documentTemplate.documentKindId).id ===
                    filteredDocumentKindCategoryId) &&
            (!!(filteredDisciplineId === "") || documentTemplate.disciplineId === filteredDisciplineId)
    );

    const sortByKnowledgeCode = (st: IDocumentTemplate) => getKnowledgeById(st.knowledgeId).code;
    const sortByDisciplineCode = (st: IDocumentTemplate) => getDisciplineById(st.disciplineId).code;
    const sortByDocumentKindCategoryCode = (st: IDocumentTemplate) =>
        getDocumentKindCategoryByDocumentKindId(st.documentKindId).code;
    const sortByDocumentKindCode = (st: IDocumentTemplate) => getDocumentKindById(st.documentKindId).subCode;
    const sortedDocumentTemplates = _.sortBy(filteredSheetTemplates, [
        sortByKnowledgeCode,
        sortByDocumentKindCategoryCode,
        sortByDocumentKindCode,
        sortByDisciplineCode,
        "name",
    ]);

    const handleClose = () => {
        setSelectedTemplate(EMPTY_DOCUMENT_TEMPLATE);
        setShowTemplateModal(false);
    };

    const handleCreate = () => {
        setSelectedTemplate(EMPTY_DOCUMENT_TEMPLATE);
        setShowTemplateModal(true);
    };

    const handleEdit = (documentTemplate: IDocumentTemplate) => {
        setSelectedTemplate(documentTemplate);
        setShowTemplateModal(true);
    };

    return (
        <Container fluid className="m-0 p-4 h-100 w-100">
            <h1>{title ? title : t("pages.shared.templates.document.title")}</h1>
            <h6 className="text-muted">{t("pages.shared.templates.document.project-subtitle")}</h6>
            <div className="d-flex flex-column h-100-4rem">
                <div className="mt-2">
                    <Button
                        disabled={!canEditFields}
                        variant="primary"
                        className="d-inline-flex align-items-center"
                        onClick={() => handleCreate()}
                    >
                        <Plus size={20} />
                        <div className="ms-1">{t("pages.shared.templates.document.create")}</div>
                    </Button>
                </div>
                <div>
                    <Form className="d-grid gap-2 pt-4">
                        <Row>
                            <Form.Group as={Col} sm={6} lg={3}>
                                <Form.Label>{t("pages.shared.templates.filter.search")}</Form.Label>
                                <Form.Control type="text" value={search} onChange={e => setSearch(e.target.value)} />
                            </Form.Group>
                            <Form.Group as={Col} sm={6} lg={3}>
                                <Form.Label>{t("pages.shared.templates.filter.knowledge")}</Form.Label>
                                <Form.Select
                                    value={filteredKnowledgeId}
                                    onChange={e => setFilteredKnowledgeId(e.target.value)}
                                >
                                    <option value="">{t("pages.shared.templates.filter.all")}</option>
                                    {contextModel.knowledges.map(knowledge => (
                                        <option key={knowledge.id} value={knowledge.id}>
                                            {`${knowledge.code} ${knowledge.value}`}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} sm={6} lg={3}>
                                <Form.Label>{t("pages.shared.templates.filter.documentcontent")}</Form.Label>
                                <Form.Select
                                    value={filteredDocumentKindCategoryId}
                                    onChange={e => setFilteredDocumentKindCategoryId(e.target.value)}
                                >
                                    <option value="">{t("pages.shared.templates.filter.all")}</option>
                                    {contextModel.documentKindCategories.map(dkc => (
                                        <option key={dkc.id} value={dkc.id}>
                                            {`${dkc.code} ${dkc.value}`}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} sm={6} lg={3}>
                                <Form.Label>{t("pages.shared.templates.filter.discipline")}</Form.Label>
                                <Form.Select
                                    value={filteredDisciplineId}
                                    onChange={e => setFilteredDisciplineId(e.target.value)}
                                >
                                    <option value="">{t("pages.shared.templates.filter.all")}</option>
                                    {contextModel.disciplines.map(discipline => (
                                        <option key={discipline.id} value={discipline.id}>
                                            {`${discipline.code} ${discipline.value}`}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
                <div className="flex-grow-1 overflow-auto">
                    <ListGroup variant="flush" className="mt-3">
                        <ListGroup.Item
                            style={{ backgroundColor: "#E4ECFB" }}
                            className="bg-secondary d-flex justify-content-between align-items-center"
                        >
                            <small className="w-25">{t("pages.shared.templates.list.name")}</small>
                            <small className="w-25">{t("pages.shared.templates.list.knowledge")}</small>
                            <small className="w-25">{t("pages.shared.templates.list.documentcontent")}</small>
                            <small className="w-25">{t("pages.shared.templates.list.discipline")}</small>
                        </ListGroup.Item>
                        {sortedDocumentTemplates &&
                            sortedDocumentTemplates.map(sheetTemplate => (
                                <DocumentTemplateListItem
                                    key={sheetTemplate.id}
                                    template={sheetTemplate}
                                    handleClick={() => handleEdit(sheetTemplate)}
                                />
                            ))}
                    </ListGroup>
                </div>
                {showTemplateModal && (
                    <DocumentTemplateModal
                        documentTemplate={selectedTemplate}
                        handleCreate={createDocumentTemplate}
                        handleUpdate={updateDocumentTemplate}
                        handleDelete={deleteDocumentTemplate}
                        handleClose={handleClose}
                    />
                )}
            </div>
        </Container>
    );
};

export default DocumentTemplatesContainer;
