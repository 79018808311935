import {
    BUILDING_REGULATIONS,
    BUILDING_MATERIAL_OPTIONS,
    VENTILATION_LOCATIONS,
    HEATING_SUPPLY_OPTIONS,
    INDOOR_REQUIREMENTS_OPTIONS,
    BUILDING_COMPACTNESS_OPTIONS,
    BUILDING_ROOM_SIZES_OPTIONS,
    BUILDING_TYPES,
} from "_constants";
import { Storey, User, EnergyRapportFields, EnergyRapportTables, Project } from "types";
import _ from "lodash";

export const transformFieldKeysToValues = (
    fields: EnergyRapportFields,
    tables: EnergyRapportTables
): Record<string, string | boolean | unknown> => {
    const BuildingRegulations =
        BUILDING_REGULATIONS.find(
            item => item.key === fields.BuildingRegulations
        )?.value || "";
    const BuildingType = BUILDING_TYPES.find(item => item.key === fields.BuildingType)?.value.toLowerCase() || "Ukendt type";
    const VentilationUnits = tables && tables.VentilationUnits ? tables.VentilationUnits.map(vu => ({
        ...vu,
        elvf: vu.elvf ? "Ja" : "Nej",
    })) : [];
    const VentilationUnitPlacement =
        VENTILATION_LOCATIONS.find(
            vl => vl.key === fields.VentilationUnitPlacement
        )?.description || "ukendt";
    const HeatSupply =
        HEATING_SUPPLY_OPTIONS.find(hs => hs.key === fields.HeatSupply)
            ?.value.toLowerCase() || "Ukendt";
    const isDistrictHeating = fields.HeatSupply === "SUP_DISTRICT";
    const uniqueWindowTypes = _.sortBy(tables.UniqueWindowTypes, ["orient", 'name'])

    const mappedFields = {
        ...fields,
        ...tables,
        BuildingType,
        UniqueWindowTypes: uniqueWindowTypes,
        ShadeText: shadeText(fields),
        hasShadow: Boolean(fields.Shadow),
        hasHeatingPipeOutdoorCompensation: Boolean(
            fields.HeatingPipeOutdoorCompensation
        ),
        BuildingRegulations,
        ...energyTarget(fields),
        ...calculateHeatCapacity(fields),
        HeatSupply,
        isDistrictHeating,
        ...indoorClimate(fields),
        VentilationUnits,
        VentilationUnitPlacement,
        RevisionDate: fields.RevisionDate || "-",
    };

    return mappedFields;
};

const energyTarget = (fields: EnergyRapportFields) => {
    const targets = {
        Lavenergi: fields.EnergyFrameLowEnergy,
        "BR 2018": fields.EnergyFrameBR18,
        "Renoveringsklasse 1": fields.EnergyFrameRenovation,
        "Renoveringsklasse 2": fields.EnergyFrameRenovation2,
    };
    const target =
        targets[fields.EnergyFrameTarget as keyof typeof targets] || 0;
    const TotalEnergyFrame = target + Number(fields.EnergyFrameAdd);
    return {
        EnergyFrame: target,
        TotalEnergyFrame,
    };
};

const shadeText = (fields: EnergyRapportFields): string => {
    const option = fields.Shade;
    switch (option) {
        case "none":
            return "Der er ingen aktiv solafskærmning i projektet";

        case "indvendig-sommer":
            return "Der anvendes indvendig manuelt aktiverede gardiner om sommeren, som har en af-skærmningsfaktor på -0,8 jf. SBi-Anvisning 213. (Kun boliger)";

        case "indvendig":
            return "Der anvendes indvendig manuelt aktiverede gardiner, som har en afskærmningsfaktor på 0,8 jf. SBi-Anvisning 213.";

        case "standard":
            return `Der er regnet med ${
                fields.ShadeName
            } for samtlige vinduer i projektet. (Fc-værdi = ${String(
                fields.ShadeValue
            ).replace(".", ",")})`;

        case "bygherre":
            return "Solafskærmning er taget i regning forudsat opsat af bygherre/slutbruger.";

        default:
            return "Der er ingen aktiv solafskærmning i projektet";
    }
};

const indoorClimate = (fields: EnergyRapportFields) => {
    const method = INDOOR_REQUIREMENTS_OPTIONS.find(
        m => m.key === fields.IndoorClimateMethod
    );
    const key = method?.key || "";
    const hasIndoorAnalysis = !fields.isCommercial && key !== "none";
    const showIndoorAnalysisTable = !fields.isCommercial && key === "sommerkomfort";
    const expandIndoorClimateSection =
        key === "bsim" || key === "sommerkomfort";
    const IndoorClimateHeader =
        key === "bsim" ? "Dynamisk indeklimasimulering" : "Sommerkomfort";
    const IndoorClimateText =
        key === "bsim"
            ? `Der henvises til særskilt notat ${fields.IndoorClimateSimulationName}`
            : "I dette afsnit undersøges det kritiske rum, dvs. den rum/zone som har mest solbelastning.\n\nØvrig tekst tilføjes.";

    return {
        IndoorClimateMethodText: method?.description || "Ukendt",
        expandIndoorClimateSection,
        IndoorClimateHeader,
        IndoorClimateText,
        hasIndoorAnalysis,
        showIndoorAnalysisTable,
    };
};

const calculateHeatCapacity = (fields: EnergyRapportFields) => {
    const roofOptions = BUILDING_MATERIAL_OPTIONS.filter(material => material.type === 'roof');
    const floorOptions = BUILDING_MATERIAL_OPTIONS.filter(material => material.type === 'floor');
    const wallOptions = BUILDING_MATERIAL_OPTIONS.filter(material => material.type === 'wall');
    
    // roof
    const roofKey = fields?.HeatingCapacityRoof || '';
    const roof = roofOptions.find(r => r.key === roofKey);
    const roofValue = roof?.heatCapacity || 0;
    const roofName = roof?.value || 0;
    
    // floor
    const floorKey = fields?.HeatingCapacityFloor || '';
    const floor = floorOptions.find(f => f.key === floorKey);
    const floorValue = floor?.heatCapacity || 0;
    const floorName = floor?.value;

    // walls
    const exterior = wallOptions.find(f => f.key === fields?.HeatingCapacityExterior);
    const compactnessFactor = BUILDING_COMPACTNESS_OPTIONS[fields.BuildingCompactness].factor;
    
    const exteriorValue = (exterior?.heatCapacity || 0) * compactnessFactor ;
    const exteriorName = exterior?.value;

    const roomSizesFactor = BUILDING_ROOM_SIZES_OPTIONS[fields.BuildingRoomSizes].factor;
    const interior = wallOptions.find(f => f.key === fields.HeatingCapacityPartitians);
    const interiorValue = (interior?.heatCapacity || 0) * roomSizesFactor
    const interiorName = interior?.value;
    
    const heatCapacity: number = Math.round(roofValue + floorValue + exteriorValue + interiorValue + 10);
    return {
        HeatingCapacityRoof: roofName,
        HeatingCapacityRoofValue: Math.round(roofValue),
        HeatingCapacityFloor: floorName,
        HeatingCapacityFloorValue: Math.round(floorValue),
        HeatingCapacityPartitians: interiorName,
        HeatingCapacityPartitiansValue: Math.round(interiorValue),
        HeatingCapacityExterior: exteriorName,
        HeatingCapacityExteriorValue: Math.round(exteriorValue),
        HeatCapacity: Math.round(heatCapacity),
    };
};

export const calculateHeatCapacityValue = (fields: EnergyRapportFields) => calculateHeatCapacity(fields).HeatCapacity;

export const loadFieldsFromUser = (
    user: User
): Partial<EnergyRapportFields> => {
    const fieldsWithUserData: Partial<EnergyRapportFields> = {};
    if (user?.initials) {
        fieldsWithUserData.InitialOwner = user.initials;
    }
    return fieldsWithUserData;
};
export const loadFieldsFromProject = (
    project: Project
): Partial<EnergyRapportFields> => {
    const fieldsWithProjectData: Partial<EnergyRapportFields> = {};
    if (project?.name) {
        fieldsWithProjectData.ProjectName = project.name;
    }
    if (project?.number) {
        fieldsWithProjectData.ProjectId = project.number;
    }

    if (project?.client) {
        fieldsWithProjectData.Bygherre = project.client;
    }
    return fieldsWithProjectData;
};
export const loadFieldsFromBuildingsAndStoreys = (projectStoreys: {
    [storeyId: string]: Storey[];
}) => {
    const fieldsWithBuildingsAndStoreysData: Partial<EnergyRapportFields> =
        {};
    const storeys = Object.values(projectStoreys);
    const numberOfBuildings = storeys.length;
    if (numberOfBuildings > 0) {
        const buildingStoreys = storeys.map(b => b.length);
        fieldsWithBuildingsAndStoreysData.NumberOfBuildings = numberOfBuildings;
        fieldsWithBuildingsAndStoreysData.NumberOfFloors = Math.max(
            ...buildingStoreys
        );
    }

    return fieldsWithBuildingsAndStoreysData;
};
