import React, { useContext, useState } from "react";
import { Nav, Navbar, NavDropdown, Button, Dropdown, NavItem, NavLink } from "react-bootstrap";
import { Download, PersonCircle } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { AuthenticationContext } from "context";
import RevitDownloadModal from "../Installation/RevitDownloadModal";
import UserModalForm from "../User/UserModalForm";

const TopNavigationBar: React.FC = () => {
    const { t } = useTranslation();
    const [showUserForm, setShowUserForm] = useState(false);
    const [showInstallModal, setShowInstallModal] = useState(false);
    const { handleSignOut } = useContext(AuthenticationContext);

    return (
        <Navbar className="border-bottom justify-content-end p-3" style={{ height: "3.5rem" }}>
            <Nav className="d-flex">
                <div className="d-flex me-3 justify-content-center align-items-center">
                    <Button
                        className="d-inline-flex align-items-center"
                        size="sm"
                        variant="secondary"
                        onClick={() => setShowInstallModal(true)}
                    >
                        <Download className="me-1" />
                        {t("navigation.top.buttons.download")}
                    </Button>
                </div>
                <div>
                    <Dropdown as={NavItem} drop="start">
                        <Dropdown.Toggle as={NavLink} className="hide-caret">
                            <PersonCircle size="1.5rem" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setShowUserForm(true)}>
                                {t("navigation.top.buttons.profile")}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    handleSignOut();
                                }}
                            >
                                {t("navigation.top.buttons.logout")}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* <NavDropdown
                        drop="start"
                        className="my-dropdown-toggle"
                        title={<PersonCircle size="1.5rem" />}
                        id="topNavigation"
                    >
                        <NavDropdown.Item onClick={() => setShowUserForm(true)}>
                            {t("navigation.top.buttons.profile")}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                            onClick={() => {
                                handleSignOut();
                            }}
                        >
                            {t("navigation.top.buttons.logout")}
                        </NavDropdown.Item>
                    </NavDropdown> */}
                </div>
            </Nav>
            {showUserForm && <UserModalForm handleClose={() => setShowUserForm(false)} />}
            {showInstallModal && <RevitDownloadModal handleClose={() => setShowInstallModal(false)} />}
        </Navbar>
    );
};

export default TopNavigationBar;
