import React, { createContext, ReactNode, useState } from "react";

interface IApplicationUIContext {
    showAppDrawer: boolean;
    handleToogleAppDrawer: () => void;
    handleHideAppDrawer: () => void;
    handleShowAppDrawer: () => void;
}

export const ApplicationUIContext = createContext({} as IApplicationUIContext);

interface Props {
    children?: ReactNode;
}

export const ApplicationUIProvider = ({ children }: Props) => {
    const [showAppDrawer, setShowAppDrawer] = useState<boolean>(true);

    const handleToogleAppDrawer = () => {
        setShowAppDrawer(prev => !prev);
    };

    const handleHideAppDrawer = () => {
        setShowAppDrawer(false);
    };

    const handleShowAppDrawer = () => {
        setShowAppDrawer(true);
    };

    return (<ApplicationUIContext.Provider
        value={{
            handleToogleAppDrawer,
            handleHideAppDrawer,
            handleShowAppDrawer,
            showAppDrawer,
        }}
    >
        {children}
    </ApplicationUIContext.Provider>
    );
}
export default ApplicationUIProvider;