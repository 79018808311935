/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense } from "react";
import { Spinner } from "react-bootstrap";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { CookiesProvider } from "react-cookie";
import { QueryClientProvider } from "@tanstack/react-query";
import { ApplicationUIProvider, AuthProvider, LoggingProvider, UserProvider } from "context";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import "./i18n";
import "./bootstrap.scss";

import { queryClient } from "config";
import { Toaster } from "react-hot-toast";

ReactDOM.render(
    <Suspense
        fallback={
            <div className="d-flex justify-content-center align-items-center h-100">
                <Spinner animation="border" variant="primary" />
            </div>
        }
    >
        <Toaster
            position="bottom-right"
            toastOptions={{
                duration: 5000,
            }}
        />
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <LoggingProvider>
                    <CookiesProvider>
                        <UserProvider>
                            <ApplicationUIProvider>
                                <BrowserRouter>
                                    <App />
                                </BrowserRouter>
                            </ApplicationUIProvider>
                        </UserProvider>
                    </CookiesProvider>
                </LoggingProvider>
            </AuthProvider>
        </QueryClientProvider>
    </Suspense>,

    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
