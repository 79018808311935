import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { PlusCircle } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ShaftsContext } from "context";
import { useContextModel, useEnergyRapport, useProject, useProjectId } from "hooks";
import ToolCard from "../Shared/Tools/ToolCard";
import UserCard from "../Shared/User/UserCard";

const ProjectDashboard: React.FC = () => {
    const projectId = useProjectId();
    const { project, users, sheetCount } = useProject(projectId);
    const { getPhaseById, getStageById } = useContextModel();
    const history = useHistory();
    const { shafts } = useContext(ShaftsContext);
    const { completed } = useEnergyRapport();
    const { t } = useTranslation();

    const sortedUsers = users.sort((a, b) => {
        if (a.role === b.role) {
            return a.name.localeCompare(b.name);
        }
        return a.role.localeCompare(b.role);
    });

    return (
        <div className="p-4 m-0 h-100 w-100" style={{ overflowY: "auto" }}>
            <Row>
                <Col xs={12} xl={6}>
                    <Row className="mb-4">
                        <Col>
                            <h3>{t("pages.shared.dashboard.project.title-details")}</h3>
                        </Col>
                    </Row>
                    <Row className="mb-5 ps-3">
                        <Col sm={6} md={4} style={{ borderLeft: "solid", borderColor: "#CEDBF7" }} className="mb-4">
                            <h6 className="text-muted">{t("pages.shared.dashboard.project.name")}</h6>
                            <b>{project?.name}</b>
                        </Col>
                        <Col sm={6} md={4} style={{ borderLeft: "solid", borderColor: "#CEDBF7" }} className="mb-4">
                            <h6 className="text-muted">{t("pages.shared.dashboard.project.internal-id")}</h6>
                            <b>{project?.internalNumber}</b>
                        </Col>
                        <Col sm={6} md={4} style={{ borderLeft: "solid", borderColor: "#CEDBF7" }} className="mb-4">
                            <h6 className="text-muted">{t("pages.shared.dashboard.project.external-id")}</h6>
                            <b>{project?.number}</b>
                        </Col>
                        <Col sm={6} md={4} style={{ borderLeft: "solid", borderColor: "#CEDBF7" }} className="mb-4">
                            <h6 className="text-muted">{t("pages.shared.dashboard.project.project-leader")}</h6>
                            <b>{project?.projectleader}</b>
                        </Col>
                        <Col sm={6} md={4} style={{ borderLeft: "solid", borderColor: "#CEDBF7" }} className="mb-4">
                            <h6 className="text-muted">{t("pages.shared.dashboard.project.phase")}</h6>
                            <b>{getPhaseById(project?.phaseId || "").value}</b>
                        </Col>
                        <Col sm={6} md={4} style={{ borderLeft: "solid", borderColor: "#CEDBF7" }} className="mb-4">
                            <h6 className="text-muted">{t("pages.shared.dashboard.project.stage")}</h6>
                            <b>{getStageById(project?.stageId || "").value}</b>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} xl={6} className="border-left">
                    <Row className="mb-4">
                        <Col>
                            <div className="d-flex justify-content-between">
                                <h3>{t("pages.shared.dashboard.project.title-team")}</h3>
                                <h6
                                    className="text-muted d-flex align-items-center"
                                    onClick={() => history.push(`/projects/${projectId}/users`)}
                                    style={{ cursor: "pointer" }}
                                >
                                    {<PlusCircle className="me-1" color="#7685DE" />}
                                    {t("pages.shared.dashboard.project.add-member")}
                                </h6>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {sortedUsers.map(user => (
                            <Col className="" key={user.id} xs={12} sm={6}>
                                <UserCard key={user?.id} user={user} />
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
            <Row className="mt-6">
                <Col>
                    <h3>{t("pages.shared.dashboard.project.title-tools")}</h3>
                    <Row className="p-2">
                        {project?.projectFeatures && project?.projectFeatures.FAST && (
                            <ToolCard
                                tool="FAST"
                                title="Total sheets:"
                                titleAmount={sheetCount || 0}
                                onClick={() => history.push(`/projects/${projectId}/fast/sheets`)}
                            />
                        )}
                        {project?.projectFeatures && project?.projectFeatures.FLAT && (
                            <ToolCard
                                tool="FLAT"
                                title="Total shafts:"
                                titleAmount={shafts?.length || 0}
                                onClick={() => history.push(`/projects/${projectId}/flat/calculations`)}
                            />
                        )}
                        {project?.projectFeatures && project?.projectFeatures.BE18 && (
                            <ToolCard
                                tool="BE18"
                                title="Total rapports:"
                                titleAmount={completed ? 1 : 0}
                                onClick={() => history.push(`/projects/${projectId}/be18/documents`)}
                            />
                        )}
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default ProjectDashboard;
