import React, { useEffect, createContext, useState, ReactNode, useContext } from "react";
import { LoggingContext } from "context";
import { Building } from "types";
import axios from "axios";
import { API_URL, firestore } from "config";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { pickBy, sortBy } from "lodash";
import { useAuth } from "hooks";

interface IBuildingContext {
    error: Error | null | undefined;
    handleCreateBuilding: (projectId: string, building: Building) => void;
    handleSaveBuilding: (projectId: string, building: Building) => void;
    handleDeleteBuilding: (projectId: string, buildingId: string) => void;
    loadingBuildings: boolean;
    buildings: Building[];
}

export const BuildingsContext = createContext({} as IBuildingContext);

interface Props {
    projectId?: string;
    children?: ReactNode;
}

const BuildingsProvider = ({ projectId, children }: Props): JSX.Element => {
    const [loadingBuildings, setLoadingBuildings] = useState<boolean>(true);
    const { getAccessToken } = useAuth();
    const [buildings, setBuildings] = useState<Building[]>([]);
    const [error, setError] = useState<Error | null>();

    const { logger } = useContext(LoggingContext);

    useEffect(() => {
        if (!projectId) {
            return;
        }

        const buildingsRef = collection(firestore, "projects", projectId, "buildings");

        setLoadingBuildings(true);
        onSnapshot(
            buildingsRef,
            documentSnapshots => {
                const data: Building[] = [];
                documentSnapshots.forEach(documentSnapshot => {
                    const building = documentSnapshot.data() as Building;
                    building.id = documentSnapshot.id;
                    data.push(building);
                });
                setBuildings(sortBy(data, ["code", "name"]));
                setLoadingBuildings(false);
            },
            error => {
                setLoadingBuildings(false);
                setError(new Error("Lokationer kunne ikke hentes. Prøv venligst igen."));
                logger.error("Couldn't fetch buildings", error);
            }
        );
    }, [projectId, logger]);

    const handleCreateBuilding = async (projectId: string, building: Building) => {
        // TODO: Remove projectId param
        setLoadingBuildings(true);
        const accessToken = await getAccessToken();
        const requestUrl = `${API_URL}/v1/projects/${projectId}/buildings`;
        const cleanBuilding = pickBy(building, value => value !== undefined);
        const {
            name,
            numberOfStoreys,
            numberOfBasementStoreys,
            housingTypeSize,
            code,
            scopeBox,
            revitModel,
            description,
            housingType,
            specialStoreys,
        } = cleanBuilding;

        const requestJson = JSON.stringify({
            buildings: [
                {
                    name,
                    numberOfStoreys: Number(numberOfStoreys),
                    numberOfBasementStoreys: Number(numberOfBasementStoreys),
                    housingTypeSize,
                    code,
                    scopeBox,
                    revitModel,
                    description,
                    housingType,
                    specialStoreys,
                },
            ],
        });

        try {
            const response = await axios.post(requestUrl, requestJson, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            setLoadingBuildings(false);
            return response.data;
        } catch (error) {
            setError(error as Error);
            setLoadingBuildings(false);
        }
    };

    const handleSaveBuilding = async (projectId: string, building: Building) => {
        // TODO: Remove projectId param
        const accessToken = await getAccessToken();
        const buildingId = building.id;
        const currentBuildingState = buildings.find(building => building.id === buildingId);
        if (currentBuildingState === undefined) return;
        const isSame = ![
            "code",
            "description",
            "name",
            "revitModel",
            "sharedLocation",
            "housingType",
            "housingTypeSize",
            "scopeBox",
        ].some(property => building[property as keyof Building] !== currentBuildingState[property as keyof Building]);
        if (isSame) return;
        setLoadingBuildings(true);
        const requestUrl = `${API_URL}/v1/projects/${projectId}/buildings/${building.id}`;

        const { name, code, scopeBox, revitModel, description, sharedLocation, housingType, housingTypeSize } =
            building;
        const requestJson = JSON.stringify({
            name,
            code,
            scopeBox,
            revitModel,
            description,
            sharedLocation,
            housingType,
            housingTypeSize,
        });
        try {
            const response = await axios.put(requestUrl, requestJson, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            setLoadingBuildings(false);
            return response.data;
        } catch (error) {
            setError(error as Error);
            setLoadingBuildings(false);
        }
    };

    const handleDeleteBuilding = async (projectId: string, buildingId: string) => {
        setLoadingBuildings(true);
        const accessToken = await getAccessToken();
        const requestUrl = `${API_URL}/v1/projects/${projectId}/buildings/${buildingId}`;

        try {
            const response = await axios.delete(requestUrl, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            setLoadingBuildings(true);
            return response.data;
        } catch (error) {
            setError(error as Error);
            setLoadingBuildings(true);
        }
    };

    return (
        <BuildingsContext.Provider
            value={{
                error,
                handleCreateBuilding,
                handleSaveBuilding,
                handleDeleteBuilding,
                loadingBuildings,
                buildings,
            }}
        >
            {children}
        </BuildingsContext.Provider>
    );
};

export default BuildingsProvider;
