import { useEffect, useState } from "react";
import { collection, onSnapshot } from "@firebase/firestore";
import { firestore } from "config";
import { IShaftType, IShaftCalculation } from "types";
import { useProjectId } from "hooks";

type ShaftCalculationHook = {
    shaftCalculations: IShaftCalculation[];
}

export const useShaftCalculations = (shaft: IShaftType): ShaftCalculationHook => {
    const [shaftCalculations, setShaftCalculations] = useState<IShaftCalculation[]>([]);
    const projectId = useProjectId();

    useEffect(() => {
        if (shaft.id === undefined || shaft.id === "") {
            return;
        }

        const shaftsCollectionRef = collection(firestore, "projects", projectId, "shafts", shaft.id, "calculations");
        onSnapshot(shaftsCollectionRef, shaftsSnapshot => {
            const shaftCalculations: IShaftCalculation[] = [];
            shaftsSnapshot.forEach(calculationDoc => {
                const shaftCalculation =
                    calculationDoc.data() as IShaftCalculation;
                shaftCalculation.id = calculationDoc.id;
                shaftCalculations.push(shaftCalculation as IShaftCalculation);
            });
            setShaftCalculations(shaftCalculations);
        });
    }, [shaft, projectId]);

    return {
        shaftCalculations
    };
};
