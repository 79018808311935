import { PlatformFeatures } from "types";
import { Stickies, PeopleFill, PencilSquare, FilePost, DistributeHorizontal, Icon } from "react-bootstrap-icons";

export interface INavigationMenuItem {
    translationId: string;
    url: string;
    icon?: Icon;
    indicator?: boolean;
}

export interface INavigationMenuItemGroup extends INavigationMenuItem {
    path: string;
    showOnEnabledFeature: PlatformFeatures;
    subPages: INavigationMenuItem[];
}

export const COMPANY_NAVIGATION_MENU: INavigationMenuItem[] = [
    {
        translationId: "navigation.company.general",
        url: `company`,
    },
    {
        translationId: "navigation.company.users",
        url: `company/users`,
    },
    {
        translationId: "navigation.company.templates",
        url: `company/templates`,
    },
    {
        translationId: "navigation.company.valuelists",
        url: `company/value-pair-lists`,
    },
    {
        translationId: "navigation.company.parameters",
        url: `company/naming-convention`,
    },
];

export const PROJECT_SETTINGS_NAVIGATION_MENU: INavigationMenuItem[] = [
    {
        url: "settings",
        translationId: "navigation.project.settings",
        icon: PencilSquare,
    },
    {
        url: "users",
        translationId: "navigation.project.members",
        icon: PeopleFill,
    },
];

export const PROJECT_DRAWER_NAVIGATION_MENU: INavigationMenuItemGroup[] = [
    {
        path: "fast",
        translationId: "tools.FAST.title",
        url: `fast`,
        showOnEnabledFeature: {
            FAST: true,
            FLAT: false,
            BE18: false,
        },
        icon: Stickies,
        subPages: [
            {
                translationId: "navigation.project.FAST.locations",
                url: `fast/locations`,
            },
            {
                translationId: "navigation.project.FAST.sheetlist",
                url: `fast/sheets`,
            },
            {
                translationId: "navigation.project.FAST.settings",
                url: `fast/settings`,
            },
            {
                translationId: "navigation.project.FAST.templates",
                url: `fast/templates`,
            },
            {
                translationId: "navigation.project.FAST.model",
                url: `fast/valuelists`,
            },
            {
                translationId: "navigation.project.FAST.parameters",
                url: `fast/parameters`,
            },
        ],
    },
    {
        path: "flat",
        translationId: "tools.FLAT.title",
        url: `flat`,
        showOnEnabledFeature: {
            FAST: false,
            FLAT: true,
            BE18: false,
        },
        icon: DistributeHorizontal,
        subPages: [
            {
                translationId: "navigation.project.FLAT.locations",
                url: `flat/locations`,
                // indicator: true,
            },

            {
                translationId: "navigation.project.FLAT.apartments",
                url: `flat/apartments`,
                // indicator: true,
            },
            {
                translationId: "navigation.project.FLAT.systems",
                url: `flat/systems`,
                // indicator: true,
            },
            {
                translationId: "navigation.project.FLAT.calculations",
                url: `flat/calculations`,
                // indicator: true,
            },
        ],
    },
    {
        path: "be18",
        translationId: "tools.BE18.title",
        url: `be18`,
        showOnEnabledFeature: {
            FAST: false,
            FLAT: false,
            BE18: true,
        },
        icon: FilePost,
        subPages: [
            {
                translationId: "navigation.project.BE18.locations",
                url: `be18/locations`,
            },
            {
                translationId: "navigation.project.BE18.documentlist",
                url: `be18/documents`,
            },
            {
                translationId: "navigation.project.BE18.templates",
                url: `be18/templates`,
            },
            {
                translationId: "navigation.project.BE18.settings",
                url: `be18/settings`,
            },
            {
                translationId: "navigation.project.BE18.energyframe",
                url: `be18/generate`,
            },
        ],
    },
];
