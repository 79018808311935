import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Building } from 'types';
import { useFormFields } from 'hooks';
import { EMPTY_BUILDING, EMPTY_FAST_BUILDING } from '_constants';
import { logEvent } from 'config';
import BuildingForm from './BuildingForm';
import FastBuildingForm from './FastBuildingForm';
import { useTranslation } from 'react-i18next'

interface Props {
    handleClose: () => void;
    handleSave: (fields: Building) => void;
    building: Building | null;
    title: string;
    tool: "FAST" | "other";
}

const BuildingCreateModalForm: React.FC<Props> = ({ handleClose, handleSave, building, title, tool }) => {
    const { t } = useTranslation();
    const fallbackBuilding = tool === "FAST" ? EMPTY_FAST_BUILDING : EMPTY_BUILDING;
    const { fields, handleFieldChange } = useFormFields<Building>(building || fallbackBuilding);
    const [isInvalid, setIsInvalid] = useState<string[]>([]);

    const handleSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        const valid = validateFields();
        if (valid) {
            handleSave(fields);
            logEvent("building_save");
        }
    };

    const validateFields = (): boolean => {
        const invalid = []
        const nameIsValid = () => fields.name !== "";
        const codeIsValid = () => fields.code !== "";
        const numFloorIsValid = () => (fields.numberOfBasementStoreys + fields.numberOfStoreys) > 0;

        if (!nameIsValid()) {
            invalid.push('NAME');
        }
        if (!codeIsValid()) {
            invalid.push('CODE');
        }
        if (!numFloorIsValid()) {
            invalid.push('NUMFLOOR');
        }
        if (invalid.length > 0) {
            setIsInvalid(invalid);
            return false;
        }
        return true;
    }

    return (
        <>
            <Modal show={true} onHide={handleClose} backdrop="static" scrollable size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {tool === "FAST" ?
                        <FastBuildingForm fields={fields} handleFieldChange={handleFieldChange} isInvalid={isInvalid} setIsInvalid={setIsInvalid} /> :
                        <BuildingForm fields={fields} handleFieldChange={handleFieldChange} isInvalid={isInvalid} setIsInvalid={setIsInvalid} />}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleClose}>
                        {t('buttons.cancel')}
                    </Button>
                    <Button
                        disabled={isInvalid.length > 0}
                        variant="primary"
                        onClick={handleSubmit}
                    >
                        {t('buttons.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BuildingCreateModalForm;
