import { IModelDocumentKind, IModelItem, IModelOutputFormat, IModelValuePair, IModelRevitParameter } from "types";

export const EMPTY_ITEM: IModelItem = {
    id: "",
};

export const EMPTY_VALUEPAIR: IModelValuePair = {
    id: "",
    value: "",
    code: "",
    preferred: false,
};

export const EMPTY_DOCUMENTKIND: IModelDocumentKind = {
    id: "",
    value: "",
    documentKindCategoryId: "",
    subCode: "",
    preferred: false,
};

export const UNKNOWN_DOCUMENTKIND: IModelDocumentKind = {
    id: "",
    value: "",
    documentKindCategoryId: "",
    subCode: "",
    preferred: false,
};

export const EMPTY_OUTPUTFORMAT: IModelOutputFormat = {
    id: "",
    size: [],
    code: "",
    preferred: false,
};

export const EMPTY_REVITPARAMETER: IModelRevitParameter = {
    id: "",
    parameterValue: "",
    revitParameterName: "",
};

export const UNKNOWN_VALUEPAIR: IModelValuePair = {
    id: "",
    value: "-",
    code: "-",
    preferred: false,
};

export enum ModelDataType {
    VALUEPAIR = "VALUEPAIR",
    DOCUMENTKIND = "DOCUMENTKIND",
    OUTPUTFORMAT = "OUTPUTFORMAT",
}
