import React, { FC, HTMLAttributes } from "react";
import { Container } from "react-bootstrap";
import { useEnergyRapport } from "hooks";
import ReportConfigurator from "./ReportConfigurator";
import CompletedReportForm from "./CompletedForm";

interface Props extends HTMLAttributes<HTMLBaseElement> {
    projectId: string;
}

const EnergyEfficiencyContainer: FC<Props> = ({ projectId }) => {
    const { completed } = useEnergyRapport();

    return (
        <Container fluid className="p-4 m-0 h-100" style={{ overflowY: 'auto' }}>
            {completed ? <CompletedReportForm /> : <ReportConfigurator />}
        </Container>
    );
};

export default EnergyEfficiencyContainer;
