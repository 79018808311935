import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { ProjectContext } from "context";
import { Project } from "types";
import ConfirmModal from "../Shared/ConfirmModal";

interface Props {
    project: Project;
}

const ProjectSettingsArchive: React.FC<Props> = ({ project }) => {
    const [showConfirmArchiveModal, setShowConfirmArchiveModal] = useState<boolean>(false);
    const { handleArchiveProject } = useContext(ProjectContext);

    return (
        <>
            {!project.archived && (
                <Row>
                    <Col>
                        {project?.id && (
                            <Button
                                variant="danger"
                                onClick={() => {
                                    setShowConfirmArchiveModal(true);
                                }}
                            >
                                Archive project
                            </Button>
                        )}
                    </Col>
                </Row>
            )}
            <ConfirmModal
                show={showConfirmArchiveModal}
                handleAction={() => {
                    handleArchiveProject(project?.id);
                    setShowConfirmArchiveModal(false);
                }}
                handleClose={() => setShowConfirmArchiveModal(false)}
                description="Are you sure you want to archive this project?"
                confirmText="Archive project"
            />
        </>
    );
};

export default ProjectSettingsArchive;
