import { pick } from "lodash";
import {
    XMLFile,
    XMLEntity,
    BE18Lighting,
    BE18TransparentConstruction,
    BE18Ventilation,
    Report,
    BE18Shading,
} from "types";
import { xml2json } from "xml-js";

const titleCase = (txt: string) => txt[0].toUpperCase() + txt.substring(1).toLowerCase();

export abstract class AbstractXMLService<T = undefined> {
    protected xml = "";
    protected content: T | undefined;
    protected data: XMLFile;
    constructor(xml: string) {
        this.xml = xml;
        const json = xml2json(xml);
        this.data = JSON.parse(json) as XMLFile;
    }

    abstract parseFile(xml: XMLEntity | XMLFile | undefined): T | undefined;

    abstract getContent(): T;

    protected getSubElement = (xml: XMLEntity | XMLFile | undefined, elementName: string) => {
        if (xml === undefined) return undefined;
        const collection = xml.elements;
        if (collection === undefined) return undefined;
        const el = collection.find(item => item.name === elementName);
        return el;
    };

    protected getElementValue = (xml: XMLEntity | undefined): string | undefined => {
        if (xml === undefined) return undefined;
        const collection = xml.elements;
        if (collection === undefined || collection.length === 0) return undefined;
        const first = collection[0];
        const value = first?.text;
        if (value === undefined) return undefined;
        if (value.slice(0, 1) === "." && value.slice(-1) === ".") {
            return value.slice(1, -1);
        }
        return value;
    };

    protected getSubElementsByPath = (xml: XMLEntity | XMLFile, elementPath: string): XMLEntity[] => {
        if (xml === undefined) return [];
        const pathElements = elementPath.split("/");
        const [currentPathName, ...rest] = pathElements;
        const collection = xml.elements;
        if (collection === undefined || collection.length === 0) return [];
        const els = collection.filter(item => item.name === currentPathName);
        if (pathElements.length === 1) {
            return els;
        }
        if (els[0] === undefined) return els;
        return this.getSubElementsByPath(els[0], rest.join("/"));
    };

    protected getSubElementByPath = (xml: XMLEntity | XMLFile, elementPath: string): XMLEntity => {
        if (xml === undefined) throw new Error(`xml is undefined, check the element for path ${elementPath}`);

        const pathElements = elementPath.split("/");

        const [currentPathName, ...rest] = pathElements;

        const collection = xml.elements;
        if (collection === undefined || collection.length === 0)
            throw new Error(`collection is undefined, check the elementPath ${elementPath}}`);

        const els = collection.filter(item => item.name === currentPathName);

        if (els[0] === undefined) {
            throw new Error(`there are no elements with this path, check the path ${elementPath}`);
        }

        if (pathElements.length === 1) {
            return els[0];
        }

        return this.getSubElementByPath(els[0], rest.join("/"));
    };

    protected getElementValueByPath = (xml: XMLEntity | XMLFile, elementPath: string, fallback = ""): string => {
        try {
            const element = this.getSubElementByPath(xml, elementPath);
            const value = this.getElementValue(element);
            if (value === undefined) {
                return fallback;
            }
            return value;
        } catch (error) {
            return fallback;
        }
    };

    protected getSubElements = (xml: XMLEntity | XMLFile | undefined, elementName: string): XMLEntity[] => {
        if (xml === undefined) return [];
        const collection = xml.elements;
        if (collection === undefined) return [];
        const els = collection.filter(item => item.name === elementName);
        return els;
    };

    protected getSubElementsByAttribute = (
        xml: XMLEntity | XMLFile | undefined,
        elementName: string,
        attributes: string
    ): XMLEntity[] => {
        if (xml === undefined) return [];
        const collection = xml.elements;
        if (collection === undefined) return [];
        const els = collection.filter(item => item.name === elementName);
        if (attributes !== "") {
            return els.filter(el => Object.keys(el.attributes).includes(attributes));
        }
        return els;
    };

    protected getSubElementsByAttributes = (
        xml: XMLEntity | XMLFile | undefined,
        elementName: string,
        attributeContains: [string, string]
    ): XMLEntity[] => {
        if (xml === undefined) return [];
        const collection = xml.elements;
        if (collection === undefined) return [];
        const els = collection.filter(item => item.name === elementName);
        const [key, value] = attributeContains;
        return els.filter(el => el.attributes?.[key]?.includes(value));
    };
}

export abstract class AbstractBE18XMLService extends AbstractXMLService<Pick<Report, "tables" | "fields"> | undefined> {
    protected sortByExtractedNumberFromProperty =
        <TObject extends Record<string, unknown>, TKey extends keyof TObject>(key: TKey) =>
        (a: TObject, b: TObject): number => {
            let na = 0;
            let nb = 0;
            if (a.key === undefined || b.key === undefined) return 0;
            if (typeof a.system !== "string" || typeof b.system !== "string") return 0;
            const naMatch = a.system.match(/\d+/);
            if (naMatch && naMatch[0]) {
                na = Number(naMatch[0]);
            }
            const nbMatch = b.system.match(/\d+/);
            if (nbMatch && nbMatch[0]) {
                nb = Number(nbMatch[0]);
            }
            return na - nb;
        };

    protected getUniquesByProperty = <TObject extends Record<string, unknown>, TKey extends keyof TObject>(
        object: TObject[],
        keys: TKey[]
    ): TObject[] =>
        object.reduce<TObject[]>(
            (acc, cur) =>
                acc.find(v => JSON.stringify(pick(v, keys)) === JSON.stringify(pick(cur, keys))) ? acc : [...acc, cur],
            []
        );

    public extractLigthingFromXML = (el: XMLEntity): BE18Lighting => {
        const name = this.getElementValue(this.getSubElement(el, "id")) || "-";
        const lux = Number(this.getElementValue(this.getSubElement(el, "light")) || "0");
        const regulator = this.getElementValue(this.getSubElement(el, "has_reg")) || "-";

        return {
            name,
            lux,
            regulator,
        };
    };

    public extractWindowsFromXML = (el: XMLEntity): BE18TransparentConstruction => {
        const name = this.getElementValue(this.getSubElement(el, "id")) || "-";
        const fc = this.getElementValue(this.getSubElement(el, "fc")) || "-";
        const ff = this.getElementValue(this.getSubElement(el, "ff")) || "-";
        const orient = this.parseOrientation(this.getElementValue(this.getSubElement(el, "orient"))) || "-";
        const u = this.getElementValue(this.getSubElement(el, "u")) || "-";
        const g = this.getElementValue(this.getSubElement(el, "g")) || "-";
        const no = this.getElementValue(this.getSubElement(el, "no")) || "-";
        const area = this.getElementValue(this.getSubElement(el, "area")) || "-";
        return {
            name,
            fc,
            ff,
            orient,
            u,
            g,
            no,
            area,
        };
    };

    public extractVentilationFromXML = (el: XMLEntity): BE18Ventilation => {
        const name = this.getElementValue(this.getSubElement(el, "id")) || "-";
        const nvgv = Number(this.getElementValue(this.getSubElement(el, "nvgv")) || "0");
        const elvf = this.getElementValue(this.getSubElement(el, "el_vf")) === "T";
        const sel = Number(this.getElementValue(this.getSubElement(el, "sel")) || "0");
        const infil = Number(this.getElementValue(this.getSubElement(el, "qid")) || "0");
        const q50 = (infil - 0.04) / 0.06;
        const airVelocity = Number(this.getElementValue(this.getSubElement(el, "qvm")) || "0");
        const airVelocitySummer = Number(this.getElementValue(this.getSubElement(el, "qvm_day")) || "0");
        const naturalAirVelocitySummer = Number(this.getElementValue(this.getSubElement(el, "qid_day")) || "0");
        // er det centralt, decentralt eller ikke ventileret område
        let system = airVelocity > 0 && airVelocitySummer > 0 ? "DE" : "INF";
        const systemMatch = name.toLowerCase().match(/ve\d+/);
        if (systemMatch && systemMatch[0]) {
            system = systemMatch[0].toUpperCase();
        }

        return {
            infil,
            name,
            nvgv,
            elvf,
            sel,
            system,
            q50,
            naturalAirVelocitySummer,
        };
    };

    public extractShadingFromXML = (el: XMLEntity): BE18Shading => {
        const name = this.getElementValue(this.getSubElement(el, "id")) || "Ikke navngivet skygge";
        const horizon = this.parseNumberValue(this.getElementValue(this.getSubElement(el, "horizon")));
        const overhang = this.parseNumberValue(this.getElementValue(this.getSubElement(el, "overhang")));
        const left = this.parseNumberValue(this.getElementValue(this.getSubElement(el, "sidefin_left")));
        const right = this.parseNumberValue(this.getElementValue(this.getSubElement(el, "sidefin_right")));
        const opening = this.parseNumberValue(this.getElementValue(this.getSubElement(el, "opening")));

        return {
            name,
            horizon,
            overhang,
            left,
            right,
            opening,
        };
    };

    public parseConstructionId = (id: string | undefined): { name: string; iso: string; lambda: string } => {
        const base = { name: "", iso: "??", lambda: "??" };
        if (id === "" || id === undefined) return base;
        const sections = id.split(",");
        if (sections.length === 1) {
            base.name = id;
            return base;
        }
        const isoIndex = sections.findIndex(section => section.includes("mm"));
        if (isoIndex >= 0) {
            const isoRaw = sections.splice(isoIndex, 1)[0];
            const isoIntMatch = isoRaw?.match(/\d+/g);
            if (isoIntMatch && isoIntMatch.length > 0 && isoIntMatch[0]) {
                const iso = isoIntMatch[0];
                base.iso = iso;
            }
        }
        const lambdaIndex = sections.findIndex(section => section.includes("lambda"));
        if (lambdaIndex >= 0) {
            const lambdaRaw = sections.splice(lambdaIndex, 1)[0];
            const lambdaIntMatch = lambdaRaw?.match(/\d+/g);
            if (lambdaIntMatch && lambdaIntMatch.length > 0 && lambdaIntMatch[0]) {
                const lambda = lambdaIntMatch[0];
                base.lambda = lambda;
            }
        }

        base.name = sections.join(", ");
        return base;
    };

    public parseOrientation = (orient: string | undefined): string => {
        if (orient === undefined) return "-";
        const orientNumbersMatch = orient.match(/\d+/);
        if (orientNumbersMatch && orientNumbersMatch[0]) {
            const orientNumber = parseInt(orientNumbersMatch[0]);
            if (orientNumber > 337.5 || orientNumber <= 22.5) return "Nord";
            if (orientNumber <= 67.5) return "NordØst";
            if (orientNumber <= 112.5) return "Øst";
            if (orientNumber <= 157.5) return "SydØst";
            if (orientNumber <= 202.5) return "Syd";
            if (orientNumber <= 247.5) return "SydVest";
            if (orientNumber <= 292.5) return "Vest";
            if (orientNumber <= 337.5) return "NordVest";
        }
        const result = orient.replace("N", "Nord").replace("S", "Syd").replace("Ø", "Øst").replace("V", "Vest");

        return titleCase(result);
    };

    public parseNumberValue = (xmlValue: string | undefined): number => {
        if (xmlValue === undefined || xmlValue === "") {
            return 0;
        }
        if (xmlValue.includes(",")) {
            xmlValue = xmlValue.replace(/,/g, ".");
        }
        const value = Number(xmlValue);
        return value;
    };
}
