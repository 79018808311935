import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHover } from "hooks";
import { Apartment } from "types";

interface Props {
    onClick: (apartment: Apartment) => void;
    apartment: Apartment;
}

const ApartmentCard: React.FC<Props> = ({ apartment, onClick }: Props) => {
    const hoverRef = React.useRef(null);
    const isHover = useHover(hoverRef);
    const { t } = useTranslation();

    return (
        <Card
            style={{ width: "18rem" }}
            ref={hoverRef}
            className="transition-fast pointer m-2"
            onClick={() => onClick(apartment)}
            bg={isHover ? "light" : undefined}
        >
            <Card.Body>
                <Card.Title>{apartment.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                    {apartment.areaSize}m<sup>2</sup>
                </Card.Subtitle>
                <Card.Text>
                    {apartment.rooms.length > 0 ? (
                        apartment.rooms.map((room, index) => <h6 key={index}>{room.name}</h6>)
                    ) : (
                        <h6>
                            <i>{t("pages.FLAT.apartments.card-no-rooms")}</i>
                        </h6>
                    )}
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default ApartmentCard;
