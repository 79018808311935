import React, { useContext, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { PencilSquare, Stickies, Trash } from "react-bootstrap-icons";
import { IShaftType } from "types";
import { useHover } from "hooks";
import { ShaftsContext } from "../../../../context/ShaftsProvider";
import OverlayIconButton from "../../../Shared/Buttons/OverlayIconButton";
import ConfirmModal from "../../../Shared/ConfirmModal";
import { useTranslation } from "react-i18next";

interface Props {
    isSelected: boolean;
    shaft: IShaftType;
    handleClick: () => void;
    handleDuplicateShaft: (shaft: IShaftType) => void;
    handleEditShaft: (shaft: IShaftType) => void;
}

const ShaftCard: React.FC<Props> = ({ isSelected, shaft, handleClick, handleDuplicateShaft, handleEditShaft }) => {
    const { handleDeleteShaft } = useContext(ShaftsContext);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const { t } = useTranslation();

    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);

    return (
        <>
            <Card
                ref={hoverRef}
                className="my-1 transition pointer"
                onClick={handleClick}
                border={isHover ? "dark" : undefined}
                bg={isSelected ? "light" : undefined}
            >
                <Card.Body className="p-2">
                    <div className="mx-2 d-flex justify-content-between align-items-center">
                        <div>{shaft.name}</div>
                        <div>
                            <OverlayIconButton
                                overlayText={t("pages.FLAT.shafts.tooltip-edit")}
                                onClick={e => {
                                    e.stopPropagation();
                                    handleEditShaft(shaft);
                                }}
                            >
                                <PencilSquare />
                            </OverlayIconButton>
                            <OverlayIconButton
                                overlayText={t("pages.FLAT.shafts.tooltip-duplicate")}
                                onClick={e => {
                                    e.stopPropagation();
                                    handleDuplicateShaft(shaft);
                                }}
                            >
                                <Stickies />
                            </OverlayIconButton>
                            <OverlayIconButton
                                overlayText={t("pages.FLAT.shafts.tooltip-delete")}
                                onClick={e => {
                                    e.stopPropagation();
                                    setShowDeleteConfirmation(true);
                                }}
                            >
                                <Trash />
                            </OverlayIconButton>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <ConfirmModal
                show={showDeleteConfirmation}
                description={t("pages.FLAT.shafts.confirm-delete-title")}
                confirmText={t("buttons.confirm")}
                handleAction={() => {
                    handleDeleteShaft(shaft.id);
                    setShowDeleteConfirmation(false);
                }}
                handleClose={() => setShowDeleteConfirmation(false)}
            />
        </>
    );
};

export default ShaftCard;
