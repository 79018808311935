import axios from "axios";
import { API_URL } from "config";
import { FirestoreTokenUser } from "types";

type AbstractUserCreationService = {
    getInvitedUserData: (token: string) => Promise<FirestoreTokenUser>;
    createInvitedUser: (email: string, name: string, password: string) => Promise<string>;
};

export const UserCreationService: AbstractUserCreationService = {
    getInvitedUserData: async (token: string) => {
        const requestQueryParameter = `?token=${token}`;
        const requestUrl = `${API_URL}/v1/user-tokens${requestQueryParameter}`;

        const response = await axios.get(requestUrl, {
            headers: { "Content-Type": "application/json" },
        });

        return response.data;
    },
    createInvitedUser: async (token: string, name: string, password: string) => {
        const requestUrl = `${API_URL}/v1/users/create-email-user`;
        const requestJson = JSON.stringify({ token, name, password });

        const response = await axios.post(requestUrl, requestJson, {
            headers: { "Content-Type": "application/json" },
        });

        return response.data;
    },
};
