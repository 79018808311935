import React from "react";
import { useTranslation } from "react-i18next";
import { FormEvent, SyntheticEvent } from "hooks";
import { IRevision } from "../../context/SheetsProvider";
import { Modal, Container, Col, Form, Button, Row } from "react-bootstrap";

interface Props {
    show: boolean;
    selectedRevision: IRevision | undefined;
    revisions: IRevision[];
    handleClose: () => void;
    handleFieldChange: (event: FormEvent) => void;
}

const EditRevisionModal: React.FC<Props> = ({
    show,
    selectedRevision,
    revisions,
    handleFieldChange,
    handleClose,
    ...props
}) => {
    const { t } = useTranslation();
    const customHandleFieldChange = (targetId: string, targetValue: IRevision[]) =>
        handleFieldChange({ target: { id: targetId, value: targetValue } } as SyntheticEvent);

    const setRevisionName = (event: FormEvent) => {
        const { value } = event.target;
        if (selectedRevision !== undefined) {
            const revision = selectedRevision;
            const editedRevions = revisions;
            const index = revisions.findIndex(rev => rev.index === revision.index);
            if (revisions.find(rev => rev.index === revision.index) !== undefined) {
                revision.name = value as string;
                editedRevions[index] = revision;
                customHandleFieldChange("revisions", editedRevions);
            }
        }
    };
    const setRevisionDate = (event: FormEvent) => {
        const { value } = event.target;
        if (selectedRevision !== undefined) {
            const revision = selectedRevision;
            const editedRevions = revisions;
            const index = revisions.findIndex(rev => rev.index === revision.index);
            if (revisions.find(rev => rev.index === revision.index) !== undefined) {
                revision.date = value as string;
                editedRevions[index] = revision;
                customHandleFieldChange("revisions", editedRevions);
            }
        }
    };

    const nameIsValid = () => selectedRevision?.name !== "";

    return (
        <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered show={show}>
            <Modal.Body>
                <Container>
                    <Col>
                        <Form className="d-grid gap-2">
                            <Row>
                                <Form.Group controlId="name" className="w-100">
                                    <Form.Label>
                                        {t("pages.FAST.sheet-list.export-sheet-list.fields.revision-name")}
                                    </Form.Label>
                                    <Form.Control
                                        isInvalid={!nameIsValid()}
                                        type="text"
                                        value={selectedRevision?.name}
                                        onChange={e => setRevisionName(e)}
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group controlId="date" className="w-100">
                                    <Form.Label>
                                        {t("pages.FAST.sheet-list.export-sheet-list.fields.revision-date")}
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={selectedRevision?.date}
                                        onChange={e => setRevisionDate(e as FormEvent)}
                                    />
                                </Form.Group>
                            </Row>
                        </Form>
                    </Col>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={!nameIsValid()} onClick={handleClose}>
                    {t("buttons.close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditRevisionModal;
