import { createContext, ReactNode } from "react";
import { useAppVersion } from 'hooks';
import { ChangeLogEntry } from "types";

export enum LogLevel {
    log = 1,
    info = 2,
    warn = 3,
    error = 4,
}

interface ILoggingContext {
    logger: {
        log: (message: string, data?: unknown) => void;
        info: (message: string, data?: unknown) => void;
        warn: (message: string, data?: unknown) => void;
        error: (message: string, data?: unknown) => void;
    };
    plugin: ChangeLogEntry[];
    webapp: ChangeLogEntry[];
    currentVersion: string;
}

export const LoggingContext = createContext({} as ILoggingContext);

interface Props {
    children: ReactNode;

}

const isDevelopment = process.env.NODE_ENV !== "production";

// Only log message and data to development environment
// for debugging purposes
const logToConsole = (message: string, data?: unknown) => {
    if (isDevelopment) {
        // eslint-disable-next-line no-console
        console.log(message, data);
    }
};

export const LoggingProvider = ({ children }: Props): JSX.Element => {
    const { plugin, webapp, currentVersion } = useAppVersion()

    const logger = {
        log: async (message: string, data?: unknown) => {

            logToConsole(message, data);
        },
        info: async (message: string, data?: unknown) => {

            logToConsole(message, data);
        },
        warn: async (message: string, data?: unknown) => {

            logToConsole(message, data);
        },
        error: async (message: string, data?: unknown) => {

            logToConsole(message, data);
        },
    };

    return (
        <LoggingContext.Provider
            value={{
                logger,
                plugin,
                webapp,
                currentVersion
            }}
        >
            {children}
        </LoggingContext.Provider>
    );
};

export default LoggingProvider;
