import React, { useContext } from 'react';
import { ProjectContext } from 'context';
import HomePage from './HomePage';
import HomePageNoProjects from './HomePageNoProjects';

const HomePageContainer: React.FC = () => {
    const { projects } = useContext(ProjectContext);

    const nonArchivedProjects = projects.filter(project => !project.archived);

    return (
        <>
            {nonArchivedProjects.length > 0 ? <HomePage projects={projects} /> : <HomePageNoProjects />}
        </>
    );
}

export default HomePageContainer;