import { useContext, useCallback, useMemo } from 'react';
import { SyntaxContext } from 'context';
import { ISheet } from 'types';
import { SheetService } from '../services/SheetServices';

type PreviewSheetsNumberProps = {
    previewSheetNumber: (sheet: ISheet) => string;
}

export const usePreviewSheetsNumber = (): PreviewSheetsNumberProps => {
    const { sheetSyntaxElements } = useContext(SyntaxContext);
    const previewSheetNumber = useCallback((sheet: ISheet) => SheetService.previewSheetNumber(sheet, sheetSyntaxElements), [sheetSyntaxElements]);

    return { previewSheetNumber }
}
