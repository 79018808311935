import React, { useContext, useState } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Archive, PencilSquare, Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useApplicationUI, useContextModel } from "hooks";
import { ProjectContext } from "context";
import { Project } from "types";
import _ from "lodash";
import { BE18Badge, FastBadge, FlatBadge } from "../Shared/Badges/FeatureBadges";
import OverlayIconButton from "../Shared/Buttons/OverlayIconButton";
import ConfirmModal from "../Shared/ConfirmModal";

interface Props {
    projects: Project[];
}

const ProjectsList: React.FC<Props> = ({ projects }) => {
    const { handleHideAppDrawer } = useApplicationUI();
    const { handleArchiveProject, handleDeleteProject } = useContext(ProjectContext);
    const [showConfirmArchiveProject, setShowConfirmArchiveProject] = useState(false);
    const [showConfirmDeleteProject, setShowConfirmDeleteProject] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState<string>("");
    const { getPhaseById } = useContextModel();
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <>
            <ListGroup variant="flush">
                <ListGroup.Item
                    style={{ backgroundColor: "#E4ECFB" }}
                    className="d-flex justify-content-between align-items-center"
                >
                    <small style={{ width: "20%" }}>{t("pages.shared.projects-overview.name")}</small>
                    <small style={{ width: "20%" }}>{t("pages.shared.projects-overview.internal-id")}</small>
                    <small style={{ width: "20%" }}>{t("pages.shared.projects-overview.phase")}</small>
                    <small style={{ width: "20%" }}>{t("pages.shared.projects-overview.tools")}</small>
                    <div style={{ width: 30 }}></div>
                </ListGroup.Item>
                <div style={{ overflowY: "auto" }}>
                    {projects &&
                        _.orderBy(projects, ["name", "internalNumber"], ["asc", "asc"]).map(project => (
                            <ListGroup.Item
                                action
                                onClick={() => {
                                    project.archived
                                        ? history.push(`/projects/${project.id}/settings`)
                                        : history.push(`/projects/${project.id}`);
                                    handleHideAppDrawer();
                                }}
                                key={project.id}
                                className="d-flex justify-content-between align-items-center text-truncate"
                            >
                                <small className="text-truncate" style={{ width: "20%" }}>
                                    {project.name}
                                </small>
                                <small className="text-truncate" style={{ width: "20%" }}>
                                    {project.internalNumber}
                                </small>
                                <small className="text-truncate" style={{ width: "20%" }}>
                                    {getPhaseById(project.phaseId).value}
                                </small>
                                <div className="text-truncate" style={{ width: "20%" }}>
                                    {project.projectFeatures && (
                                        <>
                                            {project.projectFeatures.FAST && <FastBadge />}
                                            {project.projectFeatures.FLAT && <FlatBadge />}
                                            {project.projectFeatures.BE18 && <BE18Badge />}
                                        </>
                                    )}
                                </div>
                                <div className="m-0 p-0">
                                    {!project.archived && (
                                        <OverlayIconButton
                                            overlayText="Archive"
                                            onClick={e => {
                                                e.stopPropagation();
                                                setSelectedProjectId(project.id);
                                                setShowConfirmArchiveProject(true);
                                            }}
                                        >
                                            <Archive className="p-0 m-0" />
                                        </OverlayIconButton>
                                    )}
                                    {project.archived && (
                                        <OverlayIconButton
                                            overlayText="Delete"
                                            onClick={event => {
                                                event.stopPropagation();
                                                setSelectedProjectId(project.id);
                                                setShowConfirmDeleteProject(true);
                                            }}
                                        >
                                            <Trash className="p-0 m-0" />
                                        </OverlayIconButton>
                                    )}
                                </div>
                            </ListGroup.Item>
                        ))}
                </div>
            </ListGroup>
            <ConfirmModal
                show={showConfirmArchiveProject}
                handleClose={() => setShowConfirmArchiveProject(false)}
                handleAction={() => {
                    handleArchiveProject(selectedProjectId);
                    setShowConfirmArchiveProject(false);
                }}
                description="Are you sure you want to archive this project?"
                confirmText="Confirm"
            />
            <ConfirmModal
                show={showConfirmDeleteProject}
                handleClose={() => setShowConfirmDeleteProject(false)}
                handleAction={() => {
                    handleDeleteProject(selectedProjectId);
                    setShowConfirmDeleteProject(false);
                }}
                description="Are you sure you want to delete this project?"
                confirmText="Confirm"
            />
        </>
    );
};

export default ProjectsList;
