import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ContainerWithStatusAlertHandling } from "../../hoc/withStatusAlerts";
import ModelList from "../ValueList/ModelList";

const ProjectValuePairListContainer: React.FC = () => {
    const { t } = useTranslation();
    return (
        <ContainerWithStatusAlertHandling
            fluid
            loadingMessage={"Henter værdilister..."}
            className="m-0 ps-4 pt-4 h-100"
            style={{ overflowY: "auto" }}
        >
            <Row>
                <Col>
                    <h1>{t("pages.FAST.value-lists.title")}</h1>
                    <h6 className="text-muted">{t("pages.FAST.value-lists.subtitle")}</h6>
                    <ModelList />
                </Col>
            </Row>
        </ContainerWithStatusAlertHandling>
    );
};

export default ProjectValuePairListContainer;
