import React from "react";
import { ListGroup, ToggleButton } from "react-bootstrap";
import { useDocumentsNaming } from "hooks";
import { IDocument } from "types";
import { logEvent } from "config";

interface Props {
    document: IDocument;
    handleClick: (documentId: string) => void;
}

const DocumentListRow = ({ document, handleClick }: Props): JSX.Element => {
    const { documentNumber, documentTitle } = useDocumentsNaming(document);

    return (
        <ListGroup.Item
            action
            onClick={() => {
                handleClick(document.id);
                logEvent("document_edit");
            }}
        >
            <span>{documentTitle()}</span>
            <span style={{ float: "right" }}>{documentNumber()}</span>
        </ListGroup.Item>
    );
};

export default DocumentListRow;
