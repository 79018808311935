import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormFields, useContextModel } from "hooks";
import { IModelDocumentKind } from "types";
import ConfirmModal from "../../Shared/ConfirmModal";

interface Props {
    handleClose: () => void;
    documentKind: IModelDocumentKind;
    documentKindModel: IModelDocumentKind[];
    handleCreate: (data: IModelDocumentKind) => Promise<void>;
    handleUpdate: (data: IModelDocumentKind) => Promise<void>;
    handleDelete: (id: string) => Promise<void>;
}

const DocumentKindModalForm: React.FC<Props> = ({
    handleClose,
    documentKind,
    documentKindModel,
    handleCreate,
    handleUpdate,
    handleDelete,
}) => {
    const { t } = useTranslation();
    const { fields, handleFieldChange, setFields } = useFormFields(documentKind);
    const newDocumentKind = documentKind.id === "";
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [isInvalid, setIsInvalid] = useState<string[]>([]);
    const { contextModel, getDocumentKindCategoryById } = useContextModel();

    const DocumentKindSubCodeExistsInModel = (): boolean => {
        const subCode = fields.subCode.toUpperCase();
        const dkcId = fields.documentKindCategoryId;
        if (newDocumentKind) {
            const existingValuePairs = documentKindModel.filter(
                dk => dk.subCode === subCode && dk.documentKindCategoryId === dkcId
            );
            return existingValuePairs.length > 0;
        } else {
            const existingValuePairs = documentKindModel.filter(
                dk => dk.subCode === subCode && dk.documentKindCategoryId === dkcId && dk.id !== fields.id
            );
            return existingValuePairs.length > 0;
        }
    };

    const handleSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        const valid = validateFields();
        if (valid) {
            if (newDocumentKind) {
                handleCreate(fields);
            } else {
                handleUpdate(fields);
            }
            handleClose();
        }
    };

    const handleTogglePreferred = () => {
        setFields(prevFields => ({ ...prevFields, preferred: !prevFields.preferred }));
    };

    const validateFields = (): boolean => {
        const invalid = [];
        const subCodeIsValid = () => fields.subCode !== "";
        const valueIsValid = () => fields.value !== "";
        const documentKindCategoryIdIsValid = () => fields.documentKindCategoryId !== "";

        if (!subCodeIsValid()) {
            invalid.push("SUBCODE");
        }
        if (!valueIsValid()) {
            invalid.push("VALUE");
        }
        if (!documentKindCategoryIdIsValid()) {
            invalid.push("DOCUMENT_KIND_CATEGORY_ID");
        }
        if (invalid.length > 0) {
            setIsInvalid(invalid);
            return false;
        }
        return true;
    };

    return (
        <>
            <Modal show={true} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    {t(newDocumentKind ? "actions.add" : "actions.edit")}{" "}
                    {newDocumentKind
                        ? ""
                        : `${getDocumentKindCategoryById(documentKind.documentKindCategoryId).code}.${
                              documentKind.subCode
                          } - ${documentKind.value}`}
                </Modal.Header>
                <Modal.Body>
                    <Form className="d-grid gap-2">
                        <Form.Group controlId="value">
                            <Form.Label>
                                {t("pages.FAST.value-lists.document-kind.fields.value")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                isInvalid={isInvalid.includes("VALUE")}
                                type="text"
                                value={fields.value}
                                onChange={event => {
                                    if (isInvalid.includes("VALUE")) {
                                        setIsInvalid((fieldIds: string[]) =>
                                            fieldIds.filter(fieldId => fieldId !== "VALUE")
                                        );
                                    }
                                    handleFieldChange(event as React.ChangeEvent<HTMLInputElement>);
                                }}
                            />
                        </Form.Group>
                        <Form.Group controlId="documentKindCategoryId">
                            <Form.Label>
                                {t("pages.FAST.value-lists.document-kind.fields.code")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                                isInvalid={isInvalid.includes("DOCUMENT_KIND_CATEGORY_ID")}
                                value={fields.documentKindCategoryId}
                                onChange={event => {
                                    if (isInvalid.includes("DOCUMENT_KIND_CATEGORY_ID")) {
                                        setIsInvalid((fieldIds: string[]) =>
                                            fieldIds.filter(fieldId => fieldId !== "DOCUMENT_KIND_CATEGORY_ID")
                                        );
                                    }
                                    handleFieldChange(event);
                                }}
                            >
                                {contextModel.documentKindCategories.map(dkc => (
                                    <option key={dkc.id} value={dkc.id}>
                                        {dkc.code} - {dkc.value}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="subCode">
                            <Form.Label>
                                {t("pages.FAST.value-lists.document-kind.fields.subcode")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                isInvalid={isInvalid.includes("SUBCODE") || DocumentKindSubCodeExistsInModel()}
                                type="number"
                                value={fields.subCode}
                                onChange={event => {
                                    if (isInvalid.includes("SUBCODE")) {
                                        setIsInvalid((fieldIds: string[]) =>
                                            fieldIds.filter(fieldId => fieldId !== "SUBCODE")
                                        );
                                    }
                                    handleFieldChange(event as React.ChangeEvent<HTMLInputElement>);
                                }}
                            />
                        </Form.Group>
                        {DocumentKindSubCodeExistsInModel() && (
                            <Form.Control.Feedback type="invalid">
                                Underkoden bliver anvendt på anden værdi
                            </Form.Control.Feedback>
                        )}

                        <Form.Group controlId="preferred">
                            <Form.Check
                                type="checkbox"
                                label={t("pages.FAST.value-lists.document-kind.fields.preferred")}
                                checked={fields.preferred}
                                onChange={handleTogglePreferred}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                    {!newDocumentKind && (
                        <Button
                            variant="danger"
                            onClick={() => {
                                setShowConfirmModal(true);
                            }}
                        >
                            {t("buttons.delete")}
                        </Button>
                    )}
                    <Button
                        disabled={isInvalid.length > 0 || DocumentKindSubCodeExistsInModel()}
                        variant="primary"
                        onClick={handleSubmit}
                    >
                        {t(newDocumentKind ? "buttons.add" : "buttons.save")}
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                <ConfirmModal
                    show={showConfirmModal}
                    handleAction={() => {
                        handleDelete(fields.id);
                        handleClose();
                    }}
                    handleClose={() => {
                        setShowConfirmModal(false);
                    }}
                    description={t("pages.FAST.value-lists.delete-confirm")}
                    confirmText={t("buttons.delete")}
                />
            }
        </>
    );
};

export default DocumentKindModalForm;
