import React, { useState } from "react";
import { Button, Col, Container, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { RevitParameter } from "types";
import { FormEvent, useAuth } from "hooks";
import _ from "lodash";
import ConfirmModal from "../Shared/ConfirmModal";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { RevitParameterService } from "services";

interface Props {
    fields: RevitParameter;
    handleCreate: () => void;
    handleUpdate: () => void;
    handleDelete: () => void;
    handleClose: () => void;
    handleFieldChange: (e: FormEvent) => void;
    handleItemClick: (value: string) => void;
}

export const RevitParameterEditModal: React.FC<Props> = ({
    fields,
    handleItemClick,
    handleCreate,
    handleUpdate,
    handleDelete,
    handleClose,
    handleFieldChange,
}) => {
    const { t } = useTranslation();
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
    const parameterValueIsValid = fields.parameterValue !== "";
    const revitValueIsValid = fields.revitParameterName !== "";
    const inputIsValid = parameterValueIsValid && revitValueIsValid;
    const { getAccessToken } = useAuth();

    const { data: Parameters } = useQuery({
        queryKey: ["revitParametersList"],
        queryFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) {
                throw new Error("No access token found");
            }
            return RevitParameterService.getRevitParameterList(accessToken);
        },
    });

    return (
        <>
            <Modal size="xl" show={true} onHide={handleClose} backdrop="static" centered>
                <Modal.Header closeButton />
                <Modal.Body className="px-0">
                    <Form noValidate className="d-grid gap-2">
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Group controlId="revitParameterName">
                                        <Form.Label>
                                            {t("pages.FAST.settings.revit-parameters.fields.revit-parameter")}
                                        </Form.Label>
                                        <Form.Control
                                            isValid={revitValueIsValid}
                                            isInvalid={!revitValueIsValid}
                                            size="sm"
                                            placeholder="Indtast revit parameter navn"
                                            value={fields.revitParameterName}
                                            onChange={handleFieldChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="parameterValue">
                                        <Form.Label>
                                            {t("pages.FAST.settings.revit-parameters.fields.FAST-name")}
                                        </Form.Label>
                                        <Form.Control
                                            isValid={parameterValueIsValid}
                                            isInvalid={!parameterValueIsValid}
                                            size="sm"
                                            placeholder="Indtast parameter værdi"
                                            value={fields.parameterValue}
                                            onChange={handleFieldChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                    <hr />
                    <h6 className="px-4">{t("pages.FAST.settings.revit-parameters.fields.select-fast-parameter")}</h6>
                    <Row className="ms-4 me-5 mb-2 mt-3">
                        <Col>{t("pages.FAST.settings.revit-parameters.fields.id")}</Col>
                        <Col>{t("pages.FAST.settings.revit-parameters.fields.description")}</Col>
                        <Col>{t("pages.FAST.settings.revit-parameters.fields.code")}</Col>
                    </Row>
                    <div className="px-4" style={{ overflow: "auto", height: "400px" }}>
                        <ListGroup>
                            {Parameters &&
                                _.sortBy(Parameters, "id").map((parameter, idx) => (
                                    <ListGroup.Item
                                        key={parameter.id}
                                        action
                                        onClick={() => handleItemClick(parameter.id)}
                                    >
                                        <Row>
                                            <Col>{parameter.id}</Col>
                                            <Col>{parameter.description}</Col>
                                            <Col>
                                                <small>{parameter.codePrefix}</small>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))}
                        </ListGroup>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                    {fields.id && (
                        <Button variant="danger" onClick={() => setShowConfirmDeleteModal(true)}>
                            {t("buttons.delete")}
                        </Button>
                    )}
                    {!fields.id && (
                        <Button variant="primary" disabled={!inputIsValid} onClick={() => handleCreate()}>
                            {t("buttons.save")}
                        </Button>
                    )}
                    {fields.id && (
                        <Button variant="primary" disabled={!inputIsValid} onClick={() => handleUpdate()}>
                            {t("buttons.save")}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

            <ConfirmModal
                show={showConfirmDeleteModal}
                handleAction={handleDelete}
                handleClose={() => setShowConfirmDeleteModal(false)}
                description={t("pages.FAST.settings.revit-parameters.delete-confirm")}
                confirmText={t("buttons.confirm")}
            />
        </>
    );
};

export default RevitParameterEditModal;
