import { BuildingsContext } from "context";
import { StoreysContext } from "context/StoreysProvider";
import { forEach, sortBy } from "lodash";
import { useContext } from "react";
import { Building, PlanTemplate, Storey } from "types";

export const usePlanTemplateHovertext = (sheetTemplate: PlanTemplate) => {
    const { storeys: allStoreys } = useContext(StoreysContext);
    const { buildings } = useContext(BuildingsContext);

    if (!sheetTemplate) {
        return "";
    }

    let fullText = "";

    forEach(buildings as Building[], building => {
        let buildingText = `${building.name} - `;
        let storiesText = "";
        let numSheetsCreatedForBuilding = 0;

        if (!building.id) return;

        let numStories = allStoreys[building.id].length;
        const sortedStoreys = sortBy(allStoreys[building.id], ["number", "id"]);

        forEach(sortedStoreys, (storey: Storey) => {
            if (storey.code === sheetTemplate.storeyCode || (sheetTemplate.storeyCode === "E?" && storey.isPlan)) {
                if (numStories === 1) {
                    storiesText += `${storey.name}`;
                } else {
                    storiesText += `${storey.name}, `;
                }
                numSheetsCreatedForBuilding++;
            }
            numStories--;
        });
        if (numSheetsCreatedForBuilding > 0) {
            fullText += buildingText + storiesText + "\n";
        }
    });
    return fullText;
};
