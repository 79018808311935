import { t } from "i18next";
import React, { useState } from "react";
import { Button, Card, Container, Row } from "react-bootstrap";
import EditProjectRevitParametersModalForm from "./EditProjectRevitParametersModalForm";
import { useProjectId, useProject } from "hooks";

const ParametersContainer: React.FC = () => {
    const [showEditProjectRevitParameters, setShowEditProjectRevitParameters] = useState<boolean>(false);
    const projectId = useProjectId();
    const { project } = useProject(projectId);

    return (
        <Container fluid className="m-0 p-4 w-100 h-100" style={{ overflowY: "auto" }}>
            <h1>{t("pages.FAST.parameters.title")}</h1>
            <h6 className="text-muted">{t("pages.FAST.parameters.subtitle")}</h6>
            <Row>
                <Card style={{ width: "300px" }} className="m-3">
                    <Card.Body>{t("pages.FAST.settings.card-title.revit-parameters")}</Card.Body>
                    <Button
                        className="m-1"
                        variant="secondary"
                        disabled={project?.archived || false}
                        onClick={() => {
                            setShowEditProjectRevitParameters(true);
                        }}
                    >
                        {t("buttons.edit")}
                    </Button>
                </Card>
                {showEditProjectRevitParameters && (
                    <EditProjectRevitParametersModalForm
                        projectId={projectId}
                        handleClose={() => setShowEditProjectRevitParameters(false)}
                    />
                )}
            </Row>
        </Container>
    );
};

export default ParametersContainer;
