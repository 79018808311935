import { Storey, IAbstractDocument, AbstractBaseSheetTemplate, ISheet } from "types";
import { SyntaxElementIds } from "_constants";
import { API_URL } from "config";
import axios from "axios";

type AbstractSheetService = {
    previewSheetNumber: (sheet: IAbstractDocument, syntaxElements: SyntaxElementIds[]) => string;
    sortBySheetNumber: (sheet: IAbstractDocument, syntaxElements: SyntaxElementIds[], storeys: Storey[]) => string;
    addSheets: (
        projectId: string,
        selectedSheetTemplates: {
            amount: number;
            sheetTemplate: AbstractBaseSheetTemplate;
        }[],
        accessToken: string
    ) => Promise<void>;
    deleteSheets: (projectId: string, sheetIds: string[], accessToken: string) => Promise<void>;
    deleteSheet: (projectId: string, sheetId: string, accessToken: string) => Promise<void>;
    getSheet: (projectId: string, sheetId: string, accessToken: string) => Promise<ISheet>;
    updateSheet: (
        projectId: string,
        sheetId: string,
        data: ISheet | Partial<ISheet>,
        accessToken: string
    ) => Promise<ISheet>;
    getSheets: (projectId: string, accessToken: string) => Promise<ISheet[]>;
    mismatchProjectIdsOnSheets: (projectId: string, accessToken: string) => Promise<boolean>;
    updateProjectIdsOnSheets: (projectId: string, accessToken: string) => Promise<ISheet[]>;
    recalculateSheetsSerial: (projectId: string, accessToken: string) => Promise<ISheet[]>;
};

export const SheetService: AbstractSheetService = {
    previewSheetNumber: (previewSheet: IAbstractDocument, syntaxElements: SyntaxElementIds[]): string => {
        const stringBuilder: string[] = [];

        if (!syntaxElements || syntaxElements.length === 0) {
            return "";
        }
        syntaxElements.forEach(syntaxElement => {
            if (syntaxElement === SyntaxElementIds.PROJECTID) {
                const projectId = previewSheet.projectId || "IXX";
                stringBuilder.push(projectId);
            }
            if (syntaxElement === SyntaxElementIds.KNOWLEDGEAREA) {
                const knowledgeCode = previewSheet.knowledgeCode || "KXX";
                stringBuilder.push(knowledgeCode);
            }
            if (syntaxElement === SyntaxElementIds.DISCIPLINE) {
                const disciplineCode = previewSheet.disciplineCode || "MXX";
                stringBuilder.push(disciplineCode);
            }
            if (syntaxElement === SyntaxElementIds.SHEETTYPE) {
                const sheetTypeCode = previewSheet.sheetTypeCode || "HXX";
                stringBuilder.push(sheetTypeCode);
            }
            if (syntaxElement === SyntaxElementIds.LOCATION) {
                const locationCode = previewSheet.locationCode || "LXX";
                stringBuilder.push(locationCode);
            }
            if (syntaxElement === SyntaxElementIds.STOREY) {
                const storeyCode = previewSheet.storeyCode || "EX";
                stringBuilder.push(storeyCode);
            }
            if (syntaxElement === SyntaxElementIds.SERIALNUMBER) {
                const serialNumber = previewSheet.serialNumber || "N0001";
                stringBuilder.push(serialNumber);
            }
            if (syntaxElement === SyntaxElementIds.PHASE) {
                const phaseCode = previewSheet.phaseCode || "AXX";
                stringBuilder.push(phaseCode);
            }
            if (syntaxElement === SyntaxElementIds.THEMEVALUELIST) {
                const themeCode = previewSheet.themeCode || "TBG";
                stringBuilder.push(themeCode);
            }
            if (syntaxElement === SyntaxElementIds.STAGE) {
                const stageCode = previewSheet.stageCode || "SXX";
                stringBuilder.push(stageCode);
            }
            if (syntaxElement === SyntaxElementIds.FILETYPE) {
                const fileType = previewSheet.fileTypeCode || "FX";
                stringBuilder.push(fileType);
            }
            if (syntaxElement === SyntaxElementIds.BUSINESSTYPE) {
                const businessType = previewSheet.businessTypeCode || "BX";
                stringBuilder.push(businessType);
            }
        });

        return stringBuilder.join("_");
    },
    sortBySheetNumber: (
        previewSheet: IAbstractDocument,
        syntaxElements: SyntaxElementIds[],
        storeys: Storey[]
    ): string => {
        const stringBuilder: string[] = [];

        syntaxElements.forEach(syntaxElement => {
            if (syntaxElement === SyntaxElementIds.PROJECTID) {
                const projectId = previewSheet.projectId || "IXX";
                stringBuilder.push(projectId);
            }
            if (syntaxElement === SyntaxElementIds.KNOWLEDGEAREA) {
                const knowledgeCode = previewSheet.knowledgeCode || "KXX";
                stringBuilder.push(knowledgeCode);
            }
            if (syntaxElement === SyntaxElementIds.DISCIPLINE) {
                const disciplineCode = previewSheet.disciplineCode || "MXX";
                stringBuilder.push(disciplineCode);
            }
            if (syntaxElement === SyntaxElementIds.SHEETTYPE) {
                const sheetTypeCode = previewSheet.sheetTypeCode || "HXX";
                stringBuilder.push(sheetTypeCode);
            }
            if (syntaxElement === SyntaxElementIds.LOCATION) {
                const locationCode = previewSheet.locationCode || "LXX";
                stringBuilder.push(locationCode);
            }
            if (syntaxElement === SyntaxElementIds.STOREY) {
                const storeyPriority =
                    storeys.find(s => s.id === previewSheet.storeyId)?.sortPriority?.toString() || "99";
                stringBuilder.push(storeyPriority);
            }
            if (syntaxElement === SyntaxElementIds.SERIALNUMBER) {
                const serialNumber = previewSheet.serialNumber || "N0001";
                stringBuilder.push(serialNumber);
            }
            if (syntaxElement === SyntaxElementIds.PHASE) {
                const phaseCode = previewSheet.phaseCode || "AXX";
                stringBuilder.push(phaseCode);
            }
            if (syntaxElement === SyntaxElementIds.THEMEVALUELIST) {
                const themeCode = previewSheet.themeCode || "TBG";
                stringBuilder.push(themeCode);
            }
            if (syntaxElement === SyntaxElementIds.STAGE) {
                const stageCode = previewSheet.stageCode || "SXX";
                stringBuilder.push(stageCode);
            }
            if (syntaxElement === SyntaxElementIds.FILETYPE) {
                const fileType = previewSheet.fileTypeCode || "FX";
                stringBuilder.push(fileType);
            }
            if (syntaxElement === SyntaxElementIds.BUSINESSTYPE) {
                const businessType = previewSheet.businessTypeCode || "BX";
                stringBuilder.push(businessType);
            }
        });

        return stringBuilder.join("_");
    },
    addSheets: async (
        projectId: string,
        selectedSheetTemplates: {
            amount: number;
            sheetTemplate: AbstractBaseSheetTemplate;
        }[],
        accessToken: string
    ) => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/sheets`;
        const requestJson = JSON.stringify({ selectedSheetTemplates });
        const response = await axios.post(requestUrl, requestJson, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.data;
    },
    deleteSheets: async (projectId: string, sheetIds: string[], accessToken: string) => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/delete-sheets`;
        const requestJson = JSON.stringify({ sheetIds });
        const response = await axios.put(requestUrl, requestJson, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.data;
    },
    updateSheet: async (projectId: string, sheetId: string, data: ISheet | Partial<ISheet>, accessToken: string) => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/sheets/${sheetId}`;
        const requestJson = JSON.stringify({ sheet: data });
        const response = await axios.put(requestUrl, requestJson, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.data;
    },
    getSheet: async (projectId: string, sheetId: string, accessToken: string) => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/sheets/${sheetId}`;
        const response = await axios.get(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.data as ISheet;
    },
    deleteSheet: async (projectId: string, sheetId: string, accessToken: string) => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/sheets/${sheetId}`;
        const response = await axios.delete(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.data;
    },
    getSheets: async (projectId: string, accessToken: string) => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/sheets`;
        const response = await axios.get(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.data as ISheet[];
    },
    updateProjectIdsOnSheets: async (projectId: string, accessToken: string) => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/sheets/utility/update-project-ids`;
        const response = await axios.put(
            requestUrl,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        return response.data;
    },
    mismatchProjectIdsOnSheets: async (projectId: string, accessToken: string) => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/sheets/utility/mismatching-project-ids`;
        const response = await axios.get(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.data.anyMismatch as boolean;
    },
    recalculateSheetsSerial: async (projectId: string, accessToken: string) => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/sheets/utility/recalculate-sheets-serial`;
        const response = await axios.put(
            requestUrl,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        return response.data as ISheet[];
    },
};
