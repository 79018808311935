import React, { useContext, useState } from "react";
import { DropdownButton, Dropdown, Row, Col, Button } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { System, SystemDiscipline } from "types";
import { EMPTY_SYSTEM } from "_constants";
import _ from "lodash";
import { ContainerWithStatusAlertHandling } from "../../../hoc/withStatusAlerts";
import SystemCard from "./SystemCard";
import SystemModal from "./SystemModal";
import ProjectSupplyModal from "../ProjectSupplyModal";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { SystemService } from "services/SystemService";
import { useProjectId, useAuth } from "hooks";

const SystemsContainer: React.FC = () => {
    const projectId = useProjectId();
    const { getAccessToken } = useAuth();
    const {
        data: systems,
        isLoading,
        error,
    } = useQuery({
        queryKey: ["systems"],
        queryFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) {
                throw new Error("No access token found");
            }
            return SystemService.getSystems(projectId, accessToken);
        },
        enabled: !!projectId,
        select: response => response.data,
    });

    const [showSystemModal, setShowSystemModal] = useState<boolean>(false);
    const [selectedSystem, setSelectedSystem] = useState<System>(EMPTY_SYSTEM);
    const [showSupplyModal, setShowSupplyModal] = useState<boolean>(false);
    const { t } = useTranslation();

    const handleSelectSystem = (systemDiscipline: SystemDiscipline): void => {
        const newSystem = {
            ...EMPTY_SYSTEM,
            name: `${t(`pages.FLAT.systems.disciplines.${systemDiscipline}`)}`,
            systemDiscipline,
        };
        setSelectedSystem(newSystem as System);
        setShowSystemModal(true);
    };

    const handleOpenEditSystemModal = (system: System) => {
        setSelectedSystem(system);
        setShowSystemModal(true);
    };

    return (
        <ContainerWithStatusAlertHandling
            className="m-0 p-4 h-100"
            style={{ overflow: "auto" }}
            fluid
            loading={isLoading}
            loadingMessage={t("pages.FLAT.systems.loading") as string}
            error={error as Error}
        >
            <h1>{t("pages.FLAT.systems.title")}</h1>
            <h6 className="text-muted">{t("pages.FLAT.systems.subtitle")}</h6>
            <Row className="mt-4">
                <Col className="mb-4" xs={12}>
                    <DropdownButton
                        className="d-inline-flex me-2 align-items-center"
                        variant="primary"
                        title={
                            <>
                                <Plus size="1.5rem" />
                                <span>{t("pages.FLAT.systems.add-system")}</span>
                            </>
                        }
                        id="addSystem"
                    >
                        {Object.keys(SystemDiscipline).map(systemDiscipline => (
                            <Dropdown.Item
                                key={systemDiscipline}
                                onClick={() => handleSelectSystem(systemDiscipline as SystemDiscipline)}
                            >
                                {`${t(`pages.FLAT.systems.disciplines.${systemDiscipline}`)}`}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                    <Button variant="outline-primary" onClick={() => setShowSupplyModal(true)}>
                        {t("pages.FLAT.systems.supply-condition")}
                    </Button>
                </Col>
            </Row>
            <div>
                <Row className="ms-0">
                    {systems &&
                        _.orderBy(systems, ["systemDiscipline"]).map(system => (
                            <SystemCard key={system.id} system={system} onClick={handleOpenEditSystemModal} />
                        ))}
                </Row>
            </div>
            {showSystemModal && <SystemModal system={selectedSystem} handleClose={() => setShowSystemModal(false)} />}
            {showSupplyModal && <ProjectSupplyModal handleClose={() => setShowSupplyModal(false)} />}
        </ContainerWithStatusAlertHandling>
    );
};

export default SystemsContainer;
