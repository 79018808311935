import { SystemDiscipline } from "types";

export interface IComponentDimensions {
    heatingPipeForward: number;
    heatingPipeReturn: number;
    heatingIsolationSize: number;
    heatingIsolationClass: string;
}

export interface IShaftType {
    id: string;
    name: string;
    amount: number;
    buildingId: string;
    systemIds: string[];
    storeyIds: string[];
    shaftConnections: IShaftConnection[];
    calculations?: IShaftCalculation[];
    shaftelements?: IShaftElement[];
}

export interface IShaftCalculation {
    id: string;
    creationDate: string;
    name: string;
    componentDimensions?: IComponentDimensions;
    waterCold?: number;
    waterHot?: number;
    useHousingProjectValues?: boolean;
    heatingFlow?: number;
    heatingFlowIso?: string;
    heatingReturn?: number;
    heatingReturnIso?: string;
    waterCirculation?: number;
    circulationWaterIso?: string;
    sewage?: number;
    drainage?: number;
    ventilationSupply?: number;
    ventilationExtract?: number;
    ventilationIntake?: number;
    ventilationExhaust?: number;
    ventilationSupplyIso?: string;
    ventilationExtractIso?: string;
    ventilationIntakeIso?: string;
    ventilationExhaustIso?: string;
    cableLatter?: number;
    powerBoard?: string;
    coldWaterIso?: string;
    hotWaterIso?: string;
}

export enum ShaftSizingType {
    SLIM = 'SLIM',
    QUADRATIC = 'QUADRATIC',
    WIDTHGIVENHEIGHT = 'WIDTHGIVENHEIGHT',
}

export interface IShaftConnection {
    shaftConnectionUID: string;
    storeyId: string;
    apartmentId: string;
    roomIds: string[];
    systemIds: string[];
}

export interface IShaftElement {
    id: string;
    name: string;
    system: SystemDiscipline;
    coordinate?: Coordinate;
}

export interface IShaftCoordinates {
    boundingBox: BoundingBox;
    pipeElements: IPipe[];
    cableTrayElements: ICableTray[];
}

export interface BoundingBox {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface Coordinate {
    x: number;
    y: number;
}

export interface ICableTray extends IShaftElement {
    width: number;
}

export interface IPipe extends IShaftElement {
    diameter: number;
}

export interface IIsolatedPipe extends IPipe {
    isolationClass: IsolationClass;
}

export enum IsolationClass {
    IC1 = 'IC1',
    IC2 = 'IC2',
    IC3 = 'IC3',
    IC4 = 'IC4',
    IC5 = 'IC5',
    IC6 = 'IC6',
    ENERGY = 'ENERGY',
    ENERGY_OUTSIDE = 'ENERGY_OUTSIDE',
}