export type System = {
    id: string;
    name: string;
} & (
    | HeatingSystemProps
    | VentilationSystemProps
    | SewageSystemProps
    | ElectricitySystemProps
    | DrainageSystemProps
    | WaterSystemProps
    | {
          systemDiscipline: undefined;
      }
);

type ExtractSystem<TSystem, UDiscipline, VType = unknown> = TSystem extends {
    systemDiscipline: UDiscipline;
    systemType?: VType;
}
    ? TSystem
    : never;

export type HeatingSystem = ExtractSystem<System, SystemDiscipline.HEATING>;
export type VentilationSystem = ExtractSystem<System, SystemDiscipline.VENTILATION>;
export type CentralVentilationSystem = ExtractSystem<System, SystemDiscipline.VENTILATION, SystemType.CENTRAL>;
export type DecentralVentilationSystem = ExtractSystem<System, SystemDiscipline.VENTILATION, SystemType.DECENTRAL>;
export type SewageSystem = ExtractSystem<System, SystemDiscipline.SEWAGE>;
export type ElectricitySystem = ExtractSystem<System, SystemDiscipline.ELECTRICITY>;
export type DrainageSystem = ExtractSystem<System, SystemDiscipline.DRAINAGE>;
export type WaterSystem = ExtractSystem<System, SystemDiscipline.WATER>;
export type CentralWaterSystem = ExtractSystem<System, SystemDiscipline.WATER, SystemType.CENTRAL>;
export type DecentralWaterSystem = ExtractSystem<System, SystemDiscipline.WATER, SystemType.DECENTRAL>;

export enum SystemDiscipline {
    WATER = "WATER",
    VENTILATION = "VENTILATION",
    SEWAGE = "SEWAGE",
    HEATING = "HEATING",
    ELECTRICITY = "ELECTRICITY",
    DRAINAGE = "DRAINAGE",
}

export enum SystemType {
    CENTRAL = "CENTRAL",
    DECENTRAL = "DECENTRAL",
}

export enum SystemLocation {
    ROOF = "ROOF",
    BASEMENT = "BASEMENT",
}

export enum HousingType {
    UNKNOWN = "UNKNOWN",
    STUDENTHOUSING = "STUDENTHOUSING",
    DORMITORYHOUSING = "DORMITORYHOUSING",
    ORDINARYHOUSING = "ORDINARYHOUSING",
}

export interface ProjectSupply {
    heatingSupply: HeatingSupply;
    systemFlow: SystemFlow;
    primaryTemperatureSetForward: number;
    primaryTemperatureSetReturn: number;
}

export enum HeatingSupply {
    DISTRICTHEATING = "DISTRICTHEATING",
    NATURALGAS = "NATURALGAS",
    OTHER = "OTHER",
}

export enum SystemFlow {
    DIRECT = "DIRECT",
    INDIRECT = "INDIRECT",
}

type DrainageSystemProps = {
    systemDiscipline: SystemDiscipline.DRAINAGE;
    pipeSize: 110 | 160;
};

type ElectricitySystemProps = {
    systemDiscipline: SystemDiscipline.ELECTRICITY;
    integratedSwitchboard: true;
};

type HeatingSystemProps = {
    systemDiscipline: SystemDiscipline.HEATING;
    shuntPlacement: SystemType;
    heatingMethod: HeatingMethod;
    temperatureSetForward: number;
    temperatureSetReturn: number;
    heatingEffect: number;
};

export enum HeatingMethod {
    UNDERFLOORHEATING = "UNDERFLOORHEATING",
    RADIATOR = "RADIATOR",
}

type SewageSystemProps = {
    systemDiscipline: SystemDiscipline.SEWAGE;
};

type VentilationSystemProps = {
    systemDiscipline: SystemDiscipline.VENTILATION;
    fireProtection: FireProtection;
    heatingSurface?: boolean;
} & (CentralVentilationSystemProps | DecentralVentilationSystemProps);

type CentralVentilationSystemProps = {
    systemType: SystemType.CENTRAL;
    fireProtection: FireProtection.SMOKEVENTILATION;
};

type DecentralVentilationSystemProps = {
    systemType: SystemType.DECENTRAL;
    fireProtection: FireProtection.FIREDAMPER;
    sharedExhaust?: boolean;
    sharedIntake?: boolean;
};

export enum FireProtection {
    FIREDAMPER = "FIREDAMPER",
    SMOKEVENTILATION = "SMOKEVENTILATION",
}

type WaterSystemProps = {
    systemDiscipline: SystemDiscipline.WATER;
} & (CentralWaterSystemProps | DecentralWaterSystemProps);

type DecentralWaterSystemProps = {
    systemType: SystemType.DECENTRAL;
    component: WaterComponent;
    componentHeight: number;
    componentWidth: number;
    componentEffect: number;
};

type CentralWaterSystemProps = {
    systemType: SystemType.CENTRAL;
};

export enum WaterComponent {
    HOTWATERTANK = "HOTWATERTANK",
    HEATEXCHANGER = "HEATEXCHANGER",
}
