import React, { ReactNode, useEffect, useState, useContext, createContext } from "react";
import { Room } from "types";
import { LoggingContext } from "context";
import { addDoc, collection, deleteDoc, doc, onSnapshot, updateDoc } from "@firebase/firestore";
import { firestore } from "config";

interface IRoomsContext {
    error: Error | null | undefined;
    handleCreateRoom: (room: Room) => void;
    handleUpdateRoom: (room: Room) => void;
    handleDeleteRoom: (roomId: string) => void;
    loadingRooms: boolean;
    rooms: Room[];
}

export const RoomsContext = createContext({} as IRoomsContext);

interface Props {
    projectId?: string | undefined;
    children?: ReactNode;
}

const RoomsProvider = ({ projectId, children }: Props): JSX.Element => {
    const [loadingRooms, setLoadingRooms] = useState<boolean>(true);
    const [rooms, setRooms] = useState<Room[]>([]);
    const [error, setError] = useState<Error | null>();
    const { logger } = useContext(LoggingContext);

    useEffect(
        () => {
            if (!projectId) {
                return;
            }
            const roomsRef = collection(firestore, 'projects', projectId, 'rooms');

            setLoadingRooms(true);
            onSnapshot(roomsRef, roomsSnapshot => {
                const rooms: Room[] = [];
                roomsSnapshot.forEach(roomDocument => {
                    const room = roomDocument.data() as Room;
                    room.id = roomDocument.id;
                    const fixtures = room.fixtures.sort((a, b) => a.priority - b.priority);
                    const roomWithSortedFixtures = { ...room, fixtures: fixtures };
                    rooms.push(roomWithSortedFixtures as Room);
                });
                setRooms(rooms);
                setLoadingRooms(false);
            },
                error => {
                    setLoadingRooms(false);
                    setError(new Error("Rum kunne ikke hentes. Prøv venligst igen."))
                    logger.error("Couldn't fetch rooms", error);
                }
            );
        }, [projectId, logger]);

    const handleCreateRoom = async (room: Room) => {
        if (!projectId) {
            setError(new Error("Rum kunne ikke oprettes, prøv igen."));
            return;
        }
        try {
            setLoadingRooms(true);
            const fixtures = room.fixtures.map((fixture, index) => ({ ...fixture, priority: index + 1 }));
            const roomsCollectionRef = collection(firestore, "projects", projectId, "rooms");
            addDoc(roomsCollectionRef, cleanRoom({ ...room, fixtures: fixtures }));
        } catch (error) {
            setError(new Error("Rum kunne ikke opdateres, prøv igen."));
            logger.error("Failed to update room", error);
        } finally {
            setLoadingRooms(false);
        }
    }

    const handleUpdateRoom = async (room: Room) => {
        if (!projectId) {
            setError(new Error("Rum kunne ikke redigeres, prøv igen."));
            return;
        }
        try {
            setLoadingRooms(true);
            const fixtures = room.fixtures.map((fixture, index) => ({ ...fixture, priority: index + 1 }));
            const docRef = doc(firestore, "projects", projectId, "rooms", room.id);
            updateDoc(docRef, cleanRoom({ ...room, fixtures: fixtures }));
        } catch (error) {
            setError(new Error("Rum kunne ikke opdateres, prøv igen."));
            logger.error("Failed to update room", error);
        } finally {
            setLoadingRooms(false);
        }
    }

    const handleDeleteRoom = async (roomId: string) => {
        if (!projectId) {
            setError(new Error("Rum kunne ikke slettes, prøv igen."));
            return;
        }
        try {
            setLoadingRooms(true);
            const docRef = doc(firestore, "projects", projectId, "rooms", roomId);
            deleteDoc(docRef);
        } catch (error) {
            setError(new Error("Rum kunne ikke slettes, prøv igen."));
            logger.error("Failed to delete room", error);
        } finally {
            setLoadingRooms(false);
        }

    }

    const cleanRoom = (room: Partial<Room>) => {
        delete room.id;
        return room;
    }

    return (<RoomsContext.Provider
        value={{
            error,
            handleCreateRoom,
            handleUpdateRoom,
            handleDeleteRoom,
            loadingRooms,
            rooms,
        }}
    >
        {children}
    </RoomsContext.Provider>
    );
}
export default RoomsProvider;