import React, { ReactNode, useEffect, useState, useContext, createContext } from "react";
import { IShaftType } from "types";
import { LoggingContext } from "context";
import { addDoc, collection, deleteDoc, doc, updateDoc } from "@firebase/firestore";
import { onSnapshot } from "firebase/firestore";
import { firestore, API_URL } from "config";
import axios from "axios";
import { useAuth } from "hooks";

interface IShaftsContext {
    error: Error | null | undefined;
    handleCreateShaft: (shaft: IShaftType) => void;
    handleUpdateShaft: (shaft: IShaftType, calculationId: string) => void;
    handleDeleteShaft: (shaftId: string) => void;
    createShaftCalculation: (shaftId: string) => void;
    loadingShafts: boolean;
    shafts: IShaftType[];
}

export const ShaftsContext = createContext({} as IShaftsContext);

interface Props {
    projectId?: string | undefined;
    children?: ReactNode;
}

const ShaftsProvider = ({ projectId, children }: Props): JSX.Element => {
    const [loadingShafts, setLoadingShafts] = useState<boolean>(true);
    const [shafts, setShafts] = useState<IShaftType[]>([]);
    const [error, setError] = useState<Error | null>();
    const { logger } = useContext(LoggingContext);
    const { getAccessToken } = useAuth();

    useEffect(() => {
        if (!projectId) {
            return;
        }
        const shaftsRef = collection(firestore, "projects", projectId, "shafts");
        setLoadingShafts(true);
        onSnapshot(
            shaftsRef,
            shaftsSnapshot => {
                const shafts: IShaftType[] = [];
                shaftsSnapshot.forEach(shaftDocument => {
                    const shaft = shaftDocument.data() as IShaftType;
                    shaft.id = shaftDocument.id;
                    shafts.push(shaft as IShaftType);
                });

                setShafts(shafts);
                setLoadingShafts(false);
            },
            error => {
                setLoadingShafts(false);
                setError(new Error("Skakte kunne ikke hentes. Prøv venligst igen."));
                logger.error("Couldn't fetch buildings", error);
            }
        );
    }, [projectId, logger]);

    const handleCreateShaft = async (shaft: IShaftType) => {
        if (!projectId) {
            setError(new Error("Skakt kunne ikke oprettes, prøv igen."));
            return;
        }
        try {
            setLoadingShafts(true);
            const shaftsCollectionRef = collection(firestore, "projects", projectId, "shafts");
            const createdShaft = await addDoc(shaftsCollectionRef, cleanShaft(shaft));

            await createShaftCalculation(createdShaft.id);
        } catch (error) {
            setError(new Error("Skakt kunne ikke opdateres, prøv igen."));
            logger.error("Failed to update shaft", error);
        } finally {
            setLoadingShafts(false);
        }
    };

    const handleUpdateShaft = async (shaft: IShaftType, calculationId: string) => {
        if (!projectId) {
            setError(new Error("Skakt kunne ikke redigeres, prøv igen."));
            return;
        }
        try {
            setLoadingShafts(true);
            const shaftId = shaft.id;

            const docRef = doc(firestore, "projects", projectId, "shafts", shaftId);
            updateDoc(docRef, cleanShaft(shaft));

            if (calculationId === undefined || calculationId === "") {
                await createShaftCalculation(shaftId);
            } else {
                await updateShaftCalculation(shaftId, calculationId);
            }
        } catch (error) {
            setError(new Error("Skakt kunne ikke opdateres, prøv igen."));
            logger.error("Failed to update shaft", error);
        } finally {
            setLoadingShafts(false);
        }
    };

    const handleDeleteShaft = async (shaftId: string) => {
        if (!projectId) {
            setError(new Error("Skakt kunne ikke slettes, prøv igen."));
            return;
        }
        try {
            setLoadingShafts(true);
            const docRef = doc(firestore, "projects", projectId, "shafts", shaftId);
            deleteDoc(docRef);
        } catch (error) {
            setError(new Error("Skakt kunne ikke slettes, prøv igen."));
            logger.error("Failed to delete shaft", error);
        } finally {
            setLoadingShafts(false);
        }
    };

    const createShaftCalculation = async (shaftId: string) => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/shafts/${shaftId}/calculations`;
        try {
            setLoadingShafts(true);
            const accessToken = await getAccessToken();
            if (accessToken === undefined) {
                throw new Error("No access token");
            }
            await axios.post(
                requestUrl,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
        } catch (e) {
            // console.log(e);
        } finally {
            setLoadingShafts(false);
        }
    };

    const updateShaftCalculation = async (shaftId: string, calculationId: string) => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/shafts/${shaftId}/calculations/${calculationId}`;
        try {
            setLoadingShafts(true);
            const accessToken = await getAccessToken();
            if (accessToken === undefined) {
                throw new Error("No access token");
            }
            await axios.put(
                requestUrl,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
        } catch (e) {
            // console.log(e);
        } finally {
            setLoadingShafts(false);
        }
    };

    const cleanShaft = (shaft: Partial<IShaftType>) => {
        delete shaft.id;
        return shaft;
    };

    return (
        <ShaftsContext.Provider
            value={{
                error,
                handleCreateShaft,
                handleUpdateShaft,
                handleDeleteShaft,
                createShaftCalculation,
                loadingShafts,
                shafts,
            }}
        >
            {children}
        </ShaftsContext.Provider>
    );
};
export default ShaftsProvider;
