import React, { useState, useMemo, MouseEventHandler } from "react";
import { Row, Col, Badge, Button, Container, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ISheet } from "types";
import { usePreviewSheetsNumber, SyntheticEvent, useFormFields, useProjectId, useAuth } from "hooks";
import { EMPTY_SHEET } from "_constants";
import ConfirmModal from "../Shared/ConfirmModal";
import { logEvent } from "config";
import SheetModalFormFields from "./SheetModalFormFields";
import { SheetService } from "services";
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

interface Props {
    sheetId: string;
    handleClose: () => void;
}

const SheetModalForm: React.FC<Props> = ({ sheetId, handleClose }) => {
    const { t } = useTranslation();
    const projectId = useProjectId();
    const { getAccessToken } = useAuth();
    const queryClient = useQueryClient();
    const queries = useQueries({
        queries: [
            {
                queryKey: ["sheets", sheetId],
                queryFn: async () => {
                    const accessToken = await getAccessToken();
                    if (!accessToken) throw new Error("No access token");
                    return SheetService.getSheet(projectId, sheetId, accessToken);
                },
                onSuccess: (data: ISheet) => {
                    if (!data) throw new Error("No data");
                    setFields(data);
                },
                onError: () => {
                    toast.error(t("status.error"));
                    handleClose();
                },
            },
            {
                queryKey: ["sheets"],
                queryFn: async () => {
                    const accessToken = await getAccessToken();
                    if (!accessToken) throw new Error("No access token");
                    return SheetService.getSheets(projectId, accessToken);
                },
                onError: () => {
                    toast.error(t("status.error"));
                    handleClose();
                },
            },
        ],
    });

    const submitUpdate = useMutation({
        mutationFn: async (data: ISheet) => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            return SheetService.updateSheet(projectId, sheetId, data, accessToken);
        },
        onSuccess: () => {
            logEvent("sheet_save");
            queryClient.invalidateQueries(["sheets"]);
            handleClose();
        },
    });
    const sheetQuery = queries[0];
    const sheetsQuery = queries[1];
    const sheet = sheetQuery.isFetched && sheetQuery.data ? sheetQuery.data : (EMPTY_SHEET as ISheet);
    const sheets = sheetsQuery.isFetched && sheetsQuery.data ? sheetsQuery.data : [];
    const { mutateAsync: submitDelete } = useMutation({
        mutationFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            return SheetService.deleteSheet(projectId, sheetId, accessToken);
        },
        onSuccess: () => {
            logEvent("sheet_delete");
            handleClose();
        },
    });
    const {
        fields,
        handleFieldChange,
        handleKeyValueChange,
        handleScaleKeyValueChange,
        handleFormatKeyValueChange,
        customHandleFieldChange,
        setFields,
    } = useFormFields<ISheet>(sheet);
    const [isInvalid, setIsInvalid] = useState<string[]>([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const { previewSheetNumber } = usePreviewSheetsNumber();

    const newSheets = () => sheets.filter(s => s.id !== sheet.id);
    const sheetNumber = useMemo(() => previewSheetNumber(fields), [fields, previewSheetNumber]);
    const sheetNumberIsUnique = useMemo(
        () => newSheets().filter(s => previewSheetNumber(s) === sheetNumber).length === 0,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fields, newSheets]
    );

    const handleFieldChangeHook = <Event extends SyntheticEvent>(event: Event) => {
        const targetField = event.target.id;
        if (targetField === "title" && isInvalid.includes("TITLE")) {
            setIsInvalid((fieldIds: string[]) => fieldIds.filter(fieldId => fieldId !== "TITLE"));
        }
        if (targetField === "serialNumber" && isInvalid.includes("SERIAL_NUMBER")) {
            setIsInvalid((fieldIds: string[]) => fieldIds.filter(fieldId => fieldId !== "SERIAL_NUMBER"));
        }
        handleFieldChange(event);
    };

    const validateFields = (): boolean => {
        const invalid = [];
        const nameIsValid = () => fields.title !== "";
        const serialNumbersValid = () => fields.serialNumber !== "";
        if (!nameIsValid()) {
            invalid.push("TITLE");
        }
        if (!serialNumbersValid()) {
            invalid.push("SERIAL_NUMBER");
        }
        if (invalid.length > 0) {
            setIsInvalid(invalid);
            return false;
        }
        return true;
    };

    const handleSubmit: MouseEventHandler<HTMLElement> = event => {
        event.preventDefault();
        const valid = validateFields();
        if (valid) {
            customHandleFieldChange("documentId", previewSheetNumber(fields));
            submitUpdate.mutate({ ...fields });
        }
    };

    return (
        <>
            <Modal size="xl" show={true} onHide={handleClose} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{sheetQuery.isLoading ? t("status.loading") : sheet.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {sheet.handledByRevit ? (
                        <RevitSheetModal />
                    ) : (
                        <>
                            <Row className="mx-0">
                                <Col xs="1">{t("status.editing")}:</Col>
                                <Col>
                                    <Badge className="me-1 text-monospace">{previewSheetNumber(sheet)}</Badge>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <SheetModalFormFields
                                    fields={fields}
                                    handleKeyValueChange={handleKeyValueChange}
                                    handleScaleKeyValueChange={handleScaleKeyValueChange}
                                    handleFormatKeyValueChange={handleFormatKeyValueChange}
                                    handleFieldChange={handleFieldChangeHook}
                                    isInvalid={isInvalid}
                                    sheetNumberIsUnique={sheetNumberIsUnique}
                                    previewSheetNumber={sheetNumber}
                                />
                            </Row>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-info"
                        onClick={() => submitUpdate.mutate({ ...fields, handledByRevit: !sheet.handledByRevit })}
                    >
                        {t(
                            sheet?.handledByRevit
                                ? "pages.FAST.sheet-list.edit-sheet.buttons.unignore-in-sheetlist"
                                : "pages.FAST.sheet-list.edit-sheet.buttons.ignore-in-sheetlist"
                        )}
                    </Button>
                    <Button variant="secondary" disabled={submitUpdate.isLoading} onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                    <Button
                        disabled={isInvalid.length > 0 || submitUpdate.isLoading}
                        variant="primary"
                        onClick={handleSubmit}
                    >
                        {submitUpdate.isLoading ? t("status.loading") : t("buttons.save")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <ConfirmModal
                show={showConfirmModal}
                handleAction={() => submitDelete()}
                handleClose={() => setShowConfirmModal(false)}
                description={t("pages.FAST.sheet-list.edit-sheet.buttons.delete-confirm")}
                confirmText={t("buttons.confirm")}
            />
        </>
    );
};

export default SheetModalForm;

const RevitSheetModal = () => (
    <Container style={{ minHeight: "535.83px" }}>
        <h4>This sheet can only be edited in Revit</h4>
    </Container>
);
