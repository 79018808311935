import React, { ReactNode, useContext, useState } from "react";
import { Button, ContainerProps, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "context";
import { User, Project } from "types";
import { sortBy } from "lodash";
import { logEvent } from "config";
import { ToolTipButton } from "../ToolTip/ToolTip";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "hooks";
import { UserService } from "services";

interface Props extends ContainerProps {
    handleClose: () => void;
    handleSave: (userId: string, projectId: string) => void;
    project?: Project;
    children?: ReactNode;
}

const AddUserToProjectModalForm: React.FC<Props> = ({ handleClose, handleSave, project }) => {
    const { company } = useContext(CompanyContext);
    const { t } = useTranslation();
    const [selectedUser, setSelectedUser] = useState<User>();

    const { getAccessToken } = useAuth();
    const { data: users, isLoading: usersIsLoading } = useQuery({
        queryKey: ["companyUsers"],
        queryFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            if (!company) throw new Error("No company");
            return UserService.getCompanyUsers(company.id, accessToken);
        },
        enabled: !!company,
    });

    return (
        <Modal show={true} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("actions.add")}
                    <ToolTipButton
                        eventId={"hover_users_edit"}
                        className="ms-2"
                        size="sm"
                        tooltipMessage={t("pages.shared.users.add-help")}
                        tooltipId="project.addUserModal"
                        tooltipPlacement="right"
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {" "}
                {}
                <Form.Select
                    onChange={el => {
                        const userId = el.target.value;
                        setSelectedUser(users?.find(user => user.id === userId));
                    }}
                    value={selectedUser?.id}
                >
                    {sortBy(users, "name").map(user => (
                        <option
                            key={user.id}
                            selected={user.id === selectedUser?.id}
                            value={user.id}
                        >{`${user.name} (${user.email})`}</option>
                    ))}
                </Form.Select>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("buttons.cancel")}
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        if (project && selectedUser) {
                            handleSave(selectedUser.id, project.id);
                            logEvent("project_user_add");
                        }
                    }}
                >
                    {t("buttons.add")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddUserToProjectModalForm;
