import React, { FC, HTMLAttributes } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { FormEvent, useEnergyRapport } from "hooks";
import { EnergyRapportFields } from "types";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<HTMLBaseElement> {
    fieldId: keyof EnergyRapportFields;
    options: { key: string | boolean | number; value: string }[];
    label?: string;
    handleChange?: (e: unknown) => void;
}

export const ToogleOption: FC<Props> = ({ fieldId, options, label, handleChange }) => {
    const { fields, handleFieldChange } = useEnergyRapport();
    const { t } = useTranslation();
    const handleSelectOption = (value: typeof options[number]["key"]) => {
        if (handleChange !== undefined) {
            handleChange(value);
            return;
        }
        const syntheticEvent = {
            target: {
                value,
                id: fieldId,
            },
        };
        handleFieldChange(syntheticEvent as FormEvent);
    };

    return (
        <Form.Group as={Col} controlId="roof">
            {label && <Form.Label style={{ fontWeight: 500 }}>{t(label)}</Form.Label>}
            <Row>
                {options.map((option, i) => (
                    <div className="cl-xs-2 col-xl-auto" key={i}>
                        <div className="d-grid gap-2">
                            <Button
                                className={`mb-3 font-weight-normal border ${
                                    fields[fieldId] === option.key ? "border-primary" : "border-gray text-muted"
                                }`}
                                style={{ minHeight: "55px", minWidth: "150px" }}
                                variant={fields[fieldId] === option.key ? "light" : "outline-secondary"}
                                key={option.key as string}
                                onClick={() => handleSelectOption(option.key)}
                                size="sm"
                            >
                                {option.value}
                            </Button>
                        </div>
                    </div>
                ))}
            </Row>
        </Form.Group>
    );
};

export const RadioOption: FC<Props> = ({ fieldId, options, label, handleChange }) => {
    const { fields, handleFieldChange } = useEnergyRapport();

    const handleSelectOption = (value: typeof options[number]["key"]) => {
        if (handleChange !== undefined) {
            handleChange(value);
            return;
        }
        const syntheticEvent = {
            target: {
                value,
                id: fieldId,
            },
        };
        handleFieldChange(syntheticEvent as FormEvent);
    };

    return (
        <Form.Group as={Col} controlId="roof">
            {label && <Form.Label style={{ fontWeight: 500 }}>{label}</Form.Label>}
            <div className="mb-2 text-muted">
                {options.map((option, i) => (
                    <Form.Check
                        key={i}
                        inline
                        id={option.key as string}
                        type="radio"
                        onClick={() => handleSelectOption(option.key)}
                        label={option.value}
                        className="pe-4"
                        checked={fields[fieldId] === option.key}
                    />
                ))}
            </div>
        </Form.Group>
    );
};
