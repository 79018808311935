import React, { MouseEventHandler, FC } from 'react';

type ListItemWithActionProps = {
    handleOnClick: MouseEventHandler
    interactive?: boolean;
    children?: React.ReactNode;
}

export const ListItemWithActionWrapper: FC<ListItemWithActionProps> = ({ handleOnClick, interactive, children }) => (
    <div 
        onClick={handleOnClick} 
        className={`list-item-wrap ${interactive ? 'interactive' : ''}`}>
        {children}
    </div>
)

export const ListItemDissmissContainer : FC<ListItemWithActionProps> = ({ handleOnClick, children }) => (
    <div 
        className="list-item-dismiss" 
        onClick={handleOnClick}>
        {children}
    </div>
)
export const ListItemActionContainer : FC<ListItemWithActionProps> = ({ handleOnClick, children }) => (
    <div 
        className="list-item-action" 
        onClick={handleOnClick}>
        {children}
    </div>
)
