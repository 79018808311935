import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { LoggingContext } from "context";
import { IDocument, IDocumentTemplate } from "types";
import { useContextModel } from "hooks";
import { addDoc, onSnapshot, collection, doc, deleteDoc, updateDoc, writeBatch } from "@firebase/firestore";
import { firestore } from "config";

interface IDocumentContext {
    documents: IDocument[];
    error: Error | null;
    generateDocumentData: (data: IDocumentTemplate | IDocument) => Partial<IDocument>;
    handleDeleteDocument: (documentId: string) => void;
    handleDeleteDocuments: (documentIds: string[]) => void;
    handleGenerateDocuments: (selectedDocumentKeys: IDocumentTemplate[]) => Promise<IDocument[]>;
    handleSaveDocumentField: (
        documentId: string,
        data: IDocumentTemplate | Partial<IDocumentTemplate>
    ) => void;
    loadingDocuments: boolean;
    getReportId: (doc: IDocument) => string | undefined;
}

export const DocumentsContext = createContext({} as IDocumentContext);

interface Props {
    projectId?: string;
    children?: ReactNode;
}

const DocumentsProvider = ({ projectId, children }: Props): JSX.Element => {
    const [loadingDocuments, setLoadingDocuments] = useState<boolean>(true);
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const { getKnowledgeById, getDisciplineById, getDocumentKindCategoryById, getDocumentKindById } = useContextModel();
    const [documentWithReports, setDocumentWithReports] = useState<string[][]>([])

    const { logger } = useContext(LoggingContext);

    useEffect(() => {
        if (!projectId) {
            return;
        }
        const documentsRef = collection(firestore, "projects", projectId, "documents");

        setLoadingDocuments(true);
        onSnapshot(documentsRef, documentSnapshots => {
            const documents: IDocument[] = [];
            documentSnapshots.forEach(documentSnapshot => {
                const document = documentSnapshot.data() as IDocument;
                document.id = documentSnapshot.id;
                documents.push(document);
            });
            setDocuments(documents);
            setLoadingDocuments(false);
        },
            error => {
                setLoadingDocuments(false);
                setError(new Error("Dokumenterne kunne ikke hentes. Prøv venligst igen."));
                logger.error("Failed to fetch all documents", error);
            }
        );
    }, [projectId, logger]);

    useEffect(() => {
        if (!projectId) {
            return;
        }
        const reportsRef = collection(firestore, "projects", projectId, "reports");
        onSnapshot(reportsRef, reportsSnapshots => {
            const reports = reportsSnapshots.docs.map(reportSnapshot => [reportSnapshot.data()?.documentId || 'UNKNOWN_DOC_ID', reportSnapshot.id]);
            setDocumentWithReports(reports)
        })

    }, [projectId])

    const generateDocumentData = (data: IDocumentTemplate | IDocument): Partial<IDocument> => {
        const knowledge = getKnowledgeById(data.knowledgeId);
        const discipline = getDisciplineById(data.disciplineId);
        const documentKind = getDocumentKindById(data.documentKindId);
        const documentKindCategory = getDocumentKindCategoryById(documentKind.documentKindCategoryId);

        const title = (data as IDocumentTemplate).name || (data as IDocument).title || "";

        const doc: Partial<IDocument> = {
            title,
            disciplineId: discipline?.id,
            disciplineCode: discipline?.code,
            disciplineValue: discipline?.value,
            knowledgeId: knowledge?.id,
            knowledgeCode: knowledge?.code,
            knowledgeValue: knowledge?.value,
            documentKindId: documentKind?.id,
            documentKindValue: documentKind?.value,
            documentKindCode: documentKind?.subCode,
            documentKindCategoryId: documentKindCategory?.id,
            documentKindCategoryValue: documentKindCategory?.value,
            documentKindCategoryCode: documentKindCategory?.code,
        }
        return doc;
    }

    const handleSaveDocumentField = async (documentId: string, data: IDocument | Partial<IDocument>) => {
        const updatedDoc = generateDocumentData(data as IDocument);

        const documentRef = doc(firestore, `projects/${projectId}/documents/${documentId}`);
        try {
            updateDoc(documentRef, updatedDoc as { [x: string]: string });
        } catch (error) {
            setError(new Error("Dokumentet kunne ikke opdateres. Prøv venligst igen."));
            logger.error("Failed to update document fields", error);
        }
    };

    const handleDeleteDocument = async (documentId: string) => {
        if (!projectId) {
            logger.error("Failed to delete document, projectId is falsy", error);
            setError(new Error("Dokument kunne ikke slettes. Prøv venligst igen."));
            return;
        }
        const documentRef = doc(firestore, "projects", projectId, "documents", documentId);
        try {
            deleteDoc(documentRef);
        } catch (error) {
            setError(new Error("Dokumentet kunne ikke fjernes. Prøv venligst igen."));
            logger.error("Failed to delete document", error);
        }
    };

    const handleDeleteDocuments = async (documentIds: string[]) => {
        if (!projectId) {
            logger.error("Failed to delete all documents, projectId is falsy", error);
            setError(new Error("Dokumenterne kunne ikke slettes. Prøv venligst igen."));
            return;
        }
        try {
            const batch = writeBatch(firestore);
            documentIds.forEach(documentId => {
                const docRef = doc(firestore, `projects/${projectId}/documents/${documentId}`);
                batch.delete(docRef);
            });
            await batch.commit();
            return;
        } catch (error) {
            setError(new Error("Dokumenterne kunne ikke fjernes. Prøv venligst igen."));
            logger.error("Failed to delete documents", error);
        }
    };

    const handleGenerateDocuments = async (
        selectedDocumentKeys: IDocumentTemplate[]
    ) => {

        const documents = selectedDocumentKeys.map(documentTemplate => generateDocumentData(documentTemplate));
        try {
            const createdDocs = await addDocumentsToFirestore(documents as IDocument[]);
            return createdDocs;
        } catch (error) {
            setError(new Error("Dokumenterne kunne ikke oprettes. Prøv venligst igen."));
            logger.error("Failed to generate documents", error)
            return [];
        }
    };

    const addDocumentsToFirestore = async (documents: IDocument[]): Promise<IDocument[]> => {
        try {
            const createdDocs: IDocument[] = [];
            for (const document of documents) {
                const doc = { ...document, projectId: projectId }
                const documentsRef = collection(firestore, `projects/${projectId}/documents`)
                let ref = await addDoc(documentsRef, doc);
                createdDocs.push({ ...doc, id: ref.id } as IDocument);
            }
            return createdDocs;
        } catch (error) {
            setError(new Error("Dokumenterne kunne ikke oprettes. Prøv venligst igen."));
            logger.error("Failed to generate documents", error)
            return [];
        }
    };

    const getReportId = (doc: IDocument): string | undefined => {
        const idPair = documentWithReports.find(pair => pair[0] === doc.id);
        if (!idPair) return undefined;
        return idPair[1];
    }

    return (
        <DocumentsContext.Provider
            value={{
                documents,
                getReportId,
                error,
                generateDocumentData,
                handleDeleteDocument,
                handleDeleteDocuments,
                handleGenerateDocuments,
                handleSaveDocumentField,
                loadingDocuments,
            }}
        >
            {children}
        </DocumentsContext.Provider>
    );
};

export default DocumentsProvider;
