import React, { useState } from "react";
import { Button, Form, ListGroup, Row, Col } from "react-bootstrap";
import { GripVertical, Plus, Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { Storey } from "types";
import { LEVEL_STOREY } from "_constants";
import CreateStoreyModal from "./CreateStoreyModal";
import StoreyModal from "./StoreyModal";
import OverlayIconButton from "components/Shared/Buttons/OverlayIconButton";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth, useProjectId } from "hooks";
import { StoreyService } from "services/StoreyServices";

interface EditStoreyListProps {
    // storeys: Storey[];
    buildingId: string;
    storeys: Storey[];
    setStoreys?: (storeys: Storey[]) => void;
}

export const EditStoreyList: React.FC<EditStoreyListProps> = ({ buildingId, storeys, setStoreys }) => {
    const { t } = useTranslation();
    const [showCreateStoreyModal, setShowCreateStoreyModal] = useState<boolean>(false);
    const [showStoreyModal, setShowStoreyModal] = useState<boolean>(false);
    const [selectedStorey, setSelectedStorey] = useState<Storey>(LEVEL_STOREY);
    const { getAccessToken } = useAuth();
    const projectId = useProjectId();
    const queryClient = useQueryClient();

    const deleteStoreyMutation = useMutation({
        mutationFn: async (storeyId: string) => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            if (!projectId) throw new Error("No project");
            if (!buildingId) throw new Error("No building");
            if (!storeyId) throw new Error("No storey");

            return StoreyService.deleteStorey(projectId, buildingId, storeyId, accessToken);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["storeys", projectId, buildingId]);
            // storeysQuery.refetch();
        },
    });

    const handleSelectStorey = (storey: Storey) => {
        setSelectedStorey(storey);
        setShowStoreyModal(true);
    };

    const handleDismissStoreyModal = () => {
        setSelectedStorey(LEVEL_STOREY);
        setShowStoreyModal(false);
    };

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(storeys);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        if (setStoreys) {
            setStoreys(items);
        }
    };

    return (
        <>
            <ListGroup>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {storeys &&
                                    storeys.map((storey, index) => (
                                        <Draggable
                                            key={storey?.id}
                                            draggableId={storey?.id || storey.code}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    className="card my-2 bg-gray-100"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    key={storey.id}
                                                    onClick={() => handleSelectStorey(storey)}
                                                >
                                                    <div className="card-body py-2 m-0 d-flex justify-content-between">
                                                        <div className="inline-flex">
                                                            <GripVertical style={{ color: "gray" }} className="me-2" />
                                                            {storey.code}
                                                        </div>
                                                        <div>{storey.name}</div>
                                                        <OverlayIconButton
                                                            overlayText={t("pages.shared.edit-storey.remove-storey")}
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                deleteStoreyMutation.mutate(storey.id!);
                                                            }}
                                                        >
                                                            <Trash />
                                                        </OverlayIconButton>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder} {/* This is keeps spacing when dragging element */}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </ListGroup>
            <Form.Text className="text-muted">{t("pages.shared.locations.fields.storeys-help")}</Form.Text>
            <Row className="mt-2">
                <Col>
                    <Button
                        className="mt-1"
                        variant="outline-primary"
                        onClick={e => {
                            e.preventDefault();
                            setShowCreateStoreyModal(true);
                        }}
                    >
                        <Plus />
                        {t("pages.shared.add-storey.button-add")}
                    </Button>
                </Col>
            </Row>
            {showStoreyModal && (
                <StoreyModal handleClose={handleDismissStoreyModal} storey={selectedStorey} buildingId={buildingId} />
            )}
            {showCreateStoreyModal && (
                <CreateStoreyModal handleClose={() => setShowCreateStoreyModal(false)} buildingId={buildingId} />
            )}
        </>
    );
};
