import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormFields } from "hooks";
import { IModelOutputFormat } from "types";
import ConfirmModal from "../../Shared/ConfirmModal";

interface Props {
    format: IModelOutputFormat;
    formatModel: IModelOutputFormat[];
    handleClose: () => void;
    handleCreate: (data: IModelOutputFormat) => Promise<void>;
    handleUpdate: (data: IModelOutputFormat) => Promise<void>;
    handleDelete: (id: string) => Promise<void>;
}

const FormatModalForm: React.FC<Props> = ({
    format,
    formatModel,
    handleClose,
    handleCreate,
    handleUpdate,
    handleDelete,
}) => {
    const { t } = useTranslation();
    const { fields, handleFieldChange, setFields } = useFormFields(format);
    const newOutputFormat = format.id === "";
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [isInvalid, setIsInvalid] = useState<string[]>([]);

    const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const height = event.target.value as unknown as number;
        const value = fields.size;
        value[0] = height;
        const outputFormat = { ...fields, size: value } as IModelOutputFormat;
        setFields(outputFormat);
    };

    const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const width = event.target.value as unknown as number;
        const value = fields.size;
        value[1] = width;
        const outputFormat = { ...fields, size: value } as IModelOutputFormat;
        setFields(outputFormat);
    };

    const FormatCodeExistsInModel = (): boolean => {
        const code = fields.code.toUpperCase();
        if (newOutputFormat) {
            const existingValuePairs = formatModel.filter(of => of.code === code);
            return existingValuePairs.length > 0;
        } else {
            const existingValuePairs = formatModel.filter(of => of.code === code && of.id !== fields.id);
            return existingValuePairs.length > 0;
        }
    };

    const handleSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        const valid = validateFields();
        if (valid) {
            if (newOutputFormat) {
                handleCreate(fields);
            } else {
                handleUpdate(fields);
            }
            handleClose();
        }
    };

    const handleTogglePreferred = () => {
        setFields(prevFields => ({ ...prevFields, preferred: !prevFields.preferred }));
    };

    const validateFields = (): boolean => {
        const invalid = [];
        const codeIsValid = () => fields.code !== "";
        const sizeZeroIsValid = () => fields.size[0] > 0;
        const sizeOneIsValid = () => fields.size[1] > 0;
        if (!codeIsValid()) {
            invalid.push("CODE");
        }
        if (!sizeZeroIsValid()) {
            invalid.push("SIZE_ONE");
        }
        if (!sizeOneIsValid()) {
            invalid.push("SIZE_ZERO");
        }
        if (invalid.length > 0) {
            setIsInvalid(invalid);
            return false;
        }
        return true;
    };

    return (
        <>
            <Modal show={true} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    {t(newOutputFormat ? "actions.add" : "actions.edit")} {newOutputFormat ? "" : `${format.code}`}
                </Modal.Header>
                <Modal.Body>
                    <Form className="d-grid gap-2">
                        <Form.Group controlId="code">
                            <Form.Label>
                                {t("pages.FAST.value-lists.format.fields.code")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                isInvalid={isInvalid.includes("CODE") || FormatCodeExistsInModel()}
                                type="text"
                                value={fields.code}
                                onChange={event => {
                                    if (isInvalid.includes("CODE")) {
                                        setIsInvalid((fieldIds: string[]) =>
                                            fieldIds.filter(fieldId => fieldId !== "CODE")
                                        );
                                    }
                                    handleFieldChange(event as React.ChangeEvent<HTMLInputElement>);
                                }}
                            />
                            {FormatCodeExistsInModel() && (
                                <Form.Control.Feedback type="invalid">
                                    {t("pages.FAST.value-lists.value-pairs.code-exists")}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        {t("pages.FAST.value-lists.format.fields.height")}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        isInvalid={isInvalid.includes("SIZE_ZERO")}
                                        placeholder="0"
                                        type="number"
                                        value={fields.size[0]}
                                        onKeyDown={event => {
                                            if (event.key === "e" || event.key === "E") {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={event => {
                                            if (isInvalid.includes("SIZE_ZERO")) {
                                                setIsInvalid((fieldIds: string[]) =>
                                                    fieldIds.filter(fieldId => fieldId !== "SIZE_ZERO")
                                                );
                                            }
                                            handleHeightChange(event as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        {t("pages.FAST.value-lists.format.fields.width")}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        isInvalid={isInvalid.includes("SIZE_ONE")}
                                        type="number"
                                        placeholder="0"
                                        value={fields.size[1]}
                                        onKeyDown={event => {
                                            if (event.key === "e" || event.key === "E") {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={event => {
                                            if (isInvalid.includes("SIZE_ONE")) {
                                                setIsInvalid((fieldIds: string[]) =>
                                                    fieldIds.filter(fieldId => fieldId !== "SIZE_ONE")
                                                );
                                            }
                                            handleWidthChange(event as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group controlId="preferred">
                            <Form.Check
                                type="checkbox"
                                label={t("pages.FAST.value-lists.format.fields.preferred")}
                                checked={fields.preferred}
                                onChange={handleTogglePreferred}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                    {!newOutputFormat && (
                        <Button
                            variant="danger"
                            onClick={() => {
                                setShowConfirmModal(true);
                            }}
                        >
                            {t("buttons.delete")}
                        </Button>
                    )}
                    <Button
                        disabled={isInvalid.length > 0 || FormatCodeExistsInModel()}
                        variant="primary"
                        onClick={handleSubmit}
                    >
                        {t(newOutputFormat ? "buttons.add" : "buttons.save")}
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                <ConfirmModal
                    show={showConfirmModal}
                    handleAction={() => {
                        handleDelete(fields.id);
                        handleClose();
                    }}
                    handleClose={() => {
                        setShowConfirmModal(false);
                    }}
                    description={t("pages.FAST.value-lists.delete-confirm")}
                    confirmText={t("buttons.delete")}
                />
            }
        </>
    );
};

export default FormatModalForm;
