import { useContext, useEffect, useState } from "react";
import { CompanyContext, ProjectContext } from "context";
import { ThemeValueList, ThemeValuePair } from "types";
import axios from "axios";
import { API_URL } from "config";
import { firestore } from "config";
import { collection, onSnapshot } from "firebase/firestore";
import { useAuth, useProject, useProjectId } from "hooks";

type ThemeValueListsProps = {
    themeValueLists: ThemeValueList[];
    createThemeValueList: (themeValueList: Partial<ThemeValueList>) => Promise<[] | undefined>;
    editThemeValueList: (themeValueList: ThemeValueList) => void;
    deleteThemeValueList: (themeValueListId: string) => void;
    addThemeValuePair: (themeValueListId: string, themeValuePair: Partial<ThemeValuePair>) => void;
    editThemeValuePair: (themeValueListId: string, themeValuePair: Partial<ThemeValuePair>) => void;
    deleteThemeValuePair: (themeValuePairId: string) => void;
    loading: boolean;
    error: undefined | Error;
    projectValuePairs: ThemeValuePair[];
};

export const useThemeValueLists = (): ThemeValueListsProps => {
    const { company } = useContext(CompanyContext);
    const projectId = useProjectId();
    const { project } = useProject(projectId);
    const [themeValueLists, setThemeValueLists] = useState<ThemeValueList[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error>();
    const { getAccessToken } = useAuth();

    const valueList = themeValueLists.find(l => l.id === project?.themeValueListId);
    const valuePairs =
        valueList?.valuePairs.map(valuePair => ({
            ...valuePair,
            code: `${valueList?.code || "T"}${valuePair.code}`,
        })) || [];

    useEffect(() => {
        if (!company) return;
        const valueListsRef = collection(firestore, "companies", `${company.id}`, "theme-value-lists");
        const unsubscribe = onSnapshot(valueListsRef, _ => getValueThemeLists(company.id));
        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    useEffect(() => {
        if (!company) return;
        const valueListsRef = collection(firestore, "companies", `${company.id}`, "theme-value-pairs");
        const unsubscribe = onSnapshot(valueListsRef, _ => getValueThemeLists(company.id));
        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    const getValueThemeLists = async (companyId: string): Promise<[] | undefined> => {
        if (companyId === null) return [];
        try {
            const accessToken = await getAccessToken();
            if (accessToken === undefined) {
                throw new Error("Failed to get access token");
            }
            const requestUrl = `${API_URL}/v1/companies/${companyId}/theme-value-lists`;

            const response = await axios.get(requestUrl, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const { valueLists } = response.data;
            setThemeValueLists(valueLists);
            return response.data;
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    const deleteThemeValueList = async (themeValueListId: string): Promise<void> => {
        if (!company?.id) return;
        try {
            const accessToken = await getAccessToken();
            if (accessToken === undefined) {
                throw new Error("Failed to get access token");
            }
            const requestUrl = `${API_URL}/v1/companies/${company.id}/theme-value-lists/${themeValueListId}`;
            const response = await axios.delete(requestUrl, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return response.data;
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    const createThemeValueList = async (themeValueList: Partial<ThemeValueList>): Promise<[] | undefined> => {
        if (!company?.id) return [];
        try {
            const accessToken = await getAccessToken();
            if (accessToken === undefined) {
                throw new Error("Failed to get access token");
            }
            const requestUrl = `${API_URL}/v1/companies/${company.id}/theme-value-lists`;

            const response = await axios.post(requestUrl, themeValueList, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return response.data;
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    const editThemeValueList = async (themeValueList: Partial<ThemeValueList>): Promise<[] | undefined> => {
        if (!company?.id) return [];
        try {
            const accessToken = await getAccessToken();
            if (accessToken === undefined) {
                throw new Error("Failed to get access token");
            }
            const requestUrl = `${API_URL}/v1/companies/${company.id}/theme-value-lists/${themeValueList.id}`;

            const response = await axios.put(requestUrl, themeValueList, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return response.data;
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    const addThemeValuePair = async (
        themeValueListId: string,
        themeValuePair: Partial<ThemeValuePair>
    ): Promise<[] | undefined> => {
        if (!company?.id) return [];
        try {
            const accessToken = await getAccessToken();
            if (accessToken === undefined) {
                throw new Error("Failed to get access token");
            }
            const requestUrl = `${API_URL}/v1/companies/${company.id}/theme-value-lists/${themeValueListId}/theme-value-pairs`;

            const response = await axios.post(requestUrl, themeValuePair, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return response.data;
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    const editThemeValuePair = async (
        themeValueListId: string,
        themeValuePair: Partial<ThemeValuePair>
    ): Promise<[] | undefined> => {
        if (!company?.id) return [];
        try {
            const accessToken = await getAccessToken();
            if (accessToken === undefined) {
                throw new Error("Failed to get access token");
            }
            const requestUrl = `${API_URL}/v1/companies/${company.id}/theme-value-lists/${themeValueListId}/theme-value-pairs/${themeValuePair.id}`;

            const response = await axios.put(requestUrl, themeValuePair, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return response.data;
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    const deleteThemeValuePair = async (themeValuePairId: string): Promise<void> => {
        if (!company) return;
        try {
            const accessToken = await getAccessToken();
            if (accessToken === undefined) {
                throw new Error("Failed to get access token");
            }
            const requestUrl = `${API_URL}/v1/companies/${company.id}/theme-value-pairs/${themeValuePairId}`;

            const response = await axios.delete(requestUrl, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return response.data;
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    return {
        themeValueLists,
        createThemeValueList,
        editThemeValueList,
        deleteThemeValueList,
        addThemeValuePair,
        editThemeValuePair,
        deleteThemeValuePair,
        loading,
        error,
        projectValuePairs: valuePairs,
    };
};
