import React from "react";
import { Form, Col, Row, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SystemType, DecentralWaterSystem, WaterSystem, WaterComponent } from "types";
import { waterComponentEffectViewModel } from "../../../../utils/system.lookup";

type Props<TSystemType> = {
    fields: TSystemType;
    handleFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setFields: React.Dispatch<React.SetStateAction<TSystemType>>;
    inputIsinvalid: boolean;
};

const WaterForm: React.FC<Props<WaterSystem>> = ({ fields, handleFieldChange, setFields, inputIsinvalid }) => {
    const { t } = useTranslation();

    const handleChangeSystemType = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const systemType = event.target.value as SystemType;
        let baseSystem = { ...fields, systemType };
        if (systemType === SystemType.DECENTRAL) {
            baseSystem = {
                ...baseSystem,
                componentEffect: 0,
                componentHeight: 0,
                componentWidth: 0,
            };
        }
        setFields(baseSystem as WaterSystem);
    };

    return (
        <>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>
                            {t("pages.FLAT.systems.card-modal.type")}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                            isInvalid={!fields.systemType && inputIsinvalid}
                            id="systemType"
                            value={fields.systemType}
                            onChange={e => {
                                handleChangeSystemType(e as React.ChangeEvent<HTMLSelectElement>);
                            }}
                        >
                            <option value="" key="">
                                {t("pages.FLAT.systems.card-modal.type-select")}
                            </option>
                            {Object.keys(SystemType).map(type => (
                                <option key={type} value={type}>
                                    {`${t(`pages.FLAT.systems.card-modal.${type}`)}`}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            {fields.systemType === SystemType.DECENTRAL && (
                <DecentralWaterForm
                    fields={fields as DecentralWaterSystem}
                    handleFieldChange={handleFieldChange}
                    setFields={setFields as React.Dispatch<React.SetStateAction<DecentralWaterSystem>>}
                    inputIsinvalid={inputIsinvalid}
                />
            )}
        </>
    );
};

export default WaterForm;

const DecentralWaterForm: React.FC<{
    fields: DecentralWaterSystem;
    handleFieldChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    setFields: React.Dispatch<React.SetStateAction<DecentralWaterSystem>>;
    inputIsinvalid: boolean;
}> = ({ fields, handleFieldChange, setFields, inputIsinvalid }) => {
    const { t } = useTranslation();

    const handleComponentFieldChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const waterComponent = event.target.value as WaterComponent;
        const effect = waterComponentEffectViewModel(waterComponent);
        setFields(prevFields => ({ ...prevFields, component: waterComponent, componentEffect: effect }));
    };

    return (
        <>
            <hr />
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>
                            {t("pages.FLAT.systems.card-modal.water.component")}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                            isInvalid={!fields.component && inputIsinvalid}
                            id="component"
                            value={fields.component}
                            onChange={e => handleComponentFieldChange(e)}
                        >
                            <option value="" key="">
                                {t("pages.FLAT.systems.card-modal.water.component-select")}
                            </option>
                            {Object.keys(WaterComponent).map(type => (
                                <option key={type} value={type}>
                                    {`${t(`pages.FLAT.systems.card-modal.water.${type}`)}`}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label>
                        {t("pages.FLAT.systems.card-modal.water.effect")}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            isInvalid={fields.componentEffect < 0 && inputIsinvalid}
                            as="input"
                            id="componentEffect"
                            type="number"
                            value={fields.componentEffect}
                            onChange={e => {
                                handleFieldChange(e as React.ChangeEvent<HTMLInputElement>);
                            }}
                        />
                        <InputGroup.Text>
                            W/m<sup>2</sup>
                        </InputGroup.Text>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>
                            {t("pages.FLAT.systems.card-modal.water.component-height")}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                isInvalid={fields.componentHeight < 0 && inputIsinvalid}
                                as="input"
                                id="componentHeight"
                                type="number"
                                value={fields.componentHeight}
                                onChange={e => {
                                    handleFieldChange(e as React.ChangeEvent<HTMLInputElement>);
                                }}
                            />
                            <InputGroup.Text>mm</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>
                            {t("pages.FLAT.systems.card-modal.water.component-width")}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                isInvalid={fields.componentWidth < 0 && inputIsinvalid}
                                as="input"
                                id="componentWidth"
                                type="number"
                                value={fields.componentWidth}
                                onChange={e => {
                                    handleFieldChange(e as React.ChangeEvent<HTMLInputElement>);
                                }}
                            />
                            <InputGroup.Text>mm</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};
