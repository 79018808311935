import React from 'react';

interface Props {
    providers: Array<React.JSXElementConstructor<React.PropsWithChildren<unknown>>>
    children: React.ReactNode;
    [key: string]: unknown;
}

const ProviderComposer = (props: Props) => {
    const { providers = [], children, ...rest } = props

    return (
        <>
            {providers.reduceRight((acc, Comp) => <Comp {...rest}>{acc}</Comp>, children)}
        </>
    )
}

export default ProviderComposer;