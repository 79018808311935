import { useContext, useState, useEffect } from "react";
import { Project, RevitParameter, User } from "types";
import { collection, query, where, onSnapshot, addDoc, doc, setDoc, deleteDoc } from "firebase/firestore";
import { ProjectContext } from "context";
import { firestore } from "config";
import { DEFAULT_SERIALNUMBERCONFIG } from "_constants";

export const useProject = (
    projectId: string
): {
    project: Project | undefined;
    users: User[];
    revitParameters: RevitParameter[];
    loading: boolean;
    createRevitParameter: (data: Partial<RevitParameter>) => Promise<void>;
    updateRevitParameter: (data: Partial<RevitParameter>) => Promise<void>;
    deleteRevitParameter: (id: string) => Promise<void>;
    sheetCount: number;
} => {
    const [project, setProject] = useState<Project | undefined>(undefined);
    const [users, setUsers] = useState<User[]>([]);
    const [revitParameters, setRevitParameters] = useState<RevitParameter[]>([]);
    const { projects, setError, setLoading, loading } = useContext(ProjectContext);
    const [sheetCount, setSheetCount] = useState(0);

    useEffect(() => {
        if (!projectId || projects.length === 0) return;
        const project = projects.find(p => p.id === projectId);
        if (!project) return;

        if (project.serialNumberConfig === undefined) project.serialNumberConfig = DEFAULT_SERIALNUMBERCONFIG;
        setProject(project);
    }, [projectId, projects]);

    useEffect(() => {
        if (!projectId) return;
        const usersRef = collection(firestore, "users");
        const projectUsersQuery = query(usersRef, where("projects", "array-contains", projectId));
        const unsubscribe = onSnapshot(projectUsersQuery, documentsSnapshot => {
            const projectUsers: User[] = [];
            documentsSnapshot.forEach(documentSnapshot => {
                const user = documentSnapshot.data() as User;
                user.id = documentSnapshot.id;
                projectUsers.push(user);
            });
            setUsers(projectUsers);
        });
        return () => unsubscribe();
    }, [projectId]);

    useEffect(() => {
        if (!projectId) return;
        const parameterRef = collection(firestore, "projects", projectId, "revit-parameters");
        const unSubscribe = onSnapshot(parameterRef, parametersSnapshot => {
            const revitParameters: RevitParameter[] = [];
            parametersSnapshot.forEach(parameterSnapShot => {
                const revitParameter = parameterSnapShot.data() as RevitParameter;
                revitParameter.id = parameterSnapShot.id;
                revitParameters.push(revitParameter);
            });
            setRevitParameters(revitParameters);
        });
        return () => unSubscribe();
    }, [projectId]);

    useEffect(() => {
        if (!projectId) return;
        const sheetRef = collection(firestore, "projects", projectId, "sheets");
        const unSubscribe = onSnapshot(sheetRef, sheetSnapshot => {
            setSheetCount(sheetSnapshot.size);
        });
        return () => unSubscribe();
    }, [projectId]);

    const createRevitParameter = async (data: Partial<RevitParameter>) => {
        if (!project || !project.id) {
            setError(new Error("Projekt ikke fundet, prøv igen."));
            return;
        }

        if (data.parameterValue === undefined) {
            setError(new Error("Parameter ikke fundet, prøv igen."));
            return;
        }

        try {
            const revitParameterRef = collection(firestore, "projects", project.id, "revit-parameters");
            const { id, ...cleanedData } = data;
            await addDoc(revitParameterRef, cleanedData);
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    const updateRevitParameter = async (data: Partial<RevitParameter>) => {
        setLoading(true);
        if (!project || !project.id) {
            setError(new Error("Projekt ikke fundet, prøv igen"));
            return;
        }

        if (data.parameterValue === undefined) {
            setError(new Error("Parameter ikke fundet, prøv igen."));
            return;
        }

        try {
            if (data?.id === undefined) {
                throw new Error("Parameter ikke fundet, prøv igen.");
            }
            const revitParameterRef = doc(firestore, "projects", project.id, "revit-parameters", data.id);
            const { id, ...cleanedData } = data;
            await setDoc(revitParameterRef, cleanedData, { merge: true });
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    const deleteRevitParameter = async (id: string) => {
        setLoading(true);
        if (!project || !project.id) {
            setError(new Error("Projekt ikke fundet, prøv igen"));
            return;
        }
        try {
            const revitParameterRef = doc(firestore, "projects", project.id, "revit-parameters", id);
            await deleteDoc(revitParameterRef);
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        project,
        users,
        revitParameters,
        createRevitParameter,
        updateRevitParameter,
        deleteRevitParameter,
        sheetCount,
    };
};
