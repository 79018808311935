import { kebabCase } from "lodash";
import { EnergyRapportFields } from "types";
import { SectionData } from "../components/EnergyEfficiency/Sections/Sections";

export const SHADING_OPTIONS = [
    {
        value: "Ingen aktiv solafskærmning",
        key: "none",
        description: "Der er ingen aktiv solafskærmning i projektet",
        fc: 0,
    },
    {
        value: "Indvendig manuelt gardiner om sommeren",
        key: "indvendig-sommer",
        description:
      "Der anvendes indvendig manuelt aktiverede gardiner om sommeren, som har en af-skærmningsfaktor på -0,8 jf. SBi-Anvisning 213",
        fc: -0.8,
    },
    {
        value: "Indvendig manuelt aktiverede gardiner",
        key: "indvendig",
        description:
      "Der anvendes indvendig manuelt aktiverede gardiner, som har en afskærmningsfaktor på 0,8 jf. SBi-Anvisning 213",
        fc: 0.8,
    },
    {
        value: "Solafskærmning",
        key: "standard",
        description:
      "Der er regnet med nedenstående solafskærmning for samtlige vinduer i projektet",
        fc: 0.7,
    },
    {
        value: "Opsat af bygherre/slutbruger",
        key: "bygherre",
        description:
      "Solafskærmning er taget i regning forudsat opsat af bygherre/slutbruger.",
        fc: 0,
    },
];

export const SHADOW_OPTIONS = [
    {
        value: "Ikke inkluderet i beregning",
        key: false,
    },
    {
        value: "Inkluderet i beregning",
        key: true,
    }
]

export const SHADING_TYPE_OPTIONS = [
    {
        value: "Udvendige Persienner, Lys",
        shadingValue: 0.22,
        key: "udvendige-persienner-lys",
    },
    {
        value: "Udvendige Persienner, Mørk",
        shadingValue: 0.12,
        key: "udvendige-persienner-mork",
    },
    {
        value: "Udvendigt netrullegardin ",
        shadingValue: 0.2,
        key: "udvendigt-netrullegardin",
    },
    {
        value: "Integreret Gardin, Lys, Delvis gennemskinnelig",
        shadingValue: 0.3,
        key: "integreret-gardin-lys-delvis-gennemskinnelig",
    },
    {
        value: "Integreret gardin, mørk, uigennemskinnelig",
        shadingValue: 0.45,
        key: "integreret-gardin-mork-uigennemskinnelig",
    },
    {
        value: "Integreret Persienne, Lys",
        shadingValue: 0.35,
        key: "integreret-persienne-lys",
    },
    {
        value: "Indvendigt Gardin, Lys",
        shadingValue: 0.5,
        key: "indvendigt-gardin-lys",
    },
    {
        value: "Indvendigt Gardin, mørk",
        shadingValue: 0.8,
        key: "indvendigt-gardin-mork",
    },
    {
        value: "Indvendige Persienner, Lys",
        shadingValue: 0.55,
        key: "indvendige-persienner-lys",
    },
    {
        value: "Indvendige Persienner, Mørk",
        shadingValue: 0.8,
        key: "indvendige-persienner-mork",
    },
    {
        value: "Indvendig solafskærmning, manuelt",
        shadingValue: -0.8,
        key: "indvendig-solafskaermning-manuelt",
    },
    {
        value: "Andet",
        shadingValue: 0,
        key: "other",
    },
];

export const VENTILATION_TYPES = [
    {
        value: "VAV",
        key: "vav",
        description: "",
    },
    {
        value: "CAV",
        key: "cav",
        description: "",
    },
    {
        value: "Hybrid",
        key: "hybrid",
        description: "",
    },
];

export const VENTILATION_LOCATIONS = [
    {
        value: "Hver lejlighed",
        key: "apartment",
        description: "I opvarmet rum i hver lejlighed",
    },
    {
        value: "Teknikrum",
        key: "technical-equipment-room",
        description: "i opvarmet teknikrum",
    },
    {
        value: "Teknikrum og i hver lejlighed",
        key: "apartment-technical-equipment-room",
        description: "i opvarmet teknikrum og i opvarmet rum i hver lejlighed",
    },
];

export const BUILDING_REGULATIONS = [
    {
        value: "BR 2018",
        key: "br18",
    },
    {
        value: "BR 2015",
        key: "br15",
    },
];

export const BUILDING_TYPES = [
    {
        value: "Fritliggende bolig",
        key: "FTYPE",
    },
    {
        value: "Sammenbyggede boliger",
        key: "STYPE",
    },
    {
        value: "Etagebolig",
        key: "ETYPE",
    },
    {
        value: "Lager",
        key: "LTYPE",
    },
    {
        value: "Andre bygninger",
        key: "ATYPE",
    },
]

export const ENERGY_FRAME_TARGETS = [
    {
        value: "Lavenergi",
        key: "Lavenergi",
    },
    {
        value: "BR 2018",
        key: "BR 2018",
    },
    {
        value: "Renoveringsklasse 1",
        key: "Renoveringsklasse 1",
    },
    {
        value: "Renoveringsklasse 2",
        key: "Renoveringsklasse 2",
    },
];
export const PROJECT_TYPE_OPTIONS = [
    {
        value: "Bolig",
        key: "ATYPE",
        description:
      "Fritliggende boliger, Sammenbyggede boliger eller Etageboliger",
    },
    {
        value: "Erhverv",
        key: "OTHER",
        description: "Andet",
    },
];

export const BOOLEAN = [
    {
        value: "Ja",
        key: true,
    },
    {
        value: "Nej",
        key: false,
    },
];

export const CENTRAL_DECENTRAL = [
    {
        value: "Fælles",
        key: 'central',
    },
    {
        value: "Decentral",
        key: 'decentral',
    },
];

export const ENERGY_FRAME_ADDITIONS = [
    {
        value: "Almen belysning",
        key: "energyFrameAdditionsLighting",
    },
    {
        value: "Ventilation",
        key: "energyFrameAdditionsVentilation",
    },
    {
        value: "Varmt brugsvand",
        key: "energyFrameAdditionsWater",
    },
    {
        value: "Brugstid",
        key: "energyFrameAdditionsHours",
    },
    {
        value: "Lofthøjde",
        key: "energyFrameAdditionsCeiling",
    },
];

export const INDOOR_REQUIREMENTS_OPTIONS = [
    {
        value: "Sommerkomfort",
        key: "sommerkomfort",
        description:
      "Bygningsreglementets krav kan eftervises ved den forenklede metode ”sommerkomfort” ",
    },
    {
        value: "BSIM",
        key: "bsim",
        description:
      "Bygningsreglementets krav kan eftervises via dynamisk beregning",
    },
    {
        value: "Ikke eftervist",
        key: "",
        description: "Bygningsreglementets krav eftervises ikke",
    },
];

export const HEATING_SUPPLY_OPTIONS = [
    {
        value: "Fjernvarme",
        key: "SUP_DISTRICT",
        description: "",
    },
    {
        value: "Kedel",
        key: "SUP_BOILER",
        description: "",
    },
    {
        value: "Blokvarme",
        key: "SUP_BLOCK",
        description: "",
    },
    {
        value: "El",
        key: "SUP_EL",
        description: "",
    },
];

export const BUILDING_MATERIAL_OPTIONS = [
    {
        value: "Gipsplader",
        key: "gips",
        description: "(ét lag)",
        heatCapacity: 3,
        type: "roof",
    },
    {
        value: "Nedhængt loft",
        key: "nedhængt-loft",
        description: "",
        heatCapacity: 3,
        type: "roof",
    },
    {
        value: "Klinkebeton",
        key: "klinke-beton",
        description: "(1.800 kg/m³)",
        heatCapacity: 45,
        type: "roof",
    },
    {
        value: "Beton",
        key: "beton",
        description: "(2.400 kg/m³)",
        heatCapacity: 60,
        type: "roof",
    },{
        value: "Trægulv på isolering",
        key: "trægulv-isolering",
        description: "",
        heatCapacity: 4,
        type: "floor",
    },
    {
        value: "Trægulv på strøer på beton",
        key: "mix-beton",
        description: "eller hævet gulv over beton",
        heatCapacity: 10,
        type: "floor",
    },
    {
        value: "Trægulv med dug på beton",
        key: "trægulv-dug-beton",
        description: "",
        heatCapacity: 17,
        type: 'floor',
    },
    {
        value: "Tæppe på beton",
        key: "tæppe-beton",
        description: "",
        heatCapacity: 30,
        type: 'floor',
    },
    {
        value: "Klinkebeton",
        key: "klinke-beton-klinker",
        description: "evt. med klinker (1.800 kg/m³)",
        heatCapacity: 45,
        type: 'floor',
    },
    {
        value: "Linoleum på beton",
        key: "linoleum-beton",
        description: "",
        heatCapacity: 53,
        type: 'floor',
    },
    {
        value: "Beton",
        key: "beton",
        description: "evt. med klinker (2.400 kg/m³)",
        heatCapacity: 60,
        type: 'floor',
    },{
        value: "Gipsplader",
        key: "gips",
        description: "(to lag)",
        type: 'wall',
        heatCapacity: 3,
    },
    {
        value: "Porebeton",
        key: "porebeton",
        description: "(535 kg/m³)",
        heatCapacity: 7,
        type: 'wall',
    },
    {
        value: "Tegl",
        key: "tegl",
        description: "(1.500 kg/m³)",
        heatCapacity: 18,
        type: 'wall',
    },
    {
        value: "Klinkebeton",
        key: "klinkebeton",
        description: "(1.800 kg/m³)",
        heatCapacity: 24,
        type: 'wall',
    },
    {
        value: "Beton",
        key: "beton",
        description: "(2.400 kg/m³)",
        heatCapacity: 32,
        type: 'wall',
    },
];

export const BUILDING_COMPACTNESS_OPTIONS = [
    {
        value: "Lille",
        key: 0,
        factor: 0.58,
    },
    {
        value: "Mellem",
        key: 1,
        factor: 1,
    },
    {
        value: "Stor",
        key: 2,
        factor: 1.42,
    },
];

export const BUILDING_ROOM_SIZES_OPTIONS = [
    {
        value: "Små",
        key: 0,
        factor: 0.59,
    },
    {
        value: "Mellem",
        key: 1,
        factor: 1,
    },
    {
        value: "Store",
        key: 2,
        factor: 2.01,
    },
];

export const ISO_OPTIONS = [
    {
        value: "Ingen",
        key: false,
        description: "",
    },
    {
        value: "Kileståret tagisolering",
        key: true,
        description: "",
    },
];

export const WINDOW_TYPES = [
    {
        value: "Energirude 2 lags",
        u: 1.2,
        g: 0.6,
    },
    {
        value: "Energirude 3 lags",
        u: 0.9,
        g: 0.55,
    },
    {
        value: "Solafskærmende glas",
        u: 0.9,
        g: 0.35,
    },
];

export const WINDOW_TYPE_OPTIONS = WINDOW_TYPES.map(w => ({
    value: w.value,
    key: kebabCase(w.value),
    description: `U: ${w.u}, g: ${w.g}`,
}));

export const ORIENTATION = [
    {
        key: 'Nord',
        value: 'Nord',
    },
    {
        key: 'NordØst',
        value: 'Nordøst',
    },
    {
        key: 'Øst',
        value: 'Øst',
    },
    {
        key: 'SydØst',
        value: 'Sydøst',
    },
    {
        key: 'Syd',
        value: 'Syd',
    },
    {
        key: 'SydVest',
        value: 'Sydvest',
    },
    {
        key: 'Vest',
        value: 'Vest',
    },
    {
        key: 'NordVest',
        value: 'Nordvest',
    },
]

export const SECTIONS : SectionData[] = [
    {
        id: 'FILES',
        titleTranslationId: 'pages.BE18.sections.files.title',
        subTitleTranslationId: 'pages.BE18.sections.files.subtitle',
    },
    {
        id: 'META_DATA',
        titleTranslationId: 'pages.BE18.sections.metadata.title',
        subTitleTranslationId: 'pages.BE18.sections.metadata.subtitle',
    },
    {
        id: 'HEATING_CAPACITY',
        titleTranslationId: 'pages.BE18.sections.heating-capacity.title',
        subTitleTranslationId: 'pages.BE18.sections.heating-capacity.subtitle',
    },
    {
        id: "ADDITIONAL",
        titleTranslationId: 'pages.BE18.sections.additional.title',
        subTitleTranslationId: 'pages.BE18.sections.additional.subtitle',
    },
]

export const INITIAL_STATE: EnergyRapportFields = {
    Bygherre: "",
    CreationDate: "",
    RevisionDate: "",
    ProjectId: "",
    InitialOwner: "",
    InitialControl: "",
    InitialApproved: "",
    ProjectName: "",
    NumberOfFloors: 0,
    FloorArea: 0,
    BruttoArea: 0,
    AddedArea: 0,
    hasAddedArea: false,
    BuildingPrimaryFunction: "",
    NumberOfBuildings: 0,
    BuildingRegulations: "",
    BuildingType: "",
    AdditionsEnergyFrame: "",
    EnergyFrameTarget: "",
    EnergyFrame: "",
    EnergyFrameAdd: 0,
    TotalEnergyFrame: 0,
    TotalEnergyNeed: 0,
    TransmissionlossRequirement: 0,
    TransmissionsLoss: 0,
    Architect: "",
    ArchitectDate: "",
    Version: "",
    HeatCapacity: 0,
    BuildingCompactness: 0,
    BuildingRoomSizes: 0,
    HeatingCapacityRoof: "",
    HeatingCapacityRoofValue: 0,
    HeatingCapacityFloor: "",
    HeatingCapacityFloorValue: 0,
    HeatingCapacityPartitians: "",
    HeatingCapacityPartitiansValue: 0,
    HeatingCapacityExterior: "",
    HeatingCapacityExteriorValue: 0,
    HeatCapacityTotal: 0,
    TimeUsedWeekly: 0,
    AdditionTimeUsedWeekly: "",
    HeatSupply: "",
    hasWedgeCutInsulation: false,
    MinimumRoofIsolationThickness: "",
    MaxRoofIsolationThickness: "",
    GlassAreaRatio: 0,
    WindowType: "",
    WindowOrientation: "",
    WindowGlassRationWeightedAverage: 0,
    Shadow: false,
    ShadeText: "",
    IndoorClimateMethod: "",
    IndoorClimateMethodText: "",
    IndoorClimateText: "",
    IndoorClimateHeader: "",
    IndoorClimateSimulationName: "",
    VentilationType: "",
    VentilationNumberOfUnits: 0,
    VentilationUnitPlacement: "",
    VentilationDuringSommer: 0,
    q50: "",
    BuildingEnvelopeRegulation: "",
    PeopleLoadUserTime: 0,
    ApplianceUserTime: 0,
    HeatSupplySupplyTemp: 0,
    HeatSupplyReturnTemp: 0,
    HeatingPipeLength: 0,
    HeatingPipeHeatloss: 0,
    HeatingPipeOutdoorCompensation: false,
    HotWaterTankType: "",
    useFlowWaterHeater: false,
    HotWaterTankSize: 0,
    HotWaterTankHeatloss: 0,
    HotWaterTankPipeHeatloss: 0,
    HotWaterTankPipeLength: 0,
    CirculatorPumpAmount: 0,
    CirculatorPumpEffect: 0,
    CirculatorPumpReductionFactor: 0,
    CirculatorPipeLength: 0,
    CirculatorPipeHeatloss: 0,
    HasSolarPanel: false,
    SolarPanelArea: 0,
    SolarPanelPeakPower: 0,
    SolarPanelEfficiency: 0,
    SolarPanelOrientation: "",
    SolarPanelSlope: 0,
    Rotation: 0,
    Shade: "",
    ShadeName: "",
    ShadeValue: 0,
    EnergyFrameLowEnergy: 0,
    EnergyFrameBR18: 0,
    EnergyFrameRenovation: 0,
    EnergyFrameRenovation2: 0,
    WedgeCutRoofInsulationUValue: 0,
    isCommercial: false,
    isResidential: false,
    NumberOfUnits: 0,
    hasMultipleUsageLoadZones: false,
    isHeatingPipeOutdoorCompensation: false,
    hasIndoorAnalysis: false,
    showIndoorAnalysisTable: false,
    HeatedRoomArea: 0,
    HeatedRoomVolumen: 0,
    HeatedRoomVinter: 0,
    HeatedRoomSummerDay: 0,
    HeatedRoomSummerNigth: 0,
    hasEnergyFrameAdditions: false,
};