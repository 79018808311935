import React from "react";
import { Route, Switch } from "react-router-dom";
import LoginContainer from "../components/Login/LoginContainer";
import InvitedUserContainer from "../components/User/Create/InvitedUserContainer";
import FooterContainer from "../components/Footer/FooterContainer";
import ChangeLogContainer from "../components/ChangeLog/ChangeLogContainer";
import StandaloneForgotPasswordContainer from "components/Login/StandaloneForgotPasswordContainer";

const UnProtectedRoutes: React.FC = () => (
    <>
        <Switch>
            <Route path="/create-user">
                <InvitedUserContainer />
            </Route>
            <Route path="/change-log">
                <ChangeLogContainer />
            </Route>
            <Route path="/reset-password">
                <StandaloneForgotPasswordContainer />
            </Route>
            <Route path="/">
                <LoginContainer />
            </Route>
        </Switch>
        <div style={{ position: "fixed", bottom: 0, width: "100vw" }}>
            <FooterContainer />
        </div>
    </>
);

export default UnProtectedRoutes;
