import { useState, FC, HTMLAttributes, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import { useAuth, useEnergyRapport, useFormFields, useLogger, useProjectId } from "hooks";
import { Report } from "types";
import { SECTIONS } from "_constants";
import { SectionHandler } from "./Sections/Sections";
import SectionProgressBar from "./Sections/SectionProgressBar";
import { IconButton } from "../Shared/Layout";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { DivWithStatusAlertHandling } from "hoc/withStatusAlerts";

type Props = HTMLAttributes<HTMLBaseElement>;

const ReportConfigurator: FC<Props> = () => {
    const [step, setStep] = useState<number>(0);
    const lastStep = SECTIONS.length - 1;
    const { t } = useTranslation();
    const { user } = useAuth();
    const projectId = useProjectId();
    const logger = useLogger();

    const { handleInitiateNewReport, createReport, setCompleted } = useEnergyRapport();
    const { fields: reportMetadata } = useFormFields<Omit<Report, "fields" | "tables">>({
        title: "",
        id: "",
        creator: "",
        dateCreated: "",
        dateModified: "",
    });

    const createReportMutation = useMutation({
        mutationKey: [projectId, "reports", { method: "POST" }],
        mutationFn: createReport,
        onSuccess: () => setCompleted(true),
        onError: error => {
            const err = error as Error;
            logger.error(err.message || `Error: createReportMutation, user: ${user?.uid || "unknown user"}`, error);
        },
    });

    useEffect(() => {
        handleInitiateNewReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DivWithStatusAlertHandling
            loading={createReportMutation.isLoading}
            loadingMessage={t("status.creating-type", { type: "energy report" }) as string}
            error={createReportMutation.failureReason as Error}
            className="d-flex flex-column h-100"
        >
            <div>
                <h1>{t("pages.BE18.configurator.title")} </h1>
            </div>
            <SectionProgressBar currentStep={step} setStep={(index: number) => setStep(index)} sections={SECTIONS} />
            <div className="d-flex flex-grow-1">
                <Row className="w-100">
                    <Col xs={12} lg={3} className="p-4 border-right">
                        <h5>{t(SECTIONS[step].titleTranslationId)}</h5>
                        <p className="text-muted">{t(SECTIONS[step].subTitleTranslationId)}</p>
                    </Col>
                    <Col xs={12} lg={9} className="p-4 h-100">
                        <SectionHandler page={SECTIONS[step].id} />
                    </Col>
                </Row>
            </div>
            <div className="d-flex justify-content-end">
                <IconButton
                    icon={ChevronLeft}
                    iconPosition="left"
                    variant="secondary"
                    onClick={() => setStep(step - 1)}
                    className="me-1"
                >
                    {t("buttons.back")}
                </IconButton>
                {lastStep !== step && (
                    <IconButton
                        icon={ChevronRight}
                        iconPosition="right"
                        variant="primary"
                        onClick={() => setStep(step + 1)}
                    >
                        {t("buttons.next")}
                    </IconButton>
                )}
                {lastStep === step && (
                    <Button variant="outline-primary" onClick={() => createReportMutation.mutate(reportMetadata)}>
                        {t("pages.BE18.configurator.button-create-document")}
                    </Button>
                )}
            </div>
        </DivWithStatusAlertHandling>
    );
};

export default ReportConfigurator;
