import React, { useContext, useState } from "react";
import { Modal, Button, Form, Row, Col, Accordion } from "react-bootstrap";
import { ChevronRight, ChevronDown } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { StoreysContext } from "context";
import { FormEvent, useAuth, useFormFields, useProjectId } from "hooks";
import { Storey } from "types";
import { ToolTipFormControl } from "../ToolTip/ToolTip";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { StoreyService } from "services/StoreyServices";

type Props = {
    storey: Storey;
    buildingId: string;
    handleClose: () => void;
};

const StoreyModal = ({ storey, buildingId, handleClose }: Props): JSX.Element => {
    const { t } = useTranslation();
    // const { handleSaveStorey } = useContext(StoreysContext);
    const queryClient = useQueryClient();
    const { getAccessToken } = useAuth();
    const projectId = useProjectId();
    const { fields, handleFieldChange, setFields } = useFormFields<Storey>(storey);

    const updateStoreyMutation = useMutation({
        mutationFn: async (storey: Storey) => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            if (!projectId) throw new Error("No project");
            if (!buildingId) throw new Error("No building");
            if (!storey.id) throw new Error("No storey");

            return StoreyService.putStorey(projectId, buildingId, storey, accessToken);
        },
        onSuccess: () => {
            queryClient.refetchQueries(["storeys", projectId, buildingId]);
        },
    });

    return (
        <Modal show={true} onHide={handleClose} backdrop="static" scrollable centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("actions.edit")} {storey.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalFields
                    fields={fields}
                    handleFieldChange={handleFieldChange}
                    handleCheckField={fieldId => {
                        setFields(val => ({ ...val, [fieldId]: !val[fieldId] }));
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleClose()}>
                    {t("buttons.cancel")}
                </Button>
                <Button
                    onClick={() => {
                        updateStoreyMutation.mutate(fields);
                        handleClose();
                    }}
                >
                    {t("buttons.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default StoreyModal;

type ModalFieldsProps = {
    fields: Storey;
    handleFieldChange: (e: FormEvent) => void;
    handleCheckField: (fieldId: keyof Storey) => void;
};

const ModalFields = ({ fields, handleFieldChange, handleCheckField }: ModalFieldsProps): JSX.Element => {
    const { t } = useTranslation();
    const [toggleAccordian, setToggleAccordian] = useState(false);
    return (
        <Form className="d-grid gap-2">
            <Form.Group as={Row} controlId="name">
                <Form.Label column sm="3" className="pt-0">
                    {t("pages.shared.edit-storey.fields.name")}
                </Form.Label>
                <Col sm="9">
                    <ToolTipFormControl
                        value={fields.name}
                        handleFieldChange={handleFieldChange}
                        placeholder="F.eks. 1. etage"
                        tooltipId="storeys.name"
                        tooltipMessage={t("pages.shared.edit-storey.fields.name-help")}
                    />
                </Col>
            </Form.Group>
            {/* Nummer */}
            <Form.Group as={Row} controlId="code">
                <Form.Label column sm="3" className="pt-0">
                    {t("pages.shared.edit-storey.fields.code")}
                </Form.Label>
                <Col sm="9">
                    <ToolTipFormControl
                        value={fields.code}
                        handleFieldChange={handleFieldChange}
                        placeholder="EX"
                        tooltipId="storeys.code"
                        tooltipMessage={t("pages.shared.edit-storey.fields.code-help")}
                    />
                </Col>
            </Form.Group>

            <Accordion>
                <Accordion.Item
                    onClick={() => setToggleAccordian(!toggleAccordian)}
                    className="border border-0 pointer"
                    eventKey="0"
                >
                    <Accordion.Header className="disabled-accordian-header">
                        <span>{t("pages.shared.edit-storey.fields.advanced")}</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <Form.Check
                                className="my-2"
                                type="checkbox"
                                label={t("pages.shared.edit-storey.fields.create-plan-sheets")}
                                onChange={() => handleCheckField("isPlan")}
                                checked={fields.isPlan}
                            />
                            <Form.Group as={Row} className="mt-4">
                                <Form.Label column sm="4" className="pt-0">
                                    {t("pages.shared.edit-storey.fields.special")}
                                </Form.Label>
                                <Col sm="4">
                                    <Form.Check
                                        inline
                                        type="checkbox"
                                        label={t("pages.shared.locations.variables.storey-types.roof")}
                                        onChange={() => handleCheckField("isRoof")}
                                        checked={fields.isRoof}
                                    />
                                    <Form.Check
                                        inline
                                        type="checkbox"
                                        label={t("pages.shared.locations.variables.storey-types.foundation")}
                                        onChange={() => handleCheckField("isFoundation")}
                                        checked={fields.isFoundation}
                                    />
                                    <Form.Check
                                        inline
                                        type="checkbox"
                                        label={t("pages.shared.locations.variables.storey-types.basement")}
                                        onChange={() => handleCheckField("isBasement")}
                                        checked={fields.isBasement}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Form.Check
                                        inline
                                        type="checkbox"
                                        label={t("pages.shared.locations.variables.storey-types.pile")}
                                        onChange={() => handleCheckField("isPile")}
                                        checked={fields.isPile}
                                    />
                                    <Form.Check
                                        inline
                                        type="checkbox"
                                        label={t("pages.shared.locations.variables.storey-types.terrain")}
                                        onChange={() => handleCheckField("isSite")}
                                        checked={fields.isSite}
                                    />
                                    <Form.Check
                                        inline
                                        type="checkbox"
                                        label={t("pages.shared.locations.variables.storey-types.ground")}
                                        onChange={() => handleCheckField("isGround")}
                                        checked={fields.isGround}
                                    />
                                </Col>
                            </Form.Group>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Form>
    );
};
