import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { FormEvent } from "hooks";
import { ThemeValueList } from "types";
import { useTranslation } from "react-i18next";

interface Props {
    fields: Partial<ThemeValueList>;
    handleChangeField: (e: FormEvent) => void;
    setFields?: (fields: Partial<ThemeValueList>) => void;
}

const ThemeValueListModalFields: React.FC<Props> = ({ fields, handleChangeField, setFields }) => {
    const isEditState = fields.id !== undefined;
    const { t } = useTranslation();

    const handleChangeSort = (e: FormEvent) => {
        if (setFields) {
            setFields({ ...fields, lexicographicallySorted: !fields.lexicographicallySorted });
        }
    };

    return (
        <>
            {isEditState && (
                <h3>
                    <small>
                        <strong>{t("pages.FAST.value-lists.theme-value-pairs.listinformation")}</strong>
                    </small>
                </h3>
            )}
            <Form className="d-grid gap-2">
                <Row>
                    <Form.Group as={Col} controlId="code" className="flex-grow-0" md="12" lg="3">
                        <Form.Label>{t("pages.FAST.value-lists.theme-value-pairs.code-prefix")}</Form.Label>
                        <Form.Control
                            type="text"
                            value={fields.code}
                            onChange={handleChangeField}
                            isInvalid={!fields.code}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="name">
                        <Form.Label>{t("pages.FAST.value-lists.theme-value-pairs.name")}</Form.Label>
                        <Form.Control
                            type="text"
                            value={fields.name}
                            onChange={handleChangeField}
                            isInvalid={!fields.name}
                        />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="definition">
                        <Form.Label>{t("pages.FAST.value-lists.theme-value-pairs.description")}</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder={
                                t("pages.FAST.value-lists.theme-value-pairs.description-placeholder") as string
                            }
                            value={fields.definition}
                            onChange={handleChangeField}
                            style={{ resize: "none" }}
                        />
                    </Form.Group>
                </Row>
                <Form.Label>{t("pages.FAST.value-lists.theme-value-pairs.sort-title")}</Form.Label>
                <Row>
                    <div>
                        <Form.Check
                            type="radio"
                            label={t("pages.FAST.value-lists.theme-value-pairs.numerically-sorted") as string}
                            checked={!fields.lexicographicallySorted || false}
                            onChange={handleChangeSort}
                        />
                    </div>
                    <div>
                        <Form.Check
                            type="radio"
                            label={t("pages.FAST.value-lists.theme-value-pairs.lexicographically-sorted") as string}
                            checked={fields.lexicographicallySorted || false}
                            onChange={handleChangeSort}
                        />
                    </div>
                </Row>
            </Form>
        </>
    );
};

export default ThemeValueListModalFields;
