import React from 'react'
import { VENTILATION_TYPES, VENTILATION_LOCATIONS, CENTRAL_DECENTRAL, BUILDING_REGULATIONS, ENERGY_FRAME_TARGETS } from "_constants";
import BaseSection, { SectionField, SectionFieldWithOptions } from "./BaseSection";

const fields: (SectionField | SectionFieldWithOptions)[][] = [
    [
        {
            type: 'text',
            labelId: 'pages.BE18.sections.metadata.labels.created-by',
            id: 'InitialOwner'
        },
        {
            type: 'text',
            labelId: 'pages.BE18.sections.metadata.labels.checked-by',
            id: 'InitialControl'
        },
        {
            type: 'text',
            labelId: 'pages.BE18.sections.metadata.labels.approved-by',
            id: 'InitialApproved'
        },
    ],
    [
        {
            type: 'options',
            id: 'BuildingRegulations',
            labelId: 'pages.BE18.sections.metadata.labels.building-regulation',
            options: BUILDING_REGULATIONS,
        }
    ],
    [
        {
            type: 'options',
            id: 'EnergyFrameTarget',
            labelId: 'pages.BE18.sections.metadata.labels.energy-frame',
            options: ENERGY_FRAME_TARGETS,
        }
    ],
    [
        {
            type: 'text',
            labelId: 'pages.BE18.sections.metadata.labels.architect',
            id: 'Architect'
        },
        {
            type: 'text',
            labelId: 'pages.BE18.sections.metadata.labels.recieval-date',
            id: 'ArchitectDate'
        },
    ],
    [
        {
            type: 'options',
            id: 'VentilationType',
            labelId: 'pages.BE18.sections.metadata.labels.ventilation-type',
            options: VENTILATION_TYPES,
        }
    ],
    [
        {
            type: 'options',
            id: 'VentilationUnitPlacement',
            labelId: 'pages.BE18.sections.metadata.labels.ventilation-location',
            options: VENTILATION_LOCATIONS,
        }
    ],
    [
        {
            type: 'options',
            id: 'HotWaterTankType',
            labelId: 'pages.BE18.sections.metadata.labels.hotwater-tank',
            options: CENTRAL_DECENTRAL,
        }
    ],
]

export const MetaDataSection = () => <BaseSection data={fields} />