import { Storey, Building } from "types";
import { SPECIALSTOREYS, LEVEL_STOREY } from "_constants";
import { API_URL } from "config";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useLogger, useAuth } from "hooks";

export const usePreviewStoreys = (building: Partial<Building>, tool: "FAST" | "other" = "other"): Storey[] => {
    const logger = useLogger();
    const { getAccessToken } = useAuth();
    const { numberOfStoreys, numberOfBasementStoreys } = building;
    const url = `${API_URL}/v1/model/storeys?numberOfStoreys=${numberOfStoreys}&numberOfBasementStoreys=${numberOfBasementStoreys}`;
    const query = useQuery<Storey[]>({
        queryKey: ["storeys", numberOfStoreys, numberOfBasementStoreys],
        queryFn: async () => {
            try {
                const accessToken = await getAccessToken();
                const res = await axios.get(url, { headers: { Authorization: `token ${accessToken}` } });
                return res.data;
            } catch (err) {
                logger.error("Failed to fetch model of storyes", err);
            }
        },
    });

    const storeysFilter = (storey: Storey) => {
        if (tool === "FAST") return true;
        return !SPECIALSTOREYS.map(specialStorey => specialStorey.code).includes(storey.code);
    };

    const storeys = query.isFetched && query.data ? query.data.filter(storeysFilter) : [LEVEL_STOREY];

    return storeys;
};
