import React, { ReactNode, ReactPropTypes } from "react";
import {
    TooltipProps,
    ButtonProps,
    FormControlProps,
    FormCheckProps,
    OverlayTrigger,
    Tooltip,
    Button,
    InputGroup,
    FormCheck,
    Form,
} from "react-bootstrap";
import { InfoLg } from "react-bootstrap-icons";
import { FormEvent } from "hooks";
import { logEvent } from "config";

interface IToolTipWrapper extends TooltipProps {
    eventId: string;
    message: string;
    children?: ReactNode;
    props?: ReactPropTypes;
}

interface IToolTipButton extends ButtonProps {
    eventId: string;
    tooltipId: string;
    tooltipMessage: string;
    tooltipPlacement?: "top" | "bottom" | "left" | "right";
    children?: ReactNode;
    props?: ReactPropTypes;
}

interface IToolTipFormControl extends FormControlProps {
    tooltipId: string;
    tooltipMessage: string;
    tooltipPlacement?: "top" | "bottom" | "left" | "right";
    handleFieldChange: (e: FormEvent) => void;
    placeholder?: string | undefined;
    props?: ReactPropTypes;
}

interface IToolTipFormCheck extends FormCheckProps {
    tooltipId: string;
    tooltipMessage: string;
    tooltipPlacement?: "top" | "bottom" | "left" | "right";
    handleFieldChange: (e: FormEvent) => void;
    props?: ReactPropTypes;
}

export const ToolTipWrapper = ({
    eventId,
    placement = "bottom",
    message,
    id,
    children,
    ...props
}: IToolTipWrapper): JSX.Element => (
    <OverlayTrigger
        onToggle={() => logEvent(eventId)}
        placement={placement}
        delay={150}
        overlay={<Tooltip id={id}>{<div dangerouslySetInnerHTML={{ __html: message }} />}</Tooltip>}
    >
        <div className="d-inline-block align-top">{children ? children : <span {...props}>?</span>}</div>
    </OverlayTrigger>
);

export const ToolTipButton = ({
    eventId,
    tooltipPlacement,
    tooltipMessage,
    tooltipId,
    children,
    ...props
}: IToolTipButton): JSX.Element => (
    <ToolTipWrapper eventId={eventId} placement={tooltipPlacement} message={tooltipMessage} id={tooltipId}>
        <Button
            {...props}
            className={`${props?.className || ""} rounded-circle p-1`}
            variant="secondary"
            style={{ pointerEvents: "none" }}
            disabled
        >
            {children ? children : <InfoLg className="m-0 p-0 d-block" />}
        </Button>
    </ToolTipWrapper>
);

export const ToolTipFormControl = ({
    value,
    handleFieldChange,
    tooltipPlacement = "left",
    tooltipMessage,
    tooltipId,
    ...props
}: IToolTipFormControl): JSX.Element => (
    <InputGroup>
        <Form.Control
            defaultValue={value}
            onChange={handleFieldChange}
            {...props}
            className={`${props?.className || ""} border-right-0`}
        />
        <OverlayTrigger
            placement={tooltipPlacement}
            delay={50}
            overlay={<Tooltip id={tooltipId}>{tooltipMessage}</Tooltip>}
        >
            <InputGroup.Text
                className="border-left-0 bg-white"
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            >
                <InfoLg />
            </InputGroup.Text>
        </OverlayTrigger>
    </InputGroup>
);

export const ToolTipFormCheck = ({
    value,
    handleFieldChange,
    tooltipPlacement = "left",
    tooltipMessage,
    tooltipId,
    ...props
}: IToolTipFormCheck): JSX.Element => (
    <InputGroup>
        <FormCheck
            defaultValue={value}
            onChange={handleFieldChange}
            {...props}
            className={`${props?.className || ""} border-right-0`}
        />
    </InputGroup>
);
