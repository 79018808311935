import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Building, Storey } from "types";
import { FormEvent, SyntheticEvent } from "hooks";
import { DEFAULT_SPECIAL_STOREY_CODE } from "_constants";
import { PreviewStoreyList } from "../Storeys/PreviewStoreyList";
import { ToolTipFormControl } from "../ToolTip/ToolTip";
import { EditStoreyList } from "../Storeys/EditStoreyList";
import FastPreviewStoreyList from "../Storeys/FastPreviewStoreyList";

export interface BuildingFormProps {
    fields: Building;
    handleFieldChange: (event: FormEvent) => void;
    isInvalid: string[];
    setIsInvalid: (input: (s: string[]) => string[]) => void;
    storeyList?: Storey[];
    setStoreyList?: (storeys: Storey[]) => void;
    tool?: "FAST" | "other";
}

const BuildingForm: React.FC<BuildingFormProps> = ({
    fields,
    handleFieldChange,
    isInvalid,
    setIsInvalid,
    storeyList,
    setStoreyList,
    tool = "other",
}) => {
    const { t } = useTranslation();
    const storeysCount = [...Array(20).keys()];
    const { numberOfStoreys, numberOfBasementStoreys, code } = fields;
    const isCreatingBuildingState = fields.id === undefined;
    const specialStoreys = fields?.specialStoreys || DEFAULT_SPECIAL_STOREY_CODE;

    const handleToggleStoreyCode = (storeyCode: string) => {
        const curStoreys = specialStoreys;
        let storeys;
        if (curStoreys.includes(storeyCode)) {
            storeys = curStoreys.filter((code: string) => code !== storeyCode);
        } else {
            storeys = [...curStoreys, storeyCode];
        }
        handleFieldChange({ target: { id: "specialStoreys", value: storeys } } as SyntheticEvent);
        return storeys;
    };

    return (
        <Form className="d-grid gap-2">
            {/* name */}
            <Row>
                <Col>
                    <Form.Group controlId="name">
                        <Form.Label column sm="3" className="pt-0">
                            {t("pages.shared.locations.fields.name")}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <ToolTipFormControl
                            isInvalid={isInvalid.includes("NAME")}
                            value={fields.name}
                            handleFieldChange={event => {
                                if (isInvalid.includes("NAME")) {
                                    setIsInvalid((fieldIds: string[]) =>
                                        fieldIds.filter(fieldId => fieldId !== "NAME")
                                    );
                                }
                                handleFieldChange(event as FormEvent);
                            }}
                            tooltipId="buildings.buildingName"
                            tooltipMessage={t("pages.shared.locations.fields.name-help") as string}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    {/* Nummer */}
                    <Form.Group controlId="code">
                        <Form.Label column sm="3" className="pt-0">
                            {t("pages.shared.locations.fields.code")}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <ToolTipFormControl
                            isInvalid={isInvalid.includes("CODE")}
                            value={code}
                            handleFieldChange={event => {
                                if (isInvalid.includes("CODE")) {
                                    setIsInvalid((fieldIds: string[]) =>
                                        fieldIds.filter(fieldId => fieldId !== "CODE")
                                    );
                                }
                                handleFieldChange(event as FormEvent);
                            }}
                            tooltipId="buildings.buildingName"
                            tooltipMessage={t("pages.shared.locations.fields.code-help") as string}
                        />
                    </Form.Group>
                </Col>
            </Row>
            {tool === "FAST" && (
                <Row>
                    <Col>
                        {/* Scope box */}
                        <Form.Group controlId="scopeBox">
                            <Form.Label column sm="3" className="pt-0">
                                {t("pages.shared.locations.fields.scope-box")}
                            </Form.Label>
                            <ToolTipFormControl
                                value={fields.scopeBox}
                                handleFieldChange={handleFieldChange}
                                type="text"
                                tooltipId="buildings.buildingScopeBox"
                                tooltipMessage={t("pages.shared.locations.fields.scope-box-help") as string}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        {/* Beskrivelse */}
                        <Form.Group controlId="description">
                            <Form.Label column sm="3" className="pt-0">
                                {t("pages.shared.locations.fields.description")}
                            </Form.Label>
                            <ToolTipFormControl
                                value={fields.description}
                                handleFieldChange={handleFieldChange}
                                type="text"
                                tooltipId="buildings.buildingDescriptionLabel"
                                tooltipMessage={t("pages.shared.locations.fields.description-help") as string}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            )}
            {isCreatingBuildingState && (
                <Row>
                    <Col>
                        <Form.Group controlId="numberOfBasementStoreys">
                            <Form.Label>
                                {t("pages.shared.locations.fields.storeys")}
                                <span className="ps-1 text-muted">
                                    {t("pages.shared.locations.fields.storeys-below-basement")}
                                </span>
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                                isInvalid={isInvalid.includes("NUMFLOOR")}
                                defaultValue={fields.numberOfBasementStoreys}
                                onChange={event => {
                                    if (isInvalid.includes("NUMFLOOR")) {
                                        setIsInvalid((fieldIds: string[]) =>
                                            fieldIds.filter(fieldId => fieldId !== "NUMFLOOR")
                                        );
                                    }
                                    handleFieldChange(event);
                                }}
                            >
                                {storeysCount.map(index => (
                                    <option key={index} value={Number(index)}>
                                        {index}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="numberOfStoreys">
                            <Form.Label>
                                {t("pages.shared.locations.fields.storeys")}
                                <span className="ps-1 text-muted">
                                    {t("pages.shared.locations.fields.storeys-above-basement")}
                                </span>
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                                isInvalid={isInvalid.includes("NUMFLOOR")}
                                defaultValue={fields.numberOfStoreys}
                                onChange={event => {
                                    if (isInvalid.includes("NUMFLOOR")) {
                                        setIsInvalid((fieldIds: string[]) =>
                                            fieldIds.filter(fieldId => fieldId !== "NUMFLOOR")
                                        );
                                    }
                                    handleFieldChange(event);
                                }}
                            >
                                {storeysCount.map(index => (
                                    <option key={index} value={Number(index)}>
                                        {index}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
            )}
            {/* Storeys preview */}
            <Form.Group as={Row}>
                <Col>
                    <Form.Label column sm="3" className="pt-0">
                        {t(
                            isCreatingBuildingState
                                ? "pages.shared.locations.fields.storeys-preview"
                                : "pages.shared.locations.fields.storeys"
                        )}
                    </Form.Label>
                    {isCreatingBuildingState ? (
                        tool === "FAST" ? (
                            <FastPreviewStoreyList
                                numberOfStoreys={Number(numberOfStoreys)}
                                numberOfBasementStoreys={Number(numberOfBasementStoreys)}
                                specialStoreys={specialStoreys}
                                handleToggleStorey={handleToggleStoreyCode}
                            />
                        ) : (
                            <PreviewStoreyList
                                numberOfStoreys={Number(numberOfStoreys)}
                                numberOfBasementStoreys={Number(numberOfBasementStoreys)}
                                specialStoreys={specialStoreys}
                                handleToggleStorey={handleToggleStoreyCode}
                            />
                        )
                    ) : (
                        <EditStoreyList buildingId={fields.id!} storeys={storeyList!} setStoreys={setStoreyList} />
                    )}
                </Col>
            </Form.Group>
        </Form>
    );
};

export default BuildingForm;
