import { SystemFlow, HeatingSupply, ProjectSupply, System, IShaftType, Apartment, Room, FixtureType } from "types";

export const EMPTY_SHAFT: IShaftType = {
    id: "",
    name: "",
    buildingId: "",
    amount: 1,
    systemIds: [],
    storeyIds: [],
    shaftConnections: [],
};

export const EMPTY_APARTMENT: Apartment = {
    id: "",
    name: "",
    areaSize: 0,
    rooms: [],
};

export const EMPTY_SYSTEM: System = {
    id: "",
    name: "",
    systemDiscipline: undefined,
};

export const EMPTY_ROOM: Room = {
    id: "",
    name: "",
    airFlow: 180,
    fixtures: [],
};

export const KitchenTemplate: Room = {
    id: "",
    name: "Kitchen",
    airFlow: 80,
    fixtures: [
        { type: FixtureType.KITCHENSINK, priority: 1 },
        { type: FixtureType.DISHWASHER, priority: 2 },
    ],
};

export const ToiletTemplate: Room = {
    id: "",
    name: "Toilet",
    airFlow: 80,
    fixtures: [
        { type: FixtureType.SINK, priority: 1 },
        { type: FixtureType.WC, priority: 2 },
    ],
};

export const BathRoomTemplate: Room = {
    id: "",
    airFlow: 80,
    name: "Bathroom",
    fixtures: [
        { type: FixtureType.BATHTUB, priority: 1 },
        { type: FixtureType.SINK, priority: 2 },
        { type: FixtureType.WC, priority: 3 },
    ],
};

export const GuestToiletTemplate: Room = {
    id: "",
    airFlow: 80,
    name: "Guest toilet",
    fixtures: [
        { type: FixtureType.SINK, priority: 1 },
        { type: FixtureType.WC, priority: 2 },
    ],
};

export const LivingRoomTemplate: Room = {
    id: "",
    airFlow: 180,
    name: "Living room",
    fixtures: [],
};

export const RoomTemplates: Room[] = [
    KitchenTemplate,
    ToiletTemplate,
    BathRoomTemplate,
    GuestToiletTemplate,
    LivingRoomTemplate,
];

export const UNKNOWN_PROJECTSUPPLY: ProjectSupply = {
    heatingSupply: HeatingSupply.DISTRICTHEATING,
    systemFlow: SystemFlow.DIRECT,
    primaryTemperatureSetForward: 60,
    primaryTemperatureSetReturn: 30,
};
