import { CreateProjectStatus } from "types";

export const emptyProjectStatus: CreateProjectStatus = {
    copiedThemeAndSerialNumbers: false,
    copiedNamingSyntax: false,
    copiedRevitParameters: false,
    copiedDisciplines: false,
    copiedKnowledges: false,
    copiedSheetTypes: false,
    copiedFormats: false,
    copiedScales: false,
    copiedPhases: false,
    copiedStages: false,
    copiedDocumentKindCategories: false,
    copiedDocumentKinds: false,
    copiedFileTypes: false,
    copiedBusinessTypes: false,
    copiedSheetTemplates: false,
    copiedDocumentTemplates: false,
    createdSharedBuilding: false,
    createdGroundLevel: false,
};
