import React, { useState } from "react";
import { Modal, Button, Container, Form, Row } from "react-bootstrap";
import { useUser, useFormFields, FormEvent } from "hooks";
import { AuthError } from "firebase/auth";
import { useTranslation } from "react-i18next";

interface Props {
    handleClose: () => void;
    closeParent: () => void;
}

const ChangePasswordModal: React.FC<Props> = ({ handleClose, closeParent }) => {
    const { updatePassword, error } = useUser();
    const [requestError, setRequestError] = useState(false);
    const { t } = useTranslation();
    const { fields, handleFieldChange } = useFormFields({
        password: "",
        newPassword: "",
    });

    const inputIsValid = () => fields.password !== "" && fields.newPassword !== "";

    const errorMessage = (error: AuthError) => {
        if (error.code === "auth/wrong-password") {
            return t("pages.user-profile.error-wrong-password");
        }
        if (error.code === "auth/too-many-requests") {
            return t("pages.user-profile.error-many-attemps");
        }
        return t("pages.user-profile.error-generic");
    };

    return (
        <Modal show={true} onHide={handleClose} backdrop="static" centered>
            <Modal.Body>
                <PasswordForm
                    fields={fields}
                    handleFieldChange={handleFieldChange}
                    oldPasswordError={requestError && error ? errorMessage(error as AuthError) : ""}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("buttons.cancel")}
                </Button>
                <Button
                    disabled={!inputIsValid()}
                    variant="primary"
                    onClick={() => {
                        updatePassword(fields.password, fields.newPassword).then(
                            result => {
                                if (result) {
                                    handleClose();
                                }
                            },
                            reject => {
                                setRequestError(true);
                            }
                        );
                    }}
                >
                    {t("buttons.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChangePasswordModal;

interface FormProps {
    handleFieldChange: (e: FormEvent) => void;
    fields: {
        password: string;
        newPassword: string;
    };
    oldPasswordError: string;
}

const PasswordForm: React.FC<FormProps> = ({ handleFieldChange, fields, oldPasswordError }) => {
    const { t } = useTranslation();

    return (
        <Container>
            <Form className="d-grid gap-2" noValidate>
                <Form.Group as={Row} controlId="password">
                    <Form.Label>{t("pages.user-profile.current-password")}</Form.Label>
                    <Form.Control
                        isInvalid={oldPasswordError !== ""}
                        autoFocus
                        type="text"
                        value={fields.password}
                        onChange={handleFieldChange}
                    />
                    <Form.Control.Feedback type="invalid">{oldPasswordError}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Row} controlId="newPassword">
                    <Form.Label>{t("pages.user-profile.new-password")}</Form.Label>
                    <Form.Control
                        type="text"
                        isInvalid={fields.newPassword === ""}
                        value={fields.newPassword}
                        onChange={handleFieldChange}
                    />
                    <Form.Control.Feedback type="invalid">
                        {t("pages.user-profile.new-password-not-empty")}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </Container>
    );
};
