import React, { useState } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import Icon from "../../images/lets-start.svg";
import CreateProjectModalForm from "../Project/CreateProjectModalForm";
import { Plus } from "react-bootstrap-icons";

const HomePageNoProjects: React.FC = () => {
    const [showCreateProjectForm, setShowCreateProjectForm] = useState(false);

    return (
        <Container className="p-4 h-100">
            <Row className="h-100 justify-content-center align-items-center">
                <Col className="text-center">
                    <img src={Icon} alt="" />
                    <h3>Start your first project</h3>
                    <p className="text-muted">
                        Create drawing lists, calculate shaft sizes or generate an energy rapport.
                    </p>
                    <Button onClick={() => setShowCreateProjectForm(true)}>
                        <div className="d-flex align-items-center">
                            <Plus size={22} className="me-1" />
                            <span>Create project</span>
                        </div>
                    </Button>
                </Col>
            </Row>
            {showCreateProjectForm && <CreateProjectModalForm handleClose={() => setShowCreateProjectForm(false)} />}
        </Container>
    );
};

export default HomePageNoProjects;
