import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useUser, useFormFields } from 'hooks';
import UserForm from './UserForm';
import { auth } from 'config';
import ChangePasswordModal from './ChangePasswordModal';
import { useTranslation } from 'react-i18next';

interface Props {
    handleClose: () => void;
}

const UserModalForm: React.FC<Props> = ({ handleClose }) => {
    const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false)
    const { user, updateUser } = useUser();
    const { t } = useTranslation();
    const authProvider = auth.currentUser?.providerData[0].providerId;

    const emptyUser = {
        id: '',
        name: '',
        initials: '',
        email: '',
        company: '',
        knowledgeId: '',
        viewTemplateName: '',
    };

    const [isInvalid, setIsInvalid] = useState<string[]>([]);
    const { fields, handleFieldChange } = useFormFields(user || emptyUser);

    const validateFields = (): boolean => {
        const invalid = []
        const nameIsValid = () => fields.name !== "";
        const initialsIsValid = () => fields.initials !== "";
        const knowledgeIdIsValid = () => fields.knowledgeId !== "";
        if (!nameIsValid()) {
            invalid.push('NAME');
        }
        if (!initialsIsValid()) {
            invalid.push('INITIALS');
        }
        if (!knowledgeIdIsValid()) {
            invalid.push('KNOWLEDGEID');
        }
        if (invalid.length > 0) {
            setIsInvalid(invalid);
            return false;
        }
        return true;
    }

    const handleSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        const valid = validateFields();
        if (valid) {
            updateUser(fields);
            handleClose();
        }
    };

    return (
        <Modal show={true} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{user ? t("pages.user-profile.profile-edit") : t("pages.user-profile.profile-create")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserForm fields={fields} handleFieldChange={handleFieldChange} setIsInvalid={setIsInvalid} isInvalid={isInvalid} />
            </Modal.Body>
            <Modal.Footer>
                {authProvider === "password" && <Button variant="outline-primary" onClick={() => setShowChangePasswordModal(true)}>
                    {t("pages.user-profile.button-change-password")}
                </Button>}
                <Button variant="secondary" onClick={handleClose}>
                    {t("buttons.cancel")}
                </Button>
                <Button
                    disabled={isInvalid.length > 0}
                    variant="primary"
                    onClick={handleSubmit}>
                    {t("buttons.save")}
                </Button>
            </Modal.Footer>
            {showChangePasswordModal && <ChangePasswordModal handleClose={() => setShowChangePasswordModal(false)} closeParent={handleClose} />}
        </Modal>
    );
};

export default UserModalForm;
