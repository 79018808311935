import React, { useContext, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { IDocumentTemplate } from "types";
import { useContextModel, FormEvent } from "hooks";
import { UserContext } from "context";
import { useTranslation } from "react-i18next";

interface Props {
    fields: IDocumentTemplate;
    handleFieldChange: (e: FormEvent) => void;
    setFields: (fields: IDocumentTemplate) => void;
    setIsInvalid: (input: (s: string[]) => string[]) => void;
    isInvalid: string[];
}

const DocumentTemplateForm: React.FC<Props> = ({ fields, handleFieldChange, setFields, setIsInvalid, isInvalid }) => {
    const {
        contextModel,
        loadingModel,
        inCompanyContext,
        knowledgeStillExist,
        disciplineStillExist,
        documentKindStillExist,
        documentKindCategoryStillExist,
        getDocumentKindCategoryById,
        getDocumentKindCategoryByDocumentKindId,
    } = useContextModel();
    const { user } = useContext(UserContext);
    const { t } = useTranslation();

    const canEditFields = user?.role === "admin" || !inCompanyContext;

    useEffect(() => {
        if (loadingModel) return;
        if (fields.documentKindCategoryId === "" || fields.documentKindCategoryId === undefined) {
            const dkc = getDocumentKindCategoryByDocumentKindId(fields.documentKindId);
            setFields({ ...fields, documentKindCategoryId: dkc.id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingModel]);

    return (
        <Form className="d-grid gap-2" noValidate>
            <Row>
                <Form.Group as={Col} controlId="name">
                    <Form.Label>
                        {t("pages.shared.templates.document.documentname")}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                        disabled={!canEditFields}
                        isInvalid={isInvalid.includes("NAME")}
                        required
                        type="text"
                        placeholder={t("placeholders.notspecified") as string}
                        value={fields.name}
                        onChange={event => {
                            if (isInvalid.includes("NAME")) {
                                setIsInvalid((fieldIds: string[]) => fieldIds.filter(fieldId => fieldId !== "NAME"));
                            }
                            handleFieldChange(event);
                        }}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} controlId="knowledgeId">
                    <Form.Label>
                        {t("pages.shared.templates.value-title.knowledge")}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                        disabled={!canEditFields}
                        isInvalid={
                            (isInvalid.includes("KNOWLEDGEID") || !knowledgeStillExist(fields.knowledgeId)) &&
                            !loadingModel
                        }
                        value={fields.knowledgeId}
                        onChange={event => {
                            if (isInvalid.includes("KNOWLEDGEID")) {
                                setIsInvalid((fieldIds: string[]) =>
                                    fieldIds.filter(fieldId => fieldId !== "KNOWLEDGEID")
                                );
                            }
                            handleFieldChange(event);
                        }}
                    >
                        <option value="">{t("pages.shared.templates.value-empty.knowledge")}</option>
                        {contextModel.knowledges.map((knowledge, index) => (
                            <option value={knowledge.id} key={index}>
                                {knowledge.code} - {knowledge.value}
                                {knowledge.preferred && " ★ "}
                            </option>
                        ))}
                    </Form.Select>
                    {!knowledgeStillExist(fields.knowledgeId) && (
                        <Form.Control.Feedback type="invalid">
                            {t("pages.shared.templates.value-deleted.knowledge")}
                        </Form.Control.Feedback>
                    )}
                    {fields.knowledgeId === "" && (
                        <Form.Control.Feedback type="invalid">
                            {t("pages.shared.templates.value-required.knowledge")}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group as={Col} controlId="disciplineId">
                    <Form.Label>
                        {t("pages.shared.templates.value-title.discipline")}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                        disabled={!canEditFields}
                        isInvalid={
                            (isInvalid.includes("DISCIPLINEID") || !disciplineStillExist(fields.disciplineId)) &&
                            !loadingModel
                        }
                        value={fields.disciplineId}
                        onChange={event => {
                            if (isInvalid.includes("DISCIPLINEID")) {
                                setIsInvalid((fieldIds: string[]) =>
                                    fieldIds.filter(fieldId => fieldId !== "DISCIPLINEID")
                                );
                            }
                            handleFieldChange(event);
                        }}
                    >
                        <option value="">{t("pages.shared.templates.value-empty.discipline")}</option>
                        {contextModel.disciplines.map(discipline => (
                            <option value={discipline.id} key={discipline.id}>
                                {discipline.code} - {discipline.value}
                                {discipline.preferred && " ★ "}
                            </option>
                        ))}
                    </Form.Select>
                    {!disciplineStillExist(fields.disciplineId) && (
                        <Form.Control.Feedback type="invalid">
                            {t("pages.shared.templates.value-deleted.discipline")}
                        </Form.Control.Feedback>
                    )}
                    {fields.disciplineId === "" && (
                        <Form.Control.Feedback type="invalid">
                            {t("pages.shared.templates.value-required.discipline")}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} controlId="documentKindCategoryId">
                    <Form.Label>
                        {t("pages.shared.templates.value-title.documentcontentcategory")}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                        disabled={!canEditFields}
                        isInvalid={
                            (isInvalid.includes("DOCUMENTKINDCATEGORYID") ||
                                !documentKindCategoryStillExist(fields.documentKindCategoryId)) &&
                            !loadingModel
                        }
                        value={fields.documentKindCategoryId}
                        onChange={event => {
                            if (isInvalid.includes("DOCUMENTKINDCATEGORYID")) {
                                setIsInvalid((fieldIds: string[]) =>
                                    fieldIds.filter(fieldId => fieldId !== "DOCUMENTKINDCATEGORYID")
                                );
                            }
                            handleFieldChange(event);
                        }}
                    >
                        <option value="">{t("pages.shared.templates.value-empty.documentcontentcategory")}</option>
                        {contextModel.documentKindCategories?.map(dkc => (
                            <option key={dkc.id} value={dkc.id}>
                                {`${dkc.code} - ${dkc.value}`}
                                {dkc.preferred && " ★ "}
                            </option>
                        ))}
                    </Form.Select>
                    {!documentKindStillExist(fields.documentKindId) && (
                        <Form.Control.Feedback type="invalid">
                            {t("pages.shared.templates.value-deleted.documentcontentcategory")}
                        </Form.Control.Feedback>
                    )}
                    {fields.documentKindCategoryId === "" && (
                        <Form.Control.Feedback type="invalid">
                            {t("pages.shared.templates.value-required.documentcontentcategory")}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group as={Col} controlId="documentKindId">
                    <Form.Label>
                        {t("pages.shared.templates.value-title.documentcontent")}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                        disabled={!canEditFields}
                        isInvalid={
                            (isInvalid.includes("DOCUMENTKINDID") || !documentKindStillExist(fields.documentKindId)) &&
                            !loadingModel
                        }
                        value={fields.documentKindId}
                        onChange={event => {
                            if (isInvalid.includes("DOCUMENTKINDID")) {
                                setIsInvalid((fieldIds: string[]) =>
                                    fieldIds.filter(fieldId => fieldId !== "DOCUMENTKINDID")
                                );
                            }
                            handleFieldChange(event);
                        }}
                    >
                        <option value="">{t("pages.shared.templates.value-empty.documentcontent")}</option>
                        {contextModel.documentKinds
                            ?.filter(dk => dk.documentKindCategoryId === fields.documentKindCategoryId)
                            .map(documentKind => (
                                <option key={documentKind.id} value={documentKind.id}>
                                    {getDocumentKindCategoryById(documentKind.documentKindCategoryId)?.code}.
                                    {documentKind.subCode} - {documentKind.value}
                                    {documentKind.preferred && " ★ "}
                                </option>
                            ))}
                    </Form.Select>
                    {!documentKindStillExist(fields.documentKindId) && (
                        <Form.Control.Feedback type="invalid">
                            {t("pages.shared.templates.value-deleted.documentcontent")}
                        </Form.Control.Feedback>
                    )}
                    {fields.documentKindId === "" && (
                        <Form.Control.Feedback type="invalid">
                            {t("pages.shared.templates.value-required.documentcontent")}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
            </Row>
        </Form>
    );
};

export default DocumentTemplateForm;
