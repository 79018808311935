import { useContext } from "react";
import { ApplicationUIContext } from "context";

type UseApplicationUI = {
    handleToogleAppDrawer: () => void;
    handleHideAppDrawer: () => void;
    handleShowAppDrawer: () => void;
    showAppDrawer: boolean;
}

export const useApplicationUI = () : UseApplicationUI => {
    const { handleToogleAppDrawer,
        handleHideAppDrawer,
        handleShowAppDrawer,
        showAppDrawer } = useContext(ApplicationUIContext);

    return {
        handleToogleAppDrawer,
        handleHideAppDrawer,
        handleShowAppDrawer,
        showAppDrawer,
    }
}