import React from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Envelope, Microsoft } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

interface Props {
    setShowEmailLoginForm: (showEmailLoginForm: boolean) => void;
}

const LoginChooser: React.FC<Props> = ({ setShowEmailLoginForm }) => {
    const { t } = useTranslation();

    return (
        <div style={{ width: "25rem" }}>
            <Row className="mb-4">
                <Col className="d-flex justify-content-center">
                    <h3>{t("pages.login.title")}</h3>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-coming-soon-mssso">Coming soon</Tooltip>}
                    >
                        <div className="d-grid gap-2">
                            <Button
                                variant="outline-grey"
                                disabled={true}
                                className="align-items-center"
                                // onClick={handleMicrosoftLogin}
                            >
                                <Microsoft size={20} className="me-2" />
                                {t("pages.login.login-chooser.button-microsoft")}
                            </Button>
                        </div>
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                    <div className="d-grid gap-2">
                        <Button
                            variant="outline-primary"
                            className="align-items-center"
                            onClick={() => setShowEmailLoginForm(true)}
                        >
                            <Envelope size={20} className="me-2" />
                            {t("pages.login.login-chooser.button-email")}
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center">
                    <h6>{t("pages.login.login-chooser.not-registered")}</h6>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-coming-soon-create">Coming soon</Tooltip>}
                    >
                        <div className="d-flex justify-content-end">
                            <h6 className="ms-2 text-primary" style={{ cursor: "pointer" }}>
                                {t("pages.login.login-chooser.create-account")}
                            </h6>
                        </div>
                    </OverlayTrigger>
                </Col>
            </Row>
        </div>
    );
};

export default LoginChooser;
