import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Row, Col, Container } from "react-bootstrap";
import { useFormFields } from "hooks";
import {
    ElectricitySystem,
    HeatingSystem,
    SewageSystem,
    System,
    SystemDiscipline,
    SystemType,
    VentilationSystem,
    WaterSystem,
} from "types";
import ConfirmModal from "../../Shared/ConfirmModal";
import DrainageForm from "./Disciplines/DrainageForm";
import ElectricityForm from "./Disciplines/ElectricityForm";
import HeatingForm from "./Disciplines/HeatingForm";
import SewageForm from "./Disciplines/SewageForm";
import VentilationForm from "./Disciplines/VentilationForm";
import WaterForm from "./Disciplines/WaterForm";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SystemService } from "services/SystemService";
import { useProjectId, useAuth } from "hooks";
import toast from "react-hot-toast";

interface Props {
    system: System;
    handleClose: () => void;
}

const SystemModal: React.FC<Props> = ({ handleClose, system }) => {
    const client = useQueryClient();
    const projectId = useProjectId();
    const { getAccessToken } = useAuth();
    const newSystem = system.id === "";
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const disciplineLocalizationId = `${system.systemDiscipline}`;
    const { fields, handleFieldChange, setFields } = useFormFields<System>(system);
    const { t } = useTranslation();
    const [inputIsinvalid, setInputIsInvalid] = useState<boolean>(false);

    const { mutate: handleCreateSystem } = useMutation({
        mutationFn: async (system: System) => {
            const accessToken = await getAccessToken();
            if (!accessToken) {
                throw new Error("No access token found");
            }
            const task = SystemService.createSystem(projectId, system, accessToken);
            toast.promise(task, {
                loading: t("status.loading"),
                success: t("status.success"),
                error: t("status.error"),
            });
            return task;
        },
        onSuccess: () => {
            client.invalidateQueries(["systems"]);
        },
    });
    const { mutate: handleUpdateSystem } = useMutation({
        mutationFn: async (system: System) => {
            const accessToken = await getAccessToken();
            if (!accessToken) {
                throw new Error("No access token found");
            }
            const task = SystemService.updateSystem(projectId, system, accessToken);
            toast.promise(task, {
                loading: t("status.loading"),
                success: t("status.success"),
                error: t("status.error"),
            });
            return task;
        },
        onSuccess: () => {
            client.invalidateQueries(["systems"]);
        },
    });
    const { mutate: handleDeleteSystem } = useMutation({
        mutationFn: async (systemId: string) => {
            const accessToken = await getAccessToken();
            if (!accessToken) {
                throw new Error("No access token found");
            }
            const task = SystemService.deleteSystem(projectId, systemId, accessToken);
            toast.promise(task, {
                loading: t("status.loading"),
                success: t("status.success"),
                error: t("status.error"),
            });
            return task;
        },
        onSuccess: () => {
            client.invalidateQueries(["systems"]);
        },
    });

    const checkIfInputIsValid = () => {
        // split into multiple functions for readability
        const nameIsValid = fields.name !== "";
        if (fields.systemDiscipline === SystemDiscipline.WATER) {
            const waterSystemTypeIsValid = !!fields.systemType;
            if (fields.systemType === SystemType.DECENTRAL) {
                const decentralWaterSystemComponentIsValid = !!fields.component;
                const decentralWaterSystemComponentEffectIsValid = fields.componentEffect >= 0;
                const decentralWaterSystemComponentHeightIsValid = fields.componentHeight >= 0;
                const decentralWaterSystemComponentWidthIsValid = fields.componentWidth >= 0;
                return (
                    nameIsValid &&
                    waterSystemTypeIsValid &&
                    decentralWaterSystemComponentIsValid &&
                    decentralWaterSystemComponentEffectIsValid &&
                    decentralWaterSystemComponentHeightIsValid &&
                    decentralWaterSystemComponentWidthIsValid
                );
            } else {
                return nameIsValid && waterSystemTypeIsValid;
            }
        } else if (fields.systemDiscipline === SystemDiscipline.HEATING) {
            const heatingSystemTypeIsValid = !!fields.shuntPlacement;
            const heatingSupplyMethodIsValid = !!fields.heatingMethod;
            const heatingSupplyTemperatureIsValid = fields.temperatureSetForward >= 0;
            const heatingReturnTemperatureIsValid = fields.temperatureSetReturn >= 0;
            const heatingEffectIsValid = fields.heatingEffect >= 0;
            return (
                nameIsValid &&
                heatingSystemTypeIsValid &&
                heatingSupplyMethodIsValid &&
                heatingSupplyTemperatureIsValid &&
                heatingReturnTemperatureIsValid &&
                heatingEffectIsValid
            );
        } else if (fields.systemDiscipline === SystemDiscipline.VENTILATION) {
            const ventilationSystemTypeIsValid = !!fields.systemType;
            const ventilationFireProtectionIsValid = !!fields.fireProtection;
            return nameIsValid && ventilationSystemTypeIsValid && ventilationFireProtectionIsValid;
        } else if (fields.systemDiscipline === SystemDiscipline.DRAINAGE) {
            const drainageSizeIsValid = !!fields.pipeSize;
            return nameIsValid && drainageSizeIsValid;
        } else {
            return nameIsValid;
        }
    };

    useEffect(() => {
        if (inputIsinvalid) {
            setInputIsInvalid(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields]);

    const handleSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        if (checkIfInputIsValid()) {
            if (newSystem) {
                handleCreateSystem(fields);
            } else {
                handleUpdateSystem(fields);
            }
            handleClose();
        } else {
            setInputIsInvalid(true);
        }
    };

    return (
        <>
            <Modal show={true} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {newSystem
                            ? `${t("pages.FLAT.systems.modal.title-new")} ${t(
                                  `pages.FLAT.systems.disciplines.${disciplineLocalizationId}`
                              )}`
                            : `${t("pages.FLAT.systems.modal.title-edit")} ${t(
                                  `pages.FLAT.systems.disciplines.${disciplineLocalizationId}`
                              )}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-0">
                    <Container>
                        <Form className="d-grid gap-2">
                            <Form.Group>
                                <Form.Label>
                                    {t("pages.FLAT.systems.modal.name")}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    isInvalid={inputIsinvalid}
                                    id="name"
                                    placeholder={t("pages.FLAT.systems.modal.name-placeholder") as string}
                                    value={fields.name}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>

                            {fields.systemDiscipline === SystemDiscipline.WATER && (
                                <WaterForm
                                    fields={fields}
                                    handleFieldChange={handleFieldChange}
                                    setFields={setFields as React.Dispatch<React.SetStateAction<WaterSystem>>}
                                    inputIsinvalid={inputIsinvalid}
                                />
                            )}

                            {fields.systemDiscipline === SystemDiscipline.HEATING && (
                                <HeatingForm
                                    fields={fields}
                                    handleFieldChange={handleFieldChange}
                                    setFields={setFields as React.Dispatch<React.SetStateAction<HeatingSystem>>}
                                    inputIsinvalid={inputIsinvalid}
                                />
                            )}

                            {fields.systemDiscipline === SystemDiscipline.VENTILATION && (
                                <VentilationForm
                                    fields={fields}
                                    handleFieldChange={handleFieldChange}
                                    setFields={setFields as React.Dispatch<React.SetStateAction<VentilationSystem>>}
                                    inputIsinvalid={inputIsinvalid}
                                />
                            )}

                            {fields.systemDiscipline === SystemDiscipline.SEWAGE && (
                                <SewageForm
                                    fields={fields}
                                    setFields={setFields as React.Dispatch<React.SetStateAction<SewageSystem>>}
                                />
                            )}

                            {fields.systemDiscipline === SystemDiscipline.ELECTRICITY && (
                                <ElectricityForm
                                    fields={fields}
                                    setFields={setFields as React.Dispatch<React.SetStateAction<ElectricitySystem>>}
                                />
                            )}

                            {fields.systemDiscipline === SystemDiscipline.DRAINAGE && (
                                <DrainageForm
                                    fields={fields}
                                    handleFieldChange={handleFieldChange}
                                    inputIsinvalid={inputIsinvalid}
                                />
                            )}
                        </Form>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    {!newSystem && (
                        <Button
                            className="text-danger"
                            variant="outline"
                            onClick={_ => {
                                setShowConfirmModal(true);
                            }}
                        >
                            {t("buttons.delete")}
                        </Button>
                    )}
                    <div>
                        <Button variant="outline-primary" onClick={handleClose}>
                            {t("buttons.cancel")}
                        </Button>
                        <Button className="ms-2" disabled={inputIsinvalid} variant="primary" onClick={handleSubmit}>
                            {newSystem ? t("buttons.create") : t("buttons.save")}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            {
                <ConfirmModal
                    show={showConfirmModal}
                    handleAction={() => {
                        handleDeleteSystem(system.id);
                        handleClose();
                    }}
                    handleClose={() => {
                        setShowConfirmModal(false);
                    }}
                    title={t("pages.FLAT.systems.modal.title-delete")}
                    description={t("pages.FLAT.systems.modal.description-delete")}
                    confirmText={t("buttons.delete")}
                />
            }
        </>
    );
};

export default SystemModal;
