import React, { useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserContext } from "context";
import { useFormFields } from "hooks";
import { RevitParameter } from "types";
import { EMPTY_REVITPARAMETER } from "_constants";
import orderBy from "lodash/orderBy";
import RevitParameterFormRow from "./RevitParameterFormRow";
import RevitParameterEditModal from "./RevitParameterEditModal";

interface Props {
    revitParameters: RevitParameter[];
    createRevitParameter: (data: Partial<RevitParameter>) => Promise<void>;
    updateRevitParameter: (data: Partial<RevitParameter>) => Promise<void>;
    deleteRevitParameter: (id: string) => Promise<void>;
}

const RevitParameterContainer: React.FC<Props> = ({
    revitParameters,
    createRevitParameter,
    updateRevitParameter,
    deleteRevitParameter,
}) => {
    const { t } = useTranslation();
    const [showCreateEditModal, setShowCreateEditModal] = useState<boolean>(false);
    const { fields, handleFieldChange, setFields } = useFormFields<RevitParameter>(EMPTY_REVITPARAMETER);
    const { user } = useContext(UserContext);
    const isAdmin = user?.role === "admin";

    const handleDelete = () => {
        if (!fields.id) return;
        deleteRevitParameter(fields.id);
        handleClose();
    };

    const handleUpdate = () => {
        updateRevitParameter(fields);
        handleClose();
    };

    const handleCreate = () => {
        createRevitParameter(fields);
        handleClose();
    };

    const handleClose = () => {
        setShowCreateEditModal(false);
    };

    const handleEditEntry = (revitParameter: RevitParameter) => {
        setShowCreateEditModal(true);
        setFields(revitParameter);
    };

    const handleNewEntry = () => {
        setShowCreateEditModal(true);
        setFields(EMPTY_REVITPARAMETER);
    };

    const handleItemClick = (value: string) => {
        let formattedValue;
        if (fields.parameterValue !== "") {
            formattedValue = `${fields.parameterValue} [${value}]`;
        } else {
            formattedValue = `[${value}]`;
        }
        setFields({ ...fields, parameterValue: formattedValue });
    };

    return (
        <>
            <Row>
                <Col>
                    <Button disabled={!isAdmin} onClick={handleNewEntry} className="me-2">
                        {t("buttons.add")}
                    </Button>
                    <Form className="d-grid gap-2">
                        <Row className="mt-2">
                            <Col xs={5}>
                                <h6 className="mb-1">
                                    {t("pages.FAST.settings.revit-parameters.fields.revit-parameter")}
                                </h6>
                            </Col>
                            <Col xs={5}>
                                <h6 className="mb-1">{t("pages.FAST.settings.revit-parameters.fields.FAST-name")}</h6>
                            </Col>
                            <Col xs={2}></Col>
                        </Row>
                        {revitParameters &&
                            orderBy(revitParameters, ["parameterValue", "revitParameterName"], ["asc", "asc"]).map(
                                (revitParameter, index) => (
                                    <RevitParameterFormRow
                                        key={index}
                                        handleEdit={handleEditEntry}
                                        revitParameter={revitParameter}
                                    />
                                )
                            )}
                    </Form>
                </Col>
            </Row>
            {showCreateEditModal && (
                <RevitParameterEditModal
                    fields={fields}
                    handleDelete={handleDelete}
                    handleClose={handleClose}
                    handleCreate={handleCreate}
                    handleUpdate={handleUpdate}
                    handleFieldChange={handleFieldChange}
                    handleItemClick={handleItemClick}
                />
            )}
        </>
    );
};

export default RevitParameterContainer;
