import { Building, HousingType } from "types";
import { DEFAULT_SPECIAL_STOREY_CODE } from "./storey.constants";

export const UNKNOWN_BUILDING_ID = 'UNKNOWN_BUILDING';

export const EMPTY_BUILDING: Building = {
    id: undefined,
    name: '',
    code: '',
    numberOfStoreys: 0,
    numberOfBasementStoreys: 0,
    scopeBox: '',
    revitModel: '',
    description: '',
    sharedLocation: false,
    housingType: HousingType.UNKNOWN,
    housingTypeSize: 0,
    specialStoreys: []
};

export const EMPTY_FAST_BUILDING: Building = {
    id: undefined,
    name: '',
    code: '',
    numberOfStoreys: 0,
    numberOfBasementStoreys: 0,
    scopeBox: '',
    revitModel: '',
    description: '',
    sharedLocation: false,
    housingType: HousingType.UNKNOWN,
    housingTypeSize: 0,
    specialStoreys: DEFAULT_SPECIAL_STOREY_CODE
};

export const UNKNOWN_BUILDING: Building = {
    id: UNKNOWN_BUILDING_ID,
    name: 'Fælles',
    code: 'LXX',
    numberOfStoreys: 0,
    numberOfBasementStoreys: 0,
    scopeBox: '',
    revitModel: '',
    description: '',
    sharedLocation: true,
};
