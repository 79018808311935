import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LocationSpecificTemplate } from "types";
import { useFormFields } from "hooks";
import LocationSpecificTemplateForm from "./LocationSpecificTemplateForm";
import LocationSpecificTemplateList from "./LocationSpecificTemplateList";

interface Props {
    selectedDiagrams: LocationSpecificTemplate[];
    handleClose: () => void;
    handleAddDiagram: (sheetTemplate: LocationSpecificTemplate) => void;
    handleDeleteDiagram: (sheetTemplate: LocationSpecificTemplate) => void;
    title: string;
    sheetTemplate: LocationSpecificTemplate;
}

const LocationSpecificTemplateModalForm: React.FC<Props> = ({
    handleClose,
    handleAddDiagram,
    handleDeleteDiagram,
    selectedDiagrams,
    title,
    sheetTemplate,
}) => {
    const { t } = useTranslation();
    const { fields, handleFieldChange } = useFormFields<LocationSpecificTemplate>({
        ...sheetTemplate,
        locationId: "UNKNOWN_BUILDING",
    });

    return (
        <Modal show={true} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LocationSpecificTemplateForm fields={fields} handleFieldChange={handleFieldChange} />
                <Row>
                    <Col className="d-flex justify-content-end mt-3">
                        <Button onClick={() => handleAddDiagram(fields)}>{t("buttons.add")}</Button>
                    </Col>
                </Row>
                <hr />
                <LocationSpecificTemplateList handleDeleteDiagram={handleDeleteDiagram} diagrams={selectedDiagrams} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("buttons.close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LocationSpecificTemplateModalForm;
