import React from "react";
import Modal from "react-bootstrap/Modal";
import { IDocument } from "types";
import DocumentForm from "./DocumentForm";

interface Props {
    document: IDocument;
    show: boolean;
    handleClose: () => void;
}

const DocumentEditorModal: React.FC<Props> = ({ document, show, handleClose }): JSX.Element => (
    <Modal size="lg" show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>{document?.title}</Modal.Title>
        </Modal.Header>
        <DocumentForm document={document} handleClose={handleClose} />
    </Modal>
);

export default DocumentEditorModal;
