import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import CompanySettingsContainer from "../components/Company/CompanySettingsContainer";
import CompanyParameters from "../components/Company/CompanyParameters";
import CompanyUsers from "../components/Company/CompanyUsers";
import ThemeValueListContainer from "../components/ThemeValueList/ThemeValueListContainer";
import CompanyTemplatesContainer from "../components/Templates/CompanyTemplatesContainer";

const CompanyRoutes: React.FC = () => {
    const { path, url } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <CompanySettingsContainer />
            </Route>
            <Route exact path={`${url}/templates`}>
                <CompanyTemplatesContainer />
            </Route>
            <Route exact path={`${url}/users`}>
                <CompanyUsers />
            </Route>
            <Route exact path={`${url}/value-pair-lists`}>
                <ThemeValueListContainer />
            </Route>
            <Route exact path={`${url}/naming-convention`}>
                <CompanyParameters />
            </Route>
        </Switch>
    );
};

export default CompanyRoutes;
