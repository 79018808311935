import React from "react";
import { Row, Col } from "react-bootstrap";
import { Line, LineItem } from "../Shared/Layout";
import { useTranslation } from "react-i18next";

const ThemeValueListTableHeader: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Row>
            <Col>
                <div className="px-3 text-muted">
                    <Line>
                        <LineItem style={{ width: "35%" }}>{t("pages.FAST.value-lists.theme-headers.name")}</LineItem>
                        <LineItem style={{ width: "20%", textAlign: "center" }}>
                            {t("pages.FAST.value-lists.theme-headers.values")}
                        </LineItem>
                        <LineItem style={{ width: "20%", textAlign: "center" }}>
                            {t("pages.FAST.value-lists.theme-headers.codes")}
                        </LineItem>
                        <LineItem style={{ width: "25%", textAlign: "right" }}>
                            {t("pages.FAST.value-lists.theme-headers.definitions")}
                        </LineItem>
                    </Line>
                </div>
            </Col>
        </Row>
    );
};

export default ThemeValueListTableHeader;
