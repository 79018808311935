import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DocumentTemplatesContainer from "./DocumentTemplates/DocumentTemplatesContainer";
import SheetTemplatesContainer from "./SheetTemplates/SheetTemplatesContainer";

const CompanyTemplatesContainer: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="p-4 h-100">
            <Tabs mountOnEnter={true} unmountOnExit={true}>
                <Tab eventKey="sheet-templates" title={t("pages.shared.templates.sheet.title-tab")}>
                    <SheetTemplatesContainer title={t("pages.shared.templates.sheet.title-tab") as string} />
                </Tab>
                <Tab eventKey="document-templates" title={t("pages.shared.templates.document.title-tab")}>
                    <DocumentTemplatesContainer title={t("pages.shared.templates.document.title-tab") as string} />
                </Tab>
            </Tabs>
        </div>
    );
};

export default CompanyTemplatesContainer;
