import React, { useEffect, useState } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { useThemeValueLists, useFormFields } from "hooks";
import { ThemeValueList } from "types";
import { EMPTY_THEME_VALUE_LIST } from "_constants";
import { isEqual } from "lodash";
import ConfirmModal from "../Shared/ConfirmModal";
import ThemeValueListModalFields from "./ThemeValueListModalFields";
import ThemeValuePairList from "./ThemeValuePairList";
import ThemeValuePairModal from "./ThemeValuePairModal";
import { useTranslation } from "react-i18next";
import DeleteButton from "components/Shared/Buttons/DeleteButton";

interface Props {
    themeValueList?: ThemeValueList;
    handleClose: () => void;
}

const ThemeValueListModal: React.FC<Props> = ({ themeValueList, handleClose }) => {
    const { createThemeValueList, editThemeValueList, deleteThemeValueList, deleteThemeValuePair } =
        useThemeValueLists();
    const [showCreateThemeValuePair, setShowCreateThemeValuePair] = useState<boolean>(false);
    const [showEditThemeValuePair, setShowEditThemeValuePair] = useState<boolean>(false);
    const [selectedThemeValuePair, setSelectedThemeValuePair] = useState<string>("");
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const { fields, handleFieldChange, setFields } = useFormFields<Partial<ThemeValueList>>(
        themeValueList || EMPTY_THEME_VALUE_LIST
    );
    const isEditState = themeValueList !== undefined;
    const noFormChanges = isEqual(themeValueList, fields);
    const { t } = useTranslation();
    const bg = isEditState ? "bg-light pt-2" : "pt-2";

    const handleSubmit = () => {
        if (isEditState) {
            editThemeValueList(fields as ThemeValueList);
        } else {
            createThemeValueList(fields);
        }
        setFields(EMPTY_THEME_VALUE_LIST);
    };
    const handleSelectThemeValuePair = (id: string) => {
        setSelectedThemeValuePair(id);
        setShowEditThemeValuePair(true);
    };

    const handleClickDelete = (): void => {
        if (themeValueList === undefined) return;
        const { id } = themeValueList;
        if (id === undefined) return;
        deleteThemeValueList(id);
        handleClose();
    };

    const handleDeletetThemeValuePair = (id: string) => {
        setShowEditThemeValuePair(false);
        setSelectedThemeValuePair("");
        deleteThemeValuePair(id);
    };

    const valuePairs = isEditState && themeValueList && themeValueList.valuePairs ? themeValueList.valuePairs : [];

    useEffect(() => {
        if (!themeValueList) return;
        setFields(themeValueList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeValueList]);
    return (
        <>
            <Modal size={isEditState ? "xl" : undefined} show={true} onHide={handleClose} backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {isEditState ? themeValueList.name : t("pages.FAST.value-lists.button-add-theme")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <Container fluid>
                        <Row>
                            <Col>
                                {isEditState && (
                                    <ThemeValuePairList
                                        code={(fields as ThemeValueList).code}
                                        handleShowModal={setShowCreateThemeValuePair}
                                        lexicographicallySorted={fields.lexicographicallySorted || false}
                                        themeValuePairs={valuePairs}
                                        handleSelectThemeValuePair={handleSelectThemeValuePair}
                                        handleDeletetThemeValuePair={handleDeletetThemeValuePair}
                                    />
                                )}
                            </Col>
                            <Col
                                xs={{ span: 12, order: 1 }}
                                md={{ span: 12, order: 1 }}
                                lg={{ span: isEditState ? 6 : 12, order: 12 }}
                                className={bg}
                            >
                                <ThemeValueListModalFields
                                    fields={fields}
                                    handleChangeField={handleFieldChange}
                                    setFields={setFields}
                                />
                            </Col>
                        </Row>
                    </Container>
                    {showCreateThemeValuePair && (
                        <ThemeValuePairModal
                            themeValueListId={fields.id}
                            handleClose={() => setShowCreateThemeValuePair(false)}
                        />
                    )}
                    {showEditThemeValuePair && (
                        <ThemeValuePairModal
                            themeValueListId={fields.id}
                            handleClose={() => setShowEditThemeValuePair(false)}
                            themeValuePair={valuePairs.find(vp => vp.id === selectedThemeValuePair)}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <div>{isEditState && <DeleteButton onClick={() => setShowConfirmModal(true)} />}</div>
                    <div>
                        <Button variant="secondary" onClick={handleClose} style={{ minWidth: "100px" }}>
                            {t("buttons.cancel")}
                        </Button>

                        <Button
                            className="ms-2"
                            variant="primary"
                            disabled={noFormChanges}
                            onClick={() => {
                                if (noFormChanges) {
                                    handleClose();
                                }
                                handleSubmit();
                            }}
                            style={{ minWidth: "100px" }}
                        >
                            {isEditState ? t("buttons.save") : t("buttons.create")}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <ConfirmModal
                show={showConfirmModal}
                handleAction={() => handleClickDelete()}
                handleClose={() => setShowConfirmModal(false)}
                description={t("pages.FAST.value-lists.delete-confirm-theme")}
                confirmText={t("buttons.confirm")}
            />
        </>
    );
};

export default ThemeValueListModal;
