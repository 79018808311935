import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { User } from "types";

interface Props {
    user: User;
}

const UserCard: React.FC<Props> = ({ user }) => (
    <Card className="m-0 p-0 mb-4 border-0" style={{ height: 45, width: 250 }}>
        <Card.Body className="m-0 p-0">
            <Row>
                <Col xs={2}>
                    <div
                        style={{ borderRadius: "100%", height: 45, width: 45 }}
                        className="d-flex justify-content-center align-items-center bg-secondary text-truncate"
                    >
                        <b>{user.initials}</b>
                    </div>
                </Col>
                <Col xs={10} className="d-flex align-items-center">
                    <div className="ms-1">
                        <h6 style={{ fontSize: "1rem" }} className="text-truncate mb-0">
                            {user.name}
                        </h6>
                        <h6 style={{ fontSize: "0.7rem" }} className="text-muted text-truncate m-0">
                            {user.email}
                        </h6>
                    </div>
                </Col>
            </Row>
        </Card.Body>
    </Card>
);

export default UserCard;
