import React, { useContext, useState } from "react";
import { Button, Col, Container, FormControl, InputGroup, Row } from "react-bootstrap";
import { PlusCircleFill, Search } from "react-bootstrap-icons";
import { ProjectContext } from "context";
import ProjectsList from "./ProjectsList";
import CreateProjectModalForm from "../Project/CreateProjectModalForm";

const ProjectsContainer: React.FC = () => {
    const { projects } = useContext(ProjectContext);
    const [showCreateProjectForm, setShowCreateProjectForm] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [showingArchivedProjects, setShowingArchivedProjects] = useState(false);

    const listedProjects = () => {
        if (showingArchivedProjects) {
            return projects.filter(
                project => project.archived && project.name.toLowerCase().includes(searchValue.toLowerCase())
            );
        } else {
            return projects.filter(
                project => !project.archived && project.name.toLowerCase().includes(searchValue.toLowerCase())
            );
        }
    };

    const handleShowArchivedProjects = () => {
        setShowingArchivedProjects(true);
    };

    const handleShowRecentProjects = () => {
        setShowingArchivedProjects(false);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    return (
        <Container fluid className="p-4 h-100">
            <Row className="mb-4">
                <Col>
                    <h1>Your Projects</h1>
                    <h6 className="text-muted">Here you can see all the projects you are working on.</h6>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col>
                    <Button className="d-flex align-items-center" onClick={() => setShowCreateProjectForm(true)}>
                        <PlusCircleFill size={20} className="me-2" />
                        New project
                    </Button>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <InputGroup style={{ width: 400 }} size="sm">
                        <InputGroup.Text>
                            <Search />
                        </InputGroup.Text>
                        <FormControl placeholder="Search" type="text" value={searchValue} onChange={handleSearch} />
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col>
                    <span onClick={handleShowRecentProjects} className="me-5" style={{ cursor: "pointer" }}>
                        {!showingArchivedProjects && (
                            <u
                                style={{
                                    textDecorationColor: "#7685DE",
                                    textDecorationThickness: 3,
                                    textUnderlineOffset: 4,
                                }}
                            >
                                Recent
                            </u>
                        )}
                        {showingArchivedProjects && <>Recent</>}
                    </span>
                    <span onClick={handleShowArchivedProjects} style={{ cursor: "pointer" }}>
                        {showingArchivedProjects && (
                            <u
                                style={{
                                    textDecorationColor: "#7685DE",
                                    textDecorationThickness: 3,
                                    textUnderlineOffset: 4,
                                }}
                            >
                                Archived
                            </u>
                        )}
                        {!showingArchivedProjects && <>Archived</>}
                    </span>
                </Col>
            </Row>
            <Row className="h-100-17rem overflow-auto">
                <Col>
                    <ProjectsList projects={listedProjects()} />
                </Col>
            </Row>
            {showCreateProjectForm && <CreateProjectModalForm handleClose={() => setShowCreateProjectForm(false)} />}
        </Container>
    );
};

export default ProjectsContainer;
