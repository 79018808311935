import React, { useContext } from "react";
import { ListGroup } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { LocationSpecificTemplate } from "types";
import { BuildingsContext } from "context";
import { useBuildingStoreys } from "hooks";
import { UNKNOWN_BUILDING } from "_constants";
import { getStorey } from "../../utils/helpers";
import { Line, LineItem } from "../Shared/Layout";

interface Props {
    diagram: LocationSpecificTemplate;
    handleDeleteDiagram: (sheetTemplate: LocationSpecificTemplate) => void;
}

const LocationSpecificTemplateListItem: React.FC<Props> = ({ diagram, handleDeleteDiagram }) => {
    const { title, locationId, storeyId } = diagram;
    const { buildings } = useContext(BuildingsContext);
    const storeys = useBuildingStoreys(locationId);
    const diagramStorey = getStorey(storeys, storeyId);
    const diagramLocation = buildings.find(b => b.id === locationId) || UNKNOWN_BUILDING;
    const locationName = diagramLocation.name;
    const storeyName = diagramStorey.name;

    return (
        <ListGroup.Item>
            <Line>
                <LineItem grow>
                    <Line>
                        <LineItem px={2}>{title}</LineItem>
                        <LineItem px={2}>{locationName}</LineItem>
                        <LineItem px={2}>{storeyName}</LineItem>
                    </Line>
                </LineItem>
                <LineItem px={2} role="button" onClick={() => handleDeleteDiagram(diagram)}>
                    <Trash />
                </LineItem>
            </Line>
        </ListGroup.Item>
    );
};

export default LocationSpecificTemplateListItem;
