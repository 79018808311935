export enum ViewType {
    Undefined = 0, //Undefined/unspecified type of view.
    FloorPlan = 1, //Floor plan type of view.
    EngineeringPlan = 115, //Structural plan or Engineering plan type of view.
    AreaPlan = 116, //Area plan type of view.
    CeilingPlan = 2, //Reflected ceiling plan type of view.
    Elevation = 3, //Elevation type of view.
    Section = 117, //Cross section type of view.
    Detail = 118, //Detail type of view.
    ThreeD = 4, //3-D type of view.
    Schedule = 5, //Schedule type of view.
    DraftingView = 10, //Drafting type of view.
    DrawingSheet = 6, //Drawing sheet type of view.
    Legend = 11, //Legend type of view.
    Report = 8, //Report type of view.
    ProjectBrowser = 7, //The project browser view.
    SystemBrowser = 12, //The MEP system browser view.
    CostReport = 119, //Cost Report view.
    LoadsReport = 120, //Loads Report view.
    PresureLossReport = 121, //Pressure Loss Report view.
    PanelSchedule = 123, //Panel Schedule Report view.
    ColumnSchedule = 122, //Column Schedule type of view.
    Walkthrough = 124, //Walk-Through type of 3D view.
    Rendering = 125, //Rendering type of view.
    SystemsAnalysisReport = 126, //Systems analysis report view.
    Internal = 214, //Internal views are not available to API users - Revit's internal type of view
}
