import React from "react";
import { Badge } from "react-bootstrap";
import { ChangeLogEntry as ChangeLogEntryEntity } from "types";

type ChangeLogEntryProps = {
    changeLogEntry: ChangeLogEntryEntity;
};

const ChangeLogEntry = ({ changeLogEntry }: ChangeLogEntryProps): JSX.Element => {
    const { version, date, features } = changeLogEntry;
    const versionDate = new Date(date);
    const dateOptions: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };

    return (
        <div className="mb-6">
            <Badge pill bg="info" className="py-2 px-3">
                v{version}
            </Badge>
            <h6 className="ps-2 d-inline-block text-muted">{`- ${versionDate.toLocaleDateString(
                "da-DK",
                dateOptions
            )}`}</h6>
            <ul className="mt-4 mb-3">
                {features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                ))}
            </ul>
        </div>
    );
};

export default ChangeLogEntry;
