import React, { useContext } from 'react';
import { Container, FormGroup, Row, FormControl, Form } from 'react-bootstrap';
import { CompanyContext } from 'context';
import { FormEvent, useContextModel } from 'hooks';
import { type User } from 'types';
import { useTranslation } from 'react-i18next';

interface Props {
    handleFieldChange: (e: FormEvent) => void;
    isInvalid: string[];
    setIsInvalid: (input: (s: string[]) => string[]) => void;
    fields: User;
}

const UserForm: React.FC<Props> = ({ handleFieldChange, fields, isInvalid, setIsInvalid }) => {
    const { company } = useContext(CompanyContext);
    const { contextModel, knowledgeStillExist } = useContextModel();
    const { t } = useTranslation();
    const defaultKnowledge = company?.knowledgeId ? company.knowledgeId : (contextModel.knowledges && contextModel.knowledges[0])?.id

    return (
        <Container>
            <Form className="d-grid gap-2" noValidate>
                <FormGroup as={Row} controlId="name">
                    <Form.Label>{t("pages.user-profile.name")}<span className='text-danger'>*</span></Form.Label>
                    <FormControl
                        isInvalid={isInvalid.includes("NAME")}
                        autoFocus
                        type="text"
                        value={fields.name!}
                        onChange={event => {
                            if (isInvalid.includes("NAME")) {
                                setIsInvalid((fieldIds: string[]) => fieldIds.filter(fieldId => fieldId !== "NAME"))
                            }
                            handleFieldChange(event)
                        }}
                    />
                </FormGroup>
                <FormGroup as={Row} controlId="initials">
                    <Form.Label>{t("pages.user-profile.initials")}<span className='text-danger'>*</span></Form.Label>
                    <FormControl
                        isInvalid={isInvalid.includes("INITIALS")}
                        type="text"
                        value={fields.initials}
                        onChange={event => {
                            if (isInvalid.includes("INITIALS")) {
                                setIsInvalid((fieldIds: string[]) => fieldIds.filter(fieldId => fieldId !== "INITIALS"))
                            }
                            handleFieldChange(event)
                        }}
                    />
                </FormGroup>
                <FormGroup as={Row} controlId="email">
                    <Form.Label>{t("pages.user-profile.email")}</Form.Label>
                    <FormControl type="email" value={fields.email!} readOnly />
                </FormGroup>
                <FormGroup as={Row} controlId="company">
                    <Form.Label>{t("pages.user-profile.company")}</Form.Label>
                    <FormControl type="text" value={company?.name} readOnly />
                </FormGroup>
                <Form.Group as={Row} controlId="knowledgeId">
                    <Form.Label>{t("pages.user-profile.primary-knowledge")}<span className='text-danger'>*</span></Form.Label>
                    <Form.Select
                        isInvalid={isInvalid.includes("KNOWLEDGEID") || !knowledgeStillExist(fields.knowledgeId)}
                        defaultValue={defaultKnowledge}
                        value={fields.knowledgeId}
                        onChange={event => {
                            if (isInvalid.includes("KNOWLEDGEID")) {
                                setIsInvalid((fieldIds: string[]) => fieldIds.filter(fieldId => fieldId !== "KNOWLEDGEID"))
                            }
                            handleFieldChange(event)
                        }}
                    >
                        <option value="" key="">{t("pages.user-profile.choose-knowledge")}</option>
                        {contextModel.knowledges.map(knowledge => (
                            <option value={knowledge.id} key={knowledge.id}>
                                {knowledge.code} - {knowledge.value}{knowledge.preferred && " ★ "}
                            </option>
                        ))}
                    </Form.Select>
                    {!knowledgeStillExist(fields.knowledgeId) && <Form.Control.Feedback type="invalid">{t("pages.user-profile.primary-knowledge-deleted")}</Form.Control.Feedback>}
                    {isInvalid.includes("KNOWLEDGEID") && <Form.Control.Feedback type="invalid">{t("pages.user-profile.choose-knowledge")}</Form.Control.Feedback>}
                </Form.Group>
                <FormGroup as={Row} controlId="viewTemplateName">
                    <Form.Label>{t("pages.user-profile.revit-view-template-name")}</Form.Label>
                    <FormControl type="text" value={fields.viewTemplateName} onChange={handleFieldChange} placeholder={t("pages.user-profile.revit-view-template-name-placeholder") as string} />
                </FormGroup>
            </Form>
        </Container>
    )
};

export default UserForm;
