import React, { useState } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { useThemeValueLists } from "hooks";
import ModelList from "../ValueList/ModelList";
import ThemeValueListModal from "./ThemeValueListModal";
import ThemeValueListTable from "./ThemeValueListTable";
import { useTranslation } from "react-i18next";

const ThemeValueListContainer: React.FC = () => {
    const { themeValueLists } = useThemeValueLists();
    const [showCreateThemeValueListModal, setShowCreateThemeValueListModal] = useState<boolean>(false);
    const [showEditThemeValueListModal, setShowEditThemeValueListModal] = useState<boolean>(false);
    const [selectedThemeValueList, setSelectedThemeValueList] = useState<string>("");
    const { t } = useTranslation();

    const handleSelectThemeValueList = (id: string) => {
        setSelectedThemeValueList(id);
        setShowEditThemeValueListModal(true);
    };

    const handleCreateThemeValueList = () => {
        setSelectedThemeValueList("");
        setShowEditThemeValueListModal(true);
    };

    return (
        <Container fluid className="p-4 h-100" style={{ overflowY: "auto" }}>
            <h1>{t("pages.FAST.value-lists.title")}</h1>
            <h6 className="text-muted">{t("pages.FAST.value-lists.subtitle-company")}</h6>
            <Row className="mt-5">
                <Col>
                    <h4>{t("pages.FAST.value-lists.title-themevalue")}</h4>
                    <Button
                        variant="primary"
                        className="d-inline-flex align-items-center mb-3 mt-2"
                        onClick={() => handleCreateThemeValueList()}
                    >
                        <Plus />
                        <div className="ms-1">{t("pages.FAST.value-lists.button-add-theme")}</div>
                    </Button>
                    <ThemeValueListTable
                        themeValueLists={themeValueLists}
                        handleSelectThemeValueList={handleSelectThemeValueList}
                    />
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <h4>Value lists</h4>
                    <ModelList />
                </Col>
            </Row>
            {showCreateThemeValueListModal && (
                <ThemeValueListModal handleClose={() => setShowCreateThemeValueListModal(false)} />
            )}
            {showEditThemeValueListModal && (
                <ThemeValueListModal
                    themeValueList={themeValueLists.find(tvl => tvl.id === selectedThemeValueList)}
                    handleClose={() => setShowEditThemeValueListModal(false)}
                />
            )}
        </Container>
    );
};

export default ThemeValueListContainer;
