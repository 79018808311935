import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics, logEvent as logAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log("connecting to firestore emulator...");
    connectFirestoreEmulator(firestore, "localhost", 8081);
}
export const analytics = getAnalytics(app);
export const logEvent = (eventId: string): void => logAnalytics(analytics, eventId);
export const auth = getAuth(app);
export const storage = getStorage(app);
