import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { Button, Col, Form, Modal, InputGroup, ListGroup, DropdownButton, Dropdown, Row } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { useFormFields } from "hooks";
import { Room, Fixture, FixtureType } from "types";
import { ToolTipButton } from "../../ToolTip/ToolTip";
import ConfirmModal from "../../Shared/ConfirmModal";
import OverlayIconButton from "../../Shared/Buttons/OverlayIconButton";
import { useTranslation } from "react-i18next";

interface Props {
    room: Room;
    handleAddRoom: (room: Room) => void;
    handleUpdateRoom: (room: Room) => void;
    handleRemoveRoom: (room: Room) => void;
    handleClose: () => void;
}

const RoomModal: React.FC<Props> = ({ room, handleAddRoom, handleUpdateRoom, handleRemoveRoom, handleClose }) => {
    const { fields, handleFieldChange, setFields } = useFormFields<Room>(room);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
    const [isInvalid, setIsInvalid] = useState<string[]>([]);
    const newRoom = fields.id === "";
    const { t } = useTranslation();

    const handleAddFixture = (fixtureType: FixtureType) => {
        const highestPriority =
            fields.fixtures.length > 0 ? Math.max(...fields.fixtures.map(fixture => fixture.priority)) : 0;
        const newFixture: Fixture = {
            type: fixtureType,
            priority: highestPriority + 1,
        };
        setFields(prevFields => ({
            ...prevFields,
            fixtures: [...prevFields.fixtures, newFixture],
        }));
    };

    const handleRemoveFixture = (fixture: Fixture) => {
        setFields(curFields => ({
            ...curFields,
            fixtures: curFields.fixtures.filter(t => t !== fixture),
        }));
    };

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        const items = [...fields.fixtures];
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setFields(curFields => ({ ...curFields, fixtures: items }));
    };

    const handleSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        const valid = validateFields();
        if (valid) {
            newRoom ? handleAddRoom(fields) : handleUpdateRoom(fields);
        }
        handleClose();
    };

    const validateFields = (): boolean => {
        const invalid = [];
        const nameIsValid = () => fields.name !== "";
        const airFlowIsValid = () => fields.airFlow >= 0;
        if (!nameIsValid()) {
            invalid.push("NAME");
        }
        if (!airFlowIsValid()) {
            invalid.push("AIRFLOW");
        }
        if (invalid.length > 0) {
            setIsInvalid(invalid);
            return false;
        }
        return true;
    };

    return (
        <>
            <Modal show={true} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>{room.name}</Modal.Header>
                <Modal.Body>
                    <Form className="d-grid gap-2">
                        <Row>
                            <Col>
                                <Form.Group controlId="name">
                                    <Form.Label>
                                        {t("pages.FLAT.apartments.room-modal.name")}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        isInvalid={isInvalid.includes("NAME")}
                                        placeholder={t("pages.FLAT.apartments.room-modal.name-placeholder") as string}
                                        type="text"
                                        value={fields.name}
                                        onChange={e => {
                                            if (isInvalid.includes("NAME")) {
                                                setIsInvalid((fieldIds: string[]) =>
                                                    fieldIds.filter(fieldId => fieldId !== "NAME")
                                                );
                                            }
                                            handleFieldChange(e);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group controlId="airFlow">
                                    <Form.Label>
                                        {t("pages.FLAT.apartments.room-modal.airflow")}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            isInvalid={isInvalid.includes("AIRFLOW")}
                                            placeholder={
                                                t("pages.FLAT.apartments.room-modal.airflow-placeholder") as string
                                            }
                                            type="number"
                                            value={fields.airFlow}
                                            onChange={e => {
                                                if (isInvalid.includes("AIRFLOW")) {
                                                    setIsInvalid((fieldIds: string[]) =>
                                                        fieldIds.filter(fieldId => fieldId !== "AIRFLOW")
                                                    );
                                                }
                                                handleFieldChange(e);
                                            }}
                                        />
                                        <InputGroup.Text>
                                            m<sup>3</sup>/h
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr />
                        <h5>
                            {t("pages.FLAT.apartments.room-modal.fixture")}
                            <ToolTipButton
                                eventId={"hover_project"}
                                size="sm"
                                tooltipMessage={t("pages.FLAT.apartments.room-modal.fixture-tooltip")}
                                tooltipId="buildings.roomFixturesLabel"
                                className="ms-2"
                            />
                        </h5>
                        <ListGroup>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="fixtures">
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {fields.fixtures.map((fixture, index) => (
                                                <Draggable
                                                    key={fixture.priority}
                                                    draggableId={`${fixture.type}${fixture.priority}`}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            className="card my-1"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            key={fixture.priority}
                                                        >
                                                            <div className="card-body py-2 m-0 d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <span className="text-muted">{`${
                                                                        index + 1
                                                                    }. `}</span>
                                                                    {t(`pages.FLAT.apartments.fixture.${fixture.type}`)}
                                                                </div>
                                                                <OverlayIconButton
                                                                    overlayText={t(
                                                                        "pages.FLAT.apartments.room-modal.remove-fixture"
                                                                    )}
                                                                    onClick={e => {
                                                                        e.stopPropagation();
                                                                        handleRemoveFixture(fixture);
                                                                    }}
                                                                >
                                                                    <Trash />
                                                                </OverlayIconButton>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <DropdownButton
                                variant="outline-primary"
                                title={t("pages.FLAT.apartments.room-modal.add-fixture")}
                                id="input-group-dropdown-1"
                            >
                                {Object.keys(FixtureType).map((fixture, index) => (
                                    <Dropdown.Item
                                        key={fixture}
                                        onClick={() => handleAddFixture(fixture as FixtureType)}
                                    >
                                        {t(`pages.FLAT.apartments.fixture.${fixture}`)}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </ListGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                    {!newRoom && (
                        <Button
                            variant="danger"
                            onClick={() => {
                                setShowConfirmDeleteModal(true);
                            }}
                        >
                            {t("buttons.delete")}
                        </Button>
                    )}
                    <Button variant="primary" onClick={handleSubmit} disabled={isInvalid.length > 0}>
                        {t("buttons.save")}
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                <ConfirmModal
                    show={showConfirmDeleteModal}
                    handleAction={() => {
                        handleRemoveRoom(fields);
                        handleClose();
                    }}
                    handleClose={() => {
                        setShowConfirmDeleteModal(false);
                    }}
                    description={t("pages.FLAT.apartments.room-modal.delete-title")}
                    confirmText={t("buttons.confirm")}
                />
            }
        </>
    );
};

export default RoomModal;
