import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LoggingContext } from "context";

const FooterContainer: React.FC = () => {
    const { currentVersion } = useContext(LoggingContext);
    return (
        <div className="d-flex justify-content-center bg-white rounded-sm" style={{ height: "1.5rem" }}>
            <div className="px-5 text-center">
                <small>@ 2021, Flux Automated Design</small>
            </div>
            <div className="px-5 text-center">
                <a href="https://www.flux-ad.dk/privacy-notice/" target="_blank" rel="noopener noreferrer">
                    <small>Privacy Notice</small>
                </a>
            </div>
            <div className="px-5 text-center">
                <Link to="/change-log">
                    <small>{`Version ${currentVersion}`}</small>
                </Link>
            </div>
        </div>
    );
};

export default FooterContainer;
