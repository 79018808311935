import { ThemeValuePair } from 'types';

export const EMPTY_THEME_VALUE_LIST = {
    name: '',
    code: 'T',
    definition: '',
};

export const EMPTY_THEME_VALUE_PAIR: ThemeValuePair = {
    id: '',
    value: '',
    code: '',
    preferred: false,
    themeValueListId: '',
};
