import React from "react";
import { ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LocationSpecificTemplate } from "types";
import LocationSpecificTemplateListItem from "./LocationSpecificTemplateListItem";

interface Props {
    diagrams: LocationSpecificTemplate[];
    handleDeleteDiagram: (sheetTemplate: LocationSpecificTemplate) => void;
}

const LocationSpecificTemplateList: React.FC<Props> = ({ diagrams, handleDeleteDiagram }) => {
    const { t } = useTranslation();

    return (
        <>
            <h5>{t("pages.FAST.sheet-list.create-sheets.location-specific.fields.sheets")}</h5>
            <ListGroup>
                {diagrams.length > 0 &&
                    diagrams.map((diagram, index) => (
                        <LocationSpecificTemplateListItem
                            key={`${diagram.title}-${index}`}
                            diagram={diagram}
                            handleDeleteDiagram={handleDeleteDiagram}
                        />
                    ))}
            </ListGroup>
        </>
    );
};

export default LocationSpecificTemplateList;
