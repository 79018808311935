import { Building, Storey } from "types";
import {
    BASEMENT_STOREY,
    FOUNDATION_STOREY,
    GROUND_STOREY,
    PILE_STOREY,
    ROOF_STOREY,
    StoreyCodes,
    LEVEL_STOREY,
} from "../_constants/storey.constants";
import { t } from "i18next";
import { API_URL } from "config";
import axios from "axios";

export const getStoreyByCode = (storeyCode: string, building: Partial<Building> | null = null): Storey => {
    if (!storeyCode || storeyCode === undefined) {
        return {
            ...LEVEL_STOREY,
            name: t(LEVEL_STOREY.name),
        };
    }
    if (storeyCode === StoreyCodes.GROUND_LEVEL) {
        return {
            ...GROUND_STOREY,
            name: t(GROUND_STOREY.name),
        };
    }

    if (storeyCode.includes(StoreyCodes.BASEMENT_LEVEL)) {
        if (storeyCode.slice(-1).toLowerCase() === "l") {
            return {
                ...BASEMENT_STOREY,
                code: storeyCode,
                name: "Kælder 1",
                number: -1,
                sortPriority: 0,
            };
        }
        const numberExtract = storeyCode.slice(-2).match(/\d+/);
        if (numberExtract && numberExtract.length > 0) {
            const number = parseInt(numberExtract[0]);
            return {
                code: storeyCode,
                name: `${t(BASEMENT_STOREY.name)} ${Math.abs(number)}`,
                number: -Math.abs(number),
                sortPriority: 0,
            };
        }
    }

    if (storeyCode === StoreyCodes.FOUNDATION_LEVEL) {
        return {
            ...FOUNDATION_STOREY,
            name: t(FOUNDATION_STOREY.name),
        };
    }

    if (storeyCode === StoreyCodes.PILE_FOUNDATION_LEVEL) {
        return {
            ...PILE_STOREY,
            name: t(PILE_STOREY.name),
        };
    }

    if (storeyCode === StoreyCodes.ROOF_LEVEL) {
        const topStorey =
            building !== null && building.numberOfStoreys && building.numberOfBasementStoreys
                ? building.numberOfStoreys + building.numberOfBasementStoreys + 1
                : 0;
        return {
            ...ROOF_STOREY,
            name: t(ROOF_STOREY.name),
            number: topStorey,
        };
    }
    const storeyMatch = storeyCode.match(/\d+/);
    if (!storeyCode.includes("X") && storeyMatch) {
        const number = parseInt(storeyMatch[0]);
        return {
            code: storeyCode,
            name: `${Math.abs(number)}. Etage`,
            number: Math.abs(number),
            sortPriority: 0,
        };
    }

    return {
        ...LEVEL_STOREY,
        name: t(LEVEL_STOREY.name),
    };
};

export const isNormalStorey = (storey: Storey): boolean => {
    const isFoundation = storey.code === StoreyCodes.FOUNDATION_LEVEL;
    const isPileFoundation = storey.code === StoreyCodes.PILE_FOUNDATION_LEVEL;
    const isRoof = storey.code === StoreyCodes.ROOF_LEVEL;
    const isSite = storey.code === StoreyCodes.SITE_LEVEL;
    return !isFoundation && !isPileFoundation && !isRoof && !isSite;
};

type IStoreyService = {
    getStoreyByCode: (storeyCode: string, building: Partial<Building> | null) => Storey;
    isNormalStorey: (storey: Storey) => boolean;
    getStoreys: (projectId: string, buildingId: string, accessToken: string) => Promise<Storey[]>;
    postStorey: (projectId: string, buildingId: string, storey: Storey, accessToken: string) => Promise<Storey>;
    putStorey: (projectId: string, buildingId: string, storey: Storey, accessToken: string) => Promise<Storey>;
    deleteStorey: (projectId: string, buildingId: string, storeyId: string, accessToken: string) => Promise<void>;
};

export const StoreyService: IStoreyService = {
    getStoreyByCode,
    isNormalStorey,
    getStoreys: async (projectId: string, buildingId: string, accessToken: string): Promise<Storey[]> => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/buildings/${buildingId}/storeys`;
        const response = await axios.get<Storey[]>(requestUrl, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.data;
    },
    postStorey: async (projectId: string, buildingId: string, storey: Storey, accessToken: string): Promise<Storey> => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/buildings/${buildingId}/storeys`;
        const response = await axios.post<Storey>(
            requestUrl,
            { storey },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        return response.data;
    },
    putStorey: async (projectId: string, buildingId: string, storey: Storey, accessToken: string): Promise<Storey> => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/buildings/${buildingId}/storeys/${storey.id}`;
        const response = await axios.put<Storey>(
            requestUrl,
            { storey },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        return response.data;
    },
    deleteStorey: async (projectId: string, buildingId: string, storeyId: string, accessToken: string) => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/buildings/${buildingId}/storeys/${storeyId}`;
        return await axios.delete(requestUrl, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    },
};
