import React, { useContext, useState } from "react";
import { Container, Button, Spinner, Row, Col } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { CompanyContext, UserContext } from "context";
import { User, UserCompanyRole } from "types";
import ConfirmModal from "../Shared/ConfirmModal";
import InviteCompanyUserModal from "./InviteCompanyUserModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UserService } from "services";
import { useAuth } from "hooks";
import _ from "lodash";
import toast from "react-hot-toast";
import CompanyUsersList from "./CompanyUsersList";

const CompanyUsers: React.FC = () => {
    const { user } = useContext(UserContext);
    const { company } = useContext(CompanyContext);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [showConfirmDeleteUserModal, setShowComfirmDeleteUserModal] = useState<boolean>(false);
    const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
    const { t } = useTranslation();
    const { getAccessToken } = useAuth();
    const queryClient = useQueryClient();

    const companyUsersQuery = useQuery({
        queryKey: ["companyUsers"],
        queryFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            if (!company) throw new Error("No company");
            return UserService.getCompanyUsers(company.id, accessToken);
        },
        enabled: !!company,
        select: data => data.map((user: Partial<User>) => ({ ...user, invitedUser: false })),
    });

    const companyInvitedUsersQuery = useQuery({
        queryKey: ["companyInvitedUsers"],
        queryFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            if (!company) throw new Error("No company");
            return UserService.getCompanyInvitedUsers(company.id, accessToken);
        },
        enabled: !!company,
        select: data => data.map((user: Partial<User>) => ({ ...user, invitedUser: true })),
    });

    const handleRemoveUser = (user: User) => {
        setSelectedUser(user);
        setShowComfirmDeleteUserModal(true);
    };

    const isAdminUser = user && user.role === UserCompanyRole.admin ? true : false;

    const removeCompanyUserMutation = useMutation({
        mutationFn: async (userId: string) => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            if (!company) throw new Error("No company");
            const removePromise = UserService.removeCompanyUser(company.id, userId, accessToken);
            toast.promise(removePromise, {
                loading: t("status.loading"),
                success: t("pages.company.users.remove-success"),
                error: t("pages.company.users.remove-error"),
            });
            setShowComfirmDeleteUserModal(false);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["companyUsers"]);
        },
    });

    return (
        <Container fluid className="p-4 h-100">
            {isAdminUser && (
                <>
                    <ConfirmModal
                        show={showConfirmDeleteUserModal}
                        handleClose={() => setShowComfirmDeleteUserModal(false)}
                        handleAction={() => {
                            removeCompanyUserMutation.mutate(selectedUser?.id as string);
                        }}
                        description={t("pages.company.users.title-remove")}
                        confirmText={t("buttons.confirm")}
                    />
                    <InviteCompanyUserModal show={showAddUserModal} handleClose={() => setShowAddUserModal(false)} />
                </>
            )}
            <h1>{t("pages.company.users.title")}</h1>
            <h6 className="text-muted">{t("pages.company.users.subtitle")}</h6>
            <Button
                className="my-3 d-inline-flex align-items-center"
                variant="primary"
                onClick={() => setShowAddUserModal(true)}
            >
                <Plus />
                <div className="ms-1">{t("pages.company.users.button-add-user")}</div>
            </Button>
            <Row className="h-75 overflow-auto">
                <Col>
                    <CompanyUsersList
                        users={companyUsersQuery.data?.concat(companyInvitedUsersQuery.data || []) || []}
                        isAdminUser={isAdminUser}
                        handleRemoveUser={handleRemoveUser}
                        isLoading={companyUsersQuery.isLoading || companyInvitedUsersQuery.isLoading}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default CompanyUsers;
