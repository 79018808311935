import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useContextModel } from "hooks";
import { LocationSpecificTemplate, AbstractBaseSheetTemplate } from "types";
import { CheckboxCard, Line, LineItem } from "../Shared/Layout";
import LocationSpecificTemplateModalForm from "../LocationSpecificTemplate/LocationSpecificTemplateModalForm";

interface Props {
    sheetTemplate: LocationSpecificTemplate;
    selectedDiagrams: AbstractBaseSheetTemplate[];
    handleAddDiagram: (sheetTemplate: LocationSpecificTemplate) => void;
    handleDeleteDiagram: (sheetTemplate: LocationSpecificTemplate) => void;
}

const LocationSpecificCheckboxCard: React.FC<Props> = ({
    sheetTemplate,
    selectedDiagrams,
    handleAddDiagram,
    handleDeleteDiagram,
}) => {
    const { t } = useTranslation();
    const [showDiagramConfigurator, setShowDiagramConfigurator] = useState(false);
    const selected = selectedDiagrams && selectedDiagrams.length > 0;
    const { getDisciplineById } = useContextModel();

    return (
        <>
            <CheckboxCard
                selected={selected}
                onClick={() => {
                    setShowDiagramConfigurator(prevState => !prevState);
                }}
            >
                <Line>
                    <LineItem grow className="user-select-none">
                        {sheetTemplate.title}{" "}
                        <span className="text-muted font-weight-lighter">
                            ({getDisciplineById(sheetTemplate.disciplineId)?.code})
                        </span>
                    </LineItem>
                    <LineItem className="mx-3">
                        {selected ? `${selectedDiagrams.length}` : ""} {selected ? t("status.added") : ""}
                    </LineItem>
                    <LineItem className="mx-3">
                        <Button variant="link" className="d-inline-flex align-items-center m-0 p-0">
                            <BoxArrowUpRight className="text-dark"></BoxArrowUpRight>
                        </Button>
                    </LineItem>
                </Line>
            </CheckboxCard>
            {showDiagramConfigurator ? (
                <LocationSpecificTemplateModalForm
                    selectedDiagrams={selectedDiagrams as LocationSpecificTemplate[]}
                    handleClose={() => setShowDiagramConfigurator(false)}
                    handleAddDiagram={handleAddDiagram}
                    handleDeleteDiagram={handleDeleteDiagram}
                    title={t("actions.add")}
                    sheetTemplate={sheetTemplate}
                />
            ) : null}
        </>
    );
};

export default LocationSpecificCheckboxCard;
