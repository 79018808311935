import React, { useContext } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "context";
import { COMPANY_NAVIGATION_MENU } from "_constants";
import { NavItem, NavGroup } from "../Shared/Navigation";

const CompanyNavigationDrawer: React.FC = () => {
    const { t } = useTranslation();
    const { company } = useContext(CompanyContext);
    return (
        <Nav className="transition h-100 bg-gray-100 border-right d-flex flex-column p-3" style={{ width: "16rem" }}>
            <NavGroup>
                <NavItem className="text-gray-800">{company?.name || ""}</NavItem>
            </NavGroup>
            <NavGroup className="flex-grow-1 m-0 p-0 mt-5">
                {COMPANY_NAVIGATION_MENU.map(menuItem => (
                    <Nav.Link
                        className="text-gray-800"
                        key={menuItem.url}
                        as={Link}
                        href={`#${menuItem.url}`}
                        to={`/${menuItem.url}`}
                    >
                        {t(menuItem.translationId)}
                    </Nav.Link>
                ))}
            </NavGroup>
        </Nav>
    );
};

export default CompanyNavigationDrawer;
