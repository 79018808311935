import React, { useContext } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { UserContext } from "context";
import { RevitParameter } from "types";
import { useTranslation } from "react-i18next";

interface Props {
    revitParameter: RevitParameter;
    handleEdit: (revitParameter: RevitParameter) => void;
}
const RevitParameterFormRow: React.FC<Props> = ({ handleEdit, revitParameter }) => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const isAdmin = user?.role === "admin";

    return (
        <Row className="mb-2">
            <Col xs={5}>
                <Form.Control
                    disabled={true}
                    size="sm"
                    placeholder="Indtast revit parameter navn"
                    value={revitParameter.revitParameterName}
                />
            </Col>
            <Col xs={5}>
                <Form.Control
                    disabled={true}
                    size="sm"
                    placeholder="Indtast parameter værdi"
                    value={revitParameter.parameterValue}
                />
            </Col>
            <Col xs={2}>
                <Button
                    size="sm"
                    variant="primary"
                    disabled={!isAdmin}
                    onClick={() => {
                        handleEdit(revitParameter);
                    }}
                >
                    {t("buttons.edit")}
                </Button>
            </Col>
        </Row>
    );
};

export default RevitParameterFormRow;
