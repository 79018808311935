import React, { useContext, useState } from "react";
import { Button, Form, Row, Card, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SerialNumberConfig } from "types";
import { ProjectContext, SyntaxContext } from "context";
import { useProjectId, useProject } from "hooks";
import NamingSyntaxModal from "../Project/NamingSyntaxModal";

const FastSettingsContainer: React.FC = () => {
    const [showSheetNamingModal, setShowSheetNamingModal] = useState(false);
    const { sheetNameSyntax, sheetSyntaxPreview, saveProjectSyntaxElements, selectProjectThemeValueList } =
        useContext(SyntaxContext);
    const projectId = useProjectId();
    const { project } = useProject(projectId);
    const { handleSaveProject } = useContext(ProjectContext);
    const { t } = useTranslation();

    const handleSaveSerialNumberConfig = (serialNumberConfig: SerialNumberConfig) => {
        const updatedProject = { ...project, serialNumberConfig };
        handleSaveProject(updatedProject);
    };

    return (
        <Container fluid className="m-0 p-4 w-100 h-100" style={{ overflowY: "auto" }}>
            <h1>{t("pages.FAST.settings.title")}</h1>
            <h6 className="text-muted">{t("pages.FAST.settings.subtitle")}</h6>
            <Row>
                <Card className="m-3" style={{ width: 400 }}>
                    <Card.Body>
                        {t("pages.FAST.settings.card-title.sheet-number")}
                        <Form.Control disabled value={sheetSyntaxPreview} />
                    </Card.Body>
                    <Button
                        className="m-1"
                        variant="secondary"
                        disabled={project?.archived || false}
                        onClick={() => {
                            setShowSheetNamingModal(true);
                        }}
                    >
                        {t("buttons.edit")}
                    </Button>
                </Card>
            </Row>
            {project === undefined ? (
                <p>{t("pages.FAST.settings.loading")}</p>
            ) : (
                <NamingSyntaxModal
                    handleReOpen={() => setShowSheetNamingModal(true)}
                    handleClose={() => setShowSheetNamingModal(false)}
                    show={showSheetNamingModal}
                    nameSyntax={sheetNameSyntax}
                    serialNumberConfig={project.serialNumberConfig}
                    handleSaveSerialNumberConfig={handleSaveSerialNumberConfig}
                    selectedThemeValueListId={project?.themeValueListId || ""}
                    saveSyntaxElements={saveProjectSyntaxElements}
                    selectThemeValueList={selectProjectThemeValueList}
                />
            )}
        </Container>
    );
};

export default FastSettingsContainer;
