import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AuthenticationContext } from "context";
import { logEvent } from "config";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useMutation } from "@tanstack/react-query";

interface Props {
    setShowEmailLoginForm: (showLoginForm: boolean) => void;
    setShowForgotPasswordForm: (showForgotPasswordForm: boolean) => void;
}

const LoginForm: React.FC<Props> = ({ setShowEmailLoginForm, setShowForgotPasswordForm }) => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [inputIsinvalid, setInputIsInvalid] = useState<boolean>(false);
    const { handleSignIn } = useContext(AuthenticationContext);
    const { t } = useTranslation();
    const emailRegex =
        /^((?:[A-Za-z0-9!#$%&'*+\-/=?^_`{|}~]|(?<=^|\.)"|"(?=$|\.|@)|(?<=".*)[ .](?=.*")|(?<!\.)\.){1,64})(@)((?:[A-Za-z0-9.-])*(?:[A-Za-z0-9])\.(?:[A-Za-z0-9]){2,})$/gm;
    const emailIsValid = emailRegex.test(email);
    const passwordIsValid = password !== "";

    useEffect(() => {
        if (inputIsinvalid) {
            setInputIsInvalid(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, password]);

    const handleSignInMutation = useMutation({
        mutationFn: async () => {
            await handleSignIn(email, password, rememberMe);
        },
    });

    const handleSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        if (emailIsValid && passwordIsValid) {
            handleSignInMutation.mutate();
            logEvent("login");
        } else {
            setInputIsInvalid(true);
        }
    };

    return (
        <div style={{ width: "25rem" }}>
            <h1 className="mb-4" onClick={() => setShowEmailLoginForm(false)} style={{ cursor: "pointer" }}>
                {"<-"}
            </h1>

            <h4>{t("pages.login.login-email.title")}</h4>
            <h6>{t("pages.login.login-email.subtitle")}</h6>
            <Form className="d-grid gap-2 mt-4">
                <Form.Group controlId="email">
                    <Form.Label>{t("pages.login.fields.email")}</Form.Label>
                    <Form.Control
                        isInvalid={!emailIsValid && inputIsinvalid}
                        required
                        type="email"
                        placeholder={t("pages.login.fields.email-placeholder") as string}
                        value={email}
                        onChange={event => {
                            setEmail(event.target.value);
                        }}
                    />
                </Form.Group>
                <Form.Label>{t("pages.login.fields.password")}</Form.Label>
                <InputGroup>
                    <Form.Control
                        id="password"
                        isInvalid={!passwordIsValid && inputIsinvalid}
                        onChange={event => {
                            setPassword(event.target.value);
                        }}
                        placeholder={t("pages.login.fields.password-placeholder") as string}
                        type={showPassword ? "text" : "password"}
                        value={password}
                    />
                    <InputGroup.Text onClick={() => setShowPassword(!showPassword)} style={{ cursor: "pointer" }}>
                        {showPassword ? <EyeSlash /> : <Eye />}
                    </InputGroup.Text>
                </InputGroup>
            </Form>
            <div className="mt-2 mb-4 d-flex justify-content-between">
                <div role="button">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip-remember-me">{t("pages.login.login-email.tooltip-remember")}</Tooltip>
                        }
                    >
                        <div style={{ cursor: "pointer" }} onClick={() => setRememberMe(!rememberMe)}>
                            <Form.Check>
                                <Form.Check.Input type="checkbox" checked={rememberMe} />
                                <Form.Check.Label>{t("pages.login.login-email.checkbox-remember")}</Form.Check.Label>
                            </Form.Check>
                        </div>
                    </OverlayTrigger>
                </div>
                <h6
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowForgotPasswordForm(true)}
                >
                    {t("pages.login.login-email.forgot-password-button")}
                </h6>
            </div>
            {handleSignInMutation.isError && <Alert variant="warning">{t("pages.login.status.login-error")}</Alert>}
            <div className="d-grid gap-2">
                <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={handleSignInMutation.isLoading || inputIsinvalid}
                >
                    {t("pages.login.login-email.button-login")}
                </Button>
            </div>
        </div>
    );
};

export default LoginForm;
