import React, { FC, InputHTMLAttributes, TextareaHTMLAttributes } from "react";
import { Form, Col, FormControlProps } from "react-bootstrap";
import { CheckCircleFill, ExclamationTriangleFill } from "react-bootstrap-icons";
import { EnergyRapportFields } from "types";
import { useEnergyRapport } from "../../hooks/use-energy-report";
import { FormEvent } from "../../hooks/use-form-fields";
import { useTranslation } from "react-i18next";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    fieldId: keyof EnergyRapportFields;
    label?: string;
    feedback?: string;
    required?: boolean;
    as?: FormControlProps["as"];
    rows?: TextareaHTMLAttributes<HTMLTextAreaElement>["rows"];
    isInvalid?: FormControlProps["isInvalid"];
}

interface SelectProps {
    fieldId: keyof EnergyRapportFields;
    label?: string;
    options: { key: string | number | boolean; value: string }[];
    handleChange?: (e: unknown) => void;
    feedback?: string;
}

export const ReportFieldsInputGroup: FC<InputProps> = ({ fieldId, feedback, label, required, value, ...props }) => {
    const { fields, handleFieldChange: handleChange } = useEnergyRapport();
    const isTouched = <T,>(value: T) => value !== undefined && value !== "";
    const fieldIsTouched = isTouched(fields[fieldId]);
    const { t } = useTranslation();
    return (
        <Form.Group as={Col} controlId={fieldId as string}>
            {label && (
                <Form.Label style={{ fontWeight: 500 }}>
                    {t(label)}
                    {required ? (
                        fieldIsTouched ? (
                            <CheckCircleFill className="ps-1 text-primary" />
                        ) : (
                            <ExclamationTriangleFill className="ps-1 text-muted" />
                        )
                    ) : null}
                </Form.Label>
            )}
            <Form.Control value={fields[fieldId] as string} onChange={handleChange} {...(props as FormControlProps)} />
            {feedback && <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>}
        </Form.Group>
    );
};

export const ReportFieldsSelectGroup: FC<SelectProps> = ({
    fieldId,
    label,
    feedback,
    options,
    handleChange,
    ...props
}) => {
    const { fields, handleFieldChange } = useEnergyRapport();
    const handleSelectOption = (index: number) => {
        const value = options[index].key;
        if (handleChange !== undefined) {
            handleChange(value);
            return;
        }
        const syntheticEvent = {
            target: {
                value,
                id: fieldId,
            },
        };
        handleFieldChange(syntheticEvent as FormEvent);
    };
    const value = (fields[fieldId] as string) || "";
    const { t } = useTranslation();
    return (
        <Form.Group as={Col} controlId={fieldId as string}>
            {label && <Form.Label style={{ fontWeight: 500 }}>{t(label)}</Form.Label>}
            <Form.Control
                value={value}
                onChange={e => handleSelectOption(Number(e.target.value))}
                as="select"
                {...props}
            >
                {options.map((option, i) => (
                    <option key={`${fieldId}-${i}`} value={i}>
                        {option.value || ""}
                    </option>
                ))}
            </Form.Control>
            {feedback && <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>}
        </Form.Group>
    );
};
