import { useContext, useCallback, useMemo } from 'react';
import { SyntaxContext } from 'context';
import { ISheet, IDocument } from 'types';
import { useBuildingStoreys, useProject, useProjectId, usePreviewSheetsNumber } from 'hooks';
import { StoreyCodes, SyntaxElementIds } from '_constants';
import { SheetService } from '../services/SheetServices';

type SheetsNamingProps = {
    sheetNumber: () => string;
    sheetTitle: () => string;
    previewSheetNumber: (previewSheet: ISheet) => string;
    sortBySheetNumber: (previewSheet: ISheet) => string;
}

export const useSheetsNaming = (sheet: ISheet): SheetsNamingProps => {
    const { sheetSyntaxElements } = useContext(SyntaxContext);
    const storeys = useBuildingStoreys(sheet.locationId);

    const { previewSheetNumber } = usePreviewSheetsNumber();
    const sortBySheetNumber = useCallback((sheet: ISheet) => SheetService.sortBySheetNumber(sheet, sheetSyntaxElements, storeys), [sheetSyntaxElements, storeys]);

    const showBuilding = sheet.locationId !== "";
    const showStory = sheet.storeyCode !== StoreyCodes.UNSPECIFIED_LEVEL;

    const sheetNumber = useCallback((): string => previewSheetNumber(sheet), [previewSheetNumber, sheet]);

    const sheetTitle = (): string => `${sheet.title}${showBuilding ? ", " + sheet.locationName : ''}${showStory ? ", " + sheet.storeyName : ''}`

    return { sheetNumber, sheetTitle, previewSheetNumber, sortBySheetNumber }
}

type DocumentsNamingHook = {
    documentNumber: () => string;
    documentTitle: () => string;
    previewDocumentNumber: (previewDocument: IDocument) => string;
}

export const useDocumentsNaming = (doc?: IDocument): DocumentsNamingHook => {
    const currentProjectId = useProjectId();
    const projectId = doc?.projectId || currentProjectId;
    const { project } = useProject(projectId);
    const { documentSyntaxElements } = useContext(SyntaxContext);

    const previewDocumentNumber = useCallback((previewDocument?: IDocument): string => {
        const stringBuilder: string[] = [];
        if (!documentSyntaxElements ||
            documentSyntaxElements.length === 0) {
            return '';
        }

        documentSyntaxElements.forEach(syntaxElement => {
            if (syntaxElement === SyntaxElementIds.PROJECTID) {
                const projectId = project?.number || 'IXXX';
                stringBuilder.push(projectId);
            }
            if (syntaxElement === SyntaxElementIds.KNOWLEDGEAREA) {
                const knowledgeCode = previewDocument?.knowledgeCode || "KXX"
                stringBuilder.push(knowledgeCode);
            }
            if (syntaxElement === SyntaxElementIds.DISCIPLINE) {
                const disciplineCode = previewDocument?.disciplineCode || "MXX"
                stringBuilder.push(disciplineCode);
            }
            if (syntaxElement === SyntaxElementIds.CATEGORY) {
                const category = `${previewDocument?.documentKindCategoryCode || 'CXX.'}.${previewDocument?.documentKindCode || 'X'}`
                stringBuilder.push(category);
            }
            if (syntaxElement === SyntaxElementIds.TITLE) {
                const title = `Z${previewDocument?.title || 'Ikke navngivet dokument'}`
                stringBuilder.push(title);
            }
        })

        return stringBuilder.join("_");
    }, [documentSyntaxElements, project]);

    const documentNumber = (): string => previewDocumentNumber(doc);

    const documentTitle = (): string => doc?.title || 'Ikke navngivet dokument';

    return { documentNumber, documentTitle, previewDocumentNumber }
}
