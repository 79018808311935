import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const DeleteButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = props => {
    const { t } = useTranslation();

    return (
        <Button {...props} className="text-danger" variant="outline">
            {t("buttons.delete")}
        </Button>
    );
};

export default DeleteButton;
