import React, { useState } from "react";
import { Card, Button, ListGroup } from "react-bootstrap";
import { DashCircle, PlusCircle, Trash, Plus, CheckCircleFill, Circle, PencilSquare } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { IRevision } from "context";
import { TODAY } from "_constants";
import { Line, LineItem, PseudoButton } from "../Shared/Layout";

interface Props {
    revisions: IRevision[];
    value: number;
    handleFieldChange: (name: string, revisions: IRevision[] | number | string) => void;
    handleOpenModal: (index: number) => void;
}

const RevisionDiagramCheckbox = ({ revisions, value, handleFieldChange, handleOpenModal }: Props): JSX.Element => {
    const { t } = useTranslation();
    const [show, setShow] = useState<boolean>(false);

    const handleClickAddRevision = () => {
        const revisionIndexes = !revisions || revisions.length === 0 ? [0] : revisions.map(r => r.index);
        const newRevisionIndex = Math.max(...revisionIndexes) + 1;
        const newRevision = {
            index: newRevisionIndex,
            name: `Rev. ${newRevisionIndex}`,
            date: TODAY,
        };
        handleFieldChange("revisions", [...revisions, newRevision]);
    };

    const handleClickDeleteRevision = (index: number) => {
        if (value === index) {
            handleFieldChange("selectedRevision", 0);
        }
        const filteredRevisions = revisions.filter(revision => revision.index !== index);
        handleFieldChange("revisions", filteredRevisions);
    };

    return (
        <>
            <Card className="my-1 transition">
                <Card.Body>
                    <Line>
                        <LineItem className="user-select-none" grow>
                            {revisions.length === 0
                                ? t("pages.FAST.sheet-list.export-sheet-list.fields.revision")
                                : revisions.find(rev => rev.index === value)
                                ? revisions.find(rev => rev.index === value)?.name
                                : t("status.empty-value")}
                        </LineItem>
                        <LineItem className="mx-3">
                            <Button
                                onClick={event => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    setShow(prevState => !prevState);
                                }}
                                variant="link"
                                className="d-inline-flex align-items-center m-0 p-0"
                            >
                                {show ? <DashCircle /> : <PlusCircle />}
                                {show ? (
                                    <div className="ms-1">
                                        {t("pages.FAST.sheet-list.export-sheet-list.fields.hide-revisions")}
                                    </div>
                                ) : (
                                    <div className="ms-1">
                                        {t("pages.FAST.sheet-list.export-sheet-list.fields.show-revisions")}
                                    </div>
                                )}
                            </Button>
                        </LineItem>
                    </Line>
                </Card.Body>
                {show && revisions.length !== 0 && (
                    <ListGroup className="list-group-flush">
                        {revisions &&
                            revisions.map((revision, index) => (
                                <ListGroup.Item
                                    action
                                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        handleFieldChange("selectedRevision", revision.index);
                                    }}
                                    key={index}
                                >
                                    <Line>
                                        <PseudoButton
                                            variant="link"
                                            className="d-inline-flex align-items-center m-0 p-0"
                                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                                event.stopPropagation();
                                                event.preventDefault();
                                                handleFieldChange("selectedRevision", revision.index);
                                            }}
                                        >
                                            {revision.index === value ? (
                                                <CheckCircleFill className="text-success mx-2" />
                                            ) : (
                                                <Circle className="text-dark mx-2" />
                                            )}
                                        </PseudoButton>
                                        <LineItem
                                            grow
                                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                                event.stopPropagation();
                                                event.preventDefault();
                                                handleFieldChange("selectedRevision", revision.index);
                                            }}
                                        >
                                            {revision.name} | <small className="text-muted">{revision.date}</small>
                                        </LineItem>
                                        <PseudoButton
                                            variant="link"
                                            className="d-inline-flex align-items-center m-0 p-0"
                                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                                event.stopPropagation();
                                                event.preventDefault();
                                                handleOpenModal(revision.index);
                                            }}
                                        >
                                            <PencilSquare className="mx-2" />
                                        </PseudoButton>
                                        <PseudoButton
                                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                                event.stopPropagation();
                                                event.preventDefault();
                                                handleClickDeleteRevision(revision.index);
                                            }}
                                            variant="link"
                                            className="d-inline-flex align-items-center m-0 p-0"
                                        >
                                            <Trash className="mx-2" />
                                        </PseudoButton>
                                    </Line>
                                </ListGroup.Item>
                            ))}
                    </ListGroup>
                )}
                {show ? (
                    <Card.Footer>
                        <Button
                            onClick={event => {
                                event.stopPropagation();
                                handleClickAddRevision();
                            }}
                            variant="link"
                            className="d-inline-flex align-items-center"
                        >
                            <Plus />
                            <div className="ms-1">{t("buttons.add")}</div>
                        </Button>
                    </Card.Footer>
                ) : null}
            </Card>
        </>
    );
};

export default RevisionDiagramCheckbox;
