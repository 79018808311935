import { ReactNode, useRef } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ChevronRight, ChevronLeft, Icon, CircleFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useHover, useGetPageContent } from "hooks";
import { INavigationMenuItem } from "_constants";

export const NavExpandButton = ({ expanded, handleToogle }: { expanded: boolean; handleToogle: () => void }) => (
    <Nav.Link
        className="d-flex justify-content-end position-absolute p-2 m-0 bg-secondary rounded-circle text-primary"
        onClick={handleToogle}
        style={{
            top: "1rem",
            right: "0",
            transform: "translateX(50%)",
            zIndex: 100,
        }}
    >
        {expanded ? <ChevronLeft /> : <ChevronRight />}
    </Nav.Link>
);

export const NavGroup = ({
    children,
    className,
    style,
    ...rest
}: {
    children?: ReactNode;
    className?: string;
    style?: Record<string, string | number>;
}) => (
    <div
        {...rest}
        className={`d-flex flex-column${className ? " " + className : ""}`}
        style={{ ...style, minHeight: "3rem" }}
    >
        {children}
    </div>
);

export const NavItem = ({
    children,
    className,
    icon,
    expanded,
    style,
    textWhite,
    selected,
    ...rest
}: {
    icon?: Icon;
    expanded?: boolean;
    children?: ReactNode;
    className?: string;
    style?: Record<string, string | number>;
    textWhite?: boolean;
    selected?: boolean;
    [args: string]: unknown;
}) => {
    const Icon = icon;
    const show = expanded === undefined || expanded === true;
    const ref = useRef(null);
    const hover = useHover(ref);

    return (
        <Nav.Link
            {...rest}
            ref={ref}
            style={{ ...style, minHeight: "3rem" }}
            className={`
            d-inline-flex align-items-center
            
            ${!textWhite && hover && "text-gray-primary"}
            ${!textWhite && selected && "text-primary"}
            ${!textWhite && !selected && !hover && "text-gray-800"}
            
            ${textWhite && !hover && "text-white"}
            ${textWhite && hover && "text-primary"}

            ${show ? "my-0 px-3 p-0" : "mx-0 p-0"}
            ${className ? " " + className : ""}
            `}
        >
            {Icon && (
                <Icon
                    className={`${!show ? "m-3" : "me-2 my-3"}`}
                    style={{
                        minHeight: "1.25rem",
                        minWidth: "1.25rem",
                    }}
                />
            )}
            {show && <>{children}</>}
        </Nav.Link>
    );
};

export const NavItemWithIndicator = ({
    subPage,
    children,
    disabled,
    url,
    active,
    ...rest
}: {
    subPage: INavigationMenuItem;
    active: boolean;
    disabled: boolean | undefined;
    url: string;
    children?: ReactNode;
    [arg: string]: unknown;
}) => {
    const { t } = useTranslation();
    const status = useGetPageContent(subPage.url);
    const Icon = () =>
        status === "EMPTY" ? (
            <CircleFill size="10" className="me-2 text-gray-400" />
        ) : (
            <CircleFill size="10" className="me-2 text-primary" />
        );
    return (
        <NavItem
            className={`${active ? "text-primary" : "text-gray-800"} ms-3`}
            as={Link}
            href={`#`}
            to={url}
            disabled={disabled}
            icon={Icon as unknown as Icon}
            {...rest}
        >
            {t(subPage.translationId)}
        </NavItem>
    );
};
