import React from "react";
import { useTranslation } from "react-i18next";
import { ListGroup, Button, Row, Col } from "react-bootstrap";
import { Building } from "types";
import sortBy from "lodash/sortBy";
import { ToolTipButton } from "../ToolTip/ToolTip";

interface Props {
    handleCreate: () => void;
    handleEdit: (building: Building) => void;
    buildings: Building[];
}
const FastBuildingList: React.FC<Props> = ({ handleCreate, handleEdit, buildings }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <h1>
                {t("pages.shared.locations.title")}
                <ToolTipButton
                    eventId="hover_buildings_list"
                    tooltipPlacement="right"
                    size="sm"
                    tooltipMessage={t("pages.shared.locations.title-help")}
                    tooltipId="buildings.headerButton"
                    className="ms-2"
                />
            </h1>
            {buildings && buildings.length > 0 && (
                <h6 className="text-muted">{t("pages.shared.locations.subtitle")}</h6>
            )}
            <ListGroup style={{ width: "30rem" }} className="mt-4">
                {buildings &&
                    sortBy(buildings, ["code", "name"]).map(building => (
                        <ListGroup.Item key={building.id} action onClick={() => handleEdit(building)}>
                            <b>{building.name}</b> - {building.code}{" "}
                            <i>{building.description ? ` - ${building.description}` : null}</i>
                        </ListGroup.Item>
                    ))}
            </ListGroup>
            <Button className="mt-2" variant="primary" onClick={handleCreate}>
                {t("buttons.create")}
            </Button>
        </>
    );
};

export default FastBuildingList;
