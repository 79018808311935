import React from "react";
import { Card } from "react-bootstrap";
import { CheckCircleFill, Circle } from "react-bootstrap-icons";
import { useHover } from "hooks";
import { Line, LineItem } from "../Layout";

interface FeatureButtonCardProps {
    feature: string;
    handleToggleProjectFeature: (feature: string) => void;
    selected: boolean;
    disabled?: boolean;
}

const FeatureToggleButton: React.FC<FeatureButtonCardProps> = ({
    feature,
    handleToggleProjectFeature,
    selected,
    disabled,
}) => {
    const hoverRef = React.useRef(null);
    const isHover = useHover(hoverRef);

    const handleToggle = () => {
        if (disabled) {
            return;
        }
        handleToggleProjectFeature(feature);
    };

    return (
        <Card
            ref={hoverRef}
            style={{ width: "140px", height: "45px" }}
            className={disabled ? "me-2 shadow-sm" : "me-2 transition-fast pointer shadow-sm"}
            onClick={handleToggle}
            border={disabled ? "undefined" : isHover ? "dark" : undefined}
            bg={disabled ? "gray-500" : isHover ? "light" : undefined}
        >
            <div className="mx-auto d-flex justify-content-center align-items-center">
                <Line className="text-primary m-2">
                    <LineItem>{feature}</LineItem>
                </Line>
                <div style={{ position: "absolute", right: "8px", top: "0px" }}>
                    {selected ? (
                        <CheckCircleFill size="12" className="text-success" />
                    ) : (
                        <Circle size="12" className="text-dark" />
                    )}
                </div>
            </div>
        </Card>
    );
};

export default FeatureToggleButton;
