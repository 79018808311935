import { useContext, useState } from "react";
import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserContext } from "context";
import { useFormFields, useContextModel } from "hooks";
import { ISheetTemplate } from "types";
import { isEmpty } from "lodash";
import SheetTemplateForm from "./SheetTemplateForm";
import ConfirmModal from "../../Shared/ConfirmModal";

interface Props {
    sheetTemplate: ISheetTemplate;
    handleCreate: (data: Omit<ISheetTemplate, "Id">) => void;
    handleUpdate: (data: ISheetTemplate) => void;
    handleDelete: (id: string) => void;
    handleClose: () => void;
}

const SheetTemplateModal: React.FC<Props> = ({
    sheetTemplate,
    handleCreate,
    handleUpdate,
    handleDelete,
    handleClose,
}) => {
    const { user } = useContext(UserContext);
    const [isInvalid, setIsInvalid] = useState<string[]>([]);
    const { fields, handleFieldChange, setFields } = useFormFields<ISheetTemplate>(sheetTemplate);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const newTemplate = sheetTemplate?.id === "" || sheetTemplate?.id === undefined;
    const isValid =
        !isEmpty(fields.title) &&
        !isEmpty(fields.disciplineId) &&
        !isEmpty(fields.sheetTypeId) &&
        !isEmpty(fields.storeyCode) &&
        !isEmpty(fields.knowledgeId);
    const { inCompanyContext } = useContextModel();
    const canEditFields = user?.role === "admin" || !inCompanyContext;
    const { t } = useTranslation();

    const handleSubmit = () => {
        const valid = validateFields();
        if (valid) {
            if (newTemplate) {
                handleCreate(fields);
            } else {
                handleUpdate(fields);
            }
            handleClose();
        }
    };

    const validateFields = (): boolean => {
        const invalid = [];
        const nameIsValid = () => fields.title !== "";
        const knowledgeIdIsValid = () => fields.knowledgeId !== "";
        const sheetTypeIdIsValid = () => fields.sheetTypeId !== "";
        if (!nameIsValid()) {
            invalid.push("TITLE");
        }
        if (!knowledgeIdIsValid()) {
            invalid.push("KNOWLEDGEID");
        }
        if (!sheetTypeIdIsValid()) {
            invalid.push("SHEETTYPEID");
        }
        if (invalid.length > 0) {
            setIsInvalid(invalid);
            return false;
        }
        return true;
    };

    return (
        <Modal size="lg" show={true} onHide={handleClose} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    {newTemplate ? `${t("pages.shared.templates.sheet.title-new")}` : `${sheetTemplate.title}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SheetTemplateForm
                    setFields={setFields}
                    fields={fields}
                    handleFieldChange={handleFieldChange}
                    setIsInvalid={setIsInvalid}
                    isInvalid={isInvalid}
                />
                {user?.role !== "admin" ? (
                    <Container>
                        <Row style={{ justifyContent: "flex-end" }}>
                            <Col style={{ textAlign: "end" }}>
                                <small className="text-muted">{t("form.only-admin-edit")}</small>
                            </Col>
                        </Row>
                    </Container>
                ) : null}
                {sheetTemplate?.id === "" && !isValid ? (
                    <Container>
                        <Row style={{ justifyContent: "flex-end" }}>
                            <Col style={{ textAlign: "end" }}>
                                <small className="text-muted">{t("form.fillout")}</small>
                            </Col>
                        </Row>
                    </Container>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("buttons.cancel")}
                </Button>
                {!newTemplate && (
                    <Button
                        disabled={!canEditFields}
                        variant="danger"
                        onClick={() => {
                            setShowConfirmModal(true);
                        }}
                    >
                        {t("buttons.delete")}
                    </Button>
                )}
                <Button variant="primary" disabled={isInvalid.length > 0 || !canEditFields} onClick={handleSubmit}>
                    {newTemplate ? `${t("buttons.create")}` : `${t("buttons.save")}`}
                </Button>
            </Modal.Footer>
            <ConfirmModal
                show={showConfirmModal}
                handleAction={() => {
                    handleDelete(fields.id);
                    setShowConfirmModal(false);
                    handleClose();
                }}
                handleClose={() => {
                    setShowConfirmModal(false);
                }}
                description={t("pages.shared.templates.sheet.delete-title")}
                confirmText={t("buttons.confirm")}
            />
        </Modal>
    );
};

export default SheetTemplateModal;
