import React, { useContext, useState } from "react";
import { Alert, Form, Row, Col, Button, FormGroup, FormControl } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext, ProjectContext, CompanyContext } from "context";
import { useAuth, useContextModel } from "hooks";
import { Project } from "types";
import sortBy from "lodash/sortBy";
import ConfirmModal from "../Shared/ConfirmModal";
import { ContainerWithStatusAlertHandling } from "../../hoc/withStatusAlerts";
import FormFieldWithAcceptButton from "../Shared/Form/FormFieldWithAcceptButton";
import { useQuery } from "@tanstack/react-query";
import { UserService } from "services";

interface ProjectSettingsFormProps {
    project: Project;
}

const ProjectSettingsForm: React.FC<ProjectSettingsFormProps> = ({ project }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [showConfirmDeleteProjectModal, setShowConfirmDeleteProjectModal] = useState<boolean>(false);
    const [showConfirmRestoreProjectModal, setShowConfirmRestoreProjectModal] = useState<boolean>(false);
    const { contextModel, stageStillExist, phaseStillExist } = useContextModel();
    const { company } = useContext(CompanyContext);
    const { error, userRemoved } = useContext(UserContext);
    const { handleSaveProject, handleDeleteProject } = useContext(ProjectContext);
    const { getAccessToken } = useAuth();

    const { data: users } = useQuery({
        queryKey: ["companyUsers"],
        queryFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            if (!company) throw new Error("No company");
            return UserService.getCompanyUsers(company.id, accessToken);
        },
        enabled: !!company,
    });

    const handleSaveField = (property: string, value: string | number) => {
        handleSaveProject({ ...project, [property]: value });
    };

    return (
        <ContainerWithStatusAlertHandling
            status={userRemoved}
            statusTitle={"Brugeren er blevet fjernet."}
            error={error}
            fluid
            className="m-0 p-0"
        >
            {project && (
                <>
                    {project.archived && (
                        <>
                            <Row>
                                <Col>
                                    <Alert variant="warning">
                                        {t("pages.shared.project.settings.warning-archived")}
                                    </Alert>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    <Button
                                        className="me-2"
                                        variant="danger"
                                        onClick={() => setShowConfirmDeleteProjectModal(true)}
                                    >
                                        {t("pages.shared.project.settings.button-delete")}
                                    </Button>
                                    <Button onClick={() => setShowConfirmRestoreProjectModal(true)}>
                                        {t("pages.shared.project.settings.button-restore")}
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                    <Row>
                        <Col xs="auto">
                            <Form className="d-grid gap-3 mt-4">
                                <FormGroup controlId="name">
                                    <Form.Label>
                                        {t("pages.shared.project.fields.name")}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <FormFieldWithAcceptButton
                                        initialValue={project.name}
                                        handleSaveField={value => handleSaveField("name", value)}
                                        permaDisabled={project.archived}
                                        validInputRegex={/^[\p{L}\p{N} !?=@#$£&%^&\-_]{1,}$/u}
                                        invalidInputMessage={t("pages.shared.project.settings.invalid-name") as string}
                                    />
                                </FormGroup>
                                <FormGroup controlId="number">
                                    <Form.Label>
                                        {t("pages.shared.project.fields.id")}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <FormFieldWithAcceptButton
                                        initialValue={project.number}
                                        handleSaveField={value => handleSaveField("number", value)}
                                        permaDisabled={project.archived}
                                        validInputRegex={/^[\p{L}\p{N} !?=@#$£&%^&\-_]{1,}$/u}
                                        invalidInputMessage={
                                            t("pages.shared.project.settings.invalid-project-id") as string
                                        }
                                    />
                                </FormGroup>
                                <FormGroup controlId="internalNumber">
                                    <Form.Label>
                                        {t("pages.shared.project.fields.internal-id")}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <FormFieldWithAcceptButton
                                        initialValue={project.internalNumber}
                                        handleSaveField={value => handleSaveField("internalNumber", value)}
                                        permaDisabled={project.archived}
                                        validInputRegex={/^[\p{L}\p{N} !?=@#$£&%^&\-_]{1,}$/u}
                                        invalidInputMessage={
                                            t("pages.shared.project.settings.invalid-internal-id") as string
                                        }
                                    />
                                </FormGroup>
                                <FormGroup controlId="projectleader">
                                    <Form.Label>{t("pages.shared.project.fields.project-leader")}</Form.Label>
                                    <Form.Select
                                        disabled={project.archived}
                                        onChange={e => handleSaveField("projectleader", e.target.value)}
                                        value={project.projectleader}
                                    >
                                        <option key="" value="">
                                            {t("status.empty-value")}
                                        </option>
                                        {sortBy(users, "name").map(user => (
                                            <option key={user.id} value={user.name}>
                                                {user.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </FormGroup>
                                <FormGroup controlId="client">
                                    <Form.Label>{t("pages.shared.project.fields.client")}</Form.Label>
                                    <FormFieldWithAcceptButton
                                        initialValue={project.client}
                                        handleSaveField={value => handleSaveField("client", value)}
                                        permaDisabled={project.archived}
                                    />
                                </FormGroup>
                                <Form.Group controlId="stage">
                                    <Form.Label>{t("pages.shared.project.fields.stage")}</Form.Label>
                                    <Form.Select
                                        disabled={project.archived}
                                        isInvalid={!stageStillExist(project?.stageId || "")}
                                        onChange={e => handleSaveField("stageId", e.target.value)}
                                        value={project.stageId}
                                    >
                                        <option key="" value="">
                                            {t("status.empty-value")}
                                        </option>
                                        {contextModel &&
                                            sortBy(contextModel.projectStages, ["code", "value"]).map(stage => (
                                                <option key={stage.id} value={stage.id}>
                                                    {`${stage.code} - ${stage.value}`}
                                                    {stage.preferred && " ★ "}
                                                </option>
                                            ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {t("status.value-deleted")}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="phase">
                                    <Form.Label>{t("pages.shared.project.fields.phase")}</Form.Label>
                                    <Form.Select
                                        disabled={project.archived}
                                        isInvalid={!phaseStillExist(project.phaseId || "")}
                                        onChange={e => handleSaveField("phaseId", e.target.value)}
                                        value={project.phaseId}
                                    >
                                        <option key="" value="">
                                            {t("status.empty-value")}
                                        </option>
                                        {contextModel &&
                                            sortBy(contextModel.projectPhases, ["code", "value"]).map(phase => (
                                                <option key={phase.id} value={phase.id}>
                                                    {`${phase.code} - ${phase.value}`}
                                                    {phase.preferred && " ★ "}
                                                </option>
                                            ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {t("status.value-deleted")}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </>
            )}

            <ConfirmModal
                show={showConfirmDeleteProjectModal}
                handleClose={() => setShowConfirmDeleteProjectModal(false)}
                handleAction={() => {
                    handleDeleteProject(project?.id);
                    setShowConfirmDeleteProjectModal(false);
                    history.push("/");
                }}
                description={`Are you sure you want to delete this project permanently?`}
                confirmText="Delete project"
            />
            <ConfirmModal
                show={showConfirmRestoreProjectModal}
                handleClose={() => setShowConfirmRestoreProjectModal(false)}
                handleAction={() => {
                    handleSaveProject({ ...project, archived: false });
                    setShowConfirmRestoreProjectModal(false);
                }}
                description={`Are you sure you want to restore this project?`}
                confirmText="Restore project"
            />
        </ContainerWithStatusAlertHandling>
    );
};

export default ProjectSettingsForm;
