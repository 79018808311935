import { logEvent } from "config";
import { AuthenticationContext } from "context";
import React, { useContext, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { CheckLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const ForgotPasswordForm: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [submitted, setSubmitted] = useState<boolean>(false);
    const { handleForgotPassword } = useContext(AuthenticationContext);
    const { t } = useTranslation();
    const emailRegex =
        /^((?:[A-Za-z0-9!#$%&'*+\-/=?^_`{|}~]|(?<=^|\.)"|"(?=$|\.|@)|(?<=".*)[ .](?=.*")|(?<!\.)\.){1,64})(@)((?:[A-Za-z0-9.-])*(?:[A-Za-z0-9])\.(?:[A-Za-z0-9]){2,})$/gm;
    const emailIsValid = emailRegex.test(email);
    const [inputIsinvalid, setInputIsInvalid] = useState<boolean>(false);

    const handleSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        //eslint-disable-next-line
        if (submitted) location.reload();
        if (emailIsValid) {
            if (submitted) return;
            handleForgotPassword(email);
            logEvent("resetPassword");
            setSubmitted(true);
        } else {
            setInputIsInvalid(true);
        }
    };

    return (
        <div style={{ width: "25rem" }}>
            <h4>{t("pages.login.forgot-password.title")}</h4>
            <h6>{t("pages.login.forgot-password.subtitle")}</h6>
            <Form className="d-grid gap-2 my-4">
                <Form.Group controlId="email">
                    <Form.Label>{t("pages.login.fields.email")}</Form.Label>
                    <Form.Control
                        isInvalid={!emailIsValid && inputIsinvalid}
                        required
                        type="email"
                        disabled={submitted}
                        placeholder={t("pages.login.fields.email-placeholder") as string}
                        value={email}
                        onChange={event => {
                            setEmail(event.target.value);
                        }}
                    />
                </Form.Group>
            </Form>
            {submitted && (
                <Alert variant="success">
                    {t("pages.login.forgot-password.submitted")} <CheckLg className="ms-2" />
                </Alert>
            )}
            <div className="d-grid gap-2">
                <Button
                    className="btn-block d-flex align-items-center justify-content-center"
                    onClick={handleSubmit}
                    // disabled={submitted}
                    variant={submitted ? "link" : "primary"}
                >
                    {!submitted ? t("pages.login.forgot-password.send-request") : <>Back to login page</>}
                </Button>
            </div>
        </div>
    );
};

export default ForgotPasswordForm;
