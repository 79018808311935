import React, { ChangeEvent, useState, useContext, useEffect } from "react";
import { Button, Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { PencilSquare } from "react-bootstrap-icons";
import { useQuery } from "@tanstack/react-query";
import { ApartmentsContext, BuildingsContext, ShaftsContext } from "context";
import { useFormFields, useBuildingStoreys, useShaftCalculations, useAuth, useProjectId } from "hooks";
import { v4 as uuidv4 } from "uuid";
import { Apartment, System, IShaftType, Storey, IShaftConnection } from "types";
import _ from "lodash";
import ShaftConnectionModal from "./ShaftConnectionModal";
import ConfirmModal from "../../Shared/ConfirmModal";
import OverlayIconButton from "../../Shared/Buttons/OverlayIconButton";
import { useTranslation } from "react-i18next";
import { SystemService } from "services/SystemService";

interface Props {
    shaft: IShaftType;
    handleClose: () => void;
}

const ShaftModal: React.FC<Props> = ({ shaft, handleClose }: Props) => {
    const { fields, handleFieldChange, setFields } = useFormFields<IShaftType>(shaft);
    const { handleCreateShaft, handleUpdateShaft, handleDeleteShaft, createShaftCalculation } =
        useContext(ShaftsContext);
    const projectId = useProjectId();
    const { getAccessToken } = useAuth();
    const { data: systems } = useQuery({
        queryKey: ["systems"],
        queryFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) {
                throw new Error("No access token found");
            }
            return SystemService.getSystems(projectId, accessToken);
        },
        enabled: !!projectId,
        select: response => response.data,
    });

    const { apartments } = useContext(ApartmentsContext);
    const { buildings } = useContext(BuildingsContext);
    const [selectedStoreyId, setSelectedStoreyId] = useState<string>("");
    const [showShaftConnectionModal, setShowShaftConnectionModal] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const storeys = useBuildingStoreys(fields.buildingId || "");
    const newShaft = fields.id === "";
    const { t } = useTranslation();
    const filteredBuildings = buildings.filter(building => building.id !== "UNKNOWN_BUILDING");
    const nameIsValid = fields.name !== "";
    const amountIsValid = fields.amount > 0;
    const buildingIsValid = fields.buildingId !== "";
    const [inputIsinvalid, setInputIsInvalid] = useState<boolean>(false);
    const { shaftCalculations } = useShaftCalculations(shaft);

    useEffect(() => {
        if (inputIsinvalid) {
            setInputIsInvalid(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields]);

    useEffect(() => {
        if (newShaft) {
            const systemIds = systems
                ?.filter(
                    (system, index, self) =>
                        self.findIndex(s => s.systemDiscipline === system.systemDiscipline) === index
                )
                .map(({ id }) => id) as string[];

            const storeyIds = storeys?.map(({ id }) => id) as string[];
            setFields(curFields => ({ ...curFields, systemIds, storeyIds }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newShaft, systems, storeys]);

    const handleChangeBuilding = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const buildingId = event.target.value;
        const shaftConnections = [] as IShaftConnection[];
        const storeyIds = [] as string[]; //TODO update to all available storeyIds
        setFields(prevFields => ({
            ...prevFields,
            buildingId,
            shaftConnections,
            storeyIds,
        }));
    };

    const handleStoreyCheckField = (storeyId: string) => {
        setFields(prevFields => {
            const curStoreyIds = prevFields.storeyIds;
            const storeyIds = _.xor(curStoreyIds, [storeyId]);
            const shaftConnections = prevFields.shaftConnections.filter(sc => sc.storeyId !== storeyId);
            return {
                ...prevFields,
                storeyIds,
                shaftConnections,
            };
        });
    };

    const handleSystemCheckField = (systemId: string) => {
        setFields(prevFields => {
            const curSystemIds = prevFields.systemIds;

            const selectedSystemDiscipline = systems?.find(system => system.id === systemId)?.systemDiscipline;

            const systemIdsToDisable = (systems || [])
                .filter(system => system.systemDiscipline === selectedSystemDiscipline)
                .map(system => system.id)
                .filter(id => id !== systemId);

            const cleanedSystemIds = curSystemIds.filter(id => !systemIdsToDisable.includes(id));

            const systemIds = _.xor(cleanedSystemIds, [systemId]);

            return {
                ...prevFields,
                systemIds,
            };
        });
    };

    const handleAddShaftConnection = (storeyId: string, apartmentType: Apartment) => {
        const uuid = uuidv4();
        const { rooms: connectedRooms } = apartmentType;
        const newConnection = {
            apartmentId: apartmentType.id,
            roomIds: connectedRooms.map(({ id }) => id),
            storeyId,
            shaftConnectionUID: uuid,
        } as IShaftConnection;

        const updatedConnections = fields.shaftConnections;
        updatedConnections.push(newConnection);
        setFields({ ...fields, shaftConnections: updatedConnections });
    };

    const handleRemoveShaftConnection = (scToRemove: IShaftConnection) => {
        const updatedConnections = fields.shaftConnections.filter(
            sc => !(sc.shaftConnectionUID === scToRemove.shaftConnectionUID)
        );
        setFields({ ...fields, shaftConnections: updatedConnections });
    };

    const handleAddAbstractShaftConnection = (storeyId: string) => {
        if (storeyId === undefined || storeyId === "") return;
        const uuid = uuidv4();
        const newConnection = {
            apartmentId: "",
            storeyId,
            shaftConnectionUID: uuid,
        } as IShaftConnection;
        const updatedConnections = fields.shaftConnections;
        updatedConnections.push(newConnection);
        setFields({ ...fields, shaftConnections: updatedConnections });
    };

    const handleRemoveAbstractShaftConnection = (storeyId: string) => {
        if (storeyId === undefined || storeyId === "") return;
        const storeyShaftConnections = fields.shaftConnections.filter(sc => sc.storeyId === storeyId);
        if (storeyShaftConnections.length <= 0) return;

        const newShaftConnections = fields.shaftConnections;
        const idx = newShaftConnections.findIndex(sc => sc.storeyId === storeyId && sc.apartmentId === "");
        newShaftConnections.splice(idx, 1);
        setFields(curFields => ({
            ...curFields,
            shaftConnections: newShaftConnections,
        }));
    };

    const numberOfShaftConnectionsOnStorey = (storeyId: string) =>
        fields.shaftConnections.filter(sc => sc.storeyId === storeyId).length;

    const handleRoomCheckField = (roomId: string, sc: IShaftConnection) => {
        const curShaftConnections = fields.shaftConnections;
        const curShaftConnection = curShaftConnections.find(
            shaftConnection => shaftConnection.shaftConnectionUID === sc.shaftConnectionUID
        );
        if (curShaftConnection === undefined) return;
        const curShaftConnectionRoomIds = curShaftConnection?.roomIds || [];
        const newShaftConnectionRoomIds = _.xor(curShaftConnectionRoomIds, [roomId]);
        curShaftConnection.roomIds = newShaftConnectionRoomIds;
        const newShaftConnections = [
            ...curShaftConnections.filter(
                shaftConnection => !(shaftConnection.shaftConnectionUID === sc.shaftConnectionUID)
            ),
            curShaftConnection,
        ];
        const newFields = { ...fields, shaftConnections: newShaftConnections };
        setFields(newFields);
    };

    const handleSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        if (nameIsValid && amountIsValid && buildingIsValid) {
            if (newShaft) {
                handleCreateShaft(fields);
            } else {
                if (shaftCalculations === undefined || shaftCalculations.length === 0) {
                    createShaftCalculation(fields.id);
                } else {
                    handleUpdateShaft(fields, shaftCalculations[0].id);
                }
            }
            handleClose();
        } else {
            setInputIsInvalid(true);
        }
    };

    return (
        <>
            <Modal show={true} onHide={handleClose} backdrop="static" size="lg">
                <Modal.Header closeButton>
                    {newShaft ? t("pages.FLAT.shafts.modal.title-new") : t("pages.FLAT.shafts.modal.title-edit")}
                </Modal.Header>
                <Modal.Body>
                    <Form className="d-grid gap-2">
                        <Row>
                            <Col>
                                <Form.Group controlId="name">
                                    <Form.Label>
                                        {t("pages.FLAT.shafts.modal.name")}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        isInvalid={inputIsinvalid && !nameIsValid}
                                        required
                                        placeholder={t("pages.FLAT.shafts.modal.name-placeholder") as string}
                                        type="text"
                                        value={fields.name}
                                        onChange={e => handleFieldChange(e)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="amount">
                                    <Form.Label>
                                        {t("pages.FLAT.shafts.modal.quantity")}
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        isInvalid={inputIsinvalid && !amountIsValid}
                                        required
                                        type="number"
                                        value={fields.amount}
                                        onChange={e => handleFieldChange(e)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group controlId="buildingId">
                            <Form.Label>
                                {t("pages.FLAT.shafts.modal.building")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                                isInvalid={inputIsinvalid && !buildingIsValid}
                                required
                                defaultValue={fields.buildingId}
                                onChange={e => {
                                    handleChangeBuilding(e as ChangeEvent<HTMLSelectElement>);
                                }}
                            >
                                <option value="">{t("pages.FLAT.shafts.modal.building-option")}</option>
                                {buildings.length <= 0 && (
                                    <option key={""} value={""}>
                                        {t("pages.FLAT.shafts.modal.building-missing")}
                                    </option>
                                )}
                                {filteredBuildings &&
                                    filteredBuildings.map(building => (
                                        <option
                                            key={building.id}
                                            value={building.id}
                                        >{`${building.name} - ${building.code}`}</option>
                                    ))}
                            </Form.Select>
                        </Form.Group>
                    </Form>
                    <Row>
                        <Col>
                            <StoreyList
                                apartments={apartments}
                                storeys={storeys}
                                fields={fields}
                                setSelectedStoreyId={setSelectedStoreyId}
                                setShowShaftConnectionModal={setShowShaftConnectionModal}
                                handleStoreyCheckField={handleStoreyCheckField}
                                numberOfShaftConnectionsOnStorey={numberOfShaftConnectionsOnStorey}
                                handleAddAbstractShaftConnection={handleAddAbstractShaftConnection}
                                handleRemoveAbstractShaftConnection={handleRemoveAbstractShaftConnection}
                            />
                        </Col>
                        <Col>
                            <SystemsList
                                availableSystems={systems || []}
                                selectedSystemIds={fields.systemIds}
                                handleSystemCheckField={handleSystemCheckField}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                    {!newShaft && (
                        <Button
                            variant="danger"
                            onClick={() => {
                                setShowConfirmModal(true);
                            }}
                        >
                            {t("buttons.delete")}
                        </Button>
                    )}
                    <Button variant="primary" onClick={handleSubmit} disabled={inputIsinvalid}>
                        {newShaft ? t("buttons.create") : t("buttons.save")}
                    </Button>
                </Modal.Footer>
            </Modal>
            {showShaftConnectionModal && (
                <ShaftConnectionModal
                    handleRoomCheckField={handleRoomCheckField}
                    handleRemoveShaftConnection={handleRemoveShaftConnection}
                    handleAddShaftConnection={handleAddShaftConnection}
                    shaft={fields}
                    selectedStoreyId={selectedStoreyId}
                    handleClose={() => setShowShaftConnectionModal(false)}
                />
            )}
            {
                <ConfirmModal
                    show={showConfirmModal}
                    handleAction={() => {
                        handleDeleteShaft(fields.id);
                        handleClose();
                    }}
                    handleClose={() => {
                        setShowConfirmModal(false);
                    }}
                    description={t("pages.FLAT.shafts.confirm-delete-title")}
                    confirmText={t("buttons.confirm")}
                />
            }
        </>
    );
};

export default ShaftModal;

interface StoreyListProps {
    apartments: Apartment[];
    storeys: Storey[];
    fields: IShaftType;
    setSelectedStoreyId: (id: string) => void;
    setShowShaftConnectionModal: (displayState: boolean) => void;
    handleStoreyCheckField: (id: string) => void;
    numberOfShaftConnectionsOnStorey: (storeyId: string) => number;
    handleAddAbstractShaftConnection: (storeyId: string) => void;
    handleRemoveAbstractShaftConnection: (storeyId: string) => void;
}

const StoreyList: React.FC<StoreyListProps> = ({
    apartments,
    storeys,
    fields,
    setSelectedStoreyId,
    setShowShaftConnectionModal,
    handleStoreyCheckField,
    numberOfShaftConnectionsOnStorey,
}) => {
    const connectionsOnStorey = (storeyId: string | undefined) => {
        if (storeyId === undefined) return [];
        return fields.shaftConnections
            .filter(con => con.storeyId === storeyId && con.apartmentId !== "")
            .map(({ apartmentId }) => apartments.find(ap => ap.id === apartmentId)?.name);
    };

    return (
        <div className="mt-2">
            <h6>Storeys:</h6>
            <ListGroup>
                {storeys &&
                    storeys.map(storey => (
                        <ListGroup.Item
                            className="d-inline-flex justify-content-between align-items-center"
                            key={storey.id}
                            variant={fields.storeyIds.includes(storey.id || "") ? "" : "secondary"}
                        >
                            {connectionsOnStorey(storey.id).length === 0
                                ? `${storey.name}`
                                : `${storey.name} - ${connectionsOnStorey(storey.id).join(", ")}`}
                            <div className="d-inline-flex align-items-center">
                                {fields.storeyIds.includes(storey.id ?? "") && (
                                    <div className="d-inline-flex align-items-center">
                                        <OverlayIconButton
                                            className="p-0 m-0 lh-1"
                                            overlayText="Add apartment connection"
                                            onClick={e => {
                                                e.stopPropagation();
                                                if (!fields.storeyIds.includes(storey.id || "")) return;
                                                setSelectedStoreyId(storey.id || "");
                                                setShowShaftConnectionModal(true);
                                            }}
                                        >
                                            <PencilSquare className="p-0 m-0" />
                                        </OverlayIconButton>
                                        <div className="mx-2">{numberOfShaftConnectionsOnStorey(storey.id ?? "")}</div>
                                    </div>
                                )}
                                <Form.Check
                                    className="m-0"
                                    id={storey.id}
                                    key={storey.id}
                                    type="switch"
                                    checked={fields.storeyIds.includes(storey.id || "")}
                                    onChange={() => handleStoreyCheckField(storey.id || "")}
                                />
                            </div>
                        </ListGroup.Item>
                    ))}
            </ListGroup>
        </div>
    );
};

const SystemsList: React.FC<{
    availableSystems: System[];
    selectedSystemIds: string[];
    handleSystemCheckField: (id: string) => void;
}> = ({ availableSystems, selectedSystemIds, handleSystemCheckField }) => {
    const { t } = useTranslation();

    return (
        <div className="mt-2">
            <h6>{t("pages.FLAT.shafts.modal.systems")}</h6>
            <ListGroup>
                {availableSystems &&
                    availableSystems.map(system => (
                        <ListGroup.Item
                            key={system.id}
                            variant={selectedSystemIds.includes(system.id) ? "" : "secondary"}
                        >
                            <span>{system.name}</span>
                            <span className="text-muted">
                                {` - ${t(`pages.FLAT.systems.disciplines.${system.systemDiscipline}`)}`}
                            </span>
                            <span style={{ float: "right" }}>
                                <Form.Check
                                    id={system.id}
                                    key={system.id}
                                    type="switch"
                                    checked={selectedSystemIds.includes(system.id)}
                                    onChange={() => handleSystemCheckField(system.id)}
                                />
                            </span>
                        </ListGroup.Item>
                    ))}
            </ListGroup>
        </div>
    );
};
