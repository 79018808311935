import React, { useContext, useState } from "react";
import { Button, Col, ListGroup, Row, Spinner } from "react-bootstrap";
import { PersonXFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { UserContext } from "context";
import { useProject, useProjectId, useUser } from "hooks";
import { User } from "types";
import { sortBy } from "lodash";
import ConfirmModal from "../Shared/ConfirmModal";
import { ContainerWithStatusAlertHandling } from "../../hoc/withStatusAlerts";
import AddUserToProjectModalForm from "./AddUserToProjectModalForm";

const ProjectUsersContainer: React.FC = () => {
    const { t } = useTranslation();
    const [showConfirmDeleteUserModal, setShowComfirmDeleteUserModal] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const projectId = useProjectId();
    const { error, userRemoved, loading, removeUserFromProject, addProjectToUser } = useContext(UserContext);
    const { user, isAdmin } = useUser();
    const { project, users } = useProject(projectId);

    const isAllowedToAddUsers = isAdmin || project?.owner === user.id;

    const handleRemoveUser = async () => {
        if (!selectedUser || !project) {
            return;
        }
        const { id } = selectedUser;
        await removeUserFromProject(project.id, id);
        setShowComfirmDeleteUserModal(false);
    };

    return (
        <ContainerWithStatusAlertHandling
            className="m-0 ps-4 pt-4 h-100"
            fluid
            status={userRemoved}
            error={error}
            loading={loading}
            loadingMessage={"Henter brugere..."}
            style={{ overflowY: "auto" }}
        >
            <Row>
                <Col>
                    <h1>{t("pages.shared.users.title")}</h1>
                    <h6 className="text-muted">Manage users connected to this project</h6>
                    {loading && users && users.length === 0 ? (
                        <>
                            <Spinner animation="grow" />
                            <h6>{t("status.loading")}</h6>
                        </>
                    ) : (
                        <ListGroup variant="flush">
                            {users &&
                                sortBy(users, "name").map(user => (
                                    <ListGroup.Item key={user.id} className="px-0">
                                        {user.name} <i>- {user.email}</i>
                                        {users.length > 1 && (
                                            <Button
                                                style={{ float: "right" }}
                                                size="sm"
                                                disabled={project?.archived || false}
                                                variant="danger"
                                                onClick={() => {
                                                    setSelectedUser(user);
                                                    setShowComfirmDeleteUserModal(true);
                                                }}
                                            >
                                                <PersonXFill />
                                            </Button>
                                        )}
                                    </ListGroup.Item>
                                ))}
                        </ListGroup>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    {isAllowedToAddUsers && (
                        <Button
                            variant="primary"
                            disabled={project?.archived || false}
                            onClick={() => {
                                setShowAddUserModal(true);
                            }}
                        >
                            {t("buttons.add")}
                        </Button>
                    )}
                </Col>
            </Row>
            <ConfirmModal
                show={showConfirmDeleteUserModal}
                handleClose={() => setShowComfirmDeleteUserModal(false)}
                handleAction={() => handleRemoveUser()}
                description={t("pages.shared.users.confirm-delete", { name: selectedUser?.name })}
                confirmText={t("buttons.delete")}
            />
            {showAddUserModal && (
                <AddUserToProjectModalForm
                    project={project}
                    handleClose={() => setShowAddUserModal(false)}
                    handleSave={(userId: string, projectId: string) => {
                        setShowAddUserModal(false);
                        addProjectToUser(userId, projectId);
                    }}
                />
            )}
        </ContainerWithStatusAlertHandling>
    );
};

export default ProjectUsersContainer;
