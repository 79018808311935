import { useContextModel, useAgGrid, useAuth } from "hooks";
import React, { useState } from "react";
import { Button, Dropdown, Row, Spinner, Col, ButtonGroup, InputGroup, FormControl } from "react-bootstrap";
import { Download, ListCheck, ListOl, Pencil, Plus, Search, ThreeDotsVertical, Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import SheetsModalForm from "./SheetsModalForm";
import SheetModalForm from "./SheetModalForm";
import { logEvent } from "config";
import ConfirmModal from "../Shared/ConfirmModal";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ExportModal from "./ExportModal";
import OverlayIconButton from "components/Shared/Buttons/OverlayIconButton";
import CreateSheetsModal from "components/SheetsConfigurator/CreateSheetsModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SheetService } from "services";
import toast from "react-hot-toast";
import { ISheet } from "types";
import _ from "lodash";

interface Props {
    buildingsExist: boolean;
    projectId: string;
}

const TableContainer: React.FC<Props> = ({ buildingsExist, projectId }) => {
    const { loadingModel } = useContextModel();
    const [showMultipleSheetsEditModal, setShowMultipleSheetsEditModal] = useState(false);
    const [showSingleSheetEditModal, setShowSingleSheetEditModal] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
    const [showCreateSheetsModal, setShowCreateSheetsModal] = useState<boolean>(false);
    const [showConfirmResetModal, setShowConfirmResetModal] = useState<boolean>(false);
    const [showConfirmUpdateModal, setShowConfirmUpdateModal] = useState<boolean>(false);
    const [showExportModal, setShowExportModal] = useState<boolean>(false);
    const [sheets, setSheets] = useState<ISheet[]>([]);
    const { getAccessToken } = useAuth();
    const gridRef = React.useRef<AgGridReact>(null);
    const { gridOptions, onFilterTextBoxChanged, getSelectedRowIds, numSelectedRows } = useAgGrid(gridRef);
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    useQuery({
        queryKey: ["sheets"],
        queryFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            return SheetService.getSheets(projectId, accessToken);
        },
        onSuccess: data => {
            const orderedSheets = _.orderBy(data, ["serialNumber"], ["asc"]);
            setSheets(orderedSheets);
            gridRef.current?.api?.setRowData(orderedSheets);
        },
        onError: () => {
            toast.error(t("pages.FAST.sheet-list.error-fetching-sheets"));
        },
    });

    const { data: doesSheetsHaveMismatchingProjectIds } = useQuery({
        queryKey: ["mismatchingProjectIdsOnSheets"],
        queryFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            return SheetService.mismatchProjectIdsOnSheets(projectId, accessToken);
        },
    });

    const handleOpenSheetEditor = () => {
        if (getSelectedRowIds() === undefined || getSelectedRowIds()?.length === 0) return;

        if (getSelectedRowIds()?.length === 1) {
            setShowSingleSheetEditModal(true);
        } else {
            setShowMultipleSheetsEditModal(true);
        }
    };

    const deleteRowMutation = useMutation({
        mutationFn: async () => {
            const selectedRowIds = getSelectedRowIds();
            if (selectedRowIds === undefined || selectedRowIds?.length === 0) return;
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            const promise = SheetService.deleteSheets(projectId, selectedRowIds, accessToken);
            toast.promise(promise, {
                loading: t("status.loading"),
                success: t("pages.FAST.sheet-list.delete-sheets.success"),
                error: t("pages.FAST.sheet-list.delete-sheets.error"),
            });
            return promise;
        },
        onSuccess: () => {
            const selectedData = gridRef.current?.api?.getSelectedRows();
            gridRef.current?.api?.applyTransactionAsync({ remove: selectedData });
            gridRef.current?.api?.deselectAll();
            setShowConfirmDeleteModal(false);
        },
    });

    const resetSheetsSerialMutation = useMutation({
        mutationFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            const promise = SheetService.recalculateSheetsSerial(projectId, accessToken);
            toast.promise(promise, {
                loading: t("status.loading"),
                success: t("pages.FAST.sheet-list.reset-serial-sheets.success"),
                error: t("pages.FAST.sheet-list.reset-serial-sheets.error"),
            });
            return promise;
        },
        onSuccess: () => {
            setShowConfirmResetModal(false);
            queryClient.invalidateQueries(["sheets"]);
        },
    });

    const updateProjectIdsMutation = useMutation({
        mutationFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            const updatePromise = SheetService.updateProjectIdsOnSheets(projectId, accessToken);
            toast.promise(updatePromise, {
                loading: t("status.loading"),
                success: t("pages.FAST.sheet-list.update-sheets.success"),
                error: t("pages.FAST.sheet-list.update-sheets.error"),
            });
            return updatePromise;
        },
        onSuccess: () => {
            setShowConfirmUpdateModal(false);
            queryClient.invalidateQueries(["sheets"]);
        },
    });

    return (
        <>
            <Row className="justify-content-between my-2">
                <Col>
                    <InputGroup size="sm" style={{ maxWidth: "300px", minWidth: "200px" }}>
                        <InputGroup.Text>
                            <Search />
                        </InputGroup.Text>
                        <FormControl
                            placeholder={t("pages.FAST.sheet-list.search-placeholder") as string}
                            onChange={e => {
                                onFilterTextBoxChanged(e.target.value);
                            }}
                        />
                    </InputGroup>
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                    <Button
                        size="sm"
                        className="d-inline-flex align-items-center me-2 my-2"
                        disabled={!buildingsExist}
                        onClick={() => {
                            setShowCreateSheetsModal(true);
                            logEvent("sheet_create");
                        }}
                    >
                        <Plus size={21} className="me-1" />
                        {t("pages.FAST.sheet-list.buttons.create-sheets")}
                    </Button>
                    <OverlayIconButton
                        className="d-inline-flex align-items-center"
                        size="sm"
                        disabled={numSelectedRows === 0}
                        overlayText={t("pages.FAST.sheet-list.edit-sheet.buttons.tooltip")}
                        onClick={handleOpenSheetEditor}
                    >
                        <Pencil size={20} />
                    </OverlayIconButton>
                    <OverlayIconButton
                        className="d-inline-flex align-items-center"
                        size="sm"
                        disabled={numSelectedRows === 0}
                        overlayText={t("pages.FAST.sheet-list.delete-sheets.button")}
                        onClick={() => {
                            setShowConfirmDeleteModal(true);
                        }}
                    >
                        <Trash size={20} />
                    </OverlayIconButton>

                    <Dropdown drop="end">
                        <Dropdown.Toggle variant="link" className="hide-caret" id="sheet-options-dropdown">
                            <ThreeDotsVertical size={20} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                className="d-inline-flex align-items-center"
                                onClick={() => {
                                    setShowExportModal(true);
                                    logEvent("docsheet_export");
                                }}
                            >
                                <Download />
                                <div className="ms-2">{t("pages.FAST.sheet-list.buttons.export-sheetlist")}</div>
                            </Dropdown.Item>
                            <Dropdown.Item
                                className="d-inline-flex align-items-center"
                                onClick={() => setShowConfirmResetModal(true)}
                            >
                                <ListOl />
                                <span className="ms-2">{t("pages.FAST.sheet-list.reset-serial-sheets.button")}</span>
                            </Dropdown.Item>
                            {doesSheetsHaveMismatchingProjectIds && (
                                <Dropdown.Item
                                    style={{ color: "red" }}
                                    className="d-inline-flex align-items-center"
                                    onClick={() => {
                                        setShowConfirmUpdateModal(true);
                                    }}
                                >
                                    <ListCheck />
                                    <div className="ms-2">{t("pages.FAST.sheet-list.update-sheets.button")}</div>
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            {loadingModel ? (
                <div className="d-flex justify-content-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : (
                <div className="w-100 h-100">
                    <div className="ag-theme-alpine w-100 h-100">
                        <AgGridReact
                            ref={gridRef}
                            gridOptions={gridOptions}
                            overlayNoRowsTemplate={"No sheets has been created."}
                        />
                    </div>
                </div>
            )}
            {showMultipleSheetsEditModal && (
                <SheetsModalForm
                    handleClose={() => setShowMultipleSheetsEditModal(false)}
                    sheetIds={getSelectedRowIds()}
                />
            )}
            {showSingleSheetEditModal && (
                <SheetModalForm
                    handleClose={() => setShowSingleSheetEditModal(false)}
                    sheetId={getSelectedRowIds()[0]}
                />
            )}
            {showCreateSheetsModal && (
                <CreateSheetsModal
                    title={t("pages.FAST.sheet-list.create-sheets.title")}
                    handleClose={() => setShowCreateSheetsModal(false)}
                    projectId={projectId}
                    fetchData={() => queryClient.invalidateQueries(["sheets"])}
                />
            )}
            <ConfirmModal
                buttonsDisabled={resetSheetsSerialMutation.isLoading}
                show={showConfirmResetModal}
                handleAction={resetSheetsSerialMutation.mutate}
                handleClose={() => setShowConfirmResetModal(false)}
                description={t("pages.FAST.sheet-list.reset-serial-sheets.warning-text")}
                confirmText={
                    resetSheetsSerialMutation.isLoading ? t("messages.loading") : t("messages.warning.confirm")
                }
            />
            <ConfirmModal
                buttonsDisabled={updateProjectIdsMutation.isLoading}
                show={showConfirmUpdateModal}
                handleAction={updateProjectIdsMutation.mutate}
                handleClose={() => setShowConfirmUpdateModal(false)}
                description={t("pages.FAST.sheet-list.update-sheets.warning-text")}
                confirmText={updateProjectIdsMutation.isLoading ? t("messages.loading") : t("messages.warning.confirm")}
            />
            <ExportModal show={showExportModal} projectId={projectId} handleClose={() => setShowExportModal(false)} />
            <ConfirmModal
                show={showConfirmDeleteModal}
                handleAction={deleteRowMutation.mutate}
                buttonsDisabled={deleteRowMutation.isLoading}
                handleClose={() => setShowConfirmDeleteModal(false)}
                description={t("pages.FAST.sheet-list.delete-sheets.warning-text")}
                confirmText={deleteRowMutation.isLoading ? t("messages.loading") : t("buttons.delete")}
            />
        </>
    );
};

export default TableContainer;
