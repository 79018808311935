import React, { createContext, useState, ReactNode, useContext, useEffect, useCallback } from "react";
import { Company, RevitParameter, SerialNumberConfig, User } from "types";
import { LoggingContext } from "context";
import { DEFAULT_SERIALNUMBERCONFIG } from "_constants";
import axios from "axios";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { collection, doc, onSnapshot, setDoc, addDoc, deleteDoc } from '@firebase/firestore';
import { API_URL, firestore, storage } from "config";
import { ErrorData } from "@firebase/util";
import { getDocs, updateDoc } from "firebase/firestore";
import imgNologo from "../images/nologo.png"
import { useAuth } from 'hooks';

interface ICompanyContext {
    loading: boolean;
    setLoading: (isLoading: boolean) => void;
    error: Error | null;
    setError: (error: Error | null) => void;
    status: string;
    company: Company | undefined;
    updateCompany: (companyId: string, data: Partial<Company>) => Promise<void>;
    uploadCompanyLogo: (file: File) => Promise<void>;
    deleteCompanyLogo: () => Promise<void>;
    logoUrl: string;
    companyHasLogo: boolean;
    createRevitParameter: (data: Partial<RevitParameter>) => Promise<void>;
    updateRevitParameter: (data: Partial<RevitParameter>) => Promise<void>;
    deleteRevitParameter: (id: string) => Promise<void>;
    resetCompanyNamingSyntaxAndSerialNumberConfig: () => Promise<void>;
    handleUpdateSerialNumberConfig: (serialNumberConfig: SerialNumberConfig) => Promise<void>;
    revitParameters: RevitParameter[];
}

export const CompanyContext = createContext({} as ICompanyContext);

interface Props {
    children?: ReactNode;
}

const CompanyProvider = ({ children }: Props) => {
    const { logger } = useContext(LoggingContext);
    const [company, setCompany] = useState<Company | undefined>();
    const [revitParameters, setRevitParameters] = useState<RevitParameter[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [status, setStatus] = useState<string>("");
    const [loading, setLoading] = useState(true);
    const [logoUrl, setLogoUrl] = useState<string>(imgNologo);
    const [companyHasLogo, setCompanyHasLogo] = useState<boolean>(false);
    const { user: firebaseUser, getAccessToken } = useAuth();

    useEffect(() => {
        if (!firebaseUser) return;
        const userRef = doc(firestore, "users", firebaseUser.uid);

        setLoading(true);

        const unsubscribe = onSnapshot(userRef, documentSnapshot => {
            if (!documentSnapshot.exists()) {
                setLoading(false);
                return;
            }
            const user = documentSnapshot.data() as User;
            if (!user.company) {
                setLoading(false);
                return;
            }
            const userCompanyId = user.company;
            const companyRef = doc(firestore, "companies", userCompanyId);
            onSnapshot(companyRef, companySnapshot => {
                if (!companySnapshot.exists()) {
                    setLoading(false);
                    return;
                }
                const companyData = companySnapshot.data() as Company;
                setCompany({ ...companyData, id: userCompanyId })
                setLoading(false);
            })
        }, error => {
            setError(error as Error);
            setLoading(false);
        })
        return () => unsubscribe();
    }, [firebaseUser])

    //revit parameters
    useEffect(() => {
        const companyId = company?.id;
        if (!companyId) {
            return;
        }

        const revitParametersRef = collection(firestore, "companies", companyId, "revit-parameters");

        setLoading(true);
        try {
            onSnapshot(revitParametersRef, parametersSnapShot => {
                const revitParameters: RevitParameter[] = [];
                parametersSnapShot.forEach(parameterSnapShot => {
                    const revitParameter = parameterSnapShot.data() as RevitParameter;
                    revitParameter.id = parameterSnapShot.id;
                    revitParameters.push(revitParameter);
                });
                setRevitParameters(revitParameters);

            });
        } catch (error) {
            setError(new Error("Revit Parametre kunne ikke hentes. Prøv venligst igen."));
            logger.error("Failed to get revit parameters", error);
        } finally {
            setLoading(false);
        }
    }, [company, logger])

    const updateCompany = async (id: string, data: Partial<Company>) => {
        setLoading(true);
        setStatus("");

        const requestUrl = `${API_URL}/v1/companies/${id}`;
        const requestJson = JSON.stringify(data);
        const accessToken = await getAccessToken();
        try {
            await axios.put(requestUrl, requestJson, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${accessToken}`
                },
            });
            setStatus("Virksomhedsdata opdateret");
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    //serial number config
    const handleUpdateSerialNumberConfig = async (serialNumberConfig: SerialNumberConfig) => {
        setLoading(true);
        if (!company || !company.id) {
            setError(new Error("Virksomhed ikke fundet, prøv igen."));
            return;
        }

        try {
            const companyRef = doc(firestore, "companies", company.id);
            const updatedCompany = { ...company, serialNumberConfig };
            await updateDoc(companyRef, updatedCompany);
        }
        catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    }

    //Revit parameters
    const createRevitParameter = async (data: Partial<RevitParameter>) => {
        setLoading(true);
        if (!company || !company.id) {
            setError(new Error("Virksomhed ikke fundet, prøv igen."));
            return;
        }

        if (data.parameterValue === undefined) {
            setError(new Error("Parameter ikke fundet, prøv igen."));
            return;
        }

        try {
            const revitParameterRef = collection(firestore, "companies", company.id, "revit-parameters");
            const { id, ...cleanedData } = data;
            await addDoc(revitParameterRef, cleanedData);
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    }

    const updateRevitParameter = async (data: Partial<RevitParameter>) => {
        setLoading(true);
        if (!company || !company.id) {
            setError(new Error("Virksomhed ikke fundet, prøv igen"));
            return;
        }

        if (data.parameterValue === undefined) {
            setError(new Error("Parameter ikke fundet, prøv igen."));
            return;
        }

        try {
            if (!data.id) return;
            const revitParameterRef = doc(firestore, "companies", company.id, "revit-parameters", data.id);
            const { id, ...cleanedData } = data;
            await setDoc(revitParameterRef, cleanedData, { merge: true });
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    }

    const deleteRevitParameter = async (id: string) => {
        setLoading(true);
        if (!company || !company.id) {
            setError(new Error("Virksomhed ikke fundet, prøv igen"));
            return;
        }
        try {
            const revitParameterRef = doc(firestore, "companies", company.id, "revit-parameters", id);
            await deleteDoc(revitParameterRef);
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    }

    const resetCompanyNamingSyntaxAndSerialNumberConfig = async () => {
        setLoading(true);
        if (!company || !company.id) {
            setError(new Error("Virksomhed ikke fundet, prøv igen"));
            return;
        }

        try {
            //reset serial number config
            const defaultSerialNumberConfig = DEFAULT_SERIALNUMBERCONFIG;
            const companyRef = doc(firestore, "companies", company.id);
            setDoc(companyRef, { serialNumberConfig: defaultSerialNumberConfig, themeValueListId: "" }, { merge: true });

            //delete old naming syntax
            const namingSyntaxRef = collection(firestore, "companies", company.id, "naming-syntax");
            const namingSyntaxSnapshot = await getDocs(namingSyntaxRef);
            namingSyntaxSnapshot.forEach(documentSnapshot => {
                deleteDoc(doc(firestore, "companies", company.id, "naming-syntax", documentSnapshot.id));
            })

            //add default naming syntax
            const defaultNamingSyntaxRef = collection(firestore, 'defaults', 'a104', 'naming-syntax');
            const defaultNamingSyntaxSnapshot = await getDocs(defaultNamingSyntaxRef);
            defaultNamingSyntaxSnapshot.forEach(documentSnapshot => {
                addDoc(collection(companyRef, 'naming-syntax'), documentSnapshot.data())
            })
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    }

    const getCompanyLogo = useCallback(async () => {
        if (!company) return;
        try {
            const logoRef = ref(storage, `companies/${company.id}/logo/default`);
            const url = await getDownloadURL(logoRef);
            setLogoUrl(url);
            setCompanyHasLogo(true);
        } catch (error) {
            switch ((error as ErrorData).code) {
                case 'storage/object-not-found':
                    logger.error("File doesn't exist for company: " + company.id)
                    // return undefined;
                    break;
                case 'storage/unauthorized':
                    logger.error("User doesn't have permission to access the object")
                    break;
                case 'storage/canceled':
                    logger.error("User canceled the upload")
                    break;
                case 'storage/unknown':
                    logger.error("Unknown error occurred, inspect the server response")
                    break;
            }
        }
    }, [company, logger],)

    useEffect(() => {
        getCompanyLogo();
    }, [getCompanyLogo]);

    const uploadCompanyLogo = async (file: File) => {
        if (!company) return;
        setLoading(true);
        try {
            const storageRef = ref(storage, `companies/${company.id}/logo/default`);
            if (file.size > 2000000) {
                throw new Error("Logo er større end 2MB og blev derfor ikke uploaded")
            }

            if (!(file.type === "image/png" || file.type === "image/jpeg")) {
                throw new Error("Logo er ikke af typen jpg/png")
            }
            await uploadBytes(storageRef, file);
            setCompanyHasLogo(true);
            getCompanyLogo();
            setLoading(false);
        } catch (error) {
            setError(error as Error)
            setLoading(false);
        }
    }

    const deleteCompanyLogo = async () => {
        if (!company) return;
        setLoading(true);

        try {
            const storageRef = ref(storage, `companies/${company.id}/logo/default`);
            deleteObject(storageRef);
            setCompanyHasLogo(false);
            setLogoUrl(imgNologo);
        } catch (error) {
            setError(error as Error)
        } finally {
            setLoading(false);
        }
    }

    return (
        <CompanyContext.Provider
            value={{
                loading,
                setLoading,
                error,
                setError,
                status,
                updateCompany,
                company,
                revitParameters,
                createRevitParameter,
                updateRevitParameter,
                deleteRevitParameter,
                resetCompanyNamingSyntaxAndSerialNumberConfig,
                handleUpdateSerialNumberConfig,
                uploadCompanyLogo,
                companyHasLogo,
                deleteCompanyLogo,
                logoUrl,
            }}
        >
            {children}
        </CompanyContext.Provider>
    );
};

export default CompanyProvider;
