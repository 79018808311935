import { useEffect, useContext, useState } from 'react';
import { StoreysContext } from 'context';
import { Storey } from 'types';
import { sortBy } from 'lodash';

export const useBuildingStoreys = (buildingId: string): Storey[] => {
    const { storeys : allStoreys } = useContext(StoreysContext);
    const [storeys, setStoreys] = useState<Storey[]>([]);

    useEffect(() => {
        if (!buildingId || !allStoreys || buildingId === '') {
            setStoreys([]);
            return;
        }
        if (!buildingId || Object.values(allStoreys).length === 0) return;
        const s = sortBy(allStoreys[buildingId], ['number', 'id']);
        if (!s) return;
        setStoreys(s);
    }, [buildingId, allStoreys]);

    return storeys;
};