import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BuildingsContext } from "context";
import { useProjectId, useProject } from "hooks";
import { Building } from "types";
import { EMPTY_BUILDING, EMPTY_FAST_BUILDING } from "_constants";
import BuildingCreateModalForm from "./BuildingCreateModalForm";
import BuildingEditModalForm from "./BuildingEditModalForm";
import { logEvent } from "config";
import BuildingList from "./BuildingList";
import { ContainerWithStatusAlertHandling } from "../../hoc/withStatusAlerts";
import FastBuildingList from "./FastBuildingList";
import { useTranslation } from "react-i18next";

const LocationContainer: React.FC<{ tool?: "FAST" | "other" }> = ({ tool = "other" }) => {
    const { t } = useTranslation();
    const projectId = useProjectId();
    const [showCreateBuildingForm, setShowCreateBuildingForm] = useState<boolean>(false);
    const [showEditBuildingForm, setShowEditBuildingForm] = useState<boolean>(false);
    const [selectedBuilding, setSelectedBuilding] = useState<Building | null>(null);
    const { project: selectedProject } = useProject(projectId);
    const { loadingBuildings, buildings, handleSaveBuilding, handleCreateBuilding, handleDeleteBuilding, error } =
        useContext(BuildingsContext);

    const handleCreateBuildingModal = () => {
        setSelectedBuilding(tool === "FAST" ? EMPTY_FAST_BUILDING : EMPTY_BUILDING);
        setShowCreateBuildingForm(true);
        logEvent("building_create");
    };

    const handleEditBuildingModal = (building: Building) => {
        setSelectedBuilding(building);
        setShowEditBuildingForm(true);
        logEvent("building_edit");
    };

    const handleDelete = (buildingId: string) => handleDeleteBuilding(selectedProject!.id, buildingId);

    return (
        <ContainerWithStatusAlertHandling
            className="m-0 p-4 w-100 h-100"
            fluid
            loading={loadingBuildings}
            loadingMessage={t("status.loading") as string}
            error={error}
            style={{ overflowY: "auto" }}
        >
            <Row>
                <Col>
                    {tool === "FAST" ? (
                        <FastBuildingList
                            handleCreate={handleCreateBuildingModal}
                            handleEdit={handleEditBuildingModal}
                            buildings={buildings}
                        />
                    ) : (
                        <BuildingList
                            handleCreate={handleCreateBuildingModal}
                            handleEdit={handleEditBuildingModal}
                            buildings={buildings}
                        />
                    )}
                </Col>
            </Row>
            {showCreateBuildingForm && (
                <BuildingCreateModalForm
                    handleClose={() => setShowCreateBuildingForm(false)}
                    handleSave={(building: Building) => {
                        handleCreateBuilding(selectedProject!.id, building);
                        setShowCreateBuildingForm(false);
                    }}
                    building={selectedBuilding}
                    title={t("pages.shared.locations.create")}
                    tool={tool!}
                />
            )}
            {showEditBuildingForm && selectedBuilding !== null && (
                <BuildingEditModalForm
                    handleClose={() => setShowEditBuildingForm(false)}
                    handleSave={(building: Building) => {
                        handleSaveBuilding(selectedProject!.id, building);
                        setShowEditBuildingForm(false);
                    }}
                    handleDelete={(buildingId: string) => {
                        handleDelete(buildingId);
                        setShowEditBuildingForm(false);
                    }}
                    building={selectedBuilding}
                    title={t("pages.shared.locations.edit")}
                    tool={tool}
                />
            )}
        </ContainerWithStatusAlertHandling>
    );
};

export default LocationContainer;
