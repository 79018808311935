import React, { useEffect } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { useProject } from "hooks";
import { HeatingMethod, HeatingSystem, SystemType } from "types";
import { heatingMethodEffectViewModel, shuntPlacementCentralTempSetViewModel } from "../../../../utils/system.lookup";

type Props<TSystemType> = {
    fields: TSystemType;
    handleFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setFields: React.Dispatch<React.SetStateAction<TSystemType>>;
    inputIsinvalid: boolean;
};

const HeatingForm: React.FC<Props<HeatingSystem>> = ({ fields, handleFieldChange, setFields, inputIsinvalid }) => {
    const match = useRouteMatch<{ projectId: string }>("/projects/:projectId");
    const projectId = match?.params.projectId || "";
    const { project } = useProject(projectId);
    const { t } = useTranslation();

    useEffect(() => {
        if (project === undefined) return;
        if (fields.id === "" && fields.heatingEffect === undefined) {
            if (project?.stageId === "FUTURE_WORK") {
                setFields(curFields => ({
                    ...curFields,
                    heatingEffect: 40,
                    temperatureSetForward: 0,
                    temperatureSetBackward: 0,
                }));
            } else {
                setFields(curFields => ({
                    ...curFields,
                    heatingEffect: 60,
                    temperatureSetForward: 0,
                    temperatureSetBackward: 0,
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields]);

    const handleShuntPlacementFieldChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const shuntPlacement = event.target.value as SystemType;
        let system = { ...fields, shuntPlacement };
        if (shuntPlacement === SystemType.CENTRAL) {
            const temperatureSet = shuntPlacementCentralTempSetViewModel(system.heatingMethod);
            const temperatureSetForward = temperatureSet?.temperatureSetForward || 0;
            const temperatureSetReturn = temperatureSet?.temperatureSetReturn || 0;
            system = { ...system, shuntPlacement, temperatureSetForward, temperatureSetReturn };
        } else {
            const temperatureSetForward = project?.projectSupply.primaryTemperatureSetForward || 0;
            const temperatureSetReturn = project?.projectSupply.primaryTemperatureSetReturn || 0;
            system = { ...system, temperatureSetForward, temperatureSetReturn };
        }
        setFields(system);
    };

    const handleHeatingMethodFieldChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const heatingMethod = event.target.value as HeatingMethod;
        const heatingEffect = heatingMethodEffectViewModel(heatingMethod);
        let system = { ...fields, heatingMethod, heatingEffect };
        if (fields.shuntPlacement === SystemType.CENTRAL) {
            const temperatureSet = shuntPlacementCentralTempSetViewModel(system.heatingMethod) || {
                temperatureSetForward: 0,
                temperatureSetReturn: 0,
            };
            system = {
                ...system,
                temperatureSetForward: temperatureSet.temperatureSetForward,
                temperatureSetReturn: temperatureSet.temperatureSetReturn,
            };
        }
        setFields(system);
    };

    return (
        <>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>
                        {t("pages.FLAT.systems.card-modal.heating.shunt-type")}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                        isInvalid={!fields.shuntPlacement && inputIsinvalid}
                        id="shuntPlacement"
                        value={fields.shuntPlacement}
                        onChange={e => handleShuntPlacementFieldChange(e)}
                    >
                        <option value="" key="">
                            {t("pages.FLAT.systems.card-modal.type-select")}
                        </option>
                        {Object.keys(SystemType).map(type => (
                            <option key={type} value={type}>
                                {`${t(`pages.FLAT.systems.card-modal.${type}`)}`}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>
                        {t("pages.FLAT.systems.card-modal.heating.heating-method")}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                        isInvalid={!fields.heatingMethod && inputIsinvalid}
                        id="heatingMethod"
                        value={fields.heatingMethod}
                        onChange={e => {
                            handleHeatingMethodFieldChange(e);
                        }}
                    >
                        <option value="" key="">
                            {t("pages.FLAT.systems.card-modal.heating.heating-method-select")}
                        </option>
                        {Object.keys(HeatingMethod).map(type => (
                            <option key={type} value={type}>
                                {`${t(`pages.FLAT.systems.card-modal.heating.${type}`)}`}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>
            <hr />
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>
                            {t("pages.FLAT.systems.card-modal.heating.supply")}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                isInvalid={fields.temperatureSetForward < 0 && inputIsinvalid}
                                as="input"
                                id="temperatureSetForward"
                                type="number"
                                value={fields.temperatureSetForward}
                                disabled={!fields.shuntPlacement || !fields.heatingMethod}
                                onChange={e => {
                                    handleFieldChange(e as React.ChangeEvent<HTMLInputElement>);
                                }}
                            />
                            <InputGroup.Text>°C</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>
                            {t("pages.FLAT.systems.card-modal.heating.return")}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                isInvalid={fields.temperatureSetReturn < 0 && inputIsinvalid}
                                as="input"
                                id="temperatureSetReturn"
                                type="number"
                                value={fields.temperatureSetReturn}
                                disabled={!fields.shuntPlacement || !fields.heatingMethod}
                                onChange={e => {
                                    handleFieldChange(e as React.ChangeEvent<HTMLInputElement>);
                                }}
                            />
                            <InputGroup.Text>°C</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>{t("pages.FLAT.systems.card-modal.heating.delta-t")}</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="number"
                                disabled
                                value={fields.temperatureSetForward - fields.temperatureSetReturn}
                            />
                            <InputGroup.Text>°C</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>
                        {t("pages.FLAT.systems.card-modal.heating.effect")}
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            isInvalid={fields.heatingEffect < 0 && inputIsinvalid}
                            as="input"
                            id="heatingEffect"
                            type="number"
                            placeholder="40"
                            disabled={!fields.shuntPlacement || !fields.heatingMethod}
                            value={fields.heatingEffect}
                            onChange={e => {
                                handleFieldChange(e as React.ChangeEvent<HTMLInputElement>);
                            }}
                        />
                        <InputGroup.Text>
                            W/m<sup>2</sup>
                        </InputGroup.Text>
                    </InputGroup>
                </Form.Group>
            </Row>
        </>
    );
};

export default HeatingForm;
