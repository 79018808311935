import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Plus, Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { BuildingsContext, DocumentsContext } from "context";
import { logEvent } from "config";
import { ContainerWithStatusAlertHandling } from "../../hoc/withStatusAlerts";
import DocumentsModalConfigurator from "../DocumentConfigurator/DocumentsModalConfigurator";
import DocumentsList from "./DocumentsList";
import { ToolTipButton } from "../ToolTip/ToolTip";
import ConfirmModal from "../Shared/ConfirmModal";
import OverlayIconButton from "components/Shared/Buttons/OverlayIconButton";

const DocumentsContainer: React.FC = () => {
    const { t } = useTranslation();
    const [showCreateDocumentsConfigurator, setShowCreateDocumentsConfigurator] = useState<boolean>(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);

    const { documents, handleDeleteDocuments, loadingDocuments } = useContext(DocumentsContext);

    const { buildings, loadingBuildings } = useContext(BuildingsContext);
    const documentsExist = documents && documents.length > 0;
    const buildingsExist = buildings && buildings.filter(building => !building.sharedLocation).length > 0;
    const emptyList = !documentsExist;

    const onDeleteDocuments = () => {
        handleDeleteDocuments(documents.map(document => document.id));
    };

    return (
        <ContainerWithStatusAlertHandling
            loading={loadingDocuments || loadingBuildings}
            loadingMessage={"Henter data..."}
            fluid
            className="m-0 ps-4 h-100 pt-4"
            style={{ overflowY: "auto" }}
        >
            {emptyList ? (
                <CreateDocuments
                    setShowCreateDocumentsConfigurator={setShowCreateDocumentsConfigurator}
                    buildingsExist={buildingsExist}
                />
            ) : (
                <>
                    <Row>
                        <Col>
                            <h1>
                                {t("pages.BE18.document-list.title")}
                                <ToolTipButton
                                    eventId="hover_docsheet_gridview"
                                    tooltipId="documentsAndSheets.headerButton"
                                    tooltipPlacement="right"
                                    tooltipMessage={t("pages.BE18.document-list.title-help")}
                                    className="ms-2"
                                />
                            </h1>
                            <h6 className="text-muted">{t("pages.BE18.document-list.subtitle")}</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-between mt-3">
                            <Button
                                variant="primary"
                                size="sm"
                                className="d-inline-flex align-items-center me-2"
                                onClick={() => {
                                    setShowCreateDocumentsConfigurator(true);
                                    logEvent("document_create");
                                }}
                            >
                                <Plus />
                                <span className="ms-1">{t("pages.BE18.document-list.buttons.create-documents")}</span>
                            </Button>
                            <OverlayIconButton
                                onClick={() => setShowConfirmDeleteModal(true)}
                                overlayText={t("buttons.delete-all")}
                            >
                                <Trash size={"1.2rem"} />
                            </OverlayIconButton>
                        </Col>
                    </Row>
                </>
            )}
            <Row className="mt-3">
                {documentsExist && (
                    <Col>
                        <DocumentsList />
                    </Col>
                )}
            </Row>
            <ConfirmModal
                show={showConfirmDeleteModal}
                handleAction={() => {
                    onDeleteDocuments();
                    logEvent("docsheet_delete_multi");
                    setShowConfirmDeleteModal(false);
                }}
                handleClose={() => setShowConfirmDeleteModal(false)}
                description={t("pages.BE18.document-list.delete.confirm-desc")}
                confirmText={t("buttons.delete")}
            />
            {showCreateDocumentsConfigurator && (
                <DocumentsModalConfigurator
                    title={t("pages.BE18.document-list.create-documents.title")}
                    handleClose={() => setShowCreateDocumentsConfigurator(false)}
                />
            )}
        </ContainerWithStatusAlertHandling>
    );
};

export default DocumentsContainer;

interface ICreateSheetsAndDocuments {
    setShowCreateDocumentsConfigurator: (ShowCreateDocumentsConfigurator: boolean) => void;
    buildingsExist: boolean;
}

const CreateDocuments = ({ setShowCreateDocumentsConfigurator, buildingsExist }: ICreateSheetsAndDocuments) => {
    const { t } = useTranslation();
    return (
        <Row className="justify-content-center align-items-center h-100">
            <Col xs={6}>
                <h1>{t("pages.BE18.document-list.empty-list.title")}</h1>

                {buildingsExist ? (
                    <h4 className="mb-4">
                        <small>{t("pages.BE18.document-list.empty-list.subtitle")}</small>
                    </h4>
                ) : (
                    <h4 className="mb-4">
                        <small>{t("pages.BE18.document-list.empty-list.subtitle-no-locations")}</small>
                    </h4>
                )}
                <Button
                    variant="primary"
                    className="d-inline-flex align-items-center ms-2"
                    onClick={() => setShowCreateDocumentsConfigurator(true)}
                >
                    <Plus />
                    <div className="ms-1">{t("pages.BE18.document-list.buttons.create-documents")}</div>
                </Button>
            </Col>
        </Row>
    );
};
