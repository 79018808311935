import React from "react";
import { useFeatureFlag } from "hooks";

interface ComponentFeaturesProp {
    FAST: boolean;
    FLAT: boolean;
    BE18: boolean;
    override?: boolean | undefined;
    [key: string]: unknown;
}

export const withFeatureFlag =
    <T extends object>(
        enabledComponent: React.ComponentType<T>
    ): React.FC<T & ComponentFeaturesProp> =>
        ({ FAST, FLAT, BE18, override = undefined, ...props }: Partial<ComponentFeaturesProp>) => {
            const { enabledCompanyFeatureFlags } =
                useFeatureFlag();

            const isEnabled =
                FAST && enabledCompanyFeatureFlags.FAST
                    ? true
                    : FLAT && enabledCompanyFeatureFlags.FLAT
                        ? true
                        : BE18 && enabledCompanyFeatureFlags.BE18
                            ? true
                            : false;

            const Component = override !== undefined ? override ? enabledComponent : null : isEnabled ? enabledComponent : null;

            if (Component) {
                return <Component {...props as T} />;
            }

            return null;
        };

export const withProjectFeatureFlag =
    <T extends object>(
        enabledComponent: React.ComponentType<T>
    ): React.FC<T & ComponentFeaturesProp> =>
        ({ FAST, FLAT, BE18, override = undefined, ...props }: Partial<ComponentFeaturesProp>) => {
            const { enabledProjectFeatureFlags } =
                useFeatureFlag();

            const isEnabled =
                FAST && enabledProjectFeatureFlags.FAST
                    ? true
                    : FLAT && enabledProjectFeatureFlags.FLAT
                        ? true
                        : BE18 && enabledProjectFeatureFlags.BE18
                            ? true
                            : false;

            const Component = override !== undefined ? override ? enabledComponent : null : isEnabled ? enabledComponent : null;

            if (Component) {
                return <Component {...props as T} />;
            }

            return null;
        };