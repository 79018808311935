import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import fluxlogo from "../../images/fluxlogos/fluxlogo.svg";
import engineerAnimation from "../../images/animations/engineer-animation.json";
import Lottie from "lottie-react";
import LoginChooser from "./LoginChooser";
import LoginEmailContainer from "./LoginEmailContainer";

const LoginContainer: React.FC = () => {
    const [showEmailLoginForm, setShowEmailLoginForm] = useState<boolean>(false);

    return (
        <Container fluid className="h-100">
            <Row className="h-100">
                <Col className="h-100">
                    <Row className="h-25 p-4">
                        <Col>
                            <img style={{ width: 175 }} src={fluxlogo} alt="" />
                        </Col>
                    </Row>
                    <Row className="h-50">
                        <Col className="d-flex justify-content-center align-items-center">
                            {showEmailLoginForm ? (
                                <LoginEmailContainer setShowEmailLoginForm={setShowEmailLoginForm} />
                            ) : (
                                <LoginChooser setShowEmailLoginForm={setShowEmailLoginForm} />
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col style={{ backgroundColor: "#F6F8FF" }}>
                    <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <Lottie animationData={engineerAnimation} />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default LoginContainer;
