import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useHover } from 'hooks';
import { ThemeValueList } from 'types';
import { Line, LineItem } from '../Shared/Layout';

interface Props {
    themeValueList: ThemeValueList;
    handleSelectThemeValueList: (id: string) => void;
}

const ThemeValueListTableRow: React.FC<Props> = ({ themeValueList, handleSelectThemeValueList }) => {
    const hoverRef = React.useRef(null);
    const isHover = useHover(hoverRef);
    return (
        <Row className="mb-2">
            <Col>
                <Card
                    ref={hoverRef}
                    className="transition"
                    body
                    bg={isHover ? 'light' : undefined}
                    role="button"
                    onClick={() => handleSelectThemeValueList(themeValueList.id)}
                >
                    <Line>
                        <LineItem style={{ width: '35%' }}>
                            {themeValueList.name}
                        </LineItem>
                        <LineItem style={{ width: '20%', textAlign: 'center' }}>
                            {themeValueList.valuePairs.length}
                        </LineItem>
                        <LineItem style={{ minWidth: '20%', textAlign: 'center' }}>
                            {themeValueList.code}
                        </LineItem>
                        <LineItem className="d-inline-block text-truncate text-muted font-weight-light" style={{ width: '25%' }}>
                            {themeValueList.definition}
                        </LineItem>
                    </Line>
                </Card>
            </Col>
        </Row>
    )
}

export default ThemeValueListTableRow;