import { SerialNumberConfig, SyntaxViewModel, SyntaxViewModelItem } from "types";

export enum SyntaxElementIds {
    PROJECTID = "PROJECTID",
    KNOWLEDGEAREA = "KNOWLEDGEAREA",
    DISCIPLINE = "DISCIPLINE",
    SHEETTYPE = "SHEETTYPE",
    LOCATION = "LOCATION",
    STOREY = "STOREY",
    SERIALNUMBER = "SERIALNUMBER",
    CATEGORY = "CATEGORY",
    TITLE = "TITLE",
    STAGE = "STAGE",
    PHASE = "PHASE",
    THEMEVALUELIST = "THEMEVALUELIST",
    FILETYPE = "FILETYPE",
    BUSINESSTYPE = "BUSINESSTYPE",
}

export enum MetaDataType {
    KNOWLEDGE = "KNOWLEDGE",
    DISCIPLINE = "DISCIPLINE",
    SHEETTYPE = "SHEETTYPE",
    LOCATION = "LOCATION",
    STOREY = "STOREY",
    STAGE = "STAGE",
    PHASE = "PHASE",
    THEME = "THEME",
    FILETYPE = "FILETYPE",
    BUSINESSTYPE = "BUSINESSTYPE",
    NONE = "X",
}

export enum CountingGroupMethod {
    NONE = "NONE",
    ONE_GROUP = "ONE_GROUP",
    SAMENAME_GROUP = "SAMENAME_GROUP",
    METADATATYPE_GROUP = "METADATATYPE_GROUP",
}

export const DEFAULT_SERIALNUMBERCONFIG: SerialNumberConfig = {
    numDigits: 4,
    groupingMethod: CountingGroupMethod.ONE_GROUP,
    boundDigits: ["X", "X", "X", "X"] as unknown as MetaDataType[],
    metaDataTypes: [],
};

export const SYNTAX_VIEW_MODEL = (element: SyntaxElementIds): SyntaxViewModelItem => {
    const viewModel: SyntaxViewModel = {
        PROJECTID: {
            translationId: "pages.shared.naming-syntax.elements.project-id",
            code: "I44",
            codeProperty: "projectId",
        },
        KNOWLEDGEAREA: {
            translationId: "pages.shared.naming-syntax.elements.knowledge-area",
            code: "K09",
            codeProperty: "knowledgeCode",
        },
        DISCIPLINE: {
            translationId: "pages.shared.naming-syntax.elements.discipline",
            code: "M07",
            codeProperty: "disciplineCode",
        },
        SHEETTYPE: {
            translationId: "pages.shared.naming-syntax.elements.sheet-type",
            code: "H1",
            codeProperty: "sheetTypeCode",
        },
        LOCATION: {
            translationId: "pages.shared.naming-syntax.elements.location",
            code: "L1",
            codeProperty: "locationCode",
        },
        STOREY: {
            translationId: "pages.shared.naming-syntax.elements.storey",
            code: "E1",
            codeProperty: "storeyCode",
        },
        SERIALNUMBER: {
            translationId: "pages.shared.naming-syntax.elements.serial-number",
            code: "N002",
            codeProperty: "serialNumber",
        },
        CATEGORY: {
            translationId: "pages.shared.naming-syntax.elements.category",
            code: "C05.2",
            codeProperty: "documentKindCategoryCode",
        },
        TITLE: {
            translationId: "pages.shared.naming-syntax.elements.title",
            code: "Energiramme",
            codeProperty: "",
        },
        STAGE: {
            translationId: "pages.shared.naming-syntax.elements.stage",
            code: "S2",
            codeProperty: "stageCode",
        },
        PHASE: {
            translationId: "pages.shared.naming-syntax.elements.phase",
            code: "A5",
            codeProperty: "phaseCode",
        },
        THEMEVALUELIST: {
            translationId: "pages.shared.naming-syntax.elements.theme-value-list",
            code: "TBG",
            codeProperty: "themeCode",
        },
        FILETYPE: {
            translationId: "pages.shared.naming-syntax.elements.file-type",
            code: "F2",
            codeProperty: "fileTypeCode",
        },
        BUSINESSTYPE: {
            translationId: "pages.shared.naming-syntax.elements.business-type",
            code: "B05",
            codeProperty: "businessTypeCode",
        },
    };
    return viewModel[element];
};

export enum NamingTypes {
    DOCUMENT = "DOCUMENT",
    SHEET = "SHEET",
}
