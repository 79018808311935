import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Form } from "react-bootstrap";
import { UserContext } from "context";
import { useContextTemplates, useContextModel } from "hooks";
import { AbstractBaseSheetTemplate, LocationSpecificTemplate, ISheetTemplate, PlanTemplate } from "types";
import { groupBy, pick } from "lodash";
import SheetCheckboxCard from "./SheetCheckboxCard";
import { ToolTipButton } from "../ToolTip/ToolTip";
import LocationSpecificCheckboxCard from "./LocationSpecificCheckboxCard";
import SheetCheckboxCardWithRange from "./SheetCheckboxCardWithRange";

interface Props {
    selectedSheets: {
        amount: number;
        sheetTemplate: AbstractBaseSheetTemplate;
    }[];
    handleSelectSheet: (sheet: AbstractBaseSheetTemplate, amount?: number) => void;
    handleAddDiagram: (sheet: LocationSpecificTemplate) => void;
    handleDeleteDiagram: (sheet: LocationSpecificTemplate) => void;
    handleEmptySelectedSheets: () => void;
}

const CreateSheetsContext: React.FC<Props> = ({
    handleSelectSheet,
    selectedSheets,
    handleAddDiagram,
    handleDeleteDiagram,
}) => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const { sheetTemplates, loadingTemplates } = useContextTemplates();
    const { contextModel, loadingModel, getKnowledgeById, getSheetTypeById } = useContextModel();
    const uniqueSheetTemplateKnowledgeIds = [...new Set(sheetTemplates.map(template => template.knowledgeId))];
    const filteredKnowledges = contextModel.knowledges.filter(knowledge =>
        uniqueSheetTemplateKnowledgeIds.includes(knowledge.id)
    );

    const [selectedKnowledgeId, setSelectedKnowledgeId] = useState<string>("");

    const enrichedSheetTemplates = sheetTemplates
        .map(sheetTemplate => ({ ...sheetTemplate, knowledge: getKnowledgeById(sheetTemplate.knowledgeId) }))
        .map(sheetTemplate => ({ ...sheetTemplate, sheetType: getSheetTypeById(sheetTemplate.sheetTypeId) }));

    const knowledgeGroupedSheetTemplates = groupBy(enrichedSheetTemplates, "knowledge.id");
    const templates = groupBy(knowledgeGroupedSheetTemplates[selectedKnowledgeId], "sheetType.code");

    useEffect(() => {
        if (!loadingModel && !loadingTemplates) {
            const initialKnowledgeId = uniqueSheetTemplateKnowledgeIds?.includes(user?.knowledgeId || "")
                ? user?.knowledgeId
                : filteredKnowledges?.[0]?.id;
            setSelectedKnowledgeId(initialKnowledgeId || "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingModel, loadingTemplates]);

    return (
        <Container>
            {loadingModel && loadingTemplates ? (
                <>{t("status.loading")}</>
            ) : (
                <div>
                    <Row>
                        <h4>
                            <small>{t("pages.FAST.sheet-list.create-sheets.subtitle")}</small>
                        </h4>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <h4 className="my-4">
                                {t("pages.FAST.sheet-list.create-sheets.fields.knowledge-area")}
                                <ToolTipButton
                                    eventId="hover_sheet_knowledge"
                                    tooltipMessage={t("pages.FAST.sheet-list.create-sheets.fields.knowledge-area-help")}
                                    tooltipId="sheetsConfigurator.knowledgeHeader"
                                    className="ms-1"
                                />
                            </h4>
                        </Col>
                        <Col className="mx-3">
                            <Form className="d-grid gap-2">
                                <Form.Group as={Row}>
                                    <Form.Select
                                        value={selectedKnowledgeId}
                                        onChange={e => setSelectedKnowledgeId(e.target.value)}
                                    >
                                        {filteredKnowledges &&
                                            filteredKnowledges.map(knowledge => (
                                                <option key={knowledge.id} value={knowledge.id}>
                                                    {`${knowledge.code} - ${knowledge.value}`}
                                                    {knowledge.preferred && " ★ "}
                                                </option>
                                            ))}
                                    </Form.Select>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    {Object.keys(templates)
                        .sort()
                        .map(sheetTypeCode => {
                            const sheetType = getSheetTypeById(templates[sheetTypeCode][0].sheetType?.id);
                            return (
                                <Row key={sheetTypeCode}>
                                    <Col xs="12">
                                        <h3 className="my-4">
                                            <small>{`${sheetType?.code} - ${sheetType?.value}`}</small>
                                        </h3>
                                    </Col>
                                    {templates[sheetTypeCode].map((template: ISheetTemplate) => (
                                        <Col key={template.id} xs="6">
                                            {template.creationLogic === "PER_STOREY" ? (
                                                <SheetCheckboxCard
                                                    sheetTemplate={template as PlanTemplate}
                                                    handleSelectSheet={handleSelectSheet}
                                                    selected={
                                                        !!selectedSheets
                                                            .map(selectedSheet => selectedSheet.sheetTemplate.id)
                                                            .includes(template.id)
                                                    }
                                                />
                                            ) : template.creationLogic === "CHOOSE_SPECIFIC_LOCATION" ? (
                                                <LocationSpecificCheckboxCard
                                                    selectedDiagrams={selectedSheets
                                                        .map(selectedSheet => selectedSheet.sheetTemplate)
                                                        .filter(diagramTemplate => diagramTemplate.id === template.id)}
                                                    sheetTemplate={template as LocationSpecificTemplate}
                                                    handleAddDiagram={handleAddDiagram}
                                                    handleDeleteDiagram={handleDeleteDiagram}
                                                />
                                            ) : (
                                                <SheetCheckboxCardWithRange
                                                    sheetTemplate={template}
                                                    handleSelectSheet={handleSelectSheet}
                                                    selected={
                                                        !!selectedSheets
                                                            .map(selectedSheet => selectedSheet.sheetTemplate.id)
                                                            .includes(template.id)
                                                    }
                                                />
                                            )}
                                        </Col>
                                    ))}
                                </Row>
                            );
                        })}
                </div>
            )}
        </Container>
    );
};

export default CreateSheetsContext;
