import { System } from "types";
import { API_URL } from "config";
import axios, { AxiosResponse } from "axios";

type AbstractSystemService = {
    getSystems: (projectId: string, accessToken: string) => Promise<AxiosResponse<System[]>>;
    getSystem: (projectId: string, systemId: string, accessToken: string) => Promise<AxiosResponse<System>>;
    createSystem: (projectId: string, data: System, accessToken: string) => Promise<AxiosResponse<System>>;
    updateSystem: (projectId: string, data: System, accessToken: string) => Promise<AxiosResponse<System>>;
    deleteSystem: (projectId: string, systemId: string, accessToken: string) => Promise<AxiosResponse<void>>;
};

export const SystemService: AbstractSystemService = {
    getSystems: async (projectId: string, accessToken: string) => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/systems`;
        return axios.get(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
    },
    getSystem: async (projectId: string, systemId: string, accessToken: string) => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/systems/${systemId}`;
        return await axios.get(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
    },
    createSystem: async (projectId: string, system: System, accessToken: string) => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/systems`;
        const requestJson = JSON.stringify({ system });
        return await axios.post(requestUrl, requestJson, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
    },
    updateSystem: async (projectId: string, system: System, accessToken: string) => {
        const systemId = system.id;
        const requestUrl = `${API_URL}/v1/projects/${projectId}/systems/${systemId}`;
        const requestJson = JSON.stringify({ system });
        return await axios.put(requestUrl, requestJson, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
    },
    deleteSystem: async (projectId: string, systemId: string, accessToken: string) => {
        const requestUrl = `${API_URL}/v1/projects/${projectId}/systems/${systemId}`;
        return await axios.delete(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
    },
};
