import React, { useContext, useEffect } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ProjectContext } from "context";
import { ProjectSupply, HeatingSupply as HeatingSupplyOptions, SystemFlow as SystemFlowOptions } from "types";
import { FormEvent, useFormFields, useProject, useProjectId } from "hooks";
import { UNKNOWN_PROJECTSUPPLY } from "_constants";
import { primaryWaterSupplyViewModel } from "../../utils/system.lookup";

interface Props {
    handleClose: () => void;
}

const ProjectSupplyModal: React.FC<Props> = ({ handleClose }) => {
    const projectId = useProjectId();
    const { project } = useProject(projectId);
    const { fields, handleFieldChange, setFields } = useFormFields<ProjectSupply>(
        project?.projectSupply || UNKNOWN_PROJECTSUPPLY
    );
    const { handleSaveProjectField } = useContext(ProjectContext);
    const { t } = useTranslation();

    useEffect(() => {
        if (project) {
            setFields(project.projectSupply);
        }
    }, [project, setFields]);

    const HandleSaveProjectSupply = () => {
        if (project) {
            const data = {
                projectSupply: fields,
            };
            handleSaveProjectField(project?.id, data);
        }
    };

    const handleSystemFlowFieldChange = (event: FormEvent) => {
        const systemFlow = event.target.value as SystemFlowOptions;
        const temperatureSet = primaryWaterSupplyViewModel(systemFlow);
        setFields(prevFields => ({
            ...prevFields,
            systemFlow,
            primaryTemperatureSetForward: temperatureSet.primaryTemperatureSetForward,
            primaryTemperatureSetReturn: temperatureSet.primaryTemperatureSetReturn,
        }));
    };

    return (
        <Modal show={true} backdrop="static" scrollable onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t("pages.FLAT.systems.supply-modal.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="d-grid gap-2">
                    <Row className="mb-2">
                        <Col>
                            <Form.Label>{t("pages.FLAT.systems.supply-modal.supply-type")}</Form.Label>
                            <Form.Select id="heatingSupply" value={fields.heatingSupply} onChange={handleFieldChange}>
                                {Object.keys(HeatingSupplyOptions).map(type => (
                                    <option key={type} value={type}>
                                        {`${t(`pages.FLAT.systems.supply-modal.${type}`)}`}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Label>{t("pages.FLAT.systems.supply-modal.supply-connection")}</Form.Label>
                            <Form.Select
                                id="systemFlow"
                                value={fields.systemFlow}
                                onChange={handleSystemFlowFieldChange}
                            >
                                {Object.keys(SystemFlowOptions).map(type => (
                                    <option key={type} value={type}>
                                        {`${t(`pages.FLAT.systems.supply-modal.${type}`)}`}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Label>{t("pages.FLAT.systems.supply-modal.forward-temp")}</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    id="primaryTemperatureSetForward"
                                    type="number"
                                    value={fields.primaryTemperatureSetForward}
                                    onChange={handleFieldChange}
                                />
                                <InputGroup.Text>
                                    <InputGroup.Text>°C</InputGroup.Text>
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col>
                            <Form.Label>{t("pages.FLAT.systems.supply-modal.return-temp")}</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    id="primaryTemperatureSetReturn"
                                    type="number"
                                    value={fields.primaryTemperatureSetReturn}
                                    onChange={handleFieldChange}
                                />
                                <InputGroup.Text>
                                    <InputGroup.Text>°C</InputGroup.Text>
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>{t("pages.FLAT.systems.supply-modal.delta-temperature")}</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="number"
                                    disabled
                                    value={fields.primaryTemperatureSetForward - fields.primaryTemperatureSetReturn}
                                />
                                <InputGroup.Text>
                                    <InputGroup.Text>°C</InputGroup.Text>
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("buttons.cancel")}
                </Button>
                <Button
                    className="mt-2"
                    onClick={() => {
                        HandleSaveProjectSupply();
                        handleClose();
                    }}
                >
                    {t("buttons.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProjectSupplyModal;
