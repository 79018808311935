import React from "react";
import { Card } from "react-bootstrap";
import { useHover } from "hooks";
import {
    WaterComponent,
    HeatingMethod,
    System,
    SystemDiscipline,
    SystemType,
    FireProtection,
    WaterSystem,
    HeatingSystem,
    VentilationSystem,
    SewageSystem,
    ElectricitySystem,
    DrainageSystem,
} from "types";
import ElectricityIcon from "../../../images/icons/TypeElectricity.svg";
import HeatingIcon from "../../../images/icons/TypeHeating.svg";
import VentilationIcon from "../../../images/icons/TypeVentilation.svg";
import SewageIcon from "../../../images/icons/TypeSewage.svg";
import WaterIcon from "../../../images/icons/TypeWater.svg";
import DrainageIcon from "../../../images/icons/TypeDrainage.svg";
import { useTranslation } from "react-i18next";

interface Props {
    onClick: (System: System) => void;
    system: System;
}

const SystemCard: React.FC<Props> = ({ system, onClick }: Props) => {
    const hoverRef = React.useRef(null);
    const isHover = useHover(hoverRef);

    return (
        <Card
            ref={hoverRef}
            className="m-1 transition-fast pointer shadow-sm"
            style={{ width: "18rem" }}
            onClick={() => onClick(system)}
            bg={isHover ? "light" : undefined}
        >
            <Card.Body>
                <Card.Title className="d-flex justify-content-between align-items-center">
                    {`${system.name}`}
                    {system.systemDiscipline === SystemDiscipline.WATER && <img src={WaterIcon} alt="" />}
                    {system.systemDiscipline === SystemDiscipline.HEATING && <img src={HeatingIcon} alt="" />}
                    {system.systemDiscipline === SystemDiscipline.VENTILATION && <img src={VentilationIcon} alt="" />}
                    {system.systemDiscipline === SystemDiscipline.SEWAGE && <img src={SewageIcon} alt="" />}
                    {system.systemDiscipline === SystemDiscipline.ELECTRICITY && <img src={ElectricityIcon} alt="" />}
                    {system.systemDiscipline === SystemDiscipline.DRAINAGE && <img src={DrainageIcon} alt="" />}
                </Card.Title>
                {system.systemDiscipline === SystemDiscipline.WATER && <WaterBody system={system} />}

                {system.systemDiscipline === SystemDiscipline.HEATING && <HeatingBody system={system} />}

                {system.systemDiscipline === SystemDiscipline.VENTILATION && <VentilationBody system={system} />}

                {system.systemDiscipline === SystemDiscipline.SEWAGE && <SewageBody system={system} />}

                {system.systemDiscipline === SystemDiscipline.ELECTRICITY && <ElectricityBody system={system} />}

                {system.systemDiscipline === SystemDiscipline.DRAINAGE && <DrainageBody system={system} />}
            </Card.Body>
        </Card>
    );
};

export default SystemCard;

const WaterBody: React.FC<{ system: WaterSystem }> = ({ system }) => {
    const { t } = useTranslation();

    return (
        <>
            <Card.Subtitle>
                <small>
                    <span className="text-muted">{t("pages.FLAT.systems.card-modal.type")}: </span>
                    {system.systemType === SystemType.CENTRAL
                        ? t("pages.FLAT.systems.card-modal.CENTRAL")
                        : t("pages.FLAT.systems.card-modal.DECENTRAL")}
                </small>
            </Card.Subtitle>
            {system.systemType === SystemType.DECENTRAL && (
                <>
                    <Card.Subtitle className="mt-1">
                        <small>
                            <span className="text-muted">{t("pages.FLAT.systems.card-modal.water.component")}: </span>
                            {system.component === WaterComponent.HOTWATERTANK
                                ? t("pages.FLAT.systems.card-modal.water.HOTWATERTANK")
                                : t("pages.FLAT.systems.card-modal.water.HEATEXCHANGER")}
                        </small>
                    </Card.Subtitle>
                    <Card.Subtitle className="mt-1">
                        <small>
                            <span className="text-muted">{t("pages.FLAT.systems.card-modal.water.effect")}: </span>
                            {system.componentEffect} W/m<sup>2</sup>
                        </small>
                    </Card.Subtitle>
                    <Card.Subtitle className="mt-1">
                        <small>
                            <span className="text-muted">{t("pages.FLAT.systems.card-modal.water.size")}: </span>
                            {system.componentHeight}mm * {system.componentWidth}mm
                        </small>
                    </Card.Subtitle>
                </>
            )}
        </>
    );
};

const HeatingBody: React.FC<{ system: HeatingSystem }> = ({ system }) => {
    const { t } = useTranslation();

    return (
        <>
            <Card.Subtitle>
                <small>
                    <span className="text-muted">{t("pages.FLAT.systems.card-modal.type")}: </span>
                    {system.shuntPlacement === SystemType.CENTRAL
                        ? t("pages.FLAT.systems.card-modal.CENTRAL")
                        : t("pages.FLAT.systems.card-modal.DECENTRAL")}
                </small>
            </Card.Subtitle>
            <Card.Subtitle className="mt-1">
                <small>
                    <span className="text-muted">{t("pages.FLAT.systems.card-modal.heating.heating-method")}: </span>
                    {system.heatingMethod === HeatingMethod.UNDERFLOORHEATING
                        ? t("pages.FLAT.systems.card-modal.heating.UNDERFLOORHEATING")
                        : t("pages.FLAT.systems.card-modal.heating.RADIATOR")}
                </small>
            </Card.Subtitle>
            <Card.Subtitle className="mt-1">
                <small>
                    <span className="text-muted">{t("pages.FLAT.systems.card-modal.heating.supply")}: </span>
                    {system.temperatureSetForward}°C
                </small>
            </Card.Subtitle>
            <Card.Subtitle className="mt-1">
                <small>
                    <span className="text-muted">{t("pages.FLAT.systems.card-modal.heating.return")}: </span>
                    {system.temperatureSetReturn}°C
                </small>
            </Card.Subtitle>
            <Card.Subtitle className="mt-1">
                <small>
                    <span className="text-muted">{t("pages.FLAT.systems.card-modal.heating.delta-t")}: </span>
                    {system.temperatureSetForward - system.temperatureSetReturn}°C
                </small>
            </Card.Subtitle>
            <Card.Subtitle className="mt-1">
                <small>
                    <span className="text-muted">{t("pages.FLAT.systems.card-modal.heating.effect")}: </span>
                    {system.heatingEffect} W/m<sup>2</sup>
                </small>
            </Card.Subtitle>
        </>
    );
};

const VentilationBody: React.FC<{ system: VentilationSystem }> = ({ system }) => {
    const { t } = useTranslation();

    return (
        <>
            <Card.Subtitle>
                <small>
                    <span className="text-muted">{t("pages.FLAT.systems.card-modal.type")}: </span>
                    {system.systemType === SystemType.CENTRAL
                        ? t("pages.FLAT.systems.card-modal.CENTRAL")
                        : t("pages.FLAT.systems.card-modal.DECENTRAL")}
                </small>
            </Card.Subtitle>
            <Card.Subtitle className="mt-1">
                <small>
                    <span className="text-muted">
                        {t("pages.FLAT.systems.card-modal.ventilation.fire-protection")}:{" "}
                    </span>
                    {system.fireProtection === FireProtection.FIREDAMPER
                        ? t("pages.FLAT.systems.card-modal.ventilation.FIREDAMPER")
                        : t("pages.FLAT.systems.card-modal.ventilation.SMOKEVENTILATION")}
                </small>
            </Card.Subtitle>
            {system.heatingSurface === true && (
                <Card.Subtitle className="mt-1">
                    <small>
                        <span>{t("pages.FLAT.systems.card-modal.ventilation.heating-surface")}</span>
                    </small>
                </Card.Subtitle>
            )}
            {system.systemType === SystemType.DECENTRAL && (
                <>
                    {system.sharedIntake === true && (
                        <Card.Subtitle className="mt-1">
                            <small>{t("pages.FLAT.systems.card-modal.ventilation.shared-intake")}</small>
                        </Card.Subtitle>
                    )}
                    {system.sharedExhaust === true && (
                        <Card.Subtitle className="mt-1">
                            <small>{t("pages.FLAT.systems.card-modal.ventilation.shared-exhaust")}</small>
                        </Card.Subtitle>
                    )}
                </>
            )}
        </>
    );
};

const SewageBody: React.FC<{ system: SewageSystem }> = ({ system }) => <></>;

const ElectricityBody: React.FC<{ system: ElectricitySystem }> = ({ system }) => {
    const { t } = useTranslation();

    return (
        <>
            <Card.Subtitle>
                <small>
                    <span className="text-muted">{t("pages.FLAT.systems.card-modal.electricity.switchboard")}: </span>
                    {system.integratedSwitchboard === true
                        ? t("pages.FLAT.systems.card-modal.electricity.integrated")
                        : t("pages.FLAT.systems.card-modal.electricity.in-shaft")}
                </small>
            </Card.Subtitle>
        </>
    );
};

const DrainageBody: React.FC<{ system: DrainageSystem }> = ({ system }) => {
    const { t } = useTranslation();

    return (
        <>
            <Card.Subtitle>
                <small>
                    <span className="text-muted">{t("pages.FLAT.systems.card-modal.drainage.pipesize")}: </span>
                    {`ø${system.pipeSize}`}
                </small>
            </Card.Subtitle>
        </>
    );
};
