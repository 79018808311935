import React, { useContext, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { Button, Dropdown, DropdownButton, Form, InputGroup, ListGroup, Modal } from "react-bootstrap";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import { ApartmentsContext } from "context";
import { useFormFields } from "hooks";
import { Apartment, Room } from "types";
import { EMPTY_ROOM, RoomTemplates } from "_constants";
import ConfirmModal from "../../Shared/ConfirmModal";
import { ToolTipButton } from "../../ToolTip/ToolTip";
import OverlayIconButton from "../../Shared/Buttons/OverlayIconButton";
import RoomModal from "./RoomModal";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";

interface Props {
    apartment: Apartment;
    handleClose: () => void;
}

const ApartmentModal: React.FC<Props> = ({ apartment, handleClose }: Props) => {
    const { fields, handleFieldChange, setFields } = useFormFields<Apartment>(apartment); //not updated when annuleret (on delete room)
    const [selectedRoom, setSelectedRoom] = useState<Room>(EMPTY_ROOM);
    const { handleCreateApartment, handleUpdateApartment, handleDeleteApartment } = useContext(ApartmentsContext);
    const [showConfirmDeleteApartment, setShowConfirmDeleteApartment] = useState<boolean>(false);
    const [showRoomModal, setShowRoomModal] = useState<boolean>(false);
    const [isInvalid, setIsInvalid] = useState<string[]>([]);
    const newApartment = fields.id === "";
    const { t } = useTranslation();

    const handleAddNewRoom = (room: Room) => {
        const roomId = uuid();
        const newRoom = { ...room, id: roomId };
        setFields({ ...fields, rooms: [...fields.rooms, newRoom] });
    };

    const handleUpdateRoom = (room: Room) => {
        const updatedConnectedRooms = fields.rooms.map(r => (r.id === room.id ? room : r));
        setFields({ ...fields, rooms: updatedConnectedRooms });
    };

    const handleOpenRoomModal = (room: Room) => {
        setSelectedRoom(room);
        setShowRoomModal(true);
    };

    const handleRemoveRoom = (room: Room) => {
        const updatedConnectedRooms = fields.rooms.filter(r => r.id !== room.id);
        setFields({ ...fields, rooms: updatedConnectedRooms });
    };

    const handleSubmitApartment = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        const valid = validateFields();
        if (valid) {
            if (newApartment) {
                handleCreateApartment(fields);
            } else {
                handleUpdateApartment(fields);
            }
            handleClose();
        }
    };

    const validateFields = (): boolean => {
        const invalid = [];
        const nameIsValid = () => fields.name !== "";
        const areaSizeIsValid = () => fields.areaSize > 0;
        if (!nameIsValid()) {
            invalid.push("NAME");
        }
        if (!areaSizeIsValid()) {
            invalid.push("AREASIZE");
        }
        if (invalid.length > 0) {
            setIsInvalid(invalid);
            return false;
        }
        return true;
    };

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        const items = [...fields.rooms];
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setFields(prevFields => ({ ...prevFields, rooms: items }));
    };

    return (
        <>
            <Modal show={true} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    {newApartment ? t("pages.FLAT.apartments.modal.title-new") : t("pages.FLAT.apartments.modal.title")}
                </Modal.Header>
                <Modal.Body>
                    <Form className="d-grid gap-2">
                        <Form.Group controlId="name">
                            <Form.Label>
                                {t("pages.FLAT.apartments.modal.name")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                placeholder="Name"
                                isInvalid={isInvalid.includes("NAME")}
                                type="text"
                                value={fields.name}
                                onChange={e => {
                                    if (isInvalid.includes("NAME")) {
                                        setIsInvalid((fieldIds: string[]) =>
                                            fieldIds.filter(fieldId => fieldId !== "NAME")
                                        );
                                    }
                                    handleFieldChange(e);
                                }}
                            />
                        </Form.Group>
                        <Form.Group controlId="areaSize">
                            <Form.Label>
                                {t("pages.FLAT.apartments.modal.size")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    isInvalid={isInvalid.includes("AREASIZE")}
                                    placeholder="0"
                                    type="number"
                                    value={fields.areaSize}
                                    onChange={e => {
                                        if (isInvalid.includes("AREASIZE")) {
                                            setIsInvalid((fieldIds: string[]) =>
                                                fieldIds.filter(fieldId => fieldId !== "AREASIZE")
                                            );
                                        }
                                        handleFieldChange(e);
                                    }}
                                />
                                <InputGroup.Text>
                                    m<sup>2</sup>
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        <h6>
                            {t("pages.FLAT.apartments.modal.rooms")}
                            <ToolTipButton
                                eventId={"hover_project"}
                                size="sm"
                                tooltipMessage={t("pages.FLAT.apartments.modal.rooms-tooltip")}
                                tooltipId="buildings.connectedRoomsLabel"
                                className="ms-2"
                            />
                        </h6>
                        <ListGroup>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="connectedRooms">
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {fields.rooms.map((room, index) => (
                                                <Draggable
                                                    key={room.priority}
                                                    draggableId={`${room.id}${room.priority}`}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            className="card my-1"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            key={room.priority}
                                                        >
                                                            <div className="card-body py-2 m-0 d-flex justify-content-between">
                                                                <div>
                                                                    <span className="text-muted">{`${
                                                                        index + 1
                                                                    }. `}</span>
                                                                    {`${room.name}`}
                                                                </div>
                                                                <div>
                                                                    <OverlayIconButton
                                                                        overlayText={t(
                                                                            "pages.FLAT.apartments.modal.edit-room"
                                                                        )}
                                                                        onClick={e => {
                                                                            e.stopPropagation();
                                                                            handleOpenRoomModal(room);
                                                                        }}
                                                                    >
                                                                        <PencilSquare />
                                                                    </OverlayIconButton>
                                                                    <OverlayIconButton
                                                                        overlayText={t(
                                                                            "pages.FLAT.apartments.modal.remove-room"
                                                                        )}
                                                                        onClick={e => {
                                                                            e.stopPropagation();
                                                                            handleRemoveRoom(room);
                                                                        }}
                                                                    >
                                                                        <Trash />
                                                                    </OverlayIconButton>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <DropdownButton
                                title={t("pages.FLAT.apartments.modal.add-room")}
                                id="input-group-dropdown-1"
                            >
                                {RoomTemplates.map(template => (
                                    <Dropdown.Item
                                        key={template.name}
                                        onClick={() => {
                                            handleAddNewRoom(template);
                                        }}
                                    >
                                        {template.name}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </ListGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                    {!newApartment && (
                        <Button
                            variant="danger"
                            onClick={() => {
                                setShowConfirmDeleteApartment(true);
                            }}
                        >
                            {t("buttons.delete")}
                        </Button>
                    )}
                    <Button variant="primary" onClick={handleSubmitApartment} disabled={isInvalid.length > 0}>
                        {t("buttons.save")}
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                <ConfirmModal
                    show={showConfirmDeleteApartment}
                    handleAction={() => {
                        handleDeleteApartment(fields.id);
                        handleClose();
                    }}
                    handleClose={() => {
                        setShowConfirmDeleteApartment(false);
                    }}
                    description={t("pages.FLAT.apartments.modal.delete-title")}
                    confirmText={t("buttons.confirm")}
                />
            }
            {showRoomModal && (
                <RoomModal
                    room={selectedRoom}
                    handleAddRoom={handleAddNewRoom}
                    handleUpdateRoom={handleUpdateRoom}
                    handleRemoveRoom={handleRemoveRoom}
                    handleClose={() => setShowRoomModal(false)}
                />
            )}
        </>
    );
};

export default ApartmentModal;
