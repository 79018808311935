import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormFields } from "hooks";
import { IModelValuePair } from "types";
import ConfirmModal from "../../Shared/ConfirmModal";

interface Props {
    valuePair: IModelValuePair;
    valuePairModel: IModelValuePair[];
    handleClose: () => void;
    handleCreate: (data: IModelValuePair) => Promise<void>;
    handleUpdate: (data: IModelValuePair) => Promise<void>;
    handleDelete: (id: string) => Promise<void>;
}

const ValuePairModalForm: React.FC<Props> = ({
    valuePair,
    valuePairModel,
    handleClose,
    handleCreate,
    handleUpdate,
    handleDelete,
}) => {
    const { t } = useTranslation();
    const { fields, handleFieldChange, setFields } = useFormFields(valuePair);
    const newValuePair = valuePair.id === "";
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [isInvalid, setIsInvalid] = useState<string[]>([]);

    const valuePairCodeExistsInModel = (): boolean => {
        const code = fields.code.toUpperCase();
        if (newValuePair) {
            const existingValuePairs = valuePairModel.filter(vp => vp.code === code);
            return existingValuePairs.length > 0;
        } else {
            const existingValuePairs = valuePairModel.filter(vp => vp.code === code && vp.id !== fields.id);
            return existingValuePairs.length > 0;
        }
    };

    const handleSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        const valid = validateFields();
        if (valid) {
            if (newValuePair) {
                handleCreate(fields);
            } else {
                handleUpdate(fields);
            }
            handleClose();
        }
    };

    const handleTogglePreferred = () => {
        setFields(prevFields => ({ ...prevFields, preferred: !prevFields.preferred }));
    };

    const validateFields = (): boolean => {
        const invalid = [];
        const codeIsValid = () => fields.code !== "";
        const valueIsValid = () => fields.value !== "";

        if (!codeIsValid()) {
            invalid.push("CODE");
        }
        if (!valueIsValid()) {
            invalid.push("VALUE");
        }
        if (invalid.length > 0) {
            setIsInvalid(invalid);
            return false;
        }
        return true;
    };

    return (
        <>
            <Modal show={true} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    {t(newValuePair ? "actions.add" : "actions.edit")}{" "}
                    {newValuePair ? "" : `${valuePair.code} - ${valuePair.value}`}
                </Modal.Header>
                <Modal.Body>
                    <Form className="d-grid gap-2">
                        <Form.Group controlId="code">
                            <Form.Label>
                                {t("pages.FAST.value-lists.value-pairs.fields.code")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                isInvalid={isInvalid.includes("CODE") || valuePairCodeExistsInModel()}
                                type="text"
                                value={fields.code}
                                onChange={event => {
                                    if (isInvalid.includes("CODE")) {
                                        setIsInvalid((fieldIds: string[]) =>
                                            fieldIds.filter(fieldId => fieldId !== "CODE")
                                        );
                                    }
                                    handleFieldChange(event as React.ChangeEvent<HTMLInputElement>);
                                }}
                            />
                            {valuePairCodeExistsInModel() && (
                                <Form.Control.Feedback type="invalid">
                                    Koden bliver anvendt på anden værdi
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group controlId="value">
                            <Form.Label>
                                {t("pages.FAST.value-lists.value-pairs.fields.value")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                isInvalid={isInvalid.includes("VALUE")}
                                type="text"
                                value={fields.value}
                                onChange={event => {
                                    if (isInvalid.includes("VALUE")) {
                                        setIsInvalid((fieldIds: string[]) =>
                                            fieldIds.filter(fieldId => fieldId !== "VALUE")
                                        );
                                    }
                                    handleFieldChange(event as React.ChangeEvent<HTMLInputElement>);
                                }}
                            />
                        </Form.Group>
                        <Form.Group controlId="preferred">
                            <Form.Check
                                type="checkbox"
                                label={t("pages.FAST.value-lists.value-pairs.fields.preferred")}
                                checked={fields.preferred}
                                onChange={handleTogglePreferred}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                    {!newValuePair && (
                        <Button
                            variant="danger"
                            onClick={() => {
                                setShowConfirmModal(true);
                            }}
                        >
                            {t("buttons.delete")}
                        </Button>
                    )}
                    <Button
                        disabled={isInvalid.length > 0 || valuePairCodeExistsInModel()}
                        variant="primary"
                        onClick={handleSubmit}
                    >
                        {t(newValuePair ? "buttons.add" : "buttons.save")}
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                <ConfirmModal
                    show={showConfirmModal}
                    handleAction={() => {
                        handleDelete(fields.id);
                        handleClose();
                    }}
                    handleClose={() => {
                        setShowConfirmModal(false);
                    }}
                    description={t("pages.FAST.value-lists.delete-confirm")}
                    confirmText={t("buttons.delete")}
                />
            }
        </>
    );
};

export default ValuePairModalForm;
