import React, { useContext } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { IShaftCalculation, IShaftType, System, SystemDiscipline } from "types";
import { round } from "lodash";
import ElectricityIcon from "../../../../images/icons/TypeElectricity.svg";
import HeatingIcon from "../../../../images/icons/TypeHeating.svg";
import VentilationIcon from "../../../../images/icons/TypeVentilation.svg";
import SewageIcon from "../../../../images/icons/TypeSewage.svg";
import WaterIcon from "../../../../images/icons/TypeWater.svg";
import DrainageIcon from "../../../../images/icons/TypeDrainage.svg";
import NotEnabledIcon from "../../../../images/not-enabled.svg";
import { useTranslation } from "react-i18next";
import { useShaftCalculations, useProjectId, useAuth } from "hooks";
import { useQuery } from "@tanstack/react-query";
import { SystemService } from "services/SystemService";
import toast from "react-hot-toast";

interface Props {
    shaft: IShaftType;
}

const ShaftResultContainer: React.FC<Props> = ({ shaft }) => {
    const { t } = useTranslation();
    const { shaftCalculations } = useShaftCalculations(shaft);
    const projectId = useProjectId();
    const { getAccessToken } = useAuth();
    const { data: systems, isLoading } = useQuery({
        queryKey: ["systems"],
        queryFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) {
                throw new Error("No access token found");
            }
            toast.loading(t("pages.FLAT.systems.loading"));
            return SystemService.getSystems(projectId, accessToken);
        },
        enabled: !!projectId,
        select: response => response.data,
        onSuccess: () => {
            toast.dismiss();
        },
    });

    const shaftSystems = (systems || []).filter(system => shaft.systemIds.includes(system.id));

    const enabledSystems = {
        heating: shaftSystems.some(system => system.systemDiscipline === SystemDiscipline.HEATING),
        water: shaftSystems.some(system => system.systemDiscipline === SystemDiscipline.WATER),
        sewage: shaftSystems.some(system => system.systemDiscipline === SystemDiscipline.SEWAGE),
        ventilation: shaftSystems.some(system => system.systemDiscipline === SystemDiscipline.VENTILATION),
        drainage: shaftSystems.some(system => system.systemDiscipline === SystemDiscipline.DRAINAGE),
        electricity: shaftSystems.some(system => system.systemDiscipline === SystemDiscipline.ELECTRICITY),
    };

    if (isLoading || shaftCalculations.length === 0) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status" variant="primary" />
                <span className="sr-only">Loading...</span>
            </div>
        );
    } else {
        const shaftCalculation = shaftCalculations[0];
        return (
            <Row className="transition h-100">
                <Col>
                    <Row className="h-100">
                        <Col xs={5}>
                            <PipeSizeTable shaftCalculation={shaftCalculation} enabledSystems={enabledSystems} />
                        </Col>
                        <Col className="border-left align-items-center" xs={7}>
                            <div className="d-flex justify-content-center">
                                <img src={NotEnabledIcon} width={250} height={250} alt="" />
                            </div>
                            <div className="d-flex justify-content-center">
                                <h4>Shaft visualization coming soon.</h4>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
};

export default ShaftResultContainer;

interface PipeSizeTableProps {
    shaftCalculation: IShaftCalculation;
    enabledSystems: {
        heating: boolean;
        water: boolean;
        sewage: boolean;
        ventilation: boolean;
        drainage: boolean;
        electricity: boolean;
    };
}

const PipeSizeTable = ({ shaftCalculation, enabledSystems }: PipeSizeTableProps) => {
    const { t } = useTranslation();

    return (
        <>
            {enabledSystems.heating && (
                <Row className="border-bottom">
                    <DashboardSystemCard
                        title={t("pages.FLAT.shafts.results.card.heating")}
                        abbrHeader="-"
                        keyHeader={t("pages.FLAT.shafts.results.card.pipesize")}
                        valueHeader={t("pages.FLAT.shafts.results.card.isolation-class")}
                        abbrs={["VF", "VR"]}
                        keys={[
                            `ø${round(shaftCalculation.heatingFlow ?? 0, 3)}` || "-",
                            `ø${round(shaftCalculation.heatingReturn ?? 0, 3)}` || "-",
                        ]}
                        values={[shaftCalculation?.heatingFlowIso || "-", shaftCalculation.heatingReturnIso || "-"]}
                        icon={HeatingIcon}
                    />
                </Row>
            )}
            {enabledSystems.water && (
                <Row className="border-bottom">
                    <DashboardSystemCard
                        title={t("pages.FLAT.shafts.results.card.water")}
                        abbrHeader="-"
                        keyHeader={t("pages.FLAT.shafts.results.card.pipesize")}
                        valueHeader={t("pages.FLAT.shafts.results.card.isolation-class")}
                        abbrs={["VF", "VR"]}
                        keys={[
                            `ø${round(shaftCalculation.waterCold ?? 0, 3)}` || "-",
                            `ø${round(shaftCalculation.waterHot ?? 0, 3)}` || "-",
                            `ø${round(shaftCalculation.waterCirculation ?? 0, 3)}` || "-",
                        ]}
                        values={[
                            shaftCalculation.coldWaterIso || "-",
                            shaftCalculation.hotWaterIso || "-",
                            shaftCalculation.circulationWaterIso || "-",
                        ]}
                        icon={WaterIcon}
                    />
                </Row>
            )}
            {enabledSystems.sewage && (
                <Row className="border-bottom">
                    <DashboardSystemCard
                        title={t("pages.FLAT.shafts.results.card.sewage")}
                        abbrHeader="-"
                        keyHeader={t("pages.FLAT.shafts.results.card.pipesize")}
                        valueHeader={t("pages.FLAT.shafts.results.card.isolation-class")}
                        abbrs={["SP"]}
                        keys={[`ø${round(shaftCalculation.sewage ?? 0, 3)}` || "-"]}
                        values={["-"]}
                        icon={SewageIcon}
                    />
                </Row>
            )}
            {enabledSystems.ventilation && (
                <Row className="border-bottom">
                    <DashboardSystemCard
                        title={t("pages.FLAT.shafts.results.card.ventilation")}
                        abbrHeader="-"
                        keyHeader={t("pages.FLAT.shafts.results.card.pipesize")}
                        valueHeader={t("pages.FLAT.shafts.results.card.isolation-class")}
                        abbrs={["VI", "VU", "INDT", "AFK"]}
                        keys={[
                            `ø${round(shaftCalculation.ventilationSupply ?? 0, 3)}` || "-",
                            `ø${round(shaftCalculation.ventilationExtract ?? 0, 3)}` || "-",
                            `ø${round(shaftCalculation.ventilationIntake ?? 0, 3)}` || "-",
                            `ø${round(shaftCalculation.ventilationExhaust ?? 0, 3)}` || "-",
                        ]}
                        values={[
                            shaftCalculation.ventilationSupplyIso || "-",
                            shaftCalculation.ventilationExtractIso || "-",
                            shaftCalculation.ventilationIntakeIso || "-",
                            shaftCalculation.ventilationExhaustIso || "-",
                        ]}
                        icon={VentilationIcon}
                    />
                </Row>
            )}
            {enabledSystems.drainage && (
                <Row className="border-bottom">
                    <DashboardSystemCard
                        title={t("pages.FLAT.shafts.results.card.drainage")}
                        abbrHeader="-"
                        keyHeader={t("pages.FLAT.shafts.results.card.pipesize")}
                        valueHeader={t("pages.FLAT.shafts.results.card.isolation-class")}
                        abbrs={["TV"]}
                        keys={[`ø${round(shaftCalculation.drainage ?? 0, 3)}` || "-"]}
                        values={["-"]}
                        icon={DrainageIcon}
                    />
                </Row>
            )}
            {enabledSystems.electricity && (
                <Row>
                    <DashboardSystemCard
                        title={t("pages.FLAT.shafts.results.card.electricity")}
                        abbrHeader="-"
                        keyHeader={t("pages.FLAT.shafts.results.card.traysize")}
                        valueHeader={t("pages.FLAT.shafts.results.card.switchboard")}
                        abbrs={["EL"]}
                        keys={[`${round(shaftCalculation.cableLatter ?? 0, 3)}mm` || "-"]}
                        values={[
                            `${shaftCalculation.powerBoard?.split("x")[0]}mm x ${
                                shaftCalculation.powerBoard?.split("x")[1]
                            }mm` || "-",
                        ]}
                        icon={ElectricityIcon}
                    />
                </Row>
            )}
        </>
    );
};

interface DashboardSystemCardProps {
    title: string;
    abbrHeader: string;
    keyHeader: string;
    valueHeader: string;
    abbrs: string[];
    keys: string[];
    values: string[];
    icon: string;
}

const DashboardSystemCard: React.FC<DashboardSystemCardProps> = ({
    title,
    abbrHeader,
    abbrs,
    keyHeader,
    keys,
    valueHeader,
    values,
    icon,
    ...props
}) => (
    <Card className="border-0" style={{ width: "100%" }}>
        <Card.Body className="my-0 py-3">
            <Card.Title className="d-flex justify-content-between align-items-center">
                {title}
                <img src={icon} alt="" />
            </Card.Title>
            <Row>
                <Col xs={3}>
                    <Card.Subtitle className="mb-1 text-muted text-truncate">
                        <br />
                    </Card.Subtitle>
                    {abbrs &&
                        abbrs.map((abbr, index) => (
                            <Card.Subtitle className="mb-1 text-muted text-truncate" key={index}>
                                <small>{abbr}</small>
                            </Card.Subtitle>
                        ))}
                </Col>

                <Col xs={4}>
                    <Card.Subtitle className="mb-1 text-muted text-truncate">
                        <small>{keyHeader}</small>
                    </Card.Subtitle>
                    {keys &&
                        keys.map((key, index) => (
                            <Card.Subtitle className="mb-1 text-truncate" key={index}>
                                {keys[index] === "ø0" ? <small>-</small> : <small>{key}</small>}
                            </Card.Subtitle>
                        ))}
                </Col>
                <Col xs={5}>
                    <Card.Subtitle className="mb-1 text-muted text-truncate">
                        <small>{valueHeader}</small>
                    </Card.Subtitle>
                    {values &&
                        values.map((value, index) => (
                            <Card.Subtitle className="mb-1 text-truncate" key={index}>
                                {keys[index] === "ø0" ? <small>-</small> : <small>{value}</small>}
                            </Card.Subtitle>
                        ))}
                </Col>
            </Row>
        </Card.Body>
    </Card>
);
