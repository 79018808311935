import React, { useContext } from "react";
import { Button, Dropdown, DropdownButton, Form, InputGroup, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ApartmentsContext, StoreysContext } from "context";
import { Apartment, IShaftConnection, IShaftType } from "types";
import { EMPTY_APARTMENT } from "_constants";

interface Props {
    handleClose: () => void;
    selectedStoreyId: string;
    shaft: IShaftType;
    handleAddShaftConnection: (storeyId: string, apartmentType: Apartment) => void;
    handleRemoveShaftConnection: (scToRemove: IShaftConnection) => void;
    handleRoomCheckField: (roomId: string, sc: IShaftConnection) => void;
}

const ShaftConnectionModal: React.FC<Props> = ({
    handleRoomCheckField,
    handleRemoveShaftConnection,
    handleAddShaftConnection,
    shaft,
    selectedStoreyId,
    handleClose,
}) => {
    const { storeys } = useContext(StoreysContext);
    const { apartments } = useContext(ApartmentsContext);
    const selectedStorey = storeys[shaft.buildingId].find(storey => storey.id === selectedStoreyId);
    const { t } = useTranslation();

    const findApartment = (aId: string) => apartments.find(a => a.id === aId) || EMPTY_APARTMENT;

    const findApartmentRooms = (aId: string) => {
        const apartment = apartments.find(a => a.id === aId);
        const roomsIds = apartment?.rooms;
        return roomsIds || [];
    };

    const roomChosen = (sc: IShaftConnection, roomId: string) => {
        if (sc.roomIds === undefined) {
            return false;
        } else {
            return sc.roomIds.includes(roomId || "");
        }
    };

    return (
        <Modal show={true} size="sm" onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                {`${t("pages.FLAT.shafts.modal-connect.title")} ${selectedStorey?.code}`}
            </Modal.Header>
            <Modal.Body>
                {shaft.shaftConnections &&
                    shaft.shaftConnections
                        .filter(sc => sc.storeyId === selectedStoreyId && sc.apartmentId !== "")
                        .map(sc => (
                            <div key={sc.shaftConnectionUID}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5>{findApartment(sc.apartmentId)?.name}</h5>{" "}
                                    <Button variant="danger" onClick={() => handleRemoveShaftConnection(sc)}>
                                        Fjern
                                    </Button>
                                </div>
                                <Form className="d-grid gap-2">
                                    <Form.Group>
                                        <Form.Label>
                                            {t("pages.FLAT.shafts.modal-connect.subtitle-apartment")}
                                        </Form.Label>
                                        {findApartmentRooms(sc.apartmentId) &&
                                            findApartmentRooms(sc.apartmentId).map((room, index) => (
                                                <Form.Check
                                                    key={index}
                                                    type={"checkbox"}
                                                    checked={roomChosen(sc, room.id)}
                                                    label={`${room.name}`}
                                                    onChange={() => handleRoomCheckField(room.id, sc)}
                                                />
                                            ))}
                                    </Form.Group>
                                </Form>
                                <hr />
                            </div>
                        ))}
                <div>
                    <InputGroup>
                        <DropdownButton
                            variant="primary"
                            title={t("pages.FLAT.shafts.modal-connect.add-apartment")}
                            id="addApartment"
                        >
                            {apartments &&
                                apartments.map(apartmentType => (
                                    <Dropdown.Item
                                        onClick={() => handleAddShaftConnection(selectedStoreyId, apartmentType)}
                                    >
                                        {apartmentType.name}
                                    </Dropdown.Item>
                                ))}
                        </DropdownButton>
                    </InputGroup>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("buttons.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ShaftConnectionModal;
