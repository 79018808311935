import {
    createContext,
    useState,
    ReactNode,
    Dispatch,
    SetStateAction,
    useContext,
    useCallback,
} from "react";
import {
    useAuth,
    useProject,
    useUser,
    useContextTemplates,
    useDocumentsNaming,
    useLogger,
} from "hooks";
import {
    EnergyRapportFields,
    EnergyRapportTables,
    IDocumentTemplate,
    IDocument,
    Report,
} from "types";
import { DocumentsContext, StoreysContext } from "context";
import { INITIAL_STATE } from "_constants";
import axios, { AxiosRequestConfig } from "axios";
import { getDownloadURL, ref } from "firebase/storage";
import { API_URL, storage } from "config";
import { convertDecimalSymbol, onFileInput } from "utils";
import { BE18ModelService } from "../services/ModelService";
import { BE18KeyService } from "../services/KeyService";
import {
    loadFieldsFromBuildingsAndStoreys,
    loadFieldsFromProject,
    loadFieldsFromUser,
    transformFieldKeysToValues,
} from "../services/BE18Services";
import { useQuery } from "@tanstack/react-query";

type ReportMetaData = Omit<Report, "fields" | "tables">;
type Fields = EnergyRapportFields | Record<string, unknown>;
type Tables = EnergyRapportTables | Record<string, Record<string, unknown>[]>;
type WordVariables = {
    fieldsData: Fields;
    tablesData: Tables;
    title?: string;
};
type WordByIdVariables = {
    id: string;
    title?: string;
};
type ReportVariables = {
    id: string;
    reportMetaData: Partial<ReportMetaData>;
};

const DOCUMENT_TEMPLATE_NAME_IDENTIFIER = "energirramme";
const FALLBACK_DOCUMENT_TEMPLATE = {
    name: "Energiramme",
    disciplineId: "GzR0QiPTBPlmDVUZC7MT",
    documentKindCategoryId: "beSHnf1kn911Qp0IvgEZ",
    documentKindId: "4v9hNyIAKZgUQZoKMElN",
    knowledgeId: "uQ5WUyF3sMTuRKLIQBkp",
} as IDocumentTemplate;

export interface IEnergyRapportContex {
    parsing: boolean;
    fields: Fields;
    setFields: Dispatch<SetStateAction<Fields>>;
    handleInitiateNewReport: () => Promise<void>;
    createReport: (variables: ReportMetaData) => Promise<unknown>;
    generateWordReport: (variables: WordVariables) => Promise<unknown>;
    generateWordReportById: (variables: WordByIdVariables) => Promise<unknown>;
    loadModelFile: (file: File) => Promise<void>;
    loadKeyFile: (file: File) => Promise<void>;
    tables: Tables;
    modelFile: File | null;
    keyFile: File | null;
    setCompleted: Dispatch<SetStateAction<boolean>>;
    completed: boolean;
    documentTitle: () => string;
    energyReportTemplate: () => IDocumentTemplate;
}

export const EnergyRapportContext = createContext({} as IEnergyRapportContex);

interface Props {
    projectId?: string;
    children?: ReactNode;
}

const EnergyRapportProvider = ({ projectId, children }: Props): JSX.Element => {
    const [completed, setCompleted] = useState<boolean>(false);
    const [modelFile, setModelFile] = useState<File | null>(null);
    const [keyFile, setKeyFile] = useState<File | null>(null);
    const { storeys: allStoreys } = useContext(StoreysContext);
    const [fields, setFields] = useState<Fields>({});
    const [tables, setTables] = useState<Tables>({});
    const { project } = useProject(projectId || "");
    const { user } = useUser();
    const { user: firebaseAuthUser } = useAuth();
    const { getAccessToken } = useAuth();
    const { handleGenerateDocuments } = useContext(DocumentsContext);
    const logger = useLogger();
    const [parsing, setParsing] = useState<boolean>(true);
    const { generateDocumentData } = useContext(DocumentsContext);
    const { documentTemplates } = useContextTemplates();
    const { previewDocumentNumber } = useDocumentsNaming();
    const reportsQuery = useQuery<Report[]>({
        queryKey: [projectId, "reports"],
        enabled: !!projectId,
        queryFn: async () => {
            const url = `${API_URL}/v1/projects/${projectId}/reports`;
            const accessToken = await getAccessToken();
            if (accessToken === null) {
                throw new Error("Could not get access token");
            }
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return response.data as Report[];
        },
    });
    const _deleteReport = async (id: string) => {
        if (projectId === undefined) {
            throw new Error("Project id is undefined");
        }
        const requestUrl = `${API_URL}/v1/projects/${projectId}/reports/${id}`;
        const accessToken = await getAccessToken();
        if (accessToken === undefined) {
            throw new Error("Could not get access token");
        }
        const response = await axios.delete(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });

        return response.data;
    };

    const _saveReport = async (variables: ReportVariables) => {
        const { id, reportMetaData } = variables;
        if (projectId === undefined) {
            throw new Error("Project id is undefined");
        }
        const requestUrl = `${API_URL}/v1/projects/${projectId}/reports/${id}`;
        const requestBody = JSON.stringify({
            ...getCurrentReportData(),
            ...reportMetaData,
            dateModified: new Date().getTime(),
        });
        const accessToken = await getAccessToken();
        if (accessToken === undefined) {
            throw new Error("Could not get access token");
        }
        const response = await axios.put(requestUrl, requestBody, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.data;
    };

    const documentTitle = (): string => {
        const energyReportTemplate =
            documentTemplates.find(template =>
                template.name
                    .toLowerCase()
                    .includes(DOCUMENT_TEMPLATE_NAME_IDENTIFIER)
            ) || FALLBACK_DOCUMENT_TEMPLATE;

        let data = generateDocumentData(energyReportTemplate) as IDocument;

        let title = previewDocumentNumber(data);
        return title;
    };

    const handleInitiateNewReport = async () => {
        setCompleted(false);
        setFields(() => ({
            ...INITIAL_STATE,
            ...loadFieldsFromUser(user),
            ...loadFieldsFromProject(project!),
            ...loadFieldsFromBuildingsAndStoreys(allStoreys),
        }));
        setTables({});
        setKeyFile(null);
        setModelFile(null);
    };

    const getReport = async (id: string): Promise<Report | undefined> => {
        const reports = reportsQuery.data || [];
        const report = reports.find(r => r.id === id);
        if (report === undefined) {
            Error("Rapporten kan ikke vælges, kontakt venligst support.");
            return;
        }
        return report;
    };

    const getCurrentReportData = () => ({
        fields,
        tables,
    });

    const createReport = async (variables: unknown) => {
        if (firebaseAuthUser === null) {
            throw new Error("User is not logged in");
        }
        const template = energyReportTemplate();
        const docs = await handleGenerateDocuments([template]);
        const documentId = docs[0].id;
        const reportData = {
            fields,
            tables,
            ...(variables as ReportMetaData),
            title: documentTitle(),
            creator: firebaseAuthUser.uid,
            dateCreated: new Date().getTime(),
            documentId,
        };
        const requestUrl = `${API_URL}/v1/projects/${projectId}/reports`;
        const requestBody = JSON.stringify({ report: reportData });
        const accessToken = await getAccessToken();
        if (accessToken === undefined) {
            throw new Error("Could not get access token");
        }
        const response = await axios.post(requestUrl, requestBody, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.data;
    };

    const generateWordReportById = async (variables: WordByIdVariables) => {
        const { id, title } = variables;
        const report = await getReport(id);
        if (report === undefined) return;
        const {
            fields: fieldsData,
            tables: tablesData,
            title: reportTitle,
        } = report;

        return await generateWordReport({
            fieldsData,
            tablesData,
            title: title || reportTitle,
        });
    };

    const generateWordReport = async (variables: WordVariables) => {
        const templateFileUrl = await wordTemplateUrl();
        const values = convertValues(variables);
        const requestData = { values: values, wordFileUrl: templateFileUrl };
        const requestUrl = `${API_URL}/v1/be18/wordgenerator`;
        const requestBody = JSON.stringify({ ...requestData });
        const accessToken = await getAccessToken();
        if (accessToken === undefined) {
            throw new Error("Could not get access token");
        }
        const requestConfig: AxiosRequestConfig = {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
        const response = await axios.post(
            requestUrl,
            requestBody,
            requestConfig
        );
        return response.data;
    };

    const wordTemplateUrl = async () => {
        const templateRef = ref(
            storage,
            `reports/Energiramme skabelon 004.docx`
        );
        const url = await getDownloadURL(templateRef);
        return url;
    };

    const convertValues = (variables: WordVariables) => {
        const { fieldsData, tablesData } = variables;

        const values = transformFieldKeysToValues(
            fieldsData as EnergyRapportFields,
            tablesData as EnergyRapportTables
        );
        const convertedValues = convertDecimalSymbol(
            values as Record<
                string,
                | string
                | number
                | boolean
                | Record<string, string | number | boolean>[]
            >
        );
        return convertedValues;
    };

    const loadModelFile = async (file: File) => {
        setParsing(true);
        setModelFile(file);
        onFileInput(file, (raw: string) => {
            try {
                const service = new BE18ModelService(raw);
                const content = service.getContent();

                setFields(prevFields => ({
                    ...prevFields,
                    ...content.fields,
                }));
                setTables(prevTables => ({
                    ...prevTables,
                    ...content.tables,
                }));
            } catch (error) {
                logger.error(
                    `Error parsing key file, project ${projectId},\n file: ${
                        file.name
                    },\n user: ${firebaseAuthUser?.uid || "unknown"}`,
                    error
                );
            }
            setParsing(false);
        });
    };

    const loadKeyFile = async (file: File) => {
        setParsing(true);
        setKeyFile(file);
        onFileInput(file, (raw: string) => {
            try {
                const service = new BE18KeyService(raw);
                const content = service.getContent();
                setFields(prevFields => ({
                    ...prevFields,
                    ...content.fields,
                }));
            } catch (error) {
                logger.error(
                    `Error parsing key file, project ${projectId},\n file: ${
                        file.name
                    },\n user: ${firebaseAuthUser?.uid || "unknown"}`,
                    error
                );
            }

            setParsing(false);
        });
    };

    const energyReportTemplate = useCallback(
        () =>
            documentTemplates.find(template =>
                template.name
                    .toLowerCase()
                    .includes(DOCUMENT_TEMPLATE_NAME_IDENTIFIER)
            ) || FALLBACK_DOCUMENT_TEMPLATE,
        [documentTemplates]
    );

    return (
        <EnergyRapportContext.Provider
            value={{
                parsing,
                fields,
                tables,
                setFields,
                documentTitle,
                handleInitiateNewReport,
                generateWordReportById,
                generateWordReport,
                loadModelFile,
                loadKeyFile,
                modelFile,
                keyFile,
                completed,
                setCompleted,
                energyReportTemplate,
                createReport,
            }}
        >
            {children}
        </EnergyRapportContext.Provider>
    );
};

export default EnergyRapportProvider;
