import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Building, Storey } from "types";
import { useAuth, useFormFields, useProjectId } from "hooks";
import { StoreysContext } from "context";
import { UNKNOWN_BUILDING_ID } from "_constants";
import _ from "lodash";
import { logEvent } from "config";
import BuildingForm from "./BuildingForm";
import ConfirmModal from "../Shared/ConfirmModal";
import { useQuery } from "@tanstack/react-query";
import { StoreyService } from "services/StoreyServices";

interface Props {
    handleClose: () => void;
    handleSave: (fields: Building) => void;
    handleDelete: (buildingId: string) => void;
    building: Building;
    title: string;
    tool: "FAST" | "other";
}

const BuildingEditModalForm: React.FC<Props> = ({ handleDelete, handleClose, handleSave, building, title, tool }) => {
    const { t } = useTranslation();
    const { fields, handleFieldChange } = useFormFields<Building>(building);
    const [storeys, setStoreys] = useState<Storey[]>([]);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [isInvalid, setIsInvalid] = useState<string[]>([]);
    const { handleSaveStorey } = useContext(StoreysContext);
    const { getAccessToken } = useAuth();
    const projectId = useProjectId();

    const storeysQuery = useQuery({
        queryKey: ["storeys", projectId, building.id],
        queryFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            if (!projectId) throw new Error("No project");
            if (!building.id) throw new Error("No building");

            return StoreyService.getStoreys(projectId, building.id, accessToken);
        },
        enabled: !!projectId && !!building.id,
        onSuccess: data => {
            const sortedStoreys = _.sortBy(data, ["sortPriority", "number"]);
            setStoreys(sortedStoreys);
        },
    });

    const handleSaveStoreyList = (storeyList: Storey[]) => {
        for (let index = 0; index < storeyList.length; index++) {
            const element = storeyList[index];
            handleSaveStorey(fields.id || "", { ...element, sortPriority: index });
        }
    };

    const handleSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        const valid = validateFields();
        if (valid) {
            handleSaveStoreyList(storeys);
            handleSave(fields);
            logEvent("building_save");
        }
    };

    const validateFields = (): boolean => {
        const invalid = [];
        const nameIsValid = () => fields.name !== "";
        const codeIsValid = () => fields.code !== "";

        if (!nameIsValid()) {
            invalid.push("NAME");
        }
        if (!codeIsValid()) {
            invalid.push("CODE");
        }
        if (invalid.length > 0) {
            setIsInvalid(invalid);
            return false;
        }
        return true;
    };

    return (
        <>
            <Modal show={true} onHide={handleClose} backdrop="static" scrollable size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BuildingForm
                        fields={fields}
                        handleFieldChange={handleFieldChange}
                        isInvalid={isInvalid}
                        setIsInvalid={setIsInvalid}
                        storeyList={storeys}
                        setStoreyList={setStoreys}
                        tool={tool}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                    {fields.id !== UNKNOWN_BUILDING_ID && (
                        <Button variant="danger" onClick={() => setShowConfirmModal(true)}>
                            {t("buttons.delete")}
                        </Button>
                    )}
                    <Button disabled={isInvalid.length > 0} variant="primary" onClick={handleSubmit}>
                        {t("buttons.save")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <ConfirmModal
                show={showConfirmModal}
                handleAction={() => {
                    if (building.id !== undefined) {
                        handleDelete(building.id);
                        logEvent("building_delete");
                    }
                }}
                handleClose={() => setShowConfirmModal(false)}
                description={t("pages.shared.locations.confirm-delete")}
                confirmText={t("pages.shared.locations.delete")}
            />
        </>
    );
};

export default BuildingEditModalForm;
