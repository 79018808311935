import React from "react";
import { ListGroup } from "react-bootstrap";
import { CheckCircleFill, Circle } from "react-bootstrap-icons";
import { usePreviewStoreys } from "hooks";
import { Storey } from "types";
import { SPECIALSTOREYS } from "_constants";
import { sortBy } from "lodash";
import { ListItemActionContainer } from "../Shared/ListItem";
import { useTranslation } from "react-i18next";

export interface PreviewStoreyListProps {
    numberOfStoreys: number;
    numberOfBasementStoreys: number;
    specialStoreys: string[];
    handleToggleStorey: (storeyCode: string) => void;
    tool?: "FAST" | "other";
}

export const PreviewStoreyList: React.FC<PreviewStoreyListProps> = ({
    numberOfStoreys,
    numberOfBasementStoreys,
    specialStoreys,
    handleToggleStorey,
    tool = "other",
}) => {
    const storeys = usePreviewStoreys({ numberOfStoreys, numberOfBasementStoreys }, tool);
    const SPECIAL_STOREYS_CODES = SPECIALSTOREYS.map(s => s.code);
    const selectedSpecialStorey = (storey: Storey): boolean =>
        SPECIAL_STOREYS_CODES.includes(storey.code) && !specialStoreys.includes(storey.code); // is the storey a normal storey ? and does it included ind the selcted ones
    const deselectedSpecialStoreyStyle = (storey: Storey) =>
        selectedSpecialStorey(storey) ? { textDecorationLine: "line-through", color: "grey" } : undefined;
    const { t } = useTranslation();

    return (
        <ListGroup>
            {storeys &&
                sortBy(storeys, ["number", "code"]).map(storey => (
                    <ListGroup.Item key={storey.id} className="list-item">
                        <div className="list-item-wrap">
                            <div style={deselectedSpecialStoreyStyle(storey)}>{storey.code}</div>
                            <div style={deselectedSpecialStoreyStyle(storey)}>{storey.name}</div>
                        </div>
                        {SPECIAL_STOREYS_CODES.includes(storey.code) && (
                            <ListItemActionContainer handleOnClick={() => handleToggleStorey(storey.code)}>
                                {specialStoreys.includes(storey.code) ? (
                                    <CheckCircleFill className="text-success" />
                                ) : (
                                    <Circle className="text-dark" />
                                )}
                            </ListItemActionContainer>
                        )}
                    </ListGroup.Item>
                ))}
        </ListGroup>
    );
};
