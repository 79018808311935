import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DrainageSystem } from "types";

type Props<TSystemType> = {
    fields: TSystemType;
    handleFieldChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    inputIsinvalid: boolean;
};

const pipeSizes = [110, 160];

const DrainageForm: React.FC<Props<DrainageSystem>> = ({ fields, handleFieldChange, inputIsinvalid }) => {
    const { t } = useTranslation();

    return (
        <Row>
            <Form.Group as={Col}>
                <Form.Label>
                    {t("pages.FLAT.systems.card-modal.drainage.pipesize")}
                    <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                    id="pipeSize"
                    value={fields.pipeSize}
                    onChange={handleFieldChange}
                    isInvalid={!fields.pipeSize && inputIsinvalid}
                >
                    <option value="" key="">
                        {t("pages.FLAT.systems.card-modal.drainage.pipesize-select")}
                    </option>
                    {pipeSizes.map(size => (
                        <option key={size} value={size}>
                            {`Ø${size}`}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>
        </Row>
    );
};

export default DrainageForm;
