import { useState, Dispatch, SetStateAction, ChangeEvent, MouseEvent, useEffect } from "react";
import { camelCase, set } from "lodash";
import { objectKeys } from "../utils/helpers";

export type FormEvent = ChangeEvent<HTMLInputElement> | SyntheticEvent;

export type ButtonEvent = MouseEvent | MouseEvent<HTMLElement, MouseEvent>;

export type SyntheticEvent = {
    target: {
        id: string;
        value: unknown;
    };
    type: string;
    preventDefault: () => void;
    stopPropagation: () => void;
};

export const useFormFields = <FieldsType>(
    initialState: FieldsType
): {
    fields: FieldsType;
    handleFieldChange: <Event extends SyntheticEvent>(event: Event) => void;
    setFields: Dispatch<SetStateAction<FieldsType>>;
    customHandleFieldChange: (targetId: string, targetValue: unknown) => (e: FormEvent) => void;
    customHandleButtonChange: (targetId: string, targetValue: unknown) => (e: ButtonEvent) => void;
    setField: <K extends keyof FieldsType, T extends FieldsType[K]>(fieldKey: K, value: T) => void;
    handleKeyValueChange: <ListType>(list: ListType[], event: FormEvent) => void;
    handleScaleKeyValueChange: <ListType>(list: ListType[], event: FormEvent) => void;
    handleFormatKeyValueChange: <ListType>(list: ListType[], event: FormEvent) => void;
    touched: { [key in keyof FieldsType]: boolean };
    clearTourched: () => void;
} => {
    const [fields, setValues] = useState<FieldsType>(initialState);
    const initialTourched = objectKeys(initialState as object).map(key => [key, false]);
    const [touched, setTouched] = useState<{ [key in keyof FieldsType]: boolean }>(Object.fromEntries(initialTourched));
    const clearTourched = () => setTouched(Object.fromEntries(initialTourched));

    const touchField = (key: keyof FieldsType) => {
        setTouched(curTourched => ({ ...curTourched, [key]: true }));
    };

    const handleFieldChange = <Event extends SyntheticEvent>(event: Event) => {
        if (event.target.value === undefined) return;
        touchField(event.target.id as keyof FieldsType);
        setValues({
            ...fields,
            [event.target.id]: event.target.value,
        });
    };

    const customHandleFieldChange = (targetId: string, targetValue: unknown) => (e: FormEvent) => {
        const syntheticEventTarget = { ...(e as FormEvent).target, id: targetId, value: targetValue };
        const syntheticEvent = { ...(e as FormEvent), target: syntheticEventTarget };
        touchField(syntheticEvent.target.id as keyof FieldsType);
        handleFieldChange(syntheticEvent);
    };

    const customHandleButtonChange = (targetId: string, targetValue: unknown) => (e: ButtonEvent) => {
        const syntheticEventTarget = { ...e.target, id: targetId, value: targetValue };
        const syntheticEvent = { ...e, target: syntheticEventTarget };
        touchField(syntheticEvent.target.id as keyof FieldsType);
        handleFieldChange(syntheticEvent);
    };

    const setField = <K extends keyof FieldsType, T extends FieldsType[K]>(fieldKey: K, value: T) => {
        touchField(fieldKey);
        setValues(curFields => ({
            ...curFields,
            [fieldKey]: value,
        }));
    };

    const handleKeyValueChange = <ListType>(list: ListType[], event: FormEvent) => {
        const validKeys = "name,value,code,id".split(",");
        const identifyer = (event as SyntheticEvent).target.value;
        const emptyFlag = identifyer === "";
        const fieldId = event.target.id;
        const item = list.filter(listItem =>
            Object.values(listItem as { [key: string]: unknown }).includes(identifyer)
        );
        const selectedItem = item.length === 0 || identifyer === "" ? list[0] : item[0];
        for (const key in selectedItem) {
            const isValidKey = !validKeys.includes(key.toLowerCase());
            if (isValidKey) continue;
            const value = emptyFlag ? "" : selectedItem[key];
            const id = camelCase(`${fieldId} ${key}`);
            touchField(id as keyof FieldsType);
            setValues(curFields => ({ ...curFields, [id]: value }));
        }
    };

    const handleScaleKeyValueChange = <ListType>(list: ListType[], event: FormEvent) => {
        const validKeys = "name,value,code,id".split(",");
        const identifyer = (event as SyntheticEvent).target.value;
        const emptyFlag = identifyer === "";
        const fieldId = event.target.id;
        const item = list.filter(listItem =>
            Object.values(listItem as { [key: string]: unknown }).includes(identifyer)
        );
        const selectedItem = item.length === 0 || identifyer === "" ? list[0] : item[0];
        for (const key in selectedItem) {
            const isValidKey = !validKeys.includes(key.toLowerCase());
            if (isValidKey) continue;
            const value = emptyFlag ? "" : selectedItem[key];
            const id = camelCase(`${fieldId} ${key}`);
            const formattedId = id.replace("Iso", "ISO");
            touchField(formattedId as keyof FieldsType);
            setValues(curFields => ({ ...curFields, [formattedId]: value }));
        }
    };

    const handleFormatKeyValueChange = <ListType>(list: ListType[], event: FormEvent) => {
        const validKeys = "name,code,id,size".split(",");
        const identifyer = (event as SyntheticEvent).target.value;
        const emptyFlag = identifyer === "";
        const fieldId = event.target.id;
        const item = list.filter(listItem =>
            Object.values(listItem as { [key: string]: unknown }).includes(identifyer)
        );
        const selectedItem = item.length === 0 || identifyer === "" ? list[0] : item[0];
        for (const key in selectedItem) {
            const isValidKey = !validKeys.includes(key.toLowerCase());
            if (isValidKey) continue;
            if (key === "size" && !emptyFlag) {
                const [vsize, hsize] = selectedItem[key] as string[];
                const hId = camelCase(`${fieldId} H Size`);
                const vId = camelCase(`${fieldId} V Size`);
                const formattedhId = hId.replace("Iso", "ISO");
                const formattedvId = vId.replace("Iso", "ISO");
                touchField(formattedhId as keyof FieldsType);
                touchField(formattedvId as keyof FieldsType);
                const value = { [formattedhId]: hsize, [formattedvId]: vsize };
                setValues(curFields => ({ ...curFields, ...value }));
            } else {
                const value = emptyFlag ? "" : selectedItem[key];
                const id = camelCase(`${fieldId} ${key === "code" ? "name" : key}`);
                const formattedId = id.replace("Iso", "ISO");
                touchField(formattedId as keyof FieldsType);
                const newValue = { [formattedId]: value };
                setValues(curFields => ({ ...curFields, ...newValue }));
            }
        }
    };

    return {
        fields,
        handleFieldChange,
        setFields: setValues,
        customHandleFieldChange,
        customHandleButtonChange,
        setField,
        handleKeyValueChange,
        handleScaleKeyValueChange,
        handleFormatKeyValueChange,
        touched,
        clearTourched,
    };
};
