import React, { DragEvent } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useEnergyRapport } from "hooks";
import FileInputForm from "../../Shared/FileInputForm";
import { SectionComponent } from "./Sections";
import { useTranslation } from "react-i18next";

export const FilesSection: SectionComponent = () => {
    const { modelFile, keyFile, loadModelFile, loadKeyFile } = useEnergyRapport();
    const { t } = useTranslation();
    // const completed = keyFile !== null && modelFile !== null;

    const handleModelDrop = (e: DragEvent<HTMLDivElement>) => {
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const { files } = e.dataTransfer;
            if (files.length > 1) {
                alert("du kan ikke oploade mere end 1 fil ad gangen.");
                return;
            }
            if (!files[0]) return;
            const file = files[0];
            loadModelFile(file);
        }
    };
    const handleKeyDrop = (e: DragEvent<HTMLDivElement>) => {
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const { files } = e.dataTransfer;
            if (files.length > 1) {
                alert("du kan ikke oploade mere end 1 fil ad gangen.");
                return;
            }
            if (!files[0]) return;
            const file = files[0];
            if (!(file.type.split("/")[1] === "xml")) {
                alert(`Filen er ikke korrekt filformat.`);
                return;
            }
            loadKeyFile(file);
        }
    };

    return (
        <Container fluid className="h-100">
            <Row>
                <Col>
                    <FileInputForm
                        label={t("pages.BE18.sections.files.labels.model") as string}
                        fileFormat="bexml"
                        file={modelFile}
                        handleDrop={handleModelDrop}
                    />
                </Col>
                <Col>
                    <FileInputForm
                        label={t("pages.BE18.sections.files.labels.key") as string}
                        fileFormat="xml"
                        file={keyFile}
                        handleDrop={handleKeyDrop}
                    />
                </Col>
            </Row>
        </Container>
    );
};
