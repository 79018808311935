import { useContext, useState } from "react";
import { Button, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { UserContext } from "context";
import { useContextTemplates, useContextModel } from "hooks";
import { ISheetTemplate } from "types";
import { EMPTY_SHEET_TEMPLATE } from "_constants";
import SheetTemplateModal from "./SheetTemplateModal";
import SheetTemplateListItem from "./SheetTemplateListItem";
interface Props {
    title?: string;
}

const SheetTemplatesContainer: React.FC<Props> = ({ title }) => {
    const [showSheetTemplateModal, setShowSheetTemplateModal] = useState<boolean>(false);
    const [selectedTemplate, setSelectedTemplate] = useState<ISheetTemplate>(EMPTY_SHEET_TEMPLATE);
    const [search, setSearch] = useState<string>("");
    const [filteredKnowledgeId, setFilteredKnowledgeId] = useState<string>("");
    const [filteredSheetTypeId, setFilteredSheetTypeId] = useState<string>("");
    const [filteredDisciplineId, setFilteredDisciplineId] = useState<string>("");
    const { user } = useContext(UserContext);
    const { t } = useTranslation();

    const { sheetTemplates, createSheetTemplate, updateSheetTemplate, deleteSheetTemplate } = useContextTemplates();
    const { contextModel, inCompanyContext, getKnowledgeById, getSheetTypeById, getDisciplineById } = useContextModel();
    const canEditFields = user?.role === "admin" || !inCompanyContext;

    const searchSheetTemplates = sheetTemplates.filter(
        sheetTemplate => !!(search === "") || sheetTemplate.title.toLowerCase().includes(search.toLowerCase())
    );
    const filteredSheetTemplates = searchSheetTemplates.filter(
        sheetTemplate =>
            (!!(filteredKnowledgeId === "") || sheetTemplate.knowledgeId === filteredKnowledgeId) &&
            (!!(filteredSheetTypeId === "") || sheetTemplate.sheetTypeId === filteredSheetTypeId) &&
            (!!(filteredDisciplineId === "") || sheetTemplate.disciplineId === filteredDisciplineId)
    );

    const sortByKnowledgeCode = (st: ISheetTemplate) => getKnowledgeById(st.knowledgeId).code;
    const sortBySheetTypeCode = (st: ISheetTemplate) => getSheetTypeById(st.sheetTypeId).code;
    const sortByDisciplineCode = (st: ISheetTemplate) => getDisciplineById(st.disciplineId).code;
    const sortedSheetTemplates = _.sortBy(filteredSheetTemplates, [
        sortByKnowledgeCode,
        sortBySheetTypeCode,
        "title",
        sortByDisciplineCode,
    ]);

    const handleClose = () => {
        setSelectedTemplate(EMPTY_SHEET_TEMPLATE);
        setShowSheetTemplateModal(false);
    };

    const handleCreate = () => {
        setSelectedTemplate(EMPTY_SHEET_TEMPLATE);
        setShowSheetTemplateModal(true);
    };

    const handleEdit = (sheetTemplate: ISheetTemplate) => {
        setSelectedTemplate(sheetTemplate);
        setShowSheetTemplateModal(true);
    };

    return (
        <Container fluid className="m-0 p-4 h-100 w-100">
            <h1>{title ? title : t("pages.shared.templates.sheet.title")}</h1>
            <h6 className="text-muted">{t("pages.shared.templates.sheet.project-subtitle")}</h6>
            <div className="d-flex flex-column h-100-4rem">
                <div className="mt-2">
                    <Button
                        disabled={!canEditFields}
                        variant="primary"
                        className="d-inline-flex align-items-center"
                        onClick={handleCreate}
                    >
                        <Plus size={20} />
                        <div className="ms-1">{t("pages.shared.templates.sheet.create")}</div>
                    </Button>
                </div>
                <div>
                    <Form className="d-grid gap-2 pt-4">
                        <Row>
                            <Form.Group as={Col} sm={6} lg={3}>
                                <Form.Label>{t("pages.shared.templates.filter.search")}</Form.Label>
                                <Form.Control type="text" value={search} onChange={e => setSearch(e.target.value)} />
                            </Form.Group>
                            <Form.Group as={Col} sm={6} lg={3}>
                                <Form.Label>{t("pages.shared.templates.filter.knowledge")}</Form.Label>
                                <Form.Select
                                    value={filteredKnowledgeId}
                                    onChange={e => setFilteredKnowledgeId(e.target.value)}
                                >
                                    <option value="">{t("pages.shared.templates.filter.all")}</option>
                                    {contextModel.knowledges.map(knowledge => (
                                        <option key={knowledge.id} value={knowledge.id}>
                                            {`${knowledge.code} ${knowledge.value}`}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} sm={6} lg={3}>
                                <Form.Label>{t("pages.shared.templates.filter.sheettype")}</Form.Label>
                                <Form.Select
                                    value={filteredSheetTypeId}
                                    onChange={e => setFilteredSheetTypeId(e.target.value)}
                                >
                                    <option value="">{t("pages.shared.templates.filter.all")}</option>
                                    {contextModel.sheetTypes.map(sheetType => (
                                        <option key={sheetType.id} value={sheetType.id}>
                                            {`${sheetType.code} ${sheetType.value}`}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} sm={6} lg={3}>
                                <Form.Label>{t("pages.shared.templates.filter.discipline")}</Form.Label>
                                <Form.Select
                                    value={filteredDisciplineId}
                                    onChange={e => setFilteredDisciplineId(e.target.value)}
                                >
                                    <option value="">{t("pages.shared.templates.filter.all")}</option>
                                    {contextModel.disciplines.map(discipline => (
                                        <option key={discipline.id} value={discipline.id}>
                                            {`${discipline.code} ${discipline.value}`}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
                <div className="flex-grow-1 overflow-auto">
                    <ListGroup variant="flush" className="mt-3">
                        <ListGroup.Item className="bg-secondary d-flex justify-content-between align-items-center">
                            <small className="w-25">{t("pages.shared.templates.list.name")}</small>
                            <small className="w-25">{t("pages.shared.templates.list.knowledge")}</small>
                            <small className="w-25">{t("pages.shared.templates.list.sheettype")}</small>
                            <small className="w-25">{t("pages.shared.templates.list.discipline")}</small>
                        </ListGroup.Item>
                        {sortedSheetTemplates &&
                            sortedSheetTemplates.map(sheetTemplate => (
                                <SheetTemplateListItem
                                    key={sheetTemplate.id}
                                    template={sheetTemplate}
                                    handleClick={() => handleEdit(sheetTemplate)}
                                />
                            ))}
                    </ListGroup>
                </div>
                {showSheetTemplateModal && (
                    <SheetTemplateModal
                        sheetTemplate={selectedTemplate}
                        handleCreate={createSheetTemplate}
                        handleUpdate={updateSheetTemplate}
                        handleDelete={deleteSheetTemplate}
                        handleClose={handleClose}
                    />
                )}
            </div>
        </Container>
    );
};

export default SheetTemplatesContainer;
