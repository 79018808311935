import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import InvitedUserForm from "./InvitedUserForm";
import fluxlogo from "../../../images/fluxlogos/fluxlogo.svg";

const InvitedUserContainer: React.FC = () => (
    <Container fluid className="h-100">
        <Row className="h-100">
            <Col className="h-100">
                <Row className="h-25 p-4">
                    <Col>
                        <img style={{ width: 175 }} src={fluxlogo} alt="" />
                    </Col>
                </Row>
                <Row className="h-50">
                    <Col className="d-flex justify-content-center align-items-center">
                        <InvitedUserForm />
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
);
export default InvitedUserContainer;
