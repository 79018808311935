import React from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { Col, Container, Row } from "react-bootstrap";
import fluxlogo from "../../images/fluxlogos/fluxlogo.svg";

const StandaloneForgotPasswordContainer: React.FC = () => (
    <Container fluid className="h-100">
        <Row className="h-100">
            <Col className="h-100">
                <Row className="h-25 p-4">
                    <Col>
                        <img style={{ width: 175 }} src={fluxlogo} alt="" />
                    </Col>
                </Row>
                <Row className="h-50">
                    <Col className="d-flex justify-content-center align-items-center">
                        <ForgotPasswordForm />
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
);

export default StandaloneForgotPasswordContainer;
