import React, { useState, useRef } from "react";
import { Nav } from "react-bootstrap";
import { Folder2Open, Headset, Gear, Flag, House, PlusCircle } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth, useHover, useApplicationUI, useLogger } from "hooks";
import Logo from "../../images/fluxlogos/logo_white.png";
import NoTextLogo from "../../images/fluxlogos/logo_no_text.png";
import CreateProjectModalForm from "../Project/CreateProjectModalForm";
import { NavExpandButton, NavGroup, NavItem } from "../Shared/Navigation";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const AppNavigationDrawer: React.FC = () => {
    const { showAppDrawer, handleShowAppDrawer, handleHideAppDrawer, handleToogleAppDrawer } = useApplicationUI();
    const [showCreateProjectForm, setShowCreateProjectForm] = useState(false);
    const { user } = useAuth();
    const isRestricted = !user;
    const hoverCreateProjectRef = useRef(null);
    const hoverCreateProject = useHover<HTMLAnchorElement>(hoverCreateProjectRef);
    const { t } = useTranslation();
    const storage = getStorage();
    const logger = useLogger();

    const handleDownloadSupportPdf = async () => {
        try {
            const fileRef = ref(
                storage,
                `gs://${process.env.REACT_APP_STORAGE_BUCKET}/userguides/FLUX FAST - User Guide - Version 2.pdf`
            );
            const downloadURL = await getDownloadURL(fileRef);

            window.open(downloadURL, "_blank");
        } catch (error) {
            logger.error("Error downloading file:", error);
        }
    };

    return (
        <div className="bg-cloud-burst">
            <Nav className="h-100 w-100 position-relative">
                <NavExpandButton expanded={showAppDrawer} handleToogle={handleToogleAppDrawer} />
                <div
                    className="transition h-100 d-flex flex-column p-3"
                    style={{ width: showAppDrawer ? "14rem" : "5rem" }}
                >
                    <NavGroup style={{ height: "3rem", width: "7rem" }}>
                        <img
                            src={Logo}
                            alt="white logo with text"
                            style={{
                                opacity: showAppDrawer ? 1 : 0,
                                display: showAppDrawer ? "block" : "none",
                                objectFit: "contain",
                                transform: showAppDrawer ? "translateX(0.9rem)" : "translateX(0)",
                                maxHeight: "100%",
                            }}
                        />
                        <img
                            src={NoTextLogo}
                            alt="white logo without text"
                            style={{
                                opacity: showAppDrawer ? 0 : 1,
                                display: showAppDrawer ? "none" : "block",
                                objectFit: "contain",
                                maxHeight: "100%",
                                width: "3rem",
                            }}
                        />
                    </NavGroup>
                    {!isRestricted && (
                        <NavGroup className="flex-grow-1 m-0 p-0 mt-5">
                            <NavItem
                                as={Link}
                                href="#"
                                to="/"
                                icon={House}
                                expanded={showAppDrawer}
                                onClick={handleShowAppDrawer}
                                textWhite
                            >
                                {t("navigation.app.home")}
                            </NavItem>
                            <NavItem
                                as={Link}
                                href="#"
                                to="/projects"
                                icon={Folder2Open}
                                expanded={showAppDrawer}
                                onClick={handleShowAppDrawer}
                                textWhite
                            >
                                <div className="flex-grow-1">{t("navigation.app.projects")}</div>
                                <div
                                    ref={hoverCreateProjectRef}
                                    className="p-0 m-0"
                                    onClick={() => setShowCreateProjectForm(true)}
                                    style={{
                                        color: hoverCreateProject ? "#a1bdf1" : "white",
                                    }}
                                >
                                    <PlusCircle size="1.25rem" />
                                </div>
                            </NavItem>
                        </NavGroup>
                    )}
                    <NavGroup>
                        <NavItem
                            as={Link}
                            href="#"
                            to="/company"
                            icon={Gear}
                            expanded={showAppDrawer}
                            onClick={handleHideAppDrawer}
                            textWhite
                        >
                            {t("navigation.app.settings")}
                        </NavItem>
                        <NavItem
                            onClick={() => handleDownloadSupportPdf()}
                            expanded={showAppDrawer}
                            icon={Headset}
                            textWhite
                        >
                            {t("navigation.app.support")}
                        </NavItem>
                        <NavItem as={Link} href="#" to="/change-log" icon={Flag} expanded={showAppDrawer} textWhite>
                            {t("navigation.app.updates")}
                        </NavItem>
                    </NavGroup>
                </div>
            </Nav>
            {showCreateProjectForm && <CreateProjectModalForm handleClose={() => setShowCreateProjectForm(false)} />}
        </div>
    );
};

export default AppNavigationDrawer;
