import { Col, Form } from "react-bootstrap";
import { BUILDING_COMPACTNESS_OPTIONS, BUILDING_ROOM_SIZES_OPTIONS, BUILDING_MATERIAL_OPTIONS } from "_constants";
import { useEnergyRapport } from "hooks";
import { EnergyRapportFields } from "types";
import { calculateHeatCapacityValue } from "../../../services/BE18Services";
import { SectionComponent } from "./Sections";
import BaseSection, {
    SectionField,
    SectionFieldWithOptions,
    CustomSectionField,
} from "./BaseSection";
import { useTranslation } from "react-i18next";

export const HeatCapacity: SectionComponent = () => {
    const { fields } = useEnergyRapport();
    const { t } = useTranslation();

    return (
        <Form.Group as={Col}>
            <Form.Label>{t("pages.BE18.sections.heating-capacity.labels.total-heat-capacity")}</Form.Label>
            <Form.Control
                value={Math.round(calculateHeatCapacityValue(
                    fields as EnergyRapportFields
                ))}
                type="text"
                disabled
            />
        </Form.Group>
    );
};

const fields: (
    | SectionField
    | SectionFieldWithOptions
    | CustomSectionField
)[][] = [
        [
            {
                type: "dropdown",
                labelId: "pages.BE18.sections.heating-capacity.labels.building-compactness",
                id: "BuildingCompactness",
                options: BUILDING_COMPACTNESS_OPTIONS,
            },
            {
                type: "dropdown",
                labelId: "pages.BE18.sections.heating-capacity.labels.room-size",
                id: "BuildingRoomSizes",
                options: BUILDING_ROOM_SIZES_OPTIONS,
            },
        ],
        [
            {
                type: "options",
                id: "HeatingCapacityRoof",
                labelId: "pages.BE18.sections.heating-capacity.labels.roof-material",
                options: BUILDING_MATERIAL_OPTIONS.filter(
                    material => material.type === "roof"
                ),
            },
        ],
        [
            {
                type: "options",
                id: "HeatingCapacityFloor",
                labelId: "pages.BE18.sections.heating-capacity.labels.floor-material",
                options: BUILDING_MATERIAL_OPTIONS.filter(
                    material => material.type === "floor"
                ),
            },
        ],
        [
            {
                type: "options",
                id: "HeatingCapacityPartitians",
                labelId: "pages.BE18.sections.heating-capacity.labels.partitian-material",
                options: BUILDING_MATERIAL_OPTIONS.filter(
                    material => material.type === "wall"
                ),
            },
        ],
        [
            {
                type: "options",
                id: "HeatingCapacityExterior",
                labelId: "pages.BE18.sections.heating-capacity.labels.partitian-material",
                options: BUILDING_MATERIAL_OPTIONS.filter(
                    material => material.type === "wall"
                ),
            },
        ],
        [
            {
                type: "custom",
                element: HeatCapacity,
            },
            {
                type: "text",
                id: "HeatCapacityTotal",
                labelId: "pages.BE18.sections.heating-capacity.labels.total-heat-capacity",
                dependent: {
                    id: "HeatCapacityTotal",
                    value: "0",
                    condition: "!=",
                },
                readonly: true,
            },
        ],
    ];

export const HeatingCapacitySection = () => <BaseSection data={fields} />;
