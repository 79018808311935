import { useContext, ChangeEvent } from "react";
import { FormEvent } from "hooks";
import { EnergyRapportContext, IEnergyRapportContex } from "context";

const PROPERTIES = [
    "fields",
    "setFields",
    "handleInitiateNewReport",
    "createReport",
    "generateWordReport",
    "generateWordReportById",
    "loadModelFile",
    "loadKeyFile",
    "keyFile",
    "modelFile",
    "tables",
    "completed",
    "documentTitle",
    "energyReportTemplate",
    "setCompleted",
] as const;

type UseEnergyRapport = Pick<
IEnergyRapportContex,
    typeof PROPERTIES[number]
> & {
    handleFieldChange: (event: FormEvent) => void;
};

export const useEnergyRapport = (): UseEnergyRapport => {
    const context = useContext(EnergyRapportContext);

    const handleFieldChange = (event: FormEvent) => {
        if ((event as ChangeEvent<HTMLInputElement>).persist !== undefined) {
            (event as ChangeEvent<HTMLInputElement>).persist();
        }

        if ((event as ChangeEvent<HTMLInputElement>).target.value === undefined)
            return;
        context.setFields(prevFields => ({
            ...prevFields,
            [event.target.id]: (event as ChangeEvent<HTMLInputElement>).target
                .value,
        }));
    };

    const generateOutput = (): UseEnergyRapport => {
        const output: Record<string, unknown> = {
            handleFieldChange,
        };
        for (const prop of PROPERTIES) {
            output[prop] = context[prop];
        }
        return output as UseEnergyRapport;
    };

    return generateOutput();
};
