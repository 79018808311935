import React from "react";
import { Button, Col, Form, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ISheetTemplate, ThemeValueList } from "types";
import { FormEvent, SyntheticEvent } from "hooks";
import _ from "lodash";

interface Props {
    setFields: React.Dispatch<React.SetStateAction<ISheetTemplate>>;
    loadingThemeValueLists: boolean;
    fields: ISheetTemplate;
    themeValueLists: ThemeValueList[];
    canEditFields: boolean;
    handleClose: () => void;
}

const SheetTemplateThemeModalForm: React.FC<Props> = ({
    setFields,
    loadingThemeValueLists,
    canEditFields,
    fields,
    themeValueLists,
    handleClose,
}) => {
    const { t } = useTranslation();

    const handleThemeValueSelect = (e: FormEvent, themeValueListId: string) => {
        const value = e.target.value;
        let themeValueList = themeValueLists.find(tvl => tvl.id === themeValueListId);
        let themeValue = themeValueList?.valuePairs.find(tv => tv.id === value);

        setFields(prevSheetTemplate => ({
            ...prevSheetTemplate,
            themeIds: {
                ...prevSheetTemplate.themeIds,
                [themeValueListId]: themeValue?.id || "",
            },
        }));
    };

    const themeValue = (themeValueListId: string) => {
        if (fields.themeIds) {
            return fields.themeIds[themeValueListId];
        }
        return "";
    };

    return (
        <Modal show={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t("pages.shared.templates.sheet.themevalue-title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loadingThemeValueLists ? (
                    <Spinner animation="border" role="status" className="mt-4">
                        <span className="sr-only">{t("messages.loading")}</span>
                    </Spinner>
                ) : (
                    <Form className="d-grid gap-2">
                        {themeValueLists.map(themeValueList => (
                            <Form.Group as={Col} key={themeValueList.id}>
                                <Form.Label>{themeValueList.name}</Form.Label>
                                <Form.Select
                                    disabled={!canEditFields}
                                    onChange={e => handleThemeValueSelect(e as SyntheticEvent, themeValueList.id)}
                                    value={themeValue(themeValueList.id)}
                                >
                                    <option value="" key="">
                                        {!canEditFields ? "Ingen temaværdi valgt" : "Vælg temaværdi"}
                                    </option>
                                    {_.sortBy(themeValueList.valuePairs, "code").map(valuePair => (
                                        <option
                                            value={valuePair.id}
                                            key={valuePair.id}
                                        >{`${valuePair.code} ${valuePair.value}`}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        ))}
                    </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose}>{t("buttons.close")}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SheetTemplateThemeModalForm;
