import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useProject, useProjectId } from "hooks";
import ProjectFeatureToggle from "./ProjectFeatureToggle";
import ProjectSettingsForm from "./ProjectSettingsForm";
import ProjectSettingsArchive from "./ProjectSettingsArchive";

const ProjectSettingsContainer: React.FC = () => {
    const projectId = useProjectId();
    const { project } = useProject(projectId);

    return (
        <Container fluid className="m-0 p-4 h-100" style={{ overflowY: "auto" }}>
            {project && (
                <>
                    <h1>Project Settings</h1>
                    <h6 className="text-muted">Manage your project settings</h6>
                    <Row>
                        <Col>
                            <ProjectSettingsForm project={project} />
                        </Col>
                    </Row>
                    <Row className="mt-2 ms-0">
                        <ProjectFeatureToggle />
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <ProjectSettingsArchive project={project} />
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    );
};

export default ProjectSettingsContainer;
