import { StoreyCodes } from "../_constants/storey.constants";
import { IModelValuePair, ViewType } from "types";
import { SheetCreationLogic } from "_constants";

export type FirestoreSheet = {
    approvedByPerson: string;
    businessType: string;
    companyVatNo: string;
    contentDescription: string;
    contractType: string;
    createDate: string;
    creatorName: string;
    designedBy: string;
    disciplineId: string;
    disciplineCode: string;
    disciplineValue: string;
    documentationPurpose: string;
    documentClassIdISO5455Id: string;
    documentClassIdISO5457Id: string;
    documentDomainId: string;
    documentForm: string;
    documentId: string;
    documentKind: string;
    documentRevisionId: string;
    documentVersionId: string;
    exchange: string;
    knowledgeCode: string;
    knowledgeId: string;
    knowledgeValue: string;
    languageCode: string;
    levelofInformation: string;
    locationCode: string;
    locationId: string;
    locationName: string;
    organization: string;
    organizationContractId: string;
    organizationContractName: string;
    organizationId: string;
    organizationProjectDescription: string;
    organizationProjectId: string;
    organizationProjectName: string;
    phaseCode: string;
    phaseValue: string;
    processType: string;
    projectClientName: string;
    projectDelivery: string;
    projectDescription: string;
    projectId: string;
    projectName: string;
    releaseDate: string;
    representationFileName: string;
    reviewedByPerson: string;
    revisionText: string;
    revitCreateDate: string;
    serialNumber: string;
    sheetTypeValue: string;
    sheetTypeCode: string;
    stageCode: string;
    stageValue: string;
    status: string;
    storeyCode: string;
    storeyId: string;
    storeyName: string;
    themeCode: string;
    themeValue: string;
    themeId: string;
    title: string;
    workArea: string;

    // our own
    handledByRevit: boolean;
    sheetTemplateId: string;
    id: string;
    uniqueId?: string;
    projectDatabaseId: string;

    // revit specific
    legendName: string;
    viewTemplateName: string;
    viewTypeFamily?: ViewType;
    viewTypeFamilyName?: string;
};

export interface IAbstractDocument {
    approvedByPerson: string;
    businessTypeId: string;
    businessTypeCode: string;
    businessTypeValue: string;
    companyVatNo: string;
    contentDescription: string;
    contractType: string;
    createDate: string;
    creatorName: string;
    designedBy: string;
    disciplineId: string;
    disciplineCode: string;
    disciplineValue: string;
    documentationPurpose: string;
    documentClassIdISO5455Id: string;
    documentClassIdISO5455Code: string;
    documentClassIdISO5455Value: string;
    documentClassIdISO5457Id: string;
    documentClassIdISO5457Name: string;
    documentClassIdISO5457HSize: number;
    documentClassIdISO5457VSize: number;
    documentDomainId: string;
    documentForm: string;
    documentId: string;
    documentKindId: string;
    documentKindValue: string;
    documentKindCode: string;
    documentKindCategoryId: string;
    documentKindCategoryValue: string;
    documentKindCategoryCode: string;
    documentRevisionId: string;
    documentVersionId: string;
    exchange: string;
    fileTypeCode: string;
    fileTypeValue: string;
    fileTypeId: string;
    knowledgeCode: string;
    knowledgeId: string;
    knowledgeValue: string;
    languageCode: string;
    levelofInformation: string;
    locationCode: string;
    locationId: string;
    locationName: string;
    organization: string;
    organizationContractId: string;
    organizationContractName: string;
    organizationId: string;
    organizationProjectDescription: string;
    organizationProjectId: string;
    organizationProjectName: string;
    phaseCode: string;
    phaseValue: string;
    processType: string;
    projectClientName: string;
    projectDelivery: string;
    projectDescription: string;
    projectId: string;
    projectName: string;
    releaseDate: string;
    representationFileName: string;
    reviewedByPerson: string;
    revisionText: string;
    revitCreateDate: string;
    serialNumber: string;
    sheetTypeId: string;
    sheetTypeValue: string;
    sheetTypeCode: string;
    stageCode: string;
    stageValue: string;
    status: string;
    storeyCode: string;
    storeyId: string;
    storeyName: string;
    themeCode: string;
    themeValue: string;
    themeId: string;
    title: string;
    workArea: string;

    // our own
    handledByRevit: boolean;
    sheetTemplateId: string;
    id?: string;
    uniqueId?: string;
    projectDatabaseId: string;

    // revit specific
    locationScopebox: string;
    legendName: string;
    viewTemplateName: string;
    viewFamilyType?: ViewType;
    viewFamilyTypeName?: string;
}

export interface ISheet extends IAbstractDocument {
    id: string;
}

export interface IDocumentTemplate {
    id: string;
    name: string;
    knowledgeId: string;
    disciplineId: string;
    documentKindId: string;
    documentKindCategoryId: string;
}

export interface IDocument extends IAbstractDocument {
    id: string;
}

export interface ISheetTemplate extends AbstractBaseSheetTemplate {
    id: string;
}

export interface IEnrichedSheetTemplate extends ISheetTemplate {
    knowledge: IModelValuePair;
    sheetType: IModelValuePair;
    discipline: IModelValuePair;
    storey: string;
}

export type SheetTemplateStoreyOptions = { [key in StoreyCodes]: string };

export enum SerialRule {
    NONE = "NONE",
    PREFIX = "PREFIX",
    CONSTANT = "CONSTANT",
}

export interface AbstractBaseSheetTemplate {
    id?: string;
    knowledgeId: string;
    sheetTypeId: string;
    disciplineId: string;
    businessTypeId: string;
    serialRule: SerialRule;
    serialRuleContext: string;
    title: string;
    creationLogic: SheetCreationLogic;
    storeyCode?: string;
    documentClassIdISO5457Id: string; // format
    documentClassIdISO5455Id: string; // scale
    fileTypeId: string;
    viewFamilyType?: ViewType;
    viewFamilyTypeName?: string;
    viewTemplate?: string;
    legend?: string;
    themeIds?: { [themeValueListId: string]: string };
}

export interface PlanTemplate extends AbstractBaseSheetTemplate {
    storeyCode: string;
}

export interface LocationSpecificTemplate extends AbstractBaseSheetTemplate {
    locationId: string;
    storeyId: string;
}
