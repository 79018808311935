import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

interface OverlayIconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    overlayText: string;
    children?: React.ReactNode;
    size?: "sm" | "lg" | undefined;
    variant?:
        | "primary"
        | "secondary"
        | "success"
        | "warning"
        | "danger"
        | "info"
        | "light"
        | "dark"
        | "link"
        | undefined;
}

const OverlayIconButton: React.FC<OverlayIconButtonProps> = ({ overlayText, children, ...props }) => (
    <OverlayTrigger
        placement="top"
        delay={{ show: 150, hide: 400 }}
        overlay={<Tooltip id="button-tooltip">{overlayText}</Tooltip>}
    >
        <Button variant="link" className="m-0 ms-1 p-1 d-inline-flex align-items-center" {...props}>
            {children}
        </Button>
    </OverlayTrigger>
);

export default OverlayIconButton;
