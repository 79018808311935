import React, { useContext, useEffect } from "react";
import { Col, Form, FormControl, FormGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BuildingsContext } from "context";
import { LocationSpecificTemplate } from "types";
import { FormEvent, SyntheticEvent, useBuildingStoreys } from "hooks";
import { LEVEL_STOREY } from "_constants";

interface Props {
    fields: LocationSpecificTemplate;
    handleFieldChange: (e: FormEvent) => void;
}

const LocationSpecificTemplateForm: React.FC<Props> = ({ fields, handleFieldChange }) => {
    const { t } = useTranslation();
    const { buildings } = useContext(BuildingsContext);
    const storeys = useBuildingStoreys(fields.locationId);

    useEffect(() => {
        // if location changes and storey doesnt exist on new location, change to first storey
        if (!storeys) return;
        if (!storeys.map(s => s.code).includes(fields.storeyId)) {
            const firstStorey = storeys[0];
            if (!firstStorey) return;
            handleFieldChange({ target: { id: "storeyId", value: "" } } as SyntheticEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeys]);

    return (
        <Form className="d-grid gap-3">
            <FormGroup as={Row} controlId="title">
                <Form.Label column sm="3">
                    {t("pages.FAST.sheet-list.create-sheets.location-specific.fields.description")}
                </Form.Label>
                <Col sm="9">
                    <FormControl defaultValue={fields && fields.title} type="text" onChange={handleFieldChange} />
                </Col>
            </FormGroup>
            <FormGroup as={Row} controlId="locationId">
                <Form.Label column sm="3">
                    {t("pages.FAST.sheet-list.create-sheets.location-specific.fields.location")}
                </Form.Label>
                <Col sm="9">
                    <Form.Select value={fields && fields.locationId} onChange={handleFieldChange}>
                        {buildings.map(location => (
                            <option key={location.code} value={location.id}>
                                {location.name}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
            </FormGroup>
            <Form.Group as={Row} controlId="storeyId">
                <Form.Label column sm="3">
                    {t("pages.FAST.sheet-list.create-sheets.location-specific.fields.storey")}
                </Form.Label>
                <Col sm="9">
                    <Form.Select value={fields && fields.storeyId} onChange={handleFieldChange}>
                        <option>{t(LEVEL_STOREY.name)}</option>
                        {storeys.map(storey => (
                            <option key={storey.id} value={storey.id}>
                                {storey.name}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
            </Form.Group>
        </Form>
    );
};

export default LocationSpecificTemplateForm;
