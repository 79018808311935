import React from "react";
import { Spinner } from "react-bootstrap";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ApartmentContainer from "../components/Location/Apartment/ApartmentContainer";
import SystemsContainer from "../components/Location/Systems/SystemsContainer";
import CalculationContainer from "../components/Location/Shafts/Dashboard/CalculationContainer";
import EmptyFeatureState from "../components/Shared/EmptyFeatureState";
import LocationContainer from "../components/Location/LocationContainer";
import FLATDashboard from "../components/Location/Shafts/FLATDashboard";
import NotEnabledIcon from "../images/not-enabled.svg";
import ApartmentsProvider from "context/ApartmentsProvider";
import ProviderComposer from "context/ProviderComposer";
import RoomsProvider from "context/RoomsProvider";
import ShaftsProvider from "context/ShaftsProvider";
import { useProjectId, useFeatureFlag } from "hooks";
import { useTranslation } from "react-i18next";

const FlatRoutes: React.FC = () => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const projectId = useProjectId();
    const { enabledCompanyFeatureFlags, enabledProjectFeatureFlags, loading } = useFeatureFlag();

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <Spinner animation="grow" />
            </div>
        );
    } else if (!enabledCompanyFeatureFlags.FLAT) {
        return (
            <EmptyFeatureState
                title={t("status.product-not-enabled", { product: "FLAT" })}
                description={t("status.get-access-admin")}
                icon={NotEnabledIcon}
            />
        );
    } else if (!enabledProjectFeatureFlags.FLAT) {
        return (
            <EmptyFeatureState
                title={t("status.product-not-enabled-for-project", { product: "FLAT" })}
                description={t("status.get-access-project-settings")}
                icon={NotEnabledIcon}
            />
        );
    } else {
        return (
            <ProviderComposer providers={[ApartmentsProvider, ShaftsProvider, RoomsProvider]} projectId={projectId}>
                <Switch>
                    <Route exact path={`${url}`}>
                        <FLATDashboard />
                    </Route>
                    <Route exact path={`${url}/locations`}>
                        <LocationContainer />
                    </Route>
                    <Route exact path={`${url}/apartments`}>
                        <ApartmentContainer />
                    </Route>
                    <Route exact path={`${url}/systems`}>
                        <SystemsContainer />
                    </Route>
                    <Route exact path={`${url}/calculations`}>
                        <CalculationContainer />
                    </Route>
                </Switch>
            </ProviderComposer>
        );
    }
};

export default FlatRoutes;
