import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CompanyContext } from "context";
import { useAuth } from "hooks";
import React, { useContext, useState } from "react";
import { Form, FormGroup, FormControl, Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { UserService } from "services";
import { UserCompanyRole } from "types";

type Props = {
    show: boolean;
    handleClose: () => void;
};

const InviteCompanyUserModal: React.FC<Props> = ({ show, handleClose }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState<UserCompanyRole>(UserCompanyRole.user);
    const { t } = useTranslation();
    const { getAccessToken } = useAuth();
    const queryClient = useQueryClient();
    const { company } = useContext(CompanyContext);

    const addCompanyUserMutation = useMutation({
        mutationFn: async (user: { email: string; role: UserCompanyRole; name: string }) => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            if (!company) throw new Error("No company");

            const invitePromise = UserService.inviteCompanyUser(company.id, user, accessToken);
            toast.promise(invitePromise, {
                loading: t("status.loading"),
                success: t("pages.company.users.modal.invite-success"),
                error: t("pages.company.users.modal.invite-error"),
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["companyInvitedUsers"]);
            queryClient.refetchQueries(["companyInvitedUsers"]);
            handleClose();
        },
    });

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t("pages.company.users.modal.title-add")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="d-grid gap-2">
                    <FormGroup>
                        <Form.Label className="pt-2">{t("pages.company.users.modal.name")}</Form.Label>
                        <FormControl
                            value={name}
                            placeholder={t("pages.company.users.modal.name-placeholder") as string}
                            onChange={event => {
                                setName(event.target.value);
                            }}
                        />
                        <Form.Label className="pt-2">{t("pages.company.users.modal.email")}</Form.Label>
                        <FormControl
                            type="email"
                            value={email}
                            placeholder={t("pages.company.users.modal.email-placeholder") as string}
                            onChange={event => {
                                setEmail(event.target.value);
                            }}
                        />
                        <Form.Label className="pt-2">{t("pages.company.users.modal.role")}</Form.Label>
                        <Form.Select
                            value={role}
                            onChange={event => {
                                setRole(event.target.value as UserCompanyRole);
                            }}
                        >
                            <option value="user">{t("pages.company.users.modal.role-user")}</option>
                            <option value="admin">{t("pages.company.users.modal.role-admin")}</option>
                        </Form.Select>
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("buttons.cancel")}
                </Button>
                <Button
                    variant="primary"
                    disabled={addCompanyUserMutation.isLoading}
                    onClick={() => {
                        addCompanyUserMutation.mutate({ email, role, name });
                    }}
                >
                    {t("pages.company.users.modal.button-invite")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default InviteCompanyUserModal;
