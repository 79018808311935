export enum UserCompanyRole {
    user = "user",
    admin = "admin",
}

export type User = {
    name: string;
    email: string;
    knowledgeId: string;
    company: string;
    role: UserCompanyRole;
    projects: string[];
    id: string;
    initials: string;
    viewTemplateName: string;
    invitedUser?: boolean;
};

export type FirestoreTokenUser = {
    companyName: string;
    companyId: string;
    email: string;
    name: string;
    role: "admin" | "user";
    token: string;
};
