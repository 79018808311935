import React, { useContext, useState, useMemo } from "react";
import { Button, Row, Modal, Col, Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SheetsContext } from "context";
import { useFormFields, usePreviewSheetsNumber } from "hooks";
import { ISheet } from "types";
import { EMPTY_SHEET } from "_constants";
import ConfirmModal from "../Shared/ConfirmModal";
import SheetsModalFormFields from "./SheetsModalFormFields";
import { objectKeys, oneBasedOnCollection, keysDifference, useDefault } from "../../utils/helpers";
import { useQueryClient } from "@tanstack/react-query";

interface Props {
    sheetIds: string[];
    handleClose: () => void;
}

const SheetsModalForm: React.FC<Props> = ({ sheetIds, handleClose }) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const { t } = useTranslation();
    const { sheets, handleUpdateMultipleSheetsByOne } = useContext(SheetsContext);
    const selectedSheets = sheets.filter(({ id }) => sheetIds.includes(id));
    const sheetsMerged = useMemo(() => oneBasedOnCollection(selectedSheets), [selectedSheets]);
    const sheet = useDefault(sheetsMerged, EMPTY_SHEET as ISheet);
    const {
        fields,
        handleFieldChange,
        handleKeyValueChange,
        handleScaleKeyValueChange,
        handleFormatKeyValueChange,
        touched,
    } = useFormFields<ISheet>(sheet || EMPTY_SHEET);
    const { previewSheetNumber } = usePreviewSheetsNumber();
    const diffFields = useMemo(() => keysDifference(selectedSheets), [selectedSheets]);
    const queryClient = useQueryClient();

    const handleSubmit = async () => {
        const changedFieldsEntries = Object.entries(touched)
            .filter(([key, value]) => value)
            .map(([key, value]) => [key, fields[key as keyof ISheet]]);
        const changedFields = Object.fromEntries(changedFieldsEntries);
        await handleUpdateMultipleSheetsByOne(changedFields, sheetIds);
        queryClient.invalidateQueries(["sheets"]);
    };

    const sheetNumber = useMemo(() => previewSheetNumber(fields), [fields, previewSheetNumber]);

    return (
        <>
            <Modal size="xl" show={true} onHide={handleClose} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title className="ms-3">
                        {sheetIds.length} {t("pages.FAST.sheet-list.edit-sheet.title-multiple")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mx-0">
                        <Col xs="1">{t("status.editing")}:</Col>
                        <Col>
                            {selectedSheets.map(s => (
                                <Badge key={s.id} className="me-1 text-monospace">
                                    {previewSheetNumber(s)}
                                </Badge>
                            ))}
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <SheetsModalFormFields
                            fields={fields}
                            handleKeyValueChange={handleKeyValueChange}
                            handleFieldChange={handleFieldChange}
                            handleScaleKeyValueChange={handleScaleKeyValueChange}
                            handleFormatKeyValueChange={handleFormatKeyValueChange}
                            previewSheetNumber={sheetNumber}
                            diffFields={diffFields}
                            touched={touched}
                        />
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row className="w-100 mx-0 justify-content-between">
                        <Col xs={8}>
                            {Object.values(touched).filter(a => a).length > 0 && (
                                <p className="align-self-start text-primary">
                                    {t("pages.FAST.sheet-list.edit-sheet.footer-help")}
                                </p>
                            )}
                        </Col>
                        <Col xs={4} className="d-flex justify-content-end">
                            <Button variant="secondary" onClick={handleClose}>
                                {t("buttons.cancel")}
                            </Button>
                            <Button className="ms-2" variant="primary" onClick={() => setShowConfirmModal(true)}>
                                {t("buttons.save")}
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
            <ConfirmModal
                show={showConfirmModal}
                handleAction={() => {
                    handleSubmit();
                    handleClose();
                }}
                handleClose={() => setShowConfirmModal(false)}
                description={`${t("pages.FAST.sheet-list.edit-sheet.warning.text1")} ${objectKeys(touched)
                    .filter(key => touched[key])
                    .join(", ")} ${t("pages.FAST.sheet-list.edit-sheet.warning.text2")} ${selectedSheets.length} ${t(
                    "pages.FAST.sheet-list.edit-sheet.warning.text3"
                )}?`}
                confirmText={t("buttons.save")}
            />
        </>
    );
};

export default SheetsModalForm;
