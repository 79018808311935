import React, { useState } from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { Star, StarFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useContextModel } from "hooks";
import { IModelValuePair, IModelOutputFormat, IModelDocumentKind, IModelItem } from "types";
import { ModelDataType, EMPTY_VALUEPAIR, EMPTY_OUTPUTFORMAT, EMPTY_DOCUMENTKIND } from "_constants";
import _ from "lodash";
import ValuePairModalForm from "./Forms/ValuePairModalForm";
import FormatModalForm from "./Forms/FormatModalForm";
import DocumentKindModalForm from "./Forms/DocumentKindModalForm";

interface Props {
    title: string;
    itemModel: (IModelValuePair | IModelOutputFormat | IModelDocumentKind)[];
    handleClose: () => void;
    handleCreate: (data: unknown) => Promise<void>;
    handleUpdate: (data: IModelValuePair | IModelDocumentKind | IModelOutputFormat) => Promise<void>;
    handleDelete: (id: string) => Promise<void>;
    modelDataType: ModelDataType;
}

const ItemModalList: React.FC<Props> = ({
    title,
    itemModel,
    handleClose,
    handleCreate,
    handleUpdate,
    handleDelete,
    modelDataType,
}) => {
    const { t } = useTranslation();
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] =
        useState<IModelValuePair | IModelDocumentKind | IModelOutputFormat>(EMPTY_VALUEPAIR);
    const { getDocumentKindCategoryById, getFirstDocumendKindCategory } = useContextModel();

    const valuePairText = (item: IModelValuePair) => `${item.code} - ${item.value}`;
    const formatText = (item: IModelOutputFormat) => `${item.code} - ${item.size[0]}x${item.size[1]}`;
    const documentKindText = (item: IModelDocumentKind) =>
        `${getDocumentKindCategoryById(item.documentKindCategoryId)?.code}.${item.subCode} - ${item.value}`;
    const sortByDocumentKindCategoryCode = (dk: IModelDocumentKind) =>
        getDocumentKindCategoryById(dk.documentKindCategoryId).code;

    const handleCreateNewItem = () => {
        if (modelDataType === ModelDataType.VALUEPAIR) {
            setSelectedItem(EMPTY_VALUEPAIR);
        }
        if (modelDataType === ModelDataType.OUTPUTFORMAT) {
            setSelectedItem(EMPTY_OUTPUTFORMAT);
        }
        if (modelDataType === ModelDataType.DOCUMENTKIND) {
            const initial = getFirstDocumendKindCategory();
            const dkc = { ...EMPTY_DOCUMENTKIND, documentKindCategoryId: initial.id };
            setSelectedItem(dkc);
        }
        setShowEditModal(true);
    };

    const handleTogglePreferred = (item: IModelValuePair | IModelDocumentKind | IModelOutputFormat) => {
        handleUpdate({ ...item, preferred: !item.preferred });
    };

    const sortedItems = () => {
        if (modelDataType === ModelDataType.DOCUMENTKIND) {
            return _.sortBy(itemModel as IModelDocumentKind[], [sortByDocumentKindCategoryCode, "subCode", "value"]);
        } else if (modelDataType === ModelDataType.OUTPUTFORMAT) {
            return _.sortBy(itemModel as IModelOutputFormat[], ["code", "size"]);
        } else if (modelDataType === ModelDataType.VALUEPAIR) {
            return _.sortBy(itemModel as IModelValuePair[], ["code", "value"]);
        }
    };

    return (
        <>
            <Modal show={true} onHide={handleClose} backdrop="static" scrollable>
                <Modal.Header closeButton>{title}</Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        <ListGroup.Item variant="primary" action onClick={handleCreateNewItem}>
                            {t("actions.create")} {title.toLowerCase()}
                        </ListGroup.Item>
                        {sortedItems()?.map(item => (
                            <ListGroup.Item
                                className="d-flex justify-content-between align-items-center"
                                action
                                key={(item as IModelItem).id}
                                onClick={() => {
                                    setSelectedItem(item);
                                    setShowEditModal(true);
                                }}
                            >
                                {modelDataType === ModelDataType.VALUEPAIR && valuePairText(item as IModelValuePair)}
                                {modelDataType === ModelDataType.OUTPUTFORMAT && formatText(item as IModelOutputFormat)}
                                {modelDataType === ModelDataType.DOCUMENTKIND &&
                                    documentKindText(item as IModelDocumentKind)}
                                <Button
                                    className={`d-inline-flex align-items-center m-0 p-2 ${
                                        item.preferred ? "text-light" : ""
                                    }`}
                                    onClick={e => {
                                        e.stopPropagation();
                                        handleTogglePreferred(item);
                                    }}
                                    variant={item.preferred ? "success" : "light"}
                                    size="sm"
                                >
                                    {item.preferred ? <StarFill /> : <Star />}
                                </Button>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>{t("buttons.close")}</Button>
                </Modal.Footer>
            </Modal>
            {modelDataType === ModelDataType.VALUEPAIR && showEditModal && (
                <ValuePairModalForm
                    valuePair={selectedItem as IModelValuePair}
                    valuePairModel={itemModel as IModelValuePair[]}
                    handleClose={() => setShowEditModal(false)}
                    handleCreate={handleCreate}
                    handleUpdate={handleUpdate}
                    handleDelete={handleDelete}
                />
            )}
            {modelDataType === ModelDataType.OUTPUTFORMAT && showEditModal && (
                <FormatModalForm
                    format={selectedItem as IModelOutputFormat}
                    formatModel={itemModel as IModelOutputFormat[]}
                    handleClose={() => setShowEditModal(false)}
                    handleCreate={handleCreate}
                    handleUpdate={handleUpdate}
                    handleDelete={handleDelete}
                />
            )}
            {modelDataType === ModelDataType.DOCUMENTKIND && showEditModal && (
                <DocumentKindModalForm
                    documentKind={selectedItem as IModelDocumentKind}
                    documentKindModel={itemModel as IModelDocumentKind[]}
                    handleClose={() => setShowEditModal(false)}
                    handleCreate={handleCreate}
                    handleUpdate={handleUpdate}
                    handleDelete={handleDelete}
                />
            )}
        </>
    );
};

export default ItemModalList;
