import React from 'react'
import { BOOLEAN, INDOOR_REQUIREMENTS_OPTIONS, SHADOW_OPTIONS } from "_constants";
import BaseSection, { SectionField, SectionFieldWithOptions } from "./BaseSection";

const fields: (SectionField | SectionFieldWithOptions)[][] = [
    [
        {
            type: 'options',
            labelId: 'pages.BE18.sections.additional.labels.shadow',
            id: 'Shadow',
            options: SHADOW_OPTIONS,
        },
    ],
    [
        {
            type: 'options',
            labelId: 'pages.BE18.sections.additional.labels.wedge-cut-insulation',
            id: 'hasWedgeCutInsulation',
            options: BOOLEAN,
        },
    ],
    [
        {
            type: 'options',
            id: 'IndoorClimateMethod',
            labelId: 'pages.BE18.sections.additional.labels.iaq-requirments',
            options: INDOOR_REQUIREMENTS_OPTIONS,
        },
    ],
    [
        {
            type: 'text',
            labelId: 'pages.BE18.sections.additional.labels.iaq-report-name',
            id: 'IndoorClimateSimulationName',
            dependent: {
                id: 'IndoorClimateMethod',
                value: 'bsim',
                condition: '=='
            }
        },
    ]
]

export const AdditionalSections = () => <BaseSection data={fields} />