import { IAbstractDocument, IDocument, IDocumentTemplate, ISheetTemplate, SerialRule } from "types";
import { StoreyCodes } from "./storey.constants";

export const EMPTY_DOCUMENT: Partial<IDocument> = {
    id: "",
    title: "",
    disciplineId: "",
    documentKindId: "",
    knowledgeId: "",
    projectId: "",
};

export enum SheetTypes {
    LEGEND = "LEGEND",
    PLAN = "PLAN",
    ELEVATION = "ELEVATION",
    SECTION = "SECTION",
    BUILDINGSECTION = "BUILDINGSECTION",
    CALLOUT = "CALLOUT",
    DIAGRAM = "DIAGRAM",
    SPATIAL_REPRESENTATION = "SPATIAL_REPRESENTATION",
    UNKNOWN = "UNKNOWN",
}

export const EMPTY_DOCUMENT_TEMPLATE: IDocumentTemplate = {
    id: "",
    name: "",
    knowledgeId: "",
    disciplineId: "",
    documentKindId: "",
    documentKindCategoryId: "",
};

export enum SheetCreationLogic {
    CHOOSE_SPECIFIC_LOCATION = "CHOOSE_SPECIFIC_LOCATION",
    PER_STOREY = "PER_STOREY",
    CHOOSE_AMOUNT = "CHOOSE_AMOUNT",
}

export const EMPTY_SHEET_TEMPLATE: ISheetTemplate = {
    id: "",
    title: "",
    storeyCode: StoreyCodes.MULTIPLE_LEVELS,
    knowledgeId: "",
    disciplineId: "",
    serialRule: SerialRule.NONE,
    serialRuleContext: "",
    sheetTypeId: "",
    fileTypeId: "",
    viewTemplate: "",
    legend: "",
    businessTypeId: "",
    documentClassIdISO5457Id: "",
    documentClassIdISO5455Id: "",
    creationLogic: SheetCreationLogic.CHOOSE_AMOUNT,
};

export const EMPTY_SHEET: IAbstractDocument = {
    approvedByPerson: "",
    businessTypeId: "",
    businessTypeCode: "",
    businessTypeValue: "",
    companyVatNo: "",
    contentDescription: "",
    contractType: "",
    createDate: "",
    creatorName: "",
    designedBy: "",
    disciplineId: "",
    disciplineCode: "",
    disciplineValue: "",
    documentationPurpose: "",
    documentClassIdISO5455Id: "",
    documentClassIdISO5457Id: "",
    documentDomainId: "",
    documentForm: "",
    documentId: "",
    documentKindId: "",
    documentRevisionId: "",
    documentVersionId: "",
    exchange: "",
    fileTypeId: "",
    fileTypeCode: "",
    fileTypeValue: "",
    knowledgeId: "",
    knowledgeCode: "",
    knowledgeValue: "",
    languageCode: "",
    levelofInformation: "",
    locationCode: "",
    locationId: "",
    locationName: "",
    organization: "",
    organizationContractId: "",
    organizationContractName: "",
    organizationId: "",
    organizationProjectDescription: "",
    organizationProjectId: "",
    organizationProjectName: "",
    phaseCode: "",
    phaseValue: "",
    processType: "",
    projectClientName: "",
    projectDelivery: "",
    projectDescription: "",
    projectId: "",
    projectName: "",
    releaseDate: "",
    representationFileName: "",
    reviewedByPerson: "",
    revisionText: "",
    revitCreateDate: "",
    serialNumber: "",
    sheetTypeValue: "",
    sheetTypeCode: "",
    stageCode: "",
    stageValue: "",
    status: "",
    storeyCode: "",
    storeyId: "",
    storeyName: "",
    themeCode: "",
    themeValue: "",
    themeId: "",
    title: "",
    workArea: "",
    handledByRevit: false,
    sheetTemplateId: "",
    id: "",
    documentClassIdISO5455Code: "",
    documentClassIdISO5455Value: "",
    documentClassIdISO5457Name: "",
    documentClassIdISO5457HSize: 0,
    documentClassIdISO5457VSize: 0,
    documentKindValue: "",
    documentKindCode: "",
    documentKindCategoryId: "",
    documentKindCategoryValue: "",
    documentKindCategoryCode: "",
    sheetTypeId: "",
    projectDatabaseId: "",
    locationScopebox: "",
    legendName: "",
    viewTemplateName: "",
    viewFamilyType: undefined,
    viewFamilyTypeName: "",
};

export const SheetCreationLogicOptions: Record<SheetCreationLogic, string> = {
    CHOOSE_AMOUNT: "pages.FAST.templates.creation-logic.choose-amount",
    CHOOSE_SPECIFIC_LOCATION: "pages.FAST.templates.creation-logic.choose-specific-location",
    PER_STOREY: "pages.FAST.templates.creation-logic.per-storey",
};

export const SerialRuleOptions: Record<string, string> = {
    NONE: "pages.FAST.templates.serial-number-logic.none",
    PREFIX: "pages.FAST.templates.serial-number-logic.prefix",
    CONSTANT: "pages.FAST.templates.serial-number-logic.constant",
};

export const ViewTypeOptions: Record<string, string> = {
    Undefined: "pages.FAST.templates.viewtype.Undefined",
    FloorPlan: "pages.FAST.templates.viewtype.FloorPlan",
    CeilingPlan: "pages.FAST.templates.viewtype.CeilingPlan",
    EngineeringPlan: "pages.FAST.templates.viewtype.EngineeringPlan",
    AreaPlan: "pages.FAST.templates.viewtype.AreaPlan",
};
