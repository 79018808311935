import React, { useState, MouseEventHandler } from "react";
import { Nav, Button, NavLink } from "react-bootstrap";
import { Building, ChevronDown } from "react-bootstrap-icons";
import { Link, useHistory } from "react-router-dom";
import { useProject, useProjectId } from "hooks";
import { Project } from "types";
import { INavigationMenuItemGroup, PROJECT_DRAWER_NAVIGATION_MENU, PROJECT_SETTINGS_NAVIGATION_MENU } from "_constants";
import { withProjectFeatureFlag } from "../../hoc/withFeatureFlag";
import { useTranslation } from "react-i18next";
import { NavItem, NavGroup, NavItemWithIndicator } from "../Shared/Navigation";

const ProjectDrawerWithFeatureFlag = withProjectFeatureFlag(({ ...props }) => <React.Fragment {...props} />);

const ProjectNavigationDrawer: React.FC = () => {
    const history = useHistory();
    const currentPath = history.location.pathname;
    const currentSelectedTool = currentPath.split("/")[3];
    const projectId = useProjectId();
    const { project } = useProject(projectId);
    const { t } = useTranslation();

    const handleClickProject = (projectId: string) => history.push(`/projects/${projectId}`);

    return (
        <Nav
            className="transition bg-gray-100 border-right d-flex flex-column ps-3 pt-3 pb-3"
            style={{
                paddingRight: "2px",
                minWidth: "14rem",
                width: "16rem",
                overflow: "auto hidden",
                flexWrap: "nowrap",
            }}
        >
            <Nav.Item>
                <NavLink onClick={() => handleClickProject(projectId)} className="d-flex align-items-center">
                    {project?.name}
                </NavLink>
            </Nav.Item>
            <NavGroup className="flex-grow-1 m-0 p-0 mt-4 mb-4 drawer-scrollbar" style={{ height: "100px" }}>
                {PROJECT_DRAWER_NAVIGATION_MENU.map((menuItem: INavigationMenuItemGroup) => (
                    <ProjectNavigationItem
                        key={menuItem.path}
                        menuItem={menuItem}
                        projectId={projectId}
                        project={project}
                        currentSelectedTool={currentSelectedTool}
                        currentPath={currentPath}
                    />
                ))}
            </NavGroup>
            {/* Project settings menu */}
            <NavGroup>
                {PROJECT_SETTINGS_NAVIGATION_MENU.map(menuItem => (
                    <NavItem
                        className="text-gray-800"
                        key={menuItem.url}
                        as={Link}
                        href={`#${menuItem.url}`}
                        to={`/projects/${projectId}/${menuItem.url}`}
                        disabled={project?.archived}
                        icon={menuItem.icon}
                    >
                        <div className={`${currentSelectedTool === menuItem.url ? " text-primary" : ""}`}>
                            {t(menuItem.translationId)}
                        </div>
                    </NavItem>
                ))}
            </NavGroup>
        </Nav>
    );
};

export default ProjectNavigationDrawer;

const ProjectNavigationItem = ({
    menuItem,
    projectId,
    project,
    currentSelectedTool,
    currentPath,
}: {
    menuItem: INavigationMenuItemGroup;
    projectId: string;
    project: Project | undefined;
    currentSelectedTool: string;
    currentPath: string;
}): JSX.Element => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(true);
    const handleToogleSubmenuVisibility: MouseEventHandler<HTMLElement> = e => {
        e.preventDefault();
        e.stopPropagation();
        setExpanded(prev => !prev);
    };

    return (
        <ProjectDrawerWithFeatureFlag
            FAST={menuItem.showOnEnabledFeature.FAST}
            FLAT={menuItem.showOnEnabledFeature.FLAT}
            BE18={menuItem.showOnEnabledFeature.BE18}
        >
            <NavItem
                className="text-gray-800"
                as={Link}
                to={`#${menuItem.url}`}
                onClick={handleToogleSubmenuVisibility}
                disabled={project?.archived}
                icon={menuItem.icon}
            >
                <div className={`flex-grow-1${currentSelectedTool === menuItem.path ? " text-primary" : ""}`}>
                    {t(menuItem.translationId)}
                </div>
                <Button variant="link" className="p-0 m-0" onClick={handleToogleSubmenuVisibility}>
                    {expanded ? (
                        <ChevronDown className="transition transform rotate-180" />
                    ) : (
                        <ChevronDown className="transition transform" />
                    )}
                </Button>
            </NavItem>
            {/* Tool submenu */}
            {menuItem.subPages.map(subPage => {
                if (expanded) {
                    if (subPage.indicator) {
                        return (
                            <NavItemWithIndicator
                                active={currentPath.includes(subPage.url)}
                                key={subPage.url}
                                subPage={subPage}
                                url={`/projects/${projectId}/${subPage.url}`}
                                disabled={project?.archived}
                            />
                        );
                    } else {
                        return (
                            <NavItem
                                className={`ms-3 border-left`}
                                selected={currentPath.includes(subPage.url)}
                                key={subPage.url}
                                as={Link}
                                href={`#`}
                                to={`/projects/${projectId}/${subPage.url}`}
                                disabled={project?.archived}
                            >
                                {t(subPage.translationId)}
                            </NavItem>
                        );
                    }
                } else {
                    return null;
                }
            })}
        </ProjectDrawerWithFeatureFlag>
    );
};
