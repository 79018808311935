import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, FormControl, FormGroup, Modal, ProgressBar, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormFields, useApplicationUI, useContextModel, useFeatureFlag, useAuth } from "hooks";
import { ProjectContext, CompanyContext } from "context";
import { PlatformFeatures, Project } from "types";
import FeatureToggleButton from "../Shared/Buttons/FeatureToggleButton";
import { sortBy } from "lodash";
import { Check, X } from "react-bootstrap-icons";
import LoadingCreateProject from "./LoadingCreateProject";
import { useQuery } from "@tanstack/react-query";
import { UserService } from "services";

interface Props {
    handleClose: () => void;
}

const CreateProjectModalForm: React.FC<Props> = ({ handleClose }) => {
    const { handleHideAppDrawer } = useApplicationUI();
    const { creatingProject, createProjectProgress, createProjectStatus, handleCreateProject } =
        useContext(ProjectContext);
    const { contextModel, stageStillExist, phaseStillExist } = useContextModel();
    const { enabledCompanyFeatureFlags } = useFeatureFlag();
    const { company } = useContext(CompanyContext);
    const { t } = useTranslation();
    const { getAccessToken } = useAuth();
    const history = useHistory();

    const emptyProject: Partial<Project> = {
        id: "",
        client: "",
        name: "",
        number: "",
        internalNumber: "",
        projectleader: "",
        stageId: "",
        phaseId: "",
        projectFeatures: { FAST: true, FLAT: false, BE18: false },
    };
    const { fields, handleFieldChange, setFields } = useFormFields<Project | Partial<Project>>(emptyProject);

    const validInputRegex = /^[\p{L}\p{N} !?=@#$£&%^&\-_]{1,}$/u;
    const nameIsValid = validInputRegex.test(fields?.name || "");
    const numberIsValid = validInputRegex.test(fields?.number || "");
    const internalNumberIsValid = validInputRegex.test(fields?.internalNumber || "");
    const allInputFieldsIsValid = nameIsValid && numberIsValid && internalNumberIsValid;
    const [invalidInputFields, setInvalidInputFields] = useState<boolean>(false);

    const { data: users } = useQuery({
        queryKey: ["companyUsers"],
        queryFn: async () => {
            const accessToken = await getAccessToken();
            if (!accessToken) throw new Error("No access token");
            if (!company) throw new Error("No company");
            return UserService.getCompanyUsers(company.id, accessToken);
        },
        enabled: !!company,
    });

    useEffect(() => {
        if (allInputFieldsIsValid) {
            setInvalidInputFields(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields]);

    const handleSubmit = async () => {
        if (allInputFieldsIsValid) {
            await handleCreateProject(fields).then(projectId => {
                handleClose();
                handleHideAppDrawer();
                history.push(`/projects/${projectId}`);
            });
        } else {
            setInvalidInputFields(true);
        }
    };

    const handleToggleProjectFeature = (feature: keyof PlatformFeatures) => {
        const newProjectFeatures = { ...(fields.projectFeatures as PlatformFeatures) };
        newProjectFeatures[feature] = !newProjectFeatures[feature];
        setFields({ ...fields, projectFeatures: newProjectFeatures });
    };

    return (
        <>
            <Modal show={true} size="lg" onHide={handleClose} backdrop="static">
                <Modal.Header closeButton={!creatingProject}>
                    <Modal.Title>
                        {!creatingProject
                            ? t("pages.shared.project.title-modal")
                            : t("pages.shared.project.title-creating")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!creatingProject ? (
                        <>
                            <Row>
                                <Col>
                                    <h5 className="text-body">{t("pages.shared.project.title-tools")}</h5>
                                    <h6>
                                        <small>{t("pages.shared.project.subtitle-tools")}</small>
                                    </h6>
                                </Col>
                            </Row>
                            <Row className="ms-1">
                                {enabledCompanyFeatureFlags.FAST && (
                                    <FeatureToggleButton
                                        feature="FAST"
                                        handleToggleProjectFeature={handleToggleProjectFeature}
                                        selected={fields.projectFeatures?.FAST || false}
                                    />
                                )}
                                {enabledCompanyFeatureFlags.FLAT && (
                                    <FeatureToggleButton
                                        feature="FLAT"
                                        handleToggleProjectFeature={handleToggleProjectFeature}
                                        selected={fields.projectFeatures?.FLAT || false}
                                    />
                                )}
                                {enabledCompanyFeatureFlags.BE18 && (
                                    <FeatureToggleButton
                                        feature="BE18"
                                        handleToggleProjectFeature={handleToggleProjectFeature}
                                        selected={fields.projectFeatures?.BE18 || false}
                                    />
                                )}
                            </Row>
                            <Row className="mt-4">
                                <h5 className="text-body">{t("pages.shared.project.title-project")}</h5>
                            </Row>
                            <Form className="text-muted d-grid gap-2 mt-2">
                                <Row>
                                    <Col>
                                        <FormGroup controlId="name">
                                            <Form.Label>
                                                {t("pages.shared.project.fields.name")}
                                                <span className="text-danger">*</span>
                                            </Form.Label>
                                            <FormControl
                                                isInvalid={!nameIsValid && invalidInputFields}
                                                autoFocus
                                                type="name"
                                                value={fields.name}
                                                onChange={handleFieldChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <b>{fields.name}</b>
                                                {t("pages.shared.project.settings.invalid-name") as string}
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <FormGroup as={Col} controlId="number">
                                        <Form.Label>
                                            {t("pages.shared.project.fields.id")}
                                            <span className="text-danger">*</span>
                                        </Form.Label>
                                        <FormControl
                                            isInvalid={!numberIsValid && invalidInputFields}
                                            type="text"
                                            value={fields.number}
                                            onChange={handleFieldChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <b>{fields.name}</b>
                                            {t("pages.shared.project.settings.invalid-project-id") as string}
                                        </Form.Control.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} controlId="internalNumber">
                                        <Form.Label>
                                            {t("pages.shared.project.fields.internal-id")}
                                            <span className="text-danger">*</span>
                                        </Form.Label>
                                        <FormControl
                                            isInvalid={!internalNumberIsValid && invalidInputFields}
                                            type="text"
                                            value={fields.internalNumber}
                                            onChange={handleFieldChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <b>{fields.name}</b>
                                            {t("pages.shared.project.settings.invalid-internal-id") as string}
                                        </Form.Control.Feedback>
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup as={Col} controlId="projectleader">
                                        <Form.Label>{t("pages.shared.project.fields.project-leader")}</Form.Label>
                                        <Form.Select value={fields.projectleader} onChange={handleFieldChange}>
                                            <option key="" value="">
                                                {t("status.empty-value")}
                                            </option>
                                            {sortBy(users, "name").map(user => (
                                                <option key={user.id} value={user.name}>
                                                    {user.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </FormGroup>
                                    <FormGroup as={Col} controlId="client">
                                        <Form.Label>{t("pages.shared.project.fields.client")}</Form.Label>
                                        <FormControl type="text" value={fields.client} onChange={handleFieldChange} />
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="stageId">
                                        <Form.Label>{t("pages.shared.project.fields.stage")}</Form.Label>
                                        <Form.Select
                                            isInvalid={!stageStillExist(fields?.stageId || "")}
                                            value={fields && fields.stageId}
                                            onChange={handleFieldChange}
                                        >
                                            <option key="" value="">
                                                {t("status.empty-value")}
                                            </option>
                                            {contextModel &&
                                                sortBy(contextModel.projectStages, ["code", "value"]).map(stage => (
                                                    <option key={stage.id} value={stage.id}>
                                                        {`${stage.code} - ${stage.value}`}
                                                        {stage.preferred && " ★ "}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {t("status.value-deleted")}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="phaseId">
                                        <Form.Label>{t("pages.shared.project.fields.phase")}</Form.Label>
                                        <Form.Select
                                            isInvalid={!phaseStillExist(fields?.phaseId || "")}
                                            value={fields && fields.phaseId}
                                            onChange={handleFieldChange}
                                        >
                                            <option key="" value="">
                                                {t("status.empty-value")}
                                            </option>
                                            {contextModel &&
                                                sortBy(contextModel.projectPhases, ["code", "value"]).map(phase => (
                                                    <option key={phase.id} value={phase.id}>
                                                        {`${phase.code} - ${phase.value}`}
                                                        {phase.preferred && " ★ "}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {t("status.value-deleted")}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Form>
                        </>
                    ) : (
                        <LoadingCreateProject
                            createProjectStatus={createProjectStatus}
                            createProjectProgress={createProjectProgress}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={creatingProject} variant="secondary" onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                    <Button
                        disabled={invalidInputFields || creatingProject}
                        variant="primary"
                        onClick={() => handleSubmit()}
                    >
                        {t("buttons.create")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateProjectModalForm;
