import React, { useContext, useState } from "react";
import { Button, Col, Form, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserContext } from "context";
import { FormEvent, useContextModel, useThemeValueLists } from "hooks";
import { ISheetTemplate, SerialRule, ViewType } from "types";
import {
    SheetCreationLogicOptions,
    SheetCreationLogic,
    STOREY_OPTIONS,
    SerialRuleOptions,
    ViewTypeOptions,
} from "_constants";
import SheetTemplateThemeModal from "./SheetTemplateThemeModalForm";
import { InfoLg } from "react-bootstrap-icons";

interface Props {
    fields: ISheetTemplate;
    handleFieldChange: (e: FormEvent) => void;
    setIsInvalid: (input: (s: string[]) => string[]) => void;
    isInvalid: string[];
    setFields: React.Dispatch<React.SetStateAction<ISheetTemplate>>;
}

const SheetTemplateForm: React.FC<Props> = ({ setFields, fields, handleFieldChange, setIsInvalid, isInvalid }) => {
    const {
        contextModel,
        loadingModel,
        inCompanyContext,
        knowledgeStillExist,
        disciplineStillExist,
        sheetTypeStillExist,
        outputFormatStillExist,
        outputScaleStillExist,
        businessTypeStillExist,
        fileTypeStillExist,
    } = useContextModel();
    const { themeValueLists, loading: loadingThemeValueLists } = useThemeValueLists();
    const { user } = useContext(UserContext);
    const [showThemeValueListModal, setShowThemeValueListModal] = useState<boolean>(false);
    const canEditFields = user?.role === "admin" || !inCompanyContext;
    const { t } = useTranslation();

    const serialRuleContextPlaceholder = () => {
        if (fields.serialRule === "CONSTANT") {
            return "XXXX";
        } else if (fields.serialRule === "PREFIX") {
            return "X";
        } else {
            return "";
        }
    };

    const handleChangeSerialNumberLogic = (event: FormEvent) => {
        handleFieldChange(event);
        if (event.target.value === "NONE") {
            setFields((fields: ISheetTemplate) => ({
                ...fields,
                serialRuleContext: "",
            }));
        }
    };

    const handleChangeViewType = (event: FormEvent) => {
        handleFieldChange(event);
        if (event.target.value === "Undefined") {
            setFields((fields: ISheetTemplate) => ({
                ...fields,
                viewTemplate: "",
            }));
        }
    };

    return (
        <>
            <Form className="d-grid gap-2">
                <Row>
                    <Form.Group as={Col} controlId="title">
                        <Form.Label>
                            {t("pages.shared.templates.sheet.sheetname")}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            disabled={!canEditFields}
                            isInvalid={isInvalid.includes("TITLE")}
                            type="text"
                            placeholder="Ukendt tegning"
                            value={fields.title}
                            onChange={event => {
                                if (isInvalid.includes("TITLE")) {
                                    setIsInvalid((fieldIds: string[]) =>
                                        fieldIds.filter(fieldId => fieldId !== "TITLE")
                                    );
                                }
                                handleFieldChange(event);
                            }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="businessTypeId">
                        <Form.Label>{t("pages.shared.templates.value-title.businesstype")}</Form.Label>
                        <Form.Select
                            disabled={!canEditFields}
                            isInvalid={!businessTypeStillExist(fields.businessTypeId) && !loadingModel}
                            value={fields.businessTypeId}
                            onChange={handleFieldChange}
                        >
                            <option value="">{t("pages.shared.templates.value-empty.businesstype")}</option>
                            {contextModel.businessTypes.map(businessType => (
                                <option value={businessType.id} key={businessType.id}>
                                    {`${businessType.code} - ${businessType.value}`}
                                    {businessType.preferred && " ★ "}
                                </option>
                            ))}
                        </Form.Select>
                        {!businessTypeStillExist(fields.businessTypeId) && (
                            <Form.Control.Feedback type="invalid">
                                {t("pages.shared.templates.value-deleted.businesstype")}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="sheetTypeId">
                        <Form.Label>
                            {t("pages.shared.templates.value-title.sheettype")}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                            disabled={!canEditFields}
                            isInvalid={
                                (isInvalid.includes("SHEETTYPEID") || !sheetTypeStillExist(fields.sheetTypeId)) &&
                                !loadingModel
                            }
                            value={fields.sheetTypeId}
                            onChange={event => {
                                if (isInvalid.includes("SHEETTYPEID")) {
                                    setIsInvalid((fieldIds: string[]) =>
                                        fieldIds.filter(fieldId => fieldId !== "SHEETTYPEID")
                                    );
                                }
                                handleFieldChange(event);
                            }}
                        >
                            <option value="">{t("pages.shared.templates.value-empty.sheettype")}</option>
                            {contextModel.sheetTypes.map(sheetType => (
                                <option value={sheetType.id} key={sheetType.id}>
                                    {`${sheetType.code} - ${sheetType.value}`}
                                    {sheetType.preferred && " ★ "}
                                </option>
                            ))}
                        </Form.Select>
                        {!sheetTypeStillExist(fields.sheetTypeId) && (
                            <Form.Control.Feedback type="invalid">
                                {t("pages.shared.templates.value-deleted.sheettype")}
                            </Form.Control.Feedback>
                        )}
                        {fields.sheetTypeId === "" && (
                            <Form.Control.Feedback type="invalid">
                                {t("pages.shared.templates.value-required.sheettype")}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} controlId="knowledgeId">
                        <Form.Label>
                            {t("pages.shared.templates.value-title.knowledge")}
                            <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                            disabled={!canEditFields}
                            isInvalid={
                                (isInvalid.includes("KNOWLEDGEID") || !knowledgeStillExist(fields.knowledgeId)) &&
                                !loadingModel
                            }
                            value={fields.knowledgeId}
                            onChange={event => {
                                if (isInvalid.includes("KNOWLEDGEID")) {
                                    setIsInvalid((fieldIds: string[]) =>
                                        fieldIds.filter(fieldId => fieldId !== "KNOWLEDGEID")
                                    );
                                }
                                handleFieldChange(event);
                            }}
                        >
                            <option value="">{t("pages.shared.templates.value-empty.knowledge")}</option>
                            {contextModel.knowledges.map(knowledge => (
                                <option value={knowledge.id} key={knowledge.id}>
                                    {`${knowledge.code} - ${knowledge.value}`}
                                    {knowledge.preferred && " ★ "}
                                </option>
                            ))}
                        </Form.Select>
                        {!knowledgeStillExist(fields.knowledgeId) && (
                            <Form.Control.Feedback type="invalid">
                                {t("pages.shared.templates.value-deleted.knowledge")}
                            </Form.Control.Feedback>
                        )}
                        {fields.knowledgeId === "" && (
                            <Form.Control.Feedback type="invalid">
                                {t("pages.shared.templates.value-required.knowledge")}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} controlId="disciplineId">
                        <Form.Label>{t("pages.shared.templates.value-title.discipline")}</Form.Label>
                        <Form.Select
                            disabled={!canEditFields}
                            isInvalid={!disciplineStillExist(fields.disciplineId) && !loadingModel}
                            value={fields.disciplineId}
                            onChange={handleFieldChange}
                        >
                            <option value="">{t("pages.shared.templates.value-empty.discipline")}</option>
                            {contextModel.disciplines.map(discipline => (
                                <option value={discipline.id} key={discipline.id}>
                                    {`${discipline.code} - ${discipline.value}`}
                                    {discipline.preferred && " ★ "}
                                </option>
                            ))}
                        </Form.Select>
                        {!disciplineStillExist(fields.disciplineId) && (
                            <Form.Control.Feedback type="invalid">
                                {t("pages.shared.templates.value-deleted.discipline")}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="documentClassIdISO5455Id">
                        <Form.Label>{t("pages.shared.templates.value-title.scale")}</Form.Label>
                        <Form.Select
                            disabled={!canEditFields}
                            isInvalid={!outputScaleStillExist(fields.documentClassIdISO5455Id) && !loadingModel}
                            value={fields.documentClassIdISO5455Id}
                            onChange={handleFieldChange}
                        >
                            <option value="">{t("pages.shared.templates.value-empty.scale")}</option>
                            {contextModel.outputScales.map(outputScale => (
                                <option value={outputScale.id} key={outputScale.id}>
                                    {outputScale.value}
                                    {outputScale.preferred && " ★ "}
                                </option>
                            ))}
                        </Form.Select>
                        {!outputScaleStillExist(fields.documentClassIdISO5455Id) && (
                            <Form.Control.Feedback type="invalid">
                                {t("pages.shared.templates.value-deleted.scale")}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} controlId="documentClassIdISO5457Id">
                        <Form.Label>{t("pages.shared.templates.value-title.sheetsize")}</Form.Label>
                        <Form.Select
                            disabled={!canEditFields}
                            isInvalid={!outputFormatStillExist(fields.documentClassIdISO5457Id) && !loadingModel}
                            value={fields.documentClassIdISO5457Id}
                            onChange={handleFieldChange}
                        >
                            <option value="">{t("pages.shared.templates.value-empty.sheetsize")}</option>
                            {contextModel.outputFormats.map(outputFormat => (
                                <option value={outputFormat.id} key={outputFormat.id}>
                                    {`${outputFormat.code} - ${outputFormat.size[0]}mm x ${outputFormat.size[1]}mm`}
                                    {outputFormat.preferred && " ★ "}
                                </option>
                            ))}
                        </Form.Select>
                        {!outputFormatStillExist(fields.documentClassIdISO5457Id) && (
                            <Form.Control.Feedback type="invalid">
                                {t("pages.shared.templates.value-deleted.sheetsize")}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} controlId="fileTypeId">
                        <Form.Label>{t("pages.shared.templates.value-title.file-type")}</Form.Label>
                        <Form.Select
                            disabled={!canEditFields}
                            isInvalid={!fileTypeStillExist(fields.fileTypeId) && !loadingModel}
                            value={fields.fileTypeId}
                            onChange={handleFieldChange}
                        >
                            <option value="">{t("pages.shared.templates.value-empty.file-type")}</option>
                            {contextModel.fileTypes.map(fileType => (
                                <option value={fileType.id} key={fileType.id}>
                                    {`${fileType.code} - ${fileType.value}`}
                                    {fileType.preferred && " ★ "}
                                </option>
                            ))}
                        </Form.Select>
                        {!fileTypeStillExist(fields.fileTypeId) && (
                            <Form.Control.Feedback type="invalid">
                                {t("pages.shared.templates.value-deleted.file-type")}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Row>
                <hr />
                <Row>
                    <Form.Group as={Col} controlId="creationLogic">
                        <Form.Label>{t("pages.shared.templates.value-title.creationlogic")}</Form.Label>

                        <OverlayTrigger
                            overlay={
                                <Popover id="creation-logic-popover">
                                    <Popover.Body>{t("pages.shared.templates.tooltips.creationlogic")}</Popover.Body>
                                </Popover>
                            }
                        >
                            <span className="d-inline-block">
                                <Button
                                    disabled
                                    className="rounded-circle p-1 ms-2"
                                    variant="secondary"
                                    size="sm"
                                    style={{ pointerEvents: "none" }}
                                >
                                    <InfoLg className="m-0 p-0 d-block" />
                                </Button>
                            </span>
                        </OverlayTrigger>

                        <Form.Select
                            disabled={!canEditFields}
                            value={fields.creationLogic}
                            onChange={handleFieldChange}
                        >
                            {Object.keys(SheetCreationLogicOptions).map(creationLogic => (
                                <option value={creationLogic} key={creationLogic}>
                                    {t(SheetCreationLogicOptions[creationLogic as SheetCreationLogic])}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} controlId="storeyCode">
                        <Form.Label>{t("pages.shared.templates.value-title.createdfor")}</Form.Label>
                        <Form.Select
                            disabled={fields.creationLogic !== "PER_STOREY" || !canEditFields}
                            value={fields.storeyCode}
                            onChange={handleFieldChange}
                        >
                            {Object.keys(STOREY_OPTIONS).map((storeyCode, index) => (
                                <option value={storeyCode} key={storeyCode}>
                                    {t(STOREY_OPTIONS[storeyCode as keyof typeof STOREY_OPTIONS])}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="serialRule">
                        <Form.Label>{t("pages.shared.templates.value-title.serialnumberlogic")}</Form.Label>

                        <OverlayTrigger
                            overlay={
                                <Popover id="serialnumber-popover">
                                    <Popover.Body>{t("pages.shared.templates.tooltips.serialnumber")}</Popover.Body>
                                </Popover>
                            }
                        >
                            <span className="d-inline-block">
                                <Button
                                    disabled
                                    className="rounded-circle p-1 ms-2"
                                    variant="secondary"
                                    size="sm"
                                    style={{ pointerEvents: "none" }}
                                >
                                    <InfoLg className="m-0 p-0 d-block" />
                                </Button>
                            </span>
                        </OverlayTrigger>

                        <Form.Select
                            disabled={!canEditFields}
                            value={fields.serialRule}
                            onChange={handleChangeSerialNumberLogic}
                        >
                            {Object.keys(SerialRuleOptions).map((serialRule, index) => (
                                <option value={serialRule} key={serialRule}>
                                    {t(SerialRuleOptions[serialRule as SerialRule])}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} controlId="serialRuleContext">
                        <Form.Label>{t("pages.shared.templates.value-title.serialnumber")}</Form.Label>
                        <Form.Control
                            disabled={!canEditFields || fields.serialRule === "NONE" || fields.serialRule === undefined}
                            type="number"
                            placeholder={serialRuleContextPlaceholder()}
                            value={fields.serialRuleContext}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="viewFamilyType">
                        <Form.Label>{t("pages.FAST.templates.value-title.ViewFamilyType")}</Form.Label>
                        <Form.Select
                            disabled={!canEditFields}
                            value={fields.viewFamilyType}
                            onChange={handleChangeViewType}
                        >
                            {Object.keys(ViewTypeOptions).map(viewType => (
                                <option value={viewType} key={viewType}>
                                    {t(ViewTypeOptions[viewType as unknown as ViewType])}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} controlId="viewFamilyTypeName">
                        <Form.Label>{t("pages.FAST.templates.value-title.ViewFamilyTypeName")}</Form.Label>
                        <Form.Control
                            disabled={!canEditFields}
                            type="text"
                            value={fields.viewFamilyTypeName}
                            onChange={handleChangeViewType}
                            placeholder={t("pages.shared.templates.value-placeholder.ViewFamilyTypeName") as string}
                        />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="viewTemplate">
                        <Form.Label>{t("pages.shared.templates.value-title.viewtemplate")}</Form.Label>
                        <OverlayTrigger
                            overlay={
                                <Popover id="viewtemplate-popover">
                                    <Popover.Body>{t("pages.shared.templates.tooltips.viewtemplate")}</Popover.Body>
                                </Popover>
                            }
                        >
                            <span className="d-inline-block">
                                <Button
                                    disabled
                                    className="rounded-circle p-1 ms-2"
                                    variant="secondary"
                                    size="sm"
                                    style={{ pointerEvents: "none" }}
                                >
                                    <InfoLg className="m-0 p-0 d-block" />
                                </Button>
                            </span>
                        </OverlayTrigger>
                        <Form.Control
                            disabled={!canEditFields}
                            type="text"
                            placeholder={t("pages.shared.templates.value-placeholder.viewtemplate") as string}
                            value={fields.viewTemplate}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="legend">
                        <Form.Label>{t("pages.shared.templates.value-title.legend")}</Form.Label>
                        <Form.Control
                            disabled={!canEditFields}
                            type="text"
                            placeholder={t("pages.shared.templates.value-placeholder.legend") as string}
                            value={fields.legend}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                </Row>
                <Col className="d-flex align-items-center justify-content-center">
                    <Button
                        variant="primary"
                        onClick={() => {
                            setShowThemeValueListModal(true);
                        }}
                    >
                        {canEditFields
                            ? `${t("pages.shared.templates.sheet.themevalue-edit")}`
                            : `${t("pages.shared.templates.sheet.themevalue-show")}`}
                    </Button>
                </Col>
            </Form>
            {showThemeValueListModal && (
                <SheetTemplateThemeModal
                    loadingThemeValueLists={loadingThemeValueLists}
                    canEditFields={canEditFields}
                    setFields={setFields}
                    fields={fields}
                    themeValueLists={themeValueLists}
                    handleClose={() => setShowThemeValueListModal(false)}
                />
            )}
        </>
    );
};

export default SheetTemplateForm;
