import { useEffect, useState } from "react"
import { useRouteMatch } from "react-router-dom";
import { firestore } from "config";
import { collection, onSnapshot } from "firebase/firestore";

type UseGetPageContent = 'EMPTY' | 'COMPLETED'

const PAGE_TO_COLLECTION_MAP = {
    locations: 'buildings',
    apartments: 'apartments',
    systems: 'systems',
    calculations: 'shafts',
}

export const useGetPageContent = (path: string): UseGetPageContent => {
    const [pageContent, setPageContent] = useState<UseGetPageContent>('EMPTY')
    const match = useRouteMatch<{ projectId: string }>("/projects/:projectId");
    const projectId = match?.params.projectId || "";
    const page = path.split('/').slice(1)[0];

    useEffect(() => {
        if (!Object.keys(PAGE_TO_COLLECTION_MAP).includes(page)) {
            return;
        }
        const collectionName = PAGE_TO_COLLECTION_MAP[page as keyof typeof PAGE_TO_COLLECTION_MAP];
        const ref = collection(firestore, 'projects', projectId, collectionName);
        const unsubscribe = onSnapshot(ref, snapshot => {
            const data = snapshot.docs;
            let size = 0;
            if (page === 'locations') {
                const locations = data.filter(loc => loc.data().id !== 'UNKNOWN_BUILDING');
                size = locations.length;
            } else {
                size = data.length;
            }

            setPageContent(size > 0 ? 'COMPLETED' : 'EMPTY');
        });
        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId])

    return pageContent;
}