import { useCallback, useContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { CompanyContext } from "context";
import { IModel, IModelDocumentKind, IModelRevitParameter, IModelOutputFormat, IModelValuePair } from "types";
import { EMPTY_DOCUMENTKIND, EMPTY_REVITPARAMETER, UNKNOWN_DOCUMENTKIND, UNKNOWN_VALUEPAIR } from "_constants";
import { collection, onSnapshot, addDoc, doc, setDoc, deleteDoc } from "firebase/firestore";
import { firestore } from "config";
import _ from "lodash";

export const useContextModel = (): {
    contextModel: IModel;
    createDiscipline: (data: unknown) => Promise<void>;
    updateDiscipline: (data: unknown) => Promise<void>;
    deleteDiscipline: (id: string) => Promise<void>;
    createKnowledge: (data: unknown) => Promise<void>;
    updateKnowledge: (data: unknown) => Promise<void>;
    deleteKnowledge: (id: string) => Promise<void>;
    createSheetType: (data: unknown) => Promise<void>;
    updateSheetType: (data: unknown) => Promise<void>;
    deleteSheetType: (id: string) => Promise<void>;
    createOutputFormat: (data: unknown) => Promise<void>;
    updateOutputFormat: (data: unknown) => Promise<void>;
    deleteOutputFormat: (id: string) => Promise<void>;
    createOutputScale: (data: unknown) => Promise<void>;
    updateOutputScale: (data: unknown) => Promise<void>;
    deleteOutputScale: (id: string) => Promise<void>;
    createPhase: (data: unknown) => Promise<void>;
    updatePhase: (data: unknown) => Promise<void>;
    deletePhase: (id: string) => Promise<void>;
    createStage: (data: unknown) => Promise<void>;
    updateStage: (data: unknown) => Promise<void>;
    deleteStage: (id: string) => Promise<void>;
    createDocumentKind: (data: unknown) => Promise<void>;
    updateDocumentKind: (data: unknown) => Promise<void>;
    deleteDocumentKind: (id: string) => Promise<void>;
    createDocumentKindCategory: (data: unknown) => Promise<void>;
    updateDocumentKindCategory: (data: unknown) => Promise<void>;
    deleteDocumentKindCategory: (id: string) => Promise<void>;
    createFileType: (data: unknown) => Promise<void>;
    updateFileType: (data: unknown) => Promise<void>;
    deleteFileType: (id: string) => Promise<void>;
    createBusinessType: (data: unknown) => Promise<void>;
    updateBusinessType: (data: unknown) => Promise<void>;
    deleteBusinessType: (id: string) => Promise<void>;
    createRevitParameter: (data: unknown) => Promise<void>;
    updateRevitParameter: (data: unknown) => Promise<void>;
    deleteRevitParameter: (id: string) => Promise<void>;
    loadingModel: boolean;
    inCompanyContext: boolean;
    getDocumentKindCategoryById: (documentKindId: string) => IModelValuePair;
    getDocumentKindById: (documentKindId: string) => IModelDocumentKind;
    getStageById: (stageId: string) => IModelValuePair;
    getPhaseById: (phaseId: string) => IModelValuePair;
    getKnowledgeById: (knowledgeId: string) => IModelValuePair;
    getDisciplineById: (disciplineId: string) => IModelValuePair;
    getSheetTypeById: (sheetTypeId: string) => IModelValuePair;
    getFileTypeById: (fileTypeId: string) => IModelValuePair;
    getBusinessTypeById: (businessTypeId: string) => IModelValuePair;
    getRevitParameterById: (revitParameterId: string) => IModelRevitParameter;
    stageStillExist: (stageId: string) => boolean;
    phaseStillExist: (phaseId: string) => boolean;
    knowledgeStillExist: (knowledgeId: string) => boolean;
    disciplineStillExist: (disciplineId: string) => boolean;
    documentKindStillExist: (documentKindId: string) => boolean;
    documentKindCategoryStillExist: (documentKindCategoryId: string) => boolean;
    revitParameterStillExist: (revitParameterId: string) => boolean;
    sheetTypeStillExist: (sheetTypeId: string) => boolean;
    outputFormatStillExist: (outputFormatId: string) => boolean;
    outputScaleStillExist: (outputScaleId: string) => boolean;
    businessTypeStillExist: (businessTypeId: string) => boolean;
    fileTypeStillExist: (fileTypeId: string) => boolean;
    getFirstDocumendKindCategory: () => IModelValuePair;
    getDocumentKindCategoryByDocumentKindId: (documentKindId: string) => IModelValuePair;
} => {
    //Skal finde en bedre løsning til dette
    const match = useRouteMatch<{ projectId: string }>("/projects/:projectId");
    const projectId = match?.params.projectId || "";
    const inCompanyContext = projectId === "";
    const { company } = useContext(CompanyContext);

    //constants
    const contextPath = inCompanyContext ? "companies" : "projects";
    const contextId = inCompanyContext ? company?.id : projectId;
    const disciplinePath = "model-disciplines";
    const knowledgePath = "model-knowledges";
    const sheetTypePath = "model-sheetTypes";
    const outputFormatPath = "model-outputFormats";
    const outputScalePath = "model-outputScales";
    const phasePath = "model-projectPhases";
    const stagePath = "model-projectStages";
    const documentKindPath = "model-documentKinds";
    const documentKindCategoryPath = "model-documentKindCategories";
    const businessType = "model-businessTypes";
    const fileTypePath = "model-fileTypes";
    const revitParametersPath = "revit-paramters";

    //loading
    const [loadingDisciplines, setLoadingDisciplines] = useState(true);
    const [loadingKnowledges, setLoadingKnowledges] = useState(true);
    const [loadingSheetTypes, setLoadingSheetTypes] = useState(true);
    const [loadingOutputFormats, setLoadingOutputFormats] = useState(true);
    const [loadingOutputScales, setLoadingOutputScales] = useState(true);
    const [loadingPhases, setLoadingPhases] = useState(true);
    const [loadingStages, setLoadingStages] = useState(true);
    const [loadingDocumentKinds, setLoadingDocumentKinds] = useState(true);
    const [loadingDocumentKindCategories, setLoadingDocumentKindCategories] = useState(true);
    const [loadingFileTypes, setLoadingFileTypes] = useState(true);
    const [loadingRevitParameters, setLoadingRevitParameters] = useState(true);
    const [loadingBusinessTypes, setLoadingBusinessTypes] = useState(true);
    const loadingModel =
        loadingDisciplines ||
        loadingKnowledges ||
        loadingSheetTypes ||
        loadingOutputFormats ||
        loadingOutputScales ||
        loadingPhases ||
        loadingStages ||
        loadingDocumentKinds ||
        loadingDocumentKindCategories ||
        loadingFileTypes ||
        loadingRevitParameters ||
        loadingBusinessTypes;

    //state
    const [Disciplines, setDiscipline] = useState<IModelValuePair[]>([]);
    const [Knowledges, setKnowledges] = useState<IModelValuePair[]>([]);
    const [SheetTypes, setSheetTypes] = useState<IModelValuePair[]>([]);
    const [OutputFormats, setOutputFormats] = useState<IModelOutputFormat[]>([]);
    const [OutputScales, setOutputScales] = useState<IModelValuePair[]>([]);
    const [Phases, setPhases] = useState<IModelValuePair[]>([]);
    const [Stages, setStages] = useState<IModelValuePair[]>([]);
    const [DocumentKinds, setDocumentKinds] = useState<IModelDocumentKind[]>([]);
    const [DocumentKindCategories, setDocumentKindCategories] = useState<IModelValuePair[]>([]);
    const [FileTypes, setFileTypes] = useState<IModelValuePair[]>([]);
    const [revitParameters, setRevitParameters] = useState<IModelRevitParameter[]>([]);
    const [businessTypes, setBusinessTypes] = useState<IModelValuePair[]>([]);
    const contextModel: IModel = {
        disciplines: Disciplines,
        knowledges: Knowledges,
        outputFormats: OutputFormats,
        outputScales: OutputScales,
        projectPhases: Phases,
        projectStages: Stages,
        sheetTypes: SheetTypes,
        documentKinds: DocumentKinds,
        documentKindCategories: DocumentKindCategories,
        fileTypes: FileTypes,
        businessTypes: businessTypes,
        revitParameters,
    };

    //getters
    const getDocumentKindCategoryById = (dkcId: string) =>
        contextModel.documentKindCategories.find(dkc => dkc.id === dkcId) || UNKNOWN_VALUEPAIR;
    const getKnowledgeById = (knowledgeId: string) =>
        contextModel.knowledges.find(knowledge => knowledge.id === knowledgeId) || UNKNOWN_VALUEPAIR;
    const getStageById = (stageId: string) =>
        contextModel.projectStages.find(stage => stage.id === stageId) || UNKNOWN_VALUEPAIR;
    const getPhaseById = (phaseId: string) =>
        contextModel.projectPhases.find(phase => phase.id === phaseId) || UNKNOWN_VALUEPAIR;
    const getDisciplineById = (disciplineId: string) =>
        contextModel.disciplines.find(discipline => discipline.id === disciplineId) || UNKNOWN_VALUEPAIR;
    const getSheetTypeById = (sheetTypeId: string) =>
        contextModel.sheetTypes.find(sheetType => sheetType.id === sheetTypeId) || UNKNOWN_VALUEPAIR;
    const getDocumentKindById = (documentKindId: string) =>
        contextModel.documentKinds.find(documentKind => documentKind.id === documentKindId) || UNKNOWN_DOCUMENTKIND;
    const getFirstDocumendKindCategory = () => contextModel.documentKindCategories[0] || EMPTY_DOCUMENTKIND;
    const getDocumentKindCategoryByDocumentKindId = (documentKindId: string) => {
        const documentKind = getDocumentKindById(documentKindId);
        return getDocumentKindCategoryById(documentKind.documentKindCategoryId);
    };
    const getFileTypeById = (fileTypeId: string) =>
        contextModel.fileTypes.find(fileType => fileType.id === fileTypeId) || UNKNOWN_VALUEPAIR;
    const getRevitParameterById = (revitParameterId: string) =>
        contextModel.revitParameters.find(revitParameter => revitParameter.id === revitParameterId) ||
        EMPTY_REVITPARAMETER;
    const getBusinessTypeById = (businessTypeId: string) =>
        contextModel.businessTypes.find(businessType => businessType.id === businessTypeId) || UNKNOWN_VALUEPAIR;

    //checks
    const stageStillExist = (stageId: string) =>
        !stageId ? true : contextModel.projectStages?.map(s => s.id).includes(stageId);
    const phaseStillExist = (phaseId: string) =>
        !phaseId ? true : contextModel.projectPhases?.map(p => p.id).includes(phaseId);
    const knowledgeStillExist = (knowledgeId: string) =>
        !knowledgeId ? true : contextModel.knowledges?.map(k => k.id).includes(knowledgeId);
    const disciplineStillExist = (disciplineId: string) =>
        !disciplineId ? true : contextModel.disciplines?.map(d => d.id).includes(disciplineId);
    const documentKindStillExist = (documentKindId: string) =>
        !documentKindId ? true : contextModel.documentKinds?.map(dk => dk.id).includes(documentKindId);
    const documentKindCategoryStillExist = (documentKindCategoryId: string) =>
        !documentKindCategoryId
            ? true
            : contextModel.documentKindCategories?.map(dkc => dkc.id).includes(documentKindCategoryId);
    const sheetTypeStillExist = (sheetTypeId: string) =>
        !sheetTypeId ? true : contextModel.sheetTypes?.map(st => st.id).includes(sheetTypeId);
    const outputFormatStillExist = (outputFormatId: string) =>
        !outputFormatId ? true : contextModel.outputFormats?.map(of => of.id).includes(outputFormatId);
    const outputScaleStillExist = (outputScaleId: string) =>
        !outputScaleId ? true : contextModel.outputScales?.map(os => os.id).includes(outputScaleId);
    const fileTypeStillExist = (fileTypeId: string) =>
        !fileTypeId ? true : contextModel.fileTypes?.map(ft => ft.id).includes(fileTypeId);
    const businessTypeStillExist = (businessTypeId: string) =>
        !businessTypeId ? true : contextModel.businessTypes?.map(bt => bt.id).includes(businessTypeId);
    const revitParameterStillExist = (revitParameterId: string) =>
        !revitParameterId ? true : contextModel.revitParameters?.map(mdp => mdp.id).includes(revitParameterId);

    const fetchValuePairType = (
        modelPath: string,
        setModelValues: (model: IModelValuePair[]) => void,
        setLoadingModel: (bool: boolean) => void
    ) => {
        if (!contextId) return;
        setLoadingModel(true);
        const collectionRef = collection(firestore, contextPath, contextId, modelPath);
        const unSubscribe = onSnapshot(collectionRef, collectionSnapshot => {
            const valuePairItems: IModelValuePair[] = [];
            collectionSnapshot.forEach(documentSnapshot => {
                const valuePairItem = documentSnapshot.data() as IModelValuePair;
                if (valuePairItem.preferred === undefined || valuePairItem.preferred === null)
                    valuePairItem.preferred = false;
                valuePairItem.id = documentSnapshot.id;
                valuePairItems.push(valuePairItem);
            });
            const sortedValuePairItems = _.orderBy(valuePairItems, [item => !item.preferred, "code", "value"]);
            if (sortedValuePairItems === undefined) {
                setModelValues([]);
            } else {
                setModelValues(sortedValuePairItems);
            }
            setLoadingModel(false);
        });
        return () => unSubscribe();
    };

    const fetchDocumentKindType = (
        modelPath: string,
        setModelValues: (model: IModelDocumentKind[]) => void,
        setLoadingModel: (bool: boolean) => void
    ) => {
        if (!contextId) return;
        setLoadingModel(true);
        const collectionRef = collection(firestore, contextPath, contextId, modelPath);
        const unSubscribe = onSnapshot(collectionRef, collectionSnapshot => {
            const documentKindItems: IModelDocumentKind[] = [];
            collectionSnapshot.forEach(documentSnapshot => {
                const documentKindItem = documentSnapshot.data() as IModelDocumentKind;
                if (documentKindItem.preferred === undefined || documentKindItem.preferred === null)
                    documentKindItem.preferred = false;
                documentKindItem.id = documentSnapshot.id;
                documentKindItems.push(documentKindItem);
            });

            const sortedDocumentKindItems = _.orderBy(documentKindItems, [
                item => !item.preferred,
                "documentKindCategoryCode",
                "subCode",
                "value",
            ]);

            if (sortedDocumentKindItems === undefined) {
                setModelValues([]);
            } else {
                setModelValues(sortedDocumentKindItems);
            }
            setLoadingModel(false);
        });
        return () => unSubscribe();
    };

    const fetchFormatType = (
        modelPath: string,
        setModelValues: (model: IModelOutputFormat[]) => void,
        setLoadingModel: (bool: boolean) => void
    ) => {
        if (!contextId) return;
        setLoadingModel(true);
        const collectionRef = collection(firestore, contextPath, contextId, modelPath);
        const unSubscribe = onSnapshot(collectionRef, collectionSnapshot => {
            const formatItems: IModelOutputFormat[] = [];
            collectionSnapshot.forEach(documentSnapshot => {
                const formatItem = documentSnapshot.data() as IModelOutputFormat;
                if (formatItem.preferred === undefined || formatItem.preferred === null) formatItem.preferred = false;
                formatItem.id = documentSnapshot.id;
                formatItems.push(formatItem);
            });

            const sortedFormatItems = _.orderBy(formatItems, [item => !item.preferred, "code", "size"]);
            if (sortedFormatItems === undefined) {
                setModelValues([]);
            } else {
                setModelValues(sortedFormatItems);
            }
            setLoadingModel(false);
        });
        return () => unSubscribe();
    };

    const fetchRevitParameter = (
        modelPath: string,
        setModelValues: (model: IModelRevitParameter[]) => void,
        setLoadingModel: (bool: boolean) => void
    ) => {
        if (!contextId) return;
        setLoadingModel(true);
        const collectionRef = collection(firestore, contextPath, contextId, modelPath);
        const unSubscribe = onSnapshot(collectionRef, collectionSnapshot => {
            const revitParameters: IModelRevitParameter[] = [];
            collectionSnapshot.forEach(documentSnapshot => {
                const parameterItem = documentSnapshot.data() as IModelRevitParameter;
                parameterItem.id = documentSnapshot.id;
                revitParameters.push(parameterItem);
            });
            const sortedRevitParameterItems = _.sortBy(revitParameters, ["revitParameterName", "parameterValue", "id"]);
            if (sortedRevitParameterItems === undefined) {
                setModelValues([]);
            } else {
                setModelValues(sortedRevitParameterItems);
            }
            setLoadingModel(false);
        });
        return () => unSubscribe();
    };

    const fetchValuePairCallback = useCallback(fetchValuePairType, [contextId, contextPath]);
    const fetchDocumentKindCallback = useCallback(fetchDocumentKindType, [contextId, contextPath]);
    const fetchFormatCallback = useCallback(fetchFormatType, [contextId, contextPath]);
    const fetchRevitParameterCallback = useCallback(fetchRevitParameter, [contextId, contextPath]);

    //disciplines
    useEffect(() => {
        fetchValuePairCallback(disciplinePath, setDiscipline, setLoadingDisciplines);
    }, [fetchValuePairCallback]);

    //knowledges
    useEffect(() => {
        fetchValuePairCallback(knowledgePath, setKnowledges, setLoadingKnowledges);
    }, [fetchValuePairCallback]);

    //sheettypes
    useEffect(() => {
        fetchValuePairCallback(sheetTypePath, setSheetTypes, setLoadingSheetTypes);
    }, [fetchValuePairCallback]);

    //formats
    useEffect(() => {
        fetchFormatCallback(outputFormatPath, setOutputFormats, setLoadingOutputFormats);
    }, [fetchFormatCallback]);

    //scales
    useEffect(() => {
        fetchValuePairCallback(outputScalePath, setOutputScales, setLoadingOutputScales);
    }, [fetchValuePairCallback]);

    //phases
    useEffect(() => {
        fetchValuePairCallback(phasePath, setPhases, setLoadingPhases);
    }, [fetchValuePairCallback]);

    //stages
    useEffect(() => {
        fetchValuePairCallback(stagePath, setStages, setLoadingStages);
    }, [fetchValuePairCallback]);

    //documentKind
    useEffect(() => {
        fetchDocumentKindCallback(documentKindPath, setDocumentKinds, setLoadingDocumentKinds);
    }, [fetchDocumentKindCallback]);

    //documentKindCategory
    useEffect(() => {
        fetchValuePairCallback(documentKindCategoryPath, setDocumentKindCategories, setLoadingDocumentKindCategories);
    }, [fetchValuePairCallback]);

    //fileTypes
    useEffect(() => {
        fetchValuePairCallback(fileTypePath, setFileTypes, setLoadingFileTypes);
    }, [fetchValuePairCallback]);

    //businessTypes
    useEffect(() => {
        fetchValuePairCallback(businessType, setBusinessTypes, setLoadingBusinessTypes);
    }, [fetchValuePairCallback]);

    useEffect(() => {
        fetchRevitParameterCallback(revitParametersPath, setRevitParameters, setLoadingRevitParameters);
    }, [fetchRevitParameterCallback]);

    const createDiscipline = async (data: unknown) => {
        createModelItem(data as IModelValuePair, disciplinePath);
    };
    const createKnowledge = async (data: unknown) => {
        createModelItem(data as IModelValuePair, knowledgePath);
    };
    const createSheetType = async (data: unknown) => {
        createModelItem(data as IModelValuePair, sheetTypePath);
    };
    const createOutputFormat = async (data: unknown) => {
        createModelItem(data as IModelOutputFormat, outputFormatPath);
    };
    const createOutputScale = async (data: unknown) => {
        createModelItem(data as IModelValuePair, outputScalePath);
    };
    const createPhase = async (data: unknown) => {
        createModelItem(data as IModelValuePair, phasePath);
    };
    const createStage = async (data: unknown) => {
        createModelItem(data as IModelValuePair, stagePath);
    };
    const createDocumentKind = async (data: unknown) => {
        createModelItem(data as IModelDocumentKind, documentKindPath);
    };
    const createDocumentKindCategory = async (data: unknown) => {
        createModelItem(data as IModelValuePair, documentKindCategoryPath);
    };
    const createFileType = async (data: unknown) => {
        createModelItem(data as IModelValuePair, fileTypePath);
    };
    const createBusinessType = async (data: unknown) => {
        createModelItem(data as IModelValuePair, businessType);
    };
    const createRevitParameter = async (data: unknown) => {
        createModelItem(data as IModelRevitParameter, fileTypePath);
    };

    const createModelItem = async (
        data: IModelValuePair | IModelOutputFormat | IModelDocumentKind | IModelRevitParameter,
        modelPath: string
    ) => {
        if (!contextId) {
            return;
        }
        try {
            const collectionRef = collection(firestore, contextPath, contextId, modelPath);
            const { id, ...cleanedData } = data;
            await addDoc(collectionRef, cleanedData);
        } catch (error) {
            //TODO
        }
    };

    const updateDiscipline = async (data: unknown) => {
        updateModelItem(data as IModelValuePair, disciplinePath);
    };
    const updateKnowledge = async (data: unknown) => {
        updateModelItem(data as IModelValuePair, knowledgePath);
    };
    const updateSheetType = async (data: unknown) => {
        updateModelItem(data as IModelValuePair, sheetTypePath);
    };
    const updateOutputFormat = async (data: unknown) => {
        updateModelItem(data as IModelOutputFormat, outputFormatPath);
    };
    const updateOutputScale = async (data: unknown) => {
        updateModelItem(data as IModelValuePair, outputScalePath);
    };
    const updatePhase = async (data: unknown) => {
        updateModelItem(data as IModelValuePair, phasePath);
    };
    const updateStage = async (data: unknown) => {
        updateModelItem(data as IModelValuePair, stagePath);
    };
    const updateDocumentKind = async (data: unknown) => {
        updateModelItem(data as IModelDocumentKind, documentKindPath);
    };
    const updateDocumentKindCategory = async (data: unknown) => {
        updateModelItem(data as IModelValuePair, documentKindCategoryPath);
    };
    const updateFileType = async (data: unknown) => {
        updateModelItem(data as IModelValuePair, fileTypePath);
    };
    const updateBusinessType = async (data: unknown) => {
        updateModelItem(data as IModelValuePair, businessType);
    };
    const updateRevitParameter = async (data: unknown) => {
        updateModelItem(data as IModelRevitParameter, revitParametersPath);
    };

    const updateModelItem = async (
        data: IModelValuePair | IModelOutputFormat | IModelDocumentKind | IModelRevitParameter,
        path: string
    ) => {
        if (!contextId) {
            return;
        }

        try {
            const documentRef = doc(firestore, contextPath, contextId, path, data.id);
            const { id, ...cleanedData } = data;
            await setDoc(documentRef, cleanedData, { merge: true });
        } catch (error) {
            //TODO
        }
    };

    const deleteDiscipline = async (id: string) => {
        deleteModelItem(id, disciplinePath);
    };
    const deleteKnowledge = async (id: string) => {
        deleteModelItem(id, knowledgePath);
    };
    const deleteSheetType = async (id: string) => {
        deleteModelItem(id, sheetTypePath);
    };
    const deleteOutputFormat = async (id: string) => {
        deleteModelItem(id, outputFormatPath);
    };
    const deleteOutputScale = async (id: string) => {
        deleteModelItem(id, outputScalePath);
    };
    const deletePhase = async (id: string) => {
        deleteModelItem(id, phasePath);
    };
    const deleteStage = async (id: string) => {
        deleteModelItem(id, stagePath);
    };
    const deleteDocumentKind = async (id: string) => {
        deleteModelItem(id, documentKindPath);
    };
    const deleteDocumentKindCategory = async (id: string) => {
        deleteModelItem(id, documentKindCategoryPath);
    };
    const deleteFileType = async (id: string) => {
        deleteModelItem(id, fileTypePath);
    };
    const deleteBusinessType = async (id: string) => {
        deleteModelItem(id, businessType);
    };
    const deleteRevitParameter = async (id: string) => {
        deleteModelItem(id, revitParametersPath);
    };

    const deleteModelItem = async (id: string, modelPath: string) => {
        if (!contextId) {
            return;
        }
        try {
            const documentRef = doc(firestore, contextPath, contextId, modelPath, id);
            await deleteDoc(documentRef);
        } catch (error) {
            //TODO
        }
    };

    return {
        contextModel,
        createDiscipline,
        updateDiscipline,
        deleteDiscipline,
        createKnowledge,
        updateKnowledge,
        deleteKnowledge,
        createSheetType,
        updateSheetType,
        deleteSheetType,
        createOutputFormat,
        updateOutputFormat,
        deleteOutputFormat,
        createOutputScale,
        updateOutputScale,
        deleteOutputScale,
        createPhase,
        updatePhase,
        deletePhase,
        createStage,
        updateStage,
        deleteStage,
        createDocumentKind,
        updateDocumentKind,
        deleteDocumentKind,
        createDocumentKindCategory,
        updateDocumentKindCategory,
        deleteDocumentKindCategory,
        createFileType,
        updateFileType,
        deleteFileType,
        createBusinessType,
        updateBusinessType,
        deleteBusinessType,
        loadingModel,
        inCompanyContext,
        getDocumentKindCategoryById,
        getDocumentKindById,
        getPhaseById,
        getStageById,
        getKnowledgeById,
        getDisciplineById,
        getSheetTypeById,
        getFirstDocumendKindCategory,
        getDocumentKindCategoryByDocumentKindId,
        getFileTypeById,
        getBusinessTypeById,
        stageStillExist,
        phaseStillExist,
        knowledgeStillExist,
        disciplineStillExist,
        documentKindStillExist,
        documentKindCategoryStillExist,
        sheetTypeStillExist,
        outputFormatStillExist,
        outputScaleStillExist,
        fileTypeStillExist,
        businessTypeStillExist,
        createRevitParameter,
        updateRevitParameter,
        deleteRevitParameter,
        getRevitParameterById,
        revitParameterStillExist,
    };
};
