import React, { useState } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { PlusCircleFill } from "react-bootstrap-icons";
import CreateProjectModalForm from "../Project/CreateProjectModalForm";
import ProjectCard from "../Project/ProjectCard";
import doodleProject from "../../images/doodle_project.svg";
import { Project } from "types";

interface Props {
    projects: Project[];
}

const HomePage: React.FC<Props> = ({ projects }) => {
    const [showCreateProjectForm, setShowCreateProjectForm] = useState(false);
    const nonArchivedProjects = projects.filter(project => !project.archived).slice(0, 12);
    const history = useHistory();

    return (
        <div className="h-100" style={{ overflowY: "auto" }}>
            <Row className="p-2 m-0 mb-4" style={{ backgroundColor: "#E4ECFB", height: "175px" }}>
                <Col className="d-flex justify-content-between align-items-center">
                    <span style={{ width: "75%" }}>
                        <h1 className="display-4">Welcome to FLUX AD</h1>
                        <Button className="d-flex align-items-center" onClick={() => setShowCreateProjectForm(true)}>
                            <PlusCircleFill size={20} className="me-2" />
                            New project
                        </Button>
                    </span>
                    <span style={{ width: "25%" }}>
                        <img className="py-2 px-0" style={{ width: 175 }} src={doodleProject} alt="" />
                    </span>
                </Col>
            </Row>
            <div className="p-4 m-0">
                <Row className="mb-5 ps-3">
                    <Col sm={4} md={3} lg={2} style={{ borderLeft: "solid", borderColor: "#CEDBF7" }}>
                        Total projects
                        <br />
                        <b>{projects.length}</b>
                    </Col>
                    <Col sm={4} md={3} lg={2} style={{ borderLeft: "solid", borderColor: "#CEDBF7" }}>
                        In Progress
                        <br />
                        <b>{projects.filter(project => !project.archived).length}</b>
                    </Col>
                    <Col sm={4} md={3} lg={2} style={{ borderLeft: "solid", borderColor: "#CEDBF7" }}>
                        Finished
                        <br />
                        <b>{projects.filter(project => project.archived).length}</b>
                    </Col>
                </Row>
                <div>
                    <Row>
                        <Col>
                            <h1>Projects you’re working on</h1>
                            <span className="d-flex justify-content-between align-items-center">
                                <h6 className="text-muted">
                                    Here you can see all the recent documents and projects you've been working on.
                                </h6>
                                <h6
                                    className="text-muted"
                                    onClick={() => history.push("/projects")}
                                    style={{ cursor: "pointer" }}
                                >
                                    view all -{">"}
                                </h6>
                            </span>
                            <Row>
                                {nonArchivedProjects &&
                                    nonArchivedProjects.map(project => (
                                        <ProjectCard key={project.id} project={project} />
                                    ))}
                            </Row>
                        </Col>
                    </Row>
                    {showCreateProjectForm && (
                        <CreateProjectModalForm handleClose={() => setShowCreateProjectForm(false)} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default HomePage;
