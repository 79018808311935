import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserContext } from "context";
import { IDocumentTemplate } from "types";
import { useContextModel, useContextTemplates } from "hooks";
import { DocumentCheckbox } from "./DocumentCheckbox";
import { ToolTipButton } from "../ToolTip/ToolTip";

interface IDocumentConfigurator {
    handleSelectedDocumentKey: (selected: boolean, documentTemplate: IDocumentTemplate) => void;
    selectedDocumentKeys: IDocumentTemplate[];
}

const DocumentConfigurator = ({
    handleSelectedDocumentKey,
    selectedDocumentKeys,
}: IDocumentConfigurator): JSX.Element => {
    const { t } = useTranslation();
    const { documentTemplates, loadingTemplates } = useContextTemplates();
    const { user } = useContext(UserContext);
    const [selectedKnowledgeId, setSelectedKnowledgeId] = useState<string>();
    const { contextModel, loadingModel } = useContextModel();

    const uniqueDocumentTemplateKnowledgeIds = [...new Set(documentTemplates.map(template => template.knowledgeId))];
    const filteredKnowledges = contextModel.knowledges?.filter(knowledge =>
        uniqueDocumentTemplateKnowledgeIds.includes(knowledge.id)
    );

    useEffect(() => {
        if (!loadingModel && !loadingTemplates) {
            const initialKnowledgeId = uniqueDocumentTemplateKnowledgeIds?.includes(user?.knowledgeId || "")
                ? user?.knowledgeId
                : filteredKnowledges[0]?.id;
            setSelectedKnowledgeId(initialKnowledgeId || "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingModel, loadingTemplates]);

    return (
        <Container>
            {loadingModel && loadingTemplates ? (
                <>{t("status.loading")}</>
            ) : (
                <div>
                    <Row>
                        <h5>
                            <small>{t("pages.BE18.document-list.create-documents.subtitle")}</small>
                        </h5>
                    </Row>

                    <Row>
                        <Col xs="12">
                            <h4 className="my-4">
                                {t("pages.BE18.document-list.create-documents.fields.knowledge-area")}
                                <ToolTipButton
                                    eventId="hover_sheet_knowledge"
                                    tooltipMessage={t(
                                        "pages.BE18.document-list.create-documents.fields.knowledge-area-help"
                                    )}
                                    tooltipId="sheetsConfigurator.knowledgeHeader"
                                    className="ms-1"
                                />
                            </h4>
                        </Col>
                        <Col className="mx-3">
                            <Form className="d-grid gap-2">
                                <Form.Group as={Row}>
                                    <Form.Select
                                        value={selectedKnowledgeId}
                                        onChange={e => setSelectedKnowledgeId(e.target.value)}
                                    >
                                        {filteredKnowledges &&
                                            filteredKnowledges?.map(knowledge => (
                                                <option key={knowledge.id} value={knowledge.id}>
                                                    {`${knowledge.code} - ${knowledge.value}`}
                                                    {knowledge.preferred && " ★ "}
                                                </option>
                                            ))}
                                    </Form.Select>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    {contextModel &&
                        contextModel?.documentKindCategories?.map((documentKindCategory, index) => {
                            const documentKindsWithDocumentCategory = contextModel?.documentKinds
                                ?.filter(dk => dk.documentKindCategoryId === documentKindCategory.id)
                                .map(dk => dk.id);
                            const groupedDocumentTemplates = documentTemplates?.filter(
                                dt =>
                                    dt.knowledgeId === selectedKnowledgeId &&
                                    documentKindsWithDocumentCategory?.includes(dt.documentKindId)
                            );
                            return (
                                <Row key={index}>
                                    {!groupedDocumentTemplates || groupedDocumentTemplates.length === 0 ? null : (
                                        <Col xs="12">
                                            <h3 className="my-4">
                                                <small>
                                                    {documentKindCategory?.code} - {documentKindCategory?.value}
                                                </small>
                                            </h3>
                                        </Col>
                                    )}
                                    {!groupedDocumentTemplates || groupedDocumentTemplates.length === 0
                                        ? null
                                        : groupedDocumentTemplates.map(document => (
                                              <Col xs="6" key={document.id}>
                                                  <DocumentCheckbox
                                                      document={document}
                                                      handleClick={handleSelectedDocumentKey}
                                                      selected={selectedDocumentKeys.includes(document)}
                                                  />
                                              </Col>
                                          ))}
                                </Row>
                            );
                        })}
                </div>
            )}
        </Container>
    );
};
export default DocumentConfigurator;
