import React from "react";
import { withRouter } from "react-router-dom";
import { useAuth } from "hooks";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import ProjectProvider from "./context/ProjectProvider";
import CookieDisclaimer from "./components/CookieDisclaimer/CookieDisclaimer";
import CompanyProvider from "./context/CompanyProvider";
import UnProtectedRoutes from "./routes/UnProtectedRoutes";
import ProviderComposer from "./context/ProviderComposer";
import SyntaxProvider from "./context/SyntaxProvider";
import { setAGGridLicense } from "config/AGGrid";

const App = () => {
    const { user } = useAuth();

    setAGGridLicense();

    return (
        <>
            {user ? (
                <ProviderComposer providers={[ProjectProvider, CompanyProvider, SyntaxProvider]}>
                    <ProtectedRoutes />
                </ProviderComposer>
            ) : (
                <UnProtectedRoutes />
            )}
            <CookieDisclaimer />
        </>
    );
};

export default withRouter(App);
