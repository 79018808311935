import React, { useContext, useState } from "react";
import { Modal, Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDocumentsNaming, useFormFields, useContextModel, useEnergyRapport, useLogger } from "hooks";
import { IDocument } from "types";
import { EMPTY_DOCUMENT } from "_constants";
import { DocumentsContext } from "context";
import { logEvent } from "config";
import ConfirmModal from "../Shared/ConfirmModal";
import { useMutation } from "@tanstack/react-query";
import { downloadBlob } from "utils/helpers";
import { useTranslation } from "react-i18next";
import DeleteButton from "components/Shared/Buttons/DeleteButton";

interface Props {
    document: IDocument;
    handleClose: () => void;
}

const DocumentForm: React.FC<Props> = ({ document, handleClose }) => {
    const { fields, handleFieldChange } = useFormFields<IDocument>(document || EMPTY_DOCUMENT);
    const [isInvalid, setIsInvalid] = useState<string[]>([]);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const { previewDocumentNumber } = useDocumentsNaming(document);
    const {
        contextModel,
        loadingModel,
        knowledgeStillExist,
        disciplineStillExist,
        documentKindStillExist,
        documentKindCategoryStillExist,
        getDocumentKindCategoryById,
    } = useContextModel();
    const { handleSaveDocumentField, handleDeleteDocument, getReportId } = useContext(DocumentsContext);
    const reportId = getReportId(document);
    const logger = useLogger();
    const hasReport = reportId !== undefined && reportId !== "UNKNOWN_DOC_ID";
    const { generateWordReportById } = useEnergyRapport();
    const { t } = useTranslation();

    const validateFields = (): boolean => {
        const invalid = [];
        const nameIsValid = () => fields.title !== "";
        const knowledgeIdIsValid = () => fields.knowledgeId !== "";
        const disciplineIdIsValid = () => fields.disciplineId !== "";
        const documentKindIdIsValid = () => fields.documentKindId !== "";
        const documentKindCategoryIdIsValid = () => fields.documentKindCategoryId !== "";
        if (!nameIsValid()) {
            invalid.push("TITLE");
        }
        if (!knowledgeIdIsValid()) {
            invalid.push("KNOWLEDGEID");
        }
        if (!disciplineIdIsValid()) {
            invalid.push("DISCIPLINEID");
        }
        if (!documentKindIdIsValid()) {
            invalid.push("DOCUMENTKINDID");
        }
        if (!documentKindCategoryIdIsValid()) {
            invalid.push("DOCUMENTKINDCATEGORYID");
        }
        if (invalid.length > 0) {
            setIsInvalid(invalid);
            return false;
        }
        return true;
    };

    const handleSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        const valid = validateFields();
        if (valid) {
            handleSaveDocumentField(document.id, fields);
            logEvent("document_save");
            handleClose();
        }
    };

    const downloadDocumentMutation = useMutation({
        mutationFn: generateWordReportById,
        onSuccess: async data => downloadBlob(data, `${previewDocumentNumber(fields)}.docx`),
        onError: error => {
            const err = error as Error;
            logger.log(err.message, err);
        },
    });

    return (
        <>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col className="mx-3">
                            <Form className="d-grid gap-2" noValidate>
                                <Row>
                                    <Form.Group as={Col} controlId="title">
                                        <Form.Label>
                                            {t("pages.FAST.sheet-list.edit-sheet.fields.title")}
                                            <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            isInvalid={isInvalid.includes("TITLE")}
                                            required
                                            type="text"
                                            placeholder="Ikke navngivet document"
                                            value={fields.title}
                                            onChange={event => {
                                                if (isInvalid.includes("TITLE")) {
                                                    setIsInvalid((fieldIds: string[]) =>
                                                        fieldIds.filter(fieldId => fieldId !== "TITLE")
                                                    );
                                                }
                                                handleFieldChange(event);
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="knowledgeId">
                                        <Form.Label>
                                            {t("pages.FAST.sheet-list.edit-sheet.fields.knowledge-area")}
                                            <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Select
                                            isInvalid={
                                                (isInvalid.includes("KNOWLEDGEID") ||
                                                    !knowledgeStillExist(fields.knowledgeId)) &&
                                                !loadingModel
                                            }
                                            value={fields.knowledgeId}
                                            onChange={event => {
                                                if (isInvalid.includes("KNOWLEDGEID")) {
                                                    setIsInvalid((fieldIds: string[]) =>
                                                        fieldIds.filter(fieldId => fieldId !== "KNOWLEDGEID")
                                                    );
                                                }
                                                handleFieldChange(event);
                                            }}
                                        >
                                            <option value="">
                                                {t("pages.FAST.sheet-list.edit-sheet.fields.knowledge-area-help")}
                                            </option>
                                            {contextModel.knowledges.map(knowledge => (
                                                <option value={knowledge.id} key={knowledge.id}>
                                                    {knowledge.code} - {knowledge.value}
                                                    {knowledge.preferred && " ★ "}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        {!knowledgeStillExist(fields.knowledgeId) && (
                                            <Form.Control.Feedback type="invalid">
                                                {t("pages.FAST.sheet-list.edit-sheet.fields.value-deleted")}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="disciplineId">
                                        <Form.Label>
                                            {t("pages.FAST.sheet-list.edit-sheet.fields.discipline")}
                                            <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Select
                                            isInvalid={
                                                (isInvalid.includes("DISCIPLINEID") ||
                                                    !disciplineStillExist(fields.disciplineId)) &&
                                                !loadingModel
                                            }
                                            value={fields.disciplineId}
                                            onChange={event => {
                                                if (isInvalid.includes("DISCIPLINEID")) {
                                                    setIsInvalid((fieldIds: string[]) =>
                                                        fieldIds.filter(fieldId => fieldId !== "DISCIPLINEID")
                                                    );
                                                }
                                                handleFieldChange(event);
                                            }}
                                        >
                                            <option value="">
                                                {t("pages.FAST.sheet-list.edit-sheet.fields.discipline-help")}
                                            </option>
                                            {contextModel.disciplines.map(discipline => (
                                                <option value={discipline.id} key={discipline.id}>
                                                    {discipline.code} - {discipline.value}
                                                    {discipline.preferred && " ★ "}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        {!disciplineStillExist(fields.disciplineId) && (
                                            <Form.Control.Feedback type="invalid">
                                                {t("pages.FAST.sheet-list.edit-sheet.fields.value-deleted")}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="documentKindCategoryId">
                                        <Form.Label>
                                            {t("pages.FAST.sheet-list.edit-sheet.fields.documentkind-cat")}
                                            <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Select
                                            isInvalid={
                                                (isInvalid.includes("DOCUMENTKINDCATEGORYID") ||
                                                    !documentKindCategoryStillExist(fields.documentKindCategoryId)) &&
                                                !loadingModel
                                            }
                                            value={fields.documentKindCategoryId}
                                            onChange={event => {
                                                if (isInvalid.includes("DOCUMENTKINDCATEGORYID")) {
                                                    setIsInvalid((fieldIds: string[]) =>
                                                        fieldIds.filter(fieldId => fieldId !== "DOCUMENTKINDCATEGORYID")
                                                    );
                                                }
                                                handleFieldChange(event);
                                            }}
                                        >
                                            <option value="">
                                                {t("pages.FAST.sheet-list.edit-sheet.fields.documentkind-cat-help")}
                                            </option>
                                            {contextModel.documentKindCategories?.map(dkc => (
                                                <option key={dkc.id} value={dkc.id}>
                                                    {`${dkc.code} - ${dkc.value}`}
                                                    {dkc.preferred && " ★ "}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        {!documentKindCategoryStillExist(fields.documentKindCategoryId) && (
                                            <Form.Control.Feedback type="invalid">
                                                {t("pages.FAST.sheet-list.edit-sheet.fields.value-deleted")}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="documentKindId">
                                        <Form.Label>
                                            {t("pages.FAST.sheet-list.edit-sheet.fields.documentkind-sub")}
                                            <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Select
                                            isInvalid={
                                                (isInvalid.includes("DOCUMENTKINDID") ||
                                                    !documentKindStillExist(fields.documentKindId)) &&
                                                !loadingModel
                                            }
                                            value={fields.documentKindId}
                                            onChange={event => {
                                                if (isInvalid.includes("DOCUMENTKINDID")) {
                                                    setIsInvalid((fieldIds: string[]) =>
                                                        fieldIds.filter(fieldId => fieldId !== "DOCUMENTKINDID")
                                                    );
                                                }
                                                handleFieldChange(event);
                                            }}
                                        >
                                            <option value="">
                                                {t("pages.FAST.sheet-list.edit-sheet.fields.documentkind-sub-help")}
                                            </option>
                                            {contextModel.documentKinds
                                                .filter(
                                                    dk => dk.documentKindCategoryId === fields.documentKindCategoryId
                                                )
                                                .map(documentKind => (
                                                    <option key={documentKind.id} value={documentKind.id}>
                                                        {
                                                            getDocumentKindCategoryById(
                                                                documentKind.documentKindCategoryId
                                                            )?.code
                                                        }
                                                        .{documentKind.subCode} - {documentKind.value}
                                                        {documentKind.preferred && " ★ "}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                        {!documentKindStillExist(fields.documentKindId) && (
                                            <Form.Control.Feedback type="invalid">
                                                {t("pages.FAST.sheet-list.edit-sheet.fields.value-deleted")}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>
                                            {t("pages.FAST.sheet-list.edit-sheet.fields.document-number")}
                                        </Form.Label>
                                        <Form.Control type="text" value={previewDocumentNumber(fields)} disabled />
                                    </Form.Group>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <div>
                    <DeleteButton
                        onClick={() => {
                            setShowConfirmModal(true);
                        }}
                    />
                </div>
                <div>
                    {hasReport && (
                        <Button variant="primary" onClick={() => downloadDocumentMutation.mutate({ id: reportId })}>
                            Hent BE18 rapport
                        </Button>
                    )}
                </div>
                <div>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                    <Button disabled={isInvalid.length > 0} variant="primary" className="ms-2" onClick={handleSubmit}>
                        {t("buttons.save")}
                    </Button>
                </div>
            </Modal.Footer>
            <ConfirmModal
                show={showConfirmModal}
                handleAction={() => {
                    handleDeleteDocument(document.id);
                    logEvent("document_delete_form");
                    setShowConfirmModal(false);
                    handleClose();
                }}
                handleClose={() => {
                    setShowConfirmModal(false);
                    handleClose();
                }}
                description="Are you sure you want to delete this document?"
                confirmText="Delete"
            />
        </>
    );
};

export default DocumentForm;
