import { useCallback, useContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { ISheetTemplate, IDocumentTemplate } from "types";
import { CompanyContext } from "context";
import { addDoc, collection, deleteDoc, doc, onSnapshot, setDoc } from "firebase/firestore";
import { firestore } from "config";

export const useContextTemplates = (): {
    sheetTemplates: ISheetTemplate[];
    documentTemplates: IDocumentTemplate[];
    createSheetTemplate: (data: Omit<ISheetTemplate, "Id">) => void;
    updateSheetTemplate: (data: ISheetTemplate) => Promise<void>;
    deleteSheetTemplate: (id: string) => Promise<void>;
    createDocumentTemplate: (data: Omit<IDocumentTemplate, "Id">) => Promise<void>;
    updateDocumentTemplate: (data: IDocumentTemplate) => Promise<void>;
    deleteDocumentTemplate: (id: string) => Promise<void>;
    loadingTemplates: boolean;
} => {
    const match = useRouteMatch<{ projectId: string }>("/projects/:projectId");
    const projectId = match?.params.projectId || "";
    const inCompanyContext = projectId === "";
    const { company } = useContext(CompanyContext);

    //constants
    const contextPath = inCompanyContext ? "companies" : "projects";
    const contextId = inCompanyContext ? company?.id : projectId;
    const sheetTemplatePath = "sheet-templates";
    const documentTemplatePath = "document-templates";

    //loading
    const [loadingSheetTemplates, setLoadingSheetTemplates] = useState(true);
    const [loadingDocumentTemplates, setLoadingDocumentTemplates] = useState(true);
    const loadingTemplates = loadingSheetTemplates || loadingDocumentTemplates;

    //state
    const [sheetTemplates, setSheetTemplates] = useState<ISheetTemplate[]>([]);
    const [documentTemplates, setDocumentTemplates] = useState<IDocumentTemplate[]>([]);

    const fetchTemplate = (
        templatesPath: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setTemplates: (templates: any) => void,
        setLoading: (loading: boolean) => void
    ) => {
        if (!contextId) return;
        setLoading(true);
        const collectionRef = collection(firestore, contextPath, contextId, templatesPath);
        const unsubscribe = onSnapshot(collectionRef, collectionSnapshot => {
            const templates: (IDocumentTemplate | ISheetTemplate)[] = [];
            collectionSnapshot.forEach(doc => {
                const template = doc.data() as IDocumentTemplate | ISheetTemplate;
                template.id = doc.id;
                templates.push(template);
            });
            setTemplates(templates);
            setLoading(false);
        });
        return () => unsubscribe();
    };

    const fetchSheetTemplateCallback = useCallback(fetchTemplate, [contextId, contextPath]);
    const fetchDocumentTemplateCallBack = useCallback(fetchTemplate, [contextId, contextPath]);

    //sheet templates
    useEffect(() => {
        fetchSheetTemplateCallback(sheetTemplatePath, setSheetTemplates, setLoadingSheetTemplates);
    }, [fetchSheetTemplateCallback]);

    //document templates
    useEffect(() => {
        fetchDocumentTemplateCallBack(documentTemplatePath, setDocumentTemplates, setLoadingDocumentTemplates);
    }, [fetchDocumentTemplateCallBack]);

    //create template
    const createTemplateItem = async (data: ISheetTemplate | IDocumentTemplate, templatesPath: string) => {
        if (!contextId) return;
        try {
            const collectionRef = collection(firestore, contextPath, contextId, templatesPath);
            const { id, ...cleanedData } = data;
            await addDoc(collectionRef, cleanedData);
        } catch (error) {
            //TODO
        }
    };

    const createSheetTemplate = async (data: ISheetTemplate) => {
        createTemplateItem(data, sheetTemplatePath);
    };

    const createDocumentTemplate = async (data: IDocumentTemplate) => {
        createTemplateItem(data, documentTemplatePath);
    };

    //update template
    const updateTemplateItem = async (data: ISheetTemplate | IDocumentTemplate, templatesPath: string) => {
        if (!contextId) {
            return;
        }

        try {
            const documentRef = doc(firestore, contextPath, contextId, templatesPath, data.id);
            const { id, ...cleanedData } = data;
            await setDoc(documentRef, cleanedData, { merge: true });
        } catch (error) {
            //TODO
        }
    };

    const updateSheetTemplate = async (data: ISheetTemplate) => {
        updateTemplateItem(data, sheetTemplatePath);
    };

    const updateDocumentTemplate = async (data: IDocumentTemplate) => {
        updateTemplateItem(data, documentTemplatePath);
    };

    //delete template
    const deleteModelItem = async (id: string, templatesPath: string) => {
        if (!contextId) {
            return;
        }
        try {
            const documentRef = doc(firestore, contextPath, contextId, templatesPath, id);
            await deleteDoc(documentRef);
        } catch (error) {
            //TODO
        }
    };

    const deleteSheetTemplate = async (id: string) => {
        deleteModelItem(id, sheetTemplatePath);
    };

    const deleteDocumentTemplate = async (id: string) => {
        deleteModelItem(id, documentTemplatePath);
    };

    return {
        sheetTemplates,
        documentTemplates,
        createSheetTemplate,
        updateSheetTemplate,
        deleteSheetTemplate,
        createDocumentTemplate,
        updateDocumentTemplate,
        deleteDocumentTemplate,
        loadingTemplates,
    };
};
