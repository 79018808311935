import { SystemFlow, HeatingMethod, HousingType, WaterComponent } from "types";

const waterComponentEffectViewModelMap : { [key: string]: number } = {
    [WaterComponent.HEATEXCHANGER]: 45,
    [WaterComponent.HOTWATERTANK]: 8,
}

export const waterComponentEffectViewModel = (waterComponent: WaterComponent): number => waterComponentEffectViewModelMap[waterComponent]

const housingTypeAirVolumeViewModelMap : { [key: string]: number } = {
    [HousingType.DORMITORYHOUSING]: 54,
    [HousingType.ORDINARYHOUSING]: 230,
    [HousingType.STUDENTHOUSING]: 140,
}

export const housingTypeAirVolumeViewModel = (housingType: HousingType): number => housingTypeAirVolumeViewModelMap[housingType]

type PrimaryWaterSupplyViewModel = {
    primaryTemperatureSetForward: number;
    primaryTemperatureSetReturn: number;
}

const primaryWaterSupplyViewModelMap : { [key: string]: PrimaryWaterSupplyViewModel } = {
    [SystemFlow.DIRECT]: {
        primaryTemperatureSetForward: 60,
        primaryTemperatureSetReturn: 30,
    },
    [SystemFlow.INDIRECT]: {
        primaryTemperatureSetForward: 70,
        primaryTemperatureSetReturn: 40,
    }
}

export const primaryWaterSupplyViewModel = (systemFlow: SystemFlow): PrimaryWaterSupplyViewModel => primaryWaterSupplyViewModelMap[systemFlow]

// shuntPlacementViewModelMap is a map of the shunt placement to the view model
type ShuntPlacementCentralTempSetViewModel = {
    temperatureSetForward: number;
    temperatureSetReturn: number;
}

const shuntPlacementCentralTempSetViewModelMap : { [key: string]: ShuntPlacementCentralTempSetViewModel } = {
    [HeatingMethod.RADIATOR]: {
        temperatureSetForward: 60,
        temperatureSetReturn: 30,
    },
    [HeatingMethod.UNDERFLOORHEATING]: {
        temperatureSetForward: 40,
        temperatureSetReturn: 35,
    },
}
export const shuntPlacementCentralTempSetViewModel = (heatingMethod: HeatingMethod): ShuntPlacementCentralTempSetViewModel => shuntPlacementCentralTempSetViewModelMap[heatingMethod]

// heatingMethodViewModelMap is a map of the heating method to the view model
const heatingMethodEffectViewModelMap: { [key: string]: number } = {
    [HeatingMethod.RADIATOR]: 50,
    [HeatingMethod.UNDERFLOORHEATING]: 35,
}

export const heatingMethodEffectViewModel = (heatingMethod: HeatingMethod): number => heatingMethodEffectViewModelMap[heatingMethod]