import { EnergyRapportFields, Report, XMLEntity, XMLFile } from 'types';
import { AbstractBE18XMLService } from './xmlService';

export class BE18KeyService extends AbstractBE18XMLService {
    constructor(xml: string) {
        super(xml);
        const root = this.getSubElement(this.data, 'be05key_fig');
        this.content = this.parseFile(root);
    }

    public getContent(): Pick<Report, 'tables' | 'fields'> {
        if (this.content === undefined) {
            throw new Error('content');
        }
        return this.content;
    }

    public parseFile = (
        xml: XMLEntity | XMLFile | undefined,
    ): Pick<Report, 'tables' | 'fields'> | undefined => {
        const store: Pick<Report, 'tables' | 'fields'> = {
            fields: {},
            tables: {},
        };
        const setState = (key: string, value: unknown) => {
            store.fields[key] = value;
        };

        const setStateByTableRow = <T>(
            xmlEntity: XMLEntity | undefined,
            key: string,
            handler?: (input: string | undefined) => T,
        ): void => {
            const valueElement = this.getSubElement(xmlEntity, 'value');
            const value = this.getElementValue(valueElement);
            if (value === undefined) return;
            setState(key, handler === undefined ? value : handler(value));
        };

        const br18target = 'Energiramme BR 2018';
        const lowEnergyTarget = 'Energiramme lavenergi';

        const energiFrame = this.getSubElementsByAttributes(xml, 'table', ['head', br18target]);
        const energiFrameLowEnergy = this.getSubElementsByAttributes(xml, 'table', ['head', lowEnergyTarget]);
        const energiFrameRenovation = this.getSubElementsByAttributes(xml, 'table', [
            'head',
            'Renoveringsklasse 1',
        ]);
        const energiFrameRenovation2 = this.getSubElementsByAttributes(xml, 'table', [
            'head',
            'Renoveringsklasse 2',
        ]);
        setState('EnergyFrameTarget', 'BR 2018');
        if (energiFrame.length > 0) {
            const energiFrameTargetRowBR18 = this.getSubElements(energiFrame[0], 'row')[0];
            const energiFrameAdditionsRowBR18 = this.getSubElements(energiFrame[0], 'row')[1];
            const totalEnergiFrameRowBR18 = this.getSubElements(energiFrame[0], 'row')[2];
            const totalEnergiNeedRowBR18 = this.getSubElements(energiFrame[0], 'row')[3];

            setStateByTableRow(energiFrameTargetRowBR18, 'EnergyFrameBR18', this.parseNumberValue);
            setStateByTableRow(energiFrameAdditionsRowBR18, 'EnergyFrameAdd', this.parseNumberValue);
            setStateByTableRow(totalEnergiFrameRowBR18, 'TotalEnergyFrame', this.parseNumberValue);
            setStateByTableRow(totalEnergiNeedRowBR18, 'TotalEnergyNeed', this.parseNumberValue);
        }
        if (energiFrameLowEnergy.length > 0) {
            const energiFrameTargetRowLowEnergy = this.getSubElements(energiFrameLowEnergy[0], 'row')[0];
            setStateByTableRow(energiFrameTargetRowLowEnergy, 'EnergyFrameLowEnergy', this.parseNumberValue);
        }
        if (energiFrameRenovation.length > 0) {
            const energiFrameTargetRowRenovation = this.getSubElements(energiFrameRenovation[0], 'row')[0];
            setStateByTableRow(
                energiFrameTargetRowRenovation,
                'EnergyFrameRenovation',
                this.parseNumberValue,
            );
        }
        if (energiFrameRenovation2.length > 0) {
            const energiFrameTargetRowRenovation2 = this.getSubElements(energiFrameRenovation2[0], 'row')[0];
            setStateByTableRow(
                energiFrameTargetRowRenovation2,
                'EnergyFrameRenovation2',
                this.parseNumberValue,
            );
        }

        // version
        const fileAttributes = (xml as XMLEntity)?.attributes;
        if (fileAttributes?.version !== undefined) {
            const version = fileAttributes.version;
            setState('Version', version);
        }

        // TransmissionlossRequirenments
        const transmissionLossTable = this.getSubElementsByAttributes(xml, 'table', [
            'head',
            'Transmissionstab',
        ]);

        if (transmissionLossTable.length > 0) {
            const transmissionLossRequirementRow = this.getSubElements(transmissionLossTable[0], 'row')[0];
            const transmissionLossCalculationRow = this.getSubElements(transmissionLossTable[0], 'row')[2];
            setStateByTableRow(
                transmissionLossRequirementRow,
                'TransmissionlossRequirement',
                this.parseNumberValue,
            );
            setStateByTableRow(transmissionLossCalculationRow, 'TransmissionsLoss', this.parseNumberValue);
        }

        return store;
    };
}
