import React, { useEffect, createContext, useState, ReactNode, useContext } from "react";
import { BuildingsContext } from "context";
import { Storey } from "types";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { firestore } from "config";
import { pickBy } from "lodash";
interface IStoreysContext {
    error: Error | null | undefined;
    handleSaveStorey: (buildingId: string, storey: Storey) => void;
    loadingStoreys: boolean;
    storeys: { [buildingId: string]: Storey[] };
}

export const StoreysContext = createContext({} as IStoreysContext);

interface Props {
    projectId?: string | undefined;
    children?: ReactNode;
}

const StoreysProvider = ({ projectId, children }: Props): JSX.Element => {
    const { buildings } = useContext(BuildingsContext);
    const [loadingStoreys, setLoadingStoreys] = useState<boolean>(true);
    const [storeys, setStoreys] = useState<{ [key: string]: Storey[] }>({});
    const [error, setError] = useState<Error | null>();

    useEffect(() => {
        if (!projectId) {
            return;
        }
        getStoreys(projectId);
    }, [projectId, buildings]);

    const getStoreys = async (id: string) => {
        setLoadingStoreys(true);
        const buildingsRef = collection(firestore, "projects", id, "buildings");
        const buildingsSnapshot = await getDocs(buildingsRef);
        const buildingIds: string[] = [];
        buildingsSnapshot.forEach(building => buildingIds.push(building.id));
        for (const buildingId of buildingIds) {
            const storeysRef = collection(firestore, "projects", id, "buildings", buildingId, "storeys");
            const buildingStoreys: Storey[] = [];
            const storeysSnapshot = await getDocs(storeysRef);
            storeysSnapshot.forEach(storey => {
                const data = storey.data() as Storey;
                buildingStoreys.push({ id: storey.id, ...data });
            });
            setStoreys(prevStoreys => ({
                ...prevStoreys,
                [buildingId]: buildingStoreys,
            }));
        }
        setLoadingStoreys(false);
    };

    const handleSaveStorey = async (buildingId: string, storey: Storey) => {
        if (!projectId || !buildingId) {
            setError(new Error("Bygningen der skal oprettes etage i kunne ikke identificeres"));
            return;
        }

        const storeysRef = collection(firestore, "projects", projectId, "buildings", buildingId, "storeys");

        let storeyRef;
        if (storey.id === undefined) {
            storeyRef = doc(storeysRef);
        } else {
            storeyRef = doc(storeysRef, storey.id);
        }
        const cleanStorey = pickBy(storey, value => value !== undefined);
        const { id, ...storeyData } = cleanStorey;
        try {
            await setDoc(storeyRef, storeyData, { merge: true });
        } catch (error) {
            setError(error as Error);
        }
        getStoreys(projectId);
    };

    return (
        <StoreysContext.Provider
            value={{
                error,
                handleSaveStorey,
                loadingStoreys,
                storeys,
            }}
        >
            {children}
        </StoreysContext.Provider>
    );
};

export default StoreysProvider;
