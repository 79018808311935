import React from "react";
import { Button, ButtonGroup, Dropdown, DropdownButton, Form, InputGroup, Modal } from "react-bootstrap";
import { useFormFields } from "hooks";
import { SerialNumberConfig } from "types";
import { CountingGroupMethod, MetaDataType } from "_constants";
import { useTranslation } from "react-i18next";

interface Props {
    serialNumberConfig: SerialNumberConfig;
    handleSave: (serialNumberConfig: SerialNumberConfig) => void;
    handleClose: () => void;
    show: boolean;
}

const SerialNumberConfiguratorModal: React.FC<Props> = ({ serialNumberConfig, handleSave, handleClose, show }) => {
    const { t } = useTranslation();
    const { fields, handleFieldChange, setFields } = useFormFields<SerialNumberConfig>(serialNumberConfig);

    const handleChangeNumDigits = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newNumDigit = parseInt(event.target.value);
        if (newNumDigit > 0 && newNumDigit <= 8) {
            const newBoundDigits = Array<MetaDataType>(newNumDigit).fill(MetaDataType.NONE);
            setFields({ ...fields, numDigits: newNumDigit, boundDigits: newBoundDigits });
        }
    };

    const handleChangeBoundDigits = (index: number, value: MetaDataType) => {
        const newBoundDigits = [...fields.boundDigits];
        newBoundDigits[index] = value;
        setFields({ ...fields, boundDigits: newBoundDigits });
    };

    const handleRemoveMetaDataType = (index: number) => {
        const newMetaDataTypes = [...fields.metaDataTypes];
        newMetaDataTypes.splice(index, 1);
        setFields({ ...fields, metaDataTypes: newMetaDataTypes });
    };

    const handleAddMetaDataType = (value: MetaDataType) => {
        const newMetaDataTypes = [...fields.metaDataTypes, value];
        setFields({ ...fields, metaDataTypes: newMetaDataTypes });
    };

    const translationIdMetadata = (syntax: MetaDataType) => {
        switch (syntax) {
            case MetaDataType.KNOWLEDGE:
                return "pages.shared.naming-syntax.serial-number.elements.knowledge-area";
            case MetaDataType.DISCIPLINE:
                return "pages.shared.naming-syntax.serial-number.elements.discipline";
            case MetaDataType.SHEETTYPE:
                return "pages.shared.naming-syntax.serial-number.elements.sheet-type";
            case MetaDataType.LOCATION:
                return "pages.shared.naming-syntax.serial-number.elements.location";
            case MetaDataType.STOREY:
                return "pages.shared.naming-syntax.serial-number.elements.storey";
            case MetaDataType.STAGE:
                return "pages.shared.naming-syntax.serial-number.elements.stage";
            case MetaDataType.PHASE:
                return "pages.shared.naming-syntax.serial-number.elements.phase";
            case MetaDataType.THEME:
                return "pages.shared.naming-syntax.serial-number.elements.theme";
            case MetaDataType.FILETYPE:
                return "pages.shared.naming-syntax.serial-number.elements.file-type";
            default:
                return "";
        }
    };

    const translateMetadataToCode = (syntax: MetaDataType) => {
        switch (syntax) {
            case MetaDataType.KNOWLEDGE:
                return "K";
            case MetaDataType.DISCIPLINE:
                return "M";
            case MetaDataType.SHEETTYPE:
                return "H";
            case MetaDataType.LOCATION:
                return "L";
            case MetaDataType.STOREY:
                return "E";
            case MetaDataType.STAGE:
                return "S";
            case MetaDataType.PHASE:
                return "A";
            case MetaDataType.THEME:
                return "T";
            case MetaDataType.FILETYPE:
                return "F";
            default:
                return "";
        }
    };

    return (
        <Modal show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <h6>{t("pages.shared.naming-syntax.serial-number.title")}</h6>
            </Modal.Header>
            <Modal.Body>
                <Form className="d-grid gap-3">
                    <Form.Group controlId="numDigits">
                        <Form.Label>{t("pages.shared.naming-syntax.serial-number.fields.amount")}</Form.Label>
                        <Form.Select value={fields.numDigits} onChange={handleChangeNumDigits}>
                            {[...Array(7)].map((_, index) => (
                                <option key={index} value={index + 2}>
                                    {index + 2}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Text className="text-muted">
                            {t("pages.shared.naming-syntax.serial-number.fields.amount-help")}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="groupingMethod">
                        <Form.Label>{t("pages.shared.naming-syntax.serial-number.fields.counting-methods")}</Form.Label>
                        <Form.Select name="id" value={fields.groupingMethod} onChange={handleFieldChange}>
                            <option value={CountingGroupMethod.ONE_GROUP}>
                                {t("pages.shared.naming-syntax.serial-number.counting-methods.one-group")}
                            </option>
                            <option value={CountingGroupMethod.SAMENAME_GROUP}>
                                {t("pages.shared.naming-syntax.serial-number.counting-methods.same-name")}
                            </option>
                            <option value={CountingGroupMethod.METADATATYPE_GROUP}>
                                {t("pages.shared.naming-syntax.serial-number.counting-methods.meta-data")}
                            </option>
                        </Form.Select>
                        {fields.groupingMethod === CountingGroupMethod.ONE_GROUP && (
                            <Form.Text className="text-muted">
                                {t("pages.shared.naming-syntax.serial-number.counting-methods.one-group-help")}
                            </Form.Text>
                        )}
                        {fields.groupingMethod === CountingGroupMethod.SAMENAME_GROUP && (
                            <Form.Text className="text-muted">
                                {t("pages.shared.naming-syntax.serial-number.counting-methods.same-name-help")}
                            </Form.Text>
                        )}
                        {fields.groupingMethod === CountingGroupMethod.METADATATYPE_GROUP && (
                            <Form.Text className="text-muted">
                                {t("pages.shared.naming-syntax.serial-number.counting-methods.meta-data-help")}
                            </Form.Text>
                        )}
                    </Form.Group>
                    {fields.groupingMethod === CountingGroupMethod.METADATATYPE_GROUP && (
                        <Form.Group controlId="perMetaDataType">
                            <Form.Label>{t("pages.shared.naming-syntax.serial-number.fields.meta-data")}</Form.Label>
                            <InputGroup>
                                <ButtonGroup size="sm">
                                    {[...Array(fields.metaDataTypes.length)].map((_, index) => (
                                        <Button
                                            size="sm"
                                            key={index}
                                            variant="outline-primary"
                                            onClick={() => handleRemoveMetaDataType(index)}
                                        >
                                            {t(translationIdMetadata(fields.metaDataTypes[index]))}
                                        </Button>
                                    ))}
                                    <DropdownButton
                                        size="sm"
                                        className="m-0 p-0"
                                        as={ButtonGroup}
                                        title={t("buttons.add")}
                                        variant="outline-primary"
                                        onSelect={value => handleAddMetaDataType(value as MetaDataType)}
                                    >
                                        {Object.keys(MetaDataType)
                                            .filter(se => !fields.metaDataTypes.includes(se as MetaDataType))
                                            .map(key => (
                                                <Dropdown.Item key={key} eventKey={key}>
                                                    {t(translationIdMetadata(key as MetaDataType))}
                                                </Dropdown.Item>
                                            ))}
                                    </DropdownButton>
                                </ButtonGroup>
                            </InputGroup>
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Form.Label>{t("pages.shared.naming-syntax.serial-number.fields.meta-data-value")}</Form.Label>
                        <InputGroup>
                            <ButtonGroup>
                                <Button variant="outline-primary" size="sm" disabled={true}>
                                    N
                                </Button>
                                {fields.numDigits > 0 &&
                                    [...Array(fields.numDigits - 1)].map((_, index) => (
                                        <Dropdown
                                            id={`dropdown-button-drop-${index}`}
                                            key={index}
                                            as={ButtonGroup}
                                            onSelect={value => handleChangeBoundDigits(index, value as MetaDataType)}
                                        >
                                            <Dropdown.Toggle size="sm" variant="outline-primary" className="rounded-0">
                                                {translateMetadataToCode(fields.boundDigits[index]) || "X"}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {fields.boundDigits[index] !== "X" && (
                                                    <>
                                                        <Dropdown.Item eventKey="X">
                                                            {t("actions.remove")}
                                                        </Dropdown.Item>
                                                        <Dropdown.Divider />
                                                    </>
                                                )}
                                                {Object.keys(MetaDataType).map(key => (
                                                    <Dropdown.Item key={key} eventKey={key}>
                                                        {t(translationIdMetadata(key as MetaDataType))}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ))}
                                <Button variant="outline-primary" size="sm" disabled={true}>
                                    X
                                </Button>
                            </ButtonGroup>
                        </InputGroup>
                        <Form.Text className="text-muted">
                            {t("pages.shared.naming-syntax.serial-number.fields.meta-data-help")}
                        </Form.Text>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("buttons.cancel")}
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        handleSave(fields);
                        handleClose();
                    }}
                >
                    {t("buttons.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SerialNumberConfiguratorModal;
