import React, { useContext, useState } from "react";
import { Button, Form, Row, Card, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SyntaxContext } from "context";
import { useProjectId, useProject } from "hooks";
import NamingSyntaxModal from "../Project/NamingSyntaxModal";

const DocumentSettingsContainer: React.FC = () => {
    const { t } = useTranslation();
    const [showDocumentNamingModal, setShowDocumentNamingModal] = useState(false);
    const { documentNameSyntax, documentSyntaxPreview, saveProjectSyntaxElements } = useContext(SyntaxContext);
    const projectId = useProjectId();
    const { project } = useProject(projectId);

    return (
        <Container fluid className="m-0 p-4 w-100 h-100" style={{ overflowY: "auto" }}>
            <h1>{t("pages.BE18.settings.title")}</h1>
            <h6 className="text-muted">{t("pages.BE18.settings.subtitle")}</h6>
            <Row>
                <Card className="m-3" style={{ width: 400 }}>
                    <Card.Body>
                        {t("pages.BE18.settings.fields.document-number")}
                        <Form.Control disabled value={documentSyntaxPreview} />
                    </Card.Body>
                    <Button
                        className="m-1"
                        variant="secondary"
                        disabled={project?.archived || false}
                        onClick={() => {
                            setShowDocumentNamingModal(true);
                        }}
                    >
                        {t("buttons.edit")}
                    </Button>
                </Card>
            </Row>
            <NamingSyntaxModal
                show={showDocumentNamingModal}
                handleReOpen={() => setShowDocumentNamingModal(true)}
                handleClose={() => setShowDocumentNamingModal(false)}
                nameSyntax={documentNameSyntax}
                saveSyntaxElements={saveProjectSyntaxElements}
            />
        </Container>
    );
};

export default DocumentSettingsContainer;
