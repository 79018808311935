import { useLogger } from "hooks";
import { ChangeLogEntry } from "types";
import axios from "axios";
import { API_URL } from "config";
import { useQueries } from "@tanstack/react-query";

interface UseAppVersion {
    plugin: ChangeLogEntry[];
    webapp: ChangeLogEntry[];
    currentVersion: string;
}

export const useAppVersion = () : UseAppVersion => {
    const logger = useLogger();
    const getPlugin = async () => {
        try {
            const res = await axios.get<string,{ data: ChangeLogEntry[] }>(`${API_URL}/v1/version/plugin`);
            return res.data;
        } catch (err) {
            logger.error("Failed to fetch plugin version", err);
        }
    }

    const getApp = async () => {
        try {
            const res = await axios.get<string,{ data: ChangeLogEntry[] }>(`${API_URL}/v1/version/webapp`);
            return res.data;
        } catch (err) {
            logger.error("Failed to fetch webapp version", err);
        }
    };

    const queries = useQueries({
        queries: [
            { queryKey: ["plugin"], queryFn: getPlugin, cacheTime: 5 * 60 * 1000, staleTime: 60 * 60 * 1000 },
            { queryKey: ["app"], queryFn: getApp, cacheTime: 5 * 60 * 1000, staleTime: 60 * 60 * 1000 },
        ]
    });

    const pluginVersions = queries[0].isFetched && queries[0].data ? queries[0].data : [];
    const webAppVersions = queries[1].isFetched && queries[1].data ? queries[1].data : [];

    const currentVersion = webAppVersions.length > 0 ? webAppVersions[webAppVersions.length - 1].version : 'X.X.X';

    return { plugin: pluginVersions, webapp: webAppVersions, currentVersion }
}
